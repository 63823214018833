import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports -- Gradually migrating off of Grid
  Grid,
  type InputBaseComponentProps,
  Popover,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { useDebounce } from 'use-debounce';
import { v4 } from 'uuid';
import { ORDER_PAGE_CITY_AND_STATE_OPTIONS_TABLE_TEST_ID } from '../../../constants';
import {
  addAddress,
  type AddressFormField,
  updateAddress,
} from '../../domains/addresses/redux/addresses-values-slice';
import { addressIsEmpty } from '../../domains/addresses/redux/addresses-values-thunks';
import {
  PageMode,
  useQuotePageMode,
} from '../../domains/orders/hooks/use-page-mode';
import {
  type CityAndStateOptionEntity,
  useGetCityAndStateOptionsFromZipcodeLazyQuery,
  useGetZipcodeOptionsFromCityAndStateLazyQuery,
  type ZipcodeOptionEntity,
} from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import { useAppDispatch } from '../../redux/hooks';
import { ADDRESS_REQUIRED_FIELDS } from '../constants';

export const AUTOFILL_COMPONENT_TEST_ID = 'autofill-component';

type OptionStructure = {
  label: string;
  value: string;
  address: AddressFormField;
  name?: string;
};

type AddressAutofillProps = {
  /** Allow users to enter values outside of the predefined options */
  readonly freeSolo: boolean;
  readonly handleChange: ({
    address,
    isAutofillChange,
  }: {
    address: AddressFormField;
    isAutofillChange: boolean;
  }) => void;
  readonly error: string | undefined;
  readonly currentOption: OptionStructure | null;
  readonly nameInputValue: string;
  readonly hideLine2: boolean;
  readonly disabled?: boolean;
  readonly newStyling?: boolean;
  readonly useAllCaps?: boolean;
  readonly forceEditMode?: boolean;
  readonly testIds?: {
    addressLine1TestId?: string;
    addressLine2TestId?: string;
    addressCityTestId?: string;
    addressStateTestId?: string;
    addressZipTestId?: string;
  };
  readonly required: boolean;
};

/**
 * An address autocomplete form without a name field. Auto-suggests city/state/zip
 *
 * For client-facing components, use @see AddressAutocompleteForm instead,
 * since that includes a name field and client's template addresses.
 *
 * Use this component directly for customer-facing / third-party user components, just
 * note that you'll need to provide your own name field.
 */
const AddressAutofill = ({
  handleChange,
  error,
  currentOption,
  freeSolo,
  nameInputValue,
  hideLine2,
  disabled,
  newStyling,
  useAllCaps,
  forceEditMode,
  testIds,
  required,
}: AddressAutofillProps) => {
  const pageMode = useQuotePageMode({ forceEditMode });
  const uppercaseInputProps: InputBaseComponentProps =
    useAllCaps === true ? { style: { textTransform: 'uppercase' } } : {};
  const dispatch = useAppDispatch();

  const [getCityAndStateFromZipcode] =
    useGetCityAndStateOptionsFromZipcodeLazyQuery();
  const [getZipcodeFromCityAndState] =
    useGetZipcodeOptionsFromCityAndStateLazyQuery();

  const stateTextFieldRef = useRef<HTMLInputElement | null>(null);
  const [zipcodeOptions, setZipcodeOptions] = useState<ZipcodeOptionEntity[]>(
    [],
  );

  const zipcodeTextFieldRef = useRef<HTMLInputElement | null>(null);
  const [cityAndStatesOptions, setCityAndStatesOptions] = useState<
    CityAndStateOptionEntity[]
  >([]);

  // Use a single abort controller for city/state and zipcode autofill
  // to prevent responses from overwriting each other
  const abortController = useRef(new AbortController());

  const autofill = useGlobalStore((state) => state.mapboxAutofill);
  const session = useGlobalStore((state) => state.mapboxSession);
  const [addressInputValue, setAddressInputValue] = useState<AddressFormField>(
    currentOption?.address ?? {
      line1: '',
      line2: '',
      zip: '',
      city: '',
      state: '',
      isLocal: false,
      uuid: v4(),
    },
  );

  const [addressInputValueDebounced] = useDebounce(addressInputValue, 500);
  const [searchSuggestions, setSearchSuggestions] = useState<
    Array<{
      label: string;
      value: string;
      address: AddressFormField;
    }>
  >([]);

  const getAutofill = async (searchQuery: string) => {
    const apiResponse = await autofill.suggest(searchQuery, session);
    const { suggestions } = apiResponse;
    return suggestions;
  };

  // When currentOption (the parent state) changes, we want to update the
  // addressInputValue (this component's local state) accordingly.
  // We only want to update the addressInputValue if the address has actually changed,
  // otherwise we risk overwriting local changes with old parent state (for example,
  // calling handleChange by autofilling a zip code then immediately editing another field
  // would cause the local change to be overwritten when this effect runs).
  // The isNil check and the missing dependency reduce the number of extra
  // times this effect runs (yes, there are some deeper issues with this code).
  useEffect(() => {
    if (
      !isNil(currentOption) &&
      (currentOption.address.line1 !== addressInputValue.line1 ||
        currentOption.address.name !== addressInputValue.name ||
        currentOption.address.latitude !== addressInputValue.latitude ||
        currentOption.address.longitude !== addressInputValue.longitude)
    ) {
      setAddressInputValue(currentOption.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOption]);

  // onBlur is what actually updates the parent state (currentOption)
  // using handleChange. We need to call handleChange exactly once
  // whenever the state changes to avoid overwriting new values with old ones.
  // This means that we shouldn't call onBlur if we're autofilling city/state
  // or zip code call onBlur, since handleAutofillChange will call handleChange
  // again with the new autofilled value.
  const onBlur = () => {
    // handleChange updates currentOption, which may be initially set based on
    // a network call. We check to see if any fields have been changed
    // before calling handleChange so that we don't overwrite the value
    // retrieved from the network call with just an empty address.
    if (!addressIsEmpty(addressInputValue)) {
      handleChange({
        address: { ...addressInputValue },
        isAutofillChange: false,
      });
    }
  };

  const handleAutofillChange = (
    changes: { city: string; state: string } | { zip: string },
  ) => {
    const newAddressInputValue: AddressFormField = {
      ...addressInputValue,
      uuid: addressInputValue?.uuid ?? v4(),
      ...changes,
      isLocal: true,
    };
    if (pageMode === PageMode.CREATE) {
      dispatch(addAddress(newAddressInputValue));
    } else {
      dispatch(
        updateAddress({
          id: newAddressInputValue.uuid,
          changes: {
            ...changes,
            isLocal: false,
          },
        }),
      );
    }
    setAddressInputValue(newAddressInputValue);
    handleChange({
      address: newAddressInputValue,
      isAutofillChange: false,
    });
  };

  const onZipcodeBlur = async () => {
    if (
      !isNilOrEmptyString(addressInputValue?.city?.trim()) ||
      !isNilOrEmptyString(addressInputValue?.state?.trim())
    ) {
      onBlur();
      return;
    }
    const zipcode = addressInputValue?.zip;
    if (!isNilOrEmptyString(zipcode)) {
      abortController.current.abort();
      abortController.current = new AbortController();
      const cityAndStatesFromZipcodeResponse = await getCityAndStateFromZipcode(
        {
          variables: { getCityAndStateFromZipcodeInput: { zipcode } },
          context: { fetchOptions: { signal: abortController.current.signal } },
        },
      );
      const cityAndStates =
        cityAndStatesFromZipcodeResponse.data?.getCityAndStateOptionsFromZipcode
          .citiesAndStates ?? [];
      if (cityAndStates.length > 1) {
        setCityAndStatesOptions(cityAndStates);
      } else {
        const city = cityAndStates?.[0]?.city;
        const state = cityAndStates?.[0]?.state;
        if (isNilOrEmptyString(city) || isNilOrEmptyString(state)) {
          onBlur();
          return;
        }
        handleAutofillChange({ city, state });
      }
    }
  };

  const onCityOrStateBlur = async () => {
    if (!isNilOrEmptyString(addressInputValue?.zip?.trim())) {
      onBlur();
      return;
    }
    const { city, state } = addressInputValue;
    if (!isNilOrEmptyString(city) && !isNilOrEmptyString(state)) {
      abortController.current.abort();
      abortController.current = new AbortController();
      const zipcodesFromCityAndStateResponse = await getZipcodeFromCityAndState(
        {
          variables: { getZipcodeFromCityAndStateInput: { city, state } },
          context: { fetchOptions: { signal: abortController.current.signal } },
        },
      );
      const zipcodes =
        zipcodesFromCityAndStateResponse.data?.getZipcodeOptionsFromCityAndState
          .zipcodes ?? [];
      if (zipcodes.length > 1) {
        setZipcodeOptions(zipcodes);
      } else {
        const zipcode = zipcodes?.[0]?.zipcode;
        if (isNilOrEmptyString(zipcode)) {
          onBlur();
          return;
        }
        handleAutofillChange({ zip: zipcode });
      }
    }
  };

  const addSuggestionsToOptions = async () => {
    try {
      if (addressInputValue.line1 !== '') {
        const suggestions = await getAutofill(addressInputValue?.line1 ?? '');
        const searchAddressLabelAndValues = suggestions
          .filter((suggestion) => !isNil(suggestion.full_address))
          .map((suggestion) => {
            const addressUuid = v4();
            return {
              label: suggestion.full_address ?? '',
              value: addressUuid,
              address: {
                name: nameInputValue,
                line1: suggestion.address_line1 ?? '',
                city: suggestion.address_level2 ?? '',
                uuid: addressUuid,
                zip: suggestion.postcode ?? '',
                state: suggestion.address_level1 ?? '',
                country: suggestion.country ?? '',
                driverInstructions: '',
                line2: '',
                receivingHoursStart: '',
                receivingHoursEnd: '',
                latitude: undefined,
                longitude: undefined,
                isFreeForm: false,
                isLocal: false,
              },
            };
          });
        setSearchSuggestions(searchAddressLabelAndValues);
      }
    } catch (error_) {
      // To-do: Log error
      // eslint-disable-next-line no-console
      console.log(String(error_));
    }
  };

  useEffect(() => {
    void addSuggestionsToOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressInputValueDebounced]);

  const {
    addressLine1TestId,
    addressLine2TestId,
    addressCityTestId,
    addressStateTestId,
    addressZipTestId,
  } = testIds ?? {};

  const zipcodeOptionsComponent = (
    <Popover
      id="city-and-state-field"
      anchorEl={stateTextFieldRef.current}
      open={zipcodeOptions.length > 0 && cityAndStatesOptions.length === 0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => {
        setZipcodeOptions([]);
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>Zipcode</TableCell>
            <TableCell />
          </TableHead>
          {zipcodeOptions.map(({ zipcode }) => (
            <TableRow key={zipcode}>
              <TableCell>{zipcode}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    handleAutofillChange({ zip: zipcode });
                    setZipcodeOptions([]);
                  }}
                >
                  Choose
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Popover>
  );

  const cityAndStatesOptionsComponent = (
    <Popover
      id="zipcode-field"
      anchorEl={zipcodeTextFieldRef.current}
      open={cityAndStatesOptions.length > 0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => {
        setCityAndStatesOptions([]);
      }}
    >
      <TableContainer>
        <Table data-testid={ORDER_PAGE_CITY_AND_STATE_OPTIONS_TABLE_TEST_ID}>
          <TableHead>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell />
          </TableHead>
          {cityAndStatesOptions.map((cityAndStateOption) => (
            <TableRow
              key={`${cityAndStateOption.city}${cityAndStateOption.state}`}
            >
              <TableCell>{cityAndStateOption.city}</TableCell>
              <TableCell>{cityAndStateOption.state}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    handleAutofillChange({
                      city: cityAndStateOption.city,
                      state: cityAndStateOption.state,
                    });
                    setCityAndStatesOptions([]);
                  }}
                >
                  Choose
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Popover>
  );

  if (newStyling === true) {
    return (
      <Grid
        item
        md={12}
        sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
        data-testid={AUTOFILL_COMPONENT_TEST_ID}
      >
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <Grid item md={8.5} sx={{ flexGrow: 1 }}>
            <AutocompleteFuzzy
              disabled={disabled}
              value={{
                label: addressInputValue.line1 ?? '',
                value: addressInputValue.uuid,
                address: addressInputValue,
              }}
              inputValue={addressInputValue.line1 ?? ''}
              freeSolo={freeSolo}
              matchSortOptions={{ keys: ['label'] }}
              options={searchSuggestions}
              isOptionEqualToValue={(option, value) => {
                const optionString = `${option.address.line1}${option.address.line2}${option.address.city}${option.address.state}${option.address.zip}${option.address.country}`;
                const valueString = `${value.address.line1}${value.address.line2}${value.address.city}${value.address.state}${value.address.zip}${value.address.country}`;
                return optionString === valueString;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.address.uuid}>
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="address"
                  size="small"
                  label="Address line 1"
                  required={
                    required && ADDRESS_REQUIRED_FIELDS.includes('line1')
                  }
                  inputProps={{
                    ...params.inputProps,
                    ...uppercaseInputProps,
                    'data-testid': addressLine1TestId,
                  }}
                  type="text"
                  value={addressInputValue.line1 ?? ''}
                  error={!isNil(error)}
                  helperText={error}
                  onChange={(e) => {
                    setAddressInputValue({
                      ...addressInputValue,
                      line1: e.target.value,
                    });
                  }}
                />
              )}
              onBlur={onBlur}
              onChange={(e, option) => {
                if (!isNil(option) && typeof option !== 'string') {
                  handleChange({
                    address: option?.address,
                    isAutofillChange: true,
                  });
                  setAddressInputValue({
                    ...option?.address,
                  });
                }
              }}
            />
          </Grid>
          <Grid item md={3}>
            {!hideLine2 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <TextField
                  disabled={disabled}
                  label="Apt, suite, etc."
                  required={
                    required && ADDRESS_REQUIRED_FIELDS.includes('line2')
                  }
                  value={addressInputValue.line2 ?? ''}
                  inputProps={{
                    ...uppercaseInputProps,
                    'data-testid': addressLine2TestId,
                  }}
                  size="small"
                  error={!isNil(error)}
                  onBlur={onBlur}
                  onChange={(e) => {
                    setAddressInputValue({
                      ...addressInputValue,
                      line2: e.target.value,
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <TextField
            disabled={disabled}
            label="City"
            required={required && ADDRESS_REQUIRED_FIELDS.includes('city')}
            value={addressInputValue.city ?? ''}
            inputProps={{
              'data-testid': addressCityTestId,
            }}
            size="small"
            sx={{ flexGrow: 1 }}
            error={!isNil(error)}
            onBlur={onCityOrStateBlur}
            onChange={(e) => {
              const input =
                useAllCaps === true
                  ? e.target.value.toUpperCase()
                  : e.target.value;
              setAddressInputValue({
                ...addressInputValue,
                city: input,
              });
            }}
          />
          <TextField
            ref={stateTextFieldRef}
            disabled={disabled}
            value={addressInputValue.state ?? ''}
            label="State"
            required={required && ADDRESS_REQUIRED_FIELDS.includes('state')}
            inputProps={{
              'data-testid': addressStateTestId,
              maxLength: 2,
            }}
            size="small"
            sx={{ maxWidth: '200px' }}
            error={!isNil(error)}
            onBlur={onCityOrStateBlur}
            onChange={(e) => {
              setAddressInputValue({
                ...addressInputValue,
                state: e.target.value.toUpperCase(),
              });
            }}
          />
          <TextField
            ref={zipcodeTextFieldRef}
            disabled={disabled}
            value={addressInputValue?.zip ?? ''}
            inputProps={{
              ...uppercaseInputProps,
              'data-testid': addressZipTestId,
            }}
            label="Zip code"
            required={required && ADDRESS_REQUIRED_FIELDS.includes('zip')}
            size="small"
            sx={{ maxWidth: '115px' }}
            error={!isNil(error)}
            onBlur={onZipcodeBlur}
            onChange={(e) => {
              setAddressInputValue({
                ...addressInputValue,
                zip: e.target.value,
              });
            }}
          />
        </Grid>
        {cityAndStatesOptionsComponent}
        {zipcodeOptionsComponent}
      </Grid>
    );
  }

  return (
    <>
      <Typography sx={{ textAlign: 'center', mb: '5px' }}>
        Address line 1
      </Typography>
      <AutocompleteFuzzy
        disabled={disabled}
        value={{
          label: addressInputValue.line1 ?? '',
          value: addressInputValue.uuid,
          address: addressInputValue,
        }}
        inputValue={addressInputValue.line1}
        freeSolo={freeSolo}
        options={searchSuggestions}
        matchSortOptions={{ keys: ['label'] }}
        filterOptions={(x) => {
          return x;
        }}
        isOptionEqualToValue={(option, value) => {
          const optionString = `${option.address.line1}${option.address.line2}${option.address.city}${option.address.state}${option.address.zip}${option.address.country}`;
          const valueString = `${value.address.line1}${value.address.line2}${value.address.city}${value.address.state}${value.address.zip}${value.address.country}`;
          return optionString === valueString;
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.address.uuid}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="address"
            size="small"
            placeholder="Address line 1"
            inputProps={{
              'data-testid': addressLine1TestId,
            }}
            type="text"
            value={addressInputValue.line1}
            error={!isNil(error)}
            helperText={error}
            sx={{
              backgroundColor: 'white',
              width: '100%',

              '& .MuiFormHelperText-root': {
                margin: 0,
                paddingLeft: '14px',
                paddingTop: '4px',
              },
            }}
            onChange={(e) => {
              const input =
                useAllCaps === true
                  ? e.target.value.toUpperCase()
                  : e.target.value;
              setAddressInputValue({
                ...addressInputValue,
                line1: input,
              });
            }}
          />
        )}
        onBlur={onBlur}
        onChange={(e, option) => {
          if (!isNil(option) && typeof option !== 'string') {
            handleChange({
              address: option?.address,
              isAutofillChange: true,
            });
            setAddressInputValue({
              ...option?.address,
            });
          }
        }}
      />
      {!hideLine2 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            gap: '5px',
            mt: '10px',
          }}
        >
          <Typography>Apt, suite, etc</Typography>
          <TextField
            disabled={disabled}
            placeholder="Apt, suite, etc."
            value={addressInputValue.line2}
            inputProps={{
              ...uppercaseInputProps,
              'data-testid': addressLine2TestId,
            }}
            size="small"
            sx={{ backgroundColor: 'white', width: '100%' }}
            onBlur={onBlur}
            onChange={(e) => {
              const input =
                useAllCaps === true
                  ? e.target.value.toUpperCase()
                  : e.target.value;
              setAddressInputValue({
                ...addressInputValue,
                line2: input,
              });
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          alignItems: 'center',
          gap: '5px',
          mt: '10px',
        }}
      >
        <Box>
          <Typography>City</Typography>
          <TextField
            disabled={disabled}
            placeholder="City"
            value={addressInputValue.city}
            InputProps={{
              ...uppercaseInputProps,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-testid': addressCityTestId,
            }}
            size="small"
            sx={{ backgroundColor: 'white' }}
            onBlur={onCityOrStateBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                void onCityOrStateBlur();
              }
            }}
            onChange={(e) => {
              setAddressInputValue({
                ...addressInputValue,
                city: e.target.value,
              });
            }}
          />
        </Box>
        <Box>
          <Typography>State</Typography>
          <TextField
            ref={stateTextFieldRef}
            InputProps={{
              ref: stateTextFieldRef,
              ...uppercaseInputProps,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-testid': addressStateTestId,
            }}
            disabled={disabled}
            value={addressInputValue.state}
            placeholder="State"
            size="small"
            sx={{ backgroundColor: 'white' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                void onCityOrStateBlur();
              }
            }}
            onBlur={onCityOrStateBlur}
            onChange={(e) => {
              setAddressInputValue({
                ...addressInputValue,
                state: e.target.value,
              });
            }}
          />
        </Box>
        <Box>
          <Typography>Zipcode</Typography>
          <TextField
            ref={zipcodeTextFieldRef}
            InputProps={{
              ref: zipcodeTextFieldRef,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              'data-testid': addressZipTestId,
            }}
            aria-describedby="zipcode-field"
            disabled={disabled}
            value={addressInputValue?.zip}
            placeholder="Zipcode"
            size="small"
            sx={{ backgroundColor: 'white' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                void onZipcodeBlur();
              }
            }}
            onBlur={onZipcodeBlur}
            onChange={(e) => {
              setAddressInputValue({
                ...addressInputValue,
                zip: e.target.value,
              });
            }}
          />
          {cityAndStatesOptionsComponent}
          {zipcodeOptionsComponent}
        </Box>
      </Box>
    </>
  );
};

export default AddressAutofill;
