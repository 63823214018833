import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { shallow } from 'zustand/vanilla/shallow';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../../common/react-hooks/use-me-as-third-party-user';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import CustomerPortalPendingOrdersTableRow from './customer-portal-pending-order-table-row';
import { useCustomerPortalPendingOrders } from './hooks/use-customer-portal-pending-orders';

const PAGE_SIZE = 10;

const CustomerPortalPendingOrdersTable = () => {
  const [page, setPage] = useState<number>(0);

  const { edges, pageInfo, totalCount, loading, refetch } =
    useCustomerPortalPendingOrders({
      first: PAGE_SIZE,
    });

  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const { loading: thirdPartyUserLoading } = useMeAsThirdPartyUser();
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );
  // Also wait for third party user to load so we can check its feature flags
  if (statsigLoading || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  if (!ffCustomerPortalOrderEntry) {
    return <Navigate to="/customer-portal/orders" />;
  }

  const totalPages = Math.ceil((totalCount ?? 0) / PAGE_SIZE);

  const paginationButtons = (
    <>
      <Button
        disabled={loading || page === 0}
        variant="contained"
        startIcon={<ArrowLeft />}
        onClick={async () => {
          await refetch({
            last: PAGE_SIZE,
            before: pageInfo?.startCursor ?? undefined,
          });
          setPage(page - 1);
        }}
      >
        Previous page
      </Button>
      <Button
        disabled={loading || page + 1 === totalPages}
        variant="contained"
        endIcon={<ArrowRight />}
        onClick={async () => {
          await refetch({
            first: PAGE_SIZE,
            after: pageInfo?.endCursor ?? undefined,
          });
          setPage(page + 1);
        }}
      >
        Next page
      </Button>
    </>
  );

  return (
    <Stack gap={1} height="100%">
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Card>
            <TableContainer sx={{ height: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>HAWB</TableCell>
                    <TableCell>Carrier</TableCell>
                    <TableCell>Submitted At</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {edges?.map(({ node: order }) => (
                    <CustomerPortalPendingOrdersTableRow
                      key={order.uuid}
                      order={order}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Stack direction="row" justifyContent="center" gap={2}>
            {paginationButtons}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CustomerPortalPendingOrdersTable;
