import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { useCustomerPortalQuotes } from '../customer-portal-pending-orders/hooks/use-customer-portal-quotes';
import CustomerPortalQuoteTableRow from './customer-portal-quote-table-row';
import { isNil } from 'lodash';

const PAGE_SIZE = 10;

const CustomerPortalQuotesTable = () => {
  const [page, setPage] = useState<number>(0);

  const { edges, pageInfo, totalCount, loading, refetch } =
    useCustomerPortalQuotes({
      first: PAGE_SIZE,
    });

  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );
  if (!ffCustomerPortalQuoteEntry) {
    return <Navigate to="/customer-portal/quotes" />;
  }

  const paginationButtons = (
    <>
      <Button
        disabled={loading || page === 0}
        variant="contained"
        startIcon={<ArrowLeft />}
        onClick={async () => {
          await refetch({
            last: PAGE_SIZE,
            before: pageInfo?.startCursor ?? undefined,
          });
          setPage(page - 1);
        }}
      >
        Previous page
      </Button>
      <Button
        disabled={loading || pageInfo?.hasNextPage === false}
        variant="contained"
        endIcon={<ArrowRight />}
        onClick={async () => {
          await refetch({
            first: PAGE_SIZE,
            after: pageInfo?.endCursor ?? undefined,
          });
          setPage(page + 1);
        }}
      >
        Next page
      </Button>
    </>
  );

  return (
    <Stack gap={1} height="100%">
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Card>
            <TableContainer sx={{ height: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Quote Number</TableCell>
                    <TableCell>Carrier</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {edges?.map(({ node: quote }) => (
                    <CustomerPortalQuoteTableRow
                      key={quote.uuid}
                      quote={quote}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Stack direction="row" justifyContent="center" gap={2}>
            {paginationButtons}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CustomerPortalQuotesTable;
