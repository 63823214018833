import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useState, useMemo } from 'react';
import useDrivers from '../../../../../../common/react-hooks/use-drivers';
import useMe from '../../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../../common/react-hooks/use-terminals';
import { type DriverOption } from '../../../../../../common/types';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { getDriverIdentifier } from '../../../../../dispatch/utils';
import PalletModal from '../../../../../../pallet-ui/modal/pallet-modal';
import { DriverType } from '../../../../../../generated/graphql';
import { filterNotNil } from 'shared/array';

const AddDriverForDriverSettlementModal = ({
  isOpen,
  setIsOpen,
  disabledDriverUuids,
  hideDriversIneligibleForSettlement = false,
  onConfirm,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly disabledDriverUuids?: string[];
  readonly hideDriversIneligibleForSettlement?: boolean;
  readonly onConfirm: (driverUuid: string) => void;
}) => {
  const { companyConfiguration } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getDriverName, drivers, driversEligibleForSettlement } = useDrivers();
  const [selectedDriverOption, setSelectedDriverOption] = useState<
    DriverOption | undefined
  >(undefined);

  const driverOptions = useMemo(
    () =>
      (hideDriversIneligibleForSettlement
        ? driversEligibleForSettlement
        : drivers
      )
        // toSorted mutates a copy of the readonly array
        .toSorted((a, b) =>
          getDriverIdentifier(
            a,
            companyConfiguration?.useDriverNumberForDispatch,
          )?.localeCompare(
            getDriverIdentifier(
              b,
              companyConfiguration?.useDriverNumberForDispatch,
            ),
          ),
        )
        .map((driver) => ({
          value: driver.uuid,
          label: `${
            isEmpty(driver.driverReferenceNumber)
              ? ''
              : `${driver.driverReferenceNumber} - `
          }${getDriverName(driver.uuid)}`,
          terminal:
            driver.terminal?.name ??
            (terminalsEnabled ? 'All terminals' : undefined),
          disabled: disabledDriverUuids?.includes(driver.uuid) ?? false,
        })),
    [
      drivers,
      driversEligibleForSettlement,
      companyConfiguration?.useDriverNumberForDispatch,
      terminalsEnabled,
      disabledDriverUuids,
      getDriverName,
      hideDriversIneligibleForSettlement,
    ],
  );

  const onClose = () => {
    setSelectedDriverOption(undefined);
    setIsOpen(false);
  };

  return (
    <PalletModal
      open={isOpen}
      title="Add driver"
      pinnedElements={{
        bottomRight: (
          <Button
            variant="contained"
            disabled={isNil(selectedDriverOption?.value)}
            onClick={() => {
              const driverUuid = selectedDriverOption?.value;
              if (!isNil(driverUuid)) {
                onConfirm(driverUuid);
              }
              onClose();
            }}
          >
            Add
          </Button>
        ),
      }}
      onClose={onClose}
    >
      <Stack spacing={1} width="300px">
        <AutocompleteFuzzy
          size="small"
          sx={{
            width: '100%',
          }}
          value={selectedDriverOption}
          options={driverOptions}
          matchSortOptions={{ keys: ['label', 'terminal'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <MenuItem
                {...props}
                key={option.value}
                disabled={option.disabled ?? false}
              >
                <Stack>
                  <Typography fontSize="14px">{option.label}</Typography>
                  {!isNil(option.terminal) && (
                    <Typography variant="caption" color="text.secondary">
                      {option.terminal}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            );
          }}
          onChange={(event, option) => {
            setSelectedDriverOption(option ?? undefined);
            event.stopPropagation();
          }}
        />
      </Stack>
    </PalletModal>
  );
};

export default AddDriverForDriverSettlementModal;
