import { NetworkStatus } from '@apollo/client';
import SkinnyHamburger from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import { useLineHaulManifestsQuery } from '../../../../generated/graphql';
import PalletDatePicker from '../../../../pallet-ui/date-picker/pallet-date-picker';
import theme from '../../../../theme';
import CreateManifestsButton from './create-manifests-button/create-manifests-button';
import ManifestsBulkUpdate from './manifest-bulk-update';
import ManifestDetails from './manifest-details/manifest-details';
import ManifestLaneGroups from './manifest-lane-groups';
import { shallow } from 'zustand/shallow';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';

type LayoutProps = PropsWithChildren<{
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
  readonly manifestIds?: string[];
  readonly selectedManifestIds: Set<string>;
  readonly isRefetching: boolean;
  readonly errors: string[];
  readonly clearErrors: () => void;
}>;
const Layout = ({
  children,
  setSelectedManifestIds,
  manifestIds,
  selectedManifestIds,
  isRefetching,
  errors,
  clearErrors,
}: LayoutProps) => {
  const [planningDate, setPlanningDate] = useLineHaulDispatchStore(
    (state) => [state.planningDate, state.setPlanningDate],
    shallow,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const areAllManifestsSelected =
    !isNil(manifestIds) &&
    manifestIds.length > 0 &&
    selectedManifestIds.size === manifestIds.length;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    if (!isNil(manifestIds)) {
      if (areAllManifestsSelected) {
        setSelectedManifestIds(new Set());
      } else {
        setSelectedManifestIds(new Set(manifestIds));
      }
    }
    handleClose();
  };

  const onPlanningDateChange = (newDate: Dayjs | null) => {
    if (!isNil(newDate) && newDate.isValid()) {
      setSelectedManifestIds(new Set());
      setPlanningDate(newDate);
    }
  };

  return (
    <Stack gap={1} p={1} pb={0} position="relative" height="100%">
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      {selectedManifestIds.size > 0 && (
        <ManifestsBulkUpdate
          selectedManifestIds={selectedManifestIds}
          setSelectedManifestIds={setSelectedManifestIds}
          planningDate={planningDate}
        />
      )}
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography
            color={theme.palette.concreteGrey[70]}
            fontWeight="400"
            fontSize="14px"
          >
            Planning
          </Typography>
          <PalletDatePicker
            showArrows
            value={planningDate}
            onChange={onPlanningDateChange}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {isRefetching && <CircularProgress size={20} />}
          <CreateManifestsButton initialDate={planningDate} />
          <IconButton onClick={handleMenuClick}>
            <SkinnyHamburger />
          </IconButton>
        </Stack>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={handleSelectAll}>
            {areAllManifestsSelected
              ? 'Unselect all manifests'
              : 'Select all manifests'}
          </MenuItem>
        </Menu>
      </Stack>
      <Box overflow="auto">{children}</Box>
    </Stack>
  );
};

const LineHaulManifestsSection = () => {
  const [planningDate] = useLineHaulDispatchStore(
    (state) => [state.planningDate],
    shallow,
  );
  const [openedManifestUuid, setOpenedManifestUuid] = useState<string | null>(
    null,
  );
  const [selectedManifestIds, setSelectedManifestIds] = useState<Set<string>>(
    new Set(),
  );

  const { errors, onError, clearErrors } = useErrors();
  const { data, networkStatus } = useLineHaulManifestsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { date: planningDate },
    onError,
  });

  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isLoading = networkStatus === NetworkStatus.loading;

  const manifestIds = useMemo(() => {
    if (isNil(data?.lineHaulManifests.lineHaulManifests)) return [];
    return data.lineHaulManifests.lineHaulManifests.map((m) => m.uuid);
  }, [data?.lineHaulManifests.lineHaulManifests]);

  const sharedLayoutProps: LayoutProps = {
    setSelectedManifestIds,
    isRefetching,
    selectedManifestIds,
    errors,
    clearErrors,
  };

  if (isLoading) {
    return (
      <Layout {...sharedLayoutProps}>
        <CircularProgress />
      </Layout>
    );
  }

  if (data?.lineHaulManifests.lineHaulManifests.length === 0) {
    return (
      <Layout {...sharedLayoutProps}>
        <div>No manifests found</div>
      </Layout>
    );
  }

  const openedManifest = data?.lineHaulManifests.lineHaulManifests.find(
    (m) => m.uuid === openedManifestUuid,
  );

  if (!isNil(openedManifest)) {
    return (
      <ManifestDetails
        manifest={openedManifest}
        setOpenedManifestUuid={setOpenedManifestUuid}
        planningDate={planningDate}
        isRefetching={isRefetching}
        setSelectedManifestIds={setSelectedManifestIds}
      />
    );
  }

  return (
    <Layout {...sharedLayoutProps} manifestIds={manifestIds}>
      <ManifestLaneGroups
        manifests={data?.lineHaulManifests.lineHaulManifests ?? []}
        selectedManifestIds={selectedManifestIds}
        setSelectedManifestIds={setSelectedManifestIds}
        setOpenedManifestUuid={setOpenedManifestUuid}
        planningDate={planningDate}
      />
    </Layout>
  );
};

export default LineHaulManifestsSection;
