import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { assertNotNil } from 'shared/optional';
import { shallow } from 'zustand/shallow';
import {
  OrganizationForAddressBookDocument,
  type OrganizationForAddressBookQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import PalletButton from '../../../../pallet-ui/button/pallet-button';

type OrderAddressesConfigProps = {
  readonly organizationUuid: string;
  readonly addresses: OrganizationForAddressBookQuery['organization']['addresses'];
};

// Add this type definition near the top of the file
type AddressFormData = {
  name: string;
  zip: string;
  city: string;
  state: string;
  line1: string;
  line2: string;
  country: string;
};

const OrderAddressesConfig = ({
  organizationUuid,
  addresses,
}: OrderAddressesConfigProps) => {
  const firstAddress = addresses[0];
  const [selectedAddressUuid, setSelectedAddressUuid] = useState<string | null>(
    firstAddress?.uuid ?? null,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<AddressFormData>({
    mode: 'onChange',
    defaultValues: {
      name: firstAddress?.name ?? '',
      line1: firstAddress?.line1 ?? '',
      line2: firstAddress?.line2 ?? '',
      city: firstAddress?.city ?? '',
      state: firstAddress?.state ?? '',
      zip: firstAddress?.zip ?? '',
      country: firstAddress?.country ?? '',
    },
  });

  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);

  const theme = useTheme();
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [createAddressMutation, { loading: createAddressLoading }] =
    useCreateAddressMutation({
      onCompleted: () => {
        setSuccessMessage('Address created successfully');
        setShowSuccessMessage(true);
        setIsAddingNewAddress(false);
        selectAddress(firstAddress?.uuid ?? null);
      },
      onError: () => {
        setErrorMessage('Failed to create address');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationForAddressBookDocument],
    });
  const [updateAddressMutation, { loading: updateAddressLoading }] =
    useUpdateAddressMutation({
      onCompleted: (data) => {
        setSuccessMessage('Address updated successfully');
        setShowSuccessMessage(true);
        // this will reset the form so isDirty gets reset
        selectAddress(data.updateAddress.uuid);
      },
      onError: () => {
        setErrorMessage('Failed to update address');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationForAddressBookDocument],
    });

  const onSubmit = async (formData: AddressFormData) => {
    if (isAddingNewAddress) {
      await createAddressMutation({
        variables: {
          input: {
            contactUuid: organizationUuid,
            addressCreateInput: {
              name: formData.name,
              line1: formData.line1,
              line2: formData.line2,
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
              country: formData.country,
            },
          },
        },
      });
    } else {
      assertNotNil(selectedAddressUuid);
      await updateAddressMutation({
        variables: {
          input: {
            addressUpdateInput: {
              uuid: selectedAddressUuid,
              name: formData.name,
              line1: formData.line1,
              line2: formData.line2,
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
              country: formData.country,
            },
          },
        },
      });
    }
  };

  const selectAddress = (addressUuid: string | null) => {
    if (isNil(addressUuid)) {
      setSelectedAddressUuid(null);
      reset({
        name: '',
        zip: '',
        city: '',
        state: '',
        line1: '',
        line2: '',
        country: '',
      });
      return;
    }
    const address = addresses.find((a) => a.uuid === addressUuid);
    if (!isNil(address)) {
      setSelectedAddressUuid(addressUuid);
      reset({
        name: address.name,
        zip: address.zip,
        city: address.city,
        state: address.state,
        line1: address.line1,
        line2: address.line2 ?? '',
        country: address.country,
      });
    }
  };

  const handleNewAddress = () => {
    setIsAddingNewAddress(true);
    selectAddress(null);
  };

  const handleCancel = () => {
    setIsAddingNewAddress(false);
    // Reset to first address or null if no addresses
    selectAddress(firstAddress?.uuid ?? null);
  };

  return (
    <Stack gap={1}>
      <Typography variant="h6">Order addresses</Typography>
      <Stack direction="row" spacing={2} width="100%">
        <List
          sx={{
            flex: 1,
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            p: 0,
            minHeight: '200px',
          }}
        >
          <Box sx={{ p: 1 }}>
            <Button
              variant="outlined"
              disabled={isAddingNewAddress}
              sx={{ width: '100%' }}
              startIcon={<AddIcon />}
              onClick={handleNewAddress}
            >
              New address
            </Button>
          </Box>
          {isAddingNewAddress && (
            <ListItem disablePadding>
              <ListItemButton selected>
                <ListItemText
                  primary="New address"
                  sx={{ color: theme.palette.signalOrange[50] }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {addresses.map((address) => (
            <ListItem key={address.uuid} disablePadding>
              <ListItemButton
                selected={selectedAddressUuid === address.uuid}
                onClick={() => {
                  if (!isAddingNewAddress) {
                    selectAddress(address.uuid);
                  }
                }}
              >
                <ListItemText primary={address.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Card
          sx={{
            flex: 3,
            p: 2,
            boxShadow: 'none',
            border: '1px solid #E0E0E0',
          }}
        >
          {isNil(firstAddress) && !isAddingNewAddress ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography color={theme.palette.concreteGrey[40]}>
                No addresses configured
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <FormControl fullWidth error={!isNil(errors.name)}>
                {/* <InputLabel>Name</InputLabel> */}
                <TextField
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  {...register('name', {
                    required: 'Name is required',
                    minLength: {
                      value: 1,
                      message: 'Name must be at least 1 character',
                    },
                  })}
                  required
                />
                {!isNil(errors.name) && (
                  <FormHelperText error>{errors.name.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label="Line 1"
                  InputLabelProps={{ shrink: true }}
                  {...register('line1', {
                    required: 'Line 1 is required',
                    minLength: {
                      value: 1,
                      message: 'Line 1 must be at least 1 character',
                    },
                  })}
                  required
                />
                {!isNil(errors.line1) && (
                  <FormHelperText error>{errors.line1.message}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  label="Line 2"
                  InputLabelProps={{ shrink: true }}
                  {...register('line2')}
                />
              </FormControl>

              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <TextField
                    label="City"
                    InputLabelProps={{ shrink: true }}
                    {...register('city', {
                      required: 'City is required',
                      minLength: {
                        value: 1,
                        message: 'City must be at least 1 character',
                      },
                    })}
                    required
                  />
                  {!isNil(errors.city) && (
                    <FormHelperText error>{errors.city.message}</FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="State"
                    InputLabelProps={{ shrink: true }}
                    {...register('state', {
                      required: 'State is required',
                      minLength: {
                        value: 1,
                        message: 'State must be at least 1 character',
                      },
                    })}
                    required
                  />
                  {!isNil(errors.state) && (
                    <FormHelperText error>
                      {errors.state.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Zip"
                    InputLabelProps={{ shrink: true }}
                    {...register('zip', {
                      required: 'Zip is required',
                      minLength: {
                        value: 1,
                        message: 'Zip must be at least 1 character',
                      },
                    })}
                    required
                  />
                  {!isNil(errors.zip) && (
                    <FormHelperText error>{errors.zip.message}</FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Country"
                    InputLabelProps={{ shrink: true }}
                    {...register('country', {
                      required: 'Country is required',
                      minLength: {
                        value: 1,
                        message: 'Country must be at least 1 character',
                      },
                    })}
                    required
                  />
                  {!isNil(errors.country) && (
                    <FormHelperText error>
                      {errors.country.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                {isAddingNewAddress && (
                  <PalletButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </PalletButton>
                )}
                <PalletButton
                  variant="contained"
                  color="primary"
                  disabled={!isDirty}
                  loading={
                    isSubmitting || createAddressLoading || updateAddressLoading
                  }
                  onClick={handleSubmit(onSubmit)}
                >
                  {isAddingNewAddress ? 'Add address' : 'Save changes'}
                </PalletButton>
              </Stack>
            </Stack>
          )}
        </Card>
      </Stack>
    </Stack>
  );
};

export default OrderAddressesConfig;
