import { TableRow, useTheme, TableCell } from '@mui/material';
import currency from 'currency.js';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { safeAdd } from 'shared/math';
import { ShipmentType } from '../../../../../../../generated/graphql';
import { type OrderFormValues } from '../../../forms/types';
import { useShouldShowOrderChargesSection } from '../../../hooks/use-expected-order-components';
import DriverSettlementCustomChargeRow from './driver-settlement-custom-charge-row';
import DriverSettlementFreightChargeRow from './driver-settlement-freight-charge-row';
import DriverSettlementFuelChargeRow from './driver-settlement-fuel-charge-row';
import DriversTable from './drivers-table';
import { type OrderShipmentContext } from '../../../types';

const DriverSettlementForOrderChargesTable = () => {
  const { control } = useFormContext<OrderFormValues>();
  const customCharges = useWatch({
    control,
    name: `orderChargesShipment.customCharges`,
  });
  const theme = useTheme();
  const totalSettlement =
    customCharges?.reduce(
      (prev, curr) => safeAdd(prev, curr.deductionTotal ?? 0),
      0,
    ) ?? 0;

  const { showSection } = useShouldShowOrderChargesSection();

  if (!showSection) {
    return null;
  }

  const context: OrderShipmentContext = {
    shipmentType: ShipmentType.OrderCharges,
    inSettlement: false,
  };

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell>ORDER CHARGES</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <DriverSettlementFreightChargeRow context={context} />
      <DriverSettlementFuelChargeRow context={context} />
      {customCharges?.map((cc, customChargeIdx) => (
        <DriverSettlementCustomChargeRow
          key={cc.uuid}
          context={context}
          customChargeIdx={customChargeIdx}
        />
      ))}
      <TableRow>
        <TableCell sx={{ fontWeight: 500 }}>
          Eligible Driver Settlement Total
        </TableCell>
        <TableCell />
        <TableCell>{currency(totalSettlement).format()}</TableCell>
      </TableRow>
      <DriversTable isOrderCharge idx={0} />
    </>
  );
};

export default React.memo(DriverSettlementForOrderChargesTable);
