import { Box, Button, Fade, TableCell, TableRow } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { useAllowOpenInNewTab } from '../../../common/react-hooks/use-allow-open-in-new-tab';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { formatDateWithTime } from '../../../common/utils/prettyPrintUtils';
import {
  type OrderSource,
  PermissionResource,
  type ScannedOrderFailureReason,
  ScannedOrderResultStatus,
  useUpdateScannedOrderResultStatusMutation,
} from '../../../generated/graphql';
import {
  canViewCompletedOrder,
  getStatusString,
  canDeleteScannedOrder,
} from '../utils';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { isNilOrEmptyString } from 'shared/string';

const ScannedOrderRow = ({
  billingPartyContact,
  date,
  duplicateIsDocScan,
  duplicateOrderUuid,
  duplicateOrderSource,
  failureReason,
  filename,
  orderUuid,
  pageStart,
  pageEnd,
  scannedOrderResultUuid,
  status,
  shipperBillOfLadingNumber,
  refetchScannedOrders,
}: {
  readonly billingPartyContact: string | null | undefined;
  readonly date: Date;
  readonly duplicateIsDocScan: boolean;
  readonly duplicateOrderUuid: string | null | undefined;
  readonly duplicateOrderSource: OrderSource | null | undefined;
  readonly failureReason: ScannedOrderFailureReason | null | undefined;
  readonly filename: string;
  readonly orderUuid: string | null | undefined;
  readonly shipperBillOfLadingNumber: string | null | undefined;
  readonly pageStart: number;
  readonly pageEnd: number;
  readonly scannedOrderResultUuid: string;
  readonly status: ScannedOrderResultStatus;
  readonly refetchScannedOrders: () => Promise<void>;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteScannedOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.ScannedOrders,
  );

  const { allowOpenInNewTab } = useAllowOpenInNewTab();
  const [showButtons, setShowButtons] = useState(false);
  const emptyState =
    status === ScannedOrderResultStatus.InProgress ? 'Loading' : 'None';

  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const pages =
    pageStart === pageEnd ? pageStart + 1 : `${pageStart + 1}-${pageEnd + 1}`;

  const [
    updateScannedOrderResultStatus,
    { loading: updateScannedOrderResultStatusLoading },
  ] = useUpdateScannedOrderResultStatusMutation();

  const onDeleteOrder = async () => {
    if (isNil(scannedOrderResultUuid)) {
      setErrorMessage('Error deleting scanned order');
      setShowErrorMessage(true);
      return;
    }
    try {
      await updateScannedOrderResultStatus({
        variables: {
          updateScannedOrderResultStatusInput: {
            scannedOrderResultUuid,
            status: ScannedOrderResultStatus.Rejected,
          },
        },
      });
      await refetchScannedOrders();
      setSuccessMessage('Successfully deleted scanned order');
      setShowSuccessMessage(true);
    } catch {
      setErrorMessage('Error deleting scanned order');
      setShowErrorMessage(true);
    }
  };

  return (
    <TableRow
      key={scannedOrderResultUuid}
      onMouseEnter={() => {
        setShowButtons(true);
      }}
      onMouseLeave={() => {
        setShowButtons(false);
      }}
    >
      <TableCell key="date" sx={{ border: 'none' }}>
        <Box>{formatDateWithTime(date)}</Box>
      </TableCell>
      <TableCell key="filename" sx={{ border: 'none' }}>
        <Box>
          {filename} ({pages})
        </Box>
      </TableCell>
      <TableCell key="status" sx={{ border: 'none' }}>
        <Box>{shipperBillOfLadingNumber}</Box>
      </TableCell>
      <TableCell key="billingPartyContact" sx={{ border: 'none' }}>
        <Box>{billingPartyContact ?? emptyState}</Box>
      </TableCell>
      <TableCell key="status" sx={{ border: 'none' }}>
        <Box>
          {getStatusString(status, failureReason, duplicateOrderSource)}
        </Box>
      </TableCell>
      <TableCell key="buttons" sx={{ width: '5%', border: 'none' }}>
        <Fade in={showButtons}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '5px',
              // Consistent height whether or not buttons are shown
              minHeight: '36px',
            }}
          >
            {!isNil(orderUuid) &&
              status === ScannedOrderResultStatus.InReview && (
                <Button
                  sx={{ marginRight: '5px' }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={(e) => {
                    allowOpenInNewTab(
                      e,
                      `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                    );
                  }}
                >
                  Review
                </Button>
              )}
            {status === ScannedOrderResultStatus.Failed && (
              <Button
                sx={{ marginRight: '5px', width: '135px' }}
                variant="contained"
                disabled={!canWriteScannedOrders}
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/order-entry/add?scannedOrderResultUuid=${scannedOrderResultUuid}&enterManuallyFromDocScan=true`,
                  );
                }}
              >
                Enter manually
              </Button>
            )}
            {canDeleteScannedOrder(status) && (
              <Button
                sx={{ marginRight: '5px' }}
                variant="contained"
                color="error"
                disabled={
                  !canWriteScannedOrders ||
                  updateScannedOrderResultStatusLoading ||
                  isNilOrEmptyString(scannedOrderResultUuid)
                }
                onClick={onDeleteOrder}
              >
                Delete
              </Button>
            )}
            {canViewCompletedOrder(status) && (
              <Button
                sx={{ marginRight: '5px', width: '135px' }}
                variant="contained"
                disabled={!canWriteScannedOrders}
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                  );
                }}
              >
                View
              </Button>
            )}
            {status === ScannedOrderResultStatus.Duplicate &&
              !isNil(duplicateOrderUuid) && (
                <Button
                  color="secondary"
                  sx={{
                    marginRight: '5px',
                    width: '130px',
                  }}
                  variant="contained"
                  disabled={!canWriteScannedOrders}
                  onClick={(e) => {
                    const url = duplicateIsDocScan
                      ? `/orders/?orderUuid=${duplicateOrderUuid}&isDocumentScan=true`
                      : `/orders/?orderUuid=${duplicateOrderUuid}`;
                    allowOpenInNewTab(e, url);
                  }}
                >
                  View Existing
                </Button>
              )}
            {status === ScannedOrderResultStatus.Duplicate && (
              <Button
                sx={{ marginRight: '5px', width: '130px' }}
                variant="contained"
                disabled={!canWriteScannedOrders}
                onClick={(e) => {
                  allowOpenInNewTab(
                    e,
                    `/orders/?orderUuid=${orderUuid}&isDocumentScan=true`,
                  );
                }}
              >
                View Scan
              </Button>
            )}
          </Box>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default ScannedOrderRow;
