import { isNil } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  type ReportGroupConfigurationsQuery,
  type CreateReportGroupConfigurationMutation,
  type OrderReportQuery,
  type DriverReportQuery,
  type CustomerReportQuery,
  type FindBusinessDivisionsFragment,
  type ShallowTerminalFragment,
  type TerminalReportQuery,
  type ServiceLevelReportQuery,
  type StationReportQuery,
} from '../../generated/graphql';
import {
  convertCreateDataToReportGroupConfiguration,
  convertDataToReportGroupConfigurations,
} from './report-group-configuration-converter';
import {
  convertDataToCustomerReportBucketData,
  convertDataToDriverReportBucketData,
  convertDataToOrderReportBucketData,
  convertDataToServiceLevelReportBucketData,
  convertDataToStationReportBucketData,
  convertDataToTerminalReportBucketData,
} from './reports-converter';
import {
  type CustomerReportBucketData,
  type DriverReportBucketData,
  type OrderReportBucketData,
  type ReportGroupConfiguration,
  type ServiceLevelReportBucketData,
  type StationReportBucketData,
  type TerminalReportBucketData,
} from './types/report-types';

type ReportsState = {
  businessDivisions: FindBusinessDivisionsFragment[] | undefined;
  terminals: ShallowTerminalFragment[] | undefined;
  currentReportIndex: number;
  reportGroupConfigurations: ReportGroupConfiguration[];
  orderReportData: OrderReportBucketData[];
  driverReportData: DriverReportBucketData[];
  customerReportData: CustomerReportBucketData[];
  serviceLevelReportData: ServiceLevelReportBucketData[];
  stationReportData: StationReportBucketData[];
  terminalReportData: TerminalReportBucketData[];
  isLoading: boolean;
  error: string | null;
};

type ReportsActions = {
  setCurrentReportIndex: (index: number) => void;
  setCurrentReportGroupConfiguration: (
    config: ReportGroupConfiguration | undefined,
  ) => void;
  setReportGroupConfigurations: (
    data: ReportGroupConfigurationsQuery | undefined,
  ) => void;
  createReportGroupConfiguration: (
    data: CreateReportGroupConfigurationMutation | null | undefined,
  ) => void;
  deleteReportGroupConfiguration: () => void;
  setOrderReport: (data: OrderReportQuery | undefined) => void;
  setDriverReport: (data: DriverReportQuery | undefined) => void;
  setCustomerReport: (data: CustomerReportQuery | undefined) => void;
  setServiceLevelReport: (data: ServiceLevelReportQuery | undefined) => void;
  setStationReport: (data: StationReportQuery | undefined) => void;
  setTerminalReport: (
    data: TerminalReportQuery | undefined,
    userTerminalUuid?: string,
  ) => void;
  setIsLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
};

const useReportsStore = create(
  immer<ReportsState & ReportsActions>((set, get) => ({
    businessDivisions: [],
    terminals: [],
    currentReportIndex: 0,
    reportGroupConfigurations: [],
    orderReportData: [],
    driverReportData: [],
    customerReportData: [],
    serviceLevelReportData: [],
    stationReportData: [],
    terminalReportData: [],
    isLoading: false,
    error: null,
    setCurrentReportIndex: (index: number) => {
      set((state) => {
        state.currentReportIndex = index;
      });
    },
    setCurrentReportGroupConfiguration: (
      config: ReportGroupConfiguration | undefined,
    ) => {
      set((state) => {
        if (!isNil(config)) {
          state.reportGroupConfigurations[state.currentReportIndex] = config;
        }
      });
    },
    setReportGroupConfigurations: async (
      data: ReportGroupConfigurationsQuery | undefined,
    ) => {
      if (!isNil(data)) {
        set((state) => {
          state.reportGroupConfigurations =
            convertDataToReportGroupConfigurations(data);
        });
      }
    },
    createReportGroupConfiguration: async (
      data: CreateReportGroupConfigurationMutation | null | undefined,
    ) => {
      const createData = data?.createReportGroupConfiguration;
      if (!isNil(createData)) {
        set((state) => {
          state.reportGroupConfigurations.push(
            convertCreateDataToReportGroupConfiguration(createData),
          );
          state.currentReportIndex = state.reportGroupConfigurations.length - 1;
        });
      }
    },
    deleteReportGroupConfiguration: async () => {
      const { reportGroupConfigurations, currentReportIndex } = get();
      const reportGroupConfigurationToDelete =
        reportGroupConfigurations[currentReportIndex];
      if (!isNil(reportGroupConfigurationToDelete)) {
        set((state) => {
          state.reportGroupConfigurations.splice(currentReportIndex, 1);
          state.currentReportIndex = 0;
        });
      }
    },
    setOrderReport: async (data: OrderReportQuery | undefined) => {
      set((state) => {
        state.orderReportData = convertDataToOrderReportBucketData(
          data?.orderReport ?? [],
        );
        state.isLoading = false;
      });
    },
    setDriverReport: async (data: DriverReportQuery | undefined) => {
      set((state) => {
        state.driverReportData = convertDataToDriverReportBucketData(
          data?.driverReport ?? [],
        );
        state.isLoading = false;
      });
    },
    setCustomerReport: async (data: CustomerReportQuery | undefined) => {
      set((state) => {
        state.customerReportData = convertDataToCustomerReportBucketData(
          data?.customerReport ?? [],
        );
        state.isLoading = false;
      });
    },
    setServiceLevelReport: async (
      data: ServiceLevelReportQuery | undefined,
    ) => {
      set((state) => {
        state.serviceLevelReportData =
          convertDataToServiceLevelReportBucketData(
            data?.serviceLevelReport ?? [],
          );
        state.isLoading = false;
      });
    },
    setStationReport: async (data: StationReportQuery | undefined) => {
      set((state) => {
        state.stationReportData = convertDataToStationReportBucketData(
          data?.stationReport ?? [],
        );
        state.isLoading = false;
      });
    },
    setTerminalReport: async (
      data: TerminalReportQuery | undefined,
      userTerminalUuid?: string,
    ) => {
      set((state) => {
        state.terminalReportData = convertDataToTerminalReportBucketData(
          data?.terminalReport ?? [],
          userTerminalUuid,
        );
        state.isLoading = false;
      });
    },
    setIsLoading: (loading: boolean) => {
      set((state) => {
        state.isLoading = loading;
      });
    },
    setError: (error: string | null) => {
      set((state) => {
        state.error = error;

        // If there is an error, we don't show the loading alert
        state.isLoading = false;
      });
    },
  })),
);

export default useReportsStore;
