import { type Dispatch, type SetStateAction } from 'react';
import { type LineHaulManifest } from '../utils';
import { Divider, Stack } from '@mui/material';
import { type Dayjs } from 'dayjs';
import ManifestDetailsHeader from './manifest-details-header';
import ManifestDetailsBody from './manifest-details-body';
import ManifestOrdersTable from './manifest-orders-table';

type ManifestDetailsProps = {
  readonly manifest: LineHaulManifest;
  readonly setOpenedManifestUuid: Dispatch<SetStateAction<string | null>>;
  readonly planningDate: Dayjs;
  readonly isRefetching: boolean;
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
};

const ManifestDetails = ({
  manifest,
  setOpenedManifestUuid,
  planningDate,
  isRefetching,
  setSelectedManifestIds,
}: ManifestDetailsProps) => {
  return (
    <Stack height="100%">
      <ManifestDetailsHeader
        manifest={manifest}
        setOpenedManifestUuid={setOpenedManifestUuid}
        planningDate={planningDate}
        isRefetching={isRefetching}
        setSelectedManifestIds={setSelectedManifestIds}
      />
      <Divider />
      <ManifestDetailsBody manifest={manifest} />
      <ManifestOrdersTable manifest={manifest} />
    </Stack>
  );
};

export default ManifestDetails;
