import { FormControl, FormLabel, TextField } from '@mui/material';
import { type Control, Controller } from 'react-hook-form';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useState } from 'react';
import { type OrganizationFormValues } from '../form/types';
import { type ContactPersonForOrganizationFragment } from '../../../generated/graphql';

type ContactPersonOption = {
  label: string;
  uuid: string;
};

type DefaultContactPersonAutocompleteProps = {
  readonly control: Control<OrganizationFormValues>;
  readonly organizationContactPersons: ContactPersonForOrganizationFragment[];
};

const DefaultContactPersonAutocomplete = ({
  control,
  organizationContactPersons,
}: DefaultContactPersonAutocompleteProps) => {
  const [defaultContactPersonInputValue, setDefaultContactPersonInputValue] =
    useState('');

  const contactPersonOptions: ContactPersonOption[] =
    organizationContactPersons?.map((person) => ({
      label: `${person.firstName} ${person.lastName}`,
      uuid: person.uuid,
    })) ?? [];

  return (
    <Controller
      name="defaultContactPersonUuid"
      control={control}
      render={({ field }) => (
        <FormControl size="small" sx={{ flex: 1 }}>
          <FormLabel>Default contact person</FormLabel>
          <AutocompleteFuzzy
            inputValue={defaultContactPersonInputValue ?? ''}
            isOptionEqualToValue={(option, value) =>
              option.uuid === value.uuid && option.label === value.label
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
            options={contactPersonOptions}
            matchSortOptions={{ keys: ['label'] }}
            sx={{ backgroundColor: 'white' }}
            value={
              contactPersonOptions.find(
                (option) => option.uuid === field.value,
              ) ?? null
            }
            onChange={(e, newValue) => {
              field.onChange(newValue?.uuid ?? null);
            }}
            onInputChange={(e, newInputValue) => {
              setDefaultContactPersonInputValue(newInputValue);
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default DefaultContactPersonAutocomplete;
