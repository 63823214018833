import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Dialog, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactMapGL, {
  Marker,
  type MarkerDragEvent,
  NavigationControl,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { isNilOrEmptyString } from 'shared/string';
import useMe from '../../../../../../common/react-hooks/use-me';
import PalletLink from '../../../../../../pallet-ui/links/link/pallet-link';
import { type OrderFormValues } from '../../forms/types';

const useStyles = () => {
  return {
    resolveWarningMessageBox: {
      width: '100%',
      backgroundColor: '#FECACA',
      padding: '10px',
    },
    darkRedWarningText: {
      color: '#AF3030',
    },
    openMapModalLinkText: {
      cursor: 'pointer',
      color: '#3063AF',
      textDecoration: 'none',
      fontSize: '15px',
    },
    mapModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      p: '15px',
    },
  };
};

// Washington, D.C.
// Fallback in case the company has no default address
const DEFAULT_ADDRESS = {
  latitude: 38.897_957,
  longitude: -77.036_56,
};

const AddressResolverModal = ({
  idx,
  isModalOpen,
  disabled,
  setIsModalOpen,
  resolveAddress,
  showSuccessAlert,
  shouldShowResolvingStatus = true,
}: {
  idx: number;
  isModalOpen: boolean;
  disabled?: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  resolveAddress: () => void;
  showSuccessAlert: () => void;
  shouldShowResolvingStatus?: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormValues>();
  const styles = useStyles();
  const { companyData } = useMe();
  const defaultLatitude =
    companyData?.defaultAddress?.latitude ?? DEFAULT_ADDRESS.latitude;
  const defaultLongitude =
    companyData?.defaultAddress?.longitude ?? DEFAULT_ADDRESS.longitude;

  const addressValues = useWatch({ control, name: `stops.${idx}.address` });

  const [markerLatitude, setMarkerLatitude] = useState(
    addressValues?.latitude ?? defaultLatitude,
  );
  const [markerLongitude, setMarkerLongitude] = useState(
    addressValues?.longitude ?? defaultLongitude,
  );
  const [pinLocationChanged, setPinLocationChanged] = useState(false);

  useEffect(() => {
    if (isNil(addressValues?.latitude) || isNil(addressValues?.longitude)) {
      return;
    }
    setMarkerLatitude(addressValues?.latitude ?? defaultLatitude);
    setMarkerLongitude(addressValues?.longitude ?? defaultLongitude);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressValues?.latitude, addressValues?.longitude]);

  const handleDragEnd = (evt: MarkerDragEvent) => {
    setMarkerLatitude(evt.lngLat.lat);
    setMarkerLongitude(evt.lngLat.lng);
    setPinLocationChanged(true);
  };

  const closeModalAndSaveCoordinates = () => {
    if (!isNil(addressValues?.uuid)) {
      setValue(`stops.${idx}.address.latitude`, markerLatitude);
      setValue(`stops.${idx}.address.longitude`, markerLongitude);
      setValue(`stops.${idx}.address.preventCoordRecompute`, true);
      if (shouldShowResolvingStatus) {
        resolveAddress();
      }
      setPinLocationChanged(false);
      showSuccessAlert();
    }
    setIsModalOpen(false);
  };

  try {
    if (isNilOrEmptyString(import.meta.env.VITE_MAPBOX_ACCESS_TOKEN)) {
      throw new Error('Undefined Mapbox access token environment variable.');
    }
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Box sx={styles.mapModal} minHeight="90vh">
          <Typography variant="h6"> {addressValues?.line1}</Typography>
          <Stack sx={{ textAlign: 'left', width: '95%' }} spacing={2}>
            <Typography
              sx={{ fontStyle: disabled === true ? 'italic' : undefined }}
            >
              {disabled === true
                ? 'This address has been saved, so the location cannot be edited'
                : 'Drag the pin to edit the location of this address.'}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <b>Current coordinates:</b>
              <Typography>
                {markerLatitude.toFixed(4)}, {markerLongitude.toFixed(4)}{' '}
              </Typography>
              {shouldShowResolvingStatus && pinLocationChanged && (
                <>
                  <CheckIcon color="success" /> Address found{' '}
                </>
              )}
              {shouldShowResolvingStatus && !pinLocationChanged && (
                <>
                  <CloseIcon color="error" /> Address location unclear{' '}
                </>
              )}
            </Stack>
          </Stack>
          <ReactMapGL
            initialViewState={{
              longitude: markerLongitude,
              latitude: markerLatitude,
              // Zoom in for resolved addresses
              zoom: shouldShowResolvingStatus ? 10 : 15,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            style={{ height: '60vh', width: '95%' }}
            mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          >
            <NavigationControl position="bottom-right" />
            <Marker
              draggable={disabled !== true}
              longitude={markerLongitude}
              latitude={markerLatitude}
              onDragEnd={handleDragEnd}
            />
          </ReactMapGL>
          {disabled === true ? (
            <Button
              variant="outlined"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Close
            </Button>
          ) : (
            <Button
              disabled={!pinLocationChanged}
              variant="contained"
              onClick={closeModalAndSaveCoordinates}
            >
              Confirm
            </Button>
          )}
        </Box>
      </Dialog>
    );
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('Error rendering address resolver map', error);
    return <Alert severity="error">Error rendering map</Alert>;
  }
};

export const AddressResolverWarningAndModal = ({
  idx,
  disabled,
  resolveAddress,
  showSuccessAlert,
}: {
  readonly idx: number;
  readonly disabled?: boolean;
  readonly resolveAddress: () => void;
  readonly showSuccessAlert: () => void;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles = useStyles();
  return (
    <>
      <Box sx={styles.resolveWarningMessageBox}>
        <Typography sx={styles.darkRedWarningText}>
          <b> {`We can't locate that address: `}</b>
          edit the entered information or
          <PalletLink
            sx={{ cursor: 'pointer', color: '#3063AF', textDecoration: 'none' }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {' '}
            click here{' '}
          </PalletLink>
          to verify the address on a map
        </Typography>
      </Box>
      {isModalOpen && (
        <AddressResolverModal
          idx={idx}
          isModalOpen={isModalOpen}
          disabled={disabled}
          setIsModalOpen={setIsModalOpen}
          resolveAddress={resolveAddress}
          showSuccessAlert={showSuccessAlert}
        />
      )}
    </>
  );
};

export const ViewAddressInMapLinkAndModal = ({
  idx,
  disabled,
  resolveAddress,
  showSuccessAlert,
}: {
  readonly idx: number;
  readonly disabled?: boolean;
  readonly resolveAddress: () => void;
  readonly showSuccessAlert: () => void;
}) => {
  const { control } = useFormContext<OrderFormValues>();
  const stopValues = useWatch({ control, name: `stops.${idx}` });
  const addressValues = stopValues.address;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles = useStyles();
  if (isNil(addressValues?.latitude) || isNil(addressValues?.longitude)) {
    return null;
  }
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          padding: '10px',
        }}
      >
        <CheckIcon htmlColor="#48724D" sx={{ fontSize: '18px' }} />
        <PalletLink
          sx={styles.openMapModalLinkText}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          View address in map
        </PalletLink>
      </Stack>
      {isModalOpen && (
        <AddressResolverModal
          idx={idx}
          isModalOpen={isModalOpen}
          disabled={disabled}
          setIsModalOpen={setIsModalOpen}
          shouldShowResolvingStatus={false}
          resolveAddress={resolveAddress}
          showSuccessAlert={showSuccessAlert}
        />
      )}
    </>
  );
};
