import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import {
  calculateRateForCustomCharge,
  findSpecialMatrixItemForCustomCharge,
  findZoneBasedMatrixItemForCustomCharge,
} from 'shared/billing';
import {
  type AccessorialMatrixItemStoreState,
  type AccessorialRangeStoreState,
} from 'shared/types';
import { objectKeys } from 'tsafe';
import {
  type ErrorResponse,
  type ValidationResponse,
} from '../../../common/form/formValidators';
import {
  type CustomChargeArrayUpdateInput,
  CustomChargeBillingMethod,
  type CustomChargeCreateInput,
  type StandardShipmentFragment,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { selectAccessorialRangesByIds } from '../../accessorials/redux/accessorial-range-slice';
import { selectAccessorialById } from '../../accessorials/redux/accessorials-slice';
import { selectSpecialAccessorialMatrixItemsByIds } from '../../accessorials/redux/special-accessorial-matrix-items-slice';
import { selectZoneBasedAccessorialMatrixItemsByIds } from '../../accessorials/redux/zone-based-accessorial-matrix-items-slice';
import { upsertCustomChargeErrors } from './custom-charges-errors-slice';
import {
  createInitialCustomCharge,
  customChargeFieldIsRequired,
  selectCustomChargeById,
  selectCustomChargesByIds,
  upsertCustomCharge,
} from './custom-charges-values-slice';

type CalculateCustomChargeRateArgs = {
  customChargeId: string;
};

type CreateCustomChargeCreateInputArg = {
  customChargeIds: string[];
};

export const findZoneBasedMatrixItemForCustomChargeThunk = createAsyncThunk<
  AccessorialMatrixItemStoreState | undefined,
  CalculateCustomChargeRateArgs,
  { state: RootState }
>(
  'customCharges/findZoneBasedMatrixItemForCustomCharge',
  (arg, thunkAPI): AccessorialMatrixItemStoreState | undefined => {
    const customCharge = selectCustomChargeById(
      thunkAPI.getState(),
      arg.customChargeId,
    );

    if (customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc) {
      return undefined;
    }

    const accessorial = selectAccessorialById(
      thunkAPI.getState(),
      customCharge?.accessorialId ?? '',
    );
    const matrixItems =
      accessorial?.__typename === 'ZoneBasedAccessorialEntity'
        ? selectZoneBasedAccessorialMatrixItemsByIds(
            thunkAPI.getState(),
            accessorial?.matrixItemIds ?? [],
          )
        : [];
    return findZoneBasedMatrixItemForCustomCharge({
      matrixItems,
      customCharge,
    });
  },
);

export const findSpecialMatrixItemForCustomChargeThunk = createAsyncThunk<
  AccessorialMatrixItemStoreState | undefined,
  CalculateCustomChargeRateArgs,
  { state: RootState }
>(
  'customCharges/findSpecialMatrixItemForCustomCharge',
  (arg, thunkAPI): AccessorialMatrixItemStoreState | undefined => {
    const customCharge = selectCustomChargeById(
      thunkAPI.getState(),
      arg.customChargeId,
    );

    if (customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc) {
      return undefined;
    }

    const accessorial = selectAccessorialById(
      thunkAPI.getState(),
      customCharge?.accessorialId ?? '',
    );
    const matrixItems =
      accessorial?.__typename === 'SpecialAccessorialEntity'
        ? selectSpecialAccessorialMatrixItemsByIds(
            thunkAPI.getState(),
            accessorial?.matrixItemIds ?? [],
          )
        : [];
    return findSpecialMatrixItemForCustomCharge({
      matrixItems,
      customCharge,
    });
  },
);

export const findAccessorialRangeForCustomChargeThunk = createAsyncThunk<
  AccessorialRangeStoreState | undefined,
  CalculateCustomChargeRateArgs,
  { state: RootState }
>(
  'customCharges/findAccessorialRangeForCustomCharge',
  (arg, thunkAPI): AccessorialRangeStoreState | undefined => {
    const customCharge = selectCustomChargeById(
      thunkAPI.getState(),
      arg.customChargeId,
    );

    if (customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc) {
      return undefined;
    }

    const accessorial = selectAccessorialById(
      thunkAPI.getState(),
      customCharge?.accessorialId ?? '',
    );
    const ranges =
      accessorial?.__typename === 'WeightBasedAccessorialEntity'
        ? selectAccessorialRangesByIds(
            thunkAPI.getState(),
            accessorial?.rangeIds ?? [],
          )
        : [];

    return ranges.find(
      (range) => range.uuid === customCharge?.accessorialRangeId,
    );
  },
);

export const calculateCustomChargeRateThunk = createAsyncThunk<
  number | undefined,
  CalculateCustomChargeRateArgs,
  { state: RootState }
>(
  'customCharges/createCustomChargeUpdateInput',
  (arg, thunkAPI): number | undefined => {
    const customCharge = selectCustomChargeById(
      thunkAPI.getState(),
      arg.customChargeId,
    );

    if (customCharge?.billingMethod === CustomChargeBillingMethod.AdHoc) {
      return customCharge.rate;
    }

    const accessorial = selectAccessorialById(
      thunkAPI.getState(),
      customCharge?.accessorialId ?? '',
    );
    const matrixItems =
      accessorial?.__typename === 'ZoneBasedAccessorialEntity'
        ? selectZoneBasedAccessorialMatrixItemsByIds(
            thunkAPI.getState(),
            accessorial?.matrixItemIds ?? [],
          )
        : accessorial?.__typename === 'SpecialAccessorialEntity'
          ? selectSpecialAccessorialMatrixItemsByIds(
              thunkAPI.getState(),
              accessorial?.matrixItemIds ?? [],
            )
          : [];

    const rangeIds =
      accessorial?.__typename === 'WeightBasedAccessorialEntity'
        ? accessorial?.rangeIds
        : [];
    const ranges =
      accessorial?.__typename === 'WeightBasedAccessorialEntity'
        ? selectAccessorialRangesByIds(thunkAPI.getState(), rangeIds)
        : [];

    return calculateRateForCustomCharge({
      accessorial,
      matrixItems,
      customCharge,
      ranges,
    });
  },
);

export const createCustomChargesCreateInput = createAsyncThunk<
  CustomChargeCreateInput[],
  CreateCustomChargeCreateInputArg,
  { state: RootState }
>(
  'customCharges/createCustomChargeUpdateInput',
  async (arg, thunkAPI): Promise<CustomChargeCreateInput[]> => {
    const customChargeValues = selectCustomChargesByIds(
      thunkAPI.getState(),
      arg.customChargeIds,
    );
    if (isNil(customChargeValues)) {
      throw new Error('Could not find custom charges');
    }

    const filteredCustomChargeValues = customChargeValues.filter(
      (customCharge) =>
        !isNil(customCharge) &&
        !isNil(customCharge.quantity) &&
        customCharge?.quantity > 0 &&
        (customCharge.billingMethod === CustomChargeBillingMethod.AdHoc ||
          (customCharge.billingMethod ===
            CustomChargeBillingMethod.Accessorial &&
            !isNil(customCharge.accessorialId))),
    );

    const resultPromises = filteredCustomChargeValues.map(
      async (customCharge) => {
        const zoneBasedCustomChargeMatrixItem = await thunkAPI
          .dispatch(
            findZoneBasedMatrixItemForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();
        const specialCustomChargeMatrixItem = await thunkAPI
          .dispatch(
            findSpecialMatrixItemForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();
        const accessorialRange = await thunkAPI
          .dispatch(
            findAccessorialRangeForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();

        return {
          rate: customCharge.rate ?? 0,
          billingMethod:
            customCharge.billingMethod ?? CustomChargeBillingMethod.Accessorial,
          description: customCharge.description ?? '',
          name: customCharge.name ?? '',
          quantity: customCharge.quantity ?? 0,
          accessorialTemplateUuid: customCharge.accessorialId,
          zoneBasedAccessorialMatrixItemUuid:
            zoneBasedCustomChargeMatrixItem?.uuid,
          specialAccessorialMatrixItemUuid: specialCustomChargeMatrixItem?.uuid,
          accessorialRangeUuid: accessorialRange?.uuid,
          fuelSurchargeRate: customCharge.fuelSurchargePercentageRate,
          settlementFlatRate: customCharge.settlementFlatRate,
          settlementPercentageRate: customCharge.settlementPercentageRate,
        };
      },
    );
    return Promise.all(resultPromises);
  },
);

type CreateCustomChargeUpdateInputArg = {
  customChargeIds: string[];
};

export const createCustomChargesUpdateInput = createAsyncThunk<
  CustomChargeArrayUpdateInput[],
  CreateCustomChargeUpdateInputArg,
  { state: RootState }
>(
  'customCharges/createCustomChargeUpdateInput',
  async (arg, thunkAPI): Promise<CustomChargeArrayUpdateInput[]> => {
    const customChargeValues = selectCustomChargesByIds(
      thunkAPI.getState(),
      arg.customChargeIds,
    );
    if (isNil(customChargeValues)) {
      throw new Error('Could not find custom charges');
    }

    const filteredCustomChargeValues = customChargeValues.filter(
      (customCharge) =>
        !isNil(customCharge) &&
        !isNil(customCharge.quantity) &&
        customCharge?.quantity > 0 &&
        (customCharge.billingMethod === CustomChargeBillingMethod.AdHoc ||
          (customCharge.billingMethod ===
            CustomChargeBillingMethod.Accessorial &&
            !isNil(customCharge.accessorialId))),
    );

    return Promise.all(
      filteredCustomChargeValues.map(async (customCharge) => {
        const customChargeRate = await thunkAPI
          .dispatch(
            calculateCustomChargeRateThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();
        const zoneBasedCustomChargeMatrixItem = await thunkAPI
          .dispatch(
            findZoneBasedMatrixItemForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();

        const specialCustomChargeMatrixItem = await thunkAPI
          .dispatch(
            findSpecialMatrixItemForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();
        const accessorialRange = await thunkAPI
          .dispatch(
            findAccessorialRangeForCustomChargeThunk({
              customChargeId: customCharge.uuid,
            }),
          )
          .unwrap();

        if (customCharge?.isLocal === true) {
          return {
            customChargeCreateInput: {
              rate: customChargeRate ?? 0,
              billingMethod:
                customCharge.billingMethod ??
                CustomChargeBillingMethod.Accessorial,
              description: customCharge.description ?? '',
              name: customCharge.name ?? '',
              quantity: customCharge.quantity ?? 0,
              accessorialTemplateUuid: customCharge.accessorialId,
              zoneBasedAccessorialMatrixItemUuid:
                zoneBasedCustomChargeMatrixItem?.uuid,
              specialAccessorialMatrixItemUuid:
                specialCustomChargeMatrixItem?.uuid,
              accessorialRangeUuid: accessorialRange?.uuid,
              settlementFlatRate: customCharge.settlementFlatRate,
              settlementPercentageRate: customCharge.settlementPercentageRate,
            },
          };
        }
        return {
          customChargeUpdateInput: {
            rate: customChargeRate ?? 0,
            billingMethod:
              customCharge.billingMethod ??
              CustomChargeBillingMethod.Accessorial,
            description: customCharge.description ?? '',
            uuid: customCharge.uuid,
            name: customCharge.name ?? '',
            quantity: customCharge.quantity ?? 0,
            accessorialTemplateUuid: customCharge.accessorialId,
            zoneBasedAccessorialMatrixItemUuid:
              zoneBasedCustomChargeMatrixItem?.uuid,
            specialAccessorialMatrixItemUuid:
              specialCustomChargeMatrixItem?.uuid,
            settlementFlatRate: customCharge.settlementFlatRate,
            settlementPercentageRate: customCharge.settlementPercentageRate,
          },
        };
      }),
    );
  },
);

export const upsertCustomChargesForShipment = createAsyncThunk<
  string[],
  {
    shipment: StandardShipmentFragment;
    isDuplicate: boolean;
  },
  { state: RootState }
>(
  'freightCharges/upsertFreightChargesForShipment',
  async (arg, thunkAPI): Promise<string[]> => {
    const { shipment } = arg;

    let customChargeIds = shipment.customCharges?.map(
      (customCharge) => customCharge.uuid,
    );
    if (isNil(shipment.customCharges)) {
      customChargeIds = [
        await thunkAPI.dispatch(createInitialCustomCharge()).unwrap(),
      ];
    } else {
      await Promise.all(
        shipment.customCharges.map(async (customCharge) => {
          let id;
          if (customCharge.__typename === 'CustomChargeEntity') {
            id = await thunkAPI.dispatch(
              upsertCustomCharge({
                rate: customCharge.rate ?? undefined,
                description: customCharge.description ?? undefined,
                quantity:
                  customCharge.accessorialTemplate?.__typename ===
                    'WaitTimeAccessorialEntity' && arg.isDuplicate
                    ? 0
                    : customCharge.quantity,
                uuid: customCharge.uuid,
                billingMethod: customCharge.billingMethod,
                name: customCharge.name,
                accessorialId: customCharge.accessorialTemplate?.uuid,
                adhocChargeName: undefined,
                fuelSurchargePercentageRate:
                  customCharge.fuelSurchargeRate ?? undefined,
                zoneBasedAccessorialZoneId:
                  customCharge.zoneBasedAccessorialZoneUuid ?? undefined,
                zoneBasedAccessorialChargeGroupId:
                  customCharge.zoneBasedAccessorialChargeGroupUuid ?? undefined,
                specialAccessorialTariffZoneId:
                  customCharge.specialAccessorialTariffZoneUuid ?? undefined,
                specialAccessorialChargeGroupId:
                  customCharge.specialAccessorialChargeGroupUuid ?? undefined,
                accessorialRangeId:
                  customCharge.accessorialRange?.uuid ?? undefined,
                total: undefined,
                isLocal: !arg.isDuplicate,
                settlementFlatRate: customCharge.settlementFlatRate,
                settlementPercentageRate: customCharge.settlementPercentageRate,
              }),
            );
          }
          return id;
        }),
      );
    }
    return customChargeIds;
  },
);

type GetCustomChargesArg = {
  customChargeIds: string[];
};

export type CustomChargeErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
};

export const getCustomChargesErrors = createAsyncThunk<
  CustomChargeErrorsResponse[],
  GetCustomChargesArg,
  { state: RootState }
>(
  'customCharges/getCustomChargesErrors',
  async (arg, thunkAPI): Promise<CustomChargeErrorsResponse[]> => {
    const customChargeValues = selectCustomChargesByIds(
      thunkAPI.getState(),
      arg.customChargeIds,
    );
    if (isNil(customChargeValues)) {
      throw new Error('Could not find custom charges');
    }

    const customChargesErrors: CustomChargeErrorsResponse[] = [];
    await Promise.all(
      customChargeValues.map(async (customCharge) => {
        const customChargeErrors: CustomChargeErrorsResponse = {
          errors: [],
          isValid: true,
        };
        await Promise.all(
          objectKeys(customChargeFieldIsRequired).map(async (key) => {
            let userFacingFieldName = '';
            let validationResponse: ValidationResponse | null = null;
            const isRequired = customChargeFieldIsRequired[key];
            const value = customCharge[key];
            const requiredValExists =
              isRequired && !isNil(value) && !Number.isNaN(value);

            // Currently leaving all fields optional so the user can create an order without filling out charges
            switch (key) {
              case 'uuid': {
                break;
              }
              case 'adhocChargeName': {
                break;
              }
              case 'description': {
                break;
              }
              case 'rate': {
                break;
              }
              case 'name': {
                break;
              }
              case 'billingMethod': {
                userFacingFieldName = 'Billing method';
                validationResponse = {
                  valid: requiredValExists,
                  explanation: `${key} is required`,
                };
                break;
              }
              case 'accessorialId': {
                userFacingFieldName = 'Accessorial';
                validationResponse = {
                  valid:
                    customCharge.billingMethod ===
                      CustomChargeBillingMethod.AdHoc ||
                    (customCharge.billingMethod ===
                      CustomChargeBillingMethod.Accessorial &&
                      requiredValExists),
                  explanation: `${key} is required`,
                };
                break;
              }
              case 'quantity': {
                userFacingFieldName = 'Quantity';
                validationResponse = {
                  valid: requiredValExists,
                  explanation: `${key} is required`,
                };
                break;
              }
              default: {
                break;
              }
            }

            // Currently doesn't validate any freight charge fields
            if (validationResponse?.valid === true) {
              thunkAPI.dispatch(
                upsertCustomChargeErrors({
                  uuid: customCharge.uuid,
                  [key]: undefined,
                }),
              );
            } else if (validationResponse?.valid === false) {
              customChargeErrors.isValid = false;
              customChargeErrors.errors.push({
                field: userFacingFieldName,
                validationResponse,
              });
              thunkAPI.dispatch(
                upsertCustomChargeErrors({
                  uuid: customCharge.uuid,
                  [key]: validationResponse?.explanation,
                }),
              );
            }
          }),
        );
        customChargesErrors.push(customChargeErrors);
      }),
    );

    return customChargesErrors;
  },
);
