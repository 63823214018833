import dayjs from 'dayjs';
import { isNil, uniq } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeDivide } from 'shared/math';
import {
  transformAddressToFullAddressStringWithoutCity,
  transformDateStringToSpecifiedFormat,
  transformTimeToTimeString,
} from '../../../../common/utils/prettyPrintUtils';
import {
  type AddressEntity,
  type AddressFragment,
  type AppointmentFragment,
  type EquipmentEntity,
  type OrderDetailedStatus,
  type RoutesQuery,
  type RoutesWithEtaQuery,
  type ShallowPackageFragment,
  type StandardStopType,
  PickupOrDelivery,
} from '../../../../generated/graphql';
import { type CoordinatePair } from '../../../daily-control-center/types';
import { titleCase } from '../../../daily-control-center/utils';
import { CONFIRMED_STATUSES, REJECTED_STATUSES } from '../../constants';
import { UNCONFIRMED_STATUSES } from '../../constants';
import { UNSCHEDULED_STATUSES } from '../../constants';
import AppointmentsTableTabs from '../../types/appointments-table-tabs';
import { AppointmentTableField } from '../../enums/appointment-table-fields';
import { exhaustive } from 'shared/switch';

export enum SlotTypes {
  StandardStop = 'StandardStopEntity',
  GroupedStop = 'GroupedStop',
  Place = 'PlaceEntity',
}

export type FormattedStop = {
  __typename: SlotTypes.StandardStop | SlotTypes.Place;
  appointmentDate: string | undefined;
  appointmentWindow: string;
  clientReferenceNumber?: string;
  masterAirwayBillOfLadingNumber?: string;
  contactInfo?: string;
  coordinates?: CoordinatePair;
  endAppointmentTime?: string;
  id: string;
  location: Partial<AddressEntity>;
  packages?: Array<Partial<ShallowPackageFragment>>;
  notes?: string;
  orderDetailedStatus: OrderDetailedStatus | null | undefined;
  orderId: string | undefined;
  orderName: string | undefined;
  pickupOrDelivery?: string;
  routeDisplayData: string[];
  serviceUuid?: string;
  serviceName?: string;
  shipmentFieldsId?: string;
  isScheduled?: boolean;
  shipmentId?: string;
  shipmentType?: string;
  slotId?: string;
  isSpecial?: boolean;
  specialInstructions?: string | null;
  status?: string;
  etaArrivalTime?: string;
  etaFinishTime?: string;
  unassignedTableDisplayData: string[];
  deadlineDate: Date | null | undefined;
  address: AddressFragment | null | undefined;
  stopType?: StandardStopType | null | undefined;
  orderNotes: string | null | undefined;
  overrideServiceTimeInMinutes?: number;
  routeId?: string;
  orderReferenceNumbers?: string[];
  packageLocations?: string[]; // Formatted warehouse locations of all packages in the order.
};

export type Slot = FormattedStop;

export type FormattedRoute = {
  id: string;
  date: Date;
  updatedAt: string;
  lastDispatched: string;
  nameManuallyChanged: boolean;
  locked: boolean;
  startLoadTime?: number;
  endLoadTime?: number;
  startTime?: Date;
  helpers?: Array<{
    firstName: string;
    lastName: string;
    id: string;
    phoneNumber: string;
  }>;
  drivers: Array<{
    firstName: string;
    lastName: string;
    id: string;
    phoneNumber: string;
  }>;
  slots: Slot[];
  revenue?: number;
  displayData: string[];
  name: string;
  equipments?: Array<Partial<EquipmentEntity>>;
  totalTime?: string;
};

export const formatAppointment = (appointment: AppointmentFragment) => {
  let appointmentString = '';
  const { appointmentTime } = appointment;
  const { endAppointmentTime } = appointment;
  if (!isNil(appointmentTime)) {
    appointmentString += `${dayjs(appointmentTime).format('hh:mm a')}`;
  }
  if (!isNil(appointmentTime) && !isNil(endAppointmentTime)) {
    appointmentString += ' - ';
  }
  if (!isNil(endAppointmentTime)) {
    appointmentString += `${dayjs(endAppointmentTime).format('hh:mm a')}`;
  }
  if (isNil(appointmentTime) && isNil(endAppointmentTime)) {
    appointmentString = 'No appointment';
  }
  return appointmentString;
};

export const formattedRouteSlotData = (
  data: RoutesQuery | undefined,
  dataWithEta: RoutesWithEtaQuery | undefined,
  showHelpersColumnInDispatch?: boolean,
): FormattedRoute[] => {
  let routesData: RoutesQuery | RoutesWithEtaQuery | undefined;
  if (!isNil(data)) {
    routesData = data;
  }
  if (!isNil(dataWithEta)) {
    routesData = dataWithEta;
  }
  const formattedRoutes = routesData?.routes.map((route, routeIdx) => {
    const slots: Slot[] = [];
    const routeWithEtas = dataWithEta?.routes[routeIdx];
    const etas = routeWithEtas?.etas;
    for (const [slotIdx, slot] of route.slots
      .filter((slot) => slot.stops.length > 0)
      .entries()) {
      // TODO: Currently, we take the first stop in the slot but in the future, we will want to account for all stops in a slot based on the slot type.
      const filteredStops = slot.stops.filter(
        (slotRow) => !isNil(slotRow.shipment?.order),
      );
      const stops = filteredStops.map((slotRow) => {
        const appointmentTime = transformDateStringToSpecifiedFormat(
          slotRow.appointmentTime,
          'hh:mm a',
        );
        const endAppointmentTime = isNil(slotRow.endAppointmentTime)
          ? undefined
          : transformTimeToTimeString(slotRow.endAppointmentTime);
        const deliveryDate =
          slotRow.shipment?.standardShipmentFields?.deliveryDate;
        const appointmentDate = transformDateStringToSpecifiedFormat(
          deliveryDate,
          'MM/DD',
        );
        let timeString = '-';
        if (
          !isNil(slotRow.appointmentTime) &&
          (isNil(endAppointmentTime) || appointmentTime === endAppointmentTime)
        ) {
          timeString = `${appointmentDate} ${appointmentTime}`;
        } else if (
          isNil(slotRow.appointmentTime) &&
          !isNil(endAppointmentTime)
        ) {
          timeString = `${appointmentDate}, ${endAppointmentTime}`;
        } else if (
          !isNil(endAppointmentTime) &&
          !isNil(slotRow.appointmentTime) &&
          !isNil(slotRow.endAppointmentTime) &&
          appointmentTime !== endAppointmentTime
        ) {
          timeString = `${appointmentDate}, ${appointmentTime} to ${endAppointmentTime}`;
        }
        const pickupOrDelivery =
          slotRow.shipment?.standardShipmentFields?.pickupOrDelivery ?? '';
        const routeCoords =
          !isNil(slotRow.address.latitude) && !isNil(slotRow.address.longitude)
            ? {
                latitude: slotRow.address.latitude,
                longitude: slotRow.address.longitude,
              }
            : undefined;
        const addressText = isNil(routeCoords) ? '(not shown on map)' : '';
        const shipperBillOfLadingNumber =
          slotRow.shipment?.order?.standardOrderFields
            .shipperBillOfLadingNumber;
        const orderName = `${
          !isNil(shipperBillOfLadingNumber) &&
          shipperBillOfLadingNumber.length > 0
            ? shipperBillOfLadingNumber
            : slotRow.shipment?.order?.name
        } (${titleCase(pickupOrDelivery.toLowerCase())})`;
        const addressInfo = `${transformAddressToFullAddressStringWithoutCity(
          slotRow.address,
          true,
        )} ${addressText}`;

        const shipmentType =
          slotRow.shipment?.standardShipmentFields?.pickupOrDelivery;
        let eta = slotRow.cachedEta;
        if (!isNil(etas)) {
          eta = etas[slotIdx];
        }
        let formattedEta: string =
          !isNil(eta) && !isNil(eta.arrivalTime) && !isNil(eta.finishTime)
            ? `${transformTimeToTimeString(
                eta.arrivalTime,
              )} - ${transformTimeToTimeString(eta.finishTime)}`
            : '-';
        if (
          !isNil(eta) &&
          !isNil(eta.arrivalTime) &&
          (transformTimeToTimeString(eta.arrivalTime) ===
            transformTimeToTimeString(eta.finishTime) ||
            isNil(eta.finishTime))
        ) {
          formattedEta = transformTimeToTimeString(eta.arrivalTime);
        }
        const routeDisplayData: string[] = [
          orderName,
          slotRow.address.city,
          formattedEta,
          timeString,
          addressInfo,
        ];

        const packagesWithLocations = (
          slotRow?.shipment?.order?.packages ?? []
        ).filter((pkg) => !isNil(pkg.warehouseLocation));
        const allPackageLocationNames = packagesWithLocations.map(
          (pkg) => pkg?.warehouseLocation?.name,
        );
        const uniquePackageLocations = filterNotNil(
          uniq(allPackageLocationNames),
        );
        const formattedStop: FormattedStop = {
          id: slotRow.uuid,
          shipmentId: slotRow.shipment?.uuid,
          stopType: slotRow.standardStopType,
          orderNotes:
            slotRow.shipment?.order?.orderComments
              ?.map((c) => c.comment)
              .join(', ') ?? '',
          shipmentFieldsId: slotRow.shipment?.standardShipmentFields?.uuid,
          appointmentDate:
            slotRow.shipment?.standardShipmentFields?.deliveryDate,
          orderId: slotRow.shipment?.order?.uuid ?? '',
          location: slotRow.address,
          appointmentWindow: slotRow.appointmentTime,
          endAppointmentTime: slotRow.endAppointmentTime,
          unassignedTableDisplayData: [],
          routeDisplayData,
          etaArrivalTime: eta?.arrivalTime,
          etaFinishTime: eta?.finishTime,
          coordinates: routeCoords,
          __typename: SlotTypes.StandardStop,
          packages: slotRow.shipment?.order?.packages,
          orderDetailedStatus: slotRow.shipment?.order?.detailedStatusV2,
          orderName: slotRow.shipment?.order?.name ?? '',
          slotId: slot.uuid,
          shipmentType,
          notes: slotRow.notes ?? undefined,
          specialInstructions: slotRow.specialInstructions,
          overrideServiceTimeInMinutes:
            slotRow.overrideServiceTimeInMinutes ??
            slotRow.shipment?.order?.service?.timeInMinutes ??
            undefined,
          routeId: route.uuid,
          pickupOrDelivery:
            slotRow.shipment?.standardShipmentFields?.pickupOrDelivery?.toString() ??
            undefined,
          clientReferenceNumber:
            slotRow.shipment?.order?.standardOrderFields
              ?.shipperBillOfLadingNumber ?? undefined,
          masterAirwayBillOfLadingNumber:
            slotRow.shipment?.order?.standardOrderFields
              ?.masterAirwayBillOfLadingNumber ?? undefined,
          contactInfo: filterNotNil([
            slotRow.contactPerson?.firstName,
            slotRow.contactPerson?.lastName,
            slotRow.contactPerson?.phone,
          ]).join(' '),
          serviceName: slotRow.shipment?.order?.service?.name,
          serviceUuid: slotRow.shipment?.order?.service?.uuid,
          deadlineDate: slotRow.shipment?.standardShipmentFields?.deadlineDate,
          address: slotRow.address,
          orderReferenceNumbers: slotRow?.shipment?.order?.refNumbers ?? [],
          packageLocations: uniquePackageLocations,
        };
        return formattedStop;
      });
      if (!isNil(stops)) {
        const formattedStop = stops[0];
        if (!isNil(formattedStop)) {
          slots.push(formattedStop);
        }
      }
    }
    let totalTime = '';
    if (!isNil(route.totalTimeInSeconds)) {
      const totalTimeInMinutes = safeDivide(route.totalTimeInSeconds, 60);
      const totalTimeInHours = safeDivide(totalTimeInMinutes, 60);
      totalTime = `${Math.floor(totalTimeInHours)} hours, ${Math.floor(
        totalTimeInMinutes % 60,
      )} min`;
    }

    let displayData = [
      route.name,
      route.drivers
        .map((driver) => `${driver.firstName} ${driver.lastName}`)
        .join(', '),
      totalTime,
    ];

    displayData =
      showHelpersColumnInDispatch === true
        ? [
            ...displayData,
            route.helpers
              ?.map((helper) => `${helper.firstName} ${helper.lastName}`)
              .join(', ') ?? '-',
          ]
        : displayData;
    const formattedRoute: FormattedRoute = {
      id: route.uuid,
      date: route.date,
      startTime: route.defaultStartTime,
      nameManuallyChanged: route.nameManuallyChanged,
      locked: route.locked,
      startLoadTime: route.startLoadTimeInMinutes ?? undefined,
      endLoadTime: route.endLoadTimeInMinutes ?? undefined,
      drivers: route.drivers.map((driver) => {
        return {
          firstName: driver.firstName,
          lastName: driver.lastName,
          id: driver.uuid,
          phoneNumber: driver.phoneNumber,
        };
      }),
      helpers: route.helpers.map((helper) => ({
        firstName: helper.firstName,
        lastName: helper.lastName,
        id: helper.uuid,
        phoneNumber: helper.phoneNumber,
      })),
      lastDispatched: route.lastDispatched,
      updatedAt: route.routeSlotsUpdatedAt,
      name: route.name,
      equipments: route.equipments?.map((equipment) => ({
        name: equipment.name,
        type: equipment.type,
        uuid: equipment.uuid,
      })),
      slots,
      displayData,
      totalTime,
    };
    return formattedRoute;
  });

  // Return in reverse so new routes appear in the beginning.
  return [...(formattedRoutes ?? [])].reverse();
};

export const showInView = (
  view: AppointmentsTableTabs,
  appointment: AppointmentFragment,
) => {
  const isPickupOrDelivery =
    // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
    appointment.leg.shipment.standardShipmentFields?.pickupOrDelivery ===
      PickupOrDelivery.Delivery ||
    // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
    appointment.leg.shipment.standardShipmentFields?.pickupOrDelivery ===
      PickupOrDelivery.Pickup;
  const hasAppointment =
    !isNil(appointment.appointmentTime) ||
    !isNil(appointment.endAppointmentTime);
  const hasContact = !isNil(appointment.contactPerson?.phone);
  const containsUnscheduledStatus =
    UNSCHEDULED_STATUSES.includes(appointment.appointmentTextStatus) ||
    UNSCHEDULED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsUnconfirmedStatus =
    UNCONFIRMED_STATUSES.includes(appointment.appointmentTextStatus) ||
    UNCONFIRMED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsConfirmedStatus =
    CONFIRMED_STATUSES.includes(appointment.appointmentTextStatus) ||
    CONFIRMED_STATUSES.includes(appointment.appointmentCallStatus);
  const containsRejectedStatus =
    REJECTED_STATUSES.includes(appointment.appointmentTextStatus) ||
    REJECTED_STATUSES.includes(appointment.appointmentCallStatus);
  const isManuallyConfirmed = appointment.appointmentManuallyConfirmed === true;
  switch (view) {
    case AppointmentsTableTabs.Appointments: {
      return true;
    }
    case AppointmentsTableTabs.Unscheduled: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        containsUnscheduledStatus &&
        !containsUnconfirmedStatus &&
        !containsConfirmedStatus &&
        !containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.Unconfirmed: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        containsUnconfirmedStatus &&
        !containsConfirmedStatus &&
        !containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.Confirmed: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        (containsConfirmedStatus || isManuallyConfirmed) &&
        !containsRejectedStatus
      );
    }
    case AppointmentsTableTabs.RescheduleRequested: {
      return (
        isPickupOrDelivery &&
        hasContact &&
        hasAppointment &&
        !containsConfirmedStatus &&
        containsRejectedStatus &&
        !isManuallyConfirmed
      );
    }
    case AppointmentsTableTabs.WithoutAppointments: {
      return !hasAppointment;
    }
    case AppointmentsTableTabs.WithoutContacts: {
      return !hasContact;
    }
    default: {
      return exhaustive(view);
    }
  }
};

export const getAppointmentTableFieldHeaderCopy = (
  field: AppointmentTableField,
) => {
  switch (field) {
    case AppointmentTableField.ORDER_NUMBER: {
      return 'Order #';
    }
    case AppointmentTableField.APPOINTMENT_CONTACT: {
      return 'Contact name';
    }
    case AppointmentTableField.SERVICE_LEVEL: {
      return 'Service level';
    }
    case AppointmentTableField.TEXT_STATUS: {
      return 'Text status';
    }
    case AppointmentTableField.CALL_STATUS: {
      return 'Call status';
    }
    case AppointmentTableField.CONSIGNEE: {
      return 'Consignee';
    }
    case AppointmentTableField.APPOINTMENT: {
      return 'Appointment';
    }
    case AppointmentTableField.CITY: {
      return 'City';
    }
    case AppointmentTableField.STATE: {
      return 'State';
    }
    case AppointmentTableField.ZIP: {
      return 'Zip';
    }
    case AppointmentTableField.APPOINTMENT_REQUIRED: {
      return 'Appt required';
    }
    case AppointmentTableField.APPOINTMENT_MANUALLY_CONFIRMED: {
      return 'Manually confirmed';
    }
    case AppointmentTableField.STOP_TYPE: {
      return 'Stop type';
    }
    case AppointmentTableField.TEXT_RESPONSES: {
      return 'Text responses';
    }
    case AppointmentTableField.DRIVER: {
      return 'Driver';
    }
    case AppointmentTableField.ADDRESS: {
      return 'Address';
    }
    case AppointmentTableField.PHONE: {
      return 'Phone';
    }
    case AppointmentTableField.ADDRESS_TYPE: {
      return 'Address type';
    }
    case AppointmentTableField.CUSTOMER: {
      return 'Customer';
    }
    default: {
      return exhaustive(field);
    }
  }
};
