import {
  Box,
  FormControl,
  type SxProps,
  TextField,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAddressesByIds } from '../../addresses/redux/addresses-values-slice';
import {
  selectContactAddressUuids,
  selectContactDefaultAddressUuid,
  updateOneContactValues,
} from '../redux/contact-values-slice';
import ContactPageMode from './contact-page-mode';

const useStyles = () => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: 2,
      px: 2,
      py: 2,
      width: '100%',
    } as SxProps,
    flexRowBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: 2,
      my: 1.5,
      width: '100%',
    } as SxProps,
  };
};

type AddressOption = {
  label: string;
  uuid: string;
};

type DefaultAddressAutocompleteProps = {
  readonly contactUuid: string;
  readonly mode: ContactPageMode;
};

const DefaultAddressAutocomplete = ({
  contactUuid,
  mode,
}: DefaultAddressAutocompleteProps) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const styles = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<AddressOption | null>(
    null,
  );
  const defaultAddressUuid = useAppSelector((state) =>
    selectContactDefaultAddressUuid(state, contactUuid),
  );
  const addressUuids =
    useAppSelector((state) => selectContactAddressUuids(state, contactUuid)) ??
    [];
  const addresses = useAppSelector((state) =>
    selectAddressesByIds(state, addressUuids),
  );
  const addressOptions: AddressOption[] = addresses.map((address) => ({
    label: `${address.line1}, ${address.city}`,
    uuid: address.uuid,
  }));

  const updateDefaultAddress = (newDefaultAddressUuid: string | null) => {
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: { defaultAddressUuid: newDefaultAddressUuid },
      }),
    );
  };

  useEffect(() => {
    const currentDefaultAddress = addressOptions.find(
      (a) => a.uuid === defaultAddressUuid,
    );

    if (isNil(currentDefaultAddress)) {
      setInputValue('');
      setSelectedValue(null);
    } else {
      setInputValue(currentDefaultAddress.label);
      setSelectedValue(currentDefaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddressUuid, addresses]);

  return (
    <Box sx={styles.flexRowBox}>
      <FormControl sx={{ width: theme.spacing(50) }} size="small">
        <AutocompleteFuzzy
          disabled={mode === ContactPageMode.VIEW || !canWriteContacts}
          inputValue={inputValue ?? ''}
          isOptionEqualToValue={(option, value) =>
            option.uuid === value.uuid && option.label === value.label
          }
          renderInput={(params) => <TextField {...params} size="small" />}
          options={addressOptions}
          matchSortOptions={{ keys: ['label'] }}
          sx={{ backgroundColor: 'white' }}
          value={selectedValue ?? null}
          onChange={(e, newValue) => {
            setSelectedValue(newValue);
            updateDefaultAddress(newValue?.uuid ?? null);
          }}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
        />
      </FormControl>
    </Box>
  );
};

export default DefaultAddressAutocomplete;
