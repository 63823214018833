import { Button, TableCell, TableRow, useTheme } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useDrivers from '../../../../../../../../common/react-hooks/use-drivers';
import AddDriverForDriverSettlementModal from '../../../../../standard/components/driver-settlement/add-driver-for-driver-settlement-modal';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import {
  type IndependentSettlementBillLineItemValues,
  type OrderFormValues,
} from '../../../../forms/types';
import { useShouldShowStopChargesSection } from '../../../../hooks/use-expected-order-components';
import { DriverSettlementEditor } from './driver-settlement-editor';
import { useOrderFormSettlementActions } from '../../../../hooks/use-order-form-settlement-actions';

const StopSettlementTable = ({ idx }: { readonly idx: number }) => {
  const { showSection: showStopChargesSection } =
    useShouldShowStopChargesSection({
      stopIdx: idx,
    });

  const { onAddIndependentSettlementBillLineItem } =
    useOrderFormSettlementActions({ stopIdx: idx });

  const theme = useTheme();
  const { getDriverName } = useDrivers();
  const { control, setValue, getValues } = useFormContext<OrderFormValues>();

  const [showAddDriverModal, setShowAddDriverModal] = useState<boolean>(false);
  const [selectedLineItemIdx, setSelectedLineItemIdx] = useState<
    number | undefined
  >();

  const stopType = useWatch({
    control,
    name: `stops.${idx}.stopType`,
  });
  const settlementBillLineItems: IndependentSettlementBillLineItemValues[] =
    useWatch({
      control,
      name: `stops.${idx}.settlementBillLineItems`,
    }) ?? [];

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  if (!showStopChargesSection) {
    return null;
  }

  const onAddSettlementBillLineItem = (driverUuid: string) => {
    const newSettlementBillLineItems = onAddIndependentSettlementBillLineItem({
      driverUuid,
    });
    setShowAddDriverModal(false);
    setSelectedLineItemIdx(
      newSettlementBillLineItems.findIndex(
        (li) => li.driverUuid === driverUuid,
      ),
    );
  };

  return (
    <>
      <AddDriverForDriverSettlementModal
        hideDriversIneligibleForSettlement
        isOpen={showAddDriverModal}
        setIsOpen={setShowAddDriverModal}
        disabledDriverUuids={settlementBillLineItems.map((li) => li.driverUuid)}
        onConfirm={onAddSettlementBillLineItem}
      />
      {!isNil(selectedLineItemIdx) && (
        <DriverSettlementEditor
          stopIdx={idx}
          settlementBillLineItemIdx={selectedLineItemIdx}
          onClose={() => {
            setSelectedLineItemIdx(undefined);
          }}
        />
      )}
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell colSpan={3}>{stopType?.toString().toUpperCase()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ fontWeight: 500 }}>Driver</TableCell>
        <TableCell sx={{ fontWeight: 500 }}>Total</TableCell>
        <TableCell />
      </TableRow>
      {settlementBillLineItems?.map((lineItem, lineItemIdx) => (
        <TableRow key={lineItem.id}>
          <TableCell sx={{ minWidth: 350 }}>
            {getDriverName(lineItem.driverUuid)}
          </TableCell>
          <TableCell sx={{ minWidth: 200 }}>
            {currency(lineItem.totalCharge).format()}
          </TableCell>
          <TableCell sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedLineItemIdx(lineItemIdx);
              }}
            >
              {/* If user has write access and the settlement bill has not been finalized, show 'Edit' */}
              {isNil(lineItem.settlementBillFinalizedDate) &&
              !disabledIfNoAccess
                ? 'Edit'
                : 'View'}
            </Button>
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell colSpan={5}>
          <Button
            onClick={() => {
              setShowAddDriverModal(true);
            }}
          >
            + Add driver
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StopSettlementTable;
