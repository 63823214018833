import {
  AppBar,
  Box,
  Card,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  type SxProps,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { type FunctionComponent } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { transformAddressToFullAddressString } from 'shared/copy';
import { plainTimeHumanReadable } from 'shared/plain-date-time';
import { transformTimeToTimeString } from '../../../../common/utils/prettyPrintUtils';
import {
  StopType,
  useConsigneePortalOrderQuery,
  useEtaForCustomerPortalQuery,
} from '../../../../generated/graphql';
import {
  computeNextActionForConsigneePortalOrder,
  formatAppointment,
  formatDate,
} from '../../utils';

import 'mapbox-gl/dist/mapbox-gl.css';
import CustomerLoadPageField from './customer-load-page-field';
import LoadStatusDisplay from './load-status-display';

dayjs.extend(utc);
dayjs.extend(timezone);

const styles = {
  container: { height: '100%', backgroundColor: '#F7F7F7' },
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    height: 75,
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
  appBarTypography: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
    color: '#FFFFFF',
  } as SxProps,
  loadContainer: {
    py: 1,
    px: 5,
  } as SxProps,
  mapContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '250px',
    my: 2,
  } as SxProps,
  loadDetailsContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    my: 2,
    mx: 2,
    padding: 2,
  } as SxProps,
  packagesContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    my: 2,
    mx: 2,
    padding: 2,
  } as SxProps,
  loadStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
  bold: {
    fontWeight: 600,
  } as SxProps,
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
  sectionHeading: {
    marginBottom: '24px',
  } as SxProps,
  packagesList: {
    p: 2,
    my: 2,
  } as SxProps,
  packageListHeadingRowContainer: {
    borderRadius: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#F7F7F7',
    p: 2,
  } as SxProps,
  packageDataColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as SxProps,
};

type Address = {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  latitude?: number | null;
  longitude?: number | null;
};

type ConsigneeLoadPageProps = {
  readonly loadUuid: string;
};

const ConsigneeLoadPage: FunctionComponent<ConsigneeLoadPageProps> = ({
  loadUuid,
}) => {
  const matches = useMediaQuery('(min-width:900px)');

  // get load data from API using special resolver without authentication required
  const { data: consigneePortalOrderData, loading: orderLoading } =
    useConsigneePortalOrderQuery({
      variables: { uuid: loadUuid },
    });
  const { data: etaData, loading: etaLoading } = useEtaForCustomerPortalQuery({
    variables: { uuid: loadUuid },
  });

  if (orderLoading || etaLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const order = consigneePortalOrderData?.consigneePortalOrder;
  if (isNil(order)) {
    return null;
  }

  const pickupShipment = order.shipments.find(
    (sh) => sh.stopType === StopType.Pickup,
  );
  const deliveryShipment = order.shipments.find(
    (sh) => sh.stopType === StopType.Delivery,
  );
  const isPickupAndDeliveryOrder =
    !isNil(pickupShipment) && !isNil(deliveryShipment);
  const pickupStopAddress = pickupShipment?.address;
  const deliveryStopAddress = deliveryShipment?.address;

  const shipment = deliveryShipment ?? pickupShipment;

  const deliveryDate = shipment?.deliveryDate;
  const appointmentTime = shipment?.appointmentTime;
  const endAppointmentTime = shipment?.endAppointmentTime;

  // get specific data from load query result
  const stopAddress: Address | null = shipment?.address ?? null;

  const latitude = stopAddress?.latitude;
  const longitude = stopAddress?.longitude;

  const deliveryAppointment = shipment?.deliveryDate;
  const packages = order.packages ?? [];
  const loadStatus = order.detailedStatus;

  const weightUnit = 'lbs';
  const measurementUnit = 'in';
  let formattedEta =
    !isNil(etaData) && !isNil(etaData.etaForCustomerPortal)
      ? `${transformTimeToTimeString(
          etaData.etaForCustomerPortal.arrivalTime,
        )} - ${transformTimeToTimeString(
          etaData.etaForCustomerPortal.finishTime,
        )}`
      : '-';
  if (
    !isNil(etaData) &&
    !isNil(etaData.etaForCustomerPortal) &&
    transformTimeToTimeString(etaData.etaForCustomerPortal.arrivalTime) ===
      transformTimeToTimeString(etaData.etaForCustomerPortal.finishTime)
  ) {
    formattedEta = transformTimeToTimeString(
      etaData.etaForCustomerPortal.finishTime,
    );
  }

  const { companyTimezone } = order;

  const completedAt = shipment?.completedAtLocal;

  return (
    <Box sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppBar color="primary" sx={styles.appBar}>
            <Toolbar>
              <Typography sx={styles.appBarTypography}>
                Order Details
              </Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12} sx={styles.mapContainer}>
          {!isNil(latitude) && !isNil(longitude) && (
            <ReactMapGL
              initialViewState={{
                latitude,
                longitude,
                zoom: 15,
              }}
              style={{ width: '100%', height: '100%' }}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken="pk.eyJ1IjoibHVrZXFpbjEiLCJhIjoiY2w4eHRvYjFzMDJ4ZTN4bzR5NmpnbnQ4ZSJ9.Pr45YkvmRo6O-hVBrAa6tA"
            >
              <Marker
                longitude={longitude}
                latitude={latitude}
                anchor="bottom"
              />
            </ReactMapGL>
          )}
        </Grid>
        <Grid item xs={12} sx={styles.loadStatusContainer}>
          {!isNil(loadStatus) && <LoadStatusDisplay loadStatus={loadStatus} />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card sx={styles.loadDetailsContainer}>
          <Typography sx={styles.sectionHeading} variant="h4">
            Stop Information
          </Typography>
          <Grid container spacing={1}>
            <CustomerLoadPageField
              spacing={matches ? 2 : undefined}
              title="Stop Type"
              value={sentenceCase(shipment?.stopType ?? '')}
              defaultValue="-"
            />
            {!isNil(companyTimezone) && (
              <CustomerLoadPageField
                spacing={matches ? 2 : undefined}
                title="Appointment"
                value={formatAppointment({
                  appointmentDate: deliveryDate,
                  appointmentTime,
                  endAppointmentTime,
                  timeZone: companyTimezone,
                })}
                defaultValue="-"
              />
            )}
            {isPickupAndDeliveryOrder ? (
              <>
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Pickup Address"
                  value={
                    isNil(pickupStopAddress)
                      ? ''
                      : transformAddressToFullAddressString(
                          pickupStopAddress,
                          true,
                        )
                  }
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Delivery Address"
                  value={
                    isNil(deliveryStopAddress)
                      ? ''
                      : transformAddressToFullAddressString(
                          deliveryStopAddress,
                          true,
                        )
                  }
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Scheduled Delivery Date"
                  value={formatDate(
                    deliveryAppointment,
                    shipment?.appointmentTime,
                  )}
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Consignee Name"
                  value={shipment?.address?.name}
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Contact Phone"
                  value={shipment?.contactPhone}
                  defaultValue="-"
                />
              </>
            ) : (
              <CustomerLoadPageField
                spacing={matches ? 2 : undefined}
                title={`${sentenceCase(shipment?.stopType ?? '')} Address`}
                value={
                  isNil(stopAddress)
                    ? ''
                    : transformAddressToFullAddressString(stopAddress)
                }
                defaultValue="-"
              />
            )}
            <CustomerLoadPageField
              spacing={matches ? 2 : undefined}
              title="POD Signee Name"
              value={shipment?.proofOfDeliverySignee}
              defaultValue="-"
            />
            <CustomerLoadPageField
              spacing={matches ? 2 : undefined}
              title="Date Completed"
              value={
                isNil(completedAt) ? (
                  'Not Completed'
                ) : (
                  <Tooltip
                    title={`Time zone ${shipment?.completedAtLocalTimeZone}`}
                  >
                    <span>
                      {completedAt.date.month}/{completedAt.date.day}/
                      {completedAt.date.year}{' '}
                      {plainTimeHumanReadable(completedAt.time)}
                    </span>
                  </Tooltip>
                )
              }
              defaultValue="-"
            />
            <CustomerLoadPageField
              spacing={matches ? 2 : undefined}
              title="Next Action"
              value={computeNextActionForConsigneePortalOrder(order)}
              defaultValue="-"
            />
            {shipment?.routeIsToday === true && (
              <>
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Place in route"
                  value={
                    isNil(shipment?.placeInRoute)
                      ? null
                      : `Stop ${shipment?.placeInRoute}`
                  }
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="ETA"
                  value={formattedEta}
                  defaultValue="-"
                />
                <CustomerLoadPageField
                  spacing={matches ? 2 : undefined}
                  title="Driver(s) currently at"
                  value={
                    isNil(shipment.driverPlaceInRoute)
                      ? null
                      : `Stop ${shipment.driverPlaceInRoute}`
                  }
                  defaultValue="Not started yet"
                />
              </>
            )}
          </Grid>
        </Card>
        <Grid item xs={12}>
          <Card sx={styles.packagesContainer}>
            <Typography sx={styles.sectionHeading} variant="h4">
              Packages
            </Typography>
            <Grid sx={styles.packagesList}>
              {packages.length === 0 ? (
                <Typography>No packages in this shipment.</Typography>
              ) : (
                <Box sx={{ overflow: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>{`Weight (${weightUnit})`}</TableCell>
                      <TableCell>{`Length (${measurementUnit})`}</TableCell>
                      <TableCell>{`Width (${measurementUnit})`}</TableCell>
                      <TableCell>{`Height (${measurementUnit})`}</TableCell>
                    </TableHead>
                    {packages.flatMap((pkg) => {
                      return (
                        <TableRow>
                          <TableCell>{pkg.description}</TableCell>
                          <TableCell>{pkg.quantity}</TableCell>
                          <TableCell>{pkg.weight}</TableCell>
                          <TableCell>{pkg.length}</TableCell>
                          <TableCell>{pkg.width}</TableCell>
                          <TableCell>{pkg.height}</TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </Box>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsigneeLoadPage;
