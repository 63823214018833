import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import {
  type CreateLineHaulManifestInput,
  type DetailedLineHaulManifestFragment,
  useAddOrdersToManifestMutation,
  useCreateLineHaulManifestMutation,
  useLineHaulManifestsLazyQuery,
  useMarkManifestAsArrivedMutation,
  useMarkManifestAsDepartedMutation,
  useMarkManifestAsPlanningMutation,
  useRemoveLineHaulManifestMutation,
  useRemoveOrdersFromManifestMutation,
  useUpdateManifestDriverMutation,
  useUpdateManifestVehicleMutation,
} from '../../../generated/graphql';
import useLineHaulDispatchStore from '../store/line-haul-dispatch-store';

const useLineHaulDispatchActions = () => {
  const [
    planningDate,
    setManifest,
    setShouldRefreshGrid,
    setSelectingManifests,
    deselectAllManifestAndUnmanifestedSegmentUuids,
    setPlanningDate,
    addManifest,
    setOpenedManifest,
    deleteManifest,
    setManifests,
    setUnmanifestedSegments,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.planningDate,
      state.setManifest,
      state.setShouldRefreshGrid,
      state.setSelectingManifests,
      state.deselectAllManifestAndUnmanifestedSegmentUuids,
      state.setPlanningDate,
      state.addManifest,
      state.setOpenedManifest,
      state.deleteManifest,
      state.setManifests,
      state.setUnmanifestedSegments,
    ],
    shallow,
  );

  const [updateManifestDriver] = useUpdateManifestDriverMutation();
  const [updateManifestVehicle] = useUpdateManifestVehicleMutation();
  const [addOrdersToManifest] = useAddOrdersToManifestMutation();
  const [removeOrdersFromManifest] = useRemoveOrdersFromManifestMutation();
  const [markManifestAsDeparted] = useMarkManifestAsDepartedMutation();
  const [markManifestAsArrived] = useMarkManifestAsArrivedMutation();
  const [markManifestAsPlanning] = useMarkManifestAsPlanningMutation();
  const [createManifest] = useCreateLineHaulManifestMutation();
  const [removeManifest] = useRemoveLineHaulManifestMutation();
  const [getLineHaulManifests] = useLineHaulManifestsLazyQuery();

  const changePlanningDate = ({
    newPlanningDate,
  }: {
    newPlanningDate: Dayjs;
  }) => {
    setSelectingManifests(false);
    deselectAllManifestAndUnmanifestedSegmentUuids();
    setPlanningDate(newPlanningDate);
  };

  const stopSelectingManifests = () => {
    setSelectingManifests(false);
    deselectAllManifestAndUnmanifestedSegmentUuids();
  };

  const fetchManifests = async () => {
    if (!isNil(planningDate)) {
      const res = await getLineHaulManifests({
        variables: { date: planningDate },
      });

      setManifests(res.data?.lineHaulManifests.lineHaulManifests);
      setUnmanifestedSegments(res.data?.lineHaulManifests.unmanifestedSegments);
    }
  };

  const addSingleOrderToOpenedManifest = async ({
    orderUuid,
    openedManifestUuid,
  }: {
    orderUuid: string;
    openedManifestUuid: string | undefined;
  }): Promise<string | null> => {
    if (!isNil(openedManifestUuid)) {
      const res = await addOrdersToManifest({
        variables: {
          addOrdersToManifestInput: {
            uuid: openedManifestUuid,
            orderUuids: [orderUuid],
          },
        },
      });
      const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
      const errorMessage = res.data?.addOrdersToManifest.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        setManifest(createdManifest);
        return createdManifest.uuid;
      }
    }
    return null;
  };

  const addMultipleOrdersToOpenedManifest = async ({
    orderUuids,
    openedManifestUuid,
  }: {
    orderUuids: string[];
    openedManifestUuid: string | undefined;
  }): Promise<string | null> => {
    if (!isNil(openedManifestUuid)) {
      const res = await addOrdersToManifest({
        variables: {
          addOrdersToManifestInput: {
            uuid: openedManifestUuid,
            orderUuids,
          },
        },
      });
      const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
      const errorMessage = res.data?.addOrdersToManifest.errorMessage;

      if (!isNil(createdManifest) && isNil(errorMessage)) {
        setManifest(createdManifest);
        return createdManifest.uuid;
      }
    }

    return null;
  };

  const addSingleOrderToManifest = async ({
    orderUuid,
    manifest,
  }: {
    orderUuid: string;
    manifest: DetailedLineHaulManifestFragment;
  }): Promise<string | null> => {
    const res = await addOrdersToManifest({
      variables: {
        addOrdersToManifestInput: {
          uuid: manifest.uuid,
          orderUuids: [orderUuid],
        },
      },
    });
    const createdManifest = res.data?.addOrdersToManifest.lineHaulManifest;
    const errorMessage = res.data?.addOrdersToManifest.errorMessage;

    if (!isNil(createdManifest) && isNil(errorMessage)) {
      setManifest(createdManifest);
      return createdManifest.uuid;
    }
    return null;
  };

  const createNewManifest = async ({
    input,
  }: {
    input: CreateLineHaulManifestInput;
  }): Promise<DetailedLineHaulManifestFragment | null> => {
    const res = await createManifest({
      variables: {
        createLineHaulManifestInput: input,
      },
    });
    const createdManifest = res.data?.createLineHaulManifest;

    if (!isNil(createdManifest)) {
      addManifest(createdManifest);
      setOpenedManifest(createdManifest);
      return createdManifest;
    }
    return null;
  };

  const updateDriverOnManifest = async ({
    uuid,
    driverUuid,
  }: {
    uuid: string;
    driverUuid: string;
  }) => {
    const res = await updateManifestDriver({
      variables: {
        updateManifestDriverInput: {
          uuid,
          driverUuid,
        },
      },
    });

    const updatedManifest = res.data?.updateManifestDriver;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
    }
  };

  const updateVehicleOnManifest = async ({
    uuid,
    equipmentUuid,
  }: {
    uuid: string;
    equipmentUuid: string;
  }) => {
    const res = await updateManifestVehicle({
      variables: {
        updateManifestVehicleInput: {
          uuid,
          equipmentUuid,
        },
      },
    });
    const updatedManifest = res.data?.updateManifestVehicle;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
    }
  };

  const departManifest = async ({
    uuid,
    refreshGridAfter,
  }: {
    uuid: string;
    refreshGridAfter: boolean;
  }) => {
    const res = await markManifestAsDeparted({
      variables: {
        markManifestAsDepartedInput: {
          uuid,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsDeparted.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      if (refreshGridAfter) setShouldRefreshGrid(true);
    }
  };

  const departManifests = async ({ uuids }: { uuids: string[] }) => {
    await Promise.all(
      uuids.map(async (uuid) =>
        departManifest({ uuid, refreshGridAfter: false }),
      ),
    );
    setShouldRefreshGrid(true);
  };

  const arriveManifest = async ({ uuid }: { uuid: string }) => {
    const res = await markManifestAsArrived({
      variables: {
        markManifestAsArrivedInput: {
          uuid,
          fromDispatch: true,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsArrived.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const markManifestAsBeingPlanned = async ({ uuid }: { uuid: string }) => {
    const res = await markManifestAsPlanning({
      variables: {
        markManifestAsPlanningInput: {
          uuid,
        },
      },
    });
    const updatedManifest = res.data?.markManifestAsPlanning.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeMultipleOrdersFromManifest = async ({
    uuid,
    orderUuids,
  }: {
    uuid: string;
    orderUuids: string[];
  }) => {
    const res = await removeOrdersFromManifest({
      variables: {
        removeOrdersFromManifestInput: {
          uuid,
          orderUuids,
        },
      },
    });
    const updatedManifest = res.data?.removeOrdersFromManifest.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeSingleOrderFromManifest = async ({
    uuid,
    orderUuid,
  }: {
    uuid: string;
    orderUuid: string;
  }) => {
    const res = await removeOrdersFromManifest({
      variables: {
        removeOrdersFromManifestInput: {
          uuid,
          orderUuids: [orderUuid],
        },
      },
    });
    const updatedManifest = res.data?.removeOrdersFromManifest.lineHaulManifest;
    if (!isNil(updatedManifest)) {
      setManifest(updatedManifest);
      setShouldRefreshGrid(true);
    }
  };

  const removeAllOrdersFromManifest = async ({
    manifest,
  }: {
    manifest: DetailedLineHaulManifestFragment;
  }) => {
    const orderUuids = manifest.orderSegments.map((o) => o.order.uuid);
    await removeMultipleOrdersFromManifest({ uuid: manifest.uuid, orderUuids });
  };

  const deleteSingleManifest = async ({
    manifestUuid,
    refreshGridAfter,
  }: {
    manifestUuid: string;
    refreshGridAfter: boolean;
  }) => {
    const deletedManifest = await removeManifest({
      variables: {
        uuid: manifestUuid,
      },
    });

    if (!isNil(deletedManifest.data?.removeLineHaulManifest)) {
      deleteManifest(manifestUuid);
      if (refreshGridAfter) setShouldRefreshGrid(true);
    }
  };

  const deleteMultipleManifests = async ({
    manifestUuids,
  }: {
    manifestUuids: string[];
  }) => {
    await Promise.all(
      manifestUuids.map(async (uuid) =>
        deleteSingleManifest({ manifestUuid: uuid, refreshGridAfter: false }),
      ),
    );
    setShouldRefreshGrid(true);
  };

  const addTruckload = async ({
    manifest,
    departDate,
    openNewLoad,
  }: {
    manifest: DetailedLineHaulManifestFragment;
    departDate: Dayjs;
    openNewLoad: boolean;
  }) => {
    const newManifest = await createNewManifest({
      input: {
        lineHaulManifestCreateInput: {
          departDate,
          startTerminalUuid: manifest?.startTerminal.uuid,
          endTerminalUuid: manifest.endTerminal.uuid,
          referenceNumber: '',
        },
      },
    });
    if (!isNil(newManifest) && openNewLoad) {
      setOpenedManifest(newManifest);
    }
  };

  return {
    addSingleOrderToManifest,
    addSingleOrderToOpenedManifest,
    addMultipleOrdersToOpenedManifest,
    addTruckload,
    arriveManifest,
    changePlanningDate,
    createNewManifest,
    deleteMultipleManifests,
    deleteSingleManifest,
    departManifest,
    departManifests,
    fetchManifests,
    markManifestAsBeingPlanned,
    removeAllOrdersFromManifest,
    removeMultipleOrdersFromManifest,
    removeSingleOrderFromManifest,
    stopSelectingManifests,
    updateDriverOnManifest,
    updateVehicleOnManifest,
  };
};

export default useLineHaulDispatchActions;
