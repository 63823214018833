import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil, range } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  type BooleanAndInputOption,
  initialBooleanAndInputOption,
} from '../../../common/components/boolean-and-input-filter-button';
import BooleanFilterButton from '../../../common/components/boolean-filter-button';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import CustomerFilterButton from '../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  DatePickerFilterType,
} from '../../../common/components/date-dropdown-picker';
import SingleSelectFilterButton from '../../../common/components/single-select-filter-button';
import { type Option } from '../../../common/filters/types';
import {
  ReportAggregationPeriod,
  useAuditReportLazyQuery,
} from '../../../generated/graphql';
import useAuditReportStore from '../audit-report-store';
import { AuditOrderTabs } from '../types';
import AuditReportOrdersTable from './audit-report-orders-table';
import OrdersAuditReportTableRow from './audit-report-table-row';

const CACHE_ID = 'ORDER_AUDIT_REPORT';

const AuditReport = () => {
  const currentBucket = useAuditReportStore((state) => state.currentBucket);
  const [
    queryAuditReport,
    { data: auditReportData, loading: auditReportLoading },
  ] = useAuditReportLazyQuery();
  const [dateOption, setDateOption] = useState<DateOption>({
    filterType: DatePickerFilterType.Range,
    value: undefined,
    endDate: dayjs().endOf('day').toDate(),
    startDate: dayjs().subtract(1, 'weeks').startOf('day').toDate(),
  });
  const [reportAggregationPeriod, setReportAggregationPeriod] =
    useState<ReportAggregationPeriod>(ReportAggregationPeriod.Week);
  const [daysPastDueOption, setDaysPastDueOption] =
    useState<BooleanAndInputOption>(initialBooleanAndInputOption);
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [isPastDue, setIsPastDue] = useState<boolean | undefined>();

  const handleDaysPastDueChange = (newOption: BooleanAndInputOption) => {
    setDaysPastDueOption(newOption);
  };

  useEffect(() => {
    queryAuditReport({
      variables: {
        args: {
          startDate: dateOption.startDate,
          endDate: dateOption.endDate,
          reportAggregationPeriod,
          contactUuid: customerOption?.value,
          daysPastDue: daysPastDueOption.value,
          isPastDue,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateOption,
    reportAggregationPeriod,
    daysPastDueOption,
    customerOption,
    isPastDue,
  ]);

  const numBuckets = auditReportData?.auditReport.length;

  if (auditReportLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box
      sx={{
        height: '84vh',
        overflowY: 'scroll',
      }}
    >
      <Stack
        sx={{ display: isNil(currentBucket) ? undefined : 'none' }}
        spacing={2}
      >
        <Box>
          <Stack spacing={2} direction="row">
            <DateDropdownPicker
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <SingleSelectFilterButton
              cacheId={CACHE_ID}
              option={reportAggregationPeriod}
              handleChange={(option: ReportAggregationPeriod | undefined) => {
                if (!isNil(option)) {
                  setReportAggregationPeriod(option);
                }
              }}
              filterTitle="Time Period: "
              options={Object.values(ReportAggregationPeriod)}
            />
            <CustomerFilterButton
              cacheId={CACHE_ID}
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
            <BooleanFilterButton
              cacheId={CACHE_ID}
              value={isPastDue}
              handleChange={(newValue: boolean | undefined) => {
                setIsPastDue(newValue);
              }}
              filterTitle="Past Due: "
            />
          </Stack>
        </Box>
        <Stack alignItems="center" justifyContent="space-between" spacing={10}>
          <Table
            stickyHeader
            size={!isNil(numBuckets) && numBuckets > 15 ? 'small' : undefined}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '180px' }}>Time Period</TableCell>
                {Object.values(AuditOrderTabs)
                  .filter((tab) => tab !== AuditOrderTabs.All)
                  .map((tab) => (
                    <TableCell key={tab}>{tab}</TableCell>
                  ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {auditReportData?.auditReport.map((data) => (
                // eslint-disable-next-line react/jsx-key
                <OrdersAuditReportTableRow data={data} />
              ))}
              {isNil(auditReportData?.auditReport) &&
                range(1, 12).map((key) => (
                  <TableRow key={key}>
                    {Object.values(AuditOrderTabs).map((tab) => (
                      <TableCell key={tab}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Stack>
      </Stack>
      {!isNil(currentBucket) && (
        <AuditReportOrdersTable bucket={currentBucket} />
      )}
    </Box>
  );
};

export default React.memo(AuditReport);
