import { Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import useMeAsThirdPartyUser from '../../../../common/react-hooks/use-me-as-third-party-user';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import CustomerPortalCompanyField from './customer-portal-company-field';

export enum SelectEntityType {
  Order = 'ORDER',
  Quote = 'QUOTE',
}

type CustomerPortalCompanySelectModalProps = {
  readonly onClose: () => void;
  readonly entityType: SelectEntityType;
};

const CustomerPortalCompanySelectModal = ({
  onClose,
  entityType,
}: CustomerPortalCompanySelectModalProps) => {
  const [companyUuid, setCompanyUuid] = useState<string | null>(null);
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const firstContactForCompany = thirdPartyUser?.contacts.find(
    (c) => c.company.uuid === companyUuid,
  );
  const navigate = useNavigate();

  const handleCreateOrder = () => {
    if (isNilOrEmptyString(firstContactForCompany?.uuid)) {
      return;
    }
    onClose();
    navigate(
      entityType === SelectEntityType.Order
        ? '/customer-portal/orders/add'
        : '/customer-portal/quotes/add',
      {
        state: { contactUuid: firstContactForCompany?.uuid },
      },
    );
  };

  return (
    <PalletModal
      open
      hideCloseButton
      title={
        entityType === SelectEntityType.Order
          ? 'Create a new order'
          : 'Get a quote'
      }
      pinnedElements={{
        bottomRight: (
          <Button
            variant="contained"
            disabled={isNilOrEmptyString(companyUuid)}
            onClick={handleCreateOrder}
          >
            Create order
          </Button>
        ),
      }}
      onClose={onClose}
    >
      <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
        <Stack sx={{ minWidth: '400px', gap: 1 }}>
          <Typography>Select a carrier</Typography>
          <CustomerPortalCompanyField
            companyUuid={companyUuid}
            onChange={setCompanyUuid}
          />
        </Stack>
      </OrderFormEditAccessProvider>
    </PalletModal>
  );
};

export default CustomerPortalCompanySelectModal;
