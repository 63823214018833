// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fade, IconButton, Stack, Tooltip } from '@mui/material';
import Popper from '@mui/material/Popper';
import { type RefObject } from 'react';
import { type LineHaulOrderFragment } from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import useLineHaulDispatchActions from '../../../hooks/use-line-haul-dispatch-actions';

const ManifestOrdersTableRowHoverMenu = ({
  anchorRef,
  manifestUuid,
  order,
  open,
}: {
  readonly anchorRef: RefObject<HTMLDivElement>;
  readonly manifestUuid: string;
  readonly order: LineHaulOrderFragment;
  readonly open: boolean;
}) => {
  const { removeSingleOrderFromManifest } = useLineHaulDispatchActions();

  return (
    <Popper
      transition
      keepMounted
      style={{ zIndex: 100 }}
      open={open}
      anchorEl={anchorRef?.current}
      placement="top-end"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={50}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              border: `1px solid ${theme.palette.borderColor.main}`,
              borderRadius: '5px',
              backgroundColor: 'white',
              mr: 0,
              mb: -10,
            }}
          >
            <Tooltip title="Remove from manifest">
              <IconButton
                sx={{ p: '5px' }}
                onClick={(e) => {
                  removeSingleOrderFromManifest({
                    uuid: manifestUuid,
                    orderUuid: order.uuid,
                  });
                  e.stopPropagation();
                }}
              >
                <DeleteIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Fade>
      )}
    </Popper>
  );
};

export default ManifestOrdersTableRowHoverMenu;
