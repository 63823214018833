import { Stack, Tab, Tabs } from '@mui/material';
import { isNil } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import TabPanel from '../../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../generated/graphql';
import EdiMessagesTable from './edi-messages-table';
import FreightSnapTransactionsTable from './freight-snap/freight-snap-transactions-table';
import ReviewIncomingOrders from './review-incoming-orders';

const REVIEW_EDI_API_CUSTOMER_PORTAL_TEXT = 'Review order requests';
const REVIEW_EDI_API_TEXT = 'Review EDI/API orders';
const EDI_MESSAGE_HISTORY_TEXT = 'EDI message history';
const FREIGHT_SNAP_TRANSACTIONS_TEXT = 'Freight Snap';

export enum InboundMessagesTabs {
  REVIEW_ORDERS,
  EDI_MESSAGE_HISTORY,
  FREIGHT_SNAP_TRANSACTIONS,
}

const isInboundMessagesTab = (tab: number): tab is InboundMessagesTabs => {
  return Object.values(InboundMessagesTabs).includes(tab);
};

const InboundMessagesPage = () => {
  const ffShowFreightSnapTable = useFeatureFlag(
    FeatureFlag.FF_SHOW_FREIGHT_SNAP_TABLE,
  );
  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const { userPermissions } = useUserRoles();
  const { canRead: canReadEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromSearchParams = searchParams.get('tab');

  const getCurrentTab = (): InboundMessagesTabs => {
    if (!canReadEdiApiOrders) {
      return InboundMessagesTabs.REVIEW_ORDERS;
    }
    if (!isNil(tabFromSearchParams)) {
      const tabIndex = Number.parseInt(tabFromSearchParams, 10);
      if (isInboundMessagesTab(tabIndex)) {
        return tabIndex;
      }
    }
    return InboundMessagesTabs.REVIEW_ORDERS;
  };
  const currentTab = getCurrentTab();

  const { companyConfiguration, loading } = useMe({
    onCompleted: ({ me }) => {
      if (!isNil(tabFromSearchParams)) {
        return;
      }
      const requiresReviewForEdiOrderCreation =
        me?.company?.configuration?.requireReviewForEdiOrderCreation === true;
      const defaultTab = requiresReviewForEdiOrderCreation
        ? InboundMessagesTabs.REVIEW_ORDERS
        : InboundMessagesTabs.EDI_MESSAGE_HISTORY;

      setSearchParams({ tab: defaultTab.toString() });
    },
  });

  if (loading || isNil(companyConfiguration)) {
    return <CenteredCircularProgress />;
  }

  return (
    <Stack height="100%" position="relative" sx={{ backgroundColor: 'white' }}>
      {canReadEdiApiOrders && (
        <Tabs
          orientation="horizontal"
          value={currentTab}
          aria-label="inbound messages page tabs"
          sx={{ padding: 2, paddingBottom: 0 }}
          onChange={(_e, newIndex: InboundMessagesTabs) => {
            setSearchParams({ tab: newIndex.toString() });
          }}
        >
          {companyConfiguration.requireReviewForEdiOrderCreation && (
            <Tab
              value={InboundMessagesTabs.REVIEW_ORDERS}
              label={
                ffCustomerPortalOrderEntry
                  ? REVIEW_EDI_API_CUSTOMER_PORTAL_TEXT
                  : REVIEW_EDI_API_TEXT
              }
            />
          )}
          <Tab
            value={InboundMessagesTabs.EDI_MESSAGE_HISTORY}
            label={EDI_MESSAGE_HISTORY_TEXT}
          />
          {ffShowFreightSnapTable && (
            <Tab
              value={InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS}
              label={FREIGHT_SNAP_TRANSACTIONS_TEXT}
            />
          )}
        </Tabs>
      )}
      {companyConfiguration.requireReviewForEdiOrderCreation &&
        currentTab === InboundMessagesTabs.REVIEW_ORDERS && (
          <TabPanel
            selectedValue={currentTab}
            panelValue={InboundMessagesTabs.REVIEW_ORDERS}
          >
            <ReviewIncomingOrders />
          </TabPanel>
        )}
      {currentTab === InboundMessagesTabs.EDI_MESSAGE_HISTORY && (
        <TabPanel
          selectedValue={currentTab}
          panelValue={InboundMessagesTabs.EDI_MESSAGE_HISTORY}
        >
          <EdiMessagesTable />
        </TabPanel>
      )}
      {currentTab === InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS &&
        ffShowFreightSnapTable && (
          <TabPanel
            selectedValue={currentTab}
            panelValue={InboundMessagesTabs.FREIGHT_SNAP_TRANSACTIONS}
          >
            <FreightSnapTransactionsTable />
          </TabPanel>
        )}
    </Stack>
  );
};

export default InboundMessagesPage;
