import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { exhaustive } from 'shared/switch';
import {
  type OrderForConsolidationFlowFragment,
  type ProrateOrdersMutationVariables,
  useProrateOrdersMutation,
} from '../../../../../generated/graphql';
import PalletModal from '../../../../../pallet-ui/modal/pallet-modal';

type ConfirmProrateModalProps = {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly onProrateSuccess: () => void;
  readonly mutationVariables: ProrateOrdersMutationVariables;
  readonly primaryOrder: OrderForConsolidationFlowFragment;
  readonly otherOrders: OrderForConsolidationFlowFragment[];
  readonly customerName: string;
};

const ConfirmProrateModal: React.FC<ConfirmProrateModalProps> = ({
  open,
  onCancel,
  onProrateSuccess,
  mutationVariables,
  primaryOrder,
  otherOrders,
  customerName,
}: ConfirmProrateModalProps) => {
  const [
    errorMessageForConsolidateAction,
    setErrorMessageForConsolidateAction,
  ] = useState<string>();

  const theme = useTheme();
  const [prorateOrders, { loading: prorateOrdersLoading }] =
    useProrateOrdersMutation({
      onCompleted: ({ prorateOrders: prorateOrdersResult }) => {
        const { __typename: typename } = prorateOrdersResult;
        switch (typename) {
          case 'ProrateOrdersSuccessOutput': {
            onProrateSuccess();
            break;
          }
          case 'MutationErrorOutput': {
            setErrorMessageForConsolidateAction(prorateOrdersResult.message);
            break;
          }
          case undefined: {
            break;
          }
          default: {
            exhaustive(typename);
          }
        }
      },
    });

  const handleConfirm = async () => {
    await prorateOrders({
      variables: mutationVariables,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(errorMessageForConsolidateAction)}
      >
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessageForConsolidateAction(undefined);
          }}
        >
          {errorMessageForConsolidateAction}
        </Alert>
      </Snackbar>
      <PalletModal
        open={open}
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            {/* <ErrorIcon color="error" /> */}
            Are you sure you want to consolidate these orders?
          </Stack>
        }
        pinnedElements={{
          bottomRight: (
            <Stack direction="row" gap={1}>
              <Button
                sx={{ color: theme.palette.concreteGrey[50] }}
                disabled={prorateOrdersLoading}
                onClick={onCancel}
              >
                No, cancel
              </Button>
              <Button
                sx={{ backgroundColor: theme.palette.airfreightBlue[60] }}
                variant="contained"
                disabled={prorateOrdersLoading}
                onClick={handleConfirm}
              >
                Yes, consolidate
              </Button>
            </Stack>
          ),
        }}
        onClose={onCancel}
      >
        {prorateOrdersLoading ? (
          <CircularProgress />
        ) : (
          <Stack
            direction="column"
            alignItems="flex-start"
            gap={1}
            height="100%"
          >
            <Typography variant="body1">
              The following{' '}
              {otherOrders.length > 0 ? otherOrders.length + 1 : ''} orders by{' '}
              {customerName} will be consolidated:{' '}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="90%"
              p={2}
            >
              {Array.from({ length: 3 }).map((_, colIndex) => {
                const allOrders = [primaryOrder, ...otherOrders];
                const numOrdersPerCol = Math.ceil(allOrders.length / 3);
                const startIndex = colIndex * numOrdersPerCol;
                const colOrders = allOrders.slice(
                  startIndex,
                  startIndex + numOrdersPerCol,
                );

                return (
                  <Stack key={null}>
                    {colOrders.map((order) => (
                      <Typography key={order.uuid}>
                        • {order.standardOrderFields.shipperBillOfLadingNumber}
                      </Typography>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
            <Typography variant="body1" fontWeight="bold">
              Order consolidation cannot be undone.
            </Typography>
          </Stack>
        )}
      </PalletModal>
    </>
  );
};

export default ConfirmProrateModal;
