import { type QueryHookOptions } from '@apollo/client';
import { useCallback } from 'react';
import {
  type FindPaginatedOrganizationsInput,
  type PaginatedOrganizationsQuery,
  type PaginatedOrganizationsQueryVariables,
  usePaginatedOrganizationsQuery,
} from '../../generated/graphql';

/**
 * Wraps the getOrderTableFieldValues query with a fixed set of filters and
 * fields to fetch customer portal orders for review.
 */
export const useOrganizationsForTable = ({
  first,
  last,
  after,
  before,
  searchText,
  isActive,
  ...queryHookOptions
}: FindPaginatedOrganizationsInput &
  QueryHookOptions<
    PaginatedOrganizationsQuery,
    PaginatedOrganizationsQueryVariables
  >) => {
  const { data, loading, error, refetch } = usePaginatedOrganizationsQuery({
    variables: {
      findPaginatedOrganizationsInput: {
        first,
        last,
        after,
        before,
        searchText,
        isActive,
      },
    },
    ...queryHookOptions,
  });

  const refetchWithInitialVariables = useCallback(
    async (variables: FindPaginatedOrganizationsInput) =>
      refetch({
        findPaginatedOrganizationsInput: {
          ...variables,
        },
      }),
    [refetch],
  );

  return {
    ...data?.paginatedOrganizations,
    loading,
    error,
    refetch: refetchWithInitialVariables,
  };
};
