import { createAsyncThunk } from '@reduxjs/toolkit';
import { centsToDollars } from 'shared/math';
import { type AccessorialStoreState } from 'shared/types';
import apolloClient from '../../../apollo-client';
import {
  AccessorialsDocument,
  type AccessorialsQuery,
  type AccessorialsQueryVariables,
} from '../../../generated/graphql';
import { type RootState } from '../../../redux/store';
import { upsertManyAccessorialRanges } from './accessorial-range-slice';
import { setAllAccessorials } from './accessorials-slice';
import { upsertManySpecialAccessorialChargeGroups } from './special-accessorial-charge-groups-slice';
import { upsertManySpecialAccessorialMatrixItems } from './special-accessorial-matrix-items-slice';
import { upsertManyZoneBasedAccessorialChargeGroups } from './zone-based-accessorial-charge-groups-slice';
import { upsertManyZoneBasedAccessorialMatrixItems } from './zone-based-accessorial-matrix-items-slice';
import { upsertManyZoneBasedAccessorialZones } from './zone-based-accessorial-zones-slice';

export const fetchAllAccessorials = createAsyncThunk<
  AccessorialStoreState[],
  void,
  { state: RootState }
>('accessorials/fetchAllAccessorials', async (arg, thunkAPI) => {
  const response = await apolloClient.query<
    AccessorialsQuery,
    AccessorialsQueryVariables
  >({
    query: AccessorialsDocument,
  });

  const { accessorials } = response.data;
  const accessorialsForRedux: AccessorialStoreState[] = [];

  for (const accessorial of accessorials) {
    switch (accessorial.__typename) {
      case 'UnitBasedAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'UnitBasedAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial?.name ?? '',
          ratePerUnit: accessorial?.ratePerUnit ?? 0,
          maximumCharge: accessorial.maximumCharge,
          minimumCharge: accessorial.minimumCharge,
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });

        break;
      }
      case 'ZoneBasedAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'ZoneBasedAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial.name,
          zoneIds: accessorial.zones.map((zone) => zone.uuid),
          chargeGroupIds: accessorial.chargeGroups.map(
            (chargeGroup) => chargeGroup.uuid,
          ),
          matrixItemIds: accessorial.matrixItems.map(
            (matrixItem) => matrixItem.uuid,
          ),
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });
        const zones = accessorial.zones.map((zone) => ({
          uuid: zone.uuid,
          name: zone.name,
        }));
        const chargeGroups = accessorial.chargeGroups.map((chargeGroup) => ({
          uuid: chargeGroup.uuid,
          name: chargeGroup.name,
        }));
        const matrixItems = accessorial.matrixItems.map((matrixItem) => ({
          uuid: matrixItem.uuid,
          zoneUuid: matrixItem.zoneUuid,
          chargeGroupUuid: matrixItem.chargeGroupUuid,
          rate: matrixItem.rate,
        }));
        thunkAPI.dispatch(upsertManyZoneBasedAccessorialZones(zones));
        thunkAPI.dispatch(
          upsertManyZoneBasedAccessorialChargeGroups(chargeGroups),
        );
        thunkAPI.dispatch(
          upsertManyZoneBasedAccessorialMatrixItems(matrixItems),
        );

        break;
      }
      case 'StandardAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'StandardAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial?.name ?? '',
          rate: accessorial?.rate ?? 0,
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });

        break;
      }
      case 'WeightBasedAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'WeightBasedAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial?.name ?? '',
          ratePerHundredWeight: accessorial?.ratePerHundredWeight ?? 0,
          maximumCharge: accessorial.maximumCharge,
          minimumCharge: accessorial.minimumCharge,
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
          rangeIds: accessorial.accessorialRanges?.map((acc) => acc.uuid) ?? [],
        });
        const ranges =
          accessorial.accessorialRanges?.map((range) => ({
            uuid: range.uuid,
            lessThanOrEqualToValue: range.lessThanOrEqualToValue ?? 0,
            rateUsdCents: centsToDollars(range?.rateUsdCents ?? 0),
          })) ?? [];
        thunkAPI.dispatch(upsertManyAccessorialRanges(ranges));

        break;
      }
      case 'WaitTimeAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'WaitTimeAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial?.name ?? '',
          maximumCharge: accessorial.maximumCharge,
          minimumCharge: accessorial.minimumCharge,
          quickbooksMappingId: accessorial.quickbooksMappingId,
          rate: accessorial?.rate ?? 0,
          waitTimeChargePeriod: accessorial.waitTimeChargePeriod,
          waitTimeFreeMinutes: accessorial.waitTimeFreeMinutes,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });

        break;
      }
      case 'SpecialAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'SpecialAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial.name,
          chargeGroupIds: accessorial.chargeGroups.map(
            (chargeGroup) => chargeGroup.uuid,
          ),
          matrixItemIds: accessorial.matrixItems.map(
            (matrixItem) => matrixItem.uuid,
          ),
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });
        const chargeGroups = accessorial.chargeGroups.map((chargeGroup) => ({
          uuid: chargeGroup.uuid,
          dayOfWeek: chargeGroup.dayOfWeek,
          startTime: chargeGroup.startTime,
          endTime: chargeGroup.endTime,
        }));
        const matrixItems = accessorial.matrixItems.map((matrixItem) => ({
          uuid: matrixItem.uuid,
          zoneUuid: matrixItem.zoneUuid,
          chargeGroupUuid: matrixItem.chargeGroupUuid,
          rate: matrixItem.rate,
        }));
        thunkAPI.dispatch(
          upsertManySpecialAccessorialChargeGroups(chargeGroups),
        );
        thunkAPI.dispatch(upsertManySpecialAccessorialMatrixItems(matrixItems));

        break;
      }
      case 'SkidBasedAccessorialEntity': {
        accessorialsForRedux.push({
          __typename: 'SkidBasedAccessorialEntity',
          uuid: accessorial.uuid,
          name: accessorial?.name ?? '',
          ratePerSkid: accessorial?.ratePerSkid ?? 0,
          maximumCharge: accessorial.maximumCharge,
          minimumCharge: accessorial.minimumCharge,
          quickbooksMappingId: accessorial.quickbooksMappingId,
          percentForSettlement: accessorial.percentForSettlement,
          isAuthoCodeRequired: accessorial.isAuthoCodeRequired,
          isDefaultGlobalSpecial: accessorial.isDefaultGlobalSpecial,
        });

        break;
      }
      default: {
        throw new Error('Unrecognized accessorial type');
      }
    }
  }

  thunkAPI.dispatch(setAllAccessorials(accessorialsForRedux));

  return accessorialsForRedux;
});
