import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import PalletButton from '../../../../../../pallet-ui/button/pallet-button';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import PrintMenu from '../right-sidebar/print-menu';

type OrderReviewActionsProps = {
  readonly onAccept: () => Promise<void>;
  readonly onReject: () => Promise<void>;
  readonly acceptLoading: boolean;
  readonly rejectLoading: boolean;
  readonly disabled?: boolean;
};

const OrderReviewActions = ({
  onAccept,
  onReject,
  acceptLoading,
  rejectLoading,
  disabled: propsDisabled = false,
}: OrderReviewActionsProps) => {
  const printButtonRef = useRef<HTMLButtonElement>(null);
  const [showPrintMenu, setShowPrintMenu] = useState(false);

  const { disabledIfNoAccess } = useOrderFormEditAccess();
  const disabled =
    disabledIfNoAccess || propsDisabled || acceptLoading || rejectLoading;

  return (
    <>
      <PrintMenu
        isEditMode
        showPrintMenu={showPrintMenu}
        buttonRef={printButtonRef.current}
        onClose={() => {
          setShowPrintMenu(false);
        }}
      />
      <Stack direction="row" gap={1}>
        <PalletButton
          ref={printButtonRef}
          variant="outlined"
          endIcon={<ArrowDropDownIcon />}
          onClick={() => {
            setShowPrintMenu(!showPrintMenu);
          }}
        >
          Print
        </PalletButton>
        <Box width={130}>
          <PalletButton
            fullWidth
            disabled={disabled}
            variant="outlined"
            color="error"
            loading={rejectLoading}
            onClick={onReject}
          >
            Reject
          </PalletButton>
        </Box>
        <Box width={130}>
          <PalletButton
            fullWidth
            disabled={disabled}
            variant="contained"
            color="info"
            loading={acceptLoading}
            onClick={onAccept}
          >
            Accept
          </PalletButton>
        </Box>
      </Stack>
    </>
  );
};

export default OrderReviewActions;
