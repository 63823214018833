import isEqual from 'fast-deep-equal';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import {
  type UseFormSetValue,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { isDevelopment } from '../../../../../environment-variables';
import {
  FreightBillingMethod,
  FulfillmentType,
  ShipmentType,
  StopType,
} from '../../../../../generated/graphql';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { type OrderFormFieldValues } from '../forms/types';
import {
  calculateTotalCharge,
  getInitialFreightChargeValues,
  getInitialOrderChargesShipmentValues,
} from '../forms/utils';
import {
  type OrderShipmentContext,
  type ShipmentPresenceStatus,
} from '../types';
import {
  contextToNamePrefix,
  getExpectedOrderChargesShipmentStatus,
  getExpectedOrderComponentStatus,
  shipmentHasCharges,
} from '../utils';

const doUpdateShipmentToMatchExpected = (
  context:
    | {
        shipmentType: ShipmentType.Regular;
        stopIdx: number;
        stopType: StopType;
      }
    | { shipmentType: ShipmentType.LineHaul | ShipmentType.OrderCharges },
  currentStatus: ShipmentPresenceStatus,
  expectedStatus: ShipmentPresenceStatus,
  setValue: UseFormSetValue<OrderFormFieldValues>,
  createInitialFreightCharge: (
    context:
      | {
          shipmentType: ShipmentType.Regular;
          stopIdx: number;
          stopType: StopType;
        }
      | {
          shipmentType: ShipmentType.LineHaul | ShipmentType.OrderCharges;
        },
  ) => void,
  createOrderChargesShipment: () => void,
) => {
  if (isEqual(currentStatus, expectedStatus)) {
    return;
  }

  const prefixPath:
    | `stops.${number}`
    | 'lineHaulShipment'
    | 'orderChargesShipment' =
    context.shipmentType === ShipmentType.Regular
      ? `stops.${context.stopIdx}`
      : context.shipmentType === ShipmentType.LineHaul
        ? 'lineHaulShipment'
        : 'orderChargesShipment';
  if (expectedStatus.present) {
    if (currentStatus.present) {
      if (
        expectedStatus.freightChargePresent &&
        !currentStatus.freightChargePresent
      ) {
        createInitialFreightCharge(context);
        // At runtime the 2nd clause is redundant, but it's here to make typescript happy
        if (
          context.shipmentType === ShipmentType.Regular &&
          (prefixPath === `stops.${INBOUND_STOP_IDX}` ||
            prefixPath === `stops.${OUTBOUND_STOP_IDX}`)
        ) {
          setValue(`${prefixPath}.hideFromBilling`, false);
        }
      } else if (
        !expectedStatus.freightChargePresent &&
        currentStatus.freightChargePresent
      ) {
        setValue(`${prefixPath}.freightCharge`, null);
        if (
          context.shipmentType === ShipmentType.Regular &&
          (prefixPath === `stops.${INBOUND_STOP_IDX}` ||
            prefixPath === `stops.${OUTBOUND_STOP_IDX}`)
        ) {
          setValue(`${prefixPath}.hideFromBilling`, true);
        }
      }
    } else if (context.shipmentType === ShipmentType.OrderCharges) {
      createOrderChargesShipment();
    } else {
      throw new Error(
        'Currently only order charges shipment can be created after the order form has been initialized',
      );
      // if (
      //   context.shipmentType === ShipmentType.Regular &&
      //   (prefixPath === `stops.${INBOUND_STOP_IDX}` ||
      //     prefixPath === `stops.${OUTBOUND_STOP_IDX}`)
      // ) {
      //   // TS 4 doesn't properly narrow this type -- TS 5 does; if we've bumped fully we can remove this
      //   setValue(
      //     `${prefixPath}.hideFromBilling` as `stops.${number}.hideFromBilling`,
      //     false,
      //   );
      // }
    }
  } else if (currentStatus.present) {
    setValue(`${prefixPath}`, null);
  }
};

export const useShipmentStatusCheck = () => {
  const { control } = useFormContext<OrderFormFieldValues>();

  const fulfillmentType = useWatch({ control, name: 'fulfillmentType' });
  const inboundStop = useWatch({ control, name: `stops.${INBOUND_STOP_IDX}` });
  const outboundStop = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}`,
  });
  const lineHaulShipment = useWatch({ control, name: 'lineHaulShipment' });
  const orderChargesShipment = useWatch({
    control,
    name: 'orderChargesShipment',
  });
  const isUsingLineHaul = useWatch({ control, name: 'isUsingLineHaul' });

  getExpectedOrderComponentStatus({
    fulfillmentType,
    inboundStop,
    outboundStop,
    lineHaulShipment,
    orderChargesShipment,
    isUsingLineHaul: isUsingLineHaul ?? false,
    debugLog: isDevelopment(),
  });
};

export const useUpdateShipmentToMatchExpected = () => {
  const { setValue, getValues } = useFormContext<OrderFormFieldValues>();

  const createOrderChargesShipment = useCallback(() => {
    const defaultFuelSurcharge = getValues('defaultFuelSurcharge');
    const defaultFreightBillingMethod = getValues(
      'defaultFreightBillingMethod',
    );
    const orderFulfillmentType = getValues('fulfillmentType');
    const existingOrderChargesShipment = getValues('orderChargesShipment');
    if (isNil(existingOrderChargesShipment)) {
      const orderChargesShipment = getInitialOrderChargesShipmentValues({
        defaultFuelSurcharge,
        freightBillingMethod:
          defaultFreightBillingMethod ?? FreightBillingMethod.FlatRate,
        fulfillmentType: orderFulfillmentType ?? FulfillmentType.MultiTrip,
      });
      setValue('orderChargesShipment', orderChargesShipment);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        'Refusing to create order charges shipment - already exists',
        {
          extra: {
            existingOrderChargesShipment,
          },
        },
      );
    }
  }, [getValues, setValue]);

  const createInitialFreightCharge = useCallback(
    (
      context:
        | {
            shipmentType: ShipmentType.Regular;
            stopIdx: number;
            stopType: StopType | null | undefined;
          }
        | {
            shipmentType: ShipmentType.LineHaul | ShipmentType.OrderCharges;
          },
    ) => {
      let freightChargePath:
        | `stops.${number}.freightCharge`
        | `lineHaulShipment.freightCharge`
        | 'orderChargesShipment.freightCharge';
      const { shipmentType } = context;
      switch (shipmentType) {
        case ShipmentType.Regular: {
          freightChargePath = `stops.${context.stopIdx}.freightCharge`;
          break;
        }
        case ShipmentType.LineHaul: {
          freightChargePath = 'lineHaulShipment.freightCharge';
          break;
        }
        case ShipmentType.OrderCharges: {
          freightChargePath = 'orderChargesShipment.freightCharge';
          break;
        }
        default: {
          exhaustive(shipmentType);
        }
      }

      const existingFreightCharge = getValues(freightChargePath);
      const defaultFuelSurcharge = getValues('defaultFuelSurcharge');
      const defaultFreightBillingMethod = getValues(
        'defaultFreightBillingMethod',
      );
      const orderFulfillmentType = getValues('fulfillmentType');
      if (isNil(existingFreightCharge)) {
        const freightCharge = getInitialFreightChargeValues({
          defaultFuelSurcharge,
          freightBillingMethod:
            defaultFreightBillingMethod ?? FreightBillingMethod.FlatRate,
          context,
          fulfillmentType: orderFulfillmentType ?? FulfillmentType.MultiTrip,
        });
        setValue(freightChargePath, freightCharge);
      }
    },
    [getValues, setValue],
  );

  const updateShipmentToMatchExpected = useCallback(
    (
      context:
        | {
            shipmentType: ShipmentType.Regular;
            stopIdx: number;
          }
        | { shipmentType: ShipmentType.LineHaul | ShipmentType.OrderCharges },
    ) => {
      const fulfillmentType = getValues('fulfillmentType');
      const inboundStop = getValues(`stops.${INBOUND_STOP_IDX}`);
      const outboundStop = getValues(`stops.${OUTBOUND_STOP_IDX}`);
      const lineHaulShipment = getValues('lineHaulShipment');
      const orderChargesShipment = getValues('orderChargesShipment');
      const isUsingLineHaul = getValues('isUsingLineHaul');

      const {
        currentInboundShipmentStatus,
        currentOutboundShipmentStatus,
        currentLineHaulShipmentStatus,
        currentOrderChargesShipmentStatus,
        expectedInboundShipmentsStatus,
        expectedOutboundShipmentsStatus,
        expectedLineHaulShipmentStatus,
        expectedOrderChargesShipmentStatus,
      } = getExpectedOrderComponentStatus({
        fulfillmentType,
        inboundStop,
        outboundStop,
        lineHaulShipment,
        orderChargesShipment,
        isUsingLineHaul: isUsingLineHaul ?? false,
      });

      const { shipmentType } = context;
      switch (shipmentType) {
        case ShipmentType.Regular: {
          if (context.stopIdx === INBOUND_STOP_IDX) {
            if (isNil(inboundStop?.stopType) || isNil(outboundStop?.stopType)) {
              return;
            }
            doUpdateShipmentToMatchExpected(
              {
                shipmentType: ShipmentType.Regular,
                stopIdx: INBOUND_STOP_IDX,
                stopType: inboundStop.stopType,
              },
              currentInboundShipmentStatus,
              expectedInboundShipmentsStatus,
              setValue,
              createInitialFreightCharge,
              createOrderChargesShipment,
            );
            return;
          }
          if (context.stopIdx === OUTBOUND_STOP_IDX) {
            if (isNil(inboundStop?.stopType) || isNil(outboundStop?.stopType)) {
              return;
            }
            doUpdateShipmentToMatchExpected(
              {
                shipmentType: ShipmentType.Regular,
                stopIdx: OUTBOUND_STOP_IDX,
                stopType: outboundStop.stopType,
              },
              currentOutboundShipmentStatus,
              expectedOutboundShipmentsStatus,
              setValue,
              createInitialFreightCharge,
              createOrderChargesShipment,
            );
            return;
          }
          throw new Error('invalid stopIdx');
        }
        case ShipmentType.LineHaul: {
          doUpdateShipmentToMatchExpected(
            context,
            currentLineHaulShipmentStatus,
            expectedLineHaulShipmentStatus,
            setValue,
            createInitialFreightCharge,
            createOrderChargesShipment,
          );
          return;
        }
        case ShipmentType.OrderCharges: {
          doUpdateShipmentToMatchExpected(
            context,
            currentOrderChargesShipmentStatus,
            expectedOrderChargesShipmentStatus,
            setValue,
            createInitialFreightCharge,
            createOrderChargesShipment,
          );
          return;
        }
        default: {
          exhaustive(shipmentType);
        }
      }
    },
    [
      createInitialFreightCharge,
      createOrderChargesShipment,
      getValues,
      setValue,
    ],
  );

  const updateAllShipmentsToMatchExpected = useCallback(() => {
    updateShipmentToMatchExpected({
      shipmentType: ShipmentType.Regular,
      stopIdx: INBOUND_STOP_IDX,
    });
    updateShipmentToMatchExpected({
      shipmentType: ShipmentType.Regular,
      stopIdx: OUTBOUND_STOP_IDX,
    });
    updateShipmentToMatchExpected({ shipmentType: ShipmentType.LineHaul });
    updateShipmentToMatchExpected({ shipmentType: ShipmentType.OrderCharges });
  }, [updateShipmentToMatchExpected]);

  return {
    createInitialFreightCharge,
    updateShipmentToMatchExpected,
    updateAllShipmentsToMatchExpected,
  };
};

const useShipmentHasCharges = (context: OrderShipmentContext) => {
  const shipmentPath = contextToNamePrefix(context);

  const { control } = useFormContext<OrderFormFieldValues>();
  const shipment = useWatch({
    control,
    name: shipmentPath,
  });

  return shipmentHasCharges(shipment);
};

/* If hideFromBilling is true *and* the shipment has no charges, we should not show this section at all
    as it indicates that the stop does not have charges and should not have charges. If hideFromBilling=true
    but there *is* a freight charge, we'll show 'Do not bill' in the freight-charge row dropdown to give
    the user the option to add back charges. In this case, all the other components (currently quick-add
    accessorials, fuel charge, and custom charges) will be hidden unless a charge is added back (by selecting
    something other than 'No charge' as the freight billing method.
*/
export const useShouldShowStopChargesSection = ({
  stopIdx,
}: {
  stopIdx: number;
}) => {
  const { control } = useFormContext<OrderFormFieldValues>();

  const stopType = useWatch({
    control,
    name: `stops.${stopIdx}.stopType`,
  });
  const hideFromBilling = useWatch({
    control,
    name: `stops.${stopIdx}.hideFromBilling`,
  });
  const customCharges = filterNotNil(
    useWatch({ control, name: `stops.${stopIdx}.customCharges` }) ?? [],
  );
  const freightTotal = useWatch({
    control,
    name: `stops.${stopIdx}.freightCharge.totalCharge`,
  });
  const fuelTotal = useWatch({
    control,
    name: `stops.${stopIdx}.freightCharge.fuelCharge.totalCharge`,
  });

  const totalCharge = calculateTotalCharge({
    freightChargeTotal: freightTotal ?? 0,
    fuelChargeTotal: fuelTotal ?? 0,
    customCharges,
  });

  const chargesShouldBeAllowed = !hideFromBilling;

  const hasCharges = useShipmentHasCharges({
    shipmentType: ShipmentType.Regular,
    stopIdx,
    inSettlement: false,
  });

  const stopTypesThatShouldNotHaveCharges =
    stopType === StopType.None ||
    stopType === StopType.PartnerCarrierDropoff ||
    stopType === StopType.PartnerCarrierPickup;
  const hasNonZeroTotal = totalCharge > 0;

  /**
   * Avoid displaying any charge sections for none or partner carrier stops.
   * Note: the non-zero total check is to ensure that, in the case of bad states where partner carrier stops
   * have non-zero total, we display the sections to ensure that the charges add up (this would be an invalid state but a consistent one).
   */
  if (stopTypesThatShouldNotHaveCharges && !hasNonZeroTotal) {
    return {
      showSection: false,
      allowAddCharges: false,
    };
  }

  return {
    showSection: chargesShouldBeAllowed || hasCharges,
    allowAddCharges: chargesShouldBeAllowed,
  };
};

// If the order charges shipment is expected to be present on the order,
// then we want to show the order charges section to allow the user to add charges
export const useShouldShowOrderChargesSection = () => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const fulfillmentType = useWatch({ control, name: 'fulfillmentType' });
  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });
  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });
  const chargesShouldBeAllowed = getExpectedOrderChargesShipmentStatus({
    fulfillmentType,
    inboundStopType,
    outboundStopType,
  }).present;
  const hasCharges = useShipmentHasCharges({
    shipmentType: ShipmentType.OrderCharges,
    inSettlement: false,
  });
  return {
    showSection: chargesShouldBeAllowed || hasCharges,
    allowAddCharges: chargesShouldBeAllowed,
  };
};
