import { FormHelperText, TextField } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import { type SyntheticEvent, useMemo } from 'react';
import useMeAsThirdPartyUser from '../../../../common/react-hooks/use-me-as-third-party-user';
import { type Option } from '../../../../common/types';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';

type CustomerPortalCompanyFieldProps = {
  readonly companyUuid: string | null;
  readonly onChange: (companyUuid: string) => void;
  readonly label?: string;
  readonly required?: boolean;
  readonly error?: boolean;
};

const CustomerPortalCompanyField = ({
  companyUuid,
  onChange,
  label,
  required = false,
  error = false,
}: CustomerPortalCompanyFieldProps) => {
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const distinctCompanies = useMemo(() => {
    if (isNil(thirdPartyUser)) {
      return [];
    }
    const companies = thirdPartyUser.contacts.map((c) => c.company);
    return uniqBy(companies, 'uuid');
  }, [thirdPartyUser]);

  const options: Option[] = useMemo(() => {
    return distinctCompanies.map((c) => ({
      value: c.uuid,
      label: c.name,
    }));
  }, [distinctCompanies]);

  const selectedOption = useMemo(() => {
    if (isNil(companyUuid)) return;
    const company = distinctCompanies.find((c) => c.uuid === companyUuid);
    return {
      value: companyUuid,
      label: company?.name ?? '',
    };
  }, [companyUuid, distinctCompanies]);

  const handleChange = (_event: SyntheticEvent, newValue: Option) => {
    onChange(newValue.value);
  };

  return (
    <>
      <AutocompleteFuzzy
        // Without key, the autocomplete doesn't display the selected option
        // when the form is populated (e.g. after fetching an existing order)
        key={selectedOption?.value}
        autoHighlight
        disableClearable
        value={selectedOption}
        matchSortOptions={{ keys: ['label'] }}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            size="small"
            required={required}
            error={error}
          />
        )}
        size="small"
        isOptionEqualToValue={(o, v) => o.value === v?.value}
        disabled={disabledIfNoAccess || distinctCompanies.length === 1}
        onChange={handleChange}
      />
      {error && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          Carrier is required
        </FormHelperText>
      )}
    </>
  );
};

export default CustomerPortalCompanyField;
