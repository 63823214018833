import PalletButton from '../../../pallet-ui/button/pallet-button';
import PalletModal from '../../../pallet-ui/modal/pallet-modal';
import { type SettlementBillLineItem } from '../types';
import { chain, isNil } from 'lodash';
import {
  type ExistingSettlementBillInput,
  useAddLineItemsToSettlementBillsMutation,
  useDriverSettlementBillsQuery,
  type NewSettlementBillInput,
} from '../../../generated/graphql';
import { useCallback, useMemo, useState } from 'react';
import SettlementPreviewCardV2 from './settlement-preview-card-v2';
import { isNotNil } from 'shared/optional';
import { Stack } from '@mui/material';

type SendToSettlementsModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly settlementBillLineItems: SettlementBillLineItem[];
  readonly selectedSettlementBillLineItemIds: Set<string>;
  readonly refresh: () => Promise<void>;
};

const SendToSettlementsModal = ({
  open,
  onClose,
  settlementBillLineItems,
  selectedSettlementBillLineItemIds,
  refresh,
}: SendToSettlementsModalProps) => {
  const [addLineItemsToSettlementBills, { loading }] =
    useAddLineItemsToSettlementBillsMutation();

  const onSendToSettlements = async () => {
    const newSettlements: NewSettlementBillInput[] = [];
    const existingSettlements: ExistingSettlementBillInput[] = [];

    for (const [driverUuid, { lineItems }] of Object.entries(
      driverLineItemGroups,
    )) {
      if (isNil(selectedSettlementBillUuids[driverUuid])) {
        newSettlements.push({
          driverUuid,
          settlementBillLineItemIds: lineItems.map((item) => item.id),
        });
      } else {
        existingSettlements.push({
          driverSettlementBillUuid: selectedSettlementBillUuids[driverUuid],
          settlementBillLineItemIds: lineItems.map((item) => item.id),
        });
      }
    }

    await addLineItemsToSettlementBills({
      variables: {
        addLineItemsToSettlementBillsInput: {
          settlementBillCreateInputs: newSettlements,
          settlementBillUpdateInputs: existingSettlements,
        },
      },
    });

    setSelectedSettlementBillUuids({});
    onClose();
    await refresh();
  };

  // Dictionary of driver UUIDs to selected settlement bill UUIDs
  // if the user decides to send line items to an existing settlement bill
  const [selectedSettlementBillUuids, setSelectedSettlementBillUuids] =
    useState<Record<string, string | null>>({});

  const setSelectedSettlementBillUuidForDriver = useCallback(
    (driverUuid: string) => {
      return (selection: string | null) => {
        setSelectedSettlementBillUuids((prev) => ({
          ...prev,
          [driverUuid]: selection,
        }));
      };
    },
    [],
  );

  // Set of unique driver UUIDs for the selected line items
  const selectedDriverUuids = useMemo(
    () =>
      settlementBillLineItems
        .filter((item) => selectedSettlementBillLineItemIds.has(item.id))
        .map((item) => item.driver?.uuid)
        // eslint-disable-next-line unicorn/no-array-callback-reference
        .filter(isNotNil),
    [settlementBillLineItems, selectedSettlementBillLineItemIds],
  );

  // Fetch existing settlement bills for the selected drivers
  // To populate the dropdown for the user to select an existing settlement bill
  const driverSettlementBills = useDriverSettlementBillsQuery({
    variables: {
      driverUuids: selectedDriverUuids,
    },
    skip: selectedDriverUuids.length === 0 || !open,
  });

  const existingSettlementBillsByDriverUuid = useMemo(
    () =>
      chain(driverSettlementBills.data?.driverSettlementBills.edges)
        .map((edge) => ({
          uuid: edge.node.uuid,
          name: edge.node.name,
          driverUuid: edge.node.driver.uuid,
        }))
        .groupBy((node) => node.driverUuid)
        .value(),
    [driverSettlementBills.data?.driverSettlementBills.edges],
  );

  const driverLineItemGroups = useMemo(
    () =>
      chain(settlementBillLineItems)
        .filter((item) => selectedSettlementBillLineItemIds.has(item.id))
        .groupBy((item) => item.driver?.uuid)
        .mapValues((items, _) => ({
          lineItems: items,
        }))
        .value(),
    [settlementBillLineItems, selectedSettlementBillLineItemIds],
  );

  return (
    <PalletModal open={open} title="Review Settlements" onClose={onClose}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        marginBottom={1}
      >
        <PalletButton
          variant="contained"
          loading={loading}
          onClick={async () => {
            await onSendToSettlements();
          }}
        >
          Confirm
        </PalletButton>
      </Stack>
      {Object.entries(driverLineItemGroups).map(
        ([driverUuid, { lineItems }]) => (
          <SettlementPreviewCardV2
            key={driverUuid}
            settlementBillLineItems={lineItems}
            existingSettlementBills={
              existingSettlementBillsByDriverUuid[driverUuid] ?? []
            }
            selectedSettlementBillUuid={selectedSettlementBillUuids[driverUuid]}
            onSettlementBillSelect={setSelectedSettlementBillUuidForDriver(
              driverUuid,
            )}
          />
        ),
      )}
    </PalletModal>
  );
};

export default SendToSettlementsModal;
