import {
  Box,
  CircularProgress,
  // eslint-disable-next-line
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ErrorBanner from '../../../../common/components/error-banner';
import InfoBanner from '../../../../common/components/info-banner';
import useMe from '../../../../common/react-hooks/use-me';
import {
  type InvoiceForInvoiceSelectionFragment,
  InvoiceStatus,
  type MoveShipmentsBetweenInvoicesOutput,
  useInvoicesForInvoiceSelectionLazyQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import PalletModalOld from '../../../../pallet-ui/modal/pallet-modal-old';
import { getInvoiceNameOrJournalNumber } from './invoice-name-or-journal-number';

type Option = {
  label: string;
  date: Date;
  dueDate: Date;
  value: string;
};

type InvoiceSelectionModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly contactUuid?: string;
  readonly handleConfirm: (newInvoiceUuid: string) => void;
  readonly moveOperationOutput: MoveShipmentsBetweenInvoicesOutput | null;
  readonly moveOperationLoading: boolean;
};

const getOptionFromInvoice = (
  invoice: InvoiceForInvoiceSelectionFragment,
  useJournalNumberForInvoice?: boolean | null,
): Option => {
  return {
    label:
      getInvoiceNameOrJournalNumber({
        invoice,
        useJournalNumberForInvoice,
      }) ?? 'Unnamed invoice',
    date: invoice.date,
    dueDate: invoice.dueDate,
    value: invoice.uuid,
  };
};

const SelectInvoiceToMoveToModal = ({
  isOpen,
  onClose,
  contactUuid,
  handleConfirm,
  moveOperationOutput,
  moveOperationLoading,
}: InvoiceSelectionModalProps) => {
  const { companyConfiguration } = useMe();
  const [searchText, setSearchText] = useState<string>();
  const [debouncedSearchText] = useDebounce(searchText, 250);
  const [selectedOption, setSelectedOption] = useState<Option | null>();
  const [getInvoices] = useInvoicesForInvoiceSelectionLazyQuery();
  const [invoices, setInvoices] =
    useState<InvoiceForInvoiceSelectionFragment[]>();

  const fetchInvoices = async () => {
    const res = await getInvoices({
      variables: {
        statuses: InvoiceStatus.NotFinalized,
        billingPartyContactUuid: contactUuid,
        searchText,
        first: 15,
      },
    });
    setInvoices(res.data?.invoices.edges.map(({ node }) => node));
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, debouncedSearchText]);

  return (
    <PalletModalOld
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      title="Select invoice to move to"
      actions={{
        bottomRight: [
          {
            children: 'Confirm',
            color: 'info',
            onClick: () => {
              const invoiceUuid = selectedOption?.value;
              if (!isNil(invoiceUuid)) {
                handleConfirm(invoiceUuid);
              }
            },
          },
        ],
      }}
      onClose={handleClose}
    >
      <Grid item xs={12} sx={{ height: '100%' }}>
        <Stack direction="column" spacing={2}>
          <AutocompleteFuzzy
            size="small"
            sx={{ backgroundColor: 'white', width: '100%' }}
            value={selectedOption}
            options={
              invoices?.map((invoice) =>
                getOptionFromInvoice(
                  invoice,
                  companyConfiguration?.useJournalNumberForInvoice,
                ),
              ) ?? []
            }
            matchSortOptions={{ keys: ['label'] }}
            componentsProps={{
              popper: { style: { width: '30%' } },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  <Grid container sx={{ width: '100%' }} alignItems="center">
                    <Grid item xs={12}>
                      <Typography color="text.primary">
                        {option.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {!isNil(option.date) && !isNil(option.dueDate) && (
                        <Typography color="text.secondary" variant="caption">
                          {dayjs(option.date).format('MM/DD')} -{' '}
                          {dayjs(option.dueDate).format('MM/DD/YY')}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
            onChange={(event, option) => {
              setSelectedOption(option);
            }}
          />
          {moveOperationLoading && <CircularProgress />}
          {!isNil(moveOperationOutput) &&
            moveOperationOutput.__typename === 'MutationErrorOutput' && (
              <ErrorBanner errorMessage={moveOperationOutput.message} />
            )}
          {!isNil(moveOperationOutput) &&
            moveOperationOutput.__typename ===
              'MoveShipmentsBetweenInvoicesIncompleteOperationOutput' && (
              <InfoBanner
                message={`These orders will also be moved: ${moveOperationOutput.missingOrders.map((o) => o.name).join(', ')}. Click confirm again to move.`}
              />
            )}
        </Stack>
      </Grid>
    </PalletModalOld>
  );
};

export default SelectInvoiceToMoveToModal;
