import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import { IconButton, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  type StopOnRouteFragment,
  StopStatus,
} from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import { useUnassignStopsRouteActions } from '../../hooks/use-unassign-stops-route-actions';
import RouteStopAssignInput from './route-stop-assign-input';

type RouteStopCardHoverMenuProps = {
  readonly stop: StopOnRouteFragment;
  readonly setIsEditing: Dispatch<SetStateAction<boolean>>;
  readonly showEdit?: boolean;
  /**
   * We want to set left: 0 when using this component as AG Grid cell content
   * Otherwise, there's empty space to the left of the cell content: https://github.com/ag-grid/ag-grid/issues/2215
   * left: 0 "stretches" this component's content to fill the empty space
   */
  readonly left?: number;
  readonly top?: number;
};

const RouteStopCardHoverMenu = ({
  stop,
  setIsEditing,
  showEdit = true,
  left,
  top,
}: RouteStopCardHoverMenuProps) => {
  const theme = useTheme();
  const [reassignMenuRef, setReassignMenuRef] = useState<HTMLElement | null>(
    null,
  );
  const reassignMenuRefOpen = Boolean(reassignMenuRef);
  const { unassignStop } = useUnassignStopsRouteActions();
  const [setShowUnassignedSnackbar, setErrorMessage] = useDispatchStore(
    (state) => [state.setShowUnassignedSnackbar, state.setErrorMessage],
    shallow,
  );

  const closeReassignMenu = () => {
    setReassignMenuRef(null);
  };

  return (
    <Stack
      position="absolute"
      left={left}
      top={top}
      right={0}
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      zIndex={1000}
      border={`1px solid ${theme.palette.borderColor.main}`}
      borderRight={0}
      borderRadius={1}
      sx={{ backgroundColor: 'white' }}
    >
      {stop.status === StopStatus.NotArrived && (
        <>
          {showEdit && (
            <IconButton
              onClick={async (e) => {
                setIsEditing(true);
                e.stopPropagation();
              }}
            >
              <EditIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          )}
          <IconButton
            onClick={async (e) => {
              setReassignMenuRef(e.currentTarget);
              e.stopPropagation();
            }}
          >
            <EditLocationOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </>
      )}
      {stop.status !== StopStatus.Completed && (
        <IconButton
          onClick={async (e) => {
            const success = await unassignStop({
              routeUuid: stop.routeSlot?.route?.uuid,
              slotUuid: stop.routeSlot?.uuid,
              stopUuid: stop.uuid,
              refetch: true,
              emitMultiplayerEvent: true,
            });
            if (success) {
              setShowUnassignedSnackbar(true);
            } else {
              setErrorMessage(
                'Failed to unassign stop because the route has changed - please try again.',
              );
            }
            e.stopPropagation();
          }}
        >
          <DeleteIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
      {reassignMenuRefOpen && (
        <Menu
          anchorEl={reassignMenuRef}
          open={reassignMenuRefOpen}
          onClose={closeReassignMenu}
        >
          <MenuItem sx={{ width: '300px' }}>
            <RouteStopAssignInput
              stopUuids={[stop.uuid]}
              currentRouteUuid={stop.routeSlot?.route?.uuid}
            />
          </MenuItem>
        </Menu>
      )}
    </Stack>
  );
};

export default RouteStopCardHoverMenu;
