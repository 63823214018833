import { exhaustive } from 'shared/switch';
import { QuoteSource } from '../../generated/graphql';

export const getQuoteSourceDisplayName = (source: QuoteSource): string => {
  switch (source) {
    case QuoteSource.CustomerPortal: {
      return 'Customer Portal';
    }
    case QuoteSource.ManuallyCreated: {
      return 'Manually Created';
    }
    default: {
      return exhaustive(source);
    }
  }
};
