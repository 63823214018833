import { Box, MenuItem, TextField } from '@mui/material';
import { type TariffChainFragment } from '../../../../generated/graphql';

type TariffChainDropdownProps = {
  readonly label: string;
  readonly value: string | null;
  readonly tariffChains: TariffChainFragment[];
  readonly disabled: boolean;
  readonly onChange: (value: string | null) => void;
};

const TariffChainDropdown = ({
  label,
  value,
  tariffChains,
  disabled,
  onChange,
}: TariffChainDropdownProps) => {
  return (
    <Box flexGrow={1}>
      <TextField
        fullWidth
        select
        size="small"
        disabled={disabled}
        label={label}
        value={value ?? ''}
        onChange={(event) => {
          onChange(event.target.value === '' ? null : event.target.value);
        }}
      >
        {tariffChains.map((tariffChain) => (
          <MenuItem key={tariffChain.id} value={tariffChain.id}>
            {tariffChain.name}
          </MenuItem>
        ))}
        <MenuItem value="">None</MenuItem>
      </TextField>
    </Box>
  );
};

export default TariffChainDropdown;
