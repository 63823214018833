import { Stack, Tab, Tabs, useTheme } from '@mui/material';
import { type SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaddingTabPanel } from '../../common/components/tab-panel';
import {
  OrganizationType,
  useOrganizationQuery,
} from '../../generated/graphql';
import BillingPanel from '../contacts/components/billing-panel';
import NotificationsPanel from '../contacts/components/notifications/notifications-panel';
import ThirdPartyUsersPanel from '../contacts/components/third-party-users-panel';
import {
  OrganizationPageMode,
  type OrganizationPageProps,
  OrganizationPageTabs,
} from './enums';
import GeneralTab from './organization-tabs/general/general-tab';
import AddressBookTab from './organization-tabs/address-book/address-book-tab';

const OrganizationPage = (props: OrganizationPageProps) => {
  const navigate = useNavigate();
  const {
    data,
    loading: dataLoading,
    refetch: refetchOrganization,
  } = useOrganizationQuery({
    skip: props.mode === OrganizationPageMode.CREATE,
    variables: {
      input: { uuid: props.uuid ?? '' },
    },
  });

  const [currentTab, setCurrentTab] = useState<OrganizationPageTabs>(
    OrganizationPageTabs.GENERAL,
  );

  const theme = useTheme();

  const handleChangeTab = (
    event: SyntheticEvent,
    newValue: OrganizationPageTabs,
  ) => {
    setCurrentTab(newValue);
  };

  const showCustomerOnlyTabs =
    props.mode === OrganizationPageMode.EDIT &&
    (data?.organization?.types ?? []).includes(OrganizationType.Customer);

  const billingTab = showCustomerOnlyTabs ? (
    <BillingPanel contactUuid={props.uuid} />
  ) : null;

  const notificationTab = showCustomerOnlyTabs ? (
    <NotificationsPanel contactUuid={props.uuid} />
  ) : null;

  const thirdPartyUsersTab = showCustomerOnlyTabs ? (
    <ThirdPartyUsersPanel contactUuid={props.uuid} />
  ) : null;

  const addressBookTab = showCustomerOnlyTabs ? (
    <AddressBookTab organizationUuid={props.uuid} />
  ) : null;

  return (
    <Stack
      width="100%"
      height="100%"
      flexDirection="column"
      p={1}
      bgcolor={theme.palette.concreteGrey[10]}
    >
      <Tabs
        value={currentTab}
        aria-label="add-contact-tabs"
        onChange={handleChangeTab}
      >
        <Tab
          // Leads to weird states if navigating away in the middle of creating a new contact or address
          label="General"
          value={OrganizationPageTabs.GENERAL}
        />
        {showCustomerOnlyTabs && (
          <Tab label="Billing" value={OrganizationPageTabs.BILLING} />
        )}
        {showCustomerOnlyTabs && (
          <Tab
            label="Notifications"
            value={OrganizationPageTabs.NOTIFICATIONS}
          />
        )}
        {showCustomerOnlyTabs && (
          <Tab
            label="Third party users"
            value={OrganizationPageTabs.THIRD_PARTY_USERS}
          />
        )}
        {showCustomerOnlyTabs && (
          <Tab label="Address book" value={OrganizationPageTabs.ADDRESS_BOOK} />
        )}
      </Tabs>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.GENERAL}
        selectedValue={currentTab}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <GeneralTab
          pageProps={props}
          organizationData={data?.organization ?? null}
          dataLoading={dataLoading}
        />
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.BILLING}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {billingTab}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.NOTIFICATIONS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {notificationTab}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.THIRD_PARTY_USERS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {thirdPartyUsersTab}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.ADDRESS_BOOK}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {addressBookTab}
      </PaddingTabPanel>
    </Stack>
  );
};

export default OrganizationPage;
