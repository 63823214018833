import { type GridApi } from 'ag-grid-community';
import dayjs, { type Dayjs } from 'dayjs';
import { isEmpty, isNil, min } from 'lodash';
import { getNoonOfDay } from 'shared/date';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  DISPATCH_STORE_KEY,
  PLANNING_DATE_EXPIRATION_KEY,
  PLANNING_DATE_KEY,
} from '../../common/local-storage/keys';
import {
  type StopBaseFragment,
  type StopType,
  type RouteFragment,
  type RoutePathFragment,
  type StopOnRouteFragment,
} from '../../generated/graphql';
import globalStore from '../../layouts/dashboard/global-store';
import { MIN_CARD_WIDTH } from './types/dimensions';
import {
  type LastUnassignedData,
  MapRoutePathType,
  RouteSortType,
} from './types/routes';
import {
  getLinkedStopIndicesOutOfOrder,
  reorderRoute,
  reverseRoute,
  searchRoutes,
  sortRoutes,
} from './utils';

type RouteGridApi = {
  routeUuid: string;
  api: GridApi<StopOnRouteFragment>;
};

const ROUTE_PAGE_SIZE_TO_RENDER = 10;
type DispatchState = {
  planningDate: Dayjs | undefined;
  showMap: boolean;
  sortType: RouteSortType;
  sortAsc: boolean;
  selectingRoutes: boolean;
  shouldRefreshGrid: boolean;
  useMinimizedAppointmentTime: boolean;
  mapRoutePathType: MapRoutePathType;
  showUnassignedStopsInMap: boolean;
  numberOfStops: number;
  routeCardWidth: number;
  showUnassignedSnackbar: boolean;
  errorMessage: string | undefined;
  filteredRoutes: RouteFragment[] | undefined;
  routesVersionId: string | undefined;
  routes: RouteFragment[];
  routePaths: Array<RoutePathFragment | null>;
  routesLoading: boolean;
  routeSearchText: string;
  openedOrderUuid: string | undefined;
  openedRouteUuid: string | undefined;
  selectedWarehouseUuid: string | undefined;
  selectedMapStop: StopOnRouteFragment | StopBaseFragment | undefined;
  selectedRouteUuids: string[]; // tracking selected routes for routes tab
  selectedMapRouteUuids: string[]; // tracking selcted routes for map tab
  selectedStopUuids: string[];
  routeUuidsLoadingStops: string[]; // this is a queue of the routes which are to be loaded
  unrenderAllStops: boolean;
  hoveredOrderUuid: string | undefined;
  hoveredStopUuid: string | undefined;
  lastUnassignedData: LastUnassignedData | undefined;
  arrangeStopsAndRoutesVertically: boolean;
  selectedStopsForBulkAction: StopOnRouteFragment[];
  showCompleteStops: boolean;
  stopTypeFilter?: StopType;
  filteredTagUuids: string[];
  numberOfRoutesRendered: number;
  routeGridApis: RouteGridApi[];
  selectedViewUuid: string | undefined;
};

type DispatchActions = {
  setPlanningDate: (newPlanningDate: Dayjs, companyTimezone: string) => void;
  setShowMap: (show: boolean) => void;
  setSortAsc: (asc: boolean) => void;
  setSortType: (sortType: RouteSortType) => void;
  setSelectingRoutes: (selecting: boolean) => void;
  setShouldRefreshGrid: (shouldRefresh: boolean) => void;
  setUseMinimizedAppointmentTime: (useMinimized: boolean) => void;
  setMapRoutePathType: (mapRoutePathType: MapRoutePathType) => void;
  setShowUnassignedStopsInMap: (showUnassignedStopsInMap: boolean) => void;
  setNumberOfStops: (numberOfStops: number) => void;
  setRouteCardWidth: (width: number) => void;
  setShowUnassignedSnackbar: (show: boolean) => void;
  setErrorMessage: (message: string | undefined) => void;
  setFilteredRoutes: (routes: RouteFragment[] | undefined) => void;
  setRoutesVersionId: (versionId: string | undefined) => void;
  setRouteSearchText: (text: string) => void;
  setRoutes: (routes: RouteFragment[]) => void;
  setRoutesLoading: (loading: boolean) => void;
  getRouteByUuid: (uuid: string) => RouteFragment | undefined;
  getRoutesByOrderUuid: (orderUuid: string) => RouteFragment[] | undefined;
  filterRoutes: () => void;
  addRoute: (route: RouteFragment) => void;
  setRoute: (route: RouteFragment, versionId?: string | null) => void;
  reverseRoute: (uuid: string) => RouteFragment | undefined;
  deleteRoute: (uuid: string) => void;
  reorderRouteSlots: (
    routeUuid: string,
    startIndex: number,
    endIndex: number,
    swap: boolean,
  ) => RouteFragment | undefined;
  removeSlotsFromRoute: (routeUuid: string, slotUuids: string[]) => void;
  setRoutePaths: (routePaths: Array<RoutePathFragment | null>) => void;
  upsertRoutePath: (routePath: RoutePathFragment) => void;
  deleteRoutePath: (routeUuid: string) => void;
  setOpenedOrderUuid: (uuid: string | undefined) => void;
  setOpenedRouteUuid: (uuid: string | undefined) => void;
  setSelectedWarehouseUuid: (warehouseUuid: string | undefined) => void;
  getSelectedMapStop: () => StopOnRouteFragment | StopBaseFragment | undefined;
  setSelectedMapStop: (
    stop: StopOnRouteFragment | StopBaseFragment | undefined,
  ) => void;
  selectRouteUuid: (routeUuid: string) => void;
  deselectRouteUuid: (routeUuid: string) => void;
  selectRouteUuids: (routeUuids: string[]) => void;
  selectMapRouteUuid: (routeUuid: string) => void;
  deselectMapRouteUuid: (routeUuid: string) => void;
  setSelectedMapRouteUuids: (routeUuids: string[]) => void;
  setAllRouteUuidsLoadingStops: () => void;
  clearRouteUuidsLoadingStops: () => void;
  setUnrenderAllStops: (unrender: boolean) => void;
  markRouteAsRendered: (routeUuid: string) => void;
  selectAllRoutes: () => void;
  deselectAllRoutes: () => void;
  setSelectedStopUuids: (uuids: string[]) => void;
  toggleSelectedStopUuid: (uuid: string, shouldSelect: boolean) => void;
  setHoveredOrderUuid: (uuid: string | undefined) => void;
  setHoveredStopUuid: (uuid: string | undefined) => void;
  setLastUnassignedData: (data: LastUnassignedData | undefined) => void;
  setArrangeStopsAndRoutesVertically: (verticallyArrange: boolean) => void;
  toggleSelectedStopForBulkAction: (stop: StopOnRouteFragment) => void;
  setSelectedStopsForBulkAction: (stops: StopOnRouteFragment[]) => void;
  setShowCompleteStops: (showCompleteStops: boolean) => void;
  setStopTypeFilter: (stopType: StopType | undefined) => void;
  setFilteredTagUuids: (tagUuids: string[]) => void;
  updateNumberOfRoutesRendered: (numberOfRoutes?: number) => void;
  getRouteGridApis: () => RouteGridApi[];
  setRouteGridApi: ({
    routeUuid,
    gridApi,
  }: {
    routeUuid: string;
    gridApi: GridApi<StopOnRouteFragment> | null;
  }) => void;
  setSelectedViewUuid: (uuid: string | undefined) => void;
};

const useDispatchStore = create(
  persist(
    immer<DispatchState & DispatchActions>((set, get) => ({
      planningDate: undefined,
      showMap: false,
      sortType: RouteSortType.CREATED_TIME,
      sortAsc: false,
      selectingRoutes: false,
      shouldRefreshGrid: false,
      useMinimizedAppointmentTime: false,
      mapRoutePathType: MapRoutePathType.PATH,
      showUnassignedStopsInMap: true,
      numberOfStops: 5,
      routeCardWidth: MIN_CARD_WIDTH,
      showRouteDetails: true,
      showUnassignedSnackbar: false,
      errorMessage: undefined,
      filteredRoutes: undefined,
      routesVersionId: undefined,
      routes: [],
      routePaths: [],
      routesLoading: false,
      routeSearchText: '',
      routesWithoutStops: [],
      openedOrderUuid: undefined,
      openedRouteUuid: undefined,
      isEditingOpenedRoute: false,
      selectedWarehouseUuid: undefined,
      selectedMapStop: undefined,
      selectedRouteUuids: [],
      selectedMapRouteUuids: [],
      selectedStopUuids: [],
      routeUuidsLoadingStops: [],
      unrenderAllStops: true,
      hoveredOrderUuid: undefined,
      hoveredStopUuid: undefined,
      lastUnassignedData: undefined,
      arrangeStopsAndRoutesVertically: false,
      selectedStopsForBulkAction: [],
      showCompleteStops: true,
      stopTypeFilter: undefined,
      filteredTagUuids: [],
      numberOfRoutesRendered: ROUTE_PAGE_SIZE_TO_RENDER,
      routeGridApis: [],
      selectedViewUuid: undefined,
      setPlanningDate: (newPlanningDate: Dayjs, companyTimezone: string) => {
        set((state) => {
          state.planningDate = dayjs(
            getNoonOfDay(newPlanningDate, companyTimezone),
          );
          localStorage.setItem(
            PLANNING_DATE_KEY,
            state.planningDate.toISOString(),
          );
          const endOfToday = dayjs().endOf('day');
          localStorage.setItem(
            PLANNING_DATE_EXPIRATION_KEY,
            endOfToday.toISOString(),
          );
        });
      },
      setShowMap: (show: boolean) => {
        set((state) => {
          state.showMap = show;
        });
      },
      setSortAsc: (asc: boolean) => {
        set((state) => {
          state.sortAsc = asc;
        });
      },
      setSortType: (sortType: RouteSortType) => {
        set((state) => {
          state.sortType = sortType;
        });
      },
      setSelectingRoutes: (selecting: boolean) => {
        set((state) => {
          state.selectingRoutes = selecting;
        });
      },
      setShouldRefreshGrid: (shouldRefresh: boolean) => {
        set((state) => {
          state.shouldRefreshGrid = shouldRefresh;
        });
      },
      setRouteCardWidth: (width: number) => {
        set((state) => {
          state.routeCardWidth = width;
        });
      },
      setUseMinimizedAppointmentTime: (useMinimzed: boolean) => {
        set((state) => {
          state.useMinimizedAppointmentTime = useMinimzed;
        });
      },
      setMapRoutePathType: (mapRoutePathType: MapRoutePathType) => {
        set((state) => {
          state.mapRoutePathType = mapRoutePathType;
        });
      },
      setShowUnassignedStopsInMap: (showUnassignedStopsInMap: boolean) => {
        set((state) => {
          state.showUnassignedStopsInMap = showUnassignedStopsInMap;
        });
      },
      setNumberOfStops: (numberOfStops: number) => {
        set((state) => {
          state.numberOfStops = numberOfStops;
        });
      },
      setShowUnassignedSnackbar: (show: boolean) => {
        set((state) => {
          state.showUnassignedSnackbar = show;
        });
      },
      setErrorMessage: (message: string | undefined) => {
        set((state) => {
          state.errorMessage = message;
        });
      },
      setFilteredRoutes: (routes: RouteFragment[] | undefined) => {
        set((state) => {
          state.filteredRoutes = routes;
        });
      },
      setRouteSearchText: (text: string) => {
        set((state) => {
          state.routeSearchText = text;
        });
        get().filterRoutes();
      },
      setRoutesVersionId: (versionId: string | undefined) => {
        set((state) => {
          state.routesVersionId = versionId;
        });
      },
      setRoutes: (routes: RouteFragment[]) => {
        set((state) => {
          state.routes = routes;
        });
        get().filterRoutes();
      },
      setRoutesLoading: (loading: boolean) => {
        set((state) => {
          state.routesLoading = loading;
        });
      },
      getRouteByUuid: (uuid: string) => {
        const { routes } = get();
        return routes.find((r) => r.uuid === uuid);
      },
      getRoutesByOrderUuid: (orderUuid: string) => {
        const { routes } = get();
        return routes.filter((r) =>
          r.slots.some((s) => s.stops[0]?.shipment?.order?.uuid === orderUuid),
        );
      },
      filterRoutes: () => {
        set((state) => {
          let filtered = searchRoutes(state.routes, state.routeSearchText);
          if (!isEmpty(state.filteredTagUuids)) {
            filtered = filtered?.filter((route) =>
              route.tags.some((t) => state.filteredTagUuids.includes(t.uuid)),
            );
          }
          filtered = sortRoutes(filtered, state.sortType, state.sortAsc);
          state.filteredRoutes = filtered;
        });
      },
      addRoute: (route: RouteFragment) => {
        set((state) => {
          if (isNil(state.routes?.find((r) => r.uuid === route.uuid))) {
            state.routes?.push(route);
          }
        });
      },
      setRoute: async (route: RouteFragment, versionId?: string | null) => {
        const { routes: existingRoutes, routeUuidsLoadingStops } = get();
        set((state) => {
          const { routes, planningDate } = state;
          const { selectedTerminalUuid } = globalStore.getState();

          if (
            isNil(versionId) ||
            (!isNil(get().routesVersionId) &&
              state.routesVersionId === versionId)
          ) {
            const routeIndex = routes.findIndex((r) => r.uuid === route.uuid);
            if (routeIndex === -1) {
              routes.push(route);
            } else {
              routes[routeIndex] = route;
            }
          }
          state.routes = routes.filter(
            (r) =>
              dayjs(r.date).date() === dayjs(planningDate).date() &&
              (isNil(selectedTerminalUuid) ||
                (!isNil(selectedTerminalUuid) &&
                  selectedTerminalUuid === r.terminal?.uuid)),
          );
        });
        get().filterRoutes();
        if (
          !existingRoutes.some((r) => r.uuid === route.uuid) &&
          !routeUuidsLoadingStops.includes(route.uuid)
        ) {
          set((state) => {
            state.routeUuidsLoadingStops = state.routeUuidsLoadingStops.filter(
              (uuid) => !state.routes.some((r) => r.uuid === uuid),
            );
            state.routeUuidsLoadingStops.push(route.uuid);
          });
        }
      },
      reverseRoute: (uuid: string) => {
        const { routes: oldRoutes } = get();
        const routeIndex = oldRoutes?.findIndex((r) => r.uuid === uuid) ?? -1;
        const route = oldRoutes?.[routeIndex];
        if (!isNil(route)) {
          const newRoute = reverseRoute(route);
          set((state) => {
            const { routes } = state;
            if (!isNil(routes)) {
              routes[routeIndex] = newRoute;
            }
          });
          return newRoute;
        }
      },
      deleteRoute: (uuid: string) => {
        set((state) => {
          const routeIndex =
            state.routes?.findIndex((r) => r.uuid === uuid) ?? -1;
          if (routeIndex >= 0) {
            state.routes?.splice(routeIndex, 1);
          }
          const routeGridApiIndex = state.routeGridApis.findIndex(
            (r) => r.routeUuid === uuid,
          );
          if (routeGridApiIndex !== -1) {
            state.routeGridApis?.splice(routeGridApiIndex, 1);
          }
        });
        get().deselectRouteUuid(uuid);
        get().deselectMapRouteUuid(uuid);
        get().setShouldRefreshGrid(true);
        get().filterRoutes();
      },
      reorderRouteSlots: (
        routeUuid: string,
        startIndex: number,
        endIndex: number,
        swap: boolean,
      ) => {
        const { routes: oldRoutes } = get();
        const routeIndex =
          oldRoutes?.findIndex((r) => r.uuid === routeUuid) ?? -1;
        const route = oldRoutes?.[routeIndex];
        if (!isNil(route)) {
          const newRoute = reorderRoute(route, startIndex, endIndex, swap);

          if (!isEmpty(getLinkedStopIndicesOutOfOrder(newRoute))) {
            set((state) => {
              state.errorMessage =
                'Failed to reorder stops - inbound stop must precede outbound stop';
            });
            return;
          }

          set((state) => {
            const { routes } = state;
            if (!isNil(routes)) {
              routes[routeIndex] = newRoute;
            }
          });
          return newRoute;
        }
      },
      removeSlotsFromRoute: (routeUuid: string, slotUuids: string[]) => {
        set((state) => {
          const { routes } = state;
          if (!isNil(routes)) {
            const routeIndex = routes.findIndex((r) => r.uuid === routeUuid);
            const route = routes[routeIndex];
            if (!isNil(route)) {
              route.slots = route.slots.filter(
                (slot) => !slotUuids.includes(slot.uuid),
              );
              routes[routeIndex] = route;
            }
          }
        });
      },
      setRoutePaths: (routePaths: Array<RoutePathFragment | null>) => {
        set((state) => {
          state.routePaths = routePaths;
        });
      },
      upsertRoutePath: (routePath: RoutePathFragment) => {
        set((state) => {
          const { routePaths } = state;
          if (!isNil(routePaths)) {
            const routeIndex = routePaths.findIndex(
              (r) => r?.route.uuid === routePath.route.uuid,
            );
            routePaths[routeIndex] = routePath;
          }
        });
      },
      deleteRoutePath: (routeUuid: string) => {
        set((state) => {
          const routePathIndex =
            state.routePaths.findIndex((r) => r?.route.uuid === routeUuid) ??
            -1;
          state.routePaths?.splice(routePathIndex, 1);
        });
      },
      setOpenedOrderUuid: (uuid: string | undefined) => {
        set((state) => {
          state.openedOrderUuid = uuid;
        });
      },
      setOpenedRouteUuid: (uuid: string | undefined) => {
        set((state) => {
          state.openedRouteUuid = uuid;
        });
      },
      setSelectedWarehouseUuid: (warehouseUuid: string | undefined) => {
        set((state) => {
          state.selectedWarehouseUuid = warehouseUuid;
        });
      },
      getSelectedMapStop: () => {
        return get().selectedMapStop;
      },
      setSelectedMapStop: (
        stop: StopOnRouteFragment | StopBaseFragment | undefined,
      ) => {
        set((state) => {
          state.selectedMapStop = stop;
        });
      },
      selectRouteUuid: (routeUuid: string) => {
        set((state) => {
          if (!state.selectedRouteUuids.includes(routeUuid)) {
            state.selectedRouteUuids.push(routeUuid);
          }
        });
      },
      deselectRouteUuid: (routeUuid: string) => {
        set((state) => {
          state.selectedRouteUuids = state.selectedRouteUuids.filter(
            (uuid) => uuid !== routeUuid,
          );
        });
      },
      selectRouteUuids: (routeUuids: string[]) => {
        set((state) => {
          for (const routeUuid of routeUuids) {
            if (!state.selectedRouteUuids.includes(routeUuid)) {
              state.selectedRouteUuids.push(routeUuid);
            }
          }
        });
      },
      selectMapRouteUuid: (routeUuid: string) => {
        set((state) => {
          if (
            !state.selectedMapRouteUuids.includes(routeUuid) &&
            state.showMap
          ) {
            state.selectedMapRouteUuids.push(routeUuid);
          }
        });
      },
      deselectMapRouteUuid: (routeUuid: string) => {
        set((state) => {
          state.selectedMapRouteUuids = state.selectedMapRouteUuids.filter(
            (uuid) => uuid !== routeUuid,
          );
        });
      },
      setSelectedMapRouteUuids: (routeUuids: string[]) => {
        set((state) => {
          state.selectedMapRouteUuids = routeUuids;
        });
      },
      setAllRouteUuidsLoadingStops: () => {
        set((state) => {
          const { routes, sortType, sortAsc } = get();
          state.routeUuidsLoadingStops =
            sortRoutes(routes, sortType, sortAsc)?.map((r) => r.uuid) ?? [];
        });
      },
      clearRouteUuidsLoadingStops: () => {
        set((state) => {
          state.routeUuidsLoadingStops = [];
        });
      },
      setUnrenderAllStops: (unrender: boolean) => {
        set((state) => {
          state.unrenderAllStops = unrender;
        });
      },
      markRouteAsRendered: (routeUuid: string) => {
        set((state) => {
          state.routeUuidsLoadingStops = state.routeUuidsLoadingStops.filter(
            (uuid) => uuid !== routeUuid,
          );
        });
      },
      selectAllRoutes: () => {
        set((state) => {
          state.selectedRouteUuids = state.routes.map((r) => r.uuid);
        });
      },
      deselectAllRoutes: () => {
        set((state) => {
          state.selectedRouteUuids = [];
          state.selectedMapRouteUuids = [];
        });
      },
      setSelectedStopUuids: (uuids: string[]) => {
        set((state) => {
          state.selectedStopUuids = uuids;
        });
      },
      toggleSelectedStopUuid: (uuid: string, shouldSelect: boolean) => {
        set((state) => {
          state.selectedStopUuids = state.selectedStopUuids.filter(
            (stopUuid) => stopUuid !== uuid,
          );
          if (shouldSelect) {
            state.selectedStopUuids.push(uuid);
          }
        });
      },
      setHoveredOrderUuid: (uuid: string | undefined) => {
        set((state) => {
          state.hoveredOrderUuid = uuid;
        });
      },
      setHoveredStopUuid: (uuid: string | undefined) => {
        set((state) => {
          state.hoveredStopUuid = uuid;
        });
      },
      setLastUnassignedData: (data: LastUnassignedData | undefined) => {
        set((state) => {
          if (isNil(data)) {
            state.lastUnassignedData = undefined;
          } else {
            const { routeUuid, stopUuid, index } = data;
            state.lastUnassignedData = {
              routeUuid,
              stopUuid,
              index,
            };
          }
        });
      },
      setArrangeStopsAndRoutesVertically: (verticallyArrange: boolean) => {
        set((state) => {
          state.arrangeStopsAndRoutesVertically = verticallyArrange;
        });
      },
      toggleSelectedStopForBulkAction: (stop: StopOnRouteFragment) => {
        set((state) => {
          if (
            state.selectedStopsForBulkAction.some((s) => s.uuid === stop.uuid)
          ) {
            state.selectedStopsForBulkAction =
              state.selectedStopsForBulkAction.filter(
                (s) => s.uuid !== stop.uuid,
              );
          } else {
            state.selectedStopsForBulkAction.push(stop);
          }
        });
      },
      setSelectedStopsForBulkAction: (stops: StopOnRouteFragment[]) => {
        set((state) => {
          state.selectedStopsForBulkAction = stops;
        });
      },
      setShowCompleteStops: (showCompleteStops: boolean) => {
        set((state) => {
          state.showCompleteStops = showCompleteStops;
        });
      },
      setStopTypeFilter: (stopType?: StopType) => {
        set((state) => {
          state.stopTypeFilter = stopType;
        });
      },
      setFilteredTagUuids: (tagUuids: string[]) => {
        set((state) => {
          state.filteredTagUuids = tagUuids;
        });
        get().filterRoutes();
      },
      getRouteGridApis: () => {
        return get().routeGridApis;
      },
      setRouteGridApi: ({
        routeUuid,
        gridApi,
      }: {
        routeUuid: string;
        gridApi: GridApi<StopOnRouteFragment> | null;
      }) => {
        set((state) => {
          const routeGridIndex = state.routeGridApis.findIndex(
            (r) => r.routeUuid === routeUuid,
          );
          if (routeGridIndex !== -1) {
            state.routeGridApis.splice(routeGridIndex, 1);
          }
          if (!isNil(gridApi)) {
            state.routeGridApis.push({
              routeUuid,
              api: gridApi,
            });
          }
        });
      },
      updateNumberOfRoutesRendered: (numberOfRoutes?: number) => {
        set((state) => {
          state.numberOfRoutesRendered = isNil(numberOfRoutes)
            ? (min([
                state.numberOfRoutesRendered + ROUTE_PAGE_SIZE_TO_RENDER,
                state.routes.length,
              ]) ?? ROUTE_PAGE_SIZE_TO_RENDER)
            : numberOfRoutes;
        });
      },
      setSelectedViewUuid: (uuid: string | undefined) => {
        set((state) => {
          state.selectedViewUuid = uuid;
        });
      },
    })),
    {
      name: DISPATCH_STORE_KEY,
      partialize: (state) => ({
        mapRoutePathType: state.mapRoutePathType,
        selectedMapRouteUuids: state.selectedMapRouteUuids,
        showUnassignedStopsInMap: state.showUnassignedStopsInMap,
        showCompleteStops: state.showCompleteStops,
        sortType: state.sortType,
        sortAsc: state.sortAsc,
      }),
    },
  ),
);

export default useDispatchStore;
