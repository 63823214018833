import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Typography } from '@mui/material';
import {
  type ColDef,
  type ICellRendererParams,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { type ReactNode, useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import { getAppointmentTimeString } from 'shared/copy';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import { transformDateStringToSpecifiedFormat } from '../../../common/utils/prettyPrintUtils';
import {
  DispatchTableField,
  PickupOrDelivery,
  StandardStopType,
  type StopFragment,
} from '../../../generated/graphql';
import theme from '../../../theme';
import {
  getDispatchHeaderCopy,
  StopFilterField,
} from '../../ag-grid/dispatch-stops/constants';
import {
  BooleanFilter,
  DateSelectFilter,
  MultiSelectFilter,
  SingleSelectFilter,
} from '../../ag-grid/filter-components';
import NotesComponent from '../../orders/components/standard/components/notes-component';
import StopDetailsStatusIcon from '../routes/components/stop-details-status-icon';

const filterColumns: Array<ColDef<StopFragment>> = [
  {
    field: StopFilterField.CUSTOMER,
    headerName: 'Customer',
    hide: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayName),
    },
  },
  {
    field: StopFilterField.STOP_TYPE,
    headerName: 'Stop Type',
    hide: true,
    filter: MultiSelectFilter,
    filterParams: {
      values: [
        { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
        { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
      ],
      defaultSelectedValues: [
        { actualValue: PickupOrDelivery.Pickup, displayValue: 'Pickup' },
        { actualValue: PickupOrDelivery.Delivery, displayValue: 'Delivery' },
      ],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.SERVICE_LEVEL,
    headerName: 'Service Level',
    hide: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayValue),
    },
  },
  {
    field: StopFilterField.APPOINTMENT_SCHEDULED,
    headerName: 'Appointment confirmed',
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.ROUTE_NAME,
    headerName: 'Route name',
    hide: true,
    maxWidth: 120,
    sortable: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.name),
    },
    valueGetter: (params: ValueGetterParams<StopFragment>) => {
      return params.data?.stop.address?.routeName ?? 'NA';
    },
  },
  {
    field: StopFilterField.ADDRESS_TYPE,
    headerName: 'Address type',
    hide: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: Object.values(StandardStopType)
        .filter((val) => val !== StandardStopType.Dummy)
        .map((val) => {
          return { actualValue: val, displayValue: sentenceCase(val) };
        }),
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.APPOINTMENT_DATE,
    headerName: 'Appt Date',
    hide: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.APPOINTMENT_REQUIRED,
    headerName: 'Appointment required',
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.BUSINESS_DIVISION,
    headerName: 'Business division',
    hide: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayValue),
    },
  },
  {
    field: StopFilterField.HAS_APPOINTMENT,
    headerName: 'Has appointment',
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED,
    headerName: 'Options proposed',
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.CREATED_DATE,
    headerName: 'Created date',
    hide: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.DEADLINE_DATE,
    headerName: 'Deadline date',
    hide: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.ON_HAND,
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.IS_PICKED,
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.IS_SPECIAL,
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.PREVIOUSLY_ATTEMPTED,
    headerName: 'Previously attempted',
    hide: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
].sort((a, b) =>
  (a.headerName ?? a.field.toString()).localeCompare(
    b.headerName ?? b.field.toString(),
  ),
);

const renderCell = (
  firstValue: string | number | ReactNode | null | undefined,
  secondValue?: string | number | ReactNode | null,
  shrink = false,
) => (
  <Stack component="span" spacing={shrink ? 0 : 0.5}>
    <Typography component="span" sx={{ fontSize: '11px' }}>
      {firstValue}
    </Typography>
    {!isNil(secondValue) && (
      <Typography
        component="span"
        sx={{ fontSize: '11px', height: 'fit-content' }}
      >
        {secondValue}
      </Typography>
    )}
  </Stack>
);

const isGroupedOrder = (stop: StopFragment | undefined) =>
  !isNil(stop?.linkedEndStop);

export const useReservationSystemColumns = ({
  isCourier,
  setOpenedOrderUuid,
}: {
  isCourier: boolean | undefined | null;
  setOpenedOrderUuid: (uuid: string | undefined) => void;
}): Array<ColDef<StopFragment>> => {
  // The field parameter MUST be unique across filterColumns and displayColumns.
  return useMemo((): Array<ColDef<StopFragment>> => {
    const displayColumns: Array<ColDef<StopFragment>> = [
      {
        field: '',
        pinned: 'left',
        lockPinned: true,
        resizable: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        checkboxSelection: true,
        maxWidth: 40,
      },
      {
        field: DispatchTableField.OrderNumber,
        headerName: getDispatchHeaderCopy(DispatchTableField.OrderNumber),
        autoHeight: true,
        hide: false,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const { data } = params;
          if (isNil(data)) {
            return null;
          }
          const orderName = data.order?.name;
          return (
            <Button
              variant="text"
              sx={{
                minWidth: 'max-content',
                p: '2px',
                background: 'none',
                color: theme.palette.text.primary,
                fontSize: '11px',
                fontWeight: 400,

                '&:hover': {
                  background: 'none',
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                setOpenedOrderUuid(data.order?.uuid);
              }}
            >
              {orderName}
            </Button>
          );
        },
      },
      {
        field: DispatchTableField.RefNumber,
        headerName: getDispatchHeaderCopy(DispatchTableField.RefNumber),
        autoHeight: true,
        hide: false,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const referenceNumbers = params.data?.order?.refNumbers.join(', ');
          return renderCell(
            referenceNumbers,
            isGroupedOrder(params.data) ? '' : null,
          );
        },
      },
      {
        field: DispatchTableField.StopType,
        headerName: 'Stop Type',
        autoHeight: true,
        hide: false,
        sortable: true,
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const stopType = params.data?.stop.stopType;
          const stopDetails = params.data?.stopDetails;
          const linkedStopType = params.data?.linkedEndStop?.stopType;

          return renderCell(
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              component="span"
            >
              <Typography variant="caption" sx={{ fontSize: '11px' }}>
                {sentenceCase(stopType ?? '')}
              </Typography>
              {isNil(linkedStopType) && !isNil(stopDetails) && (
                <StopDetailsStatusIcon
                  status={stopDetails?.stopDetailsStatus}
                  message={stopDetails?.message}
                />
              )}
            </Stack>,
            isNil(linkedStopType) ? null : (
              <Typography variant="caption" sx={{ fontSize: '11px' }}>
                {sentenceCase(linkedStopType)}
              </Typography>
            ),
          );
        },
      },
      {
        field: DispatchTableField.City,
        headerName: getDispatchHeaderCopy(DispatchTableField.City),
        sortable: true,
        autoHeight: true,
        hide: false,
        maxWidth: 150,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const city = params.data?.stop.address?.city ?? '-';
          const linkedEndStopCity = params.data?.linkedEndStop?.address?.city;
          return renderCell(
            city,
            isGroupedOrder(params.data) ? (linkedEndStopCity ?? '') : null,
          );
        },
      },
      {
        field: DispatchTableField.ZipCode,
        headerName: getDispatchHeaderCopy(DispatchTableField.ZipCode),
        autoHeight: true,
        hide: false,
        sortable: true,
        width: 50,
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const zip = params.data?.stop.address?.zip ?? '-';
          const linkedEndStopZip = params.data?.linkedEndStop?.address?.zip;
          return renderCell(
            zip,
            isGroupedOrder(params.data) ? (linkedEndStopZip ?? '') : null,
          );
        },
      },
      {
        field: DispatchTableField.DueDate,
        headerName: getDispatchHeaderCopy(DispatchTableField.DueDate),
        autoHeight: true,
        hide: false,
        sortable: true,
        maxWidth: 80,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          if (isNil(params.data?.stop.deadlineDate)) {
            return '-';
          }
          const linkedStopDeadlineDate =
            params.data?.linkedEndStop?.deadlineDate;
          const linkedStopDeadlineDateString = isNil(linkedStopDeadlineDate)
            ? '-'
            : dayjs(linkedStopDeadlineDate).format('MM/DD');
          return renderCell(
            dayjs(params.data?.stop.deadlineDate).format('MM/DD'),
            isGroupedOrder(params.data) ? linkedStopDeadlineDateString : null,
          );
        },
        suppressSizeToFit: true,
      },
      {
        field: DispatchTableField.ServiceDate,
        headerName: getDispatchHeaderCopy(DispatchTableField.ServiceDate),
        autoHeight: true,
        hide: false,
        sortable: true,
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          if (isNil(params.data?.stop.serviceDate)) {
            return '-';
          }
          const linkedStopDeadlineDate =
            params.data?.linkedEndStop?.serviceDate;
          const linkedStopDeadlineDateString = isNil(linkedStopDeadlineDate)
            ? '-'
            : dayjs(linkedStopDeadlineDate).format('MM/DD');
          return renderCell(
            dayjs(params.data?.stop.serviceDate).format('MM/DD'),
            isGroupedOrder(params.data) ? linkedStopDeadlineDateString : null,
          );
        },
        suppressSizeToFit: true,
      },
      {
        field: DispatchTableField.Address,
        headerName: getDispatchHeaderCopy(DispatchTableField.Address),
        autoHeight: true,
        hide: false,
        sortable: true,

        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const address = params.data?.stop.address;
          const linkedStopAddress = params.data?.linkedEndStop?.address;
          return renderCell(
            `${capitalCase(address?.line1 ?? '')}`,
            isGroupedOrder(params.data)
              ? `${capitalCase(linkedStopAddress?.line1 ?? '')}`
              : null,
          );
        },
      },
      {
        field: DispatchTableField.AddressType,
        headerName: getDispatchHeaderCopy(DispatchTableField.AddressType),
        hide: false,
        maxWidth: 120,
        sortable: true,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          return params.data?.stop.standardStopType ?? 'NA';
        },
      },
      {
        field: DispatchTableField.State,
        headerName: getDispatchHeaderCopy(DispatchTableField.State),
        hide: false,
        maxWidth: 120,
        sortable: true,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          return params.data?.stop.address?.state ?? 'NA';
        },
      },
      {
        field: DispatchTableField.Appointment,
        headerName: getDispatchHeaderCopy(DispatchTableField.Appointment),
        sortable: true,
        autoHeight: true,
        hide: false,
        maxWidth: 130,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const apptDate = transformDateStringToSpecifiedFormat(
            params.data?.stop.deliveryDate,
            'MM/DD/YY',
          );
          const apptTimeString = getAppointmentTimeString({
            stop: params.data?.stop,
            deliveryDate: params.data?.stop.deliveryDate,
            useMinimizedAppointmentTime: false,
          });
          if (isGroupedOrder(params.data)) {
            return renderCell(
              `${apptDate} ${apptTimeString}`,
              getAppointmentTimeString({
                stop: params.data?.linkedEndStop,
                deliveryDate: params.data?.stop.deliveryDate,
                useMinimizedAppointmentTime: false,
              }),
            );
          }
          return renderCell(`${apptDate} ${apptTimeString}`, null);
        },
        suppressSizeToFit: true,
      },
      {
        field: DispatchTableField.Consignee,
        headerName: getDispatchHeaderCopy(DispatchTableField.Consignee),
        sortable: true,
        autoHeight: true,
        hide: false,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const linkedEndStopConsignee =
            params.data?.linkedEndStop?.address?.name;
          return renderCell(
            params.data?.stop.address?.name,
            isGroupedOrder(params.data) ? (linkedEndStopConsignee ?? '') : null,
          );
        },
      },
      {
        field: DispatchTableField.AppointmentScheduled,
        autoHeight: true,
        hide: false,
        sortable: true,
        headerName: getDispatchHeaderCopy(
          DispatchTableField.AppointmentScheduled,
        ),
        cellStyle: { textAlign: 'center' },
        maxWidth: 115,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const appointmentScheduled = params.data?.stop.appointmentScheduled;
          const linkedStopAppointmentScheduled =
            params.data?.linkedEndStop?.appointmentScheduled;
          return renderCell(
            appointmentScheduled === true ? (
              <CheckIcon color="success" sx={{ fontSize: '14px' }} />
            ) : (
              <CloseIcon color="error" sx={{ fontSize: '14px' }} />
            ),

            isGroupedOrder(params.data) ? (
              linkedStopAppointmentScheduled === true ? (
                <CheckIcon color="success" sx={{ fontSize: '14px' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '14px' }} />
              )
            ) : null,
          );
        },
      },
      {
        field: DispatchTableField.Customer,
        headerName: getDispatchHeaderCopy(DispatchTableField.Customer),
        sortable: true,
        hide: false,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          return params.data?.order?.contactName;
        },
      },
      {
        field: DispatchTableField.ServiceLevel,
        headerName: getDispatchHeaderCopy(DispatchTableField.ServiceLevel),
        hide: false,
        sortable: true,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          return params.data?.order?.serviceName;
        },
      },
      ...(isCourier === true
        ? [
            {
              field: DispatchTableField.VehicleTypeName,
              headerName: getDispatchHeaderCopy(
                DispatchTableField.VehicleTypeName,
              ),
              hide: false,
              sortable: true,
              cellRenderer: (params: ICellRendererParams<StopFragment>) => {
                return params.data?.order?.vehicleTypeName;
              },
            },
          ]
        : []),
      {
        field: DispatchTableField.SpecialInstructions,
        headerName: getDispatchHeaderCopy(
          DispatchTableField.SpecialInstructions,
        ),
        autoHeight: true,
        hide: false,
        maxWidth: 110,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const linkedEndStopSpecialInstructions =
            params.data?.linkedEndStop?.specialInstructions;
          return renderCell(
            <NotesComponent
              notes={params.data?.stop.specialInstructions ?? ''}
              buttonSx={{ padding: '0 3px' }}
            />,
            isNil(linkedEndStopSpecialInstructions) ? null : (
              <NotesComponent
                notes={linkedEndStopSpecialInstructions}
                buttonSx={{ padding: '0 3px' }}
              />
            ),
            true,
          );
        },
      },
      {
        field: DispatchTableField.PieceCount,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.PieceCount),
        cellStyle: { textAlign: 'center' },
        maxWidth: 70,

        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const packages = params.data?.order?.packages;
          return packages?.reduce((prev, curr) => curr.quantity + prev, 0);
        },
      },
      {
        field: DispatchTableField.Dims,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Dims),
        cellStyle: { textAlign: 'center' },

        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const packages = params.data?.order?.packages;
          const packagesArr = filterNotNil(
            packages?.map((package_) => {
              const { length, width, height, quantity } = package_;
              if (!isNil(length) && !isNil(height) && !isNil(width)) {
                return `${quantity} ${length}x${width}x${height}`;
              }
              return quantity;
            }) ?? [],
          );
          return packagesArr.join(', ');
        },
      },
      {
        field: DispatchTableField.Weight,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Weight),
        cellStyle: { textAlign: 'center' },

        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          const packages = params.data?.order?.packages;
          const totalWeight = packages?.reduce(
            (prev, curr) => (isNil(curr.weight) ? prev : curr.weight + prev),
            0,
          );
          return isNil(totalWeight) ? null : `${totalWeight}`;
        },
      },
      {
        field: DispatchTableField.OrderNotes,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.OrderNotes),
        cellStyle: { textAlign: 'center' },
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          return (
            <NotesComponent
              notes={`${params.data?.order?.orderComments
                ?.map((orderComment) => orderComment.comment)
                .join('\n')}`}
            />
          );
        },
      },
      {
        field: DispatchTableField.Special,
        autoHeight: true,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Special),
        cellStyle: { textAlign: 'center' },
        maxWidth: 70,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const isSpecial = params.data?.stop.isSpecial;
          const linkedEndStopIsSpecial = params.data?.linkedEndStop?.isSpecial;
          return renderCell(
            isSpecial === true ? (
              <CheckIcon color="success" sx={{ fontSize: '14px' }} />
            ) : (
              <CloseIcon color="error" sx={{ fontSize: '14px' }} />
            ),

            isGroupedOrder(params.data) ? (
              linkedEndStopIsSpecial === true ? (
                <CheckIcon color="success" sx={{ fontSize: '14px' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '14px' }} />
              )
            ) : null,
          );
        },
      },
      {
        field: DispatchTableField.RouteName,
        headerName: getDispatchHeaderCopy(DispatchTableField.RouteName),
        hide: false,
        maxWidth: 120,
        sortable: true,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          return params.data?.stop.address?.routeName ?? 'NA';
        },
      },
      {
        field: DispatchTableField.TotalCharges,
        headerName: getDispatchHeaderCopy(DispatchTableField.TotalCharges),
        hide: false,
        maxWidth: 100,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          const totalCharge = params.data?.stop.totalCharge;

          return isNil(totalCharge) ? null : currency(totalCharge).format();
        },
      },
    ];

    return [...filterColumns, ...displayColumns];
  }, [isCourier, setOpenedOrderUuid]);
};
