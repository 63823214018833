import { InputAdornment, TableCell, TextField } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { type ChangeEvent } from 'react';
import { shallow } from 'zustand/shallow';
import { RateType, TariffType } from '../../../../generated/graphql';
import { getValueWithinBounds } from './store/tariff-group-controller';
import useTariffGroupStore from './store/tariff-group-state-store';

const TariffRateCell = ({
  rowIndex,
  colIndex,
}: {
  readonly rowIndex: number;
  readonly colIndex: number;
}) => {
  const [rateMultiplier, tariffType, rateType, rateMatrix] =
    useTariffGroupStore(
      (state) => [
        state.rateMultiplier,
        state.tariffType,
        state.rateType,
        state.rateMatrix,
      ],
      shallow,
    );

  const [setRateMatrixSingleCoordinate] = useTariffGroupStore(
    (state) => [state.setRateMatrixSingleCoordinate],
    shallow,
  );
  const column = isNil(rateMatrix) ? [] : rateMatrix[rowIndex];

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const amount = Number.parseFloat(event.target.value);
    setRateMatrixSingleCoordinate(
      rowIndex,
      colIndex,
      Number.isNaN(amount) ? undefined : amount,
    );
  };

  const validateAndUpdateRate = () => {
    const col = rateMatrix[rowIndex];
    let rateValue = isNil(col) ? undefined : col[colIndex];
    if (!Number.isNaN(rateValue) && !isNil(rateValue)) {
      rateValue = getValueWithinBounds(rateValue);
      const rate = currency(rateValue, { precision: 3 }).value;
      setRateMatrixSingleCoordinate(rowIndex, colIndex, rate);
    } else {
      setRateMatrixSingleCoordinate(rowIndex, colIndex, undefined);
    }
  };

  let displayValue;

  if (isNil(rateMultiplier)) {
    displayValue =
      !isNil(column) && !isNil(column[colIndex])
        ? column[colIndex]
        : Number.NaN;
  } else {
    displayValue =
      !isNil(column) && !isNil(column[colIndex])
        ? ((column[colIndex] ?? 0) * (rateMultiplier / 100)).toFixed(2)
        : Number.NaN;
  }

  const getRateCellCopy = (type: RateType): string => {
    if (type === RateType.QuantityBased) {
      return `(Per ${
        tariffType === TariffType.PerPiece
          ? 'Piece'
          : tariffType === TariffType.PerCubicFoot
            ? 'Cubic Foot'
            : 'Pound'
      })`;
    }
    if (tariffType === TariffType.PerCubicFoot) {
      return '(Per Cubic Foot)';
    }

    return '';
  };

  return (
    <TableCell align="center" sx={{ minWidth: '130px' }}>
      <TextField
        name="rate"
        size="small"
        label={`Rate ${getRateCellCopy(rateType)}`}
        value={displayValue}
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onBlur={validateAndUpdateRate}
        onWheel={(e) => {
          (e.target as HTMLTextAreaElement).blur();
        }}
        onChange={(event) => {
          onChange(event);
        }}
      />
    </TableCell>
  );
};

export default TariffRateCell;
