import CallMissedIcon from '@mui/icons-material/CallMissed';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import {
  type CellClassParams,
  type CheckboxSelectionCallbackParams,
  type ColDef,
  type ICellRendererParams,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil, round } from 'lodash';
import { type ReactNode, useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import { getAppointmentTimeString } from 'shared/copy';
import { getCurrentTimeDefaultTimezone } from 'shared/time';
import { FeatureFlag } from '../../../common/feature-flags';
import useBusinessDivisions from '../../../common/react-hooks/use-business-divisions';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useServices from '../../../common/react-hooks/use-services';
import { chooseForegroundColor } from '../../../common/utils/colors';
import { transformDateStringToSpecifiedFormat } from '../../../common/utils/prettyPrintUtils';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  type DispatchTableColorFragment,
  DispatchTableField,
  PickupOrDelivery,
  StandardStopType,
  type StopFragment,
  StopType,
  useContactsSimpleQuery,
  useRouteNamesQuery,
} from '../../../generated/graphql';
import {
  getDispatchHeaderCopy,
  inboundStopTypes,
  outboundStopTypes,
  StopFilterField,
} from '../../ag-grid/dispatch-stops/constants';
import {
  BooleanFilter,
  DateSelectFilter,
  SingleSelectFilter,
} from '../../ag-grid/filter-components';
import TagsCell from '../../orders/components/ag-grid-cell-components/tags-cell-component';
import Paperwork from '../../orders/components/paperwork';
import NotesComponent from '../../orders/components/standard/components/notes-component';
import RouteStopAssignInput from '../routes/components/route-stop-assign-input';
import StopDetailsStatusIcon from '../routes/components/stop-details-status-icon';
import {
  formattedDateAbbreviated,
  getDispatchTableColorForStop,
} from '../utils';
import { useDispatchViewSettings } from './use-dispatch-view-settings';

type StopFragmentFilterColDef = {
  field: StopFilterField;
  headerName?: string;
  hide: true;
  suppressColumnsToolPanel: true;
  filter: typeof SingleSelectFilter;
  filterParams: {
    values?: unknown;
    defaultToNothingSelected: true;
    suppressSelectAll: boolean;
    keyCreator?: (params: ValueFormatterParams) => string;
    valueFormatter?: (params: ValueFormatterParams) => string;
    inDispatchTable?: true;
  };
} & ColDef<StopFragment>;

/** Do not use this directly, @see useStopFilterColumns instead */
const stopFilterColumns: StopFragmentFilterColDef[] = [
  {
    field: StopFilterField.CUSTOMER,
    headerName: 'Customer',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayName),
    },
  },
  {
    field: StopFilterField.STOP_TYPE,
    headerName: 'Stop Type',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: Object.values(PickupOrDelivery).map((val) => ({
        actualValue: val,
        displayValue: sentenceCase(val),
      })),
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.SERVICE_LEVEL,
    headerName: 'Service Level',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayValue),
    },
  },
  {
    field: StopFilterField.APPOINTMENT_SCHEDULED,
    headerName: 'Appointment confirmed',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.ROUTE_NAME,
    headerName: 'Route name',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.name),
    },
    valueGetter: (params: ValueGetterParams<StopFragment>) =>
      params?.data?.stop.address?.routeName ?? 'NA',
  },
  {
    field: StopFilterField.ADDRESS_TYPE,
    headerName: 'Address type',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: Object.values(StandardStopType)
        .filter((val) => val !== StandardStopType.Dummy)
        .map((val) => ({ actualValue: val, displayValue: sentenceCase(val) })),
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.APPOINTMENT_DATE,
    headerName: 'Appt Date',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      inDispatchTable: true,
    },
  },
  {
    field: StopFilterField.APPOINTMENT_REQUIRED,
    headerName: 'Appointment required',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.BUSINESS_DIVISION,
    headerName: 'Business division',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: SingleSelectFilter,
    filterParams: {
      values: [{}],
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      keyCreator: (params: ValueFormatterParams) => params.value.uuid,
      valueFormatter: (params: ValueFormatterParams) =>
        sentenceCase(params.value.displayValue),
    },
  },
  {
    field: StopFilterField.HAS_APPOINTMENT_DATE,
    headerName: 'Has an appointment date',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.HAS_APPOINTMENT,
    headerName: 'Has an appointment time',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED,
    headerName: 'Options proposed',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.CREATED_DATE,
    headerName: 'Created date',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      inDispatchTable: true,
    },
  },
  {
    field: StopFilterField.DEADLINE_DATE,
    headerName: 'Deadline date',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: DateSelectFilter,
    filterParams: {
      values: {
        startDate: getCurrentTimeDefaultTimezone().startOf('day').toDate(),
        endDate: getCurrentTimeDefaultTimezone().endOf('day').toDate(),
      },
      defaultToNothingSelected: true,
      suppressSelectAll: true,
      inDispatchTable: true,
    },
  },
  {
    field: StopFilterField.ON_HAND,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.CAN_DISPATCH,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.IS_PICKED,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.IS_REFUSED,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.IS_SPECIAL,
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
  {
    field: StopFilterField.PREVIOUSLY_ATTEMPTED,
    headerName: 'Previously attempted',
    hide: true,
    suppressColumnsToolPanel: true,
    filter: BooleanFilter,
    filterParams: {
      defaultToNothingSelected: true,
      suppressSelectAll: true,
    },
  },
] as const;

/** Do not use this directly, @see useStopFilterColumns instead */
const filterColumnsWithoutOptions: Array<ColDef<StopFragment>> =
  stopFilterColumns.sort((a, b) =>
    (a.headerName ?? a.field.toString()).localeCompare(
      b.headerName ?? b.field.toString(),
    ),
  );

export const getFilterColumnName = (key: string) =>
  filterColumnsWithoutOptions.find((col) => col.field === key)?.headerName;

/** Do not use this directly, @see useStopColumns instead */
const useStopFilterColumns = () => {
  const { data: contactsData, loading: contactsLoading } =
    useContactsSimpleQuery({
      fetchPolicy: 'cache-first',
      variables: {
        excludeNonActive: true,
      },
    });
  const { services, servicesLoading } = useServices();
  const { businessDivisions, businessDivisionsLoading } =
    useBusinessDivisions();
  const { data: routeNamesData, loading: routeNamesLoading } =
    useRouteNamesQuery({
      fetchPolicy: 'cache-first',
    });
  const loading =
    contactsLoading ||
    servicesLoading ||
    businessDivisionsLoading ||
    routeNamesLoading;
  const filterColumnsWithOptions = useMemo(
    () =>
      filterColumnsWithoutOptions.map((columnDef) => {
        if (columnDef.field === StopFilterField.CUSTOMER) {
          return {
            ...columnDef,
            filterParams: {
              ...columnDef.filterParams,
              values:
                contactsData?.contacts.map((contact) => ({
                  actualValue: contact.uuid,
                  displayValue: contact.displayName,
                })) ?? [],
            },
          };
        }
        if (columnDef.field === StopFilterField.SERVICE_LEVEL) {
          return {
            ...columnDef,
            filterParams: {
              ...columnDef.filterParams,
              values: services.map((service) => ({
                actualValue: service.uuid,
                displayValue: service.name,
              })),
            },
          };
        }
        if (columnDef.field === StopFilterField.BUSINESS_DIVISION) {
          return {
            ...columnDef,
            filterParams: {
              ...columnDef.filterParams,
              values:
                businessDivisions?.map((businessDivision) => ({
                  actualValue: businessDivision.uuid,
                  displayValue: businessDivision.name,
                })) ?? [],
            },
          };
        }
        if (columnDef.field === StopFilterField.ROUTE_NAME) {
          return {
            ...columnDef,
            filterParams: {
              ...columnDef.filterParams,
              values:
                routeNamesData?.routeNames?.map((routeName) => ({
                  actualValue: routeName.uuid,
                  displayValue: routeName.name,
                })) ?? [],
            },
          };
        }
        return columnDef;
      }),
    [contactsData, services, businessDivisions, routeNamesData],
  );
  return { loading, filterColumns: filterColumnsWithOptions };
};

const LINE_CLAMP_STYLES = {
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
};

const renderCell = (
  firstValue: string | number | ReactNode | null | undefined,
  secondValue?: string | number | ReactNode | null,
  shrink = false,
) => (
  <Stack spacing={shrink ? 0 : 0.5}>
    <Typography sx={{ fontSize: '11px', ...LINE_CLAMP_STYLES }}>
      {firstValue}
    </Typography>
    {!isNil(secondValue) && (
      <Typography
        sx={{ fontSize: '11px', height: 'fit-content', ...LINE_CLAMP_STYLES }}
      >
        {secondValue}
      </Typography>
    )}
  </Stack>
);

const isGroupedOrder = (stop: StopFragment | undefined) =>
  !isNil(stop?.linkedEndStop);

const useStopColumns = ({
  dispatchTableColors,
  setOpenedOrderUuid,
}: {
  dispatchTableColors: DispatchTableColorFragment[] | undefined;
  setOpenedOrderUuid?: (uuid: string | undefined) => void;
}): Array<ColDef<StopFragment>> => {
  // 'field' parameters MUST be unique across filterColumns and displayColumns
  // columns to be displayed on the dispatch page
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );
  const { useMinimizedAppointmentTime } = useDispatchViewSettings();

  const displayColumns = useMemo(
    (): Array<ColDef<StopFragment>> => [
      {
        field: '',
        pinned: 'left',
        lockPinned: true,
        resizable: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        suppressColumnsToolPanel: true,
        cellClass: 'checkbox-horizonal-padding',
        checkboxSelection: ({
          data,
        }: CheckboxSelectionCallbackParams<StopFragment>) =>
          isNil(data?.recurringRunUuid),
        width: 40,
      },
      {
        field: 'Route',
        pinned: 'left',
        hide: false,
        resizable: false,
        lockPinned: true,
        suppressColumnsToolPanel: true,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => (
          <RouteStopAssignInput stopUuids={[params.data?.stop.uuid]} />
        ),
        width: 120,
      },
      {
        field: DispatchTableField.OrderName,
        hide: false,
        pinned: 'left',
        lockPinned: true,
        sortable: true,
        headerName: getDispatchHeaderCopy(DispatchTableField.OrderName),
        cellStyle: ({ data }: CellClassParams<StopFragment>) => {
          if (isNil(data) || isNil(dispatchTableColors)) {
            return null;
          }
          const { stop, order } = data;
          const color = getDispatchTableColorForStop({
            isSpecial: stop.isSpecial === true,
            serviceUuid: order?.serviceUuid,
            deadlineDate: stop.deadlineDate,
            appointmentDate: stop.deliveryDate,
            appointmentStartTime: stop.appointmentTime,
            appointmentEndTime: stop.endAppointmentTime,
            dispatchTableColors,
          });
          return isNil(color) ? undefined : { background: color };
        },
        cellRenderer: ({ data }: ICellRendererParams<StopFragment>) => {
          if (isNil(data)) {
            return null;
          }
          const { stop, order } = data;
          const color = getDispatchTableColorForStop({
            isSpecial: stop.isSpecial === true,
            serviceUuid: order?.serviceUuid,
            deadlineDate: stop.deadlineDate,
            appointmentDate: stop.deliveryDate,
            appointmentStartTime: stop.appointmentTime,
            appointmentEndTime: stop.endAppointmentTime,
            dispatchTableColors: dispatchTableColors ?? [],
          });
          const bolNumber = order?.shipperBillOfLadingNumber;
          return (
            <Tooltip
              title={stop.hasPreviousAttempt === true ? 'Attempted' : null}
            >
              <Button
                variant="text"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: 1,
                  p: 0,
                  background: color ?? 'none',
                  color: isNil(color) ? 'black' : chooseForegroundColor(color),
                  '&:hover': {
                    background: color ?? 'none',
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => {
                  setOpenedOrderUuid?.(order?.uuid);
                }}
              >
                <Typography variant="caption" sx={{ fontSize: '11px' }}>
                  {bolNumber}
                </Typography>
                {stop.hasPreviousAttempt === true && (
                  <CallMissedIcon sx={{ fontSize: '11px' }} />
                )}
              </Button>
            </Tooltip>
          );
        },
      },
      {
        field: DispatchTableField.OrderNumber,
        headerName: getDispatchHeaderCopy(DispatchTableField.OrderNumber),
        hide: false,
        valueGetter: (params) => params.data?.order?.name,
      },
      {
        field: DispatchTableField.RefNumber,
        headerName: getDispatchHeaderCopy(DispatchTableField.RefNumber),
        hide: false,
        wrapText: false,
        valueGetter: (params) => params.data?.order?.refNumbers.join(', '),
      },
      {
        field: DispatchTableField.StopType,
        headerName: getDispatchHeaderCopy(DispatchTableField.StopType),
        hide: false,
        sortable: true,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const stopType = params.data?.stop.stopType;
          const stopDetails = params.data?.stopDetails;
          const linkedStopType = params.data?.linkedEndStop?.stopType;

          return renderCell(
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              component="span"
            >
              <Typography variant="caption" sx={{ fontSize: '11px' }}>
                {sentenceCase(stopType ?? '')}
              </Typography>
              {isNil(linkedStopType) && !isNil(stopDetails) && (
                <StopDetailsStatusIcon
                  status={stopDetails?.stopDetailsStatus}
                  message={stopDetails?.message}
                />
              )}
            </Stack>,
            isNil(linkedStopType) ? null : (
              <Typography variant="caption" sx={{ fontSize: '11px' }}>
                {sentenceCase(linkedStopType)}
              </Typography>
            ),
          );
        },
      },
      {
        field: DispatchTableField.InboundMethod,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundMethod),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const inboundStopType = params.data?.order?.inboundStopType;
          if (!isNil(inboundStopType)) {
            return sentenceCase(inboundStopType);
          }

          /**
           * @deprecated once all cached data has been updated we can remove old logic below this line
           */
          const stopType = params.data?.stop.stopType;
          const otherStopType = params.data?.otherStop?.stopType;

          if (!isNil(stopType) && inboundStopTypes.includes(stopType)) {
            return sentenceCase(stopType);
          }

          if (
            !isNil(otherStopType) &&
            inboundStopTypes.includes(otherStopType)
          ) {
            return sentenceCase(otherStopType);
          }

          return '-';
        },
      },
      {
        field: DispatchTableField.OutboundMethod,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundMethod),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const outboundStopType = params.data?.order?.outboundStopType;
          if (!isNil(outboundStopType)) {
            return sentenceCase(outboundStopType);
          }

          /**
           * @deprecated once all cached data has been updated we can remove old logic below this line
           */
          const stopType = params.data?.stop.stopType;
          const otherStopType = params.data?.otherStop?.stopType;

          if (!isNil(stopType) && outboundStopTypes.includes(stopType)) {
            return sentenceCase(stopType);
          }

          if (
            !isNil(otherStopType) &&
            outboundStopTypes.includes(otherStopType)
          ) {
            return sentenceCase(otherStopType);
          }

          return '-';
        },
      },
      {
        field: DispatchTableField.InboundTerminal,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundTerminal),
        hide: false,
        valueGetter: (params) => {
          const stop = params.data?.stop;
          const otherStop = params.data?.otherStop;

          if (
            !isNil(stop?.stopType) &&
            inboundStopTypes.includes(stop.stopType)
          ) {
            return stop?.terminalName ?? '-';
          }

          if (
            !isNil(otherStop?.stopType) &&
            inboundStopTypes.includes(otherStop.stopType)
          ) {
            return otherStop?.terminalName ?? '-';
          }

          return '-';
        },
      },
      {
        field: DispatchTableField.OutboundTerminal,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundTerminal),
        hide: false,
        valueGetter: (params) => {
          const stop = params.data?.stop;
          const otherStop = params.data?.otherStop;

          if (
            !isNil(stop?.stopType) &&
            outboundStopTypes.includes(stop.stopType)
          ) {
            return stop?.terminalName ?? '-';
          }

          if (
            !isNil(otherStop?.stopType) &&
            outboundStopTypes.includes(otherStop.stopType)
          ) {
            return otherStop?.terminalName ?? '-';
          }

          return '-';
        },
      },
      {
        field: DispatchTableField.Mawb,
        headerName: getDispatchHeaderCopy(DispatchTableField.Mawb),
        sortable: true,
        hide: false,
        valueGetter: (params) =>
          params.data?.order?.masterAirwayBillOfLadingNumber,
      },
      {
        field: DispatchTableField.City,
        headerName: getDispatchHeaderCopy(DispatchTableField.City),
        sortable: true,
        hide: false,
        valueGetter: (params) => {
          const city = params?.data?.stop.address?.city ?? '-';
          const linkedEndStopCity = params?.data?.linkedEndStop?.address?.city;
          if (!isNilOrEmptyString(linkedEndStopCity)) {
            return `${city} ${linkedEndStopCity}`;
          }
          return city;
        },
      },
      {
        field: DispatchTableField.ZipCode,
        headerName: getDispatchHeaderCopy(DispatchTableField.ZipCode),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const zip = params?.data?.stop.address?.zip ?? '-';
          const linkedEndStopZip = params?.data?.linkedEndStop?.address?.zip;
          if (
            isGroupedOrder(params.data) &&
            !isNilOrEmptyString(linkedEndStopZip)
          ) {
            return `${zip} ${linkedEndStopZip}`;
          }
          return zip;
        },
      },
      {
        field: DispatchTableField.DueDate,
        headerName: getDispatchHeaderCopy(DispatchTableField.DueDate),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          if (isNil(params.data?.stop.deadlineDate)) {
            return '-';
          }
          const linkedStopDeadlineDate =
            params.data?.linkedEndStop?.deadlineDate;
          const linkedStopDeadlineDateString = isNil(linkedStopDeadlineDate)
            ? null
            : formattedDateAbbreviated(dayjs(linkedStopDeadlineDate));
          const dateAbbreviated = formattedDateAbbreviated(
            dayjs(params.data?.stop.deadlineDate),
          );
          if (
            isGroupedOrder(params.data) &&
            !isNil(linkedStopDeadlineDateString)
          ) {
            return `${dateAbbreviated} ${linkedStopDeadlineDateString}`;
          }
          return dateAbbreviated;
        },
        suppressSizeToFit: true,
      },
      {
        field: DispatchTableField.ServiceDate,
        headerName: getDispatchHeaderCopy(DispatchTableField.ServiceDate),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          if (isNil(params.data?.stop.serviceDate)) {
            return '-';
          }
          const formattedDate = formattedDateAbbreviated(
            dayjs(params.data?.stop.serviceDate),
          );
          const linkedStopDeadlineDate =
            params.data?.linkedEndStop?.serviceDate;

          if (!isNil(linkedStopDeadlineDate)) {
            const linkedStopDeadlineDateString = formattedDateAbbreviated(
              dayjs(linkedStopDeadlineDate),
            );
            return `${formattedDate} ${linkedStopDeadlineDateString}`;
          }
          return formattedDate;
        },
        suppressSizeToFit: true,
      },
      {
        field: DispatchTableField.Address,
        headerName: getDispatchHeaderCopy(DispatchTableField.Address),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const addresses = [
            params.data?.stop.address?.line1,
            params.data?.linkedEndStop?.address?.line1,
          ]
            .filter((val) => !isNil(val))
            .map((val) => capitalCase(val));
          return filterNotNil(addresses).join(' ');
        },
      },
      {
        field: DispatchTableField.AddressType,
        headerName: getDispatchHeaderCopy(DispatchTableField.AddressType),
        hide: false,
        sortable: true,
        valueGetter: (params) => params?.data?.stop.standardStopType ?? 'NA',
      },
      {
        field: DispatchTableField.State,
        headerName: getDispatchHeaderCopy(DispatchTableField.State),
        hide: false,
        sortable: true,
        valueGetter: (params) => params?.data?.stop.address?.state ?? 'NA',
      },
      {
        field: DispatchTableField.Appointment,
        headerName: getDispatchHeaderCopy(DispatchTableField.Appointment),
        sortable: true,
        hide: false,
        suppressSizeToFit: true,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          const apptDate = transformDateStringToSpecifiedFormat(
            params.data?.stop.deliveryDate,
            'MM/DD/YY',
          );
          const apptTimeString = getAppointmentTimeString({
            stop: params.data?.stop,
            deliveryDate: params.data?.stop.deliveryDate,
            useMinimizedAppointmentTime,
          });
          const minimizedTimeWithAppointmentDate = useMinimizedAppointmentTime
            ? apptTimeString
            : `${apptDate} ${apptTimeString}`;

          if (isGroupedOrder(params.data)) {
            return `${minimizedTimeWithAppointmentDate} ${getAppointmentTimeString(
              {
                stop: params.data?.linkedEndStop,
                deliveryDate: params.data?.linkedEndStop?.deliveryDate,
                useMinimizedAppointmentTime,
              },
            )}`;
          }
          return minimizedTimeWithAppointmentDate;
        },
      },
      {
        field: DispatchTableField.AppointmentDate,
        headerName: getDispatchHeaderCopy(DispatchTableField.AppointmentDate),
        sortable: false,
        hide: false,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          const apptDate = transformDateStringToSpecifiedFormat(
            params.data?.stop.deliveryDate,
            'MM/DD/YY',
          );

          if (isGroupedOrder(params.data)) {
            const linkedEndStopApptDate = transformDateStringToSpecifiedFormat(
              params.data?.linkedEndStop?.deliveryDate,
              'MM/DD/YY',
            );
            return `${apptDate} ${linkedEndStopApptDate}`;
          }

          return apptDate;
        },
      },
      {
        field: DispatchTableField.AppointmentTime,
        headerName: getDispatchHeaderCopy(DispatchTableField.AppointmentTime),
        sortable: false,
        hide: false,
        valueGetter: (params: ValueGetterParams<StopFragment>) => {
          const apptTimeString = getAppointmentTimeString({
            stop: params.data?.stop,
            deliveryDate: params.data?.stop.deliveryDate,
            useMinimizedAppointmentTime,
          });

          if (isGroupedOrder(params.data)) {
            return `${apptTimeString} ${getAppointmentTimeString({
              stop: params.data?.linkedEndStop,
              deliveryDate: params.data?.linkedEndStop?.deliveryDate,
              useMinimizedAppointmentTime,
            })}`;
          }
          return apptTimeString;
        },
      },
      {
        field: DispatchTableField.Consignee,
        headerName: getDispatchHeaderCopy(DispatchTableField.Consignee),
        sortable: true,
        hide: false,
        valueGetter: (params) => {
          const stopAddressName = params.data?.stop.address?.name;
          const linkedEndStopConsignee =
            params.data?.linkedEndStop?.address?.name;
          if (
            isGroupedOrder(params.data) &&
            !isNilOrEmptyString(linkedEndStopConsignee)
          ) {
            if (!isNil(stopAddressName)) {
              return `${stopAddressName} ${linkedEndStopConsignee}`;
            }
            return linkedEndStopConsignee;
          }
          return stopAddressName;
        },
      },
      {
        field: DispatchTableField.AppointmentScheduled,
        hide: false,
        sortable: true,
        headerName: getDispatchHeaderCopy(
          DispatchTableField.AppointmentScheduled,
        ),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const appointmentScheduled = params?.data?.stop.appointmentScheduled;
          const linkedStopAppointmentScheduled =
            params.data?.linkedEndStop?.appointmentScheduled;
          return renderCell(
            appointmentScheduled === true ? (
              <CheckIcon color="success" sx={{ fontSize: '14px' }} />
            ) : (
              <CloseIcon color="error" sx={{ fontSize: '14px' }} />
            ),

            isGroupedOrder(params.data) ? (
              linkedStopAppointmentScheduled === true ? (
                <CheckIcon color="success" sx={{ fontSize: '14px' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '14px' }} />
              )
            ) : null,
          );
        },
      },
      {
        field: DispatchTableField.AppointmentRequired,
        hide: false,
        sortable: true,
        headerName: getDispatchHeaderCopy(
          DispatchTableField.AppointmentRequired,
        ),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const appointmentRequired = params?.data?.stop.appointmentRequired;
          const linkedStopAppointmentRequired =
            params.data?.linkedEndStop?.appointmentRequired;
          return renderCell(
            appointmentRequired === true ? (
              <CheckIcon color="success" sx={{ fontSize: '14px' }} />
            ) : (
              <CloseIcon color="error" sx={{ fontSize: '14px' }} />
            ),

            isGroupedOrder(params.data) ? (
              linkedStopAppointmentRequired === true ? (
                <CheckIcon color="success" sx={{ fontSize: '14px' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '14px' }} />
              )
            ) : null,
          );
        },
      },
      {
        field: DispatchTableField.Customer,
        headerName: getDispatchHeaderCopy(DispatchTableField.Customer),
        sortable: true,
        hide: false,
        valueGetter: (params) => params.data?.order?.contactName,
      },
      {
        field: DispatchTableField.ServiceLevel,
        headerName: getDispatchHeaderCopy(DispatchTableField.ServiceLevel),
        hide: false,
        sortable: true,
        valueGetter: (params) => params.data?.order?.serviceName,
      },
      {
        field: DispatchTableField.ServiceTime,
        headerName: getDispatchHeaderCopy(DispatchTableField.ServiceTime),
        hide: false,
        sortable: false,
        valueGetter: (params) => {
          const serviceTimeInMinutes = params.data?.stop.serviceTimeInMinutes;
          const linkedEndStopServiceTimeInMinutes =
            params.data?.linkedEndStop?.serviceTimeInMinutes;
          if (isGroupedOrder(params.data)) {
            return `${serviceTimeInMinutes} ${linkedEndStopServiceTimeInMinutes ?? ''}`;
          }
          return serviceTimeInMinutes;
        },
      },
      {
        field: DispatchTableField.VehicleTypeName,
        headerName: getDispatchHeaderCopy(DispatchTableField.VehicleTypeName),
        hide: false,
        sortable: true,
        valueGetter: (params) => params.data?.order?.vehicleTypeName,
      },
      {
        field: DispatchTableField.BusinessDivision,
        headerName: getDispatchHeaderCopy(DispatchTableField.BusinessDivision),
        hide: false,
        sortable: true,
        valueGetter: (params) => params.data?.order?.businessDivisionName,
      },
      {
        field: DispatchTableField.SpecialInstructions,
        headerName: getDispatchHeaderCopy(
          DispatchTableField.SpecialInstructions,
        ),
        hide: false,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const linkedEndStopSpecialInstructions =
            params?.data?.linkedEndStop?.specialInstructions;
          return renderCell(
            <NotesComponent
              notes={params?.data?.stop.specialInstructions ?? ''}
              buttonSx={{ padding: '0 3px' }}
            />,
            isNil(linkedEndStopSpecialInstructions) ? null : (
              <NotesComponent
                notes={linkedEndStopSpecialInstructions}
                buttonSx={{ padding: '0 3px' }}
              />
            ),
            true,
          );
        },
      },
      {
        field: DispatchTableField.PieceCount,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.PieceCount),
        cellStyle: { textAlign: 'center' },
        valueGetter: (params) => {
          const packages = params.data?.order?.packages;
          return packages?.reduce((prev, curr) => curr.quantity + prev, 0);
        },
      },
      {
        field: DispatchTableField.Dims,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Dims),
        cellStyle: { textAlign: 'center' },
        valueGetter: (params) => {
          const packages = params?.data?.order?.packages;
          const packagesArr = filterNotNil(
            packages?.map((package_) => {
              const { length, width, height, quantity } = package_;
              if (!isNil(length) && !isNil(height) && !isNil(width)) {
                return `${quantity} ${length}x${width}x${height}`;
              }
              return quantity;
            }) ?? [],
          );
          return packagesArr.join(', ');
        },
      },
      {
        field: DispatchTableField.Tags,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Tags),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const tags = params?.data?.order?.tags;
          return <TagsCell tags={tags ?? []} />;
        },
      },
      {
        field: DispatchTableField.Weight,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Weight),
        cellStyle: { textAlign: 'center' },
        valueGetter: (params) => {
          const packages = params.data?.order?.packages;
          const totalWeight = packages?.reduce(
            (prev, curr) => (isNil(curr.weight) ? prev : curr.weight + prev),
            0,
          );
          return isNil(totalWeight) ? null : `${round(totalWeight, 1)}`;
        },
      },
      {
        field: DispatchTableField.OrderNotes,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.OrderNotes),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams<StopFragment>) => (
          <NotesComponent
            notes={`${params.data?.order?.orderComments
              ?.map((orderComment) => orderComment.comment)
              .join('\n')}`}
          />
        ),
      },
      {
        field: DispatchTableField.Special,
        hide: false,
        headerName: getDispatchHeaderCopy(DispatchTableField.Special),
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params: ICellRendererParams<StopFragment>) => {
          const isSpecial = params?.data?.stop.isSpecial;
          const linkedEndStopIsSpecial = params.data?.linkedEndStop?.isSpecial;
          return renderCell(
            isSpecial === true ? (
              <CheckIcon color="success" sx={{ fontSize: '14px' }} />
            ) : (
              <CloseIcon color="error" sx={{ fontSize: '14px' }} />
            ),

            isGroupedOrder(params.data) ? (
              linkedEndStopIsSpecial === true ? (
                <CheckIcon color="success" sx={{ fontSize: '14px' }} />
              ) : (
                <CloseIcon color="error" sx={{ fontSize: '14px' }} />
              )
            ) : null,
          );
        },
      },
      {
        field: DispatchTableField.RouteName,
        headerName: getDispatchHeaderCopy(DispatchTableField.RouteName),
        hide: false,
        sortable: true,
        valueGetter: (params) => params?.data?.stop.address?.routeName ?? 'NA',
      },
      {
        field: DispatchTableField.Terminal,
        headerName: getDispatchHeaderCopy(DispatchTableField.Terminal),
        hide: false,
        sortable: true,
        valueGetter: (params) => params?.data?.stop.terminalName,
      },
      {
        field: DispatchTableField.TotalCharges,
        headerName: getDispatchHeaderCopy(DispatchTableField.TotalCharges),
        hide: false,
        valueGetter: (params) => {
          const totalCharge = params.data?.stop.totalCharge;
          return isNil(totalCharge) ? null : currency(totalCharge).format();
        },
      },
      {
        field: DispatchTableField.InboundName,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundName),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const inboundName = params.data?.order?.inboundName;
          if (!isNil(inboundName)) {
            return inboundName;
          }

          const stopType = params.data?.stop.stopType;
          const otherStopAddressName = params.data?.otherStop?.address?.name;

          if (isNil(stopType)) {
            return '-';
          }

          // This is an inbound stop
          if ([StopType.Pickup, StopType.Recovery].includes(stopType)) {
            return '-';
          }

          return isNilOrEmptyString(otherStopAddressName)
            ? '-'
            : otherStopAddressName;
        },
      },
      {
        field: DispatchTableField.OutboundName,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundName),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const outboundName = params.data?.order?.outboundName;
          if (!isNil(outboundName)) {
            return outboundName;
          }

          const stopType = params.data?.stop.stopType;
          const otherStopAddressName = params.data?.otherStop?.address?.name;

          if (isNil(stopType)) {
            return '-';
          }

          // This is an outbound stop
          if ([StopType.Delivery, StopType.Transfer].includes(stopType)) {
            return '-';
          }

          return isNilOrEmptyString(otherStopAddressName)
            ? '-'
            : otherStopAddressName;
        },
      },
      {
        field: DispatchTableField.InboundZip,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundZip),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const inboundZip = params.data?.order?.inboundZip;
          return inboundZip ?? '-';
        },
      },
      {
        field: DispatchTableField.OutboundZip,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundZip),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const outboundZip = params.data?.order?.outboundZip;
          return outboundZip ?? '-';
        },
      },
      {
        field: DispatchTableField.InboundCity,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundCity),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const outboundZip = params.data?.order?.inboundCity;
          return outboundZip ?? '-';
        },
      },
      {
        field: DispatchTableField.OutboundCity,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundCity),
        hide: false,
        sortable: true,
        valueGetter: (params) => {
          const outboundCity = params.data?.order?.outboundCity;
          return outboundCity ?? '-';
        },
      },
      {
        field: DispatchTableField.InboundPaperwork,
        headerName: getDispatchHeaderCopy(DispatchTableField.InboundPaperwork),
        hide: !ffInboundOutboundPaperworkColumns,
        suppressColumnsToolPanel: !ffInboundOutboundPaperworkColumns,
        sortable: true,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => (
          <Paperwork isSmall paperwork={params.data?.order?.inboundPaperwork} />
        ),
      },
      {
        field: DispatchTableField.OutboundPaperwork,
        headerName: getDispatchHeaderCopy(DispatchTableField.OutboundPaperwork),
        hide: !ffInboundOutboundPaperworkColumns,
        suppressColumnsToolPanel: !ffInboundOutboundPaperworkColumns,
        sortable: true,
        cellRenderer: (params: ICellRendererParams<StopFragment>) => (
          <Paperwork
            isSmall
            paperwork={params.data?.order?.outboundPaperwork}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatchTableColors,
      ffInboundOutboundPaperworkColumns,
      setOpenedOrderUuid,
    ],
  );
  const { filterColumns } = useStopFilterColumns();
  const stopColumns = useMemo(
    () => [...filterColumns, ...displayColumns],
    [displayColumns, filterColumns],
  );
  return stopColumns;
};

export default useStopColumns;
