import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import { useAccessorialsQuery } from '../../../../../generated/graphql';
import { getOrderSchema } from './order-schema';
import { type OrderFormValues } from './types';

type UseOrderFormOptions = {
  isRecurringTemplate: boolean;
};

type UseOrderFormReturn = {
  form: UseFormReturn<OrderFormValues>;
  loading: boolean;
};

const useOrderForm = ({
  isRecurringTemplate,
}: UseOrderFormOptions): UseOrderFormReturn => {
  const { companyData, companyConfiguration, loading: meLoading } = useMe();
  const disallowZeroDollarCharges = useFeatureFlag(
    FeatureFlag.FF_DISALLOW_ZERO_DOLLAR_CHARGES,
  );
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );

  const serviceLevelOptional = companyConfiguration?.serviceLevelOptional;
  const fulfillmentTypeEnabled = companyConfiguration?.fulfillmentTypeEnabled;
  // useOrderFormAccessorials can only be used within a form context :(
  const { data: accessorialsData, loading: accessorialsLoading } =
    useAccessorialsQuery({
      variables: {
        onlyShowCompanyAccessorials: true,
      },
    });
  const loading = meLoading || accessorialsLoading;
  const schema = useMemo(
    () =>
      getOrderSchema({
        disallowZeroDollarCharges,
        accessorials: accessorialsData?.accessorials ?? [],
        ffRecoveryTransferAddressOnly,
        requireServiceLevel: serviceLevelOptional !== true,
        requireFulfillmentType: fulfillmentTypeEnabled === true,
        isRecurringTemplate,
        orderFormFields: companyData?.orderFormFields,
      }),
    [
      disallowZeroDollarCharges,
      accessorialsData?.accessorials,
      ffRecoveryTransferAddressOnly,

      serviceLevelOptional,
      fulfillmentTypeEnabled,
      isRecurringTemplate,
      companyData?.orderFormFields,
    ],
  );

  const form: UseFormReturn<OrderFormValues> = useForm({
    resolver: zodResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
  });

  return { form, loading };
};

export default useOrderForm;
