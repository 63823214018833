import { Stack, Typography } from '@mui/material';
import React from 'react';
import { type PackageDifferenceFragment } from '../../../../../../../../../generated/graphql';
import { buildStringDifferenceBlockComponent } from '../audit-log-utils';

type PackageDifferenceBlockProps = {
  readonly packageDifferences: PackageDifferenceFragment[];
  readonly isOldPackages: boolean;
};

const PackageDifferenceBlock = ({
  packageDifferences,
  isOldPackages,
}: PackageDifferenceBlockProps) => {
  return (
    <Stack>
      {}
      {packageDifferences.map((packageDifference) => {
        if (packageDifference.wasAdded && isOldPackages) {
          // eslint-disable-next-line react/jsx-key
          return <Typography fontSize={12}> None </Typography>;
        }
        if (packageDifference.wasDeleted && !isOldPackages) {
          // eslint-disable-next-line react/jsx-key
          return <Typography fontSize={12}> None </Typography>;
        }
        return (
          // eslint-disable-next-line react/jsx-key
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {buildStringDifferenceBlockComponent(
              packageDifference.weight?.oldValue?.toString() ?? undefined,
              packageDifference.weight?.newValue?.toString() ?? undefined,
              isOldPackages,
              false,
              false,
              12,
            )}
            <Typography fontSize={12}>@</Typography>
            {buildStringDifferenceBlockComponent(
              packageDifference.length?.oldValue?.toString() ?? undefined,
              packageDifference.length?.newValue?.toString() ?? undefined,
              isOldPackages,
              false,
              false,
              12,
            )}
            <Typography fontSize={12}>x</Typography>
            {buildStringDifferenceBlockComponent(
              packageDifference.width?.oldValue?.toString() ?? undefined,
              packageDifference.width?.newValue?.toString() ?? undefined,
              isOldPackages,
              false,
              false,
              12,
            )}
            <Typography fontSize={12}>x</Typography>
            {buildStringDifferenceBlockComponent(
              packageDifference.height?.oldValue?.toString() ?? undefined,
              packageDifference.height?.newValue?.toString() ?? undefined,
              isOldPackages,
              false,
              false,
              12,
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default PackageDifferenceBlock;
