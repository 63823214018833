import { safeAdd } from 'shared/math';
import { uniqBy } from 'lodash';
import { type TableStopOnRoute } from './route-card-stops-list-columns';

export const calculateRouteTotalCharge = ({
  stops,
}: {
  stops: TableStopOnRoute[];
}) => {
  const orders = stops.map((stop) => stop.shipment?.order);
  // Get unique orders to avoid double counting charges for multi-stop orders
  const uniqueOrders = uniqBy(orders, (order) => order?.uuid);
  let totalCharge = 0;
  for (const order of uniqueOrders) {
    totalCharge = safeAdd(totalCharge, order?.totalCharge ?? 0);
  }
  return totalCharge;
};
