import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import {
  type QuoteFragment,
  type QuoteStatus,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type QuoteValues = Omit<
  QuoteFragment,
  | 'packages'
  | 'shipments'
  | 'billingPartyContact'
  | 'order'
  | 'service'
  | 'createdAt'
> & {
  packageUuids: string[];
  shipmentUuids: string[];
  contactUuid: string;
  status: QuoteStatus;
};

const quotesValuesAdapter = createEntityAdapter<QuoteValues>({
  selectId: (quoteValues) => quoteValues.uuid,
});

export const quotesValuesSlice = createSlice({
  name: 'quotesValues',
  initialState: quotesValuesAdapter.getInitialState(),
  reducers: {
    addQuoteValues: quotesValuesAdapter.addOne,
    updateOneQuoteValues: quotesValuesAdapter.updateOne,
    upsertOneQuoteValues: quotesValuesAdapter.upsertOne,
    updateManyQuoteValues: quotesValuesAdapter.updateMany,
  },
});

export const {
  selectById: selectQuoteValuesById,
  selectAll: selectAllQuoteValues,
} = quotesValuesAdapter.getSelectors((state: RootState) => state.quoteValues);

export const selectPackageUuidsForQuote = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.packageUuids;
  },
);

export const selectQuoteDimFactor = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.dimFactor;
  },
);

export const selectQuoteShipmentUuids = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.shipmentUuids;
  },
);

export const selectQuoteContactUuid = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.contactUuid;
  },
);

export const selectQuoteMeasurementUnits = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.measurementUnits;
  },
);

export const selectQuoteWeightUnits = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.weightUnits;
  },
);

export const selectQuoteTotalSkids = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.totalSkids;
  },
);

const quotesSelector = (state: RootState) => state.quoteValues;

export const selectQuotesByIds = createSelector(
  quotesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<QuoteValues>, entityIds: EntityId[]) => {
    const entityResults: QuoteValues[] = [];
    for (const id of entityIds) {
      const match = quotesValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const selectPackageUuidsForQuotes = createSelector(
  selectQuoteValuesById,
  (values: QuoteValues | undefined) => {
    return values?.packageUuids;
  },
);

export const {
  addQuoteValues,
  updateOneQuoteValues,
  upsertOneQuoteValues,
  updateManyQuoteValues,
} = quotesValuesSlice.actions;

const quotesValuesReducer = quotesValuesSlice.reducer;

export default quotesValuesReducer;
