import { FormControl, FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { type FieldError } from 'react-hook-form';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import { type Option } from '../../../../../common/types';
import { type ShallowTerminalFragment } from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

type BaseTerminalAutocompleteProps = {
  readonly terminals: ShallowTerminalFragment[];
  readonly terminalUuid: string | null | undefined;
  readonly disabled: boolean;
  readonly onChange: (event: SyntheticEvent, value: Option | null) => void;
  readonly error: FieldError | undefined;
  readonly terminalSelectTestId?: string;
  readonly terminalSelectInputTestId?: string;
};

const BaseTerminalAutocomplete = ({
  terminals,
  terminalUuid,
  disabled,
  onChange,
  error,
  terminalSelectTestId,
  terminalSelectInputTestId,
}: BaseTerminalAutocompleteProps) => {
  const { getTerminal } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [terminalsToUse, setTerminalsToUse] = useState(terminals);

  const terminalOptions = useMemo(() => {
    return (
      terminalsToUse
        ?.map((terminal) => ({
          value: terminal.uuid,
          label: terminal.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [terminalsToUse]);

  useEffect(() => {
    const fetchTerminal = async () => {
      if (
        isNil(terminalUuid) ||
        terminals.length === 0 ||
        !isNil(terminals.find((t) => t.uuid === terminalUuid))
      ) {
        setTerminalsToUse(terminals);
        return;
      }

      // this is for the case where the existing uuid corresponds to an archived contact
      const currentlySelectedTerminal = getTerminal(terminalUuid);

      if (currentlySelectedTerminal?.isActive === false) {
        setTerminalsToUse([...terminals, currentlySelectedTerminal]);
      }
    };
    void fetchTerminal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminals.length, terminalUuid]);

  return (
    <FormControl fullWidth>
      <AutocompleteFuzzy
        size="small"
        value={
          isNil(terminalUuid)
            ? null
            : {
                value: terminalUuid,
                label:
                  terminalOptions.find(
                    (terminal) => terminal.value === terminalUuid,
                  )?.label ?? '',
              }
        }
        matchSortOptions={{ keys: ['label'] }}
        options={terminalOptions}
        data-testid={terminalSelectTestId}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Terminal"
            inputProps={{
              ...params.inputProps,
              'data-testid': terminalSelectInputTestId,
            }}
            error={!isNil(error)}
          />
        )}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={onChange}
      />
      {!isNil(error?.message) && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          {error?.message?.toString()}
        </FormHelperText>
      )}
    </FormControl>
  );
};

type TerminalAutocompleteProps = Omit<
  BaseTerminalAutocompleteProps,
  'terminals'
>;

/**
 * A wrapper around BaseTerminalAutocomplete that also provides terminals from
 * the useTerminals hook.
 */
const TerminalAutocomplete = (props: TerminalAutocompleteProps) => {
  const { terminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  return <BaseTerminalAutocomplete {...props} terminals={terminals} />;
};

export { BaseTerminalAutocomplete, TerminalAutocomplete };
