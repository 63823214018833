import { TextField, Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction } from 'react';

const GeneralDatePicker = ({
  date,
  dateNil,
  setDate,
  setDateNil,
  text,
  color,
  isLarge,
  width,
}: {
  readonly date?: Dayjs;
  readonly dateNil?: Dayjs | undefined;
  readonly setDate?: (newPlanningDate: Dayjs) => void;
  readonly setDateNil?: Dispatch<SetStateAction<Dayjs | undefined>>;
  readonly text?: string;
  readonly color?: string;
  readonly isLarge?: boolean;
  readonly width?: string | number;
}) => {
  // Disable any typing into the date picker

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <DatePicker
          label={text}
          renderInput={(params) => (
            <TextField
              aria-label="date-picker"
              sx={{
                width: width ?? 200,
                height: '40px',
                backgroundColor: color,
              }}
              size={isLarge === true ? 'medium' : 'small'}
              onKeyDown={onKeyDown}
              {...params}
            />
          )}
          value={isNil(date) ? (dateNil ?? null) : date}
          onChange={(newDate) => {
            // TODO: Have a visual indicator to the user when the date they entered is invalid.
            if (newDate !== null) {
              if (!isNil(setDate)) {
                setDate(newDate);
              } else if (!isNil(setDateNil)) {
                setDateNil(newDate);
              }
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default GeneralDatePicker;
