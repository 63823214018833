import { Stack } from '@mui/material';
import { type Dayjs } from 'dayjs';
import type { Dispatch, SetStateAction } from 'react';
import { useGroupedManifests } from './hooks/use-grouped-manifests';
import ManifestLaneGroup from './manifest-lane-group';
import { type LineHaulManifest } from './utils';

type ManifestLaneGroupsProps = {
  readonly manifests: LineHaulManifest[];
  readonly selectedManifestIds: Set<string>;
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
  readonly setOpenedManifestUuid: Dispatch<SetStateAction<string | null>>;
  readonly planningDate: Dayjs;
};

const ManifestLaneGroups = ({
  manifests,
  selectedManifestIds,
  setSelectedManifestIds,
  setOpenedManifestUuid,
  planningDate,
}: ManifestLaneGroupsProps) => {
  const groupedManifests = useGroupedManifests(manifests);

  return (
    <Stack gap={1} pb={selectedManifestIds.size > 0 ? 12 : 2}>
      {groupedManifests.map(([pairKey, manifests]) => (
        <ManifestLaneGroup
          key={pairKey}
          manifests={manifests}
          selectedManifestIds={selectedManifestIds}
          setSelectedManifestIds={setSelectedManifestIds}
          setOpenedManifestUuid={setOpenedManifestUuid}
          planningDate={planningDate}
        />
      ))}
    </Stack>
  );
};

export default ManifestLaneGroups;
