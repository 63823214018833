import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type ShallowLineHaulLaneFragment,
  useLineHaulLanesQuery,
} from '../../../../generated/graphql';
import { ActiveArchivedButtonGroup } from '../common/active-or-archived-button-group';
import { ActiveOrArchivedTab } from '../common/enums';

const LineHaulLanesTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyLineHaul } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyLineHaul,
  );

  const { data: lineHaulLanes } = useLineHaulLanesQuery();

  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const lanesFiltered = lineHaulLanes?.lineHaulLanes.filter((l) =>
    isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
      ? l.isActive
      : !l.isActive,
  );
  const navigate = useNavigate();
  return (
    <>
      <ActiveArchivedButtonGroup
        activeTab={isActiveOrArchived}
        setTab={setIsActiveOrArchived}
      />
      <Stack direction="column" alignItems="flex-end" sx={{ display: 'flex' }}>
        <Button
          sx={{ marginBottom: '16px' }}
          variant="contained"
          disabled={!canWriteCompanyLineHaul}
          onClick={() => {
            navigate(`/management/line-haul/create`);
          }}
        >
          Add Lane
        </Button>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Origin terminal</TableCell>
                <TableCell>Destination terminal</TableCell>
                <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lanesFiltered?.map((row: ShallowLineHaulLaneFragment) => {
                return (
                  <TableRow
                    key={row.uuid}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {row.originTerminal.code}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography variant="body1">
                          {row.destinationTerminal.code}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Button
                          fullWidth
                          variant="outlined"
                          disabled={!canWriteCompanyLineHaul}
                          onClick={() => {
                            navigate(`/management/line-haul/${row.uuid}`);
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default LineHaulLanesTable;
