import { type AddOrdersToInvoicesSuccessOutput } from '../../../../../generated/graphql';

export const getAddedOrderUuidsFromOutput = (
  addOrdersToInvoicesSuccessOutput: AddOrdersToInvoicesSuccessOutput,
) => {
  const addedOrderUuids =
    addOrdersToInvoicesSuccessOutput.invoicesAddedToByContact.flatMap((i) => {
      if (i.__typename === 'AddOrdersToInvoicesForContactSuccessOutput') {
        return [
          ...i.existingInvoiceAdds.flatMap((a) =>
            a.__typename === 'AddOrdersToInvoiceSuccessOutput'
              ? a.ordersAdded.map((o) => o.orderUuid)
              : [],
          ),
          ...i.newInvoiceAdds.flatMap((n) =>
            n.__typename === 'AddOrdersToInvoiceSuccessOutput'
              ? n.ordersAdded.map((o) => o.orderUuid)
              : [],
          ),
        ];
      }
      return [];
    });

  return addedOrderUuids;
};
