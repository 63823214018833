import { exhaustive } from 'shared/switch';
import { DriverType, SettlementMode } from '../../generated/graphql';
import { isEmpty } from 'lodash';

type GetIsIndependentSettlementModeArgs = {
  driverType: string | null | undefined;
  defaultNonCompanyDriverSettlementMode: SettlementMode | undefined;
};

const isDriverType = (
  value: string | null | undefined,
): value is DriverType => {
  return Object.values(DriverType).includes(value as DriverType);
};

export const getIsIndependentSettlementMode = ({
  driverType,
  defaultNonCompanyDriverSettlementMode,
}: GetIsIndependentSettlementModeArgs): boolean => {
  if (isEmpty(driverType)) {
    return false;
  }
  if (!isDriverType(driverType)) {
    return false;
  }
  switch (driverType) {
    case DriverType.OwnerOperator:
    case DriverType.Contractor: {
      return (
        defaultNonCompanyDriverSettlementMode === SettlementMode.Independent
      );
    }
    case DriverType.CompanyDriver:
    case null: {
      return false;
    }
    default: {
      exhaustive(driverType);
    }
  }
};
