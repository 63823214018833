import { z } from 'zod';

export const contactPersonSchema = z.object({
  uuid: z.string().uuid(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  notes: z.string().nullish(),
  phone: z.string().nullish(),
  email: z.preprocess(
    // Preprocess empty strings to undefined
    // because email() doesn't accept empty strings
    (val) => (val === '' ? undefined : val),
    z.string().email({ message: 'Invalid contact email' }).nullish(),
  ),
});

/**
 * @see {contactPersonSchema} with all fields optional except for uuid
 *
 * We use this for fields like shipperContactPerson and consigneeContactPerson
 * which aren't visible to the user and may be null/undefined or have
 * missing/empty values from EDI or doc scanning
 *
 * Eventually we should remove these values from the form entirely, but in the
 * meantime we want to validate them as loosely as possible.
 */
export const contactPersonSchemaWithAllOptionalFields = contactPersonSchema
  .extend({
    // Remove string validations
    phone: z.string().nullish(),
    email: z.string().nullish(),
  })
  .partial()
  .extend({
    uuid: z.string().uuid(),
  });
