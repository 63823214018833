import { StopStatus, StopType } from '../../../../../generated/graphql';
import { isInboundStop } from './stop-type';
import {
  getDisabledForEditAccess,
  type OrderFormEditAccess,
} from './use-order-form-edit-access';

export const isServiceDateFieldDisabled = ({
  stopStatus,
  editAccess,
}: {
  stopStatus: string | null | undefined;
  editAccess: OrderFormEditAccess;
}) => {
  const { disabledIfInvoicePosted } = getDisabledForEditAccess({ editAccess });
  return disabledIfInvoicePosted || stopStatus === StopStatus.Completed;
};

/**
 * A function that determines whether the provided stop type requires a service date.
 * Service dates for inbound stops are always required to ensure an order has a service date at all times.
 * The outbound stop service dates are required in the following scenarios:
 * 1. It is a delivery stop
 * 2. It is a transfer or a PCP with no inbound stop
 * @param stopType the stop type in question
 * @param otherStopType the other stop type on the order.
 */
export function isServiceDateRequiredForStop({
  stopType,
  otherStopType,
}: {
  stopType: StopType;
  otherStopType: StopType;
}) {
  if (isInboundStop(stopType)) {
    return true;
  }

  const isDeliveryStop = stopType === StopType.Delivery;
  const isTransferOrPartnerCarrierPickupWithNoInboundStop =
    (stopType === StopType.Transfer ||
      stopType === StopType.PartnerCarrierPickup) &&
    otherStopType === StopType.None;

  return isDeliveryStop || isTransferOrPartnerCarrierPickupWithNoInboundStop;
}
