import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil, isString } from 'lodash';
import { Fragment, type FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cubicFeet, pounds } from 'shared/units/rates';
import { isValidNonNegativeNumber } from '../../../../../utils';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { useHasUnsavedChanges } from '../../../../common/react-hooks/use-has-unsaved-changes';
import {
  PackageSpecStatus,
  useArchiveVehicleTypeMutation,
  useCreateVehicleTypeMutation,
  usePackageSpecsMinimalQuery,
  useRestoreVehicleTypeMutation,
  useUpdateVehicleTypeMutation,
  useVehicleTypeQuery,
  type VehicleTypeCreateInput,
  type VehicleTypeFragment,
  type VehicleTypePackageSpecUpsertInput,
  VehicleTypeStatus,
} from '../../../../generated/graphql';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'white',
    width: '100%',
    borderBottom: '1px solid',
    borderColor: 'divider',
    p: 2,
  },
  backButton: {
    p: 0,
    minWidth: 'max-content',
    mb: 1,
  },
  title: {
    fontSize: '15px',
    fontWeight: 700,
  },
  sectionHeading: {
    fontSize: '14.5px',
    fontWeight: 500,
  },
  packageSpecs: {
    mt: 2,
    display: 'grid',
    alignItems: 'center',
    columnGap: '10px',
    gridAutoRows: '52px',
  },
  gridBorderRight: {
    borderRight: '1px solid',
    borderColor: 'divider',
    height: '100%',
    paddingLeft: '14px',
    marginRight: '14px',
  },
};

type VehicleTypePageCreateMode = { mode: 'create' };
type VehicleTypePageEditMode = { mode: 'edit'; uuid: string };
type VehicleTypePageViewMode = { mode: 'view'; uuid: string };

export type VehicleTypePageMode =
  | VehicleTypePageCreateMode
  | VehicleTypePageEditMode
  | VehicleTypePageViewMode;

type VehicleTypeFormData = {
  name: string;
  maxWeightPounds: string | null;
  maxVolumeCuFeet: string | null;
  capacityNotes: string | null;
  packageSpecs: VehicleTypePackageSpecUpsertInput[];
};

type VehicleTypeFormErrors = {
  [key in keyof VehicleTypeFormData]?: string;
};

const requiredFields: Array<keyof VehicleTypeFormData> = ['name'];

type VehicleTypeBodyProps = {
  readonly pageMode: VehicleTypePageMode;
  readonly initialData?: VehicleTypeFragment;
  readonly onSave: (vehicleType: VehicleTypeCreateInput) => Promise<void>;
  readonly saving: boolean;
  // If an onToggleArchive function is provided, an archive/un-archive button will be shown.
  readonly onToggleArchive?: () => void;
  readonly togglingArchive?: boolean;
};

const VehicleTypeBody: FunctionComponent<VehicleTypeBodyProps> = ({
  pageMode,
  initialData,
  onSave,
  saving,
  onToggleArchive,
  togglingArchive = false,
}) => {
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const { data: packageSpecsData, loading: loadingPackageSpecs } =
    usePackageSpecsMinimalQuery();
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();
  const [formData, setFormData] = useState<VehicleTypeFormData>({
    name: '',
    maxWeightPounds: null,
    maxVolumeCuFeet: null,
    capacityNotes: null,
    packageSpecs: [],
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: initialData?.name ?? '',
      maxWeightPounds:
        initialData?.maxWeight?.in(pounds).amount.toString() ?? null,
      maxVolumeCuFeet:
        initialData?.maxVolume?.in(cubicFeet).amount.toString() ?? null,
      capacityNotes: initialData?.capacityNotes ?? null,
      packageSpecs:
        initialData?.vehicleTypePackageSpecs.map(
          ({ packageSpec, maxQuantity }) => ({
            packageSpecId: packageSpec.id,
            maxQuantity,
          }),
        ) ?? [],
    }));
  }, [initialData]);

  const [formErrors, setFormErrors] = useState<VehicleTypeFormErrors>({});

  const handleSave = async () => {
    let hasError = false;
    const newErrors: VehicleTypeFormErrors = {};
    for (const field of requiredFields) {
      const value = formData[field];
      if (isNil(value) || (isString(value) && value.length === 0)) {
        newErrors[field] = 'This field is required';
        hasError = true;
      }
    }
    const maxWeightPounds = formData.maxWeightPounds?.trim();
    if (
      !isNil(maxWeightPounds) &&
      maxWeightPounds.length > 0 &&
      !isValidNonNegativeNumber(maxWeightPounds)
    ) {
      newErrors.maxWeightPounds = 'Please enter a valid weight';
      hasError = true;
    }
    const maxVolumeCuFeet = formData.maxVolumeCuFeet?.trim();
    if (
      !isNil(maxVolumeCuFeet) &&
      maxVolumeCuFeet.length > 0 &&
      !isValidNonNegativeNumber(maxVolumeCuFeet)
    ) {
      newErrors.maxVolumeCuFeet = 'Please enter a valid volume';
      hasError = true;
    }
    setFormErrors(newErrors);
    if (!hasError) {
      await onSave({
        name: formData.name,
        maxWeight:
          isNil(maxWeightPounds) || maxWeightPounds.length === 0
            ? null
            : pounds(Number(maxWeightPounds)),
        maxVolume:
          isNil(maxVolumeCuFeet) || maxVolumeCuFeet.length === 0
            ? null
            : cubicFeet(Number(maxVolumeCuFeet)),
        capacityNotes: formData.capacityNotes,
        packageSpecs: formData.packageSpecs,
      });
      resetHasUnsavedChanges();
    }
  };

  // Show between 1 and 3 columns in the package specs grid.
  const numPackagesColumns = Math.max(
    Math.min(packageSpecsData?.packageSpecs.packageSpecs.length ?? 1, 3),
    1,
  );

  const activePackageSpecs = packageSpecsData?.packageSpecs.packageSpecs.filter(
    ({ status }) => status === PackageSpecStatus.Active,
  );

  const status = !isNil(initialData) && initialData.status;

  return (
    <>
      <Box sx={styles.container} gap={1}>
        <Stack
          pb={2}
          justifyContent="space-between"
          direction="row"
          alignItems="start"
        >
          <Box>
            <ButtonLink
              disabled={saving || togglingArchive}
              href="/management"
              variant="text"
              size="small"
              sx={styles.backButton}
            >
              Back
            </ButtonLink>
            <Typography variant="h3" sx={styles.title}>
              {}
              {pageMode.mode === 'create'
                ? 'Add Vehicle Type'
                : pageMode.mode === 'edit'
                  ? 'Edit Vehicle Type'
                  : 'Vehicle Type'}
            </Typography>
          </Box>
          {pageMode.mode !== 'view' && (
            <Button
              disabled={saving || togglingArchive || !hasUnsavedChanges}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </Stack>
        <FormControl
          required
          disabled={pageMode.mode === 'view'}
          error={'name' in formErrors}
          sx={{ width: 200 }}
        >
          <TextField
            id="vehicle-type-name"
            label="Name"
            value={formData.name}
            error={'name' in formErrors}
            style={{ width: '280px' }}
            onChange={({ target }) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                name: target.value,
              }));
              setFormErrors(({ name: _ignored, ...prevFormErrors }) => ({
                ...prevFormErrors,
              }));
              triggerHasUnsavedChanges();
            }}
          />
          {'name' in formErrors && (
            <FormHelperText error>{formErrors.name}</FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={styles.container}>
        <Typography sx={styles.sectionHeading}>Capacity</Typography>
        <Typography variant="caption" color="text.secondary">
          Optionally specify the capacity of this vehicle type. If left blank,
          no limit is enforced.
        </Typography>
        <Stack direction="row" alignItems="center" gap={2} mt={2}>
          <InputLabel
            htmlFor="vehicle-type-max-weight"
            style={{ width: '100px' }}
          >
            Max weight
          </InputLabel>
          <TextField
            id="vehicle-type-max-weight"
            sx={{ width: '85px' }}
            error={'maxWeightPounds' in formErrors}
            value={formData.maxWeightPounds ?? ''}
            onChange={({ target }) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                maxWeightPounds: target.value,
              }));
              setFormErrors(
                ({ maxWeightPounds: _ignored, ...prevFormErrors }) => ({
                  ...prevFormErrors,
                }),
              );
              triggerHasUnsavedChanges();
            }}
          />
          <Typography variant="body1" color="text.secondary">
            pounds
          </Typography>
        </Stack>
        {'maxWeightPounds' in formErrors && (
          <FormHelperText error>{formErrors.maxWeightPounds}</FormHelperText>
        )}
        <Stack direction="row" alignItems="center" gap={2} mt={2}>
          <InputLabel
            htmlFor="vehicle-type-max-volume"
            style={{ width: '100px' }}
          >
            Max volume
          </InputLabel>
          <TextField
            id="vehicle-type-max-volume"
            sx={{ width: '85px' }}
            error={'maxVolumeCuFeet' in formErrors}
            value={formData.maxVolumeCuFeet ?? ''}
            onChange={({ target }) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                maxVolumeCuFeet: target.value,
              }));
              setFormErrors(
                ({ maxVolumeCuFeet: _ignored, ...prevFormErrors }) => ({
                  ...prevFormErrors,
                }),
              );
              triggerHasUnsavedChanges();
            }}
          />
          <Typography variant="body1" color="text.secondary">
            cubic ft
          </Typography>
        </Stack>
        {'maxVolumeCuFeet' in formErrors && (
          <FormHelperText error>{formErrors.maxVolumeCuFeet}</FormHelperText>
        )}
      </Box>
      {ffPackageSpecs && (
        <Box sx={styles.container} gap={1}>
          <Box>
            <Typography sx={styles.sectionHeading}>
              Supported package types
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Select the type and maximum number of packages this vehicle can
              carry.
            </Typography>
          </Box>
          {loadingPackageSpecs ? (
            <CircularProgress />
          ) : (
            !isNil(activePackageSpecs) &&
            (activePackageSpecs.length === 0 ? (
              // Note that we shouldn't end up in this state because we give each company some default package specs.
              <Typography>
                You do not have any package specs configured yet.
              </Typography>
            ) : (
              <Box
                sx={{
                  ...styles.packageSpecs,
                  gridTemplateColumns: `repeat(${numPackagesColumns}, 25px 150px 75px 29px)`,
                }}
              >
                {new Array(numPackagesColumns).fill(null).map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    <div />
                    <Typography fontSize="14px" color="text.secondary">
                      Type
                    </Typography>
                    <Typography fontSize="14px" color="text.secondary">
                      Max carry
                    </Typography>
                    <Box sx={styles.gridBorderRight} />
                  </Fragment>
                ))}
                {activePackageSpecs.map((packageSpec) => (
                  <Fragment key={packageSpec.id}>
                    <Checkbox
                      checked={formData.packageSpecs.some(
                        (spec) => spec.packageSpecId === packageSpec.id,
                      )}
                      sx={{
                        height: '18px',
                        p: 0,
                        mr: '7px',
                      }}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            packageSpecs: [
                              ...prevFormData.packageSpecs,
                              {
                                packageSpecId: packageSpec.id,
                              },
                            ],
                          }));
                        } else {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            packageSpecs: prevFormData.packageSpecs.filter(
                              (spec) => spec.packageSpecId !== packageSpec.id,
                            ),
                          }));
                        }
                        triggerHasUnsavedChanges();
                      }}
                    />
                    <Typography fontSize="14px">{packageSpec.name}</Typography>
                    <TextField
                      size="small"
                      value={
                        formData.packageSpecs.find(
                          (spec) => spec.packageSpecId === packageSpec.id,
                        )?.maxQuantity ?? ''
                      }
                      onChange={({ target: { value } }) => {
                        const maxQuantity =
                          value.trim() === '' ? null : Number(value.trim());
                        setFormData((prevFormData) => {
                          const currentVehicleSpec =
                            prevFormData.packageSpecs.find(
                              (spec) => spec.packageSpecId === packageSpec.id,
                            );
                          // Keep the existing element for this package spec if one exists.
                          const newVehicleSpec: VehicleTypePackageSpecUpsertInput =
                            isNil(currentVehicleSpec)
                              ? {
                                  packageSpecId: packageSpec.id,
                                  maxQuantity,
                                }
                              : {
                                  ...currentVehicleSpec,
                                  maxQuantity,
                                };
                          return {
                            ...prevFormData,
                            // Note we can't do a simple map because there may not be an entry for this package spec.
                            packageSpecs: [
                              ...prevFormData.packageSpecs.filter(
                                (spec) => spec.packageSpecId !== packageSpec.id,
                              ),
                              newVehicleSpec,
                            ],
                          };
                        });
                        triggerHasUnsavedChanges();
                      }}
                    />
                    <Box sx={styles.gridBorderRight} />
                  </Fragment>
                ))}
              </Box>
            ))
          )}
        </Box>
      )}
      {!isNil(onToggleArchive) && (
        <Box sx={styles.container} gap={2}>
          <Box>
            <Typography sx={styles.sectionHeading}>
              Archive this vehicle type
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {status === VehicleTypeStatus.Archived
                ? 'This vehicle type is archived and is not available for new orders.'
                : 'Archiving this vehicle type will make it unavailable for new orders.'}
            </Typography>
          </Box>
          {/* This wrapping box is here to keep the button from being stretched to full width. */}
          <Box>
            <Button
              variant="outlined"
              color={status === VehicleTypeStatus.Active ? 'error' : undefined}
              disabled={togglingArchive}
              onClick={onToggleArchive}
            >
              {status === VehicleTypeStatus.Archived ? 'Un-archive' : 'Archive'}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

type EditVehicleTypeProps = {
  readonly pageMode: VehicleTypePageEditMode | VehicleTypePageViewMode;
  readonly onSave: (vehicleType: VehicleTypeCreateInput) => Promise<void>;
  readonly saving: boolean;
};

const EditVehicleType: FunctionComponent<EditVehicleTypeProps> = ({
  pageMode,
  onSave,
  saving,
}) => {
  const { uuid } = pageMode;
  const { data, loading } = useVehicleTypeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid,
    },
  });
  const [archiveVehicleType, { loading: archiving }] =
    useArchiveVehicleTypeMutation({
      fetchPolicy: 'network-only',
    });
  const [restoreVehicleType, { loading: restoring }] =
    useRestoreVehicleTypeMutation({
      fetchPolicy: 'network-only',
    });

  const onArchive = async () =>
    archiveVehicleType({
      variables: {
        uuid,
      },
    });

  const onRestore = async () =>
    restoreVehicleType({
      variables: {
        uuid,
      },
    });

  const onToggleArchive = () => {
    if (data) {
      if (data.vehicleType.status === VehicleTypeStatus.Archived) {
        onRestore();
      } else {
        onArchive();
      }
    }
  };

  if (loading && isNil(data)) {
    return <CircularProgress size={15} />;
  }

  return (
    <VehicleTypeBody
      pageMode={pageMode}
      initialData={data?.vehicleType}
      saving={saving}
      togglingArchive={archiving || restoring}
      onSave={onSave}
      onToggleArchive={onToggleArchive}
    />
  );
};

type VehicleTypeProps = {
  readonly pageMode: VehicleTypePageMode;
};

const VehicleType: FunctionComponent<VehicleTypeProps> = ({ pageMode }) => {
  const navigate = useNavigate();
  const [updateVehicleType, { loading: updating }] =
    useUpdateVehicleTypeMutation({
      fetchPolicy: 'network-only',
    });
  const [createVehicleType, { loading: creating }] =
    useCreateVehicleTypeMutation({
      fetchPolicy: 'network-only',
    });

  const onSave: VehicleTypeBodyProps['onSave'] = async (data) => {
    await (pageMode.mode === 'create'
      ? createVehicleType({
          variables: {
            vehicleTypeCreateInput: data,
          },
          onCompleted: (resultData) => {
            navigate(
              `/management/vehicle-types/${resultData.createVehicleType.uuid}`,
            );
          },
        })
      : updateVehicleType({
          variables: {
            vehicleTypeUpdateInput: {
              uuid: pageMode.uuid,
              ...data,
            },
          },
        }));
  };

  if (pageMode.mode === 'create') {
    return (
      <VehicleTypeBody pageMode={pageMode} saving={creating} onSave={onSave} />
    );
  }

  return (
    <EditVehicleType
      key={pageMode.uuid}
      pageMode={pageMode}
      saving={updating}
      onSave={onSave}
    />
  );
};

export default VehicleType;
