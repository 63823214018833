import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import { type Option } from '../../../../../common/filters/types';
import { useDownload } from '../../../../../common/react-hooks/use-download';
import { useMeasureExecutionTime } from '../../../../../common/react-hooks/use-measure-execution-time';
import { useUnappliedPaymentsReportLazyQuery } from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';

type DownloadUnappliedPaymentsReportModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadUnappliedPaymentsReportModal = ({
  open,
  setOpen,
}: DownloadUnappliedPaymentsReportModalProps) => {
  const [contactOption, setContactOption] = useState<Option | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [getReport] = useUnappliedPaymentsReportLazyQuery();
  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );
  const { downloadFile } = useDownload();

  const reportInputs = useMemo(
    () => ({
      reportFilters: {
        billingPartyContactUuids: isNil(contactOption?.value)
          ? undefined
          : [contactOption.value],
      },
    }),
    [contactOption],
  );

  const startDownload = useCallback(async () => {
    const completeDownload = createFileDownload();
    setOpen(false);
    const res = await getReport({
      variables: {
        input: reportInputs,
      },
    });
    const report = res.data?.unappliedPaymentReportCsv;

    if (
      isNil(report) ||
      !isEmpty(report.errors) ||
      isNil(report.url) ||
      isNil(report.fileName)
    ) {
      completeDownload({
        alertSeverity: 'error',
        message:
          res.data?.unappliedPaymentReportCsv.errors?.join(', ') ??
          'Error downloading report',
      });

      return;
    }

    const { ok, error } = await downloadFile(report.fileName, report.url);
    if (!ok) {
      // eslint-disable-next-line no-console
      console.error('Error downloading unapplied payments report', error);
      completeDownload({
        alertSeverity: 'error',
        message: error?.message ?? 'Error downloading report',
      });

      return;
    }

    completeDownload();
    setContactOption(undefined);
    setIsFetching(false);
  }, [createFileDownload, downloadFile, getReport, setOpen, reportInputs]);

  const startDownloadWithMeasurement = useMeasureExecutionTime({
    fn: startDownload,
    rumLabel: 'download-unapplied-payments-report',
    logData: reportInputs,
  });

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={[
          styles.modal,
          {
            height: 'fit-content',
            width: '400px',
          },
        ]}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Unapplied Payments Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%', mb: 2 }}>
            <Stack direction="column" spacing={1}>
              <CustomerFilterButton
                selectedOption={contactOption}
                handleChange={(option: Option | undefined) => {
                  setContactOption(option);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <Button
                sx={{ width: 100 }}
                variant="contained"
                color="info"
                disabled={isFetching}
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadUnappliedPaymentsReportModal;
