import { Button, Fade, Stack, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { formatUserRoles } from '../../../../common/utils/roles';
import {
  type UserFragment,
  UsersDocument,
  useUpdateUserMutation,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import { ArchiveableEntity } from '../common/archive-action-component';
import ArchiveEntityModal from '../common/archive-entity-modal';
import UsersModal from './users-modal';
import { getUserIdentifier } from 'shared/roles';

const UserRow = ({
  user,
  companyUuid,
  terminalsEnabled,
  canWriteCompanyUsers,
}: {
  readonly user: UserFragment;
  readonly companyUuid: string | undefined;
  readonly terminalsEnabled: boolean;
  readonly canWriteCompanyUsers: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showArchiveUserModal, setShowArchiveUserModal] = useState(false);

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [UsersDocument],
  });

  const handleArchiveOrUnarchive = async ({
    shouldArchive,
  }: {
    shouldArchive: boolean;
  }) => {
    await updateUser({
      variables: {
        updateUserInput: {
          uuid: user.uuid,
          isActive: !shouldArchive,
        },
      },
    });
  };

  return (
    <>
      <TableRow
        key={user.uuid}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.name}</TableCell>
        {terminalsEnabled && (
          <TableCell>{user?.terminal?.name ?? '-'}</TableCell>
        )}
        <TableCell>{formatUserRoles(user?.roles ?? [])}</TableCell>
        <TableCell align="right">
          <Stack direction="row" gap={1}>
            <Fade in={isHovering}>
              <Button
                variant="contained"
                disabled={!canWriteCompanyUsers}
                onClick={() => {
                  setShowEditUserModal(true);
                }}
              >
                Edit
              </Button>
            </Fade>
            <Fade in={isHovering}>
              <Button
                variant={user.isActive ? 'contained' : 'outlined'}
                disabled={!canWriteCompanyUsers}
                color={user.isActive ? 'error' : 'warning'}
                onClick={() => {
                  setShowArchiveUserModal(true);
                }}
              >
                {user.isActive ? 'Archive' : 'Unarchive'}
              </Button>
            </Fade>
          </Stack>
        </TableCell>
      </TableRow>
      <UsersModal
        open={showEditUserModal}
        setOpen={setShowEditUserModal}
        createOrEdit={CreateOrEdit.Edit}
        companyUuid={companyUuid}
        selectedUser={user}
      />
      <ArchiveEntityModal
        entityType={ArchiveableEntity.USER}
        entityIdentifier={getUserIdentifier(user)}
        open={showArchiveUserModal}
        setOpen={setShowArchiveUserModal}
        isCurrentlyArchived={!user.isActive}
        handleArchive={() => {
          handleArchiveOrUnarchive({ shouldArchive: true });
        }}
        handleUnarchive={() => {
          handleArchiveOrUnarchive({ shouldArchive: false });
        }}
      />
    </>
  );
};

export default UserRow;
