import dayjs, { type Dayjs } from 'dayjs';
import { z } from 'zod';

/**
 * Custom schema that validates that a string is not empty.
 * z.string() will accept an empty string by default, but we often want to
 * enforce that a string is not empty.
 *
 * This mostly exists so we don't have to repeat the error message twice.
 */
export const zNonEmptyString = (params: { required_error: string }) =>
  z.string(params).trim().min(1, params.required_error);

/**
 * Custom schema that accepts a `Dayjs` instance.
 * @link https://github.com/colinhacks/zod/discussions/1259#discussioncomment-7866342
 */
export const dayjsDate = (error?: string) =>
  z.custom<Dayjs>(
    (val) => dayjs.isDayjs(val) && val.isValid(),
    error ?? 'Invalid date',
  );

/**
 * Custom schema that validates numbers between 0-100 for percentage values.
 */
export const percentageSchema = z
  .number()
  .refine((value) => value >= 0 && value <= 100, {
    message: 'Percentage must be between 0 and 100',
  });

// adding this back temporarily to unbreak my stack. It's removed in a later PR
// eslint-disable-next-line @typescript-eslint/naming-convention
export const zOMITTED = z.preprocess(() => undefined, z.literal(undefined));

/**
 * Utility type that accepts a `Date` or an ISO datetime string.
 *
 * The DateTime GraphQL scalar that we use serializes `Date`s as strings, but TypeScript
 * types them as `any`, so there are places on the frontend where we accidentally use
 * them as `Date`s when they're actually strings.
 *
 * We can't just use zDateOrDatetimeString because that will accept null values
 * too, which coerce to 1970-01-01.
 */
export const zDateOrDatetimeString = (params?: { required_error: string }) =>
  z.union(
    [
      z.date(),
      dayjsDate(params?.required_error).transform((val) => val.toDate()),
      z
        .string()
        .datetime()
        .transform((val) => new Date(val)),
    ],
    params,
  );
