import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { isEmpty, values } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { useCsvOrderMappingsQuery } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import theme from '../../../theme';

const PRIMARY_COLOR = theme.palette.primary.main;
const PRIMARY_COLOR_LIGHT = theme.palette.primary.light;

const AddNewOrderOptionsButtonGroup = styled(ButtonGroup)<{
  variant?: 'text' | 'outlined' | 'contained';
}>(({ variant }) => ({
  borderRadius: '6px',
  background: variant === 'contained' ? PRIMARY_COLOR : 'inherit',
  ':hover': {
    background: variant === 'contained' ? PRIMARY_COLOR : 'inherit',
  },
}));

const AddNewOrderOptionButton = styled(Button)<{
  variant?: 'text' | 'outlined' | 'contained';
}>(({ variant }) => ({
  background: variant === 'contained' ? PRIMARY_COLOR : PRIMARY_COLOR_LIGHT,
  textDecoration: variant === 'contained' ? '#FFFFFF' : PRIMARY_COLOR,
  ':hover': {
    background: variant === 'contained' ? PRIMARY_COLOR : PRIMARY_COLOR_LIGHT,
    textDecoration: variant === 'contained' ? '#FFFFFF' : PRIMARY_COLOR,
  },
  whiteSpace: 'nowrap',
}));

enum AddNewOrderOptions {
  ManualEntry = 'Order entry form',
  DocumentScanning = 'Create from PDFs',
  CsvImport = 'Create from CSVs',
}

const options = values(AddNewOrderOptions);

type AddNewOrderOptionsButtonProps = {
  readonly disabled: boolean;
  readonly variant?: 'text' | 'outlined' | 'contained';
};

export default function AddNewOrderOptionsButton({
  disabled,
  variant,
}: AddNewOrderOptionsButtonProps) {
  const [
    setShowBilingPartyDialog,
    setShowUploadPdfsDialog,
    setShowUploadCsvsDialog,
  ] = useGlobalStore(
    (state) => [
      state.setShowBilingPartyDialog,
      state.setShowUploadPdfsDialog,
      state.setShowUploadCsvsDialog,
    ],
    shallow,
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { data: csvOrderMappingsData } = useCsvOrderMappingsQuery();

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement>,
    index: number,
  ) => {
    event.preventDefault();
    setSelectedIndex(index);
    setOpen(false);

    if (index === 0) {
      setShowBilingPartyDialog(true);
    } else if (index === 1) {
      setShowUploadPdfsDialog(true);
    } else {
      setShowUploadCsvsDialog(true);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <AddNewOrderOptionsButtonGroup
        ref={anchorRef}
        size="small"
        variant={variant ?? 'outlined'}
        aria-label="split button"
        sx={{ backgroundColor: 'white' }}
      >
        <AddNewOrderOptionButton
          size="small"
          disabled={disabled}
          startIcon={<PostAddIcon fontSize="small" />}
          variant={variant ?? 'outlined'}
          sx={{ width: '100%' }}
          onClick={() => {
            setShowBilingPartyDialog(true);
          }}
        >
          <div style={{ marginTop: 2 }}>Add order</div>
        </AddNewOrderOptionButton>
        <AddNewOrderOptionButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          disabled={disabled}
          variant={variant ?? 'outlined'}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon fontSize="small" />
        </AddNewOrderOptionButton>
      </AddNewOrderOptionsButtonGroup>
      <Popper
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={
                        option === 'Create from CSVs'
                          ? isEmpty(csvOrderMappingsData?.csvOrderMappings)
                          : false
                      }
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        handleMenuItemClick(event, index);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
