import ModeIcon from '@mui/icons-material/Mode';
import {
  Box,
  Button,
  Chip,
  Fade,
  FormControl,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { calculateWaitTimeInMinutes } from 'shared/stops';
import {
  getCompletedStopChipTestId,
  getStopMarkAsTestIds,
} from '../../../../../../../../utils';
import DateField from '../../../../../../../common/components/date-field';
import useMe from '../../../../../../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';
import {
  StopStatus,
  StopType,
  useLegQuery,
} from '../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { type StopMethod } from '../../../forms/stop-type';
import { type OrderFormFieldValues } from '../../../forms/types';
import { type OnSubmitParams } from '../../../types';
import { stopTypeLabel } from '../../../utils';
import DriverSection from './driver-section';
import StopMarkAsMenu from './stop-mark-as-menu';
import { formatStopAttemptTime } from './utils';

const Stop = ({
  idx,
  onSubmit,
  stopMethod,
  isEditMode,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: {
  readonly idx: number;
  readonly stopMethod: StopMethod;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly isEditMode: boolean;
  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
}) => {
  const { companyTimezone } = useMe();
  const { setValue, control } = useFormContext<OrderFormFieldValues>();
  const theme = useTheme();
  const [showMarkAsMenu, setShowMarkAsMenu] = useState(false);
  const markAsButtonRef = useRef<HTMLButtonElement>(null);
  const legUuid: string | undefined = useWatch({
    control,
    name: `stops.${idx}.legUuid`,
  });
  const { data: legData } = useLegQuery(
    isNil(legUuid)
      ? {
          skip: true,
        }
      : { variables: { uuid: legUuid } },
  );
  const completedAt = useWatch({
    control,
    name: `stops.${idx}.completedAt`,
  });
  const status = useWatch({
    control,
    name: `stops.${idx}.status`,
  });
  const stopType = useWatch({
    control,
    name: `stops.${idx}.stopType`,
  });
  const arrivedAt = useWatch({
    control,
    name: `stops.${idx}.arrivedAt`,
  });
  const equipmentNames = useWatch({
    control,
    name: `stops.${idx}.equipmentNames`,
  });
  const proofOfDeliverySignee = useWatch({
    control,
    name: `stops.${idx}.proofOfDeliverySignee`,
  });
  const driverName = useWatch({ control, name: `stops.${idx}.driverName` });

  const { hours: waitTimeHours, minutes: waitTimeMinutes } =
    calculateWaitTimeInMinutes({
      arrivalDate: arrivedAt,
      completedDate: completedAt,
    });

  const [isEditingPoDSignee, setIsEditingPoDSignee] = useState(false);
  const [isHoveringPoDSignee, setIsHoveringPoDSignee] = useState(false);

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  if (stopType === StopType.None) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const { stopMarkAsMenuButtonTestId } = getStopMarkAsTestIds({ stopIdx: idx });
  const completedStopChipTestId = getCompletedStopChipTestId({ stopType });

  return (
    <Stack direction="column" justifyContent="center">
      <StopMarkAsMenu
        buttonRef={markAsButtonRef.current}
        setShowContextMenu={setShowMarkAsMenu}
        showContextMenu={showMarkAsMenu}
        idx={idx}
        legData={legData}
        setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
        setCannotCompleteOrderModalMessage={setCannotCompleteOrderModalMessage}
        onSubmit={onSubmit}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontSize="16px">
          {sentenceCase(stopMethod)}{' '}
          {isNil(stopType) ? '' : stopTypeLabel(stopType).toLowerCase()} summary
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
          <Chip
            label={sentenceCase(status ?? '')}
            data-testid={completedStopChipTestId}
          />
          {isEditMode && (
            <Button
              ref={markAsButtonRef}
              size="small"
              disabled={disabledIfFinalizedOrLater}
              data-testid={stopMarkAsMenuButtonTestId}
              onClick={() => {
                setShowMarkAsMenu(!showMarkAsMenu);
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Stack>
      <Table sx={{ backgroundColor: theme.palette.grey[100], mt: '13px' }}>
        {!isNil(completedAt) && (
          <TableRow sx={{ border: 'none' }}>
            <TableCell sx={{ border: 'none' }}>
              <DateField
                date={completedAt}
                setDate={async (date) => {
                  setValue(`stops.${idx}.completedAt`, date);
                }}
                editable={!disabledIfFinalizedOrLater}
              />
            </TableCell>
            <TableCell sx={{ border: 'none' }}>
              <Chip label="Completed" />
            </TableCell>
            <TableCell sx={{ border: 'none' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2">
                  {!isNil(equipmentNames) && !isEmpty(equipmentNames) && (
                    <>Equipment: Used {equipmentNames}</>
                  )}
                </Typography>
                {!isEditingPoDSignee && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                    onMouseEnter={() => {
                      setIsHoveringPoDSignee(true);
                    }}
                    onMouseLeave={() => {
                      setIsHoveringPoDSignee(false);
                    }}
                  >
                    <Typography variant="body2">
                      {isNil(proofOfDeliverySignee)
                        ? 'No signee'
                        : `Signed by ${proofOfDeliverySignee}`}
                    </Typography>
                    {!disabledIfFinalizedOrLater && (
                      <Fade in={isHoveringPoDSignee}>
                        <IconButton
                          disabled={disabledIfFinalizedOrLater}
                          sx={{ p: 0 }}
                          onClick={() => {
                            setIsEditingPoDSignee(true);
                          }}
                        >
                          <ModeIcon sx={{ fontSize: '16px', p: 0 }} />
                        </IconButton>
                      </Fade>
                    )}
                  </Box>
                )}
                {isEditingPoDSignee && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Controller
                      name={`stops.${idx}.proofOfDeliverySignee`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            value={value}
                            label="Signee"
                            disabled={disabledIfFinalizedOrLater}
                            onChange={onChange}
                            onBlur={() => {
                              setIsEditingPoDSignee(false);
                            }}
                          />
                        </FormControl>
                      )}
                    />
                  </Box>
                )}
              </Box>
              {!isNilOrEmptyString(driverName) && (
                <Typography color={theme.palette.grey[500]} variant="body2">
                  Driver: {driverName}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        )}
        {(!isNil(arrivedAt) || !isNil(completedAt)) && (
          <TableRow>
            <TableCell>
              <DateField
                date={arrivedAt ?? null}
                setDate={async (date) => {
                  setValue(`stops.${idx}.arrivedAt`, date);
                }}
                editable={!disabledIfFinalizedOrLater}
              />
            </TableCell>
            <TableCell>
              <Chip label="Arrived" />
            </TableCell>
            {status === StopStatus.Completed && (
              <TableCell>
                {!isNil(waitTimeHours) && !isNil(waitTimeMinutes)
                  ? `${
                      waitTimeHours > 0 ? `${waitTimeHours}h, ` : ''
                    }${waitTimeMinutes}m`
                  : '-'}{' '}
                wait
              </TableCell>
            )}
          </TableRow>
        )}
        {(legData?.leg?.previousStopAttempts ?? []).map(
          (previousStopAttempt) => {
            const attemptDriver =
              previousStopAttempt?.routeSlot?.route?.drivers?.at(0);
            let attemptDriverName = '';
            if (!isNil(attemptDriver)) {
              attemptDriverName = `${attemptDriver?.firstName ?? ''} ${
                attemptDriver?.lastName ?? ''
              }`;
            }
            return (
              <TableRow key={previousStopAttempt.uuid}>
                <TableCell>
                  {formatStopAttemptTime({
                    completedAt: previousStopAttempt.completedAt,
                    companyTimezone,
                  })}
                </TableCell>
                <TableCell>
                  <Chip label="Attempted" />
                </TableCell>
                <TableCell>
                  {isNil(previousStopAttempt.notes)
                    ? ''
                    : `Notes: ${previousStopAttempt.notes}`}
                  {!isNilOrEmptyString(attemptDriverName) && (
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: theme.palette.grey[500],
                      }}
                    >
                      Driver: {attemptDriverName}
                    </p>
                  )}
                </TableCell>
              </TableRow>
            );
          },
        )}
      </Table>
      <DriverSection idx={idx} />
    </Stack>
  );
};

export default React.memo(Stop);
