import styled, { type CSSObject } from '@emotion/styled';
import {
  type DateRange,
  DesktopDateRangePicker,
  type DesktopDateRangePickerProps,
  LocalizationProvider,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { type Dayjs } from 'dayjs';
import { isDevelopment } from '../../environment-variables';

type StyledDesktopDateRangePickerProps = {
  readonly inputBaseStyles?: CSSObject;
} & DesktopDateRangePickerProps<Dayjs>;

const StyledDesktopDateRangePicker = styled(
  DesktopDateRangePicker<Dayjs>,
)<StyledDesktopDateRangePickerProps>((props) => ({
  ...(props.inputBaseStyles && {
    '& .MuiInputBase-root': props.inputBaseStyles,
  }),
}));

type PalletDateRangePickerProps = {
  readonly value: DateRange<Dayjs>;
  readonly onAccept: DesktopDateRangePickerProps<Dayjs>['onAccept'];
  readonly placeholder?: string;
  readonly hasError?: boolean;
  readonly isRequired?: boolean;
  readonly isClearable?: boolean;
} & StyledDesktopDateRangePickerProps;

/**
 * A date range picker component that is styled to match the pallet design system.
 * @param value - The date range currently selected. The value is represented in the form [startDate, endDate].
 * @param onAccept - The callback function that is called when user selects a complete range in the
 * date range picker and closes the picker (note that this is different from onChange, which is called whenever
 * the start or end of the date range picker value changes).
 */
const PalletDateRangePicker = ({
  value,
  onAccept,
  placeholder = 'Select values...',
  inputBaseStyles,
  hasError,
  isRequired,
  isClearable,
  ...restProps
}: PalletDateRangePickerProps) => {
  const [start, end] = value;
  if (
    isDevelopment() &&
    // Check valid first because offsetName() will throw if invalid
    start?.isValid() === true &&
    end?.isValid() === true &&
    start.offsetName() !== end.offsetName()
  ) {
    throw new Error('Date range start and end have different timezones');
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDesktopDateRangePicker
        value={value}
        inputBaseStyles={inputBaseStyles}
        slots={{ field: SingleInputDateRangeField }}
        slotProps={{
          textField: {
            size: 'small',
            placeholder,
            error: hasError,
            required: isRequired,
          },
          actionBar: {
            actions: isClearable === true ? ['clear'] : [],
          },
        }}
        // This makes the date range picker use the system timezone
        // The alternative is to enforce that all timezones passed to this
        // component are in the same timezone.
        // See https://github.com/mui/mui-x/issues/13290 / FTO-1345
        timezone="default"
        onAccept={onAccept}
        {...restProps}
      />
    </LocalizationProvider>
  );
};

export default PalletDateRangePicker;
