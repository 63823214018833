import { FormControl, FormLabel, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { v4 } from 'uuid';
import AddressAutofill from '../../../../common/components/address-autofill';
import { type AddressFormField } from '../../../addresses/redux/addresses-values-slice';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { type StopMethod } from '../../../orders/components/order-form/forms/stop-type';
import { type CustomerPortalOrderFormValues } from './forms/types';
import { getCustomerPortalOrderFormStopField } from './forms/utils';

type CustomerPortalAddressFieldProps = {
  readonly stopMethod: StopMethod;
};

const CustomerPortalAddressField = ({
  stopMethod,
}: CustomerPortalAddressFieldProps) => {
  const { control, setValue, getValues } =
    useFormContext<CustomerPortalOrderFormValues>();
  const { errors } = useFormState<CustomerPortalOrderFormValues>();

  const stopFieldPath = getCustomerPortalOrderFormStopField(stopMethod);
  const { disabledIfNoAccess: disabled } = useOrderFormEditAccess();

  const name = useWatch({
    control,
    name: `${stopFieldPath}.name`,
  });
  const line1 = useWatch({
    control,
    name: `${stopFieldPath}.addressLine1`,
  });
  const line2 = useWatch({
    control,
    name: `${stopFieldPath}.addressLine2`,
  });
  const city = useWatch({
    control,
    name: `${stopFieldPath}.city`,
  });
  const state = useWatch({
    control,
    name: `${stopFieldPath}.state`,
  });
  const zipcode = useWatch({
    control,
    name: `${stopFieldPath}.zipcode`,
  });
  const country = useWatch({
    control,
    name: `${stopFieldPath}.country`,
  });

  const addressError = (
    errors[stopFieldPath]?.name ??
    errors[stopFieldPath]?.addressLine1 ??
    errors[stopFieldPath]?.addressLine2 ??
    errors[stopFieldPath]?.city ??
    errors[stopFieldPath]?.state ??
    errors[stopFieldPath]?.zipcode ??
    errors[stopFieldPath]?.country
  )?.message;

  const handleAddressChange = ({ address }: { address: AddressFormField }) => {
    setValue(stopFieldPath, {
      ...getValues(stopFieldPath),
      name: address.name ?? '',
      addressLine1: address.line1 ?? '',
      addressLine2: address.line2 ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      zipcode: address.zip ?? '',
      country: address.country ?? '',
    });
  };

  const currentAddressOption = {
    label: name,
    value: name,
    address: {
      name,
      line1,
      line2,
      city,
      state,
      zipcode,
      country,
      // These are required for the address autocomplete to work
      // but not used for the customer portal :/
      isLocal: true,
      uuid: v4(),
    },
  };

  return (
    <Stack gap={1}>
      <FormLabel>Address</FormLabel>
      <Stack gap={2}>
        <Controller
          name={`${stopFieldPath}.name`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                required
                label="Name"
                size="small"
                value={value ?? ''}
                error={!isNil(error)}
                helperText={error?.message}
                disabled={disabled}
                onChange={onChange}
              />
            </FormControl>
          )}
          disabled={disabled}
        />
        <AddressAutofill
          required
          freeSolo
          newStyling
          handleChange={handleAddressChange}
          error={addressError}
          currentOption={currentAddressOption}
          nameInputValue={name}
          hideLine2={false}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

export default CustomerPortalAddressField;
