enum AppointmentFilterField {
  APPOINTMENT_CONTACT = 'Contact name',
  SERVICE_LEVEL = 'Service level',
  TEXT_STATUS = 'Text status',
  CALL_STATUS = 'Call status',
  CONSIGNEE = 'Consignee',
  ADDRESS_TYPE = 'Address type',
  APPOINTMENT_REQUIRED = 'Appointment required',
  PHONE = 'Phone',
  CUSTOMER = 'Customer',
}

export default AppointmentFilterField;
