import ChatIcon from '@mui/icons-material/Chat';
import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import { createContextAdapter } from '@nlux/nlbridge';
import { useChatAdapter } from '@nlux/nlbridge-react';
import {
  AiChat,
  createAiContext,
  type PersonaOptions,
  type StreamingAdapterObserver,
  type StreamSend,
  useAiContext,
} from '@nlux/react';
import { isNil } from 'lodash';
import {
  Fragment,
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import { useCustomerPortalOrdersQuery } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import DriversTable from '../../management/components/drivers/drivers-table';
import {
  getFetchCustomerPortalOrdersQueryVariables,
  isThirdPartyAgent,
} from '../utils';
import { CustomerInvoices } from './customer-invoices';
import CustomerLoadsView from './customer-loads-view';
import { DemoCustomerPortalPlaceOrdersPage } from './demo-customer-portal-place-orders';

const assistantAvatar =
  'https://docs.nlkit.com/nlux/images/personas/hawking.png';
const userAvatar = 'https://docs.nlkit.com/nlux/images/personas/marissa.png';

export const personas: PersonaOptions = {
  assistant: {
    name: 'Pallet Assistant',
    avatar: assistantAvatar,
    tagline: 'Ask me anything about your orders or invoices',
  },
  user: {
    name: '',
    avatar: userAvatar,
  },
};

const contextAdapter = isNil(
  import.meta.env.VITE_CUSTOMER_PORTAL_CHATBOT_SERVER_URL,
)
  ? null
  : createContextAdapter()
      .withUrl(import.meta.env.VITE_CUSTOMER_PORTAL_CHATBOT_SERVER_URL)
      .build();

const initialItems = {
  applicationName: 'Pallet Assistant',
  applicationPurpose:
    'Be a helpful customer support agent and find answers on things like the status of shipping orders. The conversation is taking part while user is using a web or mobile application. Do not ask the user to provide their email address. Assume they are signed in and can see everything. Only answer with data that you have been specifically provided. Do not provide a delivery date unless there is one set on an order. When it would help with readability feel free to format your output with Markdown. Do not output order IDs (the "id" property) as that internal detail the user does not need to know.',
};

export const MyAiContext = isNil(contextAdapter)
  ? null
  : createAiContext(contextAdapter);

const Chat = ({
  contactUuid,
}: {
  readonly contactUuid: string | undefined;
}) => {
  const [chatOpen, setChatOpen] = useState(false);
  const adapter = useChatAdapter({
    url: import.meta.env.VITE_CUSTOMER_PORTAL_CHATBOT_SERVER_URL!,
    mode: 'copilot',
    context: MyAiContext!,
  });

  const { data: ordersData } = useCustomerPortalOrdersQuery({
    variables: {
      ...getFetchCustomerPortalOrdersQueryVariables({
        contactFilter: contactUuid,
        filterModel: {},
      }),
      first: 500,
    },
  });

  type OrderForAi = {
    id: string;
    hawb: string | null;
    name: string;
    status: string;
    onHold: boolean;
    receivedDate: string | null;
  };

  useAiContext(
    MyAiContext!,
    'Here is a list of all of the orders that the user may ask about. It is a JSON array of objects, each having a HAWB (the "hawb" property) which is usually the way orders are identified. Orders may also be identified by "name". The user is seeing these orders on the screen and may ask questions about them. Reply in plain English, not JSON.',
    ordersData?.customerPortalOrders.edges.map(
      ({ node }): OrderForAi => ({
        id: node.uuid,
        hawb: node.standardOrderFields.shipperBillOfLadingNumber ?? null,
        name: node.name,
        status: node.detailedStatusV2 ?? 'Unknown',
        onHold: node.onHold,
        receivedDate: node.receivedDate ?? null,
      }),
    ) ?? [],
  );

  if (chatOpen) {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          zIndex: 1001,
          borderRadius: '16px',
          backgroundColor: '#fff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.18)',
          width: '350px',
          maxHeight: '700px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <AiChat
          adapter={adapter}
          personaOptions={personas}
          displayOptions={{
            colorScheme: 'light',
            width: '350px',
          }}
          composerOptions={{
            autoFocus: true,
          }}
        />
      </div>
    );
  }
  return (
    <button
      type="button"
      style={{
        position: 'absolute',
        bottom: '30px',
        right: '30px',
        zIndex: 1001,
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        border: 'none',
        backgroundColor: '#21242C',
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
      }}
      onClick={() => {
        setChatOpen(true);
      }}
    >
      <ChatIcon
        sx={{
          color: '#fff',
          fontSize: '25px',
          marginTop: '3px',
          marginLeft: '0.5px',
        }}
      />
    </button>
  );
};

// A demo API by NLUX that connects to OpenAI
// and returns a stream of Server-Sent events
const demoProxyServerUrl = 'https://gptalks.api.nlux.dev/openai/chat/stream';

// Function to send query to the server and receive a stream of chunks as response
export const send: StreamSend = async (
  prompt: string,
  observer: StreamingAdapterObserver,
) => {
  const body = { prompt };
  const response = await fetch(demoProxyServerUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    observer.error(new Error('Failed to connect to the server'));
    return;
  }

  if (!response.body) {
    return;
  }

  // Read a stream of server-sent events
  // and feed them to the observer as they are being generated
  const reader = response.body.getReader();
  const textDecoder = new TextDecoder();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }

    const content = textDecoder.decode(value);
    if (content !== '') {
      observer.next(content);
    }
  }

  observer.complete();
};

enum TabIndex {
  TrackOrders = 'track-orders',
  Order = 'order',
  DriverSettlement = 'driver-settlement',
  AddressBook = 'address-book',
  UserProfile = 'user-profile',
  Reports = 'reports',

  Invoices = 'invoices',
}

type TabContent = {
  key: TabIndex;
  label: string;
  component: ReactNode;
};

const parseTabQueryParam = (tab: string | null): TabIndex => {
  switch (tab) {
    case TabIndex.TrackOrders: {
      return TabIndex.TrackOrders;
    }
    case TabIndex.Order: {
      return TabIndex.Order;
    }
    case TabIndex.DriverSettlement: {
      return TabIndex.DriverSettlement;
    }
    case TabIndex.AddressBook: {
      return TabIndex.AddressBook;
    }
    case TabIndex.UserProfile: {
      return TabIndex.UserProfile;
    }
    case TabIndex.Reports: {
      return TabIndex.Reports;
    }
    case TabIndex.Invoices: {
      return TabIndex.Invoices;
    }
    default: {
      return TabIndex.TrackOrders;
    }
  }
};

/** Redirects to /customer-portal/orders if the demo feature flag is disabled */
const CustomerPortalDemoViewWithFeatureFlags = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const ffDemoCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_DEMO_CUSTOMER_PORTAL,
  );
  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: TabIndex) => {
      if (!isNil(thirdPartyUser)) {
        setSearchParams((sp) => {
          const newParams = new URLSearchParams(sp);
          newParams.set('tab', newValue);
          if (newValue === TabIndex.Order) {
            const [firstContact] = thirdPartyUser.contacts;
            if (isNil(firstContact)) {
              newParams.delete('contactUuid');
            } else {
              newParams.set('contactUuid', firstContact.uuid);
            }
          }
          return newParams;
        });
      }
    },
    [thirdPartyUser, setSearchParams],
  );
  const currentTabKey = parseTabQueryParam(searchParams.get('tab'));

  const isAgent = isThirdPartyAgent(thirdPartyUser);

  const tabs = useMemo<TabContent[]>(() => {
    if (isAgent) {
      return [
        {
          key: TabIndex.TrackOrders,
          label: 'Track Orders',
          component: <CustomerLoadsView />,
        },
        {
          key: TabIndex.DriverSettlement,
          label: 'Settlement',
          component: <DriversTable />,
        },
        {
          key: TabIndex.AddressBook,
          label: 'Address Book',
          component: null,
        },
        {
          key: TabIndex.UserProfile,
          label: 'User Profile',
          component: null,
        },
        {
          key: TabIndex.Reports,
          label: 'Reports',
          component: null,
        },
      ];
    }

    return [
      {
        key: TabIndex.TrackOrders,
        label: 'Track Orders',
        component: <CustomerLoadsView />,
      },
      {
        key: TabIndex.Order,
        label: 'Place Orders',
        component: <DemoCustomerPortalPlaceOrdersPage />,
      },
      {
        key: TabIndex.Invoices,
        label: 'Invoices',
        component: <CustomerInvoices />,
      },
      {
        key: TabIndex.AddressBook,
        label: 'Address Book',
        component: null,
      },
      {
        key: TabIndex.UserProfile,
        label: 'User Profile',
        component: null,
      },
      {
        key: TabIndex.Reports,
        label: 'Reports',
        component: null,
      },
    ];
  }, [isAgent]);

  if (!ffDemoCustomerPortal) {
    return <Navigate to="/customer-portal/orders" />;
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          height: '100dvh',
        }}
      >
        <Stack
          sx={{
            height: '100%',
            width: '210px',
            borderRight: 1,
            borderRightColor: theme.palette.borderColor.main,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentTabKey}
            scrollButtons="auto"
            sx={{ pt: 3 }}
            onChange={handleTabChange}
          >
            {tabs.map(({ key, label }) => (
              <Tab
                key={label}
                value={key}
                label={label}
                sx={{ alignItems: 'start' }}
              />
            ))}
          </Tabs>
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {tabs.map(({ key, component }) => (
            <Fragment key={key}>
              {currentTabKey === key && <div role="tabpanel">{component}</div>}
            </Fragment>
          ))}
        </Box>
      </Stack>
      {!isNil(MyAiContext) && (
        // @ts-expect-error -- OK
        <MyAiContext.Provider initialItems={initialItems}>
          <Chat contactUuid={thirdPartyUser?.contacts[0]?.uuid} />
        </MyAiContext.Provider>
      )}
    </>
  );
};

/** Redirects to /customer-portal/orders if the demo feature flag is disabled */
export const CustomerPortalDemoView = () => {
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );
  // We need to make sure we've loaded feature flags before we render the inner component so that we don't render the wrong
  // page for a moment.
  if (statsigLoading) {
    return (
      <Box p={2} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return <CustomerPortalDemoViewWithFeatureFlags />;
};
