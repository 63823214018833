import { exhaustive } from 'shared/switch';
import {
  InboundMethod,
  OutboundMethod,
  StopType,
} from '../../../../../generated/graphql';

/**
 * @description used in stop-related components/functions to track whether the stop is inbound or outbound
 */
export enum StopMethod {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum CustomerPortalStopType {
  BusinessOrResidence = 'Business or residence',
  WarehouseOrAirport = 'Warehouse or airport',
}

const inboundStopTypeOptions = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
  StopType.None,
];

const outboundStopTypeOptions = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export const stopTypeOptions = (method: StopMethod) => {
  return method === StopMethod.Inbound
    ? inboundStopTypeOptions
    : outboundStopTypeOptions;
};

const INBOUND_STOP_TYPES = new Set([
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
]);

const OUTBOUND_STOP_TYPES = new Set(
  Object.values(StopType).filter(
    (stopType) => !INBOUND_STOP_TYPES.has(stopType),
  ),
);

const PARTNER_CARRIER_STOP_TYPES = new Set([
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
]);

const REGULAR_STOP_TYPES = new Set(
  Object.values(StopType).filter(
    (stopType) => !PARTNER_CARRIER_STOP_TYPES.has(stopType),
  ),
);

export function isInboundStop(stopType: StopType) {
  return INBOUND_STOP_TYPES.has(stopType);
}

export function isOutboundStop(stopType: StopType) {
  return OUTBOUND_STOP_TYPES.has(stopType);
}

export function isPartnerCarrierStop(stopType: StopType) {
  return PARTNER_CARRIER_STOP_TYPES.has(stopType);
}

export function isRegularStop(stopType: StopType) {
  return REGULAR_STOP_TYPES.has(stopType);
}

/**
 * This is serious tech debt: we don't actually store Partner Carrier
 * Pickup/Dropoff or None StopTypes in the DB:
 * - We store Partner Carrier stop types as
 *   Delivery/Pickup StopTypes with an inboundMethod/outboundMethod
 *   that indicates the actual stop type (including partner carrier).
 * - We store None stop types as null.
 *
 * Before sending data to the backend we need to convert:
 * - PartnerCarrierDropoff to Pickup
 * - PartnerCarrierPickup to Delivery
 * - None to `null`
 *
 * Unfortunately we can't keep this logic in `shared` because the StopType
 * enum values are different between the generated GraphQL enum and Prisma enums.
 *
 * @see deriveLogicalStopType for the inverse of this operation
 */
export const coalesceStopTypesForBackend = (
  stopType: StopType,
): StopType | null => {
  switch (stopType) {
    case StopType.Pickup:
    case StopType.PartnerCarrierDropoff: {
      return StopType.Pickup;
    }
    case StopType.Delivery:
    case StopType.PartnerCarrierPickup: {
      return StopType.Delivery;
    }
    case StopType.Recovery: {
      return StopType.Recovery;
    }
    case StopType.Transfer: {
      return StopType.Transfer;
    }
    case StopType.None: {
      return null;
    }
    default: {
      return exhaustive(stopType);
    }
  }
};

/**
 * Get the "logical" stop type based on the inbound and outbound methods
 *
 * @see coalesceStopTypesForBackend for why we need this
 */
export const deriveLogicalStopType = (
  stopType: StopType | null | undefined,
  inboundMethod: InboundMethod | null | undefined,
  outboundMethod: OutboundMethod | null | undefined,
): StopType => {
  switch (stopType) {
    case StopType.Delivery: {
      if (outboundMethod === OutboundMethod.LocalDelivery) {
        return StopType.PartnerCarrierPickup;
      }
      return stopType;
    }
    case StopType.Pickup: {
      if (inboundMethod === InboundMethod.InboundDelivery) {
        return StopType.PartnerCarrierDropoff;
      }
      return stopType;
    }
    case StopType.Recovery:
    case StopType.Transfer:
    case StopType.PartnerCarrierDropoff:
    case StopType.PartnerCarrierPickup: {
      return stopType;
    }
    case undefined:
    case null:
    case StopType.None: {
      return StopType.None;
    }
    default: {
      return exhaustive(stopType);
    }
  }
};
