import dayjs from 'dayjs';
import { assign, cloneDeep, isNil, isObject } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeDivide, safeMultiply } from 'shared/math';
import { exhaustive } from 'shared/switch';
import * as XLSX from 'xlsx';
import { type SpecialAccessorialChargeGroup } from '../../domains/management/components/accessorials/common';
import { deriveLogicalStopType } from '../../domains/orders/components/order-form/forms/stop-type';
import {
  type AddressEntity,
  DocumentType,
  type DriverFragment,
  MeasurementUnits,
  type OutstandingOrderFragmentFragment,
  PackageType,
  type PickupOrDelivery,
  SpecialDayOfWeek,
  type StandardOrderForContextMenuFragment,
  type StandardOrderFragmentFragment,
  type StopType,
  WeightUnits,
} from '../../generated/graphql';
import { type Option } from '../filters/types';
import { isInboundShipment, isOutboundShipment } from './stops';

export const convertPoundsToKilograms = (
  pounds: number | null | undefined,
): number | null | undefined => {
  if (isNil(pounds)) {
    return pounds;
  }
  return safeDivide(pounds, 2.204_62, 10);
};

export const convertKilogramsToPounds = (
  kilograms: number | null | undefined,
): number | null | undefined => {
  if (isNil(kilograms)) {
    return kilograms;
  }
  return safeMultiply(kilograms, 2.204_62, 10);
};

export const convertToCentimeters = (
  inches: number | null | undefined,
): number | null | undefined => {
  if (isNil(inches)) {
    return inches;
  }
  return safeMultiply(inches, 2.54);
};

export const convertToInches = (
  centimeters: number | null | undefined,
): number | null | undefined => {
  if (isNil(centimeters)) {
    return centimeters;
  }
  return safeDivide(centimeters, 2.54);
};

export const sleep = async (ms: number) =>
  new Promise((r) => setTimeout(r, ms));

export const convertDocumentToS3Url = (
  bucket: string,
  key: string,
  region: string,
) => {
  return `https://${bucket}.s3.${region}.amazonaws.com/${key
    .split(' ')
    .join('+')}`;
};

export const convertS3UrlToDocument = (url: string) => {
  const match = /^https:\/\/(.+)\.s3\.(.+)\.amazonaws\.com\/(.+)$/.exec(url);
  if (match) {
    const [, bucket, region, key] = match;
    return { bucket, region, key };
  }
  throw new Error('Invalid S3 image URL format');
};

export const convertNumberStringToFloat = (
  numberString: string | undefined,
): number | undefined => {
  if (isNil(numberString)) {
    return undefined;
  }
  const strippedNumber = numberString?.replaceAll('$', '').replaceAll(',', '');
  const parsedFloat = Number.parseFloat(strippedNumber);
  if (Number.isNaN(parsedFloat)) {
    return undefined;
  }
  return parsedFloat;
};

export const convertOrdersToStopsForMarkReadyToInvoiceDialog = (
  order:
    | OutstandingOrderFragmentFragment
    | StandardOrderFragmentFragment
    | null
    | StandardOrderForContextMenuFragment,
) => {
  const stops = filterNotNil(
    order?.shipments.map((shipment) => {
      const stop = shipment.legs[0]?.endStop;
      if (!isNil(stop)) {
        return {
          ...stop,
          inboundMethod: shipment.fields?.inboundMethod,
          outboundMethod: shipment.fields?.outboundMethod,
        };
      }
      return null;
    }) ?? [],
  );
  return stops.map((stop) => ({
    uuid: stop.uuid,
    pickupOrDelivery: stop.stopType as PickupOrDelivery | undefined,
    addressName: stop.address?.name ?? '',
    routeDate: stop.routeSlot?.route?.date ?? undefined,
    status: stop.status,
    completedAt: stop.completedAt ?? undefined,
    proofOfDeliverySignee: stop.proofOfDeliverySignee,
    stopType: deriveLogicalStopType(
      stop.stopType,
      stop.inboundMethod,
      stop.outboundMethod,
    ),
  }));
};

export const mergeDeep = (target: any, source: any) => {
  const targetClone = cloneDeep(target);
  const sourceClone = cloneDeep(source);
  for (const key of Object.keys(sourceClone)) {
    if (isObject(sourceClone[key]))
      assign(
        sourceClone[key],
        mergeDeep(targetClone?.[key] ?? {}, sourceClone[key]),
      );
  }
  assign(targetClone == null ? {} : targetClone, sourceClone);
  return targetClone;
};

const mergeMutate = (target: any, source: any) => {
  for (const sourceKey of Object.keys(source)) {
    target[sourceKey] = source[sourceKey];
  }
};

export const mergeDeepMutate = (target: any, source: any) => {
  for (const key of Object.keys(source)) {
    if (isObject(source[key]))
      mergeMutate(source[key], mergeDeep(target?.[key] ?? {}, source[key]));
  }
  mergeMutate(target == null ? {} : target, source);
};

export const isDeepNonEmpty = (obj: unknown) => {
  if (obj == null) return false;
  if (typeof obj === 'string' || typeof obj === 'number')
    return obj.toString().length > 0;
  if (Array.isArray(obj)) return obj.length > 0;
  if (typeof obj === 'object') {
    let isNonEmpty = false;
    for (const key of Object.keys(obj)) {
      if (isDeepNonEmpty((obj as any)[key])) isNonEmpty = true;
    }
    return isNonEmpty;
  }
  return false;
};

export const isNilOrEmptyString = (
  obj: unknown,
): obj is null | undefined | '' => {
  return isNil(obj) || (typeof obj === 'string' && obj.length === 0);
};

export const isAddressEmpty = (
  address: Partial<AddressEntity> | undefined | null,
) => {
  if (isNil(address)) {
    return true;
  }

  const { __typename, ...addressFields } = address;
  return Object.values(addressFields).every((val) => isNilOrEmptyString(val));
};

export const getStringOrEmpty = (value: string | undefined | null) => {
  return value ?? '';
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (
  phoneNumber: string | null | undefined,
): boolean => {
  // Optional hyphens or spaces between groups and optional parens for first group
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(
    phoneNumber?.trim() ?? '',
  );
};

export const isYesterday = (timestampString: string) => {
  const date = new Date(timestampString);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

export const getDayOfWeekAbbreviation = (dow: SpecialDayOfWeek | null) => {
  switch (dow) {
    case SpecialDayOfWeek.Weekday: {
      return 'WKD';
    }
    case SpecialDayOfWeek.Saturday: {
      return 'SAT';
    }
    case SpecialDayOfWeek.Sunday: {
      return 'SUN';
    }
    case SpecialDayOfWeek.Holiday: {
      return 'HOL';
    }
    default: {
      return '';
    }
  }
};

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const specialChargeGroupFormat = (
  chargeGroup:
    | PartialBy<SpecialAccessorialChargeGroup, 'toBeCreated' | 'isUpdated'>
    | null
    | undefined,
) => {
  if (isNil(chargeGroup)) {
    return '';
  }
  const formattedDOW = getDayOfWeekAbbreviation(chargeGroup.dayOfWeek);
  const formattedStart = isNil(chargeGroup.startTime)
    ? ''
    : Number.parseInt(dayjs(chargeGroup.startTime).format('HHmm'), 10);
  const formattedEnd = isNil(chargeGroup.endTime)
    ? ''
    : Number.parseInt(dayjs(chargeGroup.endTime).format('HHmm'), 10);
  const hyphen =
    isNil(chargeGroup.startTime) && isNil(chargeGroup.endTime) ? '' : '-';
  return `${formattedDOW} ${formattedStart}${hyphen}${formattedEnd}`;
};

export const getDriverName = (
  driver: Pick<DriverFragment, 'firstName' | 'lastName'> | undefined | null,
) => {
  return `${driver?.firstName ?? ''} ${driver?.lastName ?? ''}`;
};

export const scrollParentToChild = (
  parent: HTMLDivElement,
  child: HTMLElement,
) => {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect();
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };

  // Where is the child
  const childRect = child.getBoundingClientRect();
  // Is the child viewable?
  const isViewable =
    childRect.top >= parentRect.top &&
    childRect.bottom <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // Should we scroll using top or bottom? Find the smaller ABS adjustment
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      // we're near the top of the list

      parent.scrollTop += scrollTop;
    } else {
      // we're near the bottom of the list

      parent.scrollTop += scrollBot;
    }
  }
};

export const getInboundOutboundShipmentsFromOrder = <
  T extends {
    legs: Array<{ endStop: { stopType?: StopType | null } }>;
  },
>(order: {
  shipments: T[];
}) => {
  const inboundShipment = order.shipments.find((shipment) =>
    isInboundShipment(shipment),
  );
  const outboundShipment = order.shipments.find((shipment) =>
    isOutboundShipment(shipment),
  );
  return {
    inboundShipment,
    outboundShipment,
    ...order,
  };
};

/**
 * Where possible, prefer using the `getDocumentTypeCopy` from `useDocuments`
 * which already computes `enableDigitalSignatureFlow`
 */
export const getDocumentTypeCopyUtil = ({
  documentType,
  enableDigitalSignatureFlow,
}: {
  documentType: DocumentType;
  enableDigitalSignatureFlow: boolean;
}) => {
  switch (documentType) {
    case DocumentType.BillOfLading: {
      return 'Bill of lading';
    }
    case DocumentType.DeliveryAlert: {
      return 'Delivery alert';
    }
    case DocumentType.DeliveryReceipt: {
      return enableDigitalSignatureFlow
        ? 'Delivery receipt (for e-sign)'
        : 'Delivery receipt';
    }
    case DocumentType.DeliverySignature: {
      return 'Delivery signature';
    }
    case DocumentType.PickupAlert: {
      return 'Pickup alert';
    }
    case DocumentType.PickupSignature: {
      return 'Pickup signature';
    }
    case DocumentType.ProofOfDelivery: {
      return 'Driver uploaded POD';
    }
    case DocumentType.ProofOfDeliveryScanned: {
      return 'Unsigned POD';
    }
    case DocumentType.DigitalProofOfDelivery: {
      return 'Digital POD';
    }
    case DocumentType.ShipmentPhoto: {
      return 'Shipment photo';
    }
    case DocumentType.Other: {
      return 'Other';
    }
    case DocumentType.CoverPage: {
      return 'Cover page';
    }
    case DocumentType.EndOfDayDocument: {
      return 'End of day document';
    }
    case DocumentType.CustomDriverFormSignature: {
      return 'Custom driver form signature';
    }
    case DocumentType.PodReport: {
      return 'POD report';
    }
    case DocumentType.AccountingReport: {
      return 'Accounting report';
    }
    case DocumentType.StorageUnitPhoto: {
      return 'Container photo';
    }
    case DocumentType.OrderDocument: {
      return 'Order document';
    }
    case DocumentType.OsdPhoto: {
      return 'OSD photo';
    }
    case DocumentType.PickupReceiptForEsign: {
      return 'Pickup receipt (for e-sign)';
    }
    case DocumentType.DigitalProofOfPickup: {
      return 'Digital proof of pickup';
    }
    default: {
      return exhaustive(documentType);
    }
  }
};

/**
 * Get abbreviated shorthand for measurements
 */
export const getMeasurementUnitsAbbreviation = (
  measurementUnit: MeasurementUnits,
) => {
  switch (measurementUnit) {
    case MeasurementUnits.Inches: {
      return 'in';
    }
    case MeasurementUnits.Centimeters: {
      return 'cm';
    }
    default: {
      return exhaustive(measurementUnit);
    }
  }
};

/**
 * Get abbreviated shorthand for weights
 */
export const getWeightUnitsAbbreviation = (weightUnit: WeightUnits) => {
  switch (weightUnit) {
    case WeightUnits.Kilograms: {
      return 'kg';
    }
    case WeightUnits.Pounds: {
      return 'lb';
    }
    default: {
      return exhaustive(weightUnit);
    }
  }
};

/**
 * Get abbreviated shorthand for different package types in orders
 */
export const getPackageTypeAbbreviation = (packageType: PackageType) => {
  switch (packageType) {
    case PackageType.Box: {
      return 'bx';
    }
    case PackageType.Crate: {
      return 'cr';
    }
    case PackageType.Pallet: {
      return 'plt';
    }
    case PackageType.Container: {
      return 'con';
    }
    case PackageType.Bundle: {
      return 'bdl';
    }
    case PackageType.Other: {
      return 'oth';
    }
    case PackageType.Piece: {
      return 'pc';
    }
    case PackageType.Skid: {
      return 'skd';
    }
    default: {
      return exhaustive(packageType);
    }
  }
};

/**
 * Given an object type, returns a list of the object's keys
 */
export function getKeysOfType<T extends Record<string, unknown>>(): Array<
  keyof T
> {
  return [] as Array<keyof T>;
}

export const convertRowsToXlsxBlob = ({
  rows,
  sheetName,
  maxColumnWidth = 100,
}: {
  rows: string[][];
  sheetName: string;
  maxColumnWidth?: number;
}) => {
  const ws = XLSX.utils.aoa_to_sheet(rows);
  // for each column, set the width of the column to the length of the longest cell in that column
  if (rows[0] == null) {
    throw new Error('No headers found in data');
  }
  const columnWidths = rows[0].map((column, columnIdx) => {
    const columnData = rows.slice(1).map((row) => row[columnIdx]);
    const columnWidth = columnData.reduce((maxWidth, cell) => {
      return Math.min(
        Math.max(maxWidth, cell?.toString().length ?? 0),
        maxColumnWidth,
      );
    }, column.length);
    return columnWidth;
  });
  for (const [idx, width] of columnWidths.entries()) {
    ws['!cols'] = ws['!cols'] || [];
    ws['!cols'][idx] = { wch: width };
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  const buf = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
  return new Blob([buf], { type: 'application/octet-stream' });
};

export const convertValueToOption = ({
  value,
  optionsList,
}: {
  value: string | null;
  optionsList: readonly Option[] | null;
}): Option => {
  if (isNil(value) || isNil(optionsList)) {
    return {
      value: '',
      label: '',
    };
  }
  return (
    optionsList?.find((option) => option.value === value) ?? {
      value: '',
      label: '',
    }
  );
};

export const convertValueArrayToOptionArray = ({
  values,
  optionsList,
}: {
  values: string[] | null;
  optionsList: Option[] | null;
}): Option[] => {
  if (isNil(values) || isNil(optionsList)) {
    return [];
  }
  return values.map((value) => convertValueToOption({ value, optionsList }));
};

export const isMutationErrorOutput = <T extends { __typename: string }>(
  result: T | { __typename: 'MutationErrorOutput' } | undefined,
): result is { __typename: 'MutationErrorOutput' } => {
  return result?.__typename === 'MutationErrorOutput';
};

/**
 * Utility function to get the weight unit text based on the useKilograms value. If useKilograms is undefined, then it defaults to false.
 *
 * @param {boolean | null | undefined} useKilograms - The weight unit to use.
 * @returns {string} The weight unit text based on the useKilograms value. If useKilograms is undefined, then it defaults to 'lb'.
 */
export const getWeightUnitText = ({
  useKilograms,
}: {
  useKilograms: boolean | null | undefined;
}) => {
  return useKilograms === true ? 'kg' : 'lb';
};
