import { createContext, useContext } from 'react';
import type { StandardOrderFragmentFragment } from '../../../../../generated/graphql';

export type OrderViewContextValue = {
  // order is undefined until the order is loaded.
  order: StandardOrderFragmentFragment | undefined;
};

const context = createContext<OrderViewContextValue | undefined>(undefined);

export const useOrderViewContext = (): OrderViewContextValue | undefined =>
  useContext(context);

export const OrderViewContextProvider = context.Provider;
