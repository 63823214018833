import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  type PickupOrDelivery,
  type StopStatus,
  type StopType,
} from '../../../generated/graphql';

export type MarkOrderAsReadyToInvoiceFormValues = {
  stops: Array<{
    addressName: string | undefined;
    completedDate: Date | undefined;
    pickupOrDelivery: PickupOrDelivery | undefined;
    podSigneeName: string | undefined;
    uuid: string;
    status?: StopStatus;
    stopType?: StopType;
  }>;
  refusedBy: string | null;
  refusedDate: Date | null;
};

export type MarkOrderAsReadyToInvoiceContext = {
  requirePODPhotoAndName?: boolean;
};

export const useMarkOrderAsReadyToInvoiceForm = ({
  context,
}: {
  context: MarkOrderAsReadyToInvoiceContext;
}) => {
  const schema = joi.object({
    stops: joi.array().items(
      joi.object({
        uuid: joi.string().optional(),
        addressName: joi.string().optional().allow(''),
        pickupOrDelivery: joi.string().optional(),
        completedDate: joi.string().optional().allow(''),
        status: joi.string().optional(),
        podSigneeName: joi.string().optional().allow(''),
        stopType: joi.string().optional(),
      }),
    ),
    refusedBy: joi.string().optional(),
    refusedDate: joi.date().optional(),
  });

  const form = useForm<MarkOrderAsReadyToInvoiceFormValues>({
    context,
    resolver: joiResolver(schema),
  });
  const fieldArray = useFieldArray({ name: 'stops', control: form.control });

  return {
    form,
    fieldArray,
  };
};
