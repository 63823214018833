import { Alert, Box, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../../../generated/graphql';
import useIngestedEmailsStore, {
  useSelectedOrderData,
} from '../../../email-ingestion/ingested-emails-store';
import ReportDocScanIssueDialog from '../../../report-doc-scan-issue.dialog';
import OrderReviewActions from './order-review-actions';

type DocScanOrderActionsProps = {
  readonly onAccept: () => Promise<void>;
  readonly onReject: () => Promise<void>;
  readonly isSavingDocScan: boolean;
};

const DocScanOrderActions = ({
  onAccept,
  onReject,
  isSavingDocScan,
}: DocScanOrderActionsProps) => {
  const [showReportADocScanIssueDialog, setShowReportADocScanIssueDialog] =
    useIngestedEmailsStore(
      (state) => [
        state.showReportADocScanIssueDialog,
        state.setShowReportADocScanIssueDialog,
      ],
      shallow,
    );
  const selectedOrderData = useSelectedOrderData();

  const [showSnackbarThanks, setShowSnackbarThanks] = useState(false);

  const { userPermissions } = useUserRoles();
  const { hasMasterPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Master,
  );

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbarThanks}
        onClose={() => {
          setShowSnackbarThanks(false);
        }}
      >
        <Alert severity="success">Thanks for sharing your feedback!</Alert>
      </Snackbar>
      <ReportDocScanIssueDialog
        open={showReportADocScanIssueDialog}
        scannedOrderResultUuid={selectedOrderData?.uuid ?? ''}
        onConfirm={() => {
          setShowSnackbarThanks(true);
          setShowReportADocScanIssueDialog(false);
        }}
        onCancel={() => {
          setShowReportADocScanIssueDialog(false);
        }}
      />
      <OrderReviewActions
        acceptLoading={isSavingDocScan}
        rejectLoading={isSavingDocScan}
        onAccept={onAccept}
        onReject={onReject}
      />
      {hasMasterPermission && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
            display: 'flex',
            flexDirection: 'column',
            border: '2px solid #FF0000',
            borderRadius: '4px',
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <Typography variant="body2">
            Scanned order UUID: {selectedOrderData?.uuid}
          </Typography>
          <Typography variant="body2">
            Attachment UUID:{' '}
            {selectedOrderData?.scannedOrder.ingestedOutlookEmailDetails
              ?.attachmentUuid ?? 'No attachment UUID'}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(DocScanOrderActions);
