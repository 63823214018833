import { Stack, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import {
  isPartnerCarrierStop,
  type StopMethod,
} from '../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import CustomerPortalAddressField from './customer-portal-address-field';
import CustomerPortalDateRangeField from './customer-portal-date-range-field';
import { CustomerPortalOrderPartnerCarrierField } from './customer-portal-order-partner-carrier-field';
import CustomerPortalStopTypeField from './customer-portal-stop-type-field';
import { type CustomerPortalOrderFormValues } from './forms/types';
import { getCustomerPortalOrderFormStopField } from './forms/utils';

type CustomerPortalStopDetailsProps = {
  readonly stopMethod: StopMethod;
};

const CustomerPortalStopDetails = ({
  stopMethod,
}: CustomerPortalStopDetailsProps) => {
  const { control, setValue } = useFormContext<CustomerPortalOrderFormValues>();
  const { errors } = useFormState<CustomerPortalOrderFormValues>();

  const stopFieldPath = getCustomerPortalOrderFormStopField(stopMethod);
  const { getStopType } = useCustomerPortalOrderFormContext();
  const stopType = getStopType(stopMethod);

  const serviceId = useWatch({ control, name: 'serviceId' });
  const serviceDate = useWatch({
    control,
    name: `${stopFieldPath}.serviceDate`,
  });
  const deadlineDate = useWatch({
    control,
    name: `${stopFieldPath}.deadlineDate`,
  });

  const serviceDateError = errors[stopFieldPath]?.serviceDate?.message;
  const deadlineDateError = errors[stopFieldPath]?.deadlineDate?.message;

  const onChange = (range: {
    rangeStart: Dayjs | undefined;
    rangeEnd: Dayjs | undefined;
  }) => {
    setValue(`${stopFieldPath}.serviceDate`, range.rangeStart);
    setValue(`${stopFieldPath}.deadlineDate`, range.rangeEnd);
  };

  const stopMethodNotConfiguredText = (
    <Typography variant="body2" color="text.secondary">
      {isNil(serviceId) ? (
        <>
          Select a service level to configure the {sentenceCase(stopMethod)}{' '}
          stop.
        </>
      ) : (
        <>
          Selected service level does not include an {sentenceCase(stopMethod)}{' '}
          stop.
        </>
      )}
    </Typography>
  );

  return (
    <Stack gap={1} flexGrow={1} bgcolor="white" p={2}>
      <Typography variant="h6" fontSize="16px">
        {sentenceCase(stopMethod)}
      </Typography>
      {isNil(stopType) ? (
        stopMethodNotConfiguredText
      ) : (
        <>
          <CustomerPortalOrderPartnerCarrierField stopMethod={stopMethod} />
          {!isPartnerCarrierStop(stopType) && (
            <Stack gap={1}>
              <CustomerPortalAddressField stopMethod={stopMethod} />
              <CustomerPortalStopTypeField stopType={stopType} />
              <CustomerPortalDateRangeField
                stopMethod={stopMethod}
                rangeStart={serviceDate ?? undefined}
                rangeEnd={deadlineDate ?? undefined}
                error={serviceDateError ?? deadlineDateError}
                onChange={onChange}
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default CustomerPortalStopDetails;
