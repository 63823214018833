import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { type FieldValues } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { safeAdd } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { type AddressWithRequiredFields, MimeType } from 'shared/types';
import { calculateDimAndRegularWeight } from 'shared/weight';
import { v4 } from 'uuid';
import apolloClient from '../../apollo-client';
import createPagesForPdf from '../../common/utils/pdf-gen';
import { transformAddressToFullAddressStringSeparateLines } from '../../common/utils/prettyPrintUtils';
import {
  isInboundShipment,
  isOutboundShipment,
} from '../../common/utils/stops';
import { convertToInches } from '../../common/utils/utils';
import {
  type CompanyFragment,
  CreateOrderDocumentDocumentDocument,
  type CreateOrderDocumentDocumentMutation,
  type CreateOrderDocumentDocumentMutationVariables,
  DocumentType,
  EmailOrderDocumentDocument,
  type EmailOrderDocumentMutation,
  type EmailOrderDocumentMutationVariables,
  EmailOrderDocumentStatus,
  GenerateOrderDocumentPreSignedPutUrlDocument,
  type GenerateOrderDocumentPreSignedPutUrlMutation,
  type GenerateOrderDocumentPreSignedPutUrlMutationVariables,
  type HazmatClass,
  InboundMethod,
  type LineHaulLaneFragment,
  OrderDocumentType,
  type OrderEventForCoverSheetFragment,
  OrderEventType,
  type OrderForArrivalNoticeFragment,
  type OrderForCoverSheetFragment,
  type OrderForStopOnRouteFragment,
  type PackageForArrivalNoticeFragment,
  type PackageGroup,
  type ShallowContactFragment,
  type ShipmentForArrivalNoticeFragment,
  type ShipmentForCoverSheetFragment,
  ShipmentType,
  StopStatus,
  StopType,
} from '../../generated/graphql';
import { type AddressFormField } from '../addresses/redux/addresses-values-slice';
import { DocumentAttachments } from '../invoice-old/components/download-documents';
import { uploadDocumentToAws } from '../invoices/utils';
import {
  INBOUND_STOP_IDX,
  OUTBOUND_STOP_IDX,
} from '../orders/components/order-form/components/constants';
import {
  deriveLogicalStopType,
  isPartnerCarrierStop,
} from '../orders/components/order-form/forms/stop-type';
import {
  type AddressOptionalValues,
  type CustomChargeValues,
  type DocumentValues,
  type LineHaulShipmentValues,
  type OrderChargesShipmentValues,
  type PackageValues,
  type StopValues,
} from '../orders/components/order-form/forms/types';
import { getExpectedOrderChargesShipmentStatus } from '../orders/components/order-form/utils';
import { inboundStopTypes, outboundStopTypes } from '../orders/lib/stop-types';
import { type AddressToDisplay } from './components/display-address';

export enum LabelType {
  OutboundLabel = 'Outbound Label',
  ContainerLabel = 'Container Label',
  LotLabel = 'Lot Label',
}

export enum LabelSize {
  FourByFour = '4x4',
  ThreeByFour = '3x4',
  SixByFour = '6x4',
  FourBySix = '4x6',
}

export type LabelDimensions = {
  width: number;
  height: number;
};

export const getValidLabelSizesForLabelType = (
  labelType: LabelType,
): LabelSize[] => {
  switch (labelType) {
    case LabelType.OutboundLabel: {
      return [LabelSize.SixByFour];
    }
    case LabelType.ContainerLabel: {
      return [LabelSize.ThreeByFour, LabelSize.FourByFour, LabelSize.SixByFour];
    }
    case LabelType.LotLabel: {
      return [LabelSize.ThreeByFour, LabelSize.FourBySix];
    }
    default: {
      return exhaustive(labelType);
    }
  }
};

export const getLabelDimensionFromLabelSize = (
  labelSize: LabelSize,
): LabelDimensions => {
  switch (labelSize) {
    case LabelSize.ThreeByFour: {
      return { width: 3, height: 4 };
    }
    case LabelSize.FourByFour: {
      return { width: 4, height: 4 };
    }
    case LabelSize.SixByFour: {
      return { width: 6, height: 4 };
    }
    case LabelSize.FourBySix: {
      return { width: 4, height: 6 };
    }
    default: {
      return exhaustive(labelSize);
    }
  }
};

export const getAppointmentDateStringFromStopValues = (
  stop: StopValues | null | undefined,
) => {
  return `${
    isNil(stop?.deliveryDate) ? '' : dayjs(stop?.deliveryDate).format('MM/DD')
  } ${
    isNil(stop?.appointmentTime)
      ? ''
      : dayjs(stop?.appointmentTime).format('HH:mm')
  }${
    !isNil(stop?.appointmentTime) || !isNil(stop?.endAppointmentTime)
      ? ' - '
      : ''
  }${
    isNil(stop?.endAppointmentTime)
      ? ''
      : dayjs(stop?.endAppointmentTime).format('HH:mm')
  }`;
};

export type UniquePackagePiece = {
  pkg: PackageValues;
  pieceNumber: number;
};

export type OrderDeliveryReceiptData = {
  companyName: string;
  contactDisplayName: string;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  orderName: string | null | undefined;
  refNumbers: string[] | null | undefined;
  totalPieces: number;
  totalWeight: number;
  totalDimWeight: number;
  serviceName: string | null | undefined;
  inboundShipment: OrderDeliveryReceiptShipment | null | undefined;
  outboundShipment: OrderDeliveryReceiptShipment | null | undefined;
};

export type OrderDeliveryReceiptShipment = {
  stopType: StopType | null;
  address: AddressToDisplay | null | undefined;
  contactPersonName: string | null | undefined;
  contactPhone: string | null | undefined;
  instructions: string | null | undefined;
  terminalCode: string | null | undefined;
  serviceDate: Date | null | undefined;
  deadlineDate: Date | null | undefined;
  appointmentDateString: string | null | undefined;
};

export type PackageToDisplay = {
  quantity: number | null | undefined;
  weight: number | null | undefined;
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
  description: string | null | undefined;
  type?: string | null | undefined;
};

export type Logistics = {
  inBond: boolean | null | undefined;
  itTeNumber: string | null | undefined;
  hazmat: boolean | null | undefined;
  unNumber: string | null | undefined;
  hazmatDescription: string | null | undefined;
  emergencyResponseNumber: string | null | undefined;
  requiresPlacard: boolean | null | undefined;
  hazmatClass: HazmatClass | null | undefined;
  packageGroup: PackageGroup | null | undefined;
};

export type OrderCoverSheetData = {
  uuid: string | null | undefined; // Used as pallet ID
  companyName: string;
  companyAddress: AddressToDisplay | null | undefined;
  contactDisplayName: string;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  orderName: string | null | undefined;
  refNumbers: string[] | null | undefined;
  packages: PackageToDisplay[];
  serviceName: string | null | undefined;
  originCode: string | null | undefined; // State for non-multi-terminal, terminal for multi-terminal
  destinationCode: string | null | undefined; // State for non-multi-terminal, terminal for multi-terminal
  logistics: Logistics | null | undefined; // logistics section
  inboundShipment: OrderCoverSheetShipment | null;
  outboundShipment: OrderCoverSheetShipment | null;
  orderCreatedEvent: OrderEventForCoverSheetFragment | null | undefined;
};

export type OrderCoverSheetShipment = {
  stopType: StopType | null;
  address: AddressToDisplay | null | undefined;
  contactPersonName: string | null | undefined;
  contactPhone: string | null | undefined;
  instructions: string | null | undefined;
  routeName: string | null | undefined;
  driverName: string | null | undefined;
  serviceDate: Date | null | undefined;
  deadlineDate: Date | null | undefined;
  appointmentDateString: string | null | undefined;
  isSpecial: boolean;
};

export type ArrivalNoticeCustomCharges = {
  name: string | null | undefined;
  total: number;
};

export type ArrivalNoticeShipment = {
  stopType: StopType;
  shipmentType: ShipmentType;
  hideFromBilling: boolean;
  address?: AddressToDisplay | null | undefined;
  contactPersonName?: string | null | undefined;
  contactPhone?: string | null | undefined;
  totalFreightChargesAmount?: number;
  totalFuelChargesAmount?: number;
  totalChargesAmount: number;
  customCharges?: ArrivalNoticeCustomCharges[];
};

export type ArrivalNoticeData = {
  companyName: string | null | undefined;
  companyPhone: string | null | undefined;
  companyAddress: AddressToDisplay | null | undefined;
  contactDisplayName: string | null | undefined;
  thirdPartyBrokerName: string | null | undefined;
  orderName: string | null | undefined;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  itTeNumber: string | null | undefined;
  packages: PackageToDisplay[];
  serviceName: string | null | undefined;
  originCode: string | null | undefined; // State for non-multi-terminal, terminal for multi-terminal
  destinationCode: string | null | undefined; // State for non-multi-terminal, terminal for multi-terminal
  hazmat: boolean | null | undefined;
  externalNotes: string | null | undefined;
  inboundShipment: ArrivalNoticeShipment | null;
  outboundShipment: ArrivalNoticeShipment | null;
  lineHaulShipment: ArrivalNoticeShipment | null;
  orderChargesShipment: ArrivalNoticeShipment | null;
};

/**
 * Transforms a list of packages to a list of strings
 * @param packages - The list of packages to transform
 * @param useKilograms - Whether to use kilograms instead of pounds (default: false)
 * @returns The list of strings
 */
export const transformPackagesToPackageString = ({
  packages,
  useKilograms = false,
}: {
  packages: PackageToDisplay[];
  useKilograms?: boolean;
}): string[] => {
  return packages.map(
    (pkg) =>
      `${pkg.quantity} @ ${pkg.length ?? '-'} x ${pkg.width ?? '-'} x ${
        pkg.height ?? '-'
      }, ${pkg.weight}${useKilograms ? 'kg' : 'lbs'}, ${pkg.description}`,
  );
};

const convertPackagesToPackagesToDisplay = ({
  packages,
}: {
  packages: PackageForArrivalNoticeFragment[] | PackageValues[];
}): PackageToDisplay[] => {
  return packages.map((pkg) => ({
    quantity: pkg.quantity,
    weight: pkg.weight,
    length: pkg.length,
    width: pkg.width,
    height: pkg.height,
    description: pkg.description,
    type: pkg.type,
  }));
};

const convertLogisticsData = ({
  order,
}: {
  order: OrderForCoverSheetFragment | FieldValues;
}): Logistics => {
  return {
    inBond: order.inBond,
    itTeNumber: order.itTeNumber,
    hazmat: order.hazmat,
    unNumber: order.unNumber,
    hazmatDescription: order.hazmatDescription,
    emergencyResponseNumber: order.emergencyResponseNumber,
    requiresPlacard: order.requiresPlacard,
    hazmatClass: order.hazmatClass,
    packageGroup: order.packageGroup,
  };
};

const convertOrderFormStopToDeliveryReceiptShipment = ({
  stop,
  getTerminalCode,
}: {
  stop: StopValues | null | undefined;
  getTerminalCode: (uuid: string | null | undefined) => string;
}): OrderDeliveryReceiptShipment => {
  const stopType = stop?.stopType ?? StopType.None;
  const appointmentDateString = getAppointmentDateStringFromStopValues(stop);

  return {
    stopType,
    address: stop?.address,
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: filterNotNil([
      stop?.specialInstructions,
      stop?.address?.specialInstructions,
    ]).join(' '),
    terminalCode: getTerminalCode(stop?.terminalUuid),
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate: stop?.deadlineDate,
    appointmentDateString,
  };
};

export const convertOrderFormValuesToDeliveryReceiptData = ({
  order,
  getTerminalCode,
  getContactName,
  getServiceName,
  companyName,
}: {
  order: FieldValues;
  getTerminalCode: (uuid: string | null | undefined) => string;
  getContactName: (uuid: string | null | undefined) => string;
  getServiceName: (uuid: string | null | undefined) => string;
  companyName: string | undefined;
}): OrderDeliveryReceiptData => {
  const {
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    name,
    number,
    refNumbers,
  } = order;

  const totalPieces =
    order.packages?.reduce(
      (prev: number, curr: PackageValues) => safeAdd(prev, curr.quantity ?? 0),
      0,
    ) ?? 0;

  const { dimWeight: totalDimWeight, regularWeight: totalWeight } =
    calculateDimAndRegularWeight(
      order.useCentimeters === true
        ? (order.packages ?? []).map((pkg: PackageValues) => {
            return {
              ...pkg,
              length: convertToInches(pkg.length),
              width: convertToInches(pkg.width),
              height: convertToInches(pkg.height),
            };
          })
        : (order.packages ?? []),
      order.dimFactor ?? undefined,
    );

  const inboundShipment = convertOrderFormStopToDeliveryReceiptShipment({
    stop: order.stops?.at(INBOUND_STOP_IDX),
    getTerminalCode,
  });
  const outboundShipment = convertOrderFormStopToDeliveryReceiptShipment({
    stop: order.stops?.at(OUTBOUND_STOP_IDX),
    getTerminalCode,
  });

  return {
    companyName: companyName ?? '',
    contactDisplayName: getContactName(order.contactUuid),
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    orderName: name ?? number,
    refNumbers,
    totalPieces,
    totalWeight,
    totalDimWeight,
    serviceName: getServiceName(order.serviceUuid),
    inboundShipment,
    outboundShipment,
  };
};

export const getStartAndEndAppointmentDate = ({
  deadlineDate,
  appointmentTime,
  endAppointmentTime,
}: {
  deadlineDate: Date | null | undefined;
  appointmentTime: Date | null | undefined;
  endAppointmentTime: Date | null | undefined;
}): {
  startAppointment: Date | null | undefined;
  endAppointment: Date | null | undefined;
} => {
  let startAppt = isNil(deadlineDate)
    ? null
    : dayjs(deadlineDate).set('hour', 0).set('minutes', 0);
  let endAppt = startAppt;
  if (!isNil(startAppt) && !isNil(appointmentTime)) {
    startAppt = startAppt
      .set('hour', new Date(appointmentTime).getHours())
      .set('minutes', new Date(appointmentTime).getMinutes());
  }
  if (!isNil(endAppt) && !isNil(endAppointmentTime)) {
    endAppt = endAppt
      .set('hour', new Date(endAppointmentTime).getHours())
      .set('minutes', new Date(endAppointmentTime).getMinutes());
  }

  return {
    startAppointment: startAppt?.toDate(),
    endAppointment: endAppt?.toDate(),
  };
};

const convertShipmentFragmentToCoverSheetShipment = ({
  shipment,
}: {
  shipment: ShipmentForCoverSheetFragment | null | undefined;
}): OrderCoverSheetShipment | null => {
  if (isNil(shipment)) {
    return null;
  }
  const stop = shipment?.legs[0]?.endStop;
  const route = stop?.routeSlot?.route;

  const { startAppointment, endAppointment } = getStartAndEndAppointmentDate({
    deadlineDate: shipment?.standardShipmentFields?.deadlineDate,
    appointmentTime: stop?.appointmentTime,
    endAppointmentTime: stop?.endAppointmentTime,
  });
  const stopType = deriveLogicalStopType(
    stop?.stopType,
    stop?.inboundMethod,
    stop?.outboundMethod,
  );

  const appointmentDateString = `${
    isNil(startAppointment) ? '' : dayjs(startAppointment).format('MM/DD')
  } ${isNil(startAppointment) ? '' : dayjs(startAppointment).format('HH:mm')}${
    !isNil(startAppointment) || !isNil(endAppointment) ? ' - ' : ''
  }${isNil(endAppointment) ? '' : dayjs(endAppointment).format('HH:mm')}`;

  return {
    stopType,
    address: stop?.address,
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: stop?.specialInstructions,
    routeName: route?.name,
    driverName: filterNotNil([
      route?.drivers[0]?.firstName,
      route?.drivers[0]?.lastName,
    ]).join(' '),
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate: endAppointment,
    appointmentDateString,
    isSpecial: stop?.isSpecial ?? false,
  };
};

export const convertOrderCoverSheetFragmentToCoverSheetData = ({
  order,
  terminalsEnabled,
  logisticsEnabled,
}: {
  order: OrderForCoverSheetFragment | null | undefined;
  terminalsEnabled: boolean;
  logisticsEnabled: boolean;
}): OrderCoverSheetData | null => {
  if (isNil(order)) {
    return null;
  }
  const { name, refNumbers, standardOrderFields } = order;

  const { shipperBillOfLadingNumber, masterAirwayBillOfLadingNumber } =
    standardOrderFields;

  const firstShipment = order.shipments[0];
  const packages = convertPackagesToPackagesToDisplay({
    packages: firstShipment?.packages ?? [],
  });

  // Find all incomplete stops. If none, use all stops.
  const regularShipments = order.shipments
    .filter((shipment) => shipment.shipmentType === ShipmentType.Regular)
    .sort((a, b) => a.ordinal - b.ordinal);
  let filteredShipments = regularShipments
    .filter(
      (shipment) =>
        shipment?.legs.at(0)?.endStop.status !== StopStatus.Completed,
    )
    .sort((a, b) => a.ordinal - b.ordinal);
  if (filteredShipments.length === 0) {
    filteredShipments = regularShipments;
  }

  const inboundShipment = filteredShipments.find((shipment) =>
    isInboundShipment(shipment),
  );
  const inboundStop = inboundShipment?.legs[0]?.endStop;
  const outboundShipment = filteredShipments.find((shipment) =>
    isOutboundShipment(shipment),
  );
  const outboundStop = outboundShipment?.legs[0]?.endStop;

  const originCode = terminalsEnabled
    ? inboundStop?.terminal?.code
    : inboundStop?.address?.state;
  const destinationCode = terminalsEnabled
    ? outboundStop?.terminal?.code
    : outboundStop?.address?.state;

  const convertedInboundShipment = convertShipmentFragmentToCoverSheetShipment({
    shipment: inboundShipment,
  });
  const convertedOutboundShipment = convertShipmentFragmentToCoverSheetShipment(
    { shipment: outboundShipment },
  );

  const orderCreatedEvent = order?.orderEvents.find(
    (event) => event.eventType === OrderEventType.OrderCreated,
  );

  const logistics = logisticsEnabled ? convertLogisticsData({ order }) : null;

  return {
    uuid: order.uuid,
    companyName: order?.company.name ?? '',
    companyAddress: order?.company.defaultAddress,
    contactDisplayName: order?.billingPartyContact?.displayName,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    orderName: name,
    refNumbers,
    packages,
    serviceName: order?.service?.name,
    originCode,
    destinationCode,
    inboundShipment: convertedInboundShipment,
    outboundShipment: convertedOutboundShipment,
    orderCreatedEvent,
    logistics,
  };
};

const stopIsIncomplete = (
  stop: StopValues | null | undefined,
  onHand: boolean | null | undefined,
) => {
  const type = stop?.stopType ?? StopType.None;
  if (isPartnerCarrierStop(type)) {
    if (type === StopType.PartnerCarrierDropoff) {
      return onHand !== true; // If not on hand, then incomplete
    }
    return false;
  }
  return (
    stop?.shipmentType === ShipmentType.Regular &&
    stop?.status !== StopStatus.Completed
  );
};

const convertOrderFormStopToCoverSheetShipment = ({
  stop,
  routeName,
  incompleteOnly,
  validStopTypes,
  orderOnHand,
}: {
  stop: StopValues | null | undefined;
  routeName: string | null | undefined;
  incompleteOnly: boolean;
  validStopTypes: StopType[];
  orderOnHand: boolean | null | undefined;
}): OrderCoverSheetShipment | null => {
  const stopType = stop?.stopType ?? StopType.None;
  if (
    isNil(stop) ||
    stop?.shipmentType !== ShipmentType.Regular ||
    (incompleteOnly && !stopIsIncomplete(stop, orderOnHand)) ||
    !validStopTypes.includes(stopType)
  ) {
    return null;
  }
  const appointmentDateString = getAppointmentDateStringFromStopValues(stop);

  const addressCarrier = isPartnerCarrierStop(stopType)
    ? (stop.incomingCarrier ?? stop.outboundCarrier ?? '')
    : '';
  const deadlineDate =
    stopType === StopType.PartnerCarrierDropoff
      ? stop.expectedInboundArrivalDate
      : stopType === StopType.PartnerCarrierPickup
        ? stop.expectedOutboundDate
        : stop.deadlineDate;

  return {
    stopType,
    address: {
      ...stop?.address,
      name: `${addressCarrier.length > 0 ? `${addressCarrier}\n` : ''}${
        stop.address?.name ?? ''
      }`,
    },
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: filterNotNil([
      stop?.specialInstructions,
      stop?.address?.specialInstructions,
    ]).join(' '),
    routeName,
    driverName: stop?.driverName,
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate,
    appointmentDateString,
    isSpecial: stop?.isSpecial ?? false,
  };
};

// For use with new order form
export const convertOrderFormValuesToCoverSheetData = ({
  order,
  getTerminalCode,
  getContactName,
  getServiceName,
  companyName,
  companyAddress,
  terminalsEnabled,
  logisticsEnabled,
  routeNameInbound,
  routeNameOutbound,
  orderCreatedEvent,
}: {
  order: FieldValues;
  getTerminalCode: (uuid: string | null | undefined) => string;
  getContactName: (uuid: string | null | undefined) => string;
  getServiceName: (uuid: string | null | undefined) => string;
  companyName: string | undefined;
  companyAddress: AddressToDisplay | null | undefined;
  terminalsEnabled: boolean;
  logisticsEnabled: boolean;
  routeNameInbound: string | undefined;
  routeNameOutbound: string | undefined;
  orderCreatedEvent: OrderEventForCoverSheetFragment | null | undefined;
}): OrderCoverSheetData => {
  const {
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    name,
    number,
    refNumbers,
    stops,
  } = order;

  const inboundStop = stops?.at(INBOUND_STOP_IDX);
  const outboundStop = stops?.at(OUTBOUND_STOP_IDX);

  const originCode = terminalsEnabled
    ? getTerminalCode(inboundStop?.terminalUuid)
    : inboundStop?.address?.state;
  const destinationCode = terminalsEnabled
    ? getTerminalCode(outboundStop?.terminalUuid)
    : outboundStop?.address?.state;

  const inboundShipment = convertOrderFormStopToCoverSheetShipment({
    stop: inboundStop,
    routeName: routeNameInbound,
    incompleteOnly: false,
    validStopTypes: inboundStopTypes,
    orderOnHand: order.onHand,
  });
  const outboundShipment = convertOrderFormStopToCoverSheetShipment({
    stop: outboundStop,
    routeName: routeNameOutbound,
    incompleteOnly: false,
    validStopTypes: outboundStopTypes,
    orderOnHand: order.onHand,
  });

  const logistics = logisticsEnabled ? convertLogisticsData({ order }) : null;

  return {
    uuid: order.uuid,
    companyName: companyName ?? '',
    companyAddress,
    contactDisplayName: getContactName(order.contactUuid),
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    orderName: name ?? number,
    refNumbers,
    packages: order.packages ?? [],
    serviceName: getServiceName(order.serviceUuid),
    originCode,
    destinationCode,
    inboundShipment,
    outboundShipment,
    orderCreatedEvent,
    logistics,
  };
};

const convertShipmentFragmentToArrivalNoticeShipment = ({
  shipment,
}: {
  shipment: ShipmentForArrivalNoticeFragment | undefined;
}): ArrivalNoticeShipment | null => {
  if (isNil(shipment)) return null;
  const stop = shipment.legs[0]?.endStop;
  const contactPerson = stop?.contactPerson;
  const stopType = deriveLogicalStopType(
    stop?.stopType,
    stop?.inboundMethod,
    stop?.outboundMethod,
  );
  return {
    stopType,
    shipmentType: shipment.shipmentType,
    hideFromBilling: shipment.hideFromBilling,
    address: stop?.address,
    contactPersonName: `${contactPerson?.firstName ?? ''} ${
      contactPerson?.lastName ?? ''
    }`,
    contactPhone: contactPerson?.phone,
    totalFreightChargesAmount: shipment.totalFreightChargesAmount,
    totalFuelChargesAmount: shipment.totalFuelChargesAmount,
    totalChargesAmount: shipment.totalChargesAmount,
    customCharges: shipment.customCharges.map((customCharge) => ({
      name: isEmpty(customCharge?.name)
        ? customCharge?.accessorialTemplate?.name
        : customCharge?.name,
      total: customCharge.total,
    })),
  };
};

export const convertOrderArrivalNoticeFragmentToArrivalNoticeData = ({
  order,
  terminalsEnabled,
}: {
  order: OrderForArrivalNoticeFragment | null | undefined;
  terminalsEnabled: boolean;
}): ArrivalNoticeData | null => {
  if (isNil(order)) {
    return null;
  }
  const { name, standardOrderFields } = order;

  const { shipperBillOfLadingNumber, masterAirwayBillOfLadingNumber } =
    standardOrderFields;

  const packages = convertPackagesToPackagesToDisplay({
    packages: order.packages,
  });

  const regularShipments = order.shipments.filter(
    (shipment) => shipment.shipmentType === ShipmentType.Regular,
  );
  const lineHaulShipment = order.shipments.find(
    (shipment) => shipment.shipmentType === ShipmentType.LineHaul,
  );
  const orderChargesShipment = order.shipments.find(
    (shipment) => shipment.shipmentType === ShipmentType.OrderCharges,
  );

  const inboundShipment = regularShipments.find((shipment) =>
    isInboundShipment(shipment),
  );
  const inboundStop = inboundShipment?.legs[0]?.endStop;
  const outboundShipment = regularShipments.find((shipment) =>
    isOutboundShipment(shipment),
  );
  const outboundStop = outboundShipment?.legs[0]?.endStop;

  const originCode = terminalsEnabled
    ? inboundStop?.terminal?.code
    : inboundStop?.address?.state;
  const destinationCode = terminalsEnabled
    ? outboundStop?.terminal?.code
    : outboundStop?.address?.state;

  const convertedInboundShipment =
    convertShipmentFragmentToArrivalNoticeShipment({
      shipment: inboundShipment,
    });
  const convertedOutboundShipment =
    convertShipmentFragmentToArrivalNoticeShipment({
      shipment: outboundShipment,
    });
  const convertedLineHaulShipment =
    convertShipmentFragmentToArrivalNoticeShipment({
      shipment: lineHaulShipment,
    });
  const convertedOrderChargesShipment =
    convertShipmentFragmentToArrivalNoticeShipment({
      shipment: orderChargesShipment,
    });

  return {
    companyName: order?.company.name ?? '',
    companyPhone: order.company.phone,
    companyAddress: order?.company.defaultAddress,
    contactDisplayName: order?.billingPartyContact?.displayName,
    thirdPartyBrokerName: order.thirdPartyBrokers[0]?.name,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    itTeNumber: order.itTeNumber,
    orderName: name,
    packages,
    serviceName: order?.service?.name,
    originCode,
    destinationCode,
    hazmat: order?.hazmat,
    externalNotes: order?.externalNotes,
    inboundShipment: convertedInboundShipment,
    outboundShipment: convertedOutboundShipment,
    lineHaulShipment: convertedLineHaulShipment,
    orderChargesShipment: convertedOrderChargesShipment,
  };
};

const convertOrderFormLineHaulShipmentToArrivalNoticeShipment = ({
  lineHaulShipment,
}: {
  lineHaulShipment: LineHaulShipmentValues | null | undefined;
}): ArrivalNoticeShipment | null => {
  if (isNil(lineHaulShipment)) return null;

  const totalFreightChargesAmount =
    lineHaulShipment?.freightCharge?.totalCharge ?? 0;
  const totalFuelChargesAmount =
    lineHaulShipment?.freightCharge?.fuelCharge.totalCharge ?? 0;

  return {
    stopType: StopType.Delivery,
    shipmentType: ShipmentType.LineHaul,
    hideFromBilling: false,
    totalFreightChargesAmount,
    totalFuelChargesAmount,
    totalChargesAmount: totalFreightChargesAmount + totalFuelChargesAmount,
  };
};

const convertOrderFormOrderChargesShipmentToArrivalNoticeShipment = ({
  orderChargesShipment,
}: {
  orderChargesShipment: OrderChargesShipmentValues | null | undefined;
}): ArrivalNoticeShipment | null => {
  if (isNil(orderChargesShipment)) return null;

  const customCharges = orderChargesShipment.customCharges?.map(
    (customCharge) => ({
      name: customCharge.accessorialName ?? customCharge.name,
      total: customCharge.totalCharge ?? 0,
    }),
  );

  return {
    stopType: StopType.Delivery,
    shipmentType: ShipmentType.OrderCharges,
    hideFromBilling: false,
    totalChargesAmount: sumBy(customCharges, 'total'),
    customCharges,
  };
};

const convertOrderFormStopToArrivalNoticeShipment = ({
  stop,
}: {
  stop: StopValues | null | undefined;
}): ArrivalNoticeShipment | null => {
  if (isNil(stop)) return null;
  const { contactPerson } = stop;

  const totalFreightChargesAmount = stop.freightCharge?.totalCharge ?? 0;
  const totalFuelChargesAmount =
    stop.freightCharge?.fuelCharge.totalCharge ?? 0;
  const totalCustomChargesAmount = sumBy(stop.customCharges, 'totalCharge');

  return {
    stopType: stop.stopType,
    shipmentType: stop.shipmentType,
    hideFromBilling: stop.hideFromBilling,
    address: stop?.address,
    contactPersonName: `${contactPerson?.firstName ?? ''} ${
      contactPerson?.lastName ?? ''
    }`,
    contactPhone: contactPerson?.phone,
    totalFreightChargesAmount,
    totalFuelChargesAmount,
    totalChargesAmount:
      totalFreightChargesAmount +
      totalFuelChargesAmount +
      totalCustomChargesAmount,
    customCharges: stop.customCharges?.map((customCharge) => ({
      name: customCharge.accessorialName ?? customCharge.name,
      total: customCharge.totalCharge ?? 0,
    })),
  };
};

export const convertOrderFormValuesToArrivalNoticeData = ({
  order,
  terminalsEnabled,
  companyData,
  getTerminalCode,
  thirdPartyBrokerName,
  contactName,
  serviceName,
}: {
  order: FieldValues | null | undefined;
  terminalsEnabled: boolean;
  companyData: CompanyFragment | undefined;
  getTerminalCode: (uuid: string | null | undefined) => string;
  thirdPartyBrokerName?: string;
  contactName?: string;
  serviceName?: string;
}): ArrivalNoticeData | null => {
  if (isNil(order)) {
    return null;
  }
  const {
    name,
    number,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    itTeNumber,
    externalNotes,
    hazmat,
    stops,
    isUsingLineHaul,
    isCrossDock,
  } = order;

  const packages = convertPackagesToPackagesToDisplay({
    packages: order.packages ?? [],
  });

  const inboundStop = stops?.at(INBOUND_STOP_IDX);
  const outboundStop = stops?.at(OUTBOUND_STOP_IDX);

  const originCode = terminalsEnabled
    ? getTerminalCode(inboundStop?.terminalUuid)
    : inboundStop?.address?.state;
  const destinationCode = terminalsEnabled
    ? getTerminalCode(outboundStop?.terminalUuid)
    : outboundStop?.address?.state;

  const convertedInboundShipment = convertOrderFormStopToArrivalNoticeShipment({
    stop: inboundStop,
  });
  const convertedOutboundShipment = convertOrderFormStopToArrivalNoticeShipment(
    {
      stop: outboundStop,
    },
  );
  const convertedLineHaulShipment =
    isUsingLineHaul === true
      ? convertOrderFormLineHaulShipmentToArrivalNoticeShipment({
          lineHaulShipment: order.lineHaulShipment,
        })
      : null;
  const convertedOrderChargesShipment =
    isCrossDock === true
      ? convertOrderFormOrderChargesShipmentToArrivalNoticeShipment({
          orderChargesShipment: order.orderChargesShipment,
        })
      : null;

  return {
    companyName: companyData?.name,
    companyPhone: companyData?.phone,
    companyAddress: companyData?.defaultAddress,
    contactDisplayName: contactName,
    thirdPartyBrokerName,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    itTeNumber,
    orderName: name ?? number,
    packages,
    serviceName,
    originCode,
    destinationCode,
    hazmat,
    externalNotes,
    inboundShipment: convertedInboundShipment,
    outboundShipment: convertedOutboundShipment,
    lineHaulShipment: convertedLineHaulShipment,
    orderChargesShipment: convertedOrderChargesShipment,
  };
};

export type FreightCharge = {
  total: number | null | undefined;
  authoCode: string | null | undefined;
  fuelCharge: FuelCharge;
};

export type FuelCharge = {
  total: number | null | undefined;
  authoCode: string | null | undefined;
};

export type CustomCharge = {
  name: string | null | undefined;
  total: number | null | undefined;
  authoCode: string | null | undefined;
};

export type OrderAuthoSheetData = {
  uuid: string | null | undefined; // Used as pallet ID
  companyName: string;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  contactDisplayName: string;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  quoteNumber: string | null | undefined;
  refNumbers: string[] | null | undefined;
  packages: PackageToDisplay[];
  serviceName: string | null | undefined;
  inboundShipment: OrderAuthoSheetShipment | null;
  outboundShipment: OrderAuthoSheetShipment | null;
  orderChargesShipment: OrderChargesLineHaulShipment | null;
  lineHaulShipment: OrderChargesLineHaulShipment | null;
  useKilograms: boolean | null | undefined;
};

export type OrderAuthoSheetShipment = {
  stopType: StopType | null;
  address: AddressToDisplay | null | undefined;
  contactPersonName: string | null | undefined;
  contactPhone: string | null | undefined;
  instructions: string | null | undefined;
  serviceDate: Date | null | undefined;
  deadlineDate: Date | null | undefined;
  appointmentDateString: string | null | undefined;
  isSpecial: boolean;
  freightCharge: FreightCharge | null | undefined;
  customCharges: CustomCharge[];
  terminalCode: string | null | undefined;
  hideFromBilling: boolean | null | undefined;
};

export type OrderChargesLineHaulShipment = {
  stopType: string | null;
  freightCharge: FreightCharge | null | undefined;
  customCharges: CustomCharge[];
  hideFromBilling: boolean | null | undefined;
};

const convertOrderFormStopToAuthoSheetShipment = ({
  stop,
  isCrossDock,
  getTerminalCode,
  terminalsEnabled,
}: {
  stop: StopValues | null | undefined;
  isCrossDock: boolean | null | undefined;
  getTerminalCode: (uuid: string | null | undefined) => string;
  terminalsEnabled: boolean;
}): OrderAuthoSheetShipment | null => {
  const stopType = stop?.stopType ?? StopType.None;
  const appointmentDateString = getAppointmentDateStringFromStopValues(stop);

  const addressCarrier = isPartnerCarrierStop(stopType)
    ? (stop?.incomingCarrier ?? stop?.outboundCarrier ?? '')
    : '';
  const deadlineDate =
    stopType === StopType.PartnerCarrierDropoff
      ? stop?.expectedInboundArrivalDate
      : stopType === StopType.PartnerCarrierPickup
        ? stop?.expectedOutboundDate
        : stop?.deadlineDate;

  const freightCharge =
    isCrossDock === true
      ? null
      : {
          total: stop?.freightCharge?.totalCharge,
          authoCode: stop?.freightCharge?.authoCode,
          fuelCharge: {
            total: stop?.freightCharge?.fuelCharge.totalCharge,
            authoCode: stop?.freightCharge?.fuelCharge.authoCode,
          },
        };

  const customCharges =
    stop?.customCharges?.map((charge) => ({
      name: charge.accessorialName ?? charge.name,
      total: charge.totalCharge,
      authoCode: charge.authoCode,
    })) ?? [];

  return {
    stopType,
    address: {
      ...stop?.address,
      name: `${addressCarrier.length > 0 ? `${addressCarrier}\n` : ''}${
        stop?.address?.name ?? ''
      }`,
    },
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: filterNotNil([
      stop?.specialInstructions,
      stop?.address?.specialInstructions,
    ]).join(' '),
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate,
    appointmentDateString,
    isSpecial: stop?.isSpecial ?? false,
    freightCharge,
    customCharges,
    terminalCode: terminalsEnabled
      ? getTerminalCode(stop?.terminalUuid)
      : stop?.address?.state,
    hideFromBilling: stop?.hideFromBilling,
  };
};

// For use with new order form
export const convertOrderFormValuesToAuthoSheetData = ({
  order,
  getTerminalCode,
  getContactName,
  getServiceName,
  companyName,
  companyAddress,
  companyPhone,
  companyInvoiceEmail,
  terminalsEnabled,
}: {
  order: FieldValues;
  getTerminalCode: (uuid: string | null | undefined) => string;
  getContactName: (uuid: string | null | undefined) => string;
  getServiceName: (uuid: string | null | undefined) => string;
  companyName: string | undefined;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  terminalsEnabled: boolean;
}): OrderAuthoSheetData => {
  const {
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    number,
    refNumbers,
    stops,
    useKilograms,
  } = order;
  const inboundStop = stops?.at(INBOUND_STOP_IDX);
  const outboundStop = stops?.at(OUTBOUND_STOP_IDX);

  const inboundShipment = convertOrderFormStopToAuthoSheetShipment({
    stop: inboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
  });
  const outboundShipment = convertOrderFormStopToAuthoSheetShipment({
    stop: outboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
  });

  const orderChargesShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.orderChargesShipment) && order.isCrossDock === true
      ? {
          stopType: 'Order Charges',
          freightCharge: null,
          customCharges:
            order.orderChargesShipment?.customCharges?.map(
              (cc: CustomChargeValues) => ({
                name: cc.accessorialName ?? cc.name,
                total: cc.totalCharge,
                authoCode: cc.authoCode,
              }),
            ) ?? [],
          hideFromBilling: false,
        }
      : null;

  const lineHaulShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.lineHaulShipment) && order.isUsingLineHaul === true
      ? {
          stopType: 'Line Haul',
          freightCharge: {
            total: order.lineHaulShipment?.freightCharge?.totalCharge,
            authoCode: order.lineHaulShipment?.freightCharge?.authoCode,
            fuelCharge: {
              total:
                order.lineHaulShipment?.freightCharge?.fuelCharge.totalCharge,
              authoCode:
                order.lineHaulShipment?.freightCharge?.fuelCharge.authoCode,
            },
          },
          customCharges: [],
          hideFromBilling: false,
        }
      : null;

  return {
    uuid: order.uuid,
    companyName: companyName ?? '',
    companyAddress,
    companyPhone,
    companyInvoiceEmail,
    contactDisplayName: getContactName(order.contactUuid),
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    quoteNumber: number,
    refNumbers,
    packages: order.packages ?? [],
    serviceName: getServiceName(order.serviceUuid),
    inboundShipment,
    outboundShipment,
    orderChargesShipment,
    lineHaulShipment,
    useKilograms,
  };
};

export type OrderAirWaybillData = {
  uuid: string | null | undefined; // Used as pallet ID
  companyName: string;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  contactDisplayName: string;
  contactAddress: AddressToDisplay | null | undefined;
  name: string | null | undefined;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  quoteNumber: string | null | undefined;
  refNumbers: string[] | null | undefined;
  itTeNumber: string | null | undefined;
  packages: PackageToDisplay[];
  dimFactor: number | null | undefined;
  serviceName: string | null | undefined;
  hazmat: boolean | null | undefined;
  inboundShipment: OrderAirWaybillShipment | null;
  outboundShipment: OrderAirWaybillShipment | null;
  orderChargesShipment: OrderChargesLineHaulShipment | null;
  lineHaulShipment: OrderChargesLineHaulShipment | null;
  lineHaulLane: string | null | undefined;
  useKilograms: boolean | null | undefined;
};

export type OrderAirWaybillShipment = {
  stopType: StopType | null;
  address: AddressToDisplay | null | undefined;
  contactPersonName: string | null | undefined;
  contactPhone: string | null | undefined;
  instructions: string | null | undefined;
  serviceDate: Date | null | undefined;
  deadlineDate: Date | null | undefined;
  appointmentDateString: string | null | undefined;
  isSpecial: boolean;
  freightCharge: FreightCharge | null | undefined;
  customCharges: CustomCharge[];
  terminalCode: string | null | undefined;
  hideFromBilling: boolean | null | undefined;
};

const convertOrderFormStopToAirWaybillShipment = ({
  stop,
  isCrossDock,
  getTerminalCode,
  terminalsEnabled,
}: {
  stop: StopValues | null | undefined;
  isCrossDock: boolean | null | undefined;
  getTerminalCode: (uuid: string | null | undefined) => string;
  terminalsEnabled: boolean;
}): OrderAirWaybillShipment | null => {
  const stopType = stop?.stopType ?? StopType.None;
  const appointmentDateString = getAppointmentDateStringFromStopValues(stop);

  const addressCarrier = isPartnerCarrierStop(stopType)
    ? (stop?.incomingCarrier ?? stop?.outboundCarrier ?? '')
    : '';
  const deadlineDate =
    stopType === StopType.PartnerCarrierDropoff
      ? stop?.expectedInboundArrivalDate
      : stopType === StopType.PartnerCarrierPickup
        ? stop?.expectedOutboundDate
        : stop?.deadlineDate;

  const freightCharge =
    isCrossDock === true
      ? null
      : {
          total: stop?.freightCharge?.totalCharge,
          authoCode: stop?.freightCharge?.authoCode,
          fuelCharge: {
            total: stop?.freightCharge?.fuelCharge.totalCharge,
            authoCode: stop?.freightCharge?.fuelCharge.authoCode,
          },
        };

  const customCharges =
    stop?.customCharges?.map((charge) => ({
      name: charge.accessorialName ?? charge.name,
      total: charge.totalCharge,
      authoCode: charge.authoCode,
    })) ?? [];

  return {
    stopType,
    address: {
      ...stop?.address,
      name: `${addressCarrier.length > 0 ? `${addressCarrier}\n` : ''}${
        stop?.address?.name ?? ''
      }`,
    },
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: filterNotNil([
      stop?.specialInstructions,
      stop?.address?.specialInstructions,
    ]).join(' '),
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate,
    appointmentDateString,
    isSpecial: stop?.isSpecial ?? false,
    freightCharge,
    customCharges,
    terminalCode: terminalsEnabled
      ? getTerminalCode(stop?.terminalUuid)
      : stop?.address?.state,
    hideFromBilling: stop?.hideFromBilling,
  };
};

// For use with new order form
export const convertOrderFormValuesToAirWaybillData = ({
  order,
  getTerminalCode,
  getServiceName,
  contact,
  lineHaulLane,
  companyName,
  companyAddress,
  companyPhone,
  companyInvoiceEmail,
  terminalsEnabled,
}: {
  order: FieldValues;
  getTerminalCode: (uuid: string | null | undefined) => string;
  getServiceName: (uuid: string | null | undefined) => string;
  contact: ShallowContactFragment | undefined;
  lineHaulLane?: LineHaulLaneFragment | null | undefined;
  companyName: string | undefined;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  terminalsEnabled: boolean;
}): OrderAirWaybillData => {
  const {
    name,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    number,
    refNumbers,
    itTeNumber,
    hazmat,
    dimFactor,
    stops,
    useKilograms,
    isUsingLineHaul,
  } = order;
  const inboundStop = stops?.at(INBOUND_STOP_IDX);
  const outboundStop = stops?.at(OUTBOUND_STOP_IDX);

  const inboundShipment = convertOrderFormStopToAirWaybillShipment({
    stop: inboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
  });
  const outboundShipment = convertOrderFormStopToAirWaybillShipment({
    stop: outboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
  });

  const orderChargesShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.orderChargesShipment) && order.isCrossDock === true
      ? {
          stopType: 'Order Charges',
          freightCharge: null,
          customCharges:
            order.orderChargesShipment?.customCharges?.map(
              (cc: CustomChargeValues) => ({
                name: cc.accessorialName ?? cc.name,
                total: cc.totalCharge,
                authoCode: cc.authoCode,
              }),
            ) ?? [],
          hideFromBilling: false,
        }
      : null;

  const lineHaulShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.lineHaulShipment) && order.isUsingLineHaul === true
      ? {
          stopType: 'Line Haul',
          freightCharge: {
            total: order.lineHaulShipment?.freightCharge?.totalCharge,
            authoCode: order.lineHaulShipment?.freightCharge?.authoCode,
            fuelCharge: {
              total:
                order.lineHaulShipment?.freightCharge?.fuelCharge.totalCharge,
              authoCode:
                order.lineHaulShipment?.freightCharge?.fuelCharge.authoCode,
            },
          },
          customCharges: [],
          hideFromBilling: false,
        }
      : null;

  const lineHaulLaneString =
    isUsingLineHaul === true
      ? `${lineHaulLane?.originTerminal.code}-${lineHaulLane?.destinationTerminal.code}`
      : null;

  return {
    uuid: order.uuid,
    companyName: companyName ?? '',
    companyAddress,
    companyPhone,
    companyInvoiceEmail,
    contactDisplayName: contact?.displayName ?? '',
    contactAddress: contact?.defaultAddress,
    name,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    quoteNumber: number,
    refNumbers,
    itTeNumber,
    packages: order.packages ?? [],
    dimFactor,
    serviceName: getServiceName(order.serviceUuid),
    hazmat,
    inboundShipment,
    outboundShipment,
    orderChargesShipment,
    lineHaulShipment,
    lineHaulLane: lineHaulLaneString,
    useKilograms,
  };
};

export type OrderPodReportData = {
  uuid: string | null | undefined; // Used as pallet ID
  companyName: string;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  contactDisplayName: string;
  shipperBillOfLadingNumber: string | null | undefined;
  masterAirwayBillOfLadingNumber: string | null | undefined;
  orderName: string | null | undefined;
  refNumbers: string[] | null | undefined;
  packages: PackageToDisplay[];
  serviceName: string | null | undefined;
  inboundShipment: OrderPodReportShipment | null;
  outboundShipment: OrderPodReportShipment | null;
  orderChargesShipment: OrderChargesLineHaulShipment | null;
  lineHaulShipment: OrderChargesLineHaulShipment | null;
};

export type OrderPodReportShipment = {
  stopType: StopType | null;
  address: AddressToDisplay | null | undefined;
  contactPersonName: string | null | undefined;
  contactPhone: string | null | undefined;
  instructions: string | null | undefined;
  serviceDate: Date | null | undefined;
  deadlineDate: Date | null | undefined;
  appointmentDateString: string | null | undefined;
  isSpecial: boolean;
  freightCharge: FreightCharge | null | undefined;
  customCharges: CustomCharge[];
  terminalCode: string | null | undefined;
  routeName: string | null | undefined;
  driverName: string | null | undefined;
  podSignee: string | null | undefined;
  arrivedAt: Date | null | undefined;
  completedAt: Date | null | undefined;
  hideFromBilling: boolean | null | undefined;
};

export type AddressType =
  | AddressFormField
  | AddressWithRequiredFields
  | AddressOptionalValues;

export const getShipperText = (
  labelType?: LabelType,
  inboundStopType?: StopType,
  shipperAddress?: AddressType,
  recoveryAddress?: AddressType,
  pcdCarrier?: string,
): string | null | undefined => {
  switch (inboundStopType) {
    case StopType.Pickup: {
      if (labelType === LabelType.ContainerLabel) {
        return isNil(shipperAddress)
          ? undefined
          : transformAddressToFullAddressStringSeparateLines(shipperAddress);
      }
      return shipperAddress?.name;
    }
    case StopType.Recovery: {
      return recoveryAddress?.name;
    }
    case StopType.PartnerCarrierDropoff: {
      return pcdCarrier;
    }
    default: {
      return '';
    }
  }
};

export const getRoutingText = (
  outboundStopType?: StopType,
  outboundFlightAirline?: string,
  pcpCarrier?: string,
): string | undefined => {
  switch (outboundStopType) {
    case StopType.PartnerCarrierPickup: {
      return pcpCarrier;
    }
    case StopType.Transfer: {
      return outboundFlightAirline;
    }
    case StopType.Delivery:
    default: {
      return undefined;
    }
  }
};

const convertOrderFormStopToPodReportShipment = ({
  stop,
  isCrossDock,
  getTerminalCode,
  terminalsEnabled,
  routeName,
}: {
  stop: StopValues | null | undefined;
  isCrossDock: boolean | null | undefined;
  getTerminalCode: (uuid: string | null | undefined) => string;
  terminalsEnabled: boolean;
  routeName: string | null | undefined;
}): OrderPodReportShipment | null => {
  const stopType = stop?.stopType ?? StopType.None;
  const appointmentDateString = getAppointmentDateStringFromStopValues(stop);

  const addressCarrier = isPartnerCarrierStop(stopType)
    ? (stop?.incomingCarrier ?? stop?.outboundCarrier ?? '')
    : '';
  const deadlineDate =
    stopType === StopType.PartnerCarrierDropoff
      ? stop?.expectedInboundArrivalDate
      : stopType === StopType.PartnerCarrierPickup
        ? stop?.expectedOutboundDate
        : stop?.deadlineDate;

  const freightCharge =
    isCrossDock === true
      ? null
      : {
          total: stop?.freightCharge?.totalCharge,
          authoCode: stop?.freightCharge?.authoCode,
          fuelCharge: {
            total: stop?.freightCharge?.fuelCharge.totalCharge,
            authoCode: stop?.freightCharge?.fuelCharge.authoCode,
          },
        };

  const customCharges =
    stop?.customCharges?.map((charge) => ({
      name: charge.accessorialName ?? charge.name,
      total: charge.totalCharge,
      authoCode: charge.authoCode,
    })) ?? [];

  return {
    stopType,
    address: {
      ...stop?.address,
      name: `${addressCarrier.length > 0 ? `${addressCarrier}\n` : ''}${
        stop?.address?.name ?? ''
      }`,
    },
    contactPersonName: filterNotNil([
      stop?.contactPerson?.firstName,
      stop?.contactPerson?.lastName,
    ]).join(' '),
    contactPhone: stop?.contactPerson?.phone,
    instructions: filterNotNil([
      stop?.specialInstructions,
      stop?.address?.specialInstructions,
    ]).join(' '),
    serviceDate: [StopType.Pickup, StopType.Delivery].includes(stopType)
      ? stop?.serviceDate
      : null,
    deadlineDate,
    appointmentDateString,
    isSpecial: stop?.isSpecial ?? false,
    freightCharge,
    customCharges,
    terminalCode: terminalsEnabled
      ? getTerminalCode(stop?.terminalUuid)
      : stop?.address?.state,
    routeName,
    driverName: stop?.driverName,
    podSignee: stop?.proofOfDeliverySignee,
    arrivedAt: stop?.arrivedAt,
    completedAt: stop?.completedAt,
    hideFromBilling: stop?.hideFromBilling,
  };
};

// For use with new order form
export const convertOrderFormValuesToPodReportData = ({
  order,
  getTerminalCode,
  getContactName,
  getServiceName,
  companyName,
  companyAddress,
  companyPhone,
  companyInvoiceEmail,
  terminalsEnabled,
  routeNameInbound,
  routeNameOutbound,
}: {
  order: FieldValues;
  getTerminalCode: (uuid: string | null | undefined) => string;
  getContactName: (uuid: string | null | undefined) => string;
  getServiceName: (uuid: string | null | undefined) => string;
  companyName: string | undefined;
  companyAddress: AddressToDisplay | null | undefined;
  companyPhone: string | null | undefined;
  companyInvoiceEmail: string | null | undefined;
  terminalsEnabled: boolean;
  routeNameInbound: string | undefined;
  routeNameOutbound: string | undefined;
}): OrderPodReportData => {
  const {
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    refNumbers,
    stops,
    name,
    number,
  } = order;
  const inboundStop = stops?.at(INBOUND_STOP_IDX);
  const outboundStop = stops?.at(OUTBOUND_STOP_IDX);

  const inboundShipment = convertOrderFormStopToPodReportShipment({
    stop: inboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
    routeName: routeNameInbound,
  });
  const outboundShipment = convertOrderFormStopToPodReportShipment({
    stop: outboundStop,
    isCrossDock: order.isCrossDock,
    getTerminalCode,
    terminalsEnabled,
    routeName: routeNameOutbound,
  });

  const chargesShouldBeShown = getExpectedOrderChargesShipmentStatus({
    fulfillmentType: order.fulfillmentType,
    inboundStopType: inboundStop?.stopType,
    outboundStopType: outboundStop?.stopType,
  });

  const orderChargesShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.orderChargesShipment) && chargesShouldBeShown.present
      ? {
          stopType: 'Order Charges',
          freightCharge: null,
          customCharges:
            order.orderChargesShipment?.customCharges?.map(
              (cc: CustomChargeValues) => ({
                name: cc.accessorialName ?? cc.name,
                total: cc.totalCharge,
                authoCode: cc.authoCode,
              }),
            ) ?? [],
          hideFromBilling: false,
        }
      : null;

  const lineHaulShipment: OrderChargesLineHaulShipment | null =
    !isNil(order.lineHaulShipment) && order.isUsingLineHaul === true
      ? {
          stopType: 'Line Haul',
          freightCharge: {
            total: order.lineHaulShipment?.freightCharge?.totalCharge,
            authoCode: order.lineHaulShipment?.freightCharge?.authoCode,
            fuelCharge: {
              total:
                order.lineHaulShipment?.freightCharge?.fuelCharge.totalCharge,
              authoCode:
                order.lineHaulShipment?.freightCharge?.fuelCharge.authoCode,
            },
          },
          customCharges: [],
          hideFromBilling: false,
        }
      : null;

  return {
    uuid: order.uuid,
    companyName: companyName ?? '',
    companyAddress,
    companyPhone,
    companyInvoiceEmail,
    contactDisplayName: getContactName(order.contactUuid),
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
    orderName: name ?? number,
    refNumbers,
    packages: order.packages ?? [],
    serviceName: getServiceName(order.serviceUuid),
    inboundShipment,
    outboundShipment,
    orderChargesShipment,
    lineHaulShipment,
  };
};

export const getFileNamePrefixFromOrderDocumentType = (
  documentType: OrderDocumentType,
  isQuote: boolean,
) => {
  switch (documentType) {
    case OrderDocumentType.AuthoSheet: {
      return `${isQuote ? 'quote' : 'order'}-autho-sheet`;
    }
    case OrderDocumentType.AirWaybill: {
      return `${isQuote ? 'quote' : 'order'}-air-waybill`;
    }
    case OrderDocumentType.ArrivalNotice: {
      return `${isQuote ? 'quote' : 'order'}-arrival-notice`;
    }
    case OrderDocumentType.PodReport: {
      return `${isQuote ? 'quote' : 'order'}-pod-report`;
    }
    default: {
      return exhaustive(documentType);
    }
  }
};

export const getOrderDocumentDocumentUuid = async ({
  url,
  fileName,
  name,
  companyUuid,
}: {
  url: string | undefined;
  fileName: string;
  name: string;
  companyUuid: string;
}) => {
  if (isNil(url)) {
    throw new Error(
      `nil generated url for order document for order: ${companyUuid}`,
    );
  }
  const uuid = v4();
  const uniqueFileName = `${fileName}_${uuid}`;
  const urlResult = await apolloClient.mutate<
    GenerateOrderDocumentPreSignedPutUrlMutation,
    GenerateOrderDocumentPreSignedPutUrlMutationVariables
  >({
    mutation: GenerateOrderDocumentPreSignedPutUrlDocument,
    variables: {
      generateOrderDocumentPreSignedPutUrlInput: {
        fileName: uniqueFileName,
        fileType: MimeType.PDF,
        companyUuid,
      },
    },
  });
  const preSignedUrl = urlResult.data?.generateOrderDocumentPreSignedPutUrl;
  if (!isNil(preSignedUrl)) {
    const blobRes = await fetch(url);
    const resStatus = await uploadDocumentToAws(
      preSignedUrl,
      new File([await blobRes.blob()], uniqueFileName),
      MimeType.PDF,
    );
    if (resStatus === 200) {
      const resDocument = await apolloClient.mutate<
        CreateOrderDocumentDocumentMutation,
        CreateOrderDocumentDocumentMutationVariables
      >({
        mutation: CreateOrderDocumentDocumentDocument,
        variables: {
          createOrderDocumentDocumentInput: {
            documentType: DocumentType.OrderDocument,
            fileType: MimeType.PDF,
            fileName: uniqueFileName,
            name,
            companyUuid,
            thumbnail: null,
          },
        },
      });

      return resDocument.data?.createOrderDocumentDocument.document.uuid;
    }
  }
  return null;
};

export const sendOrderDocumentEmail = async ({
  blob,
  fileName,
  companyUuid,
  documentType,
  documentName,
  isQuote,
  orderIdentifier,
  recipientEmails,
  senderEmail,
}: {
  blob: Blob;
  fileName: string;
  companyUuid: string | undefined;
  documentType: OrderDocumentType;
  documentName: string;
  isQuote: boolean;
  orderIdentifier: string;
  recipientEmails: string | null | undefined;
  senderEmail: string | null | undefined;
}) => {
  const fileURL = globalThis.URL.createObjectURL(blob);
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName;

  if (!isNil(alink) && !isNil(companyUuid)) {
    const orderDocumentDocumentUuid = await getOrderDocumentDocumentUuid({
      url: alink.href,
      fileName,
      name: documentName,
      companyUuid,
    });
    const recipientEmailsList = recipientEmails
      ?.split(',')
      .map((email) => email.trim());

    if (
      !isNil(orderDocumentDocumentUuid) &&
      !isNil(recipientEmailsList) &&
      !isNil(recipientEmailsList[0])
    ) {
      const res = await apolloClient.mutate<
        EmailOrderDocumentMutation,
        EmailOrderDocumentMutationVariables
      >({
        mutation: EmailOrderDocumentDocument,
        variables: {
          emailOrderDocumentInput: {
            orderDocumentDocumentUuid,
            recipientEmail: recipientEmailsList[0],
            orderDocumentType: documentType,
            isQuote,
            orderIdentifier,
            ccEmails: recipientEmailsList.slice(1),
            senderEmail,
          },
        },
      });
      return res.data?.emailOrderDocument.status;
    }
  }
  return EmailOrderDocumentStatus.Other;
};

export const getInboundStopDetails = (
  order?: OrderForStopOnRouteFragment | null,
  outboundStopType?: StopType,
) => {
  const inboundShipment = order?.shipments?.find(
    (s) =>
      s.legs[0]?.endStop.stopType === StopType.Pickup ||
      s.legs[0]?.endStop.stopType === StopType.Recovery,
  );
  const inboundStop = inboundShipment?.legs[0]?.endStop;

  let inboundStopDetails = '';
  if (
    (outboundStopType === StopType.Transfer ||
      outboundStopType === StopType.Delivery) &&
    !isNil(inboundShipment) &&
    !isNil(inboundStop)
  ) {
    if (inboundShipment.legs[0]?.endStop.stopType === StopType.Pickup) {
      inboundStopDetails =
        inboundStop.inboundMethod === InboundMethod.InboundDelivery
          ? (inboundStop.incomingCarrier ?? '-')
          : (inboundStop.address.name ?? '-');
    } else if (
      inboundShipment.legs[0]?.endStop.stopType === StopType.Recovery
    ) {
      inboundStopDetails = inboundShipment.airportInfo?.address.name ?? '-';
    }
  }

  return inboundStopDetails;
};

export const getPodReportBlob = async (
  generatedPdf: JSX.Element,
  stopDocuments: DocumentValues[],
  attachments: DocumentAttachments,
) => {
  const pdfDoc = await PDFDocument.create();
  const blob = await pdf(generatedPdf).toBlob();
  await createPagesForPdf(await blob.arrayBuffer(), 'application/pdf', pdfDoc);

  if (
    stopDocuments.length > 0 &&
    attachments !== DocumentAttachments.NoAttachments
  ) {
    for (const doc of stopDocuments) {
      if (
        !isNil(doc) &&
        (attachments === DocumentAttachments.IncludeAll ||
          doc.type === DocumentType.ProofOfDeliveryScanned)
      ) {
        const { preSignedGetUrl, fileType } = doc;
        // eslint-disable-next-line no-await-in-loop
        const buffer = await fetch(preSignedGetUrl, { cache: 'no-cache' })
          .then(async (res) => res.arrayBuffer())
          .catch(() => 'error');
        if (!isNil(buffer) && typeof buffer !== 'string') {
          const directlyAttachPdfPagesUsingCopy =
            fileType === 'application/pdf' &&
            [
              DocumentType.DigitalProofOfDelivery,
              DocumentType.ProofOfDelivery,
              DocumentType.ProofOfDeliveryScanned,
              DocumentType.DigitalProofOfPickup,
            ].includes(doc.type);
          // eslint-disable-next-line no-await-in-loop
          await createPagesForPdf(
            buffer,
            fileType,
            pdfDoc,
            directlyAttachPdfPagesUsingCopy,
          );
        }
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {
    type: 'application/pdf',
  });
};

export const convertPackageNumberToPieceNameString = (
  packageNumber: number,
) => {
  if (packageNumber > 99) {
    return packageNumber.toString();
  }
  if (packageNumber > 9) {
    return `0${packageNumber.toString()}`;
  }
  return `00${packageNumber.toString()}`;
};
