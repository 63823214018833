import { type ReactNode } from 'react';

export enum CustomerPortalPage {
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
}

export enum DashboardPage {
  REPORTS = 'REPORTS',
  TRANSIT = 'TRANSIT',
  ORDERS = 'ORDERS',
  CONTACTS = 'CONTACTS',
  COMPANYSETUP = 'COMPANYSETUP',
  SETTINGS = 'SETTINGS',
  ACCOUNTING = 'ACCOUNTING',
  // WMS
  WAREHOUSE = 'WAREHOUSE',
  // Standalone email ingestion
  EMAIL_INGESTION = 'EMAIL_INGESTION',
}

export type DashboardTabs = {
  url: string;
  name: string;
  visibilityCondition?: boolean | null;
};

export type DashboardDrawerItem<TPage> = {
  page: TPage;
  name: string;
  icon: ReactNode;
  url: string;
  navbarUrl?: string; // optional default url that clicking an item will redirect to, otherwise will use url
  tabs: DashboardTabs[];
  visibilityCondition?: boolean | null;
};
