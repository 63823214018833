import { useChannel } from '@ably-labs/react-hooks';
import { memo } from 'react';
import {
  type AssignStopEventPayload,
  DispatchEvent,
  type NewOrderEventPayload,
  type RouteCreateEventPayload,
  type RouteEditEventPayload,
  type UnassignStopEventPayload,
} from 'shared/types';
import useFetchRoutes from '../../../domains/dispatch/hooks/use-fetch-routes';
import useRouteActions from '../../../domains/dispatch/hooks/use-route-actions';

const DispatchMultiplayerTableComponent = ({
  companyUuid,
  handleAssignStop,
  handleUnassignStop,
  handleNewOrder,
}: {
  readonly companyUuid: string;
  readonly handleAssignStop: (payload: AssignStopEventPayload) => void;
  readonly handleUnassignStop: (payload: UnassignStopEventPayload) => void;
  readonly handleNewOrder: (payload: NewOrderEventPayload) => void;
}) => {
  const { deleteRoute } = useRouteActions();
  const { fetchRoute, fetchRoutes } = useFetchRoutes();

  useChannel(`${companyUuid}:dispatch:stops`, async (message) => {
    try {
      switch (message.name) {
        case DispatchEvent.ASSIGN_STOP: {
          const payload = JSON.parse(message.data) as AssignStopEventPayload;
          handleAssignStop(payload);

          break;
        }
        case DispatchEvent.UNASSIGN_STOP: {
          const payload = JSON.parse(message.data) as UnassignStopEventPayload;
          await handleUnassignStop(payload);

          break;
        }
        case DispatchEvent.NEW_ORDER: {
          const payload = JSON.parse(message.data) as NewOrderEventPayload;
          handleNewOrder(payload);

          break;
        }
        case DispatchEvent.CREATE_ROUTE: {
          const payload = JSON.parse(message.data) as RouteCreateEventPayload;
          fetchRoutes({ uuids: payload.routeUuids });

          break;
        }
        case DispatchEvent.EDIT_ROUTE: {
          const payload = JSON.parse(message.data) as RouteEditEventPayload;
          fetchRoute(payload.routeUuid);

          break;
        }
        case DispatchEvent.DELETE_ROUTE: {
          const payload = JSON.parse(message.data) as RouteEditEventPayload;
          deleteRoute(payload.routeUuid);

          break;
        }
        // No default
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        `Error in dispatch multiplayer table component: ${JSON.stringify(error)}`,
      );
    }
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default memo(DispatchMultiplayerTableComponent);
