import { Stack, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import { SettlementBillLineItemsTable } from './components/settlement-bill-line-items-table';
import { SettlementBillsTable } from './components/settlement-bills-table';
import { SettlementBillLineItemsTableMode } from './enums';

enum SettlementTab {
  SETTLEMENT_BILL_LINE_ITEMS = 'settlement-bill-line-items',
  SETTLEMENT_BILLS = 'settlement-bills',
}

export const SettlementPage = () => {
  const [currentTab, setCurrentTab] = useState<SettlementTab>(
    SettlementTab.SETTLEMENT_BILL_LINE_ITEMS,
  );

  return (
    <Stack height="100%" spacing={1}>
      <Tabs
        value={currentTab}
        onChange={(_, value: SettlementTab) => {
          setCurrentTab(value);
        }}
      >
        <Tab
          label="Unsettled stops"
          value={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
        />
        <Tab label="Settlement bills" value={SettlementTab.SETTLEMENT_BILLS} />
      </Tabs>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
      >
        <SettlementBillLineItemsTable
          mode={SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE}
          settlementBillUuid={null}
        />
      </TabPanel>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILLS}
      >
        <SettlementBillsTable />
      </TabPanel>
    </Stack>
  );
};
