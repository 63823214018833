import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useContext, useState } from 'react';
import { filterNotNil } from 'shared/array';
import useMe from '../../../common/react-hooks/use-me';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  EndOfDayDocumentsProcessingJobStatus,
  FindCompanyDocumentStatusFilter,
  type OrderDocumentInput,
  useCreateEndOfDayDocumentProcessingJobMutation,
  useGenerateScannedOrderPreSignedPutUrlsLazyQuery,
  useSplitAndProcessOrderDocumentsMutation,
} from '../../../generated/graphql';
import EndOfDayContext from '../end-of-day-context';

export type EoDFile = { uploadedAt: Date; file: File };

export type UploadDocumentsProps = {
  files: EoDFile[];
  onFinish: (numberFiles: number) => void;
  terminalUuid?: string;
};

const useUploadDocuments = () => {
  const { setDocumentStatusFilter, setSelectedDocumentUuids, currentDate } =
    useContext(EndOfDayContext);
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [loading, setLoading] = useState(false);
  const [generatePreSignedPutUrls] =
    useGenerateScannedOrderPreSignedPutUrlsLazyQuery();
  const [splitAndProcessOrderDocuments] =
    useSplitAndProcessOrderDocumentsMutation();
  const [createEndOfDayDocumentProcessingJob] =
    useCreateEndOfDayDocumentProcessingJobMutation();

  const { companyUuid } = useMe();

  const uploadDocuments = async ({
    files,
    onFinish,
    terminalUuid,
  }: UploadDocumentsProps) => {
    if (
      isNil(companyUuid) ||
      (terminalsEnabled && isNil(terminalUuid)) ||
      (!terminalsEnabled && !isNil(terminalUuid))
    ) {
      return;
    }
    setLoading(true);
    try {
      const preSignedPutUrls = await generatePreSignedPutUrls({
        variables: {
          preSignedUrlRequests: files.map(({ file }) => {
            return {
              filename: file.name,
              filetype: file.type,
            };
          }),
        },
      });
      await Promise.all(
        files.map(async ({ file }, idx) => {
          const options = { headers: { 'Content-Type': file.type } };
          const preSignedUrl =
            preSignedPutUrls.data?.generateScannedOrderPreSignedPutUrls[idx];
          if (isNil(preSignedUrl)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
            );
            return null;
          }
          return fetch(preSignedUrl.url, {
            method: 'PUT',
            body: file,
            ...options,
          });
        }),
      );
      // TODO: Create an EOD document processing job.
      const jobs = await Promise.all(
        files.map(async ({ file }) => {
          return createEndOfDayDocumentProcessingJob({
            variables: {
              createEndOfDayDocumentProcessingJobInput: {
                companyUuid,
                terminalUuid,
                dateUploadedFor: currentDate.toDate(),
                uploadedFileName: file.name,
                status: EndOfDayDocumentsProcessingJobStatus.InProgress,
              },
            },
          });
        }),
      );
      const endOfDayDocumentsProcessingJobUuids = jobs.map(
        (job) => job.data?.createEndOfDayDocumentProcessingJob.uuid ?? '',
      );
      const orderDocuments: OrderDocumentInput[] = filterNotNil(
        files.map(({ file, uploadedAt }, idx) => {
          const preSignedUrl =
            preSignedPutUrls.data?.generateScannedOrderPreSignedPutUrls[idx];
          const endOfDayDocumentsProcessingJobUuid =
            endOfDayDocumentsProcessingJobUuids[idx];
          if (isNil(endOfDayDocumentsProcessingJobUuid)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find EOD document processing job UUID for ${file.name}`,
            );
            return null;
          }
          if (isNil(preSignedUrl)) {
            // eslint-disable-next-line no-console
            console.error(
              `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
            );
            return null;
          }
          return {
            bucket: preSignedUrl.bucket,
            key: preSignedUrl.key,
            filename: preSignedUrl.filename,
            filetype: preSignedUrl.filetype,
            endOfDayDocumentsProcessingJobUuid,
            uploadedAt,
            uploadOrdinal: idx,
            terminalUuid,
          };
        }),
      );
      await splitAndProcessOrderDocuments({
        variables: {
          splitAndProcessOrderDocumentsInput: {
            orderDocuments,
            currentDate: dayjs(currentDate)
              .startOf('day')
              .add(12, 'hours')
              .toDate(),
            companyUuid,
            autoMatchingEnabled: true,
          },
        },
      });
      setDocumentStatusFilter(FindCompanyDocumentStatusFilter.Processing);
      setSelectedDocumentUuids([]);
      onFinish(files.length);
    } finally {
      setLoading(false);
    }
  };

  return { uploadDocuments, loading };
};

export default useUploadDocuments;
