import { Stack } from '@mui/material';
import { isNil } from 'lodash';
import { type AddOrdersToInvoicesSuccessOutput } from '../../../../../generated/graphql';
import AdditionsToInvoicesForContactAccordion from './additions-to-invoices-for-contact-accordion';

type SendOrdersResultProps = {
  readonly result: AddOrdersToInvoicesSuccessOutput | undefined;
};

const SendOrdersResult = ({ result }: SendOrdersResultProps) => {
  if (isNil(result)) {
    return null;
  }

  return (
    <Stack sx={{ overflowY: 'scroll', height: '100%' }} gap={2}>
      <Stack gap={1}>
        {result.invoicesAddedToByContact.map((contactResult) => (
          <AdditionsToInvoicesForContactAccordion
            key={contactResult.contactUuid}
            addOrdersToInvoicesForContactOutput={contactResult}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default SendOrdersResult;
