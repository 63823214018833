import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { exhaustive } from 'shared/switch';
import { type AddressWithRequiredFields } from 'shared/types';
import { type AddressFormField } from '../../domains/addresses/redux/addresses-values-slice';
import { type AddressOptionalValues } from '../../domains/orders/components/order-form/forms/types';
import {
  MeasurementUnits,
  OrderSource,
  OrderStatus,
  WeightUnits,
} from '../../generated/graphql';

export const formatAppointmentRange = (
  appointmentDate: string | undefined,
  appointmentTime: string | undefined,
  endAppointmentTime: string | undefined,
) => {
  const displayAppointmentDate = isNil(appointmentDate)
    ? undefined
    : `${dayjs(appointmentDate).format('MM/DD/YYYY')}, `;
  const displayAppointmentTime = isNil(appointmentTime)
    ? undefined
    : dayjs(appointmentTime).format('hh:mm a');
  const displayEndAppointmentTime = isNil(endAppointmentTime)
    ? undefined
    : dayjs(endAppointmentTime).format('hh:mm a');
  let stopTime;
  if (!isNil(displayAppointmentTime) && isNil(displayEndAppointmentTime)) {
    stopTime = `${displayAppointmentDate ?? ''}${displayAppointmentTime}`;
  } else if (
    isNil(displayAppointmentTime) &&
    !isNil(displayEndAppointmentTime)
  ) {
    stopTime = `${displayAppointmentDate ?? ''}${displayEndAppointmentTime}`;
  } else if (
    !isNil(displayAppointmentTime) &&
    !isNil(displayEndAppointmentTime)
  ) {
    stopTime = `${
      displayAppointmentDate ?? ''
    }${displayAppointmentTime} to ${displayEndAppointmentTime}`;
  }
  return stopTime;
};

export const transformDateTimeToDateTimeString = (
  dateTime: string | null | undefined,
) => {
  if (isNil(dateTime) || dateTime.length === 0) {
    return '-';
  }
  return dayjs(dateTime).format('MM/DD hh:mm a');
};

export const transformDateTimeToDateString = (
  dateTime: string | null | undefined,
) => {
  if (isNil(dateTime) || dateTime.length === 0) {
    return '-';
  }
  return dayjs(dateTime).format('MM/DD');
};

export const transformTimeToTimeString = (
  time: string | null | undefined,
): string => {
  if (isNil(time) || time.length === 0) {
    return '-';
  }
  return dayjs(time).format('hh:mm a');
};

export const transformDateToDateString = (date: string): string => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  return `${month}/${day}/${year}`;
};

export const transformDateStringToSpecifiedFormat = (
  dateTime: string | null | undefined,
  format: string,
) => {
  if (isNil(dateTime) || dateTime.length === 0) {
    return '-';
  }
  return dayjs(dateTime).format(format);
};

export const abbreviateWeightUnits = (weightUnits: WeightUnits | undefined) => {
  if (weightUnits === WeightUnits.Kilograms) {
    return 'kg';
  }
  if (weightUnits === WeightUnits.Pounds) {
    return 'lb';
  }
  return '';
};

export const abbreviateMeasurementUnits = (
  measurementUnits: MeasurementUnits | undefined,
) => {
  if (measurementUnits === MeasurementUnits.Centimeters) {
    return 'cm';
  }
  if (measurementUnits === MeasurementUnits.Inches) {
    return 'in';
  }
  return '';
};

export const transformAddressToFullAddressStringSeparateLines = (
  address: AddressWithRequiredFields | AddressFormField | AddressOptionalValues,
): string => {
  return `${address?.name}\n${address?.line1}${
    isNil(address?.line2) ? '' : ` ${address?.line2}`
  }\n${address?.city}, ${address?.state} ${address?.zip}`;
};

export const transformAddressToFullAddressStringWithoutCity = (
  address: AddressWithRequiredFields,
  includeName?: boolean,
): string => {
  let arr = [address.line1, address.line2, address.zip];
  if (includeName === true) {
    arr = [address.name, address.line1, address.line2, address.zip];
  }
  const addressArr = filterNotNil(
    arr.filter((field) => !isNil(field) && field.length > 0),
  );
  return addressArr.join(', ');
};

// Convert a number into the equivalent number word
export const numberToWord = (num: number): string => {
  const units = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  const numStr: any = num.toString();

  if (num < 20) {
    return units[num]!;
  }

  if (numStr.length === 2) {
    return `${tens[numStr[0]]} ${units[numStr[1]]}`;
  }

  if (numStr.length === 3) {
    if (numStr[1] === '0' && numStr[2] === '0') {
      return `${units[numStr[0]]} hundred`;
    }

    return `${units[numStr[0]]} hundred ${numberToWord(
      Number.parseInt(numStr[1] + numStr[2], 10),
    )}`;
  }

  throw new Error('number was too long');
};

export const formatWeightUnits = (weightUnits: WeightUnits | undefined) => {
  if (weightUnits === WeightUnits.Kilograms) {
    return 'kgs';
  }
  if (weightUnits === WeightUnits.Pounds) {
    return 'lbs';
  }
};

export const formatMeasurementUnits = (
  measurementUnits: MeasurementUnits | undefined,
) => {
  if (measurementUnits === MeasurementUnits.Inches) {
    return 'in';
  }
  if (measurementUnits === MeasurementUnits.Centimeters) {
    return 'cm';
  }
};

export const formatEnumType = (enumType: string | undefined | null) => {
  if (isNil(enumType)) {
    return '';
  }
  const lowerCase = enumType.split('_').join(' ').toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const formatOrderSource = (orderSource: OrderSource | undefined) => {
  if (isNil(orderSource)) {
    return '';
  }
  switch (orderSource) {
    case OrderSource.ManuallyEntered: {
      return 'Manually Entered';
    }
    case OrderSource.DocumentScanning: {
      return 'Document Scanning';
    }
    case OrderSource.Edi: {
      return 'EDI';
    }
    case OrderSource.Consolidation: {
      return 'Consolidated';
    }
    case OrderSource.Api: {
      return 'API';
    }
    case OrderSource.CsvImport: {
      return 'CSV Import';
    }
    case OrderSource.Quote: {
      return 'Quote';
    }
    case OrderSource.IsTemplate: {
      return 'Recurring Order Template';
    }
    case OrderSource.FromTemplate: {
      return 'From Recurring Order Template';
    }
    case OrderSource.Migrated: {
      return 'Migrated from Legacy System';
    }
    case OrderSource.Rebilled: {
      return 'Rebilled';
    }
    case OrderSource.CustomerPortal: {
      return 'Customer Portal';
    }
    default: {
      return exhaustive(orderSource);
    }
  }
};

export const formatOrderStatus = (orderStatus: OrderStatus) => {
  if (orderStatus === OrderStatus.Created) {
    return 'Not assigned';
  }
  if (orderStatus === OrderStatus.HasIssue) {
    return 'Billing Issue';
  }
  return sentenceCase(orderStatus);
};

/**
 * Formats a date string or Date object to 'MM/DD/YYYY hh:mm a' format.
 *  If the date isn't valid, returns '-'.
 */
export const formatDateWithTime = (date: string | Date) => {
  const dayjsDate = dayjs(date);

  return dayjsDate.isValid() ? dayjsDate.format('MM/DD/YYYY hh:mm a') : '-';
};
