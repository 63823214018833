import { useMemo } from 'react';
import {
  useOrderTableFieldHeadersQuery,
  type OrderTableFieldHeaderFragment,
} from '../../../generated/graphql';

type UseOrderTableFieldHeadersOutput = {
  orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  orderTableFieldHeadersLoading: boolean;
};
/**
 * A wrapper around the useOrderTableFieldHeadersQuery hook that returns the order table field headers.
 * @returns the order table field headers and the loading state
 */
const useOrderTableFieldHeaders = (): UseOrderTableFieldHeadersOutput => {
  const {
    data: orderTableFieldHeadersData,
    loading: orderTableFieldHeadersLoading,
  } = useOrderTableFieldHeadersQuery({
    fetchPolicy: 'cache-first',
  });
  const orderTableFieldHeaders = useMemo(
    () =>
      orderTableFieldHeadersData?.orderTableFieldHeaders
        ?.orderTableFieldHeaders ?? [],
    [
      orderTableFieldHeadersData?.orderTableFieldHeaders
        ?.orderTableFieldHeaders,
    ],
  );
  return {
    orderTableFieldHeaders,
    orderTableFieldHeadersLoading,
  };
};

export default useOrderTableFieldHeaders;
