import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type FunctionComponent,
  memo,
  type SetStateAction,
  useState,
} from 'react';
import TabPanel from '../../../../../common/components/tab-panel/tab-panel';
import useMe from '../../../../../common/react-hooks/use-me';
import styles from '../../../styles';
import type { OrderToSendToBilling } from '../../../types/types';
import PreviewSendOrders from './preview-send-orders';
import SendOrdersResult from './send-orders-result';
import {
  useAddOrdersToInvoicesMutation,
  usePreviewAddOrdersToInvoicesQuery,
} from '../../../../../generated/graphql';
import { isMutationErrorOutput } from '../../../../../common/utils/utils';
import { getAddedOrderUuidsFromOutput } from './utils';

export type SelectedOrders = SelectedOrdersQueryAll | SelectedOrdersSelection;

// Select shipments by querying for all orders.
// Optional contactUuid and serviceUuid parameters can be passed in to PostFinalizedModal as props.
type SelectedOrdersQueryAll = {
  retrievalMethod: 'queryAll';
};

// Select shipments from specific orders.
type SelectedOrdersSelection = {
  retrievalMethod: 'selection';
  orders: OrderToSendToBilling[];
};

type AddOrdersToInvoicesModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onClose?: () => void;
  readonly selectedOrderUuids: string[];
  readonly onCloseAfterSuccessfulSend?: (addedOrderUuids: string[]) => void;
};

const AddOrdersToInvoicesModal: FunctionComponent<
  AddOrdersToInvoicesModalProps
> = ({
  isOpen,
  setIsOpen,
  onClose,
  selectedOrderUuids,
  onCloseAfterSuccessfulSend,
}) => {
  const { companyConfiguration } = useMe();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [addOrdersToOpenInvoices, setAddOrdersToOpenInvoices] =
    useState<boolean>(companyConfiguration?.addOrdersToOpenInvoices === true);
  const [sendOrdersFailedSnackbarMessage, setSendOrdersFailedSnackbarMessage] =
    useState<string | undefined>();

  const { data: previewData, loading: previewLoading } =
    usePreviewAddOrdersToInvoicesQuery({
      variables: {
        previewAddOrdersToInvoicesInput: {
          orderUuids: selectedOrderUuids,
          addToExistingInvoices: addOrdersToOpenInvoices,
        },
      },
      skip: !isOpen,
    });

  const onAddSuccess = () => {
    // go to the next tab in the stepper.
    setTabIndex(1);
  };

  const [
    addOrdersToInvoices,
    { data: addOrdersToInvoicesData, loading: addOrdersToInvoicesLoading },
  ] = useAddOrdersToInvoicesMutation({
    onCompleted: (data) => {
      if (
        data.addOrdersToInvoices.__typename ===
        'AddOrdersToInvoicesSuccessOutput'
      ) {
        onAddSuccess();
      } else {
        setSendOrdersFailedSnackbarMessage(data.addOrdersToInvoices.message);
      }
    },
    onError: (error) => {
      setSendOrdersFailedSnackbarMessage(error.message);
    },
  });

  const sendOrdersToBilling = async () => {
    await addOrdersToInvoices({
      variables: {
        input: {
          orderUuids: selectedOrderUuids,
          addToExistingInvoices: addOrdersToOpenInvoices,
        },
      },
    });
  };

  const handleClose = () => {
    if (
      tabIndex === 1 &&
      !isNil(onCloseAfterSuccessfulSend) &&
      addOrdersToInvoicesData?.addOrdersToInvoices.__typename ===
        'AddOrdersToInvoicesSuccessOutput'
    ) {
      const addedOrderUuids = getAddedOrderUuidsFromOutput(
        addOrdersToInvoicesData.addOrdersToInvoices,
      );
      onCloseAfterSuccessfulSend(addedOrderUuids);
    }
    setTabIndex(0);
    setIsOpen(false);
    if (!isNil(onClose)) {
      onClose();
    }
  };

  const wasSuccessfulSend =
    !isNil(addOrdersToInvoicesData?.addOrdersToInvoices) &&
    !isMutationErrorOutput(addOrdersToInvoicesData?.addOrdersToInvoices);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={[styles.modal, { width: '90vw' }]}>
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton sx={{ width: 'fit-content' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <Stepper activeStep={tabIndex} sx={{ flex: 1, mx: 2 }}>
              <Step key={0}>
                <StepLabel
                  StepIconProps={{
                    sx: { color: wasSuccessfulSend ? 'green' : undefined },
                  }}
                >
                  Review invoices
                </StepLabel>
              </Step>
              <Step key={1} completed={wasSuccessfulSend}>
                <StepLabel
                  color="green"
                  StepIconProps={{
                    sx: { color: wasSuccessfulSend ? 'green' : undefined },
                  }}
                >
                  Result
                </StepLabel>
              </Step>
            </Stepper>

            {tabIndex === 1 && (
              <Button variant="contained" onClick={handleClose}>
                Finish
              </Button>
            )}
          </Stack>

          <TabPanel selectedValue={tabIndex} panelValue={0}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormGroup
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={addOrdersToOpenInvoices}
                        disabled={addOrdersToInvoicesLoading || previewLoading}
                        onChange={(e) => {
                          setAddOrdersToOpenInvoices(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '15px' }}>
                        Add to existing unposted invoices
                      </Typography>
                    }
                  />
                </FormGroup>
                <Button
                  startIcon={
                    addOrdersToInvoicesLoading ? (
                      <CircularProgress size={15} />
                    ) : null
                  }
                  disabled={
                    addOrdersToInvoicesLoading ||
                    previewLoading ||
                    isNil(previewData)
                  }
                  variant="contained"
                  onClick={sendOrdersToBilling}
                >
                  Send orders to billing ({selectedOrderUuids.length})
                </Button>
              </Stack>
              {addOrdersToInvoicesLoading && (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress size={50} />
                </Stack>
              )}
              {!isNil(previewData) && !addOrdersToInvoicesLoading && (
                <PreviewSendOrders
                  previewData={previewData?.previewAddOrdersToInvoices}
                  previewLoading={previewLoading}
                />
              )}
            </Stack>
          </TabPanel>
          <TabPanel selectedValue={tabIndex} panelValue={1}>
            {!isNil(addOrdersToInvoicesData?.addOrdersToInvoices) &&
              addOrdersToInvoicesData.addOrdersToInvoices.__typename ===
                'AddOrdersToInvoicesSuccessOutput' && (
                <SendOrdersResult
                  result={addOrdersToInvoicesData.addOrdersToInvoices}
                />
              )}
          </TabPanel>
        </Stack>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!isNil(sendOrdersFailedSnackbarMessage)}
          onClose={() => {
            setSendOrdersFailedSnackbarMessage(undefined);
          }}
        >
          <Alert severity="error">{sendOrdersFailedSnackbarMessage}</Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default memo(AddOrdersToInvoicesModal);
