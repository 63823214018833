import { inches } from '@buge/ts-units/length';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil, values } from 'lodash';
import { exhaustive } from 'shared/switch';
import { pounds } from 'shared/units/rates';
import { downloadBlob } from '../../common/utils/file';
import { convertRowsToXlsxBlob } from '../../common/utils/utils';
import {
  type FindItemGroupsFragment,
  type StorageOrderFragment,
} from '../../generated/graphql';

export enum StorageOrdersFilterField {
  OSD = 'Is OSD?',
  EXPECTED_AT = 'Expected At',
  ARRIVED_AT = 'Arrived At',
}

export enum StorageOrdersTableField {
  OSD = 'OSD',
  CONTACT = 'CONTACT',
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',

  WAREHOUSE = 'WAREHOUSE',
  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
  CARRIER = 'CARRIER',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  SUPPLIER_COMPANY_NAME = 'SUPPLIER_COMPANY_NAME',
  LOT_NUMBER = 'LOT_NUMBER',
  CONSIGNEE_NAME = 'CONSIGNEE_NAME',

  PICKUP_DATE = 'PICKUP_DATE',
  EXPECTED_AT = 'EXPECTED_AT',

  ARRIVED_AT = 'ARRIVED_AT',
}

export enum InventoryFilterField {
  WAREHOUSE_LOCATION = 'Warehouse Location',
  DATE_MARKED_ON_HAND = 'Date Marked on Hand',

  DATE_MARKED_OSD = 'Date Marked OSD',

  DATE_PICKED = 'Date Picked',

  BILLED = 'Billed',
}

export enum InventoryTableField {
  CUSTOMER = 'CUSTOMER',

  STORAGE_UNIT_NAME = 'STORAGE_UNIT_NAME',

  ARRIVED_AT = 'ARRIVED_AT',

  STORAGE_ORDER_REFERENCE_NUMBER = 'STORAGE_ORDER_REFERENCE_NUMBER',

  WAREHOUSE = 'WAREHOUSE',

  PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',

  LOT_NUMBER = 'LOT_NUMBER',

  CONSIGNEE = 'CONSIGNEE',

  SUPPLIER_COMPANY = 'SUPPLIER_COMPANY',

  STORAGE_UNIT_UOM = 'STORAGE_UNIT_UOM',

  SKU = 'SKU',

  ITEM_DESCRIPTION = 'ITEM_DESCRIPTION',

  ITEM_QUANTITY = 'ITEM_QUANTITY',

  ITEM_UOM = 'ITEM_UOM',

  DIMS = 'DIMS',

  WEIGHT = 'WEIGHT',

  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',

  DATE_MARKED_ON_HAND = 'DATE_MARKED_ON_HAND',

  DATE_MARKED_OSD = 'DATE_MARKED_OSD',

  OSD_REASON = 'OSD_REASON',

  DATE_INVENTORY_VERIFIED = 'DATE_INVENTORY_VERIFIED',

  DATE_PICKED = 'DATE_PICKED',

  BILLED = 'BILLED',

  BILLABLE_DAYS = 'BILLABLE_DAYS',

  TOTAL_DAYS_IN_STORAGE = 'TOTAL_DAYS_IN_STORAGE',

  OUTBOUND_REF_NUMBER = 'OUTBOUND_REF_NUMBER',
}

export const getStorageOrdersTableFieldCopy = (
  field: StorageOrdersTableField,
): string => {
  switch (field) {
    case StorageOrdersTableField.CONTACT: {
      return 'Customer';
    }
    case StorageOrdersTableField.REFERENCE_NUMBER: {
      return 'Reference number';
    }
    case StorageOrdersTableField.OSD: {
      return 'OSD';
    }
    case StorageOrdersTableField.PURCHASE_ORDER_NUMBER: {
      return 'PO number';
    }
    case StorageOrdersTableField.CARRIER: {
      return 'Carrier';
    }
    case StorageOrdersTableField.TRACKING_NUMBER: {
      return 'Tracking number';
    }
    case StorageOrdersTableField.SUPPLIER_COMPANY_NAME: {
      return 'Supplier company';
    }
    case StorageOrdersTableField.LOT_NUMBER: {
      return 'Lot number';
    }
    case StorageOrdersTableField.CONSIGNEE_NAME: {
      return 'Consignee';
    }
    case StorageOrdersTableField.PICKUP_DATE: {
      return 'Pickup date';
    }
    case StorageOrdersTableField.EXPECTED_AT: {
      return 'Expected at';
    }
    case StorageOrdersTableField.ARRIVED_AT: {
      return 'Arrived at';
    }
    case StorageOrdersTableField.WAREHOUSE: {
      return 'Warehouse';
    }
    default: {
      return exhaustive(field);
    }
  }
};

export const getStorageOrdersTableField = (
  field: StorageOrdersTableField,
  storageOrder: StorageOrderFragment | undefined,
) => {
  switch (field) {
    case StorageOrdersTableField.OSD: {
      return storageOrder?.isOSD ?? false;
    }
    case StorageOrdersTableField.CONTACT: {
      return storageOrder?.contact?.displayName;
    }
    case StorageOrdersTableField.REFERENCE_NUMBER: {
      return storageOrder?.referenceNumber;
    }
    case StorageOrdersTableField.PURCHASE_ORDER_NUMBER: {
      return storageOrder?.purchaseOrderNumber ?? '-';
    }
    case StorageOrdersTableField.CARRIER: {
      return storageOrder?.carrier ?? '-';
    }
    case StorageOrdersTableField.TRACKING_NUMBER: {
      return storageOrder?.trackingNumber ?? '-';
    }
    case StorageOrdersTableField.SUPPLIER_COMPANY_NAME: {
      return storageOrder?.supplierCompanyName ?? '-';
    }
    case StorageOrdersTableField.LOT_NUMBER: {
      return storageOrder?.lotNumber ?? '-';
    }
    case StorageOrdersTableField.CONSIGNEE_NAME: {
      return storageOrder?.consigneeName ?? '-';
    }
    case StorageOrdersTableField.PICKUP_DATE: {
      return isNil(storageOrder?.pickupDate)
        ? '-'
        : dayjs(storageOrder?.pickupDate).format('MM/DD/YYYY');
    }
    case StorageOrdersTableField.EXPECTED_AT: {
      return isNil(storageOrder?.expectedAt)
        ? '-'
        : dayjs(storageOrder?.expectedAt).format('MM/DD/YYYY');
    }
    case StorageOrdersTableField.ARRIVED_AT: {
      return isNil(storageOrder?.arrivedAt)
        ? '-'
        : dayjs(storageOrder?.arrivedAt).format('MM/DD/YYYY');
    }
    case StorageOrdersTableField.WAREHOUSE: {
      return storageOrder?.warehouse.name ?? '-';
    }
    default: {
      return exhaustive(field);
    }
  }
};

export const getInventoryTableFieldCopy = (
  field: InventoryTableField,
): string => {
  switch (field) {
    case InventoryTableField.STORAGE_UNIT_NAME: {
      return 'Container name';
    }
    case InventoryTableField.CUSTOMER: {
      return 'Customer';
    }
    case InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER: {
      return 'Receipt reference';
    }
    case InventoryTableField.STORAGE_UNIT_UOM: {
      return 'Container UoM';
    }
    case InventoryTableField.SKU: {
      return 'SKU';
    }
    case InventoryTableField.ITEM_DESCRIPTION: {
      return 'Description';
    }
    case InventoryTableField.ITEM_UOM: {
      return 'Item UoM';
    }
    case InventoryTableField.ITEM_QUANTITY: {
      return 'Qty';
    }
    case InventoryTableField.DATE_MARKED_ON_HAND: {
      return 'Date marked on hand';
    }
    case InventoryTableField.DATE_MARKED_OSD: {
      return 'Date marked OSD';
    }
    case InventoryTableField.DATE_INVENTORY_VERIFIED: {
      return 'Date inventory verified';
    }
    case InventoryTableField.DATE_PICKED: {
      return 'Date picked';
    }
    case InventoryTableField.BILLED: {
      return 'Billed';
    }
    case InventoryTableField.BILLABLE_DAYS: {
      return 'Billable days';
    }
    case InventoryTableField.TOTAL_DAYS_IN_STORAGE: {
      return 'Total days in storage';
    }
    case InventoryTableField.WAREHOUSE_LOCATION: {
      return 'Location';
    }
    case InventoryTableField.OUTBOUND_REF_NUMBER: {
      return 'Outbound ref #';
    }
    case InventoryTableField.DIMS: {
      return 'Container dimensions';
    }
    case InventoryTableField.WEIGHT: {
      return 'Container weight';
    }
    case InventoryTableField.OSD_REASON: {
      return 'OSD note';
    }
    case InventoryTableField.ARRIVED_AT: {
      return 'Arrived at';
    }
    case InventoryTableField.CONSIGNEE: {
      return 'Consignee';
    }
    case InventoryTableField.SUPPLIER_COMPANY: {
      return 'Supplier company';
    }
    case InventoryTableField.PURCHASE_ORDER_NUMBER: {
      return 'PO number';
    }
    case InventoryTableField.LOT_NUMBER: {
      return 'Lot number';
    }
    case InventoryTableField.WAREHOUSE: {
      return 'Warehouse';
    }
    default: {
      return exhaustive(field);
    }
  }
};

const formatDims = (itemGroup: FindItemGroupsFragment | undefined) => {
  if (itemGroup?.storageUnit == null) return '-';
  const { length, width, height } = itemGroup.storageUnit;
  const dims = [length, width, height];
  if (dims.every((dim) => isNil(dim) || dim.amount === 0)) return '-';
  const formattedDims = dims
    .map((dim) => dim?.in(inches).amount.toFixed(2).replace('.00', '') ?? '-')
    .join(' x ');
  return `${formattedDims}in`;
};

export const getInventoryTableField = (
  field: InventoryTableField,
  itemGroup: FindItemGroupsFragment | undefined,
): string => {
  switch (field) {
    case InventoryTableField.STORAGE_UNIT_NAME: {
      return itemGroup?.storageUnit?.name ?? '-';
    }
    case InventoryTableField.CUSTOMER: {
      return itemGroup?.storageOrder?.contact?.displayName ?? '-';
    }
    case InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER: {
      return itemGroup?.storageOrder?.referenceNumber ?? '-';
    }
    case InventoryTableField.STORAGE_UNIT_UOM: {
      return sentenceCase(itemGroup?.storageUnit?.unitOfMeasure ?? '-');
    }
    case InventoryTableField.SKU: {
      return itemGroup?.item.sku ?? '-';
    }
    case InventoryTableField.ITEM_DESCRIPTION: {
      return itemGroup?.item.description ?? '-';
    }
    case InventoryTableField.ITEM_UOM: {
      return sentenceCase(itemGroup?.item.primaryUOM.unitOfMeasure ?? '-');
    }
    case InventoryTableField.ITEM_QUANTITY: {
      return itemGroup?.quantity.toString() ?? '-';
    }
    case InventoryTableField.DATE_MARKED_ON_HAND: {
      return isNil(itemGroup?.storageUnit?.markedOnHandAt)
        ? '-'
        : dayjs(itemGroup?.storageUnit?.markedOnHandAt).format('MM/DD/YYYY');
    }
    case InventoryTableField.DATE_MARKED_OSD: {
      return isNil(itemGroup?.storageUnit?.markedOsdAt)
        ? '-'
        : dayjs(itemGroup?.storageUnit?.markedOsdAt).format('MM/DD/YYYY');
    }
    case InventoryTableField.DATE_INVENTORY_VERIFIED: {
      return isNil(itemGroup?.storageUnit?.inventoryLastVerifiedAt)
        ? '-'
        : dayjs(itemGroup?.storageUnit?.inventoryLastVerifiedAt).format(
            'MM/DD/YYYY',
          );
    }
    case InventoryTableField.DATE_PICKED: {
      return isNil(itemGroup?.pickedAt)
        ? '-'
        : dayjs(itemGroup?.pickedAt).format('MM/DD/YYYY');
    }
    case InventoryTableField.BILLED: {
      return itemGroup?.storageUnit?.billed?.toString() ?? '-';
    }
    case InventoryTableField.BILLABLE_DAYS: {
      return itemGroup?.storageUnit?.currentBillableDays?.toString() ?? '-';
    }
    case InventoryTableField.TOTAL_DAYS_IN_STORAGE: {
      return itemGroup?.storageUnit?.numberOfDaysInStorage?.toString() ?? '-';
    }
    case InventoryTableField.WAREHOUSE_LOCATION: {
      return itemGroup?.storageUnit?.warehouseLocation?.name ?? '-';
    }
    case InventoryTableField.OUTBOUND_REF_NUMBER: {
      return itemGroup?.storageUnit?.outboundReferenceNumber ?? '-';
    }
    case InventoryTableField.DIMS: {
      return formatDims(itemGroup);
    }
    case InventoryTableField.WEIGHT: {
      return itemGroup?.storageUnit?.weight?.in(pounds).toString() ?? '-';
    }
    case InventoryTableField.OSD_REASON: {
      return itemGroup?.storageUnit?.osdReason ?? '-';
    }
    case InventoryTableField.ARRIVED_AT: {
      return itemGroup?.storageOrder?.arrivedAt == null
        ? '-'
        : dayjs(itemGroup.storageOrder.arrivedAt).format('MM/DD/YYYY');
    }
    case InventoryTableField.CONSIGNEE: {
      return itemGroup?.storageOrder?.consigneeName ?? '-';
    }
    case InventoryTableField.SUPPLIER_COMPANY: {
      return itemGroup?.storageOrder?.supplierCompanyName ?? '-';
    }
    case InventoryTableField.PURCHASE_ORDER_NUMBER: {
      return itemGroup?.storageOrder?.purchaseOrderNumber ?? '-';
    }
    case InventoryTableField.LOT_NUMBER: {
      return itemGroup?.storageOrder?.lotNumber ?? '-';
    }
    case InventoryTableField.WAREHOUSE: {
      return itemGroup?.storageOrder?.warehouse.name ?? '-';
    }
    default: {
      return exhaustive(field);
    }
  }
};

export const generateFullInventoryReportData = (
  itemGroups: FindItemGroupsFragment[],
): string[][] => {
  const rows = itemGroups.map((itemGroup) => {
    return values(InventoryTableField).map((inventoryTableField) =>
      getInventoryTableField(inventoryTableField, itemGroup),
    );
  });

  const headers = values(InventoryTableField).map((inventoryTableField) =>
    getInventoryTableFieldCopy(inventoryTableField),
  );

  return [headers, ...rows];
};

export const downloadLightingInventoryReportData = async (
  itemGroups: FindItemGroupsFragment[],
) => {
  const columns: InventoryTableField[] = [
    InventoryTableField.PURCHASE_ORDER_NUMBER,
    InventoryTableField.ARRIVED_AT,
    InventoryTableField.CONSIGNEE,
    InventoryTableField.SUPPLIER_COMPANY,
    InventoryTableField.SKU,
    InventoryTableField.ITEM_DESCRIPTION,
    InventoryTableField.ITEM_QUANTITY,
    InventoryTableField.STORAGE_ORDER_REFERENCE_NUMBER,
    InventoryTableField.WAREHOUSE_LOCATION,
    InventoryTableField.ITEM_UOM,
    InventoryTableField.STORAGE_UNIT_UOM,
  ];
  const headers = columns.map((column) => getInventoryTableFieldCopy(column));

  const rows = itemGroups.map((itemGroup) => {
    return columns.map((column) => getInventoryTableField(column, itemGroup));
  });
  rows.unshift(headers);

  const xlsxBlob = await convertRowsToXlsxBlob({
    rows,
    sheetName: 'Inventory report',
  });
  return downloadBlob(
    xlsxBlob,
    `inventory-report-${dayjs().format('MM-DD')}.xlsx`,
  );
};
