import { type Dayjs } from 'dayjs';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import useMe from '../../../../common/react-hooks/use-me';
import { useLineHaulManifestsForPrintQuery } from '../../../../generated/graphql';
import { Button, CircularProgress } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import GeneratedLineHaulManifestPdf from '../../../generated-documents/components/generated-line-haul-manifest-pdf';
import type { NonEmptyArray } from './utils';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import pluralize from 'pluralize';

type PrintLineHaulManifestsModalProps = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
  readonly manifestUuids: NonEmptyArray<string>;
  readonly planningDate: Dayjs;
};
const PrintLineHaulManifestsModal = ({
  open,
  setOpen,
  manifestUuids,
  planningDate,
}: PrintLineHaulManifestsModalProps) => {
  const { errors, clearErrors, onError } = useErrors();
  const { companyName, loading: meLoading } = useMe({ onError });
  const { data, loading: manifestsLoading } = useLineHaulManifestsForPrintQuery(
    {
      variables: { date: planningDate.toDate(), uuids: manifestUuids },
      onError,
    },
  );

  if (manifestsLoading || meLoading) {
    return (
      <PalletModal open={open}>
        <CircularProgress />
      </PalletModal>
    );
  }

  const manifests = data?.lineHaulManifests.lineHaulManifests ?? [];
  const fileName = `${pluralize('manifest', manifests.length)}-${planningDate.format('MM-DD-YYYY')}.pdf`;

  const document = (
    <GeneratedLineHaulManifestPdf
      manifests={manifests}
      companyName={companyName ?? ''}
      documentTitle={fileName}
    />
  );

  return (
    <PalletModal
      open={open}
      pinnedElements={{
        topRight: (
          <PDFDownloadLink document={document} fileName={fileName}>
            {({ loading }) => (
              <Button variant="contained" disabled={loading}>
                {loading ? 'Loading...' : 'Download'}
              </Button>
            )}
          </PDFDownloadLink>
        ),
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <PDFViewer style={{ width: '50vw', height: '75vh' }}>
        {document}
      </PDFViewer>
    </PalletModal>
  );
};

export default PrintLineHaulManifestsModal;
