import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Fade,
  IconButton,
  List,
  ListItem,
  Popover,
  TableRow,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useRef, useState } from 'react';
import { type GeneralLedgerCodeFragment } from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';

const MAX_VISIBLE_ACCESSORIALS = 3;

const AccessorialLink = ({
  accessorial,
  isLast,
}: {
  readonly accessorial: {
    name: string;
    uuid: string;
  };
  readonly isLast: boolean;
}) => (
  <>
    <PalletLink
      href={`/management/accessorials/${accessorial.uuid}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
        color: 'black',
      }}
    >
      {accessorial.name}
    </PalletLink>
    {!isLast && ',  '}
  </>
);

const GeneralLedgerCodesTableRow = ({
  row,
  onClickEdit,
  onClickDelete,
}: {
  readonly row: GeneralLedgerCodeFragment;
  readonly onClickEdit: () => void;
  readonly onClickDelete: () => void;
}) => {
  const visibleAccessorials = row.accessorials.slice(
    0,
    MAX_VISIBLE_ACCESSORIALS,
  );
  const hiddenAccessorials = row.accessorials.slice(MAX_VISIBLE_ACCESSORIALS);
  const [openPopover, setOpenPopover] = useState(false);
  const anchorEl = useRef(null);

  const [isHovering, setIsHovering] = useState(false);
  return (
    <TableRow
      key={row.code}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>{row.code}</TableCell>
      <TableCell>{row.description}</TableCell>
      <TableCell>
        {visibleAccessorials.map((a, index) => (
          <AccessorialLink
            key={a.uuid}
            accessorial={a}
            isLast={index === visibleAccessorials.length - 1}
          />
        ))}
        {hiddenAccessorials.length > 0 && (
          <>
            <PalletLink
              ref={anchorEl}
              component="button"
              sx={{ cursor: 'pointer', ml: 1 }}
              onClick={() => {
                setOpenPopover(true);
              }}
            >
              +{hiddenAccessorials.length} more
            </PalletLink>
            <Popover
              open={openPopover}
              anchorEl={anchorEl.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={() => {
                setOpenPopover(false);
              }}
            >
              <List sx={{ maxHeight: 200, overflow: 'auto' }}>
                {row.accessorials.map((a) => (
                  <ListItem key={a.uuid}>
                    <AccessorialLink isLast accessorial={a} />
                  </ListItem>
                ))}
              </List>
            </Popover>
          </>
        )}
      </TableCell>
      <TableCell align="right">
        <Fade in={isHovering}>
          <Button variant="contained" onClick={onClickEdit}>
            Edit
          </Button>
        </Fade>
        <Fade in={isHovering}>
          <IconButton onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default GeneralLedgerCodesTableRow;
