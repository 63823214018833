import { Tabs, Tab } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { ActiveOrArchivedTab } from './enums';

export const ActiveOrArchivedTabs = ({
  activeTab,
  setTab,
  activeLabel,
  archivedLabel,
}: {
  readonly activeTab: ActiveOrArchivedTab;
  readonly setTab: Dispatch<SetStateAction<ActiveOrArchivedTab>>;
  readonly activeLabel?: string;
  readonly archivedLabel?: string;
}) => {
  return (
    <Tabs
      value={activeTab}
      aria-label="document-status-filters"
      onChange={(_, newValue: ActiveOrArchivedTab) => {
        setTab(newValue);
      }}
    >
      <Tab
        label={activeLabel ?? ActiveOrArchivedTab.ACTIVE}
        value={ActiveOrArchivedTab.ACTIVE}
      />
      <Tab
        label={archivedLabel ?? ActiveOrArchivedTab.ARCHIVED}
        value={ActiveOrArchivedTab.ARCHIVED}
      />
    </Tabs>
  );
};
