import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  type EmailProcessingHistoryQuery,
  type IngestedOutlookEmailAttachmentProcessingStatus,
  useEmailProcessingHistoryLazyQuery,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';

const PAGE_SIZE = 10;

type EmailProcessingHistoryTableState = {
  emailProcessingHistoryData: EmailProcessingHistoryQuery | undefined;
  emailProcessingHistoryLoading: boolean;
  lastDirection: 'prev' | 'next' | undefined;
  statusFilter: IngestedOutlookEmailAttachmentProcessingStatus | 'All';
};

type EmailProcessingHistoryActions = {
  setEmailProcessingHistoryData: (
    data: EmailProcessingHistoryQuery | undefined,
  ) => void;
  setEmailProcessingHistoryLoading: (loading: boolean) => void;
  setLastDirection: (direction: 'prev' | 'next' | undefined) => void;
  setStatusFilter: (
    statusFilter: IngestedOutlookEmailAttachmentProcessingStatus | 'All',
  ) => void;
};

const useEmailProcessingHistoryStore = create(
  immer<EmailProcessingHistoryTableState & EmailProcessingHistoryActions>(
    (set) => ({
      emailProcessingHistoryData: undefined,
      emailProcessingHistoryLoading: false,
      lastDirection: undefined,
      statusFilter: 'All',
      setEmailProcessingHistoryData: (data) => {
        set((state) => {
          state.emailProcessingHistoryData = data;
        });
      },
      setEmailProcessingHistoryLoading: (loading) => {
        set((state) => {
          state.emailProcessingHistoryLoading = loading;
        });
      },
      setLastDirection: (direction) => {
        set((state) => {
          state.lastDirection = direction;
        });
      },
      setStatusFilter: (statusFilter) => {
        set((state) => {
          state.statusFilter = statusFilter;
        });
      },
    }),
  ),
);

const usePaginatedEmailProcessingHistory = () => {
  const [
    emailProcessingHistoryData,
    emailProcessingHistoryLoading,
    lastDirection,
    statusFilter,
    setEmailProcessingHistoryData,
    setEmailProcessingHistoryLoading,
    setLastDirection,
    setStatusFilter,
  ] = useEmailProcessingHistoryStore((state) => [
    state.emailProcessingHistoryData,
    state.emailProcessingHistoryLoading,
    state.lastDirection,
    state.statusFilter,
    state.setEmailProcessingHistoryData,
    state.setEmailProcessingHistoryLoading,
    state.setLastDirection,
    state.setStatusFilter,
  ]);

  const [fetchEmailProcessingHistory, { data, loading }] =
    useEmailProcessingHistoryLazyQuery();

  useEffect(() => {
    setEmailProcessingHistoryData(data);
    setEmailProcessingHistoryLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const hasNextPage =
    lastDirection === 'prev' ||
    emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.hasNextPage;

  const nextPage = useCallback(() => {
    if (emailProcessingHistoryLoading) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch next page while loading');
      return;
    }

    if (hasNextPage === false) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch next page when there is no next page');
      return;
    }

    const endCursor =
      emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.endCursor;

    fetchEmailProcessingHistory({
      variables: {
        findIngestedOutlookEmailsInput: {
          first: PAGE_SIZE,
          after: endCursor,
        },
      },
    }).then(() => {
      setLastDirection('next');
    });
  }, [
    emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.endCursor,
    emailProcessingHistoryLoading,
    fetchEmailProcessingHistory,
    hasNextPage,
    setLastDirection,
  ]);

  const hasPrevPage =
    lastDirection === 'next' ||
    emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.hasPreviousPage;

  const prevPage = useCallback(() => {
    if (emailProcessingHistoryLoading) {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch previous page while loading');
      return;
    }

    if (hasPrevPage === false) {
      // eslint-disable-next-line no-console
      console.error(
        'Cannot fetch previous page when there is no previous page',
      );
      return;
    }

    const startCursor =
      emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.startCursor;

    fetchEmailProcessingHistory({
      variables: {
        findIngestedOutlookEmailsInput: {
          last: PAGE_SIZE,
          before: startCursor,
        },
      },
    }).then(() => {
      setLastDirection('prev');
    });
  }, [
    emailProcessingHistoryData?.ingestedOutlookEmails.pageInfo.startCursor,
    emailProcessingHistoryLoading,
    fetchEmailProcessingHistory,
    hasPrevPage,
    setLastDirection,
  ]);

  const [selectedTerminalUuid] = useGlobalStore((state) => [
    state.selectedTerminalUuid,
  ]);

  const refresh = useCallback(() => {
    fetchEmailProcessingHistory({
      variables: {
        findIngestedOutlookEmailsInput: {
          first: PAGE_SIZE,
          emailIntegrationTerminalUuid: selectedTerminalUuid,
        },
      },
    });
  }, [fetchEmailProcessingHistory, selectedTerminalUuid]);

  const setStatusFilterAndRefresh = useCallback(
    (newStatusFilter: Parameters<typeof setStatusFilter>[0]) => {
      setStatusFilter(newStatusFilter);
      refresh();
    },
    [setStatusFilter, refresh],
  );

  return {
    emailProcessingHistoryData,
    emailProcessingHistoryLoading,
    statusFilter,
    setStatusFilterAndRefresh,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    refresh,
  };
};

export default usePaginatedEmailProcessingHistory;
