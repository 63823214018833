import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import useDrivers from '../../../common/react-hooks/use-drivers';
import useMe from '../../../common/react-hooks/use-me';
import {
  type DetailedLineHaulManifestFragment,
  type ShallowLineHaulSegmentFragment,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { getDriverIdentifier } from '../../dispatch/utils';
import useLineHaulDispatchActions from '../hooks/use-line-haul-dispatch-actions';

type DriverOption = {
  value: string | undefined;
  label: string;
  driverName: string | undefined;
};

const ManifestDriverField = ({
  manifest,
  unmanifestedSegment,
}: {
  readonly manifest: DetailedLineHaulManifestFragment;
  readonly unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
}) => {
  const { companyConfiguration } = useMe();
  const { drivers } = useDrivers();
  const [selectedDriverOption, setSelectedDriverOption] =
    useState<DriverOption>();
  const [showInput, setShowInput] = useState<boolean>(false);

  const { updateDriverOnManifest } = useLineHaulDispatchActions();

  useEffect(() => {
    const { driver } = manifest;

    if (isNil(driver)) {
      setSelectedDriverOption({
        label: '',
        value: undefined,
        driverName: '',
      });
      setShowInput(true);
    } else {
      setSelectedDriverOption({
        label: getDriverIdentifier(
          driver,
          companyConfiguration?.useDriverNumberForDispatch,
          false,
        ),
        driverName: getDriverIdentifier(driver, false),
        value: driver?.uuid,
      });

      setShowInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifest]);

  const updateDriver = async (newOption: DriverOption | null) => {
    // create a new manifest from this segment
    if (isNil(newOption)) {
      return;
    }
    if (!isNil(newOption.value)) {
      await updateDriverOnManifest({
        driverUuid: newOption.value,
        uuid: manifest.uuid,
      });
    }
  };

  return (
    <Stack
      width="100%"
      direction="row"
      // justifyContent="space-between"
      gap={2}
      alignItems="center"
    >
      <Typography variant="subtitle2">Driver</Typography>
      {(showInput || !isNil(unmanifestedSegment)) && (
        <AutocompleteFuzzy
          size="small"
          sx={{
            '& input': {
              fontSize: '14px',
            },
            flex: '1',
          }}
          value={selectedDriverOption}
          options={
            drivers
              ?.filter((driver) => !isNil(driver))
              ?.sort((a, b) =>
                getDriverIdentifier(
                  a,
                  companyConfiguration?.useDriverNumberForDispatch,
                )?.localeCompare(
                  getDriverIdentifier(
                    b,
                    companyConfiguration?.useDriverNumberForDispatch,
                  ),
                ),
              )
              .map((driver) => ({
                label: getDriverIdentifier(
                  driver,
                  companyConfiguration?.useDriverNumberForDispatch,
                  false,
                ),
                driverName: getDriverIdentifier(driver, false),
                value: driver.uuid,
              })) ?? []
          }
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                <Stack>
                  <Typography sx={{ fontSize: '14px' }}>
                    {option.label}
                  </Typography>
                  {companyConfiguration?.useDriverNumberForDispatch ===
                    true && (
                    <Typography variant="caption" color="text.secondary">
                      {option.driverName}
                    </Typography>
                  )}
                </Stack>
              </li>
            );
          }}
          onChange={(event, option) => {
            setSelectedDriverOption(option ?? undefined);
            updateDriver(option);
            event.stopPropagation();
          }}
        />
      )}
    </Stack>
  );
};

export default ManifestDriverField;
