import { type FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import useQuoteForm from './forms/use-quote-form';
import QuotePageForm from './quote-page-form';

type QuotePageProps = {
  readonly quoteUuid: string | null;
};

const QuotePage: FunctionComponent<QuotePageProps> = ({ quoteUuid }) => {
  const { form } = useQuoteForm();

  return (
    <FormProvider {...form}>
      <QuotePageForm quoteUuid={quoteUuid} />
    </FormProvider>
  );
};

export default QuotePage;
