import { isNil } from 'lodash';
import { z } from 'zod';
import { DocumentType, PackageType } from '../../../../../generated/graphql';
import {
  dayjsDate,
  zNonEmptyString,
} from '../../../../orders/components/order-form/forms/zod-utils';

export const INBOUND_INFORMATION_SCHEMA = z.object({
  name: zNonEmptyString({ required_error: 'Missing address name' }),
  addressLine1: zNonEmptyString({ required_error: 'Missing address line 1' }),
  addressLine2: z.string().optional(),
  city: zNonEmptyString({ required_error: 'Missing city' }),
  state: zNonEmptyString({ required_error: 'Missing state' }),
  zipcode: zNonEmptyString({ required_error: 'Missing zipcode' }),
  country: zNonEmptyString({ required_error: 'Missing country' }),
  serviceDate: dayjsDate('Missing service date').optional(),
  deadlineDate: dayjsDate('Missing deadline date').optional(),
  specialInstructions: z.string().optional(),
});

export const OUTBOUND_INFORMATION_SCHEMA = z.object({
  name: zNonEmptyString({ required_error: 'Missing address name' }),
  addressLine1: zNonEmptyString({ required_error: 'Missing address line 1' }),
  addressLine2: z.string().optional(),
  city: zNonEmptyString({ required_error: 'Missing city' }),
  state: zNonEmptyString({ required_error: 'Missing state' }),
  zipcode: zNonEmptyString({ required_error: 'Missing zipcode' }),
  country: zNonEmptyString({ required_error: 'Missing country' }),
  serviceDate: dayjsDate('Missing service date').optional(),
  deadlineDate: dayjsDate('Missing deadline date').optional(),
  specialInstructions: z.string().optional(),
});

export const PACKAGE_SCHEMA = z.object({
  description: z.string().optional(),
  quantity: z
    .number({ required_error: 'Missing quantity' })
    .int()
    .min(0, 'Quantity must not be negative'),
  weight: z
    .number({ required_error: 'Missing weight' })
    .min(0, 'Weight must not be negative'),
  length: z
    .number({ required_error: 'Missing length' })
    .min(0, 'Length must not be negative'),
  width: z
    .number({ required_error: 'Missing width' })
    .min(0, 'Width must not be negative'),
  height: z
    .number({ required_error: 'Missing height' })
    .min(0, 'Height must not be negative'),
  packageType: z.nativeEnum(PackageType).optional(),
});

export const CREATE_DOCUMENT_SCHEMA = z.object({
  s3Url: z.string().url().describe('The URL of the uploaded file in S3'),
  // TODO: Constrain this to only be the document types that are allowed for customer portal (for now).
  type: z.nativeEnum(DocumentType, {
    required_error: 'Missing document type',
  }),
});

// clientId is not included in this schema because it's completely derived
// from contactUuid
// Integration tests for form submission are in use-load-customer-portal-order-form.spec.ts
export const CUSTOMER_PORTAL_ORDER_SCHEMA = z
  .object({
    hawb: zNonEmptyString({ required_error: 'Missing HAWB' }).optional(),
    mawb: zNonEmptyString({ required_error: 'Missing MAWB' }).optional(),
    serviceId: z
      .string({ required_error: 'Missing service level' })
      .uuid('Missing service level'),
    packages: z.array(PACKAGE_SCHEMA).default([]),
    documents: z.array(CREATE_DOCUMENT_SCHEMA).default([]),
    inboundInformation: INBOUND_INFORMATION_SCHEMA.optional(),
    outboundInformation: OUTBOUND_INFORMATION_SCHEMA.optional(),
  })
  .refine((data) => {
    if (isNil(data.inboundInformation) && isNil(data.outboundInformation)) {
      return false;
    }
    return true;
  }, 'At least one inbound or outbound stop must be provided');
