import React from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { Sidebar } from '../../common/components/sidebar';
import { SIDEBAR_OPEN_KEY } from '../../common/local-storage/keys';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../generated/graphql';
import NavbarAddOrderItem from './components/navbar-add-order-item';
import NavbarNotificationItem from './components/navbar-notification-item';
import NavbarSearchItem from './components/navbar-search-item';
import { UserMenu } from './components/user-menu';
import useDrawerItems from './use-drawer-items';

const PalletSidebar = () => {
  const { companyConfiguration } = useMe();
  const notificationsEnabled =
    companyConfiguration?.notificationsEnabled === true;
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const { drawerItems } = useDrawerItems();

  return (
    <Sidebar
      actionItems={
        <>
          <NavbarSearchItem />
          {canWriteOrders && <NavbarAddOrderItem />}
          {notificationsEnabled && <NavbarNotificationItem />}
        </>
      }
      drawerItems={drawerItems}
      userMenu={<UserMenu />}
      sidebarOpenLocalStorageKey={SIDEBAR_OPEN_KEY}
    />
  );
};

export default PalletSidebar;
