import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardContent,
  Collapse,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  type IconButtonProps,
  styled,
  Typography,
} from '@mui/material';
import * as React from 'react';

type ExpandMoreProps = {
  expand: boolean;
} & IconButtonProps;

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  padding-left:15px;
  &:last-child {
    padding-bottom: 10px;
  }
`);

type EndOfDayStopCardProps = {
  readonly onClose: () => void;
  readonly addressString: string;
  readonly timeString: string;
  readonly specialInstructions?: string | null;
  readonly driverNotes?: string | null;
};

const EndOfDayStopDetailsCard = ({
  onClose,
  addressString,
  timeString,
  specialInstructions,
  driverNotes,
}: EndOfDayStopCardProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card variant="outlined">
      <CardContentSpecialPadding>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography gutterBottom sx={{ fontSize: 15 }}>
              Address
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: 13 }}
              color="text.secondary"
            >
              {addressString}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom sx={{ fontSize: 15 }}>
              Appointment Time
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: 13 }}
              color="text.secondary"
            >
              {timeString}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={{ float: 'right', padding: '2px' }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
            <ExpandMore
              sx={{ float: 'right', padding: '2px' }}
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </CardContentSpecialPadding>
      <Collapse unmountOnExit in={expanded} timeout="auto">
        <CardContentSpecialPadding>
          <Typography gutterBottom sx={{ fontSize: 15 }}>
            Special Instructions
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            sx={{ fontSize: 13 }}
            color="text.secondary"
          >
            {specialInstructions ?? '-'}
          </Typography>
          <Typography gutterBottom sx={{ fontSize: 15 }}>
            Driver Notes
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: 13 }}
            color="text.secondary"
          >
            {driverNotes ?? '-'}
          </Typography>
        </CardContentSpecialPadding>
      </Collapse>
    </Card>
  );
};

export default EndOfDayStopDetailsCard;
