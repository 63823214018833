import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { type QuickbooksDesktopResultEntity } from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const QuickbooksDesktopMappingAutocomplete = ({
  onChange,
  currentMappingId,
  quickbooksData,
  label,
  disabled = false,
  width,
  nullOption,
}: {
  readonly onChange: (value: string | undefined) => void;
  readonly currentMappingId: string | undefined;
  readonly quickbooksData: QuickbooksDesktopResultEntity | undefined;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly width?: string;
  readonly nullOption?: { label: string; value: string };
}) => {
  const currentMapping = quickbooksData?.quickbooksDesktopObjects?.find(
    (o) => o.id === currentMappingId,
  );
  const clearable = isNil(nullOption);

  return (
    <AutocompleteFuzzy
      size="small"
      sx={{ backgroundColor: 'white', width: width ?? '500px' }}
      value={
        isNil(currentMapping)
          ? (nullOption ?? null)
          : {
              value: currentMapping.id,
              label: currentMapping.name,
            }
      }
      options={(quickbooksData?.quickbooksDesktopObjects ?? []).map(
        (object) => ({
          label: object.name,
          value: object.id,
        }),
      )}
      stickyOptionProps={
        isNil(nullOption)
          ? undefined
          : {
              stickyOption: nullOption,
              filterStickyOption: true,
            }
      }
      renderOption={(props, option) => {
        return option.value === nullOption?.value ? (
          <li
            {...props}
            style={{
              fontWeight: 'bold',
              borderBottom: '1px solid grey',
            }}
          >
            {option.label}
          </li>
        ) : (
          <li {...props}>{option.label}</li>
        );
      }}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      disabled={disabled}
      disableClearable={!clearable}
      onChange={(_, option) => {
        onChange(
          !isNil(option?.value) && option?.value !== nullOption?.value
            ? option?.value
            : undefined,
        );
      }}
    />
  );
};

export default QuickbooksDesktopMappingAutocomplete;
