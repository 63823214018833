import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { Controller, type SubmitHandler } from 'react-hook-form';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import StyledDatePicker from '../../../domains/storage-orders/components/common/styled-date-picker';
import { useMarkOrderAsRefusedMutation } from '../../../generated/graphql';
import {
  type MarkOrderAsRefusedFormValues,
  useMarkOrderAsRefusedForm,
} from '../../form/use-mark-refused-form';
import { DatePickerInput } from '../forms/date-picker-input';
import { useUpdateAndRefetchOrder } from '../../../domains/orders/components/order-form/hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../../domains/orders/components/order-form/types';

const MarkOrderAsRefusedDialog = ({
  orderUuid,
  open,
  onClose,
  onSave,
}: {
  readonly orderUuid: string;
  readonly open: boolean;
  readonly onClose: (data?: MarkOrderAsRefusedFormValues) => void;
  readonly onSave: ((params: OnSubmitParams) => Promise<boolean>) | null;
}) => {
  const { updateAndRefetchOrder, loading: updateAndRefetchOrderLoading } =
    useUpdateAndRefetchOrder();
  const [markOrderAsRefused] = useMarkOrderAsRefusedMutation();

  const {
    form: {
      control,
      formState: { errors },
      handleSubmit,
    },
  } = useMarkOrderAsRefusedForm();

  const onSubmit: SubmitHandler<MarkOrderAsRefusedFormValues> = async (
    data,
  ) => {
    // TODO: Ensure we add success and error messages once FTO-308 is complete: https://linear.app/trypallet/issue/FTO-308/design-system-ensure-alert-components-follow-proper-api-guidelines
    if (isEmpty(errors)) {
      if (onSave === null) {
        const response = await markOrderAsRefused({
          variables: {
            markOrderAsRefusedInput: {
              uuid: orderUuid,
              refusedBy: data.refusedBy,
              refusedDate: data.refusedDate,
            },
          },
        });
        if (
          response?.data?.markOrderAsRefused?.__typename ===
          'MarkOrderAsRefusedSuccessOutput'
        ) {
          onClose(data);
        }
        return;
      }

      const success = await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markOrderAsRefused,
            vars: {
              markOrderAsRefusedInput: {
                uuid: orderUuid,
                refusedBy: data.refusedBy,
                refusedDate: data.refusedDate,
              },
            },
          },
        ],
        onSubmit: onSave,
        actionString: 'marking order as refused',
      });
      if (success) {
        onClose(data);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>Mark order as refused</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
          <Controller
            name="refusedBy"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                required
                name="refusedBy"
                size="small"
                label="Refused by"
                value={value}
                error={!isNil(errors.refusedBy)}
                helperText={errors.refusedBy?.message}
                sx={{ width: '50%' }}
                onChange={onChange}
              />
            )}
          />
          <Stack>
            <Controller
              name="refusedDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledDatePicker
                  hideOnScroll
                  title="Date Refused"
                  value={value}
                  plugins={[
                    <TimePicker
                      key="time-picker"
                      hideSeconds
                      position="right"
                      format="HH:mm"
                    />,
                  ]}
                  containerStyle={{ width: '35%' }}
                  calendarPosition="right"
                  render={<DatePickerInput placeholder="Refused Date" />}
                  name="Date Refused"
                  format="MM/DD/YYYY HH:mm"
                  onChange={(newDate) => {
                    onChange(newDate?.isValid ? newDate.toDate() : null);
                  }}
                />
              )}
            />
            {!isNil(errors.refusedDate) && (
              <FormHelperText sx={{ color: '#B00020' }}>
                {errors.refusedDate?.message}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={updateAndRefetchOrderLoading}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isEmpty(errors) || updateAndRefetchOrderLoading}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { MarkOrderAsRefusedDialog };
