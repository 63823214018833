import { fuelCostSchema } from './fuel-cost-schema';
import { freightChargeOrCostBaseSchema } from './freight-charge-or-cost-base-schema';
import { z } from 'zod';

// An object that tracks freight cost attached to a settlement bill line item
// Used for driver/agent settlement. The analogous object when tracking charges for a customer is freightCharge
// See https://www.notion.so/RFC-179 for more details
export const freightCostSchema = freightChargeOrCostBaseSchema.extend({
  fuelCost: fuelCostSchema,
});
