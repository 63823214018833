import { useParams } from 'react-router-dom';
import { isNotNilOrEmptyString } from 'shared/string';
import { OrganizationPageMode } from '../domains/directory/enums';
import Organization from '../domains/directory/organization-page';

export const OrganizationPage = () => {
  const { organizationUuid } = useParams();
  if (isNotNilOrEmptyString(organizationUuid)) {
    return (
      <Organization mode={OrganizationPageMode.EDIT} uuid={organizationUuid} />
    );
  }
  return null;
};

export const AddOrganizationPage = () => (
  <Organization mode={OrganizationPageMode.CREATE} uuid={null} />
);
