import {
  Box,
  CircularProgress,
  Divider,
  Modal,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReportRow, {
  type DownloadableReport,
} from '../../../common/components/ReportRow';
import useMe from '../../../common/react-hooks/use-me';
import DownloadDockScanReport from './download-dock-scan-report';
import DownloadItemInventorySummary from './download-item-inventory-summary';

const WarehouseReportsView = () => {
  const navigate = useNavigate();
  const { companyConfiguration, loading: meLoading } = useMe();
  useEffect(() => {
    if (
      !meLoading &&
      companyConfiguration?.wmsShortTermStorageEnabled === false &&
      companyConfiguration?.wmsStorageOrdersEnabled
    ) {
      navigate('/warehouse/storage-orders');
    }
  }, [meLoading, companyConfiguration, navigate]);
  const [isDockScanReportModalOpen, setIsDockScanReportModalOpen] =
    useState(false);
  const [isItemInventorySummaryModalOpen, setIsItemInventorySummaryModalOpen] =
    useState(false);

  const reports: DownloadableReport[] = [
    {
      label: 'Dock scan report',
      onClick: () => {
        setIsDockScanReportModalOpen(true);
      },
    },
    ...(companyConfiguration?.wmsStorageOrdersEnabled === true
      ? [
          {
            label: 'Item inventory summary',
            onClick: () => {
              setIsItemInventorySummaryModalOpen(true);
            },
          },
        ]
      : []),
  ];

  if (meLoading || companyConfiguration?.wmsShortTermStorageEnabled === false)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <Box margin={2}>
      <Typography variant="h6"> Download report: </Typography>
      <TableContainer>
        <Table sx={{ width: '50%' }} size="small" aria-label="simple table">
          <TableBody>
            <Divider />
            {reports.map((item) => (
              <>
                <ReportRow report={item} />
                <Divider />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={isDockScanReportModalOpen}
        onClose={() => {
          setIsDockScanReportModalOpen(false);
        }}
      >
        <DownloadDockScanReport
          onClose={() => {
            setIsDockScanReportModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={isItemInventorySummaryModalOpen}
        onClose={() => {
          setIsItemInventorySummaryModalOpen(false);
        }}
      >
        <DownloadItemInventorySummary
          onClose={() => {
            setIsItemInventorySummaryModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
};

export default WarehouseReportsView;
