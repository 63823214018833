import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeAdd } from 'shared/math';
import { calculateDimAndRegularWeight } from 'shared/weight';
import { StopType } from '../../../generated/graphql';
import { dummyStopTypes } from '../../orders/lib/stop-types';
import {
  type OrderAirWaybillData,
  transformPackagesToPackageString,
} from '../utils';
import DisplayAddress, { getAddressStringToDisplay } from './display-address';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const ADDRESSES_HEIGHT = 50;
const PACKAGES_HEIGHT = 300;

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '10px',
    flexDirection: 'column',
    gap: '10px',
  },
  largeText: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  titleBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.1,
    marginBottom: '5px',
  },
  subtitle: {
    fontSize: '12px',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  metadataText: {
    fontSize: '8px',
    color: 'gray',
  },
  refNumText: {
    fontWeight: 'bold',
  },
  labelText: {
    fontWeight: 'bold',
    width: '20%',
  },
  infoText: {
    width: '80%',
  },
  smallLabelText: {
    fontWeight: 'bold',
    width: '30%',
  },
  smallInfoText: {
    width: '70%',
  },
  formLabelText: {
    fontWeight: 'bold',
    width: '25%',
    textAlign: 'right',
  },
  formLineTop: {
    borderTopWidth: 1,
    borderTopColor: 'black',
  },
  formLineBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  rowFlexStart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rowSpaceBetween: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  colSpaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  colWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '5px',
  },
  outerCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
  },
  labelCell: {
    borderWidth: 1,
    borderColor: 'black',
    padding: '2px',
    width: '100%',
    backgroundColor: '#f0eded',
    fontWeight: 'bold',
  },
  infoCell: {
    borderWidth: 1,
    borderColor: 'black',
    padding: '2px',
    width: '100%',
  },
  quoteFixed: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 2,
    textAlign: 'right',
    fontSize: 20,
    fontWeight: 'bold',
  },
});

const GeneratedOrderAirWaybillPdf = ({
  order,
  showCharges,
}: {
  readonly order: OrderAirWaybillData;
  readonly showCharges: boolean;
}) => {
  const serviceDate =
    order.inboundShipment?.serviceDate ?? order.outboundShipment?.serviceDate;
  const shipments = [order.inboundShipment, order.outboundShipment];
  const otherShipments = filterNotNil([
    isNil(order.orderChargesShipment)
      ? null
      : { ...order.orderChargesShipment, stopType: 'Order charges' },
    isNil(order.lineHaulShipment)
      ? null
      : { ...order.lineHaulShipment, stopType: 'Line haul' },
  ]);
  const chargeableShipments = filterNotNil([
    ...shipments.filter(
      (sh) => !dummyStopTypes.includes(sh?.stopType ?? StopType.None),
    ),
    ...otherShipments,
  ]).filter((sh) => sh.hideFromBilling !== true);

  const useKilograms = order.useKilograms === true;

  const infoCell = (
    label: string,
    info: string | null | undefined,
    width: number,
    infoHeight?: number,
    labelHeight?: number,
  ) => {
    const infoText = isEmpty(info) ? ' ' : info;
    return (
      <View
        style={[
          styles.col,
          {
            width: `${width}%`,
          },
        ]}
      >
        <View style={[styles.labelCell, { height: labelHeight ?? 20 }]}>
          <Text>{label}</Text>
        </View>
        <View style={[styles.infoCell, { height: infoHeight ?? 20 }]}>
          <Text>{infoText}</Text>
        </View>
      </View>
    );
  };

  const chargeCellRow = (
    chargeName: string | null | undefined,
    chargeTotal: number | null | undefined,
    isTotal?: boolean,
  ) => {
    return (
      <View
        style={[
          styles.rowSpaceBetween,
          { fontWeight: isTotal === true ? 'bold' : undefined },
        ]}
      >
        <Text>{chargeName}</Text>
        <Text>{currency(chargeTotal ?? 0).format()}</Text>
      </View>
    );
  };

  const contactAddress = {
    ...order.contactAddress,
    name: `${order.contactDisplayName}${
      isEmpty(order.contactAddress?.name)
        ? ''
        : `\n${order.contactAddress?.name}`
    }`,
  };

  const totalPieces = sumBy(order.packages, 'quantity');
  const { dimWeight, regularWeight } = calculateDimAndRegularWeight(
    order.packages,
    order.dimFactor ?? undefined,
  );
  const packageDescriptions = transformPackagesToPackageString({
    packages: order.packages,
    useKilograms,
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const AirWayBill = () => (
    <View wrap={false} style={styles.outerCell}>
      <View style={styles.row}>
        <View
          style={[
            styles.infoCell,
            styles.col,
            {
              width: '40%',
              height: 100,
            },
          ]}
        >
          <Text style={styles.titleBold}>{order.companyName}</Text>
          <DisplayAddress address={order.companyAddress} />
          <Text style={{ fontSize: '10px' }}>
            <Text style={styles.refNumText}>Phone: </Text>
            {order.companyPhone}
          </Text>
          <Text style={{ fontSize: '10px' }}>
            <Text style={styles.refNumText}>Email: </Text>
            {order.companyInvoiceEmail}
          </Text>
        </View>
      </View>
      <View style={styles.row}>
        {infoCell(
          'Service Date',
          isNil(serviceDate) ? '-' : dayjs(serviceDate).format('MM/DD/YY'),
          20,
        )}
        {infoCell(
          'Orig',
          order.inboundShipment?.terminalCode?.toUpperCase(),
          5,
        )}
        {infoCell(
          'Dest',
          order.outboundShipment?.terminalCode?.toUpperCase(),
          5,
        )}
        {infoCell('HAWB', order.shipperBillOfLadingNumber, 35)}
        {infoCell('Order Name', order.name, 35)}
      </View>
      <View style={styles.row}>
        {infoCell('MAWB', order.masterAirwayBillOfLadingNumber, 30)}
        {infoCell('Ref #s', order.refNumbers?.join(', '), 40)}
        {infoCell('IT/TE #', order.itTeNumber, 30)}
      </View>
      <View style={styles.row}>
        {infoCell(
          'Customer',
          getAddressStringToDisplay(contactAddress),
          40,
          ADDRESSES_HEIGHT,
        )}
        {infoCell(
          'Inbound',
          getAddressStringToDisplay(order.inboundShipment?.address),
          30,
          ADDRESSES_HEIGHT,
        )}
        {infoCell(
          'Outbound',
          getAddressStringToDisplay(order.outboundShipment?.address),
          30,
          ADDRESSES_HEIGHT,
        )}
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.col,
            {
              width: showCharges ? '60%' : '100%',
            },
          ]}
        >
          <View style={styles.row}>
            {infoCell('Pieces', totalPieces.toString(), 15, PACKAGES_HEIGHT)}
            {infoCell(
              'HM',
              order.hazmat === true ? 'X' : '',
              10,
              PACKAGES_HEIGHT,
            )}
            {infoCell(
              'Descriptions',
              packageDescriptions.join('\n'),
              60,
              PACKAGES_HEIGHT,
            )}
            <View
              style={[
                styles.col,
                {
                  width: '30%',
                  height: PACKAGES_HEIGHT + 20,
                },
              ]}
            >
              {infoCell(
                'Weight',
                regularWeight.toString() + (useKilograms ? 'kg' : 'lbs'),
                100,
                (PACKAGES_HEIGHT - 20) / 2,
              )}
              {infoCell(
                'Dim wt',
                dimWeight.toString() + (useKilograms ? 'kg' : 'lbs'),
                100,
                (PACKAGES_HEIGHT - 20) / 2,
              )}
            </View>
          </View>
        </View>
        {showCharges && (
          <View
            style={[
              styles.col,
              {
                width: '40%',
                height: PACKAGES_HEIGHT + 20,
              },
            ]}
          >
            <View style={[styles.labelCell, { height: 20 }]}>
              <Text>Charges</Text>
            </View>
            <View style={[styles.infoCell, { height: PACKAGES_HEIGHT }]}>
              {chargeableShipments.map((sh, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <View
                    style={{
                      borderTopWidth: idx > 0 ? 1 : undefined,
                      borderStyle: 'dotted',
                    }}
                  >
                    <Text style={styles.subHeaderText}>{sh?.stopType}</Text>
                    {sh?.stopType !== StopType.Recovery &&
                      !isNil(sh?.freightCharge) &&
                      chargeCellRow('Freight', sh?.freightCharge?.total)}
                    {sh?.stopType !== StopType.Recovery &&
                      !isNil(sh?.freightCharge?.fuelCharge) &&
                      chargeCellRow(
                        'Fuel',
                        sh?.freightCharge?.fuelCharge.total,
                      )}
                    {sh?.customCharges.map((charge) =>
                      chargeCellRow(
                        isEmpty(charge.name) ? 'Custom charge' : charge.name,
                        charge.total,
                      ),
                    )}
                  </View>
                );
              })}
              <View
                style={{
                  borderTopWidth: 1,
                  marginTop: 2,
                }}
              >
                {chargeCellRow(
                  'Order total',
                  chargeableShipments.reduce(
                    (prevSh, sh) =>
                      safeAdd(
                        prevSh,
                        safeAdd(
                          safeAdd(
                            sh?.freightCharge?.total ?? 0,
                            sh?.freightCharge?.fuelCharge?.total ?? 0,
                          ),
                          sh?.customCharges.reduce(
                            (prev, curr) => safeAdd(prev, curr.total ?? 0),
                            0,
                          ) ?? 0,
                        ),
                      ),
                    0,
                  ),
                  true,
                )}
              </View>
            </View>
          </View>
        )}
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.col,
            {
              width: '50%',
            },
          ]}
        >
          {infoCell('Shipper signature', '', 100)}
          {infoCell('Consignee - Received in good order', '', 100)}
          {infoCell('Consignee - Printed name', '', 100)}
        </View>
        <View
          style={[
            styles.col,
            {
              width: '50%',
            },
          ]}
        >
          {infoCell('Inbound driver', '', 100, 20)}
          <View style={styles.row}>
            {infoCell('Driver ref #', '', 30)}
            {infoCell('Date', '', 30)}
            {infoCell('Time', '', 20)}
            {infoCell('Pcs', '', 20)}
          </View>
          {infoCell('Outbound driver', '', 100, 20)}
        </View>
      </View>
      <View style={styles.row}>
        {infoCell('Date', '', 20)}
        {infoCell('Time', '', 20)}
        {infoCell('Pcs', '', 20)}
        {infoCell('Origin', '', 30)}
        {infoCell('Linehaul', order.lineHaulLane, 40)}
        {infoCell('Destination', '', 40)}
      </View>
    </View>
  );

  return (
    <Document title={`Order Air Waybill - ${order?.shipperBillOfLadingNumber}`}>
      <Page size="LETTER" style={styles.page}>
        {AirWayBill()}
      </Page>
    </Document>
  );
};
export default GeneratedOrderAirWaybillPdf;
