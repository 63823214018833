import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import {
  BaseFilterInputInputPropsSx,
  type BaseFilterInputProps,
} from '../orders/components/filters/filter-utils';

const boolOptionList = [
  { value: true, label: 'is true' },
  { value: false, label: 'is false' },
];

const FilterBoolInput = ({
  filterValueInput,
  filterNameInput,
  setFilter,
}: BaseFilterInputProps) => {
  return (
    <AutocompleteFuzzy
      sx={{ backgroundColor: 'white', width: '160px', minHeight: '32px' }}
      value={
        isNil(filterValueInput)
          ? null
          : {
              value: filterValueInput,
              label: (filterValueInput as boolean) ? 'is true' : 'is false',
            }
      }
      options={boolOptionList}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder="Value"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...BaseFilterInputInputPropsSx,
              marginLeft: '-2px',
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => (
        <li {...props} style={{ padding: '4px' }}>
          {option.label}
        </li>
      )}
      onChange={(_, selected) => {
        if (isNil(selected)) {
          setFilter({
            filter: filterNameInput,
            op: null,
            value: null,
          });
        } else {
          setFilter({
            filter: filterNameInput,
            op: 'eq',
            value: selected.value,
          });
        }
      }}
    />
  );
};

export default FilterBoolInput;
