import { z } from 'zod';
import { type Scalar } from 'shared/units/scalar';
import { FreightBillingMethod } from '../../../../../generated/graphql';

export const freightChargeOrCostBaseSchema = z.object({
  uuid: z.string().uuid(), // Correlates to uuid in freightCharge and id in freightCost
  billingMethod: z.nativeEnum(FreightBillingMethod),
  // This can represent currency or a count. When currency, this represents a
  // dollar amount
  quantity: z.number().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  rate: z.number().nullable(),
  discountRate: z.custom<Scalar>().nullish(),
  totalCharge: z.number(),
  tariffUuid: z.string().uuid().nullable(),
  errorMessage: z.string().nullable(),
  description: z.string().nullable(),
  authoCode: z.string().nullable(),
});
