import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useMemo } from 'react';
import {
  type ServiceRegionEntity,
  useMinimalServiceRegionsQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

export type ServiceRegionOption = Pick<ServiceRegionEntity, 'id' | 'name'>;

const isOptionEqualToValue = (
  option: ServiceRegionOption | '',
  value: ServiceRegionOption | '',
) =>
  typeof option !== 'string' &&
  typeof value !== 'string' &&
  option.id === value.id;

const getOptionLabel = (option: ServiceRegionOption | '') =>
  typeof option === 'string' ? '' : option.name;

type ServiceRegionSelectProps = {
  readonly value: ServiceRegionOption | null;
  readonly onChange: (value: ServiceRegionOption) => void;
  readonly required?: boolean;
  readonly disabled?: boolean;
  // Used for handling errors from loading service regions.
  readonly onError: (error: Error) => void;
  readonly size?: 'small' | 'medium';
};

// Displays an autocomplete element with active service regions.
export const ServiceRegionSelect: FunctionComponent<
  ServiceRegionSelectProps
> = ({ value, onChange, required, disabled, onError, size }) => {
  const { data, loading } = useMinimalServiceRegionsQuery({
    onError,
    variables: {
      input: { isArchived: false },
    },
  });
  const options = useMemo<ServiceRegionOption[]>(() => {
    if (isNil(data)) {
      return [];
    }
    const { serviceRegions } = data.serviceRegions;
    if (isNil(value) || serviceRegions.some((sr) => sr.id === value.id)) {
      return serviceRegions;
    }
    // The selected service region was previously saved but now it is archived.
    return [...serviceRegions, value];
  }, [data, value]);

  return (
    <AutocompleteFuzzy<ServiceRegionOption | '', false, true, false>
      disableClearable
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      matchSortOptions={{ keys: ['name'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Service Region"
          required={required}
          size={size}
        />
      )}
      options={options}
      value={value ?? ''}
      onChange={(_e, newValue) => {
        if (!isNil(newValue) && newValue !== '') {
          onChange(newValue);
        }
      }}
    />
  );
};
