import TariffChainDropdown from './tariff-chain-dropdown';
import { isNil } from 'lodash';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { useTariffChains } from '../../../../common/react-hooks/use-tariff-chains';
import { Skeleton, Stack, Box } from '@mui/material';

type TariffData = {
  defaultOrdinaryTariffChainId: string | null;
  defaultLineHaulTariffChainId: string | null;
  defaultTransferTariffChainId: string | null;
  defaultPointToPointTariffChainId: string | null;
};

type TariffChainSectionProps = {
  readonly data: TariffData;
  readonly isDisabled: boolean;
  readonly onUpdate: (field: keyof TariffData, value: string | null) => void;
};

const LoadingTariffChainSection = () => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      '& > *': {
        flex: 1,
        width: '100%',
      },
    }}
  >
    <LoadingTariffChainDropdown />
  </Stack>
);

const LoadingTariffChainDropdown = () => (
  <Box flexGrow={1}>
    <Skeleton
      variant="rectangular"
      height={32}
      animation="wave"
      sx={{
        borderRadius: 1,
        bgcolor: 'cadetblue.100',
      }}
    />
  </Box>
);

const TariffChainSection = ({
  data,
  isDisabled,
  onUpdate,
}: TariffChainSectionProps) => {
  const {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
    loading,
  } = useTariffChains();

  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  if (loading) {
    return (
      <Stack gap={2}>
        <LoadingTariffChainSection />
      </Stack>
    );
  }

  const tariffConfigs = [
    {
      key: 'ordinary',
      isAvailable: ordinaryTariffGroupAvailable,
      chains: ordinaryTariffChains,
      label: 'Default Ordinary Tariff Chain',
      valueField: 'defaultOrdinaryTariffChainId',
    },
    {
      key: 'lineHaul',
      isAvailable: lineHaulTariffGroupAvailable,
      chains: lineHaulTariffChains,
      label: 'Default Line-Haul Tariff Chain',
      valueField: 'defaultLineHaulTariffChainId',
    },
    {
      key: 'transfer',
      isAvailable: transferTariffGroupAvailable,
      chains: transferTariffChains,
      label: 'Default Transfer Tariff Chain',
      valueField: 'defaultTransferTariffChainId',
    },
    {
      key: 'pointToPoint',
      isAvailable: pointToPointTariffGroupAvailable,
      chains: pointToPointTariffChains,
      label: 'Default Point-to-Point Tariff Chain',
      valueField: 'defaultPointToPointTariffChainId',
    },
  ] as const;

  return tariffConfigs.map(
    ({ key, isAvailable, chains, label, valueField }) => {
      if (isAvailable && !isNil(chains)) {
        return (
          <TariffChainDropdown
            key={key}
            label={label}
            value={data[valueField]}
            tariffChains={chains}
            disabled={isDisabled}
            onChange={(value) => {
              onUpdate(valueField, value);
            }}
          />
        );
      }
      return null;
    },
  );
};

export default TariffChainSection;
