import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { type DefaultValues } from 'react-hook-form';
import { exhaustive } from 'shared/switch';
import {
  ShipmentType,
  StopType,
  type StandardOrderFragmentFragment,
} from '../../../../../generated/graphql';
import {
  isInboundStop,
  isOutboundStop,
  StopMethod,
} from '../../../../orders/components/order-form/forms/stop-type';
import {
  type CustomerPortalOrderFormValues,
  type StopTypeWithoutNone,
} from './types';

export const CUSTOMER_PORTAL_ORDER_DEFAULT_VALUES: DefaultValues<CustomerPortalOrderFormValues> =
  { packages: [], documents: [] };

const convertShipmentToStopInformation = (
  shipment: StandardOrderFragmentFragment['shipments'][number],
): CustomerPortalOrderFormValues['inboundInformation'] => {
  const stop = shipment.legs[0]?.endStop;
  const address = stop?.address;
  if (isNil(address) || isNil(stop)) {
    return;
  }
  // TODO: can we use Temporal for this?
  const serviceDate = isNil(stop.serviceDate as string | null)
    ? undefined
    : dayjs(stop.serviceDate as string | null);
  const deadlineDate = isNil(shipment.fields?.deadlineDate as string | null)
    ? undefined
    : dayjs(shipment.fields?.deadlineDate as string | null);
  return {
    name: address.name,
    addressLine1: address.line1,
    addressLine2: address.line2 ?? undefined,
    city: address.city,
    state: address.state,
    zipcode: address.zip,
    country: address.country,
    serviceDate,
    deadlineDate,
    specialInstructions: address.specialInstructions ?? undefined,
  };
};

/** Converts a StandardOrderFragmentFragment to CustomerPortalOrderFormValues */
export const getExistingCustomerPortalOrderDefaultValues = (
  standardOrder: StandardOrderFragmentFragment,
): DefaultValues<CustomerPortalOrderFormValues> => {
  const inboundShipment = standardOrder.shipments.find(
    (s) =>
      s.shipmentType === ShipmentType.Regular &&
      !isNil(s.legs[0]?.endStop?.stopType) &&
      isInboundStop(s.legs[0]?.endStop?.stopType),
  );
  const outboundShipment = standardOrder.shipments.find(
    (s) =>
      s.shipmentType === ShipmentType.Regular &&
      !isNil(s.legs[0]?.endStop?.stopType) &&
      isOutboundStop(s.legs[0]?.endStop?.stopType),
  );
  return {
    hawb:
      standardOrder.standardOrderFields.shipperBillOfLadingNumber ?? undefined,
    mawb:
      standardOrder.standardOrderFields.masterAirwayBillOfLadingNumber ??
      undefined,
    serviceId: standardOrder.service?.uuid ?? undefined,
    documents: standardOrder.documents.map((doc) => ({
      s3Url: doc.preSignedGetUrl,
      type: doc.type,
    })),
    packages: standardOrder.packages.map((pkg) => ({
      description: pkg.description,
      quantity: pkg.quantity,
      weight: pkg.weight ?? undefined,
      length: pkg.length ?? undefined,
      width: pkg.width ?? undefined,
      height: pkg.height ?? undefined,
      packageType: pkg.type ?? undefined,
    })),
    inboundInformation: isNil(inboundShipment)
      ? undefined
      : convertShipmentToStopInformation(inboundShipment),
    outboundInformation: isNil(outboundShipment)
      ? undefined
      : convertShipmentToStopInformation(outboundShipment),
  };
};

/**
 * Converts a CustomerPortalOrderFormValues to a CreateOrderV3Request
 *
 * TODO: should we apply the useAllCaps company config to this payload?
 */
export const getCreateOrderV3Request = ({
  orderValues,
  clientId,
  customerId,
}: {
  orderValues: CustomerPortalOrderFormValues;
  clientId: string;
  customerId: string;
}) => {
  return {
    ...orderValues,
    customerId,
    clientId,
    // If one of the stops is undefined, we can't include it in the request
    // otherwise Zod will complain about the unexpected field.
    ...(isNil(orderValues.inboundInformation)
      ? {}
      : {
          inboundInformation: {
            address: {
              name: orderValues.inboundInformation?.name,
              line1: orderValues.inboundInformation?.addressLine1,
              line2: orderValues.inboundInformation?.addressLine2,
              city: orderValues.inboundInformation?.city,
              state: orderValues.inboundInformation?.state,
              zipcode: orderValues.inboundInformation?.zipcode,
              country: orderValues.inboundInformation?.country,
            },
            serviceDate:
              orderValues.inboundInformation?.serviceDate?.format('YYYY-MM-DD'),
            deadlineDate:
              orderValues.inboundInformation?.deadlineDate?.format(
                'YYYY-MM-DD',
              ),
          },
        }),
    ...(isNil(orderValues.outboundInformation)
      ? {}
      : {
          outboundInformation: {
            address: {
              name: orderValues.outboundInformation?.name,
              line1: orderValues.outboundInformation?.addressLine1,
              line2: orderValues.outboundInformation?.addressLine2,
              city: orderValues.outboundInformation?.city,
              state: orderValues.outboundInformation?.state,
              zipcode: orderValues.outboundInformation?.zipcode,
              country: orderValues.outboundInformation?.country,
            },
            serviceDate:
              orderValues.outboundInformation?.serviceDate?.format(
                'YYYY-MM-DD',
              ),
            deadlineDate:
              orderValues.outboundInformation?.deadlineDate?.format(
                'YYYY-MM-DD',
              ),
          },
        }),
  };
};

/** Given the stop method, returns the RHF field name for inbound or outbound information */
export const getCustomerPortalOrderFormStopField = (
  stopMethod: StopMethod,
): 'inboundInformation' | 'outboundInformation' => {
  switch (stopMethod) {
    case StopMethod.Inbound: {
      return 'inboundInformation';
    }
    case StopMethod.Outbound: {
      return 'outboundInformation';
    }
    default: {
      return exhaustive(stopMethod);
    }
  }
};

/**
 * Given a stop method and the service level's default stop types,
 * returns the stop type for the given stop method.
 *
 * Since both inbound and outbound stop types are nullable on ServiceLevel,
 * we treat `None` and `null` as the same thing (and return `null`).
 */
export const getStopTypeFromServiceLevel = (
  stopMethod: StopMethod,
  inboundStopType: StopType | null,
  outboundStopType: StopType | null,
): StopTypeWithoutNone | null => {
  switch (stopMethod) {
    case StopMethod.Inbound: {
      if (inboundStopType === StopType.None) {
        return null;
      }
      return inboundStopType;
    }
    case StopMethod.Outbound: {
      if (outboundStopType === StopType.None) {
        return null;
      }
      return outboundStopType;
    }
    default: {
      return exhaustive(stopMethod);
    }
  }
};
