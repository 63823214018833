import { z } from 'zod';
import {
  AccessorialType,
  CustomChargeBillingMethod,
} from '../../../../../generated/graphql';
import { isNil } from 'lodash';

export const customChargeOrCostBaseSchema = z.object({
  uuid: z.string().uuid(), // Correlates to uuid in customCharge and id in customCost
  billingMethod: z.nativeEnum(CustomChargeBillingMethod),
  isAutoApplied: z.boolean(),

  // This can represent currency or a count. When currency, this represents a
  // dollar amount
  quantity: z.number().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  rate: z.number().nullable(),
  // This is a percentage out of 100, so a value of 33 means 33%
  fuelSurchargePercentageRate: z.number().nullable(),
  totalCharge: z.number(),
  accessorialUuid: z.string().uuid().nullable(),
  accessorialName: z.string().min(1).nullable(),
  accessorialType: z.nativeEnum(AccessorialType).nullable(),
  specialAccessorialMatrixItemUuid: z.string().uuid().nullable(),
  zoneBasedAccessorialMatrixItemUuid: z.string().uuid().nullable(),
  zoneUuid: z.string().uuid().nullable(),
  chargeGroupUuid: z.string().uuid().nullable(),
  accessorialRangeUuid: z.string().uuid().nullable(),
  isLocal: z.boolean(),
  name: z.string().nullable(),
  // Not persisted. Used to track whether the rate should be taken from the Accessorial object rather than the form.
  useAccessorialRate: z.boolean(),
  // Set when charges are finalized, cleared when unfinalized. Prefer this value. Will always be the same as fuelSurchargePercentageRate in customCost.
  postedFuelSurchargeRate: z.number().nullable(),
  description: z.string().nullable(),
  authoCode: z.string().nullable(),
});

export const refineExtendedBaseCustomSchema = <
  T extends typeof customChargeOrCostBaseSchema,
>(
  schema: T,
): z.ZodEffects<z.ZodEffects<T>> => {
  return schema
    .refine(
      (data) =>
        !(
          data.accessorialType === AccessorialType.Special &&
          isNil(data.specialAccessorialMatrixItemUuid)
        ),
      {
        message: 'Special charges must have a rate selected',
        path: ['specialAccessorialMatrixItemUuid'],
      },
    )
    .superRefine((data, ctx) => {
      if (
        data.accessorialType === AccessorialType.ZoneBased &&
        (isNil(data.zoneUuid) || isNil(data.chargeGroupUuid))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Zone and charge group are required for ${data.accessorialName ?? 'zone-based accessorials'}`,
          path: ['zoneUuid', 'chargeGroupUuid'],
        });
      }
    });
};
