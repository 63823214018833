import { Stack } from '@mui/material';
import {
  type ICellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { LinehaulDispatchTableField } from '../../generated/graphql';
import PalletLink from '../../pallet-ui/links/link/pallet-link';
import { singleLineTruncatedCellProps } from '../ag-grid/utils';
import { type SegmentedLineHaulOrder } from './types';
import {
  getLineHaulOrdersTableField,
  getLineHaulOrdersTableFieldCopy,
} from './utils';

const COLUMNS = [
  {
    field: LinehaulDispatchTableField.Name,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Name,
    ),
    minWidth: 100,
    maxWidth: 150,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      const name = params?.data?.order.name;
      return (
        <PalletLink
          sx={{
            cursor: 'pointer',
            padding: 0,
          }}
          underline="hover"
          onClick={(e) => {
            e.stopPropagation();
            const url = `/orders/?orderUuid=${params?.data?.order.uuid}&goBackAfterSave=true`;
            window.open(url, '_blank');
          }}
        >
          <Stack alignItems="center" spacing={1} direction="row">
            {name}
          </Stack>
        </PalletLink>
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Hawb,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Hawb,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Hawb,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Mawb,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Mawb,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Mawb,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Segment,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Segment,
    ),
    minWidth: 140,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Segment,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Service,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Service,
    ),
    minWidth: 140,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Service,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.ServiceDate,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.ServiceDate,
    ),
    sortable: true,
    minWidth: 100,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.ServiceDate,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Weight,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Weight,
    ),
    minWidth: 100,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Weight,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.DimWeight,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.DimWeight,
    ),
    minWidth: 130,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.DimWeight,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.InboundAddressName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.InboundAddressName,
    ),
    minWidth: 120,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.InboundAddressName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.OutboundAddressName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.OutboundAddressName,
    ),
    minWidth: 120,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.OutboundAddressName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.CustomerName,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.CustomerName,
    ),
    sortable: true,
    minWidth: 150,
    ...singleLineTruncatedCellProps,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.CustomerName,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.IsSpecial,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.IsSpecial,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.IsSpecial,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.IsHazmat,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.IsHazmat,
    ),
    minWidth: 100,
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.IsHazmat,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Lane,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Lane,
    ),
    minWidth: 120,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Lane,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.PieceCount,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.PieceCount,
    ),
    minWidth: 120,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.PieceCount,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.Tags,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.Tags,
    ),
    minWidth: 120,
    cellStyle: { textAlign: 'center' },
    cellRenderer: (params: ICellRendererParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.Tags,
        params.data,
      );
    },
  },
  {
    field: LinehaulDispatchTableField.DeadlineDate,
    headerName: getLineHaulOrdersTableFieldCopy(
      LinehaulDispatchTableField.DeadlineDate,
    ),
    minWidth: 120,
    valueGetter: (params: ValueGetterParams<SegmentedLineHaulOrder>) => {
      return getLineHaulOrdersTableField(
        LinehaulDispatchTableField.DeadlineDate,
        params.data,
      );
    },
  },
];

export default COLUMNS;
