import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { captureException } from '@sentry/react';
import dayjs from 'dayjs';
import { isEmpty, isNil, sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { assertNotNil } from 'shared/optional';
import SingleSelectFilterButton from '../../../../../common/components/single-select-filter-button';
import useDocuments from '../../../../../common/react-hooks/use-documents';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  BillingCycleDuration,
  CurrentBillingPeriodInvoiceDocument,
  InvoiceSendJobBatchType,
  InvoiceTransmissionMethod,
  InvoicesByUuidsDocument,
  useContactsForInvoicingLazyQuery,
  useHandleMultipleInvoices210OutboundMutation,
  useSendInvoicesMutation,
  useSenderIsVerifiedQuery,
  useShallowInvoicesByUuidsQuery,
} from '../../../../../generated/graphql';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import PostAndSendInvoicePreviewCard from './post-and-send-invoice-preview-card';

const DEFAULT_INVOICES_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25];

type PostAndSendModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly invoiceUuids: string[];
  readonly onPostAndSend: () => void;
  readonly setIsHeaderCheckboxSelected: Dispatch<SetStateAction<boolean>>;
};

const PostAndSendModal = ({
  isOpen,
  setIsOpen,
  invoiceUuids,
  onPostAndSend,
  setIsHeaderCheckboxSelected,
}: PostAndSendModalProps) => {
  const { companyData, loading: meLoading } = useMe();
  const confirm = useConfirm();
  const invoicesToSendOptions = useInvoicesStore(
    (state) => state.invoicesToSendOptions,
  );
  const createInvoicesToSendOptions = useInvoicesStore(
    (state) => state.createInvoicesToSendOptions,
  );
  const setShouldRefreshInvoiceList = useInvoicesStore(
    (state) => state.setShouldRefreshInvoiceList,
  );
  const setShowInvoiceSendMenu = useInvoicesStore(
    (state) => state.setShowInvoiceSendMenu,
  );
  const deselectAllInvoiceUuids = useInvoicesStore(
    (state) => state.deselectAllInvoiceUuids,
  );
  const {
    documentTypesForSelection,
    loading: documentTypesForSelectionLoading,
  } = useDocuments();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [shouldSetInvoiceDate, setShouldSetInvoiceDate] =
    useState<boolean>(false);
  const [showEdiSuccessNotification, setShowEdiSuccessNotification] =
    useState<boolean>(false);
  const [showEdiError, setShowEdiError] = useState<boolean>(false);
  const [showFinalizeSuccess, setShowFinalizeSuccess] =
    useState<boolean>(false);
  const [showFinalizeError, setShowFinalizeError] = useState<boolean>(false);
  const [sendErrorMessage, setSendErrorMessage] = useState<string | null>(null);
  const [billingCycleDuration, setBillingCycleDuration] =
    useState<BillingCycleDuration>();
  const [cutoffDate, setCutoffDate] = useState<Date>(new Date());

  const [getContacts, { data: contactsData }] =
    useContactsForInvoicingLazyQuery();
  const { data: companySenderIsVerified, loading: senderIsVerifiedLoading } =
    useSenderIsVerifiedQuery();
  const { data: invoicesData, loading: isInvoicesDataLoading } =
    useShallowInvoicesByUuidsQuery({
      variables: { uuids: invoiceUuids },
    });
  const [sendMultipleInvoicesEdi] =
    useHandleMultipleInvoices210OutboundMutation({
      refetchQueries: [
        CurrentBillingPeriodInvoiceDocument,
        InvoicesByUuidsDocument,
      ],
    });
  const [sendInvoices] = useSendInvoicesMutation({
    refetchQueries: [
      CurrentBillingPeriodInvoiceDocument,
      InvoicesByUuidsDocument,
    ],
  });

  const [page, setPage] = useState<number>(0);
  const [invoicesPerPage, setInvoicesPerPage] = useState<number>(
    DEFAULT_INVOICES_PAGE_SIZE,
  );

  const filteredInvoices = useMemo(() => {
    setPage(0);
    if (!isOpen || isInvoicesDataLoading || isNil(invoicesData)) return [];
    const filteredByBillingCycleDuration = invoicesData.invoicesByUuids.filter(
      (invoice) => {
        if (isNil(billingCycleDuration)) return true;
        return (
          invoice.billToContact.billingCycleDuration === billingCycleDuration
        );
      },
    );
    return filteredByBillingCycleDuration.filter((invoice) => {
      if (isNil(cutoffDate)) return true;
      return dayjs(invoice.date).startOf('day').toDate() <= cutoffDate;
    });
  }, [
    isOpen,
    isInvoicesDataLoading,
    invoicesData,
    billingCycleDuration,
    cutoffDate,
  ]);

  useEffect(() => {
    if (isOpen && !isNil(filteredInvoices)) {
      const fetchContactsAndCreateInvoicesToSendOptions = async () => {
        const res = await getContacts({
          variables: {
            invoiceUuids,
          },
        });
        createInvoicesToSendOptions(filteredInvoices, res.data?.contacts);
      };
      fetchContactsAndCreateInvoicesToSendOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, filteredInvoices]);

  const handleClose = () => {
    setPage(0);
    setInvoicesPerPage(DEFAULT_INVOICES_PAGE_SIZE);
    setIsOpen(false);
    deselectAllInvoiceUuids();
    setIsHeaderCheckboxSelected(false);
  };

  const shouldDisableModalActions = useMemo(
    () =>
      isInvoicesDataLoading ||
      isEmpty(filteredInvoices) ||
      isSending ||
      documentTypesForSelectionLoading ||
      meLoading ||
      senderIsVerifiedLoading,
    [
      isInvoicesDataLoading,
      filteredInvoices,
      isSending,
      documentTypesForSelectionLoading,
      meLoading,
      senderIsVerifiedLoading,
    ],
  );

  const sendEdi = async () => {
    const ediInvoiceUuids = invoicesToSendOptions
      .filter(
        (option) =>
          option.invoiceTransmissionMethod === InvoiceTransmissionMethod.Edi,
      )
      .map((option) => option.invoiceUuid);

    if (ediInvoiceUuids.length > 0) {
      const res = await sendMultipleInvoicesEdi({
        variables: {
          handleMultiple210OutboundInput: { invoiceUuids: ediInvoiceUuids },
        },
      });

      if (res.data?.handleMultipleInvoices210Outbound === true) {
        setShowEdiSuccessNotification(true);
      } else {
        setShowEdiError(true);
      }
    }
  };

  const onFinishPostAndOrSend = () => {
    onPostAndSend();
    setIsSending(false);
    setIsOpen(false);
    confirm({
      title: '',
      description: (
        <Typography>
          Please wait while we generate and send your invoices. You can check
          the progress and download invoices{' '}
          <PalletLink
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setShowInvoiceSendMenu(true);
            }}
          >
            here
          </PalletLink>
        </Typography>
      ),
      hideCancelButton: true,
      confirmationText: `Done`,
    }).then(() => {});
    setShouldRefreshInvoiceList(true);
  };

  const postAndSend = async () => {
    setIsSending(true);
    try {
      // this should never happen since the button is disabled if documentTypesForSelection is nil
      // but this is a safe guard
      assertNotNil(documentTypesForSelection);

      await sendInvoices({
        variables: {
          sendInvoicesInput: {
            invoiceSendJobBatchType: InvoiceSendJobBatchType.PostAndSend,
            invoicesToSend: invoicesToSendOptions.map(
              (invoiceToSendOption) => ({
                uuid: invoiceToSendOption.invoiceUuid,
                invoiceTransmissionMethod:
                  invoiceToSendOption.invoiceTransmissionMethod,
                invoiceType: invoiceToSendOption.invoiceDownloadType,
                emails: invoiceToSendOption.invoiceSelectedEmails,
                emailSubject: invoiceToSendOption.invoiceEmailSubject,
                note: invoiceToSendOption.invoiceNote,
                attachments: invoiceToSendOption.invoiceAttachments,
              }),
            ),
            invoiceDate: shouldSetInvoiceDate ? invoiceDate : undefined,
          },
        },
      });
      sendEdi();
      onFinishPostAndOrSend();
    } catch (error) {
      setSendErrorMessage(
        'There was an error sending the invoices. Please try again or contact support.',
      );
      setIsSending(false);
      captureException(error, {
        extra: {
          invoicesToSendOptions,
          documentTypesForSelection,
          shouldSetInvoiceDate,
          invoiceDate,
        },
      });
    }
  };

  const send = async () => {
    setIsSending(true);
    try {
      assertNotNil(documentTypesForSelection);

      await sendInvoices({
        variables: {
          sendInvoicesInput: {
            invoiceSendJobBatchType: InvoiceSendJobBatchType.Send,
            invoicesToSend: invoicesToSendOptions.map(
              (invoiceToSendOption) => ({
                uuid: invoiceToSendOption.invoiceUuid,
                invoiceTransmissionMethod:
                  invoiceToSendOption.invoiceTransmissionMethod,
                invoiceType: invoiceToSendOption.invoiceDownloadType,
                emails: invoiceToSendOption.invoiceSelectedEmails,
                emailSubject: invoiceToSendOption.invoiceEmailSubject,
                note: invoiceToSendOption.invoiceNote,
                attachments: invoiceToSendOption.invoiceAttachments,
              }),
            ),
            invoiceDate: shouldSetInvoiceDate ? invoiceDate : undefined,
          },
        },
      });
      sendEdi();
      onFinishPostAndOrSend();
    } catch {
      setSendErrorMessage(
        'There was an error sending the invoices. Please try again or contact support.',
      );
      captureException('[post-and-send-modal] Error sending invoices', {
        level: 'error',
        extra: {
          invoicesToSendOptions,
          documentTypesForSelection,
          shouldSetInvoiceDate,
          invoiceDate,
        },
      });
    }
  };

  const blockSendingEmails =
    !isNil(companyData?.invoiceEmail) &&
    companySenderIsVerified?.senderIsVerified !== 'true' &&
    !senderIsVerifiedLoading;

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <Box sx={[styles.modal, { width: '75vw' }]}>
          {documentTypesForSelectionLoading ? (
            <Stack height="100%" display="flex">
              <CircularProgress />
            </Stack>
          ) : (
            <Stack height="100%" display="flex">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                    Post / Send invoices
                    {!isInvoicesDataLoading && ` (${filteredInvoices.length})`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pb={2}
                width="100%"
              >
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                  px={2}
                >
                  <Stack direction="row" gap={2} alignItems="center">
                    <SingleSelectFilterButton
                      enableDefaultFilter
                      filterTitle="Billing cycle: "
                      option={billingCycleDuration}
                      handleChange={setBillingCycleDuration}
                      defaultFilterTitle="All"
                      options={Object.values(BillingCycleDuration)}
                    />
                    <FormControl>
                      <DatePicker
                        label="Cutoff date"
                        renderInput={(props) => (
                          <TextField
                            size="small"
                            {...props}
                            sx={{ maxWidth: 200 }}
                          />
                        )}
                        value={cutoffDate}
                        onChange={(newDate) => {
                          if (!isNil(newDate)) {
                            setCutoffDate(newDate);
                          }
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <TablePagination
                  rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                  labelRowsPerPage="Show"
                  component="div"
                  count={filteredInvoices.length}
                  page={page}
                  rowsPerPage={invoicesPerPage}
                  onPageChange={(_, newPage) => {
                    setPage(newPage);
                  }}
                  onRowsPerPageChange={(e) => {
                    setInvoicesPerPage(Number.parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </Stack>
              <Stack sx={{ flex: 1, overflowY: 'scroll' }}>
                <Grid container alignItems="flex-end" spacing={2}>
                  <Grid item xs={12}>
                    {isInvoicesDataLoading ? (
                      <Stack direction="row" justifyContent="center" mt={32}>
                        <CircularProgress />
                      </Stack>
                    ) : (
                      sortBy(filteredInvoices, [
                        'billToContact.displayName',
                        'journalNumber',
                      ])
                        .slice(
                          page * invoicesPerPage,
                          (page + 1) * invoicesPerPage,
                        )
                        .map((invoice) => (
                          <PostAndSendInvoicePreviewCard
                            key={invoice.uuid}
                            invoice={invoice}
                            contact={contactsData?.contacts?.find(
                              (c) => c.uuid === invoice.billToContact.uuid,
                            )}
                            documentTypesForSelection={
                              documentTypesForSelection
                            }
                          />
                        ))
                    )}
                  </Grid>
                </Grid>
              </Stack>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                  py={1}
                >
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FormControlLabel
                      sx={{ ml: '4px' }}
                      control={
                        <Checkbox
                          checked={shouldSetInvoiceDate}
                          onChange={(e) => {
                            setShouldSetInvoiceDate(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '15px' }}>
                          Change date
                        </Typography>
                      }
                    />
                    {shouldSetInvoiceDate && (
                      <DatePicker
                        label="Invoice date"
                        renderInput={(props) => (
                          <TextField
                            size="small"
                            sx={{ maxWidth: 200 }}
                            {...props}
                          />
                        )}
                        value={invoiceDate}
                        disabled={
                          !shouldSetInvoiceDate || shouldDisableModalActions
                        }
                        onChange={(newDate) => {
                          if (!isNil(newDate)) {
                            setInvoiceDate(newDate);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                  <Stack direction="row" spacing={2}>
                    <Button
                      size="large"
                      variant="contained"
                      color="info"
                      disabled={shouldDisableModalActions}
                      endIcon={isSending && <CircularProgress size={17} />}
                      onClick={send}
                    >
                      Send Only
                    </Button>
                    <Button
                      size="large"
                      variant="contained"
                      disabled={shouldDisableModalActions}
                      endIcon={isSending && <CircularProgress size={17} />}
                      onClick={postAndSend}
                    >
                      Post and Send
                    </Button>
                  </Stack>
                </Stack>
                {blockSendingEmails && (
                  <Typography
                    sx={{ textAlign: 'right' }}
                    color="error"
                    variant="caption"
                  >
                    The email {companyData?.invoiceEmail} is not verified. Go to
                    Company {`>`} Billing
                    <br /> and click {`"`}Save and Verify{`"`} to resend a
                    verification email.
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </Modal>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showEdiSuccessNotification || showFinalizeSuccess}
        onClose={() => {
          setShowEdiSuccessNotification(false);
          setShowFinalizeSuccess(false);
        }}
      >
        <Stack spacing={2}>
          {showFinalizeSuccess && (
            <Alert
              onClose={() => {
                setShowFinalizeSuccess(false);
              }}
            >
              Invoices finalized
            </Alert>
          )}
          {showEdiSuccessNotification && (
            <Alert
              onClose={() => {
                setShowEdiSuccessNotification(false);
              }}
            >
              Invoice EDI(s) sent
            </Alert>
          )}
        </Stack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showEdiError || showFinalizeError}
        onClose={() => {
          setShowEdiError(false);
          setShowFinalizeSuccess(false);
        }}
      >
        <Stack>
          {showFinalizeError && (
            <Alert
              severity="error"
              onClose={() => {
                setShowFinalizeError(false);
              }}
            >
              Error finalizing invoices
            </Alert>
          )}
          {showEdiError && (
            <Alert
              sx={{ marginLeft: '10px' }}
              severity="error"
              onClose={() => {
                setShowEdiError(false);
              }}
            >
              Error sending EDI invoices (status code 210)
            </Alert>
          )}
        </Stack>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(sendErrorMessage)}
        onClose={() => {
          setSendErrorMessage(null);
        }}
      >
        <Stack>
          <Alert
            sx={{ marginLeft: '10px' }}
            severity="error"
            onClose={() => {
              setSendErrorMessage(null);
            }}
          >
            {sendErrorMessage}
          </Alert>
        </Stack>
      </Snackbar>
    </>
  );
};

export default PostAndSendModal;
