import {
  Button,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import type React from 'react';
import { useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { useDebounce } from 'use-debounce';

import CSVDownloadButton from '../../common/components/buttons/csv-download-button';
import CenteredCircularProgress from '../../common/components/centered-circular-progress';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useContactsReportLazyQuery,
} from '../../generated/graphql';
import { formatContactsReportToCSV } from '../contacts/utils';
import { ActiveOrArchivedTabs } from '../management/components/common/active-or-archived-tabs';
import { ActiveOrArchivedTab } from '../management/components/common/enums';
import { shallow } from 'zustand/shallow';
import { useOrganizationsForTable } from './use-organizations-for-table';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';

const DEFAULT_PAGE_SIZE = 20;

const DirectoryPage = () => {
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );

  const { userPermissions } = useUserRoles();

  // for now we will use the contacts permission.
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );
  const navigate = useNavigate();

  const [getContactsReport] = useContactsReportLazyQuery();

  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 200);
  const [isActiveOrArchivedFilter, setIsActiveOrArchivedFilter] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  const ffReplaceCustomersTabWithDirectoryTabInNav = useFeatureFlag(
    FeatureFlag.FF_REPLACE_CUSTOMERS_TAB_WITH_DIRECTORY_TAB_IN_NAV,
  );

  const {
    edges,
    pageInfo,
    totalCount,
    loading,
    refetch: fetchOrganizations,
  } = useOrganizationsForTable({
    first: DEFAULT_PAGE_SIZE,
    searchText: debouncedSearchText,
    isActive: isActiveOrArchivedFilter === ActiveOrArchivedTab.ACTIVE,
    // nextFetchPolicy is required so that onCompleted is called on refetch
    nextFetchPolicy: 'cache-and-network',
  });

  const organizations = useMemo(() => edges?.map((e) => e.node) ?? [], [edges]);

  const [page, setPage] = useState<number>(0);

  const prev = async () => {
    await fetchOrganizations({
      last: DEFAULT_PAGE_SIZE,
      before: pageInfo?.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchOrganizations({
      first: DEFAULT_PAGE_SIZE,
      after: pageInfo?.endCursor ?? undefined,
    });
  };

  const handlePageChange = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      void next();
    } else if (newPage < page) {
      void prev();
    }
    setPage(newPage);
  };

  const handleRowClick = (uuid: string) => {
    navigate(`/directory/${uuid}?edit=true`);
  };

  // We need to make sure we've loaded feature flags before we render the inner component so that we don't render the wrong
  // page for a moment.
  if (statsigLoading) {
    return <CenteredCircularProgress />;
  }

  if (!ffReplaceCustomersTabWithDirectoryTabInNav) {
    return <Navigate to="/contacts" />;
  }

  return (
    <Stack sx={{ height: '100%' }} p={1} gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ActiveOrArchivedTabs
          activeTab={isActiveOrArchivedFilter}
          setTab={setIsActiveOrArchivedFilter}
          activeLabel="Active accounts"
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TextField
          sx={{ width: '30%' }}
          size="small"
          label="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Stack justifyContent="flex-end" direction="row" gap={1}>
          <CSVDownloadButton
            getData={async () => {
              const { data } = await getContactsReport();
              return formatContactsReportToCSV(data);
            }}
            filename="contacts.csv"
            label="Download CSV"
            buttonProps={{ variant: 'contained' }}
            reportType="contacts"
            dataForTimingLog={{}}
          />
          <Button
            variant="contained"
            disabled={!canWriteContacts}
            onClick={() => {
              navigate('/directory/add');
            }}
          >
            Add account
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <TablePagination
          rowsPerPageOptions={[]}
          labelRowsPerPage="Show"
          component="div"
          count={totalCount ?? 0}
          rowsPerPage={DEFAULT_PAGE_SIZE}
          page={page}
          slotProps={{
            actions: {
              previousButton: {
                disabled: loading || page === 0,
              },
              nextButton: {
                disabled:
                  loading ||
                  totalCount === 0 ||
                  page + 1 === Math.ceil((totalCount ?? 0) / DEFAULT_PAGE_SIZE),
              },
            },
          }}
          onPageChange={handlePageChange}
        />
      </Stack>

      <TableContainer>
        <Table stickyHeader aria-label="customer-preview-table" size="small">
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Account ID</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {loading ? (
            <CenteredCircularProgress />
          ) : (
            <TableBody>
              {organizations.map((organization) => {
                return (
                  <TableRow
                    key={organization.uuid}
                    hover
                    sx={{
                      cursor: 'pointer',
                      '& .action-button': {
                        opacity: 0,
                      },
                      '&:hover .action-button': {
                        opacity: 1,
                      },
                    }}
                    onClick={() => {
                      handleRowClick(organization.uuid);
                    }}
                  >
                    <TableCell>{organization.name}</TableCell>
                    <TableCell>{organization.referenceNumber}</TableCell>
                    <TableCell align="right">
                      <Button
                        className="action-button"
                        variant="contained"
                        size="small"
                        sx={{ transition: 'opacity 0.2s' }}
                        onClick={() => {
                          handleRowClick(organization.uuid);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DirectoryPage;
