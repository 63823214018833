import FileOpenIcon from '@mui/icons-material/FileOpen';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import pako from 'pako';
import React, { useEffect, useMemo, useState } from 'react';
import { defaultStyles, JsonView } from 'react-json-view-lite';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import { base64ToUint8Array } from '../../../../../common/base-64-to-uint8-array';
import useDocuments from '../../../../../common/react-hooks/use-documents';
import useMe from '../../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import {
  EmailTransactionStatus,
  InvoiceSendJobError,
  type InvoiceSendJobFragment,
  InvoiceSendJobStatus,
  InvoiceTransmissionMethod,
  PermissionResource,
} from '../../../../../generated/graphql';
import { convertInvoiceDownloadDocumentsToInvoiceAttachmentsNew } from '../../../utils';
import EmailTransactionStatusChip from '../transmissions/emails/email-transaction-status-chip';
import InvoiceEmailTransactionLogStatuses from '../transmissions/emails/invoice-email-transaction-log-statuses';
import InvoiceJobStatusChip from './invoice-job-status-chip';

const DerivedInvoiceSendJobStatusChip = ({
  invoiceSendJob,
}: {
  readonly invoiceSendJob: InvoiceSendJobFragment;
}) => {
  switch (invoiceSendJob.invoiceTransmissionMethod) {
    case InvoiceTransmissionMethod.Edi: {
      return <InvoiceJobStatusChip status={InvoiceSendJobStatus.Success} />;
    }
    case InvoiceTransmissionMethod.Download: {
      if (
        isNil(invoiceSendJob.document) &&
        isNil(invoiceSendJob.invoiceSendJobError)
      ) {
        return (
          <InvoiceJobStatusChip status={InvoiceSendJobStatus.InProgress} />
        );
      }
      return (
        <InvoiceJobStatusChip
          status={
            isNil(invoiceSendJob.document)
              ? InvoiceSendJobStatus.Failed
              : InvoiceSendJobStatus.Success
          }
        />
      );
    }
    case InvoiceTransmissionMethod.Email:
    case InvoiceTransmissionMethod.EmailAndDownload: {
      if (!isNil(invoiceSendJob.invoiceSendJobError)) {
        return <InvoiceJobStatusChip status={InvoiceSendJobStatus.Failed} />;
      }
      if (!isNil(invoiceSendJob.emailTransaction)) {
        return (
          <EmailTransactionStatusChip
            status={invoiceSendJob.emailTransaction?.emailTransactionStatus}
          />
        );
      }
      return <InvoiceJobStatusChip status={InvoiceSendJobStatus.InProgress} />;
    }
    case InvoiceTransmissionMethod.None: {
      return <InvoiceJobStatusChip status={InvoiceSendJobStatus.Success} />;
    }
    default: {
      return exhaustive(invoiceSendJob.invoiceTransmissionMethod);
    }
  }
};

const InvoiceSendJobListRow = ({
  invoiceSendJob,
}: {
  readonly invoiceSendJob: InvoiceSendJobFragment;
}) => {
  const { companyConfiguration } = useMe();

  const [showJsonDialog, setShowJsonDialog] = useState<boolean>(false);
  const [parsedDecompressedChargesObject, setParsedDecompressedChargesObject] =
    useState<string | undefined>();

  const errorString = useMemo(() => {
    if (!isNil(invoiceSendJob.invoiceSendJobError)) {
      return sentenceCase(invoiceSendJob.invoiceSendJobError);
    }
    if (
      invoiceSendJob.emailTransaction?.emailTransactionStatus ===
      EmailTransactionStatus.Failed
    ) {
      return invoiceSendJob.emailTransaction.emailTransactionLogs
        .map((log) => log.reason)
        .join('\n');
    }
    return '';
  }, [invoiceSendJob]);

  const { userPermissions } = useUserRoles();
  const { documentTypesForSelection } = useDocuments();
  const { hasMasterPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsRoles,
  );

  useEffect(() => {
    const decompressChargesObject = async () => {
      const { invoiceChargesObject } = invoiceSendJob;
      if (hasMasterPermission && !isNil(invoiceChargesObject)) {
        const decompressed = await pako.inflate(
          base64ToUint8Array(invoiceChargesObject),
        );
        const decoder = new TextDecoder('utf-8');
        setParsedDecompressedChargesObject(decoder.decode(decompressed));
      }
    };

    decompressChargesObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMasterPermission]);

  return (
    <TableRow>
      <TableCell>{invoiceSendJob.invoice.billToContact.displayName}</TableCell>
      <TableCell>
        {companyConfiguration?.useJournalNumberForInvoice === true
          ? invoiceSendJob.invoice.journalNumber
          : invoiceSendJob.invoice.name}
      </TableCell>
      <TableCell>
        <Stack spacing={0.5} direction="row">
          {!isNil(documentTypesForSelection) && (
            <Chip
              size="small"
              label={convertInvoiceDownloadDocumentsToInvoiceAttachmentsNew({
                documents: invoiceSendJob.invoiceAttachmentDocumentTypes,
                documentTypesForSelection,
              })}
            />
          )}
        </Stack>
      </TableCell>
      <TableCell>
        {sentenceCase(invoiceSendJob.invoiceTransmissionMethod)}
      </TableCell>
      <TableCell align="center">
        <DerivedInvoiceSendJobStatusChip invoiceSendJob={invoiceSendJob} />
      </TableCell>
      <TableCell>
        <InvoiceEmailTransactionLogStatuses
          emailTransactionLogs={
            invoiceSendJob.emailTransaction?.emailTransactionLogs ?? []
          }
        />
      </TableCell>
      <TableCell>{errorString}</TableCell>
      <TableCell align="right">
        {invoiceSendJob.invoiceSendJobError ===
        InvoiceSendJobError.DocumentGenerationFailed ? (
          <Tooltip title="Failed to generate">
            <IconButton disableFocusRipple>
              <WarningIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          !isNil(invoiceSendJob.document?.preSignedGetUrl) && (
            <Tooltip title="View invoice">
              <a
                href={invoiceSendJob.document?.preSignedGetUrl}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton color="info">
                  <FileOpenIcon />
                </IconButton>
              </a>
            </Tooltip>
          )
        )}
      </TableCell>
      {hasMasterPermission && (
        <TableCell>
          <Button
            size="small"
            disabled={isNil(parsedDecompressedChargesObject)}
            onClick={() => {
              setShowJsonDialog(true);
            }}
          >
            View JSON
          </Button>
        </TableCell>
      )}
      {hasMasterPermission && !isNil(parsedDecompressedChargesObject) && (
        <Dialog
          open={showJsonDialog}
          onClose={() => {
            setShowJsonDialog(false);
          }}
        >
          <DialogTitle>Invoice Charges Data</DialogTitle>
          <JsonView
            data={JSON.parse(parsedDecompressedChargesObject)}
            style={defaultStyles}
          />
        </Dialog>
      )}
    </TableRow>
  );
};

export default React.memo(InvoiceSendJobListRow);
