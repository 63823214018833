import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import useBillingReviewStore from '../../billing-review-store';
import PostFinalizedModalV2 from './post-finalized-modal-v2';
import AddOrdersToInvoicesModal from './add-orders-to-invoices/add-orders-to-invoices-modal';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';

const SelectAndSendOrders = () => {
  const [showSendFinalizedModal, setShowSendFinalizedModal] = useState(false);
  const [
    selectedOrdersToSendShipments,
    clearOrdersToSendShipments,
    isSelectingOrdersToSend,
    setIsSelectingOrdersToSendInReviewModal,
    setOpenedOutstandingOrderUuid,
    outstandingOrdersInPage,
    setOutstandingOrdersInPage,
    removeSearchedOrdersByUuids,
  ] = useBillingReviewStore(
    (state) => [
      state.selectedOrdersToSendShipments,
      state.clearOrdersToSendShipments,
      state.isSelectingOrdersToSendInReviewModal,
      state.setIsSelectingOrdersToSendInReviewModal,
      state.setOpenedOutstandingOrderUuid,
      state.outstandingOrdersInPage,
      state.setOutstandingOrdersInPage,
      state.removeSearchedOrdersByUuids,
    ],
    shallow,
  );

  const enableSendOrdersToBillingFlow = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_SEND_ORDERS_TO_BILLING_FLOW,
  );

  // added order uuids is passed in if the component using the callback
  // knows which orders were added. In the case of the old modal it did not
  // and would just clear all the selected orders. The new modal knows
  // which orders were added successfully.
  const onSuccessfulAddOrdersToInvoices = (addedOrderUuids?: string[]) => {
    const addOrderUuidsToUse =
      addedOrderUuids ??
      selectedOrdersToSendShipments.map((order) => order.uuid);

    setOpenedOutstandingOrderUuid(undefined);

    const newOutstandingOrdersInPage = outstandingOrdersInPage.filter(
      (o) => !addOrderUuidsToUse.includes(o.uuid),
    );
    setOutstandingOrdersInPage(newOutstandingOrdersInPage);
    removeSearchedOrdersByUuids(addOrderUuidsToUse);
    clearOrdersToSendShipments();
    setIsSelectingOrdersToSendInReviewModal(false);
  };

  const numberOfOrdersToSend = uniqBy(
    selectedOrdersToSendShipments,
    (order) => order.uuid,
  ).length;

  return (
    <>
      {isSelectingOrdersToSend ? (
        <Stack direction="row" alignItems="center">
          <Button
            color="info"
            disabled={numberOfOrdersToSend === 0}
            onClick={() => {
              setShowSendFinalizedModal(true);
            }}
          >
            Send orders ({numberOfOrdersToSend})
          </Button>
          <Tooltip title="Cancel">
            <IconButton
              size="small"
              onClick={() => {
                setIsSelectingOrdersToSendInReviewModal(false);
                clearOrdersToSendShipments();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Orders must be marked as ready to invoice in order to be selected">
          <Button
            color="info"
            onClick={() => {
              setIsSelectingOrdersToSendInReviewModal(true);
            }}
          >
            Select orders to send
          </Button>
        </Tooltip>
      )}
      {isSelectingOrdersToSend &&
        !isNil(selectedOrdersToSendShipments) &&
        (enableSendOrdersToBillingFlow ? (
          <AddOrdersToInvoicesModal
            isOpen={showSendFinalizedModal}
            setIsOpen={setShowSendFinalizedModal}
            selectedOrderUuids={selectedOrdersToSendShipments.map(
              (order) => order.uuid,
            )}
            onCloseAfterSuccessfulSend={onSuccessfulAddOrdersToInvoices}
          />
        ) : (
          <PostFinalizedModalV2
            isOpen={showSendFinalizedModal}
            setIsOpen={setShowSendFinalizedModal}
            selectedOrderUuids={selectedOrdersToSendShipments.map(
              (order) => order.uuid,
            )}
            onClose={() => {}}
            onSubmit={onSuccessfulAddOrdersToInvoices}
          />
        ))}
    </>
  );
};

export default React.memo(SelectAndSendOrders);
