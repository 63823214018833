import { z } from 'zod';
import { FuelBillingMethod } from '../../../../../generated/graphql';

export const fuelChargeOrCostBaseSchema = z.object({
  uuid: z.string().uuid(), // Correlates to uuid in fuelCharge and id in fuelCost
  billingMethod: z.nativeEnum(FuelBillingMethod),
  description: z.string().nullable(),
  authoCode: z.string().nullable(),
  // This is a percentage out of 100, so a value of 33 means 33%
  surchargeRate: z.number().nullable(),
  // This is a dollar amount, e.g. 1.50 means a rate of $1.50 per X
  flatRateDollars: z.number().nullable(),
  totalCharge: z.number(),
});
