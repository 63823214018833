import {
  Alert,
  Box,
  Button,
  Fade,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Snackbar,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  type AutoApplyAccessorialConfigFragment,
  AutoApplyAccessorialRuleType,
  PermissionResource,
  useAutoApplyAccessorialConfigsQuery,
} from '../../generated/graphql';
import AddAutoApplyAccessorialRuleModal from './components/add-auto-apply-accessorial-rule-modal';
import EditAutoApplyAccessorialRuleModal from './components/edit-auto-apply-accessorial-rule-modal';

const AccessorialsTab = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsAutoApplyAccessorialRules } =
    getPermissionsFlags(
      userPermissions,
      PermissionResource.SettingsAutoApplyAccessorialRules,
    );

  const [hoveredConfigUuid, setHoveredConfigUuid] = useState<string>();
  const [addRuleModalOpen, setAddRuleModalOpen] = useState(false);
  const [editRuleModalUuid, setEditRuleModalUuid] = useState<
    string | undefined
  >();

  const [showAddedConfig, setShowAddedConfig] = useState(false);
  const [showEditedConfig, setShowEditedConfig] = useState(false);
  const [failedToAddConfig, setFailedToAddConfig] = useState(false);
  const [failedToEditConfig, setFailedToEditConfig] = useState(false);

  const { data: autoApplyAccessorialConfigsData } =
    useAutoApplyAccessorialConfigsQuery();

  const targetValueForConfig = (config: AutoApplyAccessorialConfigFragment) => {
    switch (config.ruleType) {
      case AutoApplyAccessorialRuleType.Contact: {
        return config?.contact?.displayName;
      }
      case AutoApplyAccessorialRuleType.Service: {
        return sentenceCase(config?.service?.name ?? '');
      }
      case AutoApplyAccessorialRuleType.StopType: {
        return sentenceCase(config?.stopType ?? '');
      }
      case AutoApplyAccessorialRuleType.ContactAndService: {
        return (
          <Stack>
            <Box> Contact: {config?.contact?.displayName}</Box>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
          </Stack>
        );
      }
      case AutoApplyAccessorialRuleType.ContactAndServiceAndTag: {
        return (
          <Stack>
            <Box> Contact: {config?.contact?.displayName}</Box>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
            <Box> Tag: {config?.tag?.value}</Box>
          </Stack>
        );
      }
      case AutoApplyAccessorialRuleType.ServiceAndTag: {
        return (
          <Stack>
            <Box> Service: {sentenceCase(config?.service?.name ?? '')} </Box>
            <Box> Tag: {config?.tag?.value}</Box>
          </Stack>
        );
      }
      case AutoApplyAccessorialRuleType.StopTypeAndTerminal: {
        return (
          <Stack>
            <Box> Stop Type: {sentenceCase(config?.stopType ?? '')} </Box>
            <Box> Terminal: {config?.terminal?.name ?? ''}</Box>
          </Stack>
        );
      }
      case AutoApplyAccessorialRuleType.IsHazmat:
      case AutoApplyAccessorialRuleType.IsInBond:
      case AutoApplyAccessorialRuleType.Global: {
        return '';
      }
      case AutoApplyAccessorialRuleType.Residential: {
        return '';
      }
      default: {
        exhaustive(config.ruleType);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {addRuleModalOpen && (
        <AddAutoApplyAccessorialRuleModal
          open={addRuleModalOpen}
          setShowAddedConfig={setShowAddedConfig}
          setFailedToAddConfig={setFailedToAddConfig}
          onClose={() => {
            setAddRuleModalOpen(false);
          }}
        />
      )}
      {!isNil(editRuleModalUuid) && (
        <EditAutoApplyAccessorialRuleModal
          open={!isNil(editRuleModalUuid)}
          configUuid={editRuleModalUuid}
          setShowEditedConfig={setShowEditedConfig}
          setFailedToEditConfig={setFailedToEditConfig}
          onClose={() => {
            setEditRuleModalUuid(undefined);
          }}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showAddedConfig}
        onClose={() => {
          setShowAddedConfig(false);
        }}
      >
        <Alert>Successfully added rule.</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showEditedConfig}
        onClose={() => {
          setShowEditedConfig(false);
        }}
      >
        <Alert>Successfully edited rule.</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={failedToAddConfig || failedToEditConfig}
        onClose={() => {
          setFailedToAddConfig(false);
          setFailedToEditConfig(false);
        }}
      >
        <Alert severity="error">An error occurred.</Alert>
      </Snackbar>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableCell>Accessorial(s)</TableCell>
              <TableCell>Rule Type</TableCell>
              <TableCell>Target Value</TableCell>
              <TableCell>Contacts included with rule</TableCell>
              <TableCell>Contacts excluded from rule</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  disabled={!canWriteSettingsAutoApplyAccessorialRules}
                  onClick={() => {
                    setAddRuleModalOpen(true);
                  }}
                >
                  Add Rule
                </Button>
              </TableCell>
            </TableHead>
            {autoApplyAccessorialConfigsData?.autoApplyAccessorialConfigs?.map(
              (config) => {
                return (
                  <TableRow
                    key={config.uuid}
                    onMouseEnter={() => {
                      setHoveredConfigUuid(config.uuid);
                    }}
                    onMouseLeave={() => {
                      setHoveredConfigUuid(undefined);
                    }}
                  >
                    <TableCell>
                      {config.accessorials.map((acc) => acc.name).join(', ')}
                    </TableCell>
                    <TableCell>{sentenceCase(config.ruleType)}</TableCell>
                    <TableCell>{targetValueForConfig(config)}</TableCell>
                    <TableCell sx={{ maxWidth: '200px' }}>
                      {config.contactsToIncludeWithRule
                        ?.map((contact) => contact.displayName)
                        ?.join(', ')}
                    </TableCell>
                    <TableCell sx={{ maxWidth: '200px' }}>
                      {config.contactsToExcludeFromRule
                        ?.map((contact) => contact.displayName)
                        ?.join(', ')}
                    </TableCell>
                    <TableCell align="right">
                      <Fade in={hoveredConfigUuid === config.uuid}>
                        <Button
                          variant="contained"
                          disabled={!canWriteSettingsAutoApplyAccessorialRules}
                          onClick={() => {
                            setEditRuleModalUuid(config.uuid);
                          }}
                        >
                          Edit
                        </Button>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AccessorialsTab;
