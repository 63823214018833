export enum AuditOrderTabs {
  All = 'All',
  NoAction = 'No Action',
  OnHand = 'On Hand',
  Assigned = 'Assigned',
  PartiallyComplete = 'Partially Complete',
  Attempted = 'Attempted',
  OnHold = 'On Hold',
  BillingIssues = 'Billing Issues',
  Unfinalized = 'Unfinalized',
  UnfinalizedCancelled = 'Unfinalized - Cancelled',
  FinalizedCancelled = 'Finalized - Cancelled',
  FinalizedNoCharge = 'Finalized - No Charge',
  OnInvoiceNotPosted = 'On Invoice - Not Posted',
  OnInvoicePosted = 'On Invoice - Posted',
}
