import { Fade, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import ButtonLink from '../../../../common/components/buttons/button-link';
import {
  type FormattedOrderFragment,
  OrderTableField,
} from '../../../../generated/graphql';
import { getOrderTableFieldValue } from '../../../orders/components/utils';

type CustomerPortalPendingOrdersTableRowProps = {
  readonly order: FormattedOrderFragment;
};

const CustomerPortalPendingOrdersTableRow = ({
  order,
}: CustomerPortalPendingOrdersTableRowProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Name,
        })}
      </TableCell>
      <TableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.BillOfLadingNumber,
        })}
      </TableCell>
      <TableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Company,
        })}
      </TableCell>
      <TableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.CreatedAt,
        })}
      </TableCell>
      <TableCell>
        <Fade in={isHovering}>
          <ButtonLink
            href={`/customer-portal/orders/pending-orders/${order.uuid}`}
            variant="contained"
          >
            Open
          </ButtonLink>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default CustomerPortalPendingOrdersTableRow;
