import {
  Accordion,
  AccordionDetails,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import AccordionSummary from '../../accordion-summary';
import { type PreviewAddOrdersToInvoicesForContactOutput } from '../../../../../generated/graphql';
import { sum } from 'lodash';

type PreviewAdditionsToInvoicesForContactCardProps = {
  readonly contactPreview: PreviewAddOrdersToInvoicesForContactOutput;
};

const PreviewAdditionsToInvoicesForContactCard: React.FC<
  PreviewAdditionsToInvoicesForContactCardProps
> = ({ contactPreview }) => {
  const theme = useTheme();
  const { contactName, existingInvoicesToUpdate, newInvoicesToCreate } =
    contactPreview;

  const totalOrders =
    sum(newInvoicesToCreate.map((i) => i.ordersToAdd.length)) +
    sum(existingInvoicesToUpdate.map((i) => i.ordersToAdd.length));

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: theme.palette.borderColor.main,
      }}
    >
      <AccordionSummary>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1 }}>
            <Typography>{contactName}</Typography>
          </Stack>
          <Typography sx={{ color: 'text.secondary' }} variant="caption">
            Total orders: {totalOrders}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" width="100%" spacing={2}>
          <TableContainer>
            <Table aria-label="invoice-preview-table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>Type</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Number of orders to add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {existingInvoicesToUpdate.map((invoice) => (
                  <TableRow key={invoice.invoiceUuid}>
                    <TableCell sx={{ width: '20%' }}>Existing</TableCell>
                    <TableCell sx={{ width: '40%' }}>{invoice.name}</TableCell>
                    <TableCell>{invoice.ordersToAdd.length}</TableCell>
                  </TableRow>
                ))}
                {newInvoicesToCreate.map((invoice) => (
                  <TableRow key={null}>
                    <TableCell sx={{ width: '20%' }}>New</TableCell>
                    <TableCell sx={{ width: '40%' }}>
                      {invoice.name ?? 'New invoice'}
                    </TableCell>
                    <TableCell>{invoice.ordersToAdd.length}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(PreviewAdditionsToInvoicesForContactCard);
