import { type DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  type FilterConstructionOperatorType,
  type FilterConstructionFilterType,
  type SingleFilterConstructionType,
  type FilterConstructionValueType,
} from '../../../../../common/filters/types';
import { isDateRangeFilterValueInput } from '../../../../../common/filters/utils';
import PalletDateRangePicker from '../../../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import { BaseFilterInputInputPropsSx } from './filter-utils';

type OrdersFilterDateRangePickerProps = {
  readonly filterValueInput: FilterConstructionValueType;
  readonly filterOperationInput: FilterConstructionOperatorType;
  readonly filterNameInput: FilterConstructionFilterType;
  readonly setFilter: ({
    filter,
    op,
    value,
  }: SingleFilterConstructionType) => void;
};

const OrdersFilterDateRangePicker = ({
  filterValueInput,
  filterOperationInput,
  filterNameInput,
  setFilter,
}: OrdersFilterDateRangePickerProps) => {
  const value: DateRange<Dayjs> = useMemo(() => {
    return !isNil(filterValueInput) &&
      isDateRangeFilterValueInput(filterValueInput)
      ? [dayjs(filterValueInput?.gte), dayjs(filterValueInput?.lte)]
      : [null, null];
  }, [filterValueInput]);
  return (
    <PalletDateRangePicker
      value={value}
      inputBaseStyles={{
        ...BaseFilterInputInputPropsSx,
        width: '160px',
        marginLeft: '-2px',
      }}
      onAccept={(newDateRange) => {
        const [gte, lte] = newDateRange;
        if (!isNil(gte) && !isNil(lte) && gte.isValid() && lte.isValid()) {
          setFilter({
            filter: filterNameInput,
            op: filterOperationInput,
            value: {
              gte,
              lte,
            },
          });
        }
      }}
    />
  );
};

export default OrdersFilterDateRangePicker;
