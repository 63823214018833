import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import { isNil } from 'lodash';
import React, {
  type FunctionComponent,
  memo,
  type SyntheticEvent,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { PLTRadioGroup } from '../../../common/components/forms/radio-group';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../common/feature-flags';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useUpdateUserMutation } from '../../../generated/graphql';
import PalletModal from '../../../pallet-ui/modal/pallet-modal';
import useDispatchStore from '../dispatch-store';
import { RouteShowCompleteStopsCheckbox } from '../routes/components/route-show-complete-stops-checkbox';
import { DispatchSettingsTab } from '../types/routes';
import { AppointmentTimeFormatDisplayField } from './appointment-time-format-display-field';
import DispatchColorSettings from './dispatch-color-settings';
import { RouteCardWidthSlider } from './route-card-width-slider';
import { RoutesSettings } from './routes-settings';
import { StopsToDisplaySlider } from './stops-to-display-slider';

type DispatchSettingsContextMenuProps = unknown;

const DispatchSettingsContextMenu: FunctionComponent<
  DispatchSettingsContextMenuProps
> = () => {
  const { userUuid } = useMe();
  const {
    dispatchTableColors,
    refetchDispatchTableColors,
    loadingDispatchTableColors,
  } = useDispatchTableColors();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<DispatchSettingsTab>(
    DispatchSettingsTab.ROUTES,
  );
  const ffUseNewDispatchPage = useFeatureFlag(FeatureFlag.FF_NEW_DISPATCH_PAGE);
  const [
    useMinimizedAppointmentTime,
    setUseMinimizedAppointmentTime,
    numberOfStops,
    setNumberOfStops,
    showCompleteStops,
    setShowCompleteStops,
    routeCardWidth,
    setRouteCardWidth,
    arrangeStopsAndRoutesVertically,
    setArrangeStopsAndRoutesVertically,
  ] = useDispatchStore(
    (state) => [
      state.useMinimizedAppointmentTime,
      state.setUseMinimizedAppointmentTime,
      state.numberOfStops,
      state.setNumberOfStops,
      state.showCompleteStops,
      state.setShowCompleteStops,
      state.routeCardWidth,
      state.setRouteCardWidth,
      state.arrangeStopsAndRoutesVertically,
      state.setArrangeStopsAndRoutesVertically,
    ],
    shallow,
  );
  const [updateUser] = useUpdateUserMutation();

  const updateUserSettings = async (
    newUseMinimizedAppointmentTime?: boolean,
    newArrangeStopsAndRoutesVertically?: boolean,
  ) => {
    if (!isNil(userUuid)) {
      updateUser({
        variables: {
          updateUserInput: {
            uuid: userUuid,
            useMinimizedAppointmentTime: newUseMinimizedAppointmentTime,
            arrangeStopsAndRoutesVertically: newArrangeStopsAndRoutesVertically,
            numberOfStops,
            routeCardWidth,
          },
        },
      });
    }
  };

  return (
    <>
      <IconButton
        size="small"
        id="settings-button"
        aria-haspopup="true"
        onClick={() => {
          setShowSettings(true);
        }}
      >
        <SettingsIcon />
      </IconButton>
      <PalletModal
        open={showSettings}
        onClose={() => {
          setShowSettings(false);
        }}
      >
        <Stack direction="row" height="60vh" width="80vw">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons={false}
            value={currentTab}
            onChange={(event: SyntheticEvent, newTab: DispatchSettingsTab) => {
              setCurrentTab(newTab);
            }}
          >
            {Object.values(DispatchSettingsTab).map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Box flex="1" minWidth="0" height="100%" overflow="auto" px={2}>
            <TabPanel
              panelValue={DispatchSettingsTab.ROUTES}
              selectedValue={currentTab}
            >
              <RoutesSettings />
            </TabPanel>
            <TabPanel
              panelValue={DispatchSettingsTab.DISPLAY}
              selectedValue={currentTab}
            >
              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item xs={6}>
                  <AppointmentTimeFormatDisplayField
                    useMinimizedAppointmentTime={useMinimizedAppointmentTime}
                    onChange={(value) => {
                      setUseMinimizedAppointmentTime(value);
                      updateUserSettings(value);
                    }}
                  />
                </Grid>
                <Grid item xs={6} />
                {ffUseNewDispatchPage && (
                  <Grid item xs={12}>
                    <PLTRadioGroup
                      options={[
                        { value: false, label: 'Horizontal' },
                        { value: true, label: 'Vertical' },
                      ]}
                      selectedValue={arrangeStopsAndRoutesVertically}
                      label="Unassigned orders and routes arrangement"
                      onChange={(newValue) => {
                        setArrangeStopsAndRoutesVertically(newValue);
                        updateUserSettings(undefined, newValue);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <StopsToDisplaySlider
                    numberOfStops={numberOfStops}
                    onChange={(value) => {
                      setNumberOfStops(value);
                    }}
                    onBlur={() => {
                      updateUserSettings();
                    }}
                  />
                  <RouteCardWidthSlider
                    routeCardWidth={routeCardWidth}
                    onChange={(value) => {
                      setRouteCardWidth(value);
                    }}
                    onBlur={() => {
                      updateUserSettings();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {ffUseNewDispatchPage && (
                    <RouteShowCompleteStopsCheckbox
                      showCompleteStops={showCompleteStops}
                      onChange={(value) => {
                        setShowCompleteStops(value);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              panelValue={DispatchSettingsTab.COLORS}
              selectedValue={currentTab}
              sx={{ height: '100%' }}
            >
              <DispatchColorSettings
                initialColors={dispatchTableColors ?? []}
                loading={loadingDispatchTableColors}
                onSaved={() => {
                  refetchDispatchTableColors();
                }}
              />
            </TabPanel>
          </Box>
        </Stack>
      </PalletModal>
    </>
  );
};

export default memo(DispatchSettingsContextMenu);
