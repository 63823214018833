import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apolloClient from '../apollo-client';
import CenteredCircularProgress from '../common/components/centered-circular-progress';
import useMe from '../common/react-hooks/use-me';
import styles from '../common/styles/Home.module.css';
import { getPageToOpenOnFirstLoad } from '../common/utils/pages';
import {
  LoginStatus,
  useLoginMutation,
  useMeAsThirdPartyUserQuery,
} from '../generated/graphql';
import PalletRouterLink from '../pallet-ui/links/router-link/pallet-router-link';

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { data: thirdPartyUser, loading: thirdPartyUserLoading } =
    useMeAsThirdPartyUserQuery();
  const [login, { loading: loginLoading }] = useLoginMutation();
  const { user, purchasedProductOfferings, loading } = useMe();

  const meAsThirdPartyUser = thirdPartyUser?.meAsThirdPartyUser;
  useEffect(() => {
    if (!isNil(user)) {
      const pageToOpen = getPageToOpenOnFirstLoad(
        purchasedProductOfferings ?? [],
      );
      navigate(pageToOpen);
    } else if (!isNil(meAsThirdPartyUser)) {
      navigate('/customer-portal');
    }
  }, [user, meAsThirdPartyUser, purchasedProductOfferings, navigate]);

  const handleLogin = async () => {
    if (email !== '' && password !== '') {
      const loginResult = await login({
        variables: {
          loginInputData: {
            email,
            password,
          },
        },
      });

      await apolloClient.resetStore();
      if (loginResult.data?.login?.status !== LoginStatus.Success) {
        setError(loginResult.data?.login?.status as string);
        return;
      }

      if (!isNil(loginResult.data?.login?.fullAccessUser)) {
        const pageToOpen = getPageToOpenOnFirstLoad(
          loginResult.data?.login?.fullAccessUser?.company
            ?.purchasedProductOfferings,
        );
        navigate(pageToOpen);
      } else if (!isNil(loginResult.data?.login?.thirdPartyUser)) {
        navigate('/customer-portal');
      }
    }
  };

  if (loading || thirdPartyUserLoading) {
    return <CenteredCircularProgress style={{ marginTop: '24px' }} />;
  }

  return (
    <div className={styles.container}>
      <Box display="flex" justifyContent="end">
        <PalletRouterLink to="/customer-portal/create">
          <Button
            startIcon={<AccountCircle />}
            variant="outlined"
            color="primary"
            sx={{ mt: 4 }}
          >
            Create a customer portal account
          </Button>
        </PalletRouterLink>
      </Box>
      <main className={styles.main}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            inputProps={{
              'aria-label': 'Email',
            }}
            data-cy="email"
            id="email"
            label="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            sx={{ mb: 1 }}
            value={email}
            variant="outlined"
          />
          <TextField
            inputProps={{
              'aria-label': 'Password',
            }}
            data-cy="password"
            id="password"
            label="Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            sx={{ mb: 1 }}
            type="password"
            value={password}
            variant="outlined"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              data-cy="login"
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              disabled={!email || !password}
              startIcon={loginLoading && <CircularProgress size={20} />}
              sx={{ width: '100%' }}
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </Box>
          <Typography color="red" data-cy="error">
            {isNil(error) ? '' : sentenceCase(error)}
          </Typography>
        </form>
      </main>
    </div>
  );
};

export default Home;
