import { isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import {
  CustomChargeBillingMethod,
  type AccessorialsByBillingContactQuery,
} from '../../../../../generated/graphql';
import {
  customChargeOrCostBaseSchema,
  refineExtendedBaseCustomSchema,
} from './custom-charge-or-cost-base-schema';

type AccessorialForCustomCharge = Pick<
  AccessorialsByBillingContactQuery['accessorialsByBillingContact'][number],
  'uuid' | 'name' | 'isAuthoCodeRequired'
>;

export type CustomChargeSchemaOptions = {
  accessorials: AccessorialForCustomCharge[];
  disallowZeroDollarCharges: boolean;
};

export const getCustomChargeSchema = ({
  accessorials,
  disallowZeroDollarCharges,
}: CustomChargeSchemaOptions) => {
  const unrefinedCustomChargeSchema = customChargeOrCostBaseSchema.extend({
    settlementPercentageRate: z.number().nullable(),
    settlementFlatRate: z.number().nullable(),
    settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
    deductionTotal: z.number(),
  });

  const customChargeSchemaWithBaseRefinements = refineExtendedBaseCustomSchema(
    unrefinedCustomChargeSchema,
  );

  return customChargeSchemaWithBaseRefinements
    .refine(
      (data) =>
        !(
          data.billingMethod === CustomChargeBillingMethod.AdHoc &&
          !isNilOrEmptyString(data.name) &&
          data.name.length > 25
        ),
      {
        message: 'Charge name must be 25 characters or less',
        path: ['name'],
      },
    )
    .superRefine((data, ctx) => {
      const accessorial = accessorials.find(
        (a) => a.uuid === data.accessorialUuid,
      );
      if (isNil(accessorial)) {
        return;
      }
      if (
        accessorial.isAuthoCodeRequired &&
        isNilOrEmptyString(data.authoCode?.trim())
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Autho code is required for ${accessorial.name}`,
          path: ['authoCode'],
        });
      }
    })
    .refine((data) => {
      if (!disallowZeroDollarCharges) {
        return true;
      }
      const isNonAccessorialCustomCharge = isNil(data.accessorialUuid);
      if (
        data.totalCharge === 0 ||
        (isNonAccessorialCustomCharge && data.rate === 0)
      ) {
        return false;
      }
      return true;
    }, 'Please remove any $0 charges before saving the order');
};
