import { Tooltip } from '@mui/material';
import { type PropsWithChildren } from 'react';

type ServiceLevelDeterminesStopTypeTooltipProps = PropsWithChildren<{
  readonly disabled?: boolean;
}>;

const ServiceLevelDeterminesStopTypeTooltip = ({
  children,
  disabled,
}: ServiceLevelDeterminesStopTypeTooltipProps) => {
  return (
    <Tooltip
      title="This field is set based on the selected Service Level"
      placement="bottom-start"
      disableHoverListener={disabled}
    >
      {/* span is required to use as an anchor for disabled elements */}
      <span>{children}</span>
    </Tooltip>
  );
};

export default ServiceLevelDeterminesStopTypeTooltip;
