import { Box, Popover } from '@mui/material';
import { useState } from 'react';
import TableFunctionIconButton from './table-function-icon-button';

type IconButtonWithPopoverProps = {
  readonly icon: React.ReactNode;
  readonly shouldHighlight: boolean;
  readonly showBadge?: boolean;
  readonly render: (handleClose: () => void) => React.ReactNode;
  readonly onClose?: () => void;
};

const IconButtonWithPopover = ({
  icon,
  shouldHighlight,
  showBadge = false,
  render,
  onClose,
}: IconButtonWithPopoverProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    onClose?.();
    setAnchorElement(null);
  };

  return (
    <>
      <TableFunctionIconButton
        size="small"
        shouldHighlight={shouldHighlight}
        showBadge={showBadge}
        onClick={handleClick}
      >
        {icon}
      </TableFunctionIconButton>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <Box>{render(handleClose)}</Box>
      </Popover>
    </>
  );
};

export default IconButtonWithPopover;
