import { Chip } from '@mui/material';
import { LineHaulManifestStatus } from '../../../generated/graphql';

const CHIP_COLORS = {
  [LineHaulManifestStatus.Planning]: '#c77c3c',
  [LineHaulManifestStatus.Departed]: '#3ca3c2',
  [LineHaulManifestStatus.Arrived]: '#6f7070',
} as const;

type ManifestStatusChipProps = {
  readonly status: LineHaulManifestStatus;
};
const ManifestStatusChip = ({ status }: ManifestStatusChipProps) => {
  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: CHIP_COLORS[status],
        color: 'white',
        fontWeight: 400,
        minWidth: 80,
      }}
      label={status}
    />
  );
};

export default ManifestStatusChip;
