import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import ButtonLink from '../../../../common/components/buttons/button-link';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  PermissionResource,
  useAccessorialsTableQuery,
} from '../../../../generated/graphql';
import { ActiveArchivedButtonGroup } from '../common/active-or-archived-button-group';
import { ActiveOrArchivedTab } from '../common/enums';
import AccessorialsTableRow from './accessorials-table-row';
import { QuickAddAccessorialsModal } from './quick-add-accessorials-modal';

const AccessorialsTable = ({
  contactUuid,
}: {
  readonly contactUuid?: string | null | undefined;
}) => {
  const { userPermissions } = useUserRoles();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );
  const { canWrite: canWriteCompanyAccessorials } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyAccessorials,
  );
  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);

  // Accessorials are returned ordered by name.
  const { data: accessorialsData, loading: accessorialsLoading } =
    useAccessorialsTableQuery({
      variables: { contactUuid, includeArchived: true },
    });
  const [searchQuery, setSearchQuery] = useState<string>();
  const [quickAddAccessorialsModalOpen, setQuickAddAccessorialsModalOpen] =
    useState(false);

  const contactAccessorialsDisabled =
    !isNilOrEmptyString(contactUuid) && !canWriteContacts;
  const globalAcessorialsDisabled =
    isNilOrEmptyString(contactUuid) && !canWriteCompanyAccessorials;

  const filteredAccessorials = accessorialsData?.accessorials?.filter(
    (accessorial) =>
      [
        'StandardAccessorialEntity',
        'WeightBasedAccessorialEntity',
        'ZoneBasedAccessorialEntity',
        'WaitTimeAccessorialEntity',
        'UnitBasedAccessorialEntity',
        'SkidBasedAccessorialEntity',
        'SpecialAccessorialEntity',
      ].includes(accessorial.__typename) &&
      (!isNil(contactUuid) ||
        (isNil(contactUuid) && accessorial.contact === null)) &&
      (isNil(searchQuery) ||
        accessorial.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
        ? accessorial.isArchived !== true
        : accessorial.isArchived === true),
  );

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <ActiveArchivedButtonGroup
          activeTab={isActiveOrArchived}
          setTab={setIsActiveOrArchived}
        />
        <Button
          variant="outlined"
          onClick={() => {
            setQuickAddAccessorialsModalOpen(true);
          }}
        >
          Edit quick add
        </Button>
      </Stack>
      <Stack direction="row" alignItems="start" sx={{ px: 1 }}>
        <Box flexGrow={1}>
          <TextField
            size="small"
            label="Search accessorials"
            InputProps={{ style: { backgroundColor: 'white' } }}
            disabled={accessorialsLoading}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </Box>
      </Stack>
      {accessorialsLoading ? (
        <CenteredCircularProgress />
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                {terminalsEnabled && <TableCell>Terminal</TableCell>}
                <TableCell>Details</TableCell>
                <TableCell>GL Code</TableCell>
                <TableCell align="right">
                  <ButtonLink
                    variant="contained"
                    disabled={
                      contactAccessorialsDisabled ||
                      globalAcessorialsDisabled ||
                      accessorialsLoading
                    }
                    href={
                      isNil(contactUuid)
                        ? '/management/accessorials/add'
                        : `/contacts/accessorials/add?contactUuid=${contactUuid}`
                    }
                  >
                    Add Accessorial
                  </ButtonLink>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAccessorials?.map((accessorial) => (
                <AccessorialsTableRow
                  key={accessorial.uuid}
                  accessorial={accessorial}
                  terminalsEnabled={terminalsEnabled}
                  globalAcessorialsDisabled={globalAcessorialsDisabled}
                  contactAccessorialsDisabled={contactAccessorialsDisabled}
                  contactUuid={contactUuid}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <QuickAddAccessorialsModal
        open={quickAddAccessorialsModalOpen}
        onClose={() => {
          setQuickAddAccessorialsModalOpen(false);
        }}
      />
    </Box>
  );
};

export default AccessorialsTable;
