import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { type FilterConstructionType } from '../../../../../common/filters/types';
import { type OrderTableFilterModel } from '../../types';
import {
  buildEmptySingleFilterConstructionType,
  filterConstructionTypeToOrderTableFilterModel,
  orderTableFilterModelToFilterConstructionType as rawOrderTableFilterModelToFilterConstructionType,
} from './filter-utils';
import { type FilterViewPage } from '../../../../../generated/graphql';
/**
 * If the current filter model is empty, add an empty customer name filter
 * to give the user something to start with.
 */
const convertOrderTableFilterModelToFilterConstructionType = (
  filterModel: OrderTableFilterModel,
  pageType: FilterViewPage,
): FilterConstructionType[] => {
  const filterConstructionType =
    rawOrderTableFilterModelToFilterConstructionType(filterModel);
  return isEmpty(filterConstructionType)
    ? [{ and: buildEmptySingleFilterConstructionType({ pageType }) }]
    : filterConstructionType;
};

/**
 * Utilities for managing an array of FilterConstructionTypes
 * that can eventually be saved to an OrderTableFilterModel
 */
export const useFilterConstructionTypes = ({
  filterModelV2,
  setFilterModelV2,
  pageType,
}: {
  filterModelV2: OrderTableFilterModel;
  setFilterModelV2: (filterModel: OrderTableFilterModel) => void;
  pageType: FilterViewPage;
}) => {
  const [internalFilters, setInternalFilters] = useState<
    FilterConstructionType[]
  >(
    convertOrderTableFilterModelToFilterConstructionType(
      filterModelV2,
      pageType,
    ),
  );

  const currentFilters = useMemo(
    () =>
      convertOrderTableFilterModelToFilterConstructionType(
        filterModelV2,
        pageType,
      ),
    [filterModelV2, pageType],
  );

  useEffect(() => {
    setInternalFilters(currentFilters);
  }, [currentFilters]);

  const resetInternalFilters = useCallback(() => {
    setInternalFilters(
      convertOrderTableFilterModelToFilterConstructionType(
        filterModelV2,
        pageType,
      ),
    );
  }, [filterModelV2, pageType]);

  useEffect(resetInternalFilters, [resetInternalFilters]);

  const onApplyFilter = useCallback(() => {
    setFilterModelV2(
      filterConstructionTypeToOrderTableFilterModel(internalFilters),
    );
  }, [internalFilters, setFilterModelV2]);

  return {
    /** Filter state, including any unapplied changes from EditOrderTableFilters */
    internalFilters,
    /** Set the internal filter state */
    setInternalFilters,
    /** Current applied filter state, to render anywhere outside of EditOrderTableFilters */
    currentFilters,
    /** Reset the internal filter state to the current filterModelV2 */
    resetInternalFilters,
    /** Apply the internal filter state to the filterModelV2 */
    onApplyFilter,
  };
};
