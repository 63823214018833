import { isNil } from 'lodash';
import { shallow } from 'zustand/shallow';
import { type OrderBillingStatus } from '../../../generated/graphql';
import useSaveOrderBrm from '../../orders/components/order-form/forms/use-save-order-brm';
import { convertOrderStatusToOrderBillingStatus } from '../../orders/components/order-form/forms/utils';
import useBillingReviewStore from '../billing-review-store';
import {
  OUTSTANDING_ORDERS_PAGE_SIZE,
  PaginationAction,
} from '../types/constants';
import {
  findNextProratedUuidInListOfOrders,
  findNextUuidInListOfOrders,
  findPreviousUuidInListOfOrders,
  findPrevProratedUuidInListOfOrders,
} from './navigation-utils';
import useBillingReviewActions from './use-billing-review-actions';

const useBillingReviewNavigationActions = () => {
  const [
    paginationInfo,
    outstandingOrderUuidsInReview,
    setPaginationInfo,
    openedOutstandingOrderUuid,
    setOpenedOutstandingOrderUuid,
    outstandingOrdersInPage,
    searchedOrders,
    hasNextPage,
    hasPrevPage,
  ] = useBillingReviewStore(
    (state) => [
      state.paginationInfo,
      state.outstandingOrderUuidsInReview,
      state.setPaginationInfo,
      state.openedOutstandingOrderUuid,
      state.setOpenedOutstandingOrderUuid,
      state.outstandingOrdersInPage,
      state.searchedOrders,
      state.paginationInfo.hasNextPage,
      state.paginationInfo.hasPrevPage,
    ],
    shallow,
  );

  const { saveOrder: saveCurrentOrder } = useSaveOrderBrm();

  const { fetchOutstandingOrdersAndSetStore } = useBillingReviewActions();

  const { startCursor, endCursor } = paginationInfo;

  const goToNextPage = async ({
    saveCurrentOrderBeforeNavigating,
  }: {
    saveCurrentOrderBeforeNavigating?: boolean;
  }) => {
    if (!hasNextPage) {
      return;
    }
    if (saveCurrentOrderBeforeNavigating === true) {
      await saveCurrentOrder({});
    }
    const result = await fetchOutstandingOrdersAndSetStore({
      queryVariables: {
        first: OUTSTANDING_ORDERS_PAGE_SIZE,
        after: endCursor,
        uuids: outstandingOrderUuidsInReview,
      },
      openLastOrderOnPage: false,
    });

    if (!isNil(result)) {
      setPaginationInfo({
        hasNextPage:
          result.data?.outstandingOrdersForReview?.pageInfo.hasNextPage ??
          false,
        hasPrevPage: true,
        startCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.startCursor ?? null,
        endCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.endCursor ?? null,
        lastPaginationArgs: {
          first: OUTSTANDING_ORDERS_PAGE_SIZE,
          after: endCursor,
        },
      });
    }
  };

  const goToPreviousPage = async ({
    openLastOrderOnPreviousPage,
    saveCurrentOrderBeforeNavigating,
  }: {
    openLastOrderOnPreviousPage?: boolean;
    saveCurrentOrderBeforeNavigating?: boolean;
  }) => {
    if (!hasPrevPage) {
      return;
    }
    if (saveCurrentOrderBeforeNavigating === true) {
      await saveCurrentOrder({});
    }
    const result = await fetchOutstandingOrdersAndSetStore({
      queryVariables: {
        last: OUTSTANDING_ORDERS_PAGE_SIZE,
        before: startCursor,
        uuids: outstandingOrderUuidsInReview,
      },
      openLastOrderOnPage: openLastOrderOnPreviousPage ?? false,
    });

    if (!isNil(result)) {
      setPaginationInfo({
        hasNextPage: true,
        hasPrevPage:
          result.data?.outstandingOrdersForReview?.pageInfo.hasPreviousPage ??
          false,
        startCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.startCursor ?? null,
        endCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.endCursor ?? null,
        lastPaginationArgs: {
          last: OUTSTANDING_ORDERS_PAGE_SIZE,
          before: startCursor,
        },
      });
    }
  };

  const refetchPage = async (newUuids: string[], openTo?: string) => {
    const result = await fetchOutstandingOrdersAndSetStore({
      queryVariables: {
        ...paginationInfo.lastPaginationArgs,
        uuids: newUuids,
      },
      openLastOrderOnPage: false,
    });

    if (!isNil(openTo)) {
      setOpenedOutstandingOrderUuid(openTo);
    }

    if (!isNil(result)) {
      setPaginationInfo({
        ...paginationInfo,
        // porting over existing behavior.
        hasNextPage: true,
        hasPrevPage: false,
        startCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.startCursor ?? null,
        endCursor:
          result.data?.outstandingOrdersForReview?.pageInfo.endCursor ?? null,
        lastPaginationArgs: {
          ...paginationInfo.lastPaginationArgs,
        },
      });
    }
  };

  /**
   * Given an order and a list of orders, if the order is
   * part of this list then return the next order in the list (or a prorated order within the order)
   * or return 'NEXT_PAGE' if there is no next order in the list
   * @returns 'NEXT_PAGE' | string | null
   */
  const getNextOrderUuid = (): PaginationAction | string | null => {
    if (isNil(openedOutstandingOrderUuid)) {
      return null;
    }

    // Check outstanding orders
    const nextOutstandingUuid = findNextUuidInListOfOrders({
      orders: outstandingOrdersInPage,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoNextOrder: PaginationAction.NEXT_PAGE,
    });
    if (!isNil(nextOutstandingUuid)) return nextOutstandingUuid;

    // Check searched orders
    const nextSearchedUuid = findNextUuidInListOfOrders({
      orders: searchedOrders,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoNextOrder: outstandingOrdersInPage[0]?.uuid ?? null,
    });
    if (!isNil(nextSearchedUuid)) return nextSearchedUuid;

    // Check prorated orders within outstanding orders
    const nextProratedOutstandingUuid = findNextProratedUuidInListOfOrders({
      orders: outstandingOrdersInPage,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoNextOrder: PaginationAction.NEXT_PAGE,
    });
    if (!isNil(nextProratedOutstandingUuid)) return nextProratedOutstandingUuid;

    // Check prorated orders within searched orders
    const nextProratedSearchedUuid = findNextProratedUuidInListOfOrders({
      orders: searchedOrders,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoNextOrder: outstandingOrdersInPage[0]?.uuid ?? null,
    });
    if (!isNil(nextProratedSearchedUuid)) return nextProratedSearchedUuid;

    // if we reached here there's nothing to navigate to.
    return null;
  };

  const getPrevOrderUuid = (): PaginationAction | string | null => {
    if (isNil(openedOutstandingOrderUuid)) {
      return null;
    }

    // Check outstanding orders
    const prevOutstandingUuid = findPreviousUuidInListOfOrders({
      orders: outstandingOrdersInPage,
      currentUuid: openedOutstandingOrderUuid,
      // if no previous page, the fallback is the last order in the searched orders list if it exists
      fallbackIfNoPrevOrder: hasPrevPage
        ? PaginationAction.PREV_PAGE
        : (searchedOrders.at(-1)?.uuid ?? null),
    });
    if (!isNil(prevOutstandingUuid)) return prevOutstandingUuid;

    // Check searched orders
    const prevSearchedUuid = findPreviousUuidInListOfOrders({
      orders: searchedOrders,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoPrevOrder: null,
    });
    if (!isNil(prevSearchedUuid)) return prevSearchedUuid;

    // Check prorated orders within outstanding orders
    const prevProratedOutstandingUuid = findPrevProratedUuidInListOfOrders({
      orders: outstandingOrdersInPage,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoPrevOrder: outstandingOrdersInPage.at(-1)?.uuid ?? null,
    });
    if (!isNil(prevProratedOutstandingUuid)) return prevProratedOutstandingUuid;

    // Check prorated orders within searched orders
    const prevProratedSearchedUuid = findPrevProratedUuidInListOfOrders({
      orders: searchedOrders,
      currentUuid: openedOutstandingOrderUuid,
      fallbackIfNoPrevOrder: null,
    });
    if (!isNil(prevProratedSearchedUuid)) return prevProratedSearchedUuid;

    return null;
  };

  const getCurrentOrder = async () => {
    return (
      outstandingOrdersInPage.find(
        (o) => o.uuid === openedOutstandingOrderUuid,
      ) ??
      searchedOrders.find((o) => o.uuid === openedOutstandingOrderUuid) ??
      outstandingOrdersInPage
        .flatMap((o) => o.queriedProratedOrdersWith)
        .find((o) => o?.uuid === openedOutstandingOrderUuid) ??
      searchedOrders
        .flatMap((o) => o.queriedProratedOrdersWith)
        .find((o) => o?.uuid === openedOutstandingOrderUuid)
    );
  };

  const saveAndGoToNextOrder = async ({
    newBillingStatus,
  }: {
    newBillingStatus?: OrderBillingStatus;
  }) => {
    const currentOrder = await getCurrentOrder();
    const nextOrderUuid = getNextOrderUuid();

    const success = await saveCurrentOrder({
      forceRefresh:
        convertOrderStatusToOrderBillingStatus(currentOrder?.status) !==
        newBillingStatus,
      newBillingStatus,
    });

    if (success && !isNil(nextOrderUuid)) {
      if (nextOrderUuid === PaginationAction.NEXT_PAGE) {
        goToNextPage({});
      } else {
        setOpenedOutstandingOrderUuid(nextOrderUuid);
      }
    }
  };

  const saveAndGoToPreviousOrder = async () => {
    const prevOrderUuid = getPrevOrderUuid();

    await saveCurrentOrder({});

    if (!isNil(prevOrderUuid)) {
      if (prevOrderUuid === PaginationAction.PREV_PAGE) {
        goToPreviousPage({
          openLastOrderOnPreviousPage: true,
        });
      } else {
        setOpenedOutstandingOrderUuid(prevOrderUuid);
      }
    }
  };

  return {
    saveAndGoToNextOrder,
    saveAndGoToPreviousOrder,
    goToNextPage,
    goToPreviousPage,
    refetchPage,
  };
};

export default useBillingReviewNavigationActions;
