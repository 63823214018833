import { Box, Snackbar, Alert, Stack } from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import { v4 } from 'uuid';
import useInterval from '../../../common/react-hooks/use-interval';
import {
  type AppointmentFragment,
  GetAppointmentsDocument,
  type GetAppointmentsQuery,
  useGetAppointmentsLazyQuery,
  useUpdateStopMutation,
} from '../../../generated/graphql';
import { formattedDateAbbreviated } from '../../dispatch/utils';
import AppointmentsTableTabs from '../types/appointments-table-tabs';
import {
  type PhoneNumberData,
  type UpdateRowPhoneNumber,
} from '../types/types';
import { AppointmentsTableWithFiltersAgGrid } from './appointments-table/appointments-table-ag-grid';
import { showInView } from './utils/utils';
import { useAppointmentsTableColumns } from '../hooks/use-appointments-table-columns';

dayjs.extend(utc);
dayjs.extend(timezone);

const defaultFilterTabConfigs = {
  defaultTab: AppointmentsTableTabs.Unscheduled,
  baseTab: AppointmentsTableTabs.Unscheduled,
  tabs: [
    {
      value: AppointmentsTableTabs.Appointments,
      label: 'Stops',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments;
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.Unscheduled,
      label: 'Unnotified',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Unscheduled, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.Unconfirmed,
      label: 'Unconfirmed',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Unconfirmed, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.RescheduleRequested,
      label: 'Reschedule requested',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.RescheduleRequested, appt),
        );
      },
      shouldShowCheckboxes: true,
    },
    {
      value: AppointmentsTableTabs.Confirmed,
      label: 'Confirmed',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.Confirmed, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.WithoutContacts,
      label: 'Stops without contacts',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.WithoutContacts, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
    {
      value: AppointmentsTableTabs.WithoutAppointments,
      label: 'Stops without appointments',
      filterFunction: (
        appointments: AppointmentFragment[],
      ): AppointmentFragment[] => {
        return appointments.filter((appt) =>
          showInView(AppointmentsTableTabs.WithoutAppointments, appt),
        );
      },
      shouldShowCheckboxes: false,
    },
  ],
};
const AppointmentsView = () => {
  const localDate = localStorage.getItem('appointmentDate');
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    isNil(localDate) ? dayjs().startOf('day') : dayjs(localDate),
  );

  // Query appointments data for the selected date
  const [getAppointments, { data: appointmentsData }] =
    useGetAppointmentsLazyQuery();
  const [updateStop] = useUpdateStopMutation({
    refetchQueries: [GetAppointmentsDocument],
  });

  const { columnDefs, filterColumns } = useAppointmentsTableColumns();

  const [data, setData] = useState<GetAppointmentsQuery | undefined>(undefined);
  const [showSuccessfulSave, setShowSuccessfulSave] = useState(false);
  const [filterTabConfigs, setFilterTabConfigs] = useState(
    defaultFilterTabConfigs,
  );

  const fetchAppointments = () => {
    getAppointments({
      variables: {
        deliveryDate: dayjs(getNoonOfDay(selectedDate)),
      },
    });
  };

  useInterval(() => {
    fetchAppointments();
  }, 10_000);

  useEffect(() => {
    setFilterTabConfigs(defaultFilterTabConfigs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilterTabConfigs.tabs.length]);

  useEffect(() => {
    localStorage.setItem('appointmentDate', selectedDate.toISOString());
    setFilterTabConfigs((prevState) => {
      return {
        ...prevState,
        tabs: prevState.tabs.map((tab) => {
          if (tab.value === AppointmentsTableTabs.Appointments) {
            return {
              ...tab,
              label: `Stops for ${formattedDateAbbreviated(selectedDate)}`,
            };
          }
          return tab;
        }),
      };
    });
  }, [selectedDate]);

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  // Set appointment table rows data for the selected date
  useEffect(() => {
    setData(appointmentsData);
  }, [appointmentsData]);

  const handleSavePhoneNumbers = async (
    numbersToUpdate: PhoneNumberData[],
    updateRowPhoneNumbersCallback: (updates: UpdateRowPhoneNumber[]) => void,
  ) => {
    try {
      const results = await Promise.all(
        numbersToUpdate.map(async (numberInfo) => {
          if (!isNil(numberInfo.stopUuid)) {
            return updateStop({
              variables: {
                updateStopInput: {
                  stopUpdateInput: {
                    uuid: numberInfo.stopUuid,
                    contactPersonUpsertInput: {
                      uuid: numberInfo.contactPersonUuid ?? v4(),
                      phone: numberInfo.phoneNumber,
                    },
                  },
                },
              },
            });
          }
          return null;
        }),
      );

      const convertToUpdateObject: UpdateRowPhoneNumber[] = [];
      for (const result of results) {
        const stopUuid = result?.data?.updateStop.uuid;
        const contactPerson = result?.data?.updateStop.contactPerson;
        const phone = contactPerson?.phone;
        if (!isNil(stopUuid) && !isNil(contactPerson) && !isNil(phone)) {
          convertToUpdateObject.push({
            stopUuid,
            contactPerson: {
              uuid: contactPerson.uuid,
              phone,
            },
          });
        }
      }
      updateRowPhoneNumbersCallback(convertToUpdateObject);
      setShowSuccessfulSave(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving phone numbers', error);
    }
  };

  return (
    <Stack height="100%" flexDirection="column">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
          position: 'relative',
        }}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessfulSave}
      >
        <Alert>Successfully saved</Alert>
      </Snackbar>
      <Box flex={1} minHeight={0}>
        <AppointmentsTableWithFiltersAgGrid<AppointmentsTableTabs>
          rowSelectionEnabled
          appointmentsData={data}
          columnDefinitions={columnDefs}
          filterColumns={filterColumns}
          pageSize={10}
          handleSavePhoneNumbers={handleSavePhoneNumbers}
          defaultFilterTabsConfigs={filterTabConfigs}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Stack>
  );
};

export default AppointmentsView;
