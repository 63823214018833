import {
  CircularProgress,
  Stack,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { isNil, sum } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import {
  useGetLineHaulProgressQuery,
  useLineHaulLaneQuery,
} from '../../../../../../../generated/graphql';
import { useOrderViewContext } from '../../../contexts/order-view-context';
import { type OrderFormValues } from '../../../forms/types';
import LineHaulTerminalStepLabel from './line-haul-terminal-step-label';
import { LineHaulTimelineEvent } from './line-haul-timeline-event';

const QontoConnector = styled(StepConnector)(() => ({
  height: 'fit-content',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    bottom: 0,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // borderColor: '#784af4',
      borderStyle: 'solid',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 0,
    borderStyle: 'dashed',
  },
}));

const LineHaulLaneProgressOld = () => {
  const { control } = useFormContext<OrderFormValues>();

  const lineHaulLaneUuid = useWatch({ control, name: 'lineHaulLaneUuid' });
  const orderUuid = useWatch({ control, name: 'uuid' });
  const packages = useWatch({ control, name: 'packages' });
  const expectedPieceCount = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.quantity));
  }, [packages]);

  const { data: laneData, loading: laneLoading } = useLineHaulLaneQuery({
    variables: {
      uuid: lineHaulLaneUuid ?? '',
    },
    skip: isNil(lineHaulLaneUuid),
  });
  const { data: progressData, loading: progressLoading } =
    useGetLineHaulProgressQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        getLineHaulProgressInput: {
          uuid: orderUuid,
        },
      },
    });

  if (laneLoading) {
    return (
      <Stack direction="column" justifyContent="center" p="20px" gap="20px">
        <CircularProgress />
      </Stack>
    );
  }

  if (isNil(laneData)) {
    return null;
  }

  return (
    <Stack direction="column" justifyContent="center" gap={2}>
      <Typography variant="h6" fontSize="16px">
        Line haul summary
      </Typography>
      {progressLoading && <CircularProgress />}
      <Stepper
        alternativeLabel
        sx={{ width: '100%', p: '0 !important' }}
        connector={<QontoConnector />}
      >
        {progressData?.getLineHaulProgress.terminalStatuses.map((status) => {
          return (
            <Step
              key={status.terminal.uuid}
              completed={
                !isNil(status.manifestDepartingFromThisTerminal) ||
                !isNil(status.markedArrivedAt) ||
                (isNil(status.manifestDepartingFromThisTerminal) &&
                  status.arrivalScans.length >= (packages?.length ?? 0))
              }
            >
              <LineHaulTerminalStepLabel
                status={status}
                expectedPieceCount={expectedPieceCount}
              />
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

const LineHaulLaneProgressNew = () => {
  const { control } = useFormContext<OrderFormValues>();
  const orderView = useOrderViewContext();
  const packages = useWatch({ control, name: 'packages' });
  if (isNil(orderView?.order)) {
    return null;
  }
  const { order } = orderView;
  const expectedPieceCount = isNil(packages)
    ? 0
    : sum(packages.map((pkg) => pkg.quantity));

  return (
    <Stack direction="column" justifyContent="center" gap={2}>
      <Typography variant="h6" fontSize="16px">
        Line haul summary
      </Typography>
      <Stepper
        alternativeLabel
        sx={{ width: '100%', p: '0 !important' }}
        connector={<QontoConnector />}
      >
        {order.lineHaulTimeline.map((event, i) => {
          return (
            <Step
              // eslint-disable-next-line react/no-array-index-key -- There isn't a better key.
              key={i}
              completed={
                !isNil(event.departingManifest) ||
                !isNil(event.markedArrivedAt) ||
                (expectedPieceCount > 0 &&
                  event.arrivalScans.length >= expectedPieceCount)
              }
            >
              <LineHaulTimelineEvent
                event={event}
                expectedPieceCount={expectedPieceCount}
              />
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

const LineHaulLaneProgress = () => {
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  if (ffLineHaulNetworks) {
    return <LineHaulLaneProgressNew />;
  }
  return <LineHaulLaneProgressOld />;
};

export default LineHaulLaneProgress;
