import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { type SettlementBillLineItemWithCostsFragment } from '../../../generated/graphql';
import { SettlementTableCell } from './common';

type CustomCostRowProps = {
  readonly customCost: NonNullable<
    SettlementBillLineItemWithCostsFragment['customCosts']
  >[number];
};

const CustomCostRow = ({ customCost }: CustomCostRowProps) => {
  const customCostType = customCost.accessorialTemplate?.type ?? 'Custom';
  return (
    <TableRow>
      <SettlementTableCell>{customCost.name} </SettlementTableCell>
      <SettlementTableCell>{sentenceCase(customCostType)}</SettlementTableCell>
      <SettlementTableCell>
        {isNil(customCost.rateUsdDollars)
          ? '-'
          : currency(customCost.rateUsdDollars).format()}
      </SettlementTableCell>
      <SettlementTableCell>{customCost.quantity}</SettlementTableCell>
      <SettlementTableCell>
        {isNil(customCost.fuelSurchargeRate)
          ? '-'
          : `${customCost.fuelSurchargeRate}%`}
      </SettlementTableCell>
      <SettlementTableCell>
        {isNil(customCost.totalAmountUsdDollars)
          ? '-'
          : currency(customCost.totalAmountUsdDollars).format()}
      </SettlementTableCell>
    </TableRow>
  );
};

type SettlementBillLineItemCostsSummaryProps = {
  readonly lineItem: SettlementBillLineItemWithCostsFragment;
};

const SettlementBillLineItemCostsSummary = ({
  lineItem,
}: SettlementBillLineItemCostsSummaryProps) => {
  const { freightCost, customCosts } = lineItem;

  const freightCostRow = isNil(freightCost) ? null : (
    <TableRow>
      <SettlementTableCell>Freight</SettlementTableCell>
      <SettlementTableCell>
        {isNil(freightCost.billingMethod)
          ? '-'
          : sentenceCase(freightCost.billingMethod)}
      </SettlementTableCell>
      <SettlementTableCell>
        {isNil(freightCost.rateUsdDollars)
          ? '-'
          : currency(freightCost.rateUsdDollars).format()}
      </SettlementTableCell>
      <SettlementTableCell>{freightCost.quantity}</SettlementTableCell>
      <SettlementTableCell>-</SettlementTableCell>
      <SettlementTableCell>
        {isNil(freightCost.totalAmountUsdDollars)
          ? '-'
          : currency(freightCost.totalAmountUsdDollars).format()}
      </SettlementTableCell>
    </TableRow>
  );

  const fuelCost = freightCost?.fuelCost;
  const fuelCostRow = isNil(fuelCost) ? null : (
    <TableRow>
      <SettlementTableCell>Fuel</SettlementTableCell>
      <SettlementTableCell>
        {isNil(fuelCost.billingMethod)
          ? '-'
          : sentenceCase(fuelCost.billingMethod)}
      </SettlementTableCell>
      <SettlementTableCell>
        {isNil(fuelCost.surchargeRate)
          ? isNil(fuelCost.flatRateUsdDollars)
            ? '-'
            : currency(fuelCost.flatRateUsdDollars).format()
          : `${fuelCost.surchargeRate}%`}
      </SettlementTableCell>
      <SettlementTableCell>1</SettlementTableCell>
      <SettlementTableCell>-</SettlementTableCell>
      <SettlementTableCell>
        {isNil(fuelCost.totalAmountUsdDollars)
          ? '-'
          : currency(fuelCost.totalAmountUsdDollars).format()}
      </SettlementTableCell>
    </TableRow>
  );

  const customCostRows =
    customCosts?.map((customCost) => (
      <CustomCostRow key={customCost.id} customCost={customCost} />
    )) ?? null;

  const totalCostRow = (
    <TableRow sx={{ '& td': { border: 0 } }}>
      <SettlementTableCell>
        <strong>Total</strong>
      </SettlementTableCell>
      <SettlementTableCell />
      <SettlementTableCell />
      <SettlementTableCell />
      <SettlementTableCell />
      <SettlementTableCell>
        {isNil(lineItem.totalAmountUsdDollars)
          ? '-'
          : currency(lineItem.totalAmountUsdDollars).format()}
      </SettlementTableCell>
    </TableRow>
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <SettlementTableCell width="30%">Charge name</SettlementTableCell>
            <SettlementTableCell width="20%">Type</SettlementTableCell>
            <SettlementTableCell width="10%">Rate</SettlementTableCell>
            <SettlementTableCell width="10%">Qty</SettlementTableCell>
            <SettlementTableCell width="10%">Fuel %</SettlementTableCell>
            <SettlementTableCell width="20%">Total</SettlementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {freightCostRow}
          {fuelCostRow}
          {customCostRows}
          {totalCostRow}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettlementBillLineItemCostsSummary;
