import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  DriverSettlementBillByUuidDocument,
  DriverSettlementBillsDocument,
  useDriverSettlementBillByUuidQuery,
  useUpdateDriverSettlementBillMutation,
} from '../../../generated/graphql';
import { SettlementBillLineItemsTableMode } from '../enums';
import { SettlementBillLineItemsTable } from './settlement-bill-line-items-table';
type UpdateInput = {
  newName?: string;
  newSettlementDate?: Dayjs | null;
  finalize?: boolean;
};

const SettlementBill = ({
  settlementUuid,
  onClose,
}: {
  readonly settlementUuid: string;
  readonly onClose: () => void;
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>();
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>();
  const [settlementDate, setSettlementDate] = useState<
    Dayjs | null | undefined
  >();
  const { data: settlementData, loading: dataLoading } =
    useDriverSettlementBillByUuidQuery({
      variables: {
        uuid: settlementUuid,
      },
    });
  const [updateDriverSettlementBill, { loading: updateLoading }] =
    useUpdateDriverSettlementBillMutation();

  const loading = dataLoading || updateLoading;

  const isFinalized = !isNil(
    settlementData?.driverSettlementBill.finalizedDate,
  );

  const finalizeSettlement = async () => {
    await update({ finalize: true });
  };

  const unfinalizeSettlement = async () => {
    await update({ finalize: false });
  };

  useEffect(() => {
    const settlementBill = settlementData?.driverSettlementBill;
    setName(settlementBill?.name ?? undefined);
    setStartDate(
      isNil(settlementBill?.billStartDate)
        ? null
        : dayjs(settlementBill?.billStartDate as string),
    );
    setEndDate(
      isNil(settlementBill?.billEndDate)
        ? undefined
        : dayjs(settlementBill?.billEndDate as string),
    );
    setSettlementDate(
      isNil(settlementBill?.settlementDate)
        ? undefined
        : dayjs(settlementBill?.settlementDate as string),
    );
  }, [settlementData]);

  const update = async ({
    newName,
    newSettlementDate,
    finalize,
  }: UpdateInput) => {
    await updateDriverSettlementBill({
      variables: {
        updateDriverSettlementBillInput: {
          driverSettlementBillUpdateInput: {
            uuid: settlementUuid,
            name: newName,
            settlementDate: isNil(newSettlementDate)
              ? undefined
              : newSettlementDate.toDate(),
            finalizedDate:
              finalize === true
                ? new Date()
                : finalize === false
                  ? null
                  : undefined,
          },
        },
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
      ],
    });
  };

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  }, [isSaving]);

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl>
            <TextField
              label="Name"
              value={name ?? ''}
              size="small"
              disabled={isFinalized}
              InputProps={{
                endAdornment: !isFinalized && (
                  <Typography sx={{ fontSize: '12px', color: 'gray' }}>
                    {isSaving ? 'Saving...' : 'Saved'}
                  </Typography>
                ),
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onBlur={async (e) => {
                setIsSaving(true);
                await update({ newName: e.target.value });
              }}
            />
          </FormControl>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={isFinalized}
                label="Settlement date"
                value={settlementDate}
                renderInput={(params) => (
                  <TextField
                    aria-label="date-picker"
                    sx={{ width: 150 }}
                    size="small"
                    {...params}
                  />
                )}
                onChange={async (newValue) => {
                  setSettlementDate(newValue);
                  await update({ newSettlementDate: newValue });
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl>
            <TextField
              label="Pay period"
              value={`${startDate?.format('MM/DD/YY') ?? 'N/A'} - ${
                endDate?.format('MM/DD/YY') ?? 'N/A'
              }`}
              size="small"
              InputProps={{
                readOnly: true,
              }}
              disabled={isFinalized}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {isFinalized ? (
            <Button
              size="small"
              color="error"
              variant="contained"
              disabled={loading}
              onClick={() => {
                void unfinalizeSettlement();
              }}
            >
              Unfinalize
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              disabled={loading}
              onClick={() => {
                void finalizeSettlement();
              }}
            >
              Finalize
            </Button>
          )}
          <IconButton
            disabled={loading}
            style={{ width: '30px', height: '30px' }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <Box flex={1}>
        <SettlementBillLineItemsTable
          mode={SettlementBillLineItemsTableMode.SETTLEMENT_BILL}
          settlementBillUuid={settlementUuid}
        />
      </Box>
    </Stack>
  );
};

export default SettlementBill;
