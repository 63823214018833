import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type StopDriverMapSummaryFragment } from '../../generated/graphql';

type DriverSettlementState = {
  openedOrderUuid: string | undefined;
  allSelected: boolean;
  selectedStopUuids: string[];
  deselectedStopUuids: string[];
  selectedStopsForSettlements: StopDriverMapSummaryFragment[] | undefined;
  shouldRefreshSettlementsList: boolean;
  openedSettlementUuid: string | undefined;
};

type DriverSettlementActions = {
  setOpenedOrderUuid: (uuid: string | undefined) => void;
  setAllSelected: (selected: boolean) => void;
  selectStopUuid: (stopUuid: string) => void;
  deselectStopUuid: (stopUuid: string) => void;
  setSelectedStopsForSettlements: (
    stops: StopDriverMapSummaryFragment[] | undefined,
  ) => void;
  setShouldRefreshSettlementsList: (shouldRefresh: boolean) => void;
  setOpenedSettlementUuid: (settlementUuid: string | undefined) => void;
};

const useDriverSettlementStore = create(
  immer<DriverSettlementState & DriverSettlementActions>((set) => ({
    openedOrderUuid: undefined,
    allSelected: false,
    selectedStopUuids: [],
    deselectedStopUuids: [],
    selectedStopsForSettlements: undefined,
    driverUuidToSettlementUuidMap: {},
    shouldRefreshSettlementsList: false,
    openedSettlementUuid: undefined,
    setOpenedOrderUuid: (uuid: string | undefined) => {
      set((state) => {
        state.openedOrderUuid = uuid;
      });
    },
    setAllSelected: (selected: boolean) => {
      set((state) => {
        if (!selected) {
          state.deselectedStopUuids = [];
          state.selectedStopUuids = [];
        } else if (selected) {
          state.deselectedStopUuids = [];
        }
        state.allSelected = selected;
      });
    },
    selectStopUuid: (stopUuid: string) => {
      set((state) => {
        state.deselectedStopUuids = state.deselectedStopUuids.filter(
          (uuid) => uuid !== stopUuid,
        );
        state.selectedStopUuids.push(stopUuid);
      });
    },
    deselectStopUuid: (stopUuid: string) => {
      set((state) => {
        state.selectedStopUuids = state.selectedStopUuids.filter(
          (uuid) => uuid !== stopUuid,
        );
        state.deselectedStopUuids.push(stopUuid);
      });
    },
    setSelectedStopsForSettlements: (
      stops: StopDriverMapSummaryFragment[] | undefined,
    ) => {
      set((state) => {
        state.selectedStopsForSettlements = stops;
      });
    },
    setShouldRefreshSettlementsList: (shouldRefresh: boolean) => {
      set((state) => {
        state.shouldRefreshSettlementsList = shouldRefresh;
      });
    },
    setOpenedSettlementUuid: (settlementUuid: string | undefined) => {
      set((state) => {
        state.openedSettlementUuid = settlementUuid;
      });
    },
  })),
);

export default useDriverSettlementStore;
