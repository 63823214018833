import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { type ThirdPartyUserCompany } from '../types';
import CustomerPortalOrderForm from './customer-portal-order-form/customer-portal-order-form';
import { useCreateCustomerPortalOrderForm } from './customer-portal-order-form/hooks/use-create-customer-portal-order-form';
import { useSaveOrderCustomerPortal } from './customer-portal-order-form/hooks/use-save-order-customer-portal';

type CustomerPortalAddQuotePageWithContactUuidProps = {
  readonly contactUuid: string;
  readonly setContactUuid: (contactUuid: string) => void;
  readonly company: ThirdPartyUserCompany;
};

// Separate component so that we can guarantee that contactUuid and company
// are always available
const CustomerPortalAddQuotePageWithContactUuid = ({
  contactUuid,
  setContactUuid,
  company,
}: CustomerPortalAddQuotePageWithContactUuidProps) => {
  const { saveOrder: onSubmit } = useSaveOrderCustomerPortal({
    companyUuid: company.uuid,
    contactUuid,
  });
  return (
    <CustomerPortalOrderForm
      company={company}
      contactUuid={contactUuid}
      setContactUuid={setContactUuid}
      onSubmit={onSubmit}
    />
  );
};

const CustomerPortalAddQuotePage = () => {
  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );

  const form = useCreateCustomerPortalOrderForm();

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const navigationContactUuid: string | null =
    typeof location.state?.contactUuid === 'string'
      ? location.state?.contactUuid
      : null;

  // State management is tricky here:
  // - We want to load the contact UUID from the navigation state
  // - We want to update the contact UUID in the form if navigation changes
  //   (e.g. user navigates to the same page with a different contact)
  // - We want to minimize duplicate states / useEffects (e.g. not also putting the contact
  //   UUID in the form state)
  // eslint-disable-next-line react/hook-use-state
  const [contactUuid, rawSetContactUuid] = useState<string | null>(
    navigationContactUuid,
  );

  // If the navigation state changes, read the new contact UUID
  // We can't use the setContactUuid function here because it would
  // cause this effect to run every time the contact UUID changes
  // and reset to the contact UUID stored in the navigation state
  useEffect(() => {
    if (isNilOrEmptyString(navigationContactUuid)) {
      return;
    }
    rawSetContactUuid(navigationContactUuid);
    form.reset();
  }, [navigationContactUuid, rawSetContactUuid, form]);

  const { thirdPartyUser, loading: thirdPartyUserLoading } =
    useMeAsThirdPartyUser();

  // We can derive the company from the selected contact UUID (so no need to
  // store it in the form state)
  const company: ThirdPartyUserCompany | null =
    thirdPartyUser?.contacts.find((c) => c.uuid === contactUuid)?.company ??
    null;

  const setContactUuid = useCallback(
    (newContactUuid: string) => {
      const newCompanyUuid = thirdPartyUser?.contacts.find(
        (c) => c.uuid === newContactUuid,
      )?.company.uuid;
      if (newCompanyUuid !== company?.uuid) {
        // The form may have company-specific values (e.g. service level or
        // terminal UUIDs) that we need to reset when switching companies.
        form.reset();
      }
      rawSetContactUuid(newContactUuid);
    },
    [thirdPartyUser, company, form],
  );

  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );
  // Also wait for third party user to load so we can check its feature flags
  if (statsigLoading || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  if (
    !ffCustomerPortalQuoteEntry ||
    isNilOrEmptyString(contactUuid) ||
    isNil(company)
  ) {
    return <Navigate to="/customer-portal/quotes" />;
  }

  return (
    <FormProvider {...form}>
      <CustomerPortalAddQuotePageWithContactUuid
        contactUuid={contactUuid}
        setContactUuid={setContactUuid}
        company={company}
      />
    </FormProvider>
  );
};

export default CustomerPortalAddQuotePage;
