import { Box, Card, Stack } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type SetStateAction, useCallback, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../generated/graphql';
import OrderPage from '../order-form/order-page';
import ReviewCustomerPortalOrdersTable from './review-customer-portal-orders/review-customer-portal-orders-table';
import ReviewEdiOrderDetails from './review-edi-orders/review-edi-order-details';
import ReviewEdiOrdersTable from './review-edi-orders/review-edi-orders-table';

enum ReviewableOrderType {
  EDI_OR_API = 'EDI_OR_API',
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
}

const useOrderSelection = () => {
  const [selectedEdiOrderUuids, setSelectedEdiOrderUuids] = useState<string[]>(
    [],
  );
  const [
    selectedCustomerPortalOrderUuids,
    setSelectedCustomerPortalOrderUuids,
  ] = useState<string[]>([]);

  const handleEdiOrderSelection = useCallback(
    (value: SetStateAction<string[]>) => {
      setSelectedEdiOrderUuids(value);
      setSelectedCustomerPortalOrderUuids([]);
    },
    [],
  );

  const handleCustomerPortalOrderSelection = useCallback(
    (value: SetStateAction<string[]>) => {
      setSelectedCustomerPortalOrderUuids(value);
      setSelectedEdiOrderUuids([]);
    },
    [],
  );

  const reviewableOrderType = isEmpty(selectedCustomerPortalOrderUuids)
    ? ReviewableOrderType.EDI_OR_API
    : ReviewableOrderType.CUSTOMER_PORTAL;

  return {
    selectedEdiOrderUuids,
    selectedCustomerPortalOrderUuids,
    reviewableOrderType,
    handleEdiOrderSelection,
    handleCustomerPortalOrderSelection,
  };
};

const ReviewIncomingOrders = () => {
  const {
    selectedEdiOrderUuids,
    selectedCustomerPortalOrderUuids,
    reviewableOrderType,
    handleEdiOrderSelection,
    handleCustomerPortalOrderSelection,
  } = useOrderSelection();

  const ffCustomerPortalOrders = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const { userPermissions } = useUserRoles();
  const { canRead: canReadEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );
  const { canRead: canReadCustomerPortalOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CustomerPortalOrders,
  );

  const [firstSelectedCustomerPortalOrderUuid] =
    selectedCustomerPortalOrderUuids;
  const [
    selectedEdiOrderUuidsWithPendingChanges,
    setSelectedEdiOrderUuidsWithPendingChanges,
  ] = useState<string[]>([]);
  const [loadingEdiOrders, setLoadingEdiOrders] = useState(false);

  const getCurrentOrderDetails = () => {
    switch (reviewableOrderType) {
      case ReviewableOrderType.EDI_OR_API: {
        if (!canReadEdiApiOrders) {
          return null;
        }
        return (
          <ReviewEdiOrderDetails
            selectedOrderUuids={selectedEdiOrderUuids}
            setSelectedOrderUuids={handleEdiOrderSelection}
            selectedOrderUuidsWithPendingChanges={
              selectedEdiOrderUuidsWithPendingChanges
            }
            setSelectedOrderUuidsWithPendingChanges={
              setSelectedEdiOrderUuidsWithPendingChanges
            }
            loadingOrders={loadingEdiOrders}
          />
        );
      }
      case ReviewableOrderType.CUSTOMER_PORTAL: {
        if (
          !ffCustomerPortalOrders ||
          !canReadCustomerPortalOrders ||
          isNil(firstSelectedCustomerPortalOrderUuid)
        ) {
          return null;
        }
        return (
          <OrderPage
            key={firstSelectedCustomerPortalOrderUuid}
            inCustomerPortalReview
            orderUuid={firstSelectedCustomerPortalOrderUuid}
          />
        );
      }
      default: {
        exhaustive(reviewableOrderType);
      }
    }
  };

  return (
    <Stack
      direction="row"
      gap={1}
      padding={2}
      height="100%"
      width="100%"
      justifyContent="space-between"
      minHeight={0}
    >
      <Card
        sx={{
          flexBasis: '35%',
          flexShrink: 0,
          overflowY: 'auto',
          minWidth: '400px',
        }}
      >
        {canReadEdiApiOrders && (
          <ReviewEdiOrdersTable
            selectedOrderUuids={selectedEdiOrderUuids}
            setSelectedOrderUuids={handleEdiOrderSelection}
            setLoadingEdiOrders={setLoadingEdiOrders}
            orderUuidsWithPendingChanges={
              selectedEdiOrderUuidsWithPendingChanges
            }
            setOrderUuidsWithPendingChanges={
              setSelectedEdiOrderUuidsWithPendingChanges
            }
          />
        )}
        {ffCustomerPortalOrders && canReadCustomerPortalOrders && (
          <ReviewCustomerPortalOrdersTable
            selectedOrderUuids={selectedCustomerPortalOrderUuids}
            setSelectedOrderUuids={handleCustomerPortalOrderSelection}
          />
        )}
      </Card>
      <Box sx={{ flexBasis: '65%', flexShrink: 0 }}>
        {getCurrentOrderDetails()}
      </Box>
    </Stack>
  );
};

export default ReviewIncomingOrders;
