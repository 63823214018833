import * as BulkActionsPopover from '../../../../pallet-ui/bulk-actions-popover/bulk-actions-popover';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import { useState, type Dispatch, type SetStateAction } from 'react';
import PrintLineHaulManifestsModal from './print-line-haul-manifests-modal';
import { useMarkManifestAsDepartedMutation } from '../../../../generated/graphql';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteManifestsConfirmationModal from './delete-manifests-confirmation-modal';
import { type Dayjs } from 'dayjs';
import { type NonEmptyArray } from './utils';

type ManifestsBulkUpdateProps = {
  readonly selectedManifestIds: Set<string>;
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
  readonly planningDate: Dayjs;
};
const ManifestsBulkUpdate = ({
  selectedManifestIds,
  setSelectedManifestIds,
  planningDate,
}: ManifestsBulkUpdateProps) => {
  const [showDeleteTruckloadModal, setShowDeleteTruckloadModal] =
    useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const onDeleteComplete = () => {
    setSelectedManifestIds(new Set());
  };

  // Mark manifests as departed
  const [markManifestAsDeparted, { loading: markDepartedLoading }] =
    useMarkManifestAsDepartedMutation();

  const markManifestsAsDeparted = async () => {
    setSelectedManifestIds(new Set());
    await Promise.all(
      [...selectedManifestIds].map(async (uuid) =>
        markManifestAsDeparted({
          variables: {
            markManifestAsDepartedInput: {
              uuid,
            },
          },
          update: (cache) => {
            cache.evict({ fieldName: 'lineHaulManifests' });
            cache.gc();
          },
        }),
      ),
    );
  };

  return (
    <>
      <DeleteManifestsConfirmationModal
        open={showDeleteTruckloadModal}
        setOpen={setShowDeleteTruckloadModal}
        manifestUuids={[...selectedManifestIds]}
        onDeleteComplete={onDeleteComplete}
      />
      <BulkActionsPopover.Root bottomOffset={28}>
        <BulkActionsPopover.Container>
          <BulkActionsPopover.Count count={selectedManifestIds.size} />
          <BulkActionsPopover.Option
            icon={<PrintRoundedIcon />}
            label="Print"
            disabled={markDepartedLoading}
            onClick={() => {
              setIsPrintModalOpen(true);
            }}
          />
          <BulkActionsPopover.BulkActionsOption
            icon={<ForwardRoundedIcon />}
            label="Mark departed"
            onClick={markManifestsAsDeparted}
          />
          <BulkActionsPopover.BulkActionsOption
            icon={<DeleteRoundedIcon />}
            label="Delete"
            disabled={markDepartedLoading}
            onClick={() => {
              setShowDeleteTruckloadModal(true);
            }}
          />
          <BulkActionsPopover.Close
            onClick={() => {
              setSelectedManifestIds(new Set());
            }}
          />
        </BulkActionsPopover.Container>
      </BulkActionsPopover.Root>
      {selectedManifestIds.size > 0 && (
        <PrintLineHaulManifestsModal
          open={isPrintModalOpen}
          setOpen={setIsPrintModalOpen}
          manifestUuids={[...selectedManifestIds] as NonEmptyArray<string>}
          planningDate={planningDate}
        />
      )}
    </>
  );
};

export default ManifestsBulkUpdate;
