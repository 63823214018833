import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';

dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Formats a stop attempt time to a human readable format
 */
export const formatStopAttemptTime = ({
  completedAt,
  companyTimezone,
}: {
  completedAt: Date | null | undefined;
  companyTimezone: string | null | undefined;
}) => {
  if (isNil(completedAt) || isNil(companyTimezone)) {
    return '';
  }
  return dayjs(completedAt).tz(companyTimezone).format('MM/DD/YYYY hh:mma');
};
