import { Button, Fade, Stack, TableCell, TableRow } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useServices from '../../../common/react-hooks/use-services';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type ServiceFragment,
  ServicesDocument,
  useUpdateServiceMutation,
} from '../../../generated/graphql';
import CreateOrEdit from '../enums/create-or-edit';
import { ArchiveableEntity } from './common/archive-action-component';
import ArchiveEntityModal from './common/archive-entity-modal';
import CreateOrEditService from './create-or-edit-service';

const ServiceRow = ({ service }: { readonly service: ServiceFragment }) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyServices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyServices,
  );

  const [isHovering, setIsHovering] = useState(false);
  const [showEditServiceModal, setShowEditServiceModal] = useState(false);
  const [showArchiveServiceModal, setShowArchiveServiceModal] = useState(false);
  const { loadServices } = useServices();
  const [updateService] = useUpdateServiceMutation({
    refetchQueries: [ServicesDocument],
  });

  const formatAppt = (start: Date, end: Date) => {
    if (isNil(start) || isNil(end)) {
      return '-';
    }
    return `${dayjs(start).format('hh:mm a')} to ${dayjs(end).format(
      'hh:mm a',
    )}`;
  };

  const handleArchiveOrUnarchive = async ({
    shouldArchive,
  }: {
    shouldArchive: boolean;
  }) => {
    await updateService({
      variables: {
        input: {
          serviceUpdateInput: {
            uuid: service.uuid,
            name: service.name,
            isActive: !shouldArchive,
          },
        },
      },
    });
    loadServices();
  };

  return (
    <>
      <ArchiveEntityModal
        entityType={ArchiveableEntity.SERVICE}
        entityIdentifier={sentenceCase(service.name)}
        open={showArchiveServiceModal}
        setOpen={setShowArchiveServiceModal}
        isCurrentlyArchived={!service.isActive}
        handleArchive={() => {
          handleArchiveOrUnarchive({ shouldArchive: true });
        }}
        handleUnarchive={() => {
          handleArchiveOrUnarchive({ shouldArchive: false });
        }}
      />
      {showEditServiceModal && (
        <CreateOrEditService
          open
          createOrEdit={CreateOrEdit.Edit}
          selectedService={service}
          setOpen={setShowEditServiceModal}
        />
      )}
      <TableRow
        key={service.uuid}
        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell component="th" scope="row">
          {sentenceCase(service.name)}
        </TableCell>
        <TableCell>{service.timeInMinutes ?? 'NA'}</TableCell>
        <TableCell>
          {service.useApptInRouteOptimization ? 'Yes' : 'No'}
        </TableCell>
        <TableCell>
          {formatAppt(
            service?.defaultAppointmentStartTime,
            service?.defaultAppointmentEndTime,
          )}
        </TableCell>
        <TableCell>{service.isExposedToCustomers ? 'Yes' : 'No'}</TableCell>
        <TableCell align="right">
          <Stack direction="row" gap={1}>
            <Fade in={isHovering}>
              <Button
                variant="contained"
                disabled={!canWriteCompanyServices}
                onClick={() => {
                  setShowEditServiceModal(true);
                }}
              >
                Edit
              </Button>
            </Fade>
            <Fade in={isHovering}>
              <Button
                variant={service.isActive ? 'contained' : 'outlined'}
                disabled={!canWriteCompanyServices}
                color={service.isActive ? 'error' : 'warning'}
                onClick={() => {
                  setShowArchiveServiceModal(true);
                }}
              >
                {service.isActive ? 'Archive' : 'Unarchive'}
              </Button>
            </Fade>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ServiceRow;
