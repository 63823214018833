import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  TextField,
  Stack,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import PhoneInputMask from '../../../../common/components/phone/phone-input-mask';
import {
  useUpdateWarehouseEmployeeMutation,
  type WarehouseEmployeeFragment,
  WarehouseEmployeesDocument,
} from '../../../../generated/graphql';
import { type WarehouseEmployeeFormValues } from './forms/types';
import useUpdateWarehouseEmployeeForm from './forms/use-edit-warehouse-employee-form';

type UpdateWarehouseEmployeeModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly setSuccessSnackbarVisible: Dispatch<SetStateAction<boolean>>;
  readonly setErrorSnackbarVisible: Dispatch<SetStateAction<boolean>>;

  readonly selectedWarehouseEmployee: WarehouseEmployeeFragment | undefined;
};

const UpdateWarehouseEmployeeModal = ({
  open,
  setOpen,
  setSuccessSnackbarVisible,
  setErrorSnackbarVisible,
  selectedWarehouseEmployee,
}: UpdateWarehouseEmployeeModalProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useUpdateWarehouseEmployeeForm();

  useEffect(() => {
    if (!isNil(selectedWarehouseEmployee)) {
      reset({
        firstName: selectedWarehouseEmployee.firstName,
        lastName: selectedWarehouseEmployee.lastName,
        emailAddress: selectedWarehouseEmployee.emailAddress,
        phoneNumber: selectedWarehouseEmployee.phoneNumber,
        password: selectedWarehouseEmployee.password ?? undefined,
        active: selectedWarehouseEmployee.active,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouseEmployee]);

  const handleClose = () => {
    reset();
    setShowErrorMessage(false);
    setOpen(false);
  };

  const [updateWarehouseEmployee, { loading: updateWarehouseEmployeeLoading }] =
    useUpdateWarehouseEmployeeMutation({
      refetchQueries: [WarehouseEmployeesDocument],
    });

  const onSubmit: SubmitHandler<WarehouseEmployeeFormValues> = async (data) => {
    const uuid = selectedWarehouseEmployee?.uuid;
    if (isNil(uuid) || isEmpty(uuid)) {
      setErrorSnackbarVisible(true);
      handleClose();
      return;
    }
    const { firstName, lastName, emailAddress, phoneNumber, password, active } =
      data;
    try {
      const response = await updateWarehouseEmployee({
        variables: {
          updateWarehouseEmployeeInput: {
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            password,
            active,
            uuid,
          },
        },
      });
      const createdWarehouseEmployee =
        response?.data?.updateWarehouseEmployee?.warehouseEmployee;
      if (isNil(createdWarehouseEmployee)) {
        setShowErrorMessage(true);
      } else {
        setSuccessSnackbarVisible(true);
        handleClose();
      }
    } catch {
      setErrorSnackbarVisible(true);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Update Warehouse Employee</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>First Name</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.firstName)}
                    helperText={errors?.firstName?.message}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Last Name</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.lastName)}
                    helperText={errors?.lastName?.message}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="emailAddress"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Email Address</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.emailAddress)}
                    helperText={errors?.emailAddress?.message}
                    sx={{ width: '100%' }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Phone Number</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.phoneNumber)}
                    helperText={errors?.phoneNumber?.message}
                    sx={{ width: '100%' }}
                    InputProps={{
                      inputComponent: PhoneInputMask as any,
                    }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel>Password</InputLabel>
                  <TextField
                    required
                    size="small"
                    value={value}
                    error={!isNil(errors.password)}
                    helperText={errors?.password?.message}
                    sx={{ width: '100%' }}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Controller
              control={control}
              name="active"
              defaultValue={false}
              render={({ field }) => (
                <FormControl fullWidth sx={{ paddingLeft: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    }
                    label="Active"
                  />
                </FormControl>
              )}
            />
          </Grid>
          {showErrorMessage && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Typography sx={{ color: 'red' }}>
                Error creating warehouse employee. Is the phone number unique?
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button
            variant="outlined"
            disabled={updateWarehouseEmployeeLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={updateWarehouseEmployeeLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateWarehouseEmployeeModal;
