import { useState, type Dispatch, type SetStateAction } from 'react';
import { type LineHaulManifest } from '../utils';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs, { type Dayjs } from 'dayjs';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import SkinnyHamburger from '@mui/icons-material/MoreVert';
import {
  LineHaulManifestsDocument,
  useCreateLineHaulManifestMutation,
} from '../../../../../generated/graphql';
import DeleteTruckloadModal from './delete-truckload-modal';
import PrintLineHaulManifestsModal from '../print-line-haul-manifests-modal';
import { useApolloClient } from '@apollo/client';
import DeleteManifestsConfirmationModal from '../delete-manifests-confirmation-modal';

type ManifestDetailsHeaderProps = {
  readonly manifest: LineHaulManifest;
  readonly setOpenedManifestUuid: Dispatch<SetStateAction<string | null>>;
  readonly planningDate: Dayjs;
  readonly isRefetching: boolean;
  readonly setSelectedManifestIds: Dispatch<SetStateAction<Set<string>>>;
};

const ManifestDetailsHeader = ({
  manifest,
  setOpenedManifestUuid,
  planningDate,
  isRefetching,
  setSelectedManifestIds,
}: ManifestDetailsHeaderProps) => {
  // Refetch manifests
  const client = useApolloClient();
  const refetchLineHaulManifests = async () => {
    await client.refetchQueries({
      include: [LineHaulManifestsDocument],
    });
  };

  // Modal states
  const [showPrintManifestsModal, setShowPrintManifestsModal] =
    useState<boolean>(false);
  const [showDeleteTruckloadModal, setShowDeleteTruckloadModal] =
    useState<boolean>(false);

  const [disableMenuSelection, setDisableMenuSelection] = useState(false);

  // Vertical menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Add truckload
  const [createManifest, { loading: createManifestLoading }] =
    useCreateLineHaulManifestMutation();
  const handleAddTruckload = async () => {
    await createManifest({
      variables: {
        createLineHaulManifestInput: {
          lineHaulManifestCreateInput: {
            startTerminalUuid: manifest.startTerminal.uuid,
            endTerminalUuid: manifest.endTerminal.uuid,
            departDate: planningDate.toISOString(),
            referenceNumber: manifest.referenceNumber,
          },
        },
      },
      onCompleted: async (res) => {
        // Disable the menu selection while the refetch is happening to prevent
        // the user from adding another truckload while the refetch is happening
        setDisableMenuSelection(true);
        setAnchorEl(null);
        await refetchLineHaulManifests();
        setOpenedManifestUuid(res.createLineHaulManifest.uuid);
        setDisableMenuSelection(false);
      },
    });
  };

  // Refetch manifests after deleting a manifest
  const onManifestDeleteComplete = async () => {
    // Remove the uuid from the selected manifests
    setSelectedManifestIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(manifest.uuid);
      return newSet;
    });
    setAnchorEl(null);
    await refetchLineHaulManifests();
  };

  const isLoading = createManifestLoading || isRefetching;

  return (
    <>
      <PrintLineHaulManifestsModal
        open={showPrintManifestsModal}
        setOpen={setShowPrintManifestsModal}
        manifestUuids={[manifest.uuid]}
        planningDate={planningDate}
      />
      <DeleteManifestsConfirmationModal
        open={showDeleteTruckloadModal}
        setOpen={setShowDeleteTruckloadModal}
        manifestUuids={[manifest.uuid]}
        onDeleteComplete={onManifestDeleteComplete}
      />
      <Stack p={1} direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1} alignItems="center">
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setOpenedManifestUuid(null);
            }}
          >
            Back
          </Button>
          <Stack>
            <Typography fontSize={18}>{manifest.referenceNumber}</Typography>
            <Typography color="grey" fontSize={12}>
              Depart date: {dayjs(planningDate).format('MM/DD')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            {isLoading && <CircularProgress size={20} />}
            <Button
              variant="contained"
              size="small"
              startIcon={<PrintRoundedIcon />}
              sx={{
                height: 'fit-content',
                alignSelf: 'flex-start',
                marginTop: 1,
              }}
              onClick={() => {
                setShowPrintManifestsModal(true);
              }}
            >
              Print
            </Button>
          </Stack>
          <IconButton
            sx={{ height: 'fit-content' }}
            disabled={disableMenuSelection}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <SkinnyHamburger />
          </IconButton>
          <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
            <MenuItem onClick={handleAddTruckload}>Add truckload</MenuItem>
            <Divider />
            <MenuItem
              sx={{ color: 'red' }}
              onClick={() => {
                setShowDeleteTruckloadModal(true);
              }}
            >
              Delete truckload
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </>
  );
};

export default ManifestDetailsHeader;
