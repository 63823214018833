import { type Dispatch, type SetStateAction } from 'react';
import PalletButtonGroup from '../../../../pallet-ui/button-group/pallet-button-group';
import { ActiveOrArchivedTab } from './enums';

export const ActiveArchivedButtonGroup = ({
  activeTab,
  setTab,
}: {
  readonly activeTab: ActiveOrArchivedTab;
  readonly setTab: Dispatch<SetStateAction<ActiveOrArchivedTab>>;
}) => {
  return (
    <PalletButtonGroup
      value={activeTab}
      options={[
        {
          label: ActiveOrArchivedTab.ACTIVE,
          value: ActiveOrArchivedTab.ACTIVE,
        },
        {
          label: ActiveOrArchivedTab.ARCHIVED,
          value: ActiveOrArchivedTab.ARCHIVED,
        },
      ]}
      onChange={(value: ActiveOrArchivedTab) => {
        setTab(value);
      }}
    />
  );
};
