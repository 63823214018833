import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
} from '@reduxjs/toolkit';
import type { RootState } from '../../../redux/store';
import { type FuelChargeFormField } from '../../orders/redux/drayage/order-form-types';

type FuelChargeSchema = {
  [k in keyof Required<FuelChargeFormField>]: boolean;
};

export const fuelChargeFieldIsRequired: FuelChargeSchema = {
  uuid: true,
  included: false,
  description: false,
  surchargeRate: false,
  type: false,
  total: true,
  flatRate: false,
  settlementFlatRate: false,
  settlementPercentageRate: false,
};

type FuelChargeValuesStoreState = FuelChargeFormField;

const fuelChargesValuesAdapter =
  createEntityAdapter<FuelChargeValuesStoreState>({
    selectId: (fuelCharge) => fuelCharge.uuid,
  });

export const fuelChargesValuesSlice = createSlice({
  name: 'fuelChargesValues',
  initialState: fuelChargesValuesAdapter.getInitialState(),
  reducers: {
    addFuelCharge: fuelChargesValuesAdapter.addOne,
    updateFuelCharge: fuelChargesValuesAdapter.updateOne,
    deleteFuelCharge: fuelChargesValuesAdapter.removeOne,
    updateFuelCharges: fuelChargesValuesAdapter.updateMany,
    loadedFuelCharges: fuelChargesValuesAdapter.setAll,
    upsertFuelCharge: fuelChargesValuesAdapter.upsertOne,
  },
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectFuelChargeById,

  selectIds: selectFuelChargeIds,
  // Pass in a selector that returns the posts slice of state
  selectAll: selectFuelCharges,
} = fuelChargesValuesAdapter.getSelectors(
  (state: RootState) => state.orderFormFuelChargesValues,
);

const fuelChargeValuesSelector = (state: RootState) =>
  state.orderFormFuelChargesValues;

export const selectFuelChargeBillingMethodByUuid = createSelector(
  selectFuelChargeById,
  (values: FuelChargeFormField | undefined) => {
    return values?.type;
  },
);

export const selectFuelChargesByIds = createSelector(
  fuelChargeValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state, entityIds) => {
    const entityResults: FuelChargeFormField[] = [];
    for (const id of entityIds) {
      const match = fuelChargesValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const {
  addFuelCharge,
  updateFuelCharge,
  loadedFuelCharges,
  upsertFuelCharge,
} = fuelChargesValuesSlice.actions;

export default fuelChargesValuesSlice.reducer;
