import styled from '@emotion/styled';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import type React from 'react';
import { useState } from 'react';
import { omitTypename } from '../../../../../../common/utils/graphql-input';
import {
  type CreateCsvOrdersOrderInput,
  type ParseCsvOrderRowErrorEntity,
  type ParseCsvOrdersOrderFragment,
  type ParseCsvOrdersOrderUpdateFragment,
  type ParseCsvOrdersStopFragment,
  type ParsedCsvOrderStopInput,
  type UpdateCsvOrdersOrderInput,
  useCreateOrUpdateCsvOrdersMutation,
} from '../../../../../../generated/graphql';
import { OrderUpdatesTable } from './order-updates-table';
import { OrdersTable } from './orders-table';

const ErrorListItem = styled(ListItem)`
  background: #ffb8b0;
  border-radius: 4px;
`;

enum ViewTab {
  Review,
  Errors,
}

const buildParsedStopInput = ({
  terminalName: _ignored,
  terminal,
  driverName: _ignored2,
  driverReferenceNumber: _ignored3,
  driver,
  ...stop
}: ParseCsvOrdersStopFragment): ParsedCsvOrderStopInput => ({
  ...omitTypename(stop),
  terminalUuid: terminal?.uuid,
  driverUuid: driver?.uuid,
  appointmentStartTime: isNil(stop.appointmentStartTime)
    ? null
    : omitTypename(stop.appointmentStartTime),
  appointmentEndTime: isNil(stop.appointmentEndTime)
    ? null
    : omitTypename(stop.appointmentEndTime),
});

const buildCreateCsvOrdersOrderInput = ({
  billingPartyContact,
  // customerReferenceNumber cannot be passed along in the input.
  customerReferenceNumber: _ignored,
  serviceLevel,
  inboundStop,
  outboundStop,
  packages,
  ...csvOrder
}: ParseCsvOrdersOrderFragment): CreateCsvOrdersOrderInput => ({
  ...omitTypename(csvOrder),
  billingPartyContactUuid: billingPartyContact.uuid,
  serviceUuid: serviceLevel?.uuid,
  inboundStop: isNil(inboundStop) ? null : buildParsedStopInput(inboundStop),
  outboundStop: isNil(outboundStop) ? null : buildParsedStopInput(outboundStop),
  packages: packages.map(({ packageSpec, ...pkg }) => ({
    ...omitTypename(pkg),
    packageSpecId: packageSpec?.id,
  })),
});

const buildUpdateCsvOrdersOrderInput = ({
  uuid,
  packages,
}: ParseCsvOrdersOrderUpdateFragment): UpdateCsvOrdersOrderInput => ({
  uuid,
  packages: packages.map(({ packageSpec, ...pkg }) => ({
    ...omitTypename(pkg),
    packageSpecId: packageSpec?.id,
  })),
});

export type ParseResultData = {
  readonly parsedOrders: ParseCsvOrdersOrderFragment[];
  readonly parsedOrderUpdates: ParseCsvOrdersOrderUpdateFragment[];
  readonly errors: ParseCsvOrderRowErrorEntity[];
  readonly enableInboundStop: boolean;
  readonly enableOutboundStop: boolean;
};

type CreateCsvOrdersConfirmationMessageProps = {
  readonly closeModal: () => void;
  readonly setShouldRefreshGrid: (show: boolean) => void;
  readonly setShowAcceptedCsvOrdersSuccessMessage: (show: boolean) => void;
  readonly setShowRejectedCsvOrdersSuccessMessage: (show: boolean) => void;
  readonly setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
} & ParseResultData;

const CreateCsvOrdersConfirmationMessage = ({
  parsedOrders,
  parsedOrderUpdates,
  errors,
  enableInboundStop,
  enableOutboundStop,
  closeModal,
  setShouldRefreshGrid,
  setShowAcceptedCsvOrdersSuccessMessage,
  setShowRejectedCsvOrdersSuccessMessage,
  setErrorMessage,
}: CreateCsvOrdersConfirmationMessageProps) => {
  const [selectedTab, setSelectedTab] = useState<ViewTab>(ViewTab.Review);

  const [createOrUpdateCsvOrders, { loading: createOrUpdateCsvOrdersLoading }] =
    useCreateOrUpdateCsvOrdersMutation();

  const handleCreateOrUpdateCsvOrder = async () => {
    try {
      const { data } = await createOrUpdateCsvOrders({
        variables: {
          createOrUpdateCsvOrdersInput: {
            createOrders: parsedOrders.map(buildCreateCsvOrdersOrderInput),
            updateOrders: parsedOrderUpdates.map(
              buildUpdateCsvOrdersOrderInput,
            ),
          },
        },
      });
      if (isNil(data)) {
        setErrorMessage('Failed to confirm CSV orders');
      } else if (
        data.createOrUpdateCsvOrders.__typename ===
        'CreateOrUpdateCsvOrdersSuccessOutput'
      ) {
        setShouldRefreshGrid(true);
        setShowAcceptedCsvOrdersSuccessMessage(true);
        closeModal();
      } else {
        setErrorMessage(data.createOrUpdateCsvOrders.message);
      }
    } catch (error) {
      setErrorMessage('Failed to confirm CSV orders');
      // eslint-disable-next-line no-console
      console.error('Error confirming CSV orders', error);
    }
  };

  const handleReject = async () => {
    setShowRejectedCsvOrdersSuccessMessage(true);
    closeModal();
  };

  const numberOfOrdersToCreate = parsedOrders.length;
  const numberOfOrdersToUpdate = parsedOrderUpdates.length;
  const numberOfErroredOrders = errors.length;

  let summaryMessage = '';
  if (numberOfOrdersToCreate > 0) {
    summaryMessage = `Creating ${numberOfOrdersToCreate} ${pluralize('order', numberOfOrdersToCreate)}.`;
  }
  if (numberOfOrdersToUpdate > 0) {
    if (summaryMessage !== '') {
      summaryMessage += ' ';
    }
    summaryMessage += `Updating ${numberOfOrdersToUpdate} ${pluralize('order', numberOfOrdersToUpdate)}.`;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100%',
        gap: 1,
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 700 }}>
        {summaryMessage}
      </Typography>
      {createOrUpdateCsvOrdersLoading && (
        <Stack direction="column" gap={1} sx={{ width: '80%' }}>
          <Typography variant="body1">Creating/updating orders...</Typography>
          <LinearProgress color="primary" />
        </Stack>
      )}
      <Tabs
        value={selectedTab}
        onChange={(_e, newValue) => {
          if (newValue === ViewTab.Review || newValue === ViewTab.Errors) {
            setSelectedTab(newValue);
          }
        }}
      >
        <Tab
          label={`Preview Orders (${numberOfOrdersToCreate + numberOfOrdersToUpdate})`}
        />
        <Tab label={`Errors (${numberOfErroredOrders})`} />
      </Tabs>
      {selectedTab === ViewTab.Review && (
        <>
          <Stack
            sx={{
              flexGrow: 1,
              maxHeight: '630px',
              width: '100%',
              overflow: 'auto',
              gap: 2,
            }}
          >
            {!isEmpty(parsedOrders) && (
              <Box>
                <Typography
                  variant="h5"
                  fontSize="17px"
                  sx={{ mb: '11px', fontWeight: 500 }}
                >
                  Orders to Create
                </Typography>
                <OrdersTable
                  orders={parsedOrders}
                  enableInboundStop={enableInboundStop}
                  enableOutboundStop={enableOutboundStop}
                />
              </Box>
            )}
            {!isEmpty(parsedOrderUpdates) && (
              <Box>
                <Typography
                  variant="h5"
                  fontSize="17px"
                  sx={{ mb: '11px', fontWeight: 500 }}
                >
                  Orders to Update
                </Typography>
                <OrderUpdatesTable orders={parsedOrderUpdates} />
              </Box>
            )}
          </Stack>
          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              disabled={
                createOrUpdateCsvOrdersLoading ||
                (numberOfOrdersToCreate === 0 && numberOfOrdersToUpdate === 0)
              }
              onClick={handleCreateOrUpdateCsvOrder}
            >
              Confirm Orders
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={
                createOrUpdateCsvOrdersLoading ||
                (numberOfOrdersToCreate === 0 && numberOfOrdersToUpdate === 0)
              }
              onClick={handleReject}
            >
              Reject Orders
            </Button>
          </Stack>
        </>
      )}
      {selectedTab === ViewTab.Errors && (
        <List
          sx={{
            width: '100%',
            maxHeight: '630px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {errors.map((error) => {
            return (
              <ErrorListItem key={error.row}>
                <ListItemText>
                  <strong>{`Row ${error.row}`}</strong>
                </ListItemText>
                <ListItemText>
                  <strong>{`${error.message}`}</strong>
                </ListItemText>
              </ErrorListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default CreateCsvOrdersConfirmationMessage;
