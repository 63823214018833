import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
} from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import { isEmpty, isNil } from 'lodash';
import { type ReactNode, type SetStateAction } from 'react';
import type React from 'react';
import { useCallback, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { useShallow } from 'zustand/react/shallow';
import { shallow } from 'zustand/shallow';
import DateDropdownPicker, {
  type DateOption,
  type DatePickerFilterType,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { downloadFile } from '../../../../common/utils/file';
import {
  FilterViewPage,
  type FormattedOrderFragment,
  GetSavedFilterViewsDocument,
  type OrderSortV2,
  type OrderTableField,
  type GetOrderTableFieldValuesQueryVariables,
  PermissionResource,
  type SavedFilterViewFragment,
  useCreateSavedFilterViewMutation,
  useDeleteSavedFilterViewMutation,
  useGetSavedFilterViewsQuery,
  useUpdateSavedFilterViewMutation,
  useCreateOrderTableReportMutation,
  type CreateOrderTableReportMutationVariables,
  type GetOrderTableFieldValuesInput,
} from '../../../../generated/graphql';
import AddNewOrderOptionsButton from '../../../orders/components/add-new-order-options-button';
import BulkActionsModal from '../../../orders/components/bulk-action/bulk-actions-modal';
import { type OrderFilterField } from '../../../orders/components/enums/order-filters';
import useOrdersPageStore from '../../../orders/orders-table-store';
import CreateNewSavedViewModal from '../../../saved-filter-views/components/create-new-saved-view-modal';
import useFilterStore from '../../filter-store';
import { TABLE_CONFIG_PILL_HEIGHT } from '../../table-configuration-pills';
import { useNewTableFunctionsFeatureFlag } from '../../use-new-table-functions-feature-flag';
import { useRememberedOrdersFilters } from '../../use-remembered-orders-filters';
import ViewChangedButtonsNew from '../../view-changed-buttons-new';
import { useStyles } from '../styles';
import {
  type DefaultFilterTabsConfigs,
  type OrderTableFilterModel,
  type ReadOnlyRefObject,
  type OrdersTableWithFiltersState,
} from '../types';
import {
  getOrderSortV2,
  getOrderTableFilterModel,
  isLegacyFilterModel,
  migrateLegacyFilterModelKeys,
} from '../utils';
import ConfigureOrderColumnsNew from './columns/configure-order-columns-new';
import FilterButton from './filters/filter-button';
import FilterRow from './filters/filter-row';
import OrderTableFunctionButton from './order-table-function-button';
import OrderTableFunctionsMenu from './order-table-functions-menu';
import { OrderTableFunctionsPills } from './order-table-functions-pills';
import OrderTableSearchInput from './order-table-search-input';
import SortButton from './sorts/sort-button';
import OrderTableTabsNew from './views/order-table-tabs-new';
import StyledIconButton from './table-function-icon-button';
import { AddToLineHaulManifestButton } from '../../../line-haul/components/add-to-line-haul-manifest-button';

type OrdersTableFunctionsContainerNewProps<DefaultFilterTabsType> = {
  // General props
  readonly gridRef: React.RefObject<AgGridReact<FormattedOrderFragment>>;
  readonly stateRef: ReadOnlyRefObject<
    OrdersTableWithFiltersState<DefaultFilterTabsType>
  >;
  readonly setState: (
    value: React.SetStateAction<
      OrdersTableWithFiltersState<DefaultFilterTabsType>
    >,
  ) => void;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>;
  readonly orderTableFields: OrderTableField[];
  readonly onSaveOrderTableColumns: (
    fields: OrderTableField[],
  ) => Promise<void>;
  readonly defaultViewOrderTableFields: OrderTableField[];
  readonly columnDefs: Array<ColDef<FormattedOrderFragment>>;
  readonly getOrderTableFieldValuesInput:
    | GetOrderTableFieldValuesInput
    | undefined;
  readonly changedSortModel: OrderSortV2[] | null;
  readonly setChangedSortModel: React.Dispatch<
    SetStateAction<OrderSortV2[] | null>
  >;
  readonly changedOrderTableFields: OrderTableField[] | null;
  readonly setChangedOrderTableFields: React.Dispatch<
    SetStateAction<OrderTableField[] | null>
  >;
  readonly refreshGrid: (
    // Defaults to true.
    shouldDeselectCheckboxes?: boolean,
    // Defaults to true.
    refreshServerSide?: boolean,
  ) => void;
  readonly applyFilterModel: (
    orderTableFilterModel: OrderTableFilterModel | null,
  ) => void;
  readonly applySortModel: (sortModel: OrderSortV2[]) => void;
  readonly setTableColumns: (fields: OrderTableField[]) => void;
  readonly enableNumberOfOrdersQuery?: boolean;
  readonly controlBarSpacing?: boolean;
  readonly pageType: FilterViewPage;
  readonly topRightComponent?: ReactNode;
  readonly tabsNumberOfOrderData: Array<{
    tab: DefaultFilterTabsType;
    numberOfOrders: number | undefined;
  }>;
  // General filter props
  readonly handleUpdateExistingSavedView: ({
    isAutoSave,
  }: {
    isAutoSave?: boolean;
  }) => Promise<void>;
  readonly numFiltersChanged: number;
  readonly setNumFiltersChanged: React.Dispatch<SetStateAction<number>>;
  readonly numSortsChanged: number;
  readonly setNumSortsChanged: React.Dispatch<SetStateAction<number>>;
  readonly numColumnsChanged: number;
  readonly setNumColumnsChanged: React.Dispatch<SetStateAction<number>>;
  readonly savedViewSaveSuccessAlertText: string | undefined;
  readonly setSavedViewSaveSuccessAlertText: React.Dispatch<
    SetStateAction<string | undefined>
  >;
  readonly savedViewSaveFailedAlert: boolean;
  readonly setSavedViewSaveFailedAlert: React.Dispatch<SetStateAction<boolean>>;
  // Date filter props
  readonly shouldShowDatePicker?: boolean;
  readonly defaultDatePickerFilterType?: DatePickerFilterType;
  readonly handleDatePickerChange: (option: DateOption) => void;
  // Customer filter props
  readonly shouldShowAddOrderButton?: boolean;
  // Search props
  readonly handleSearch: (searchText?: string) => void;
  readonly multipleSearches: string[];
  readonly useMultiSearchText?: boolean;
  // Bulk actions props
  readonly bulkActionsEnabled?: boolean;
  readonly bulkActionsOverLimit: boolean;
  readonly selectedUuids: string[];
  readonly deselectAll: () => void;
  // Views props
  readonly shouldAllowSavedViews?: boolean;
  readonly shouldRememberFilters?: boolean;
  // Report props
  readonly buildOrderTableReportInput: () => GetOrderTableFieldValuesQueryVariables;
  readonly shouldShowGenerateReportButtons?: boolean;
  readonly filterModelV2: OrderTableFilterModel;
  readonly setFilterModelV2: (newFilterModel: OrderTableFilterModel) => void;
};

export const OrdersTableFunctionsContainerNew = <
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  gridRef,
  stateRef,
  setState,
  defaultFilterTabsConfigs,
  orderTableFields,
  onSaveOrderTableColumns,
  defaultViewOrderTableFields,
  columnDefs,
  getOrderTableFieldValuesInput,
  changedSortModel,
  setChangedSortModel,
  changedOrderTableFields,
  setChangedOrderTableFields,
  refreshGrid,
  applyFilterModel,
  applySortModel,
  setTableColumns,
  enableNumberOfOrdersQuery,
  controlBarSpacing = true,
  pageType,
  topRightComponent,
  tabsNumberOfOrderData,
  handleUpdateExistingSavedView,
  numFiltersChanged,
  setNumFiltersChanged,
  numSortsChanged,
  setNumSortsChanged,
  numColumnsChanged,
  setNumColumnsChanged,
  savedViewSaveSuccessAlertText,
  setSavedViewSaveSuccessAlertText,
  savedViewSaveFailedAlert,
  setSavedViewSaveFailedAlert,
  shouldShowDatePicker = false,
  defaultDatePickerFilterType,
  handleDatePickerChange,
  shouldShowAddOrderButton,
  handleSearch,
  multipleSearches,
  useMultiSearchText,
  bulkActionsEnabled,
  bulkActionsOverLimit,
  selectedUuids,
  deselectAll,
  shouldAllowSavedViews = false,
  shouldRememberFilters = false,
  buildOrderTableReportInput,
  shouldShowGenerateReportButtons = true,
  filterModelV2,
  setFilterModelV2,
}: OrdersTableFunctionsContainerNewProps<DefaultFilterTabsType>) => {
  const styles = useStyles();
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);
  const [configureOrderColumnsAnchorEl, setConfigureOrderColumnsAnchorEl] =
    useState<HTMLElement | null>(null);

  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const [updateSavedViewMutation] = useUpdateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const [createSavedViewMutation] = useCreateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const [deleteSavedViewMutation] = useDeleteSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });

  const [
    bulkActionEditConfirmAlertMessage,
    setBulkActionEditConfirmAlertMessage,
  ] = useState<string>();
  const [showCreateNewSavedViewModal, setShowCreateNewSavedViewModal] =
    useState(false);
  const [showBulkActionsModal, setShowBulkActionsModal] =
    useState<boolean>(false);
  const [newViewIsFromScratch, setNewViewIsFromScratch] = useState(true);

  const rememberedFilters = useRememberedOrdersFilters(pageType);
  const { setRememberedTabs } = useFilterStore(
    useShallow((state) => ({ setRememberedTabs: state.setTabs })),
  );

  const viewChanged =
    numFiltersChanged > 0 || numSortsChanged > 0 || numColumnsChanged > 0;

  const bulkActionsTotalCount = isEmpty(selectedUuids)
    ? stateRef.current.totalCount
    : selectedUuids.length;

  const onChangeTab = useCallback(
    ({
      newTab,
      newView,
      shouldApplyFilter = true,
      shouldApplySort = true,
      shouldApplyColumns = true,
    }: {
      newTab: string | DefaultFilterTabsType;
      newView: Omit<SavedFilterViewFragment, 'user'> | undefined;
      shouldApplyFilter?: boolean;
      shouldApplySort?: boolean;
      shouldApplyColumns?: boolean;
    }) => {
      if (shouldAllowSavedViews && !isNil(newView)) {
        let newFilterModel = JSON.parse(newView.filterModelJson);
        if (isLegacyFilterModel(newFilterModel)) {
          // Even if the feature flag is on, existing views in the DB
          // or local storage might not have been migrated
          newFilterModel = migrateLegacyFilterModelKeys(newFilterModel);
        }
        setState((prevState) => {
          return {
            ...prevState,
            currentTabIsSavedView: true,
            currentTabIsNewView: false,
            ordersTab: newTab,
            currentCursor: null,
            orderTableFilterModel: newFilterModel,
            customSortModelJson:
              (newView.sortModelJson as OrderSortV2[] | null | undefined) ?? [],
            orderTableFields: newView.orderTableFields,
            currentSavedViewUuid: newView.uuid,
            currentSavedViewName: newView.displayName,
          };
        });
        if (shouldRememberFilters) {
          setRememberedTabs(JSON.stringify(newView), pageType, 'custom');
        }
        if (shouldApplyFilter) {
          applyFilterModel(newFilterModel);
        }
        if (shouldApplySort) {
          applySortModel(
            (newView.sortModelJson as OrderSortV2[] | null | undefined) ?? [],
          );
        }
        if (shouldApplyColumns) {
          setTableColumns(newView.orderTableFields);
        }
      } else {
        const currentDefaultTab = defaultFilterTabsConfigs.tabs.find(
          (tab) => tab.value === newTab,
        );
        const newFilterModel =
          (currentDefaultTab?.filterModel as OrderTableFilterModel) ?? {};

        setState((prevState) => {
          return {
            ...prevState,
            currentTabIsSavedView: false,
            currentTabIsNewView: false,
            ordersTab: newTab,
            currentCursor: null,
            orderTableFilterModel: newFilterModel,
            customSortModelJson: currentDefaultTab?.sortModel ?? [],
            orderTableFields: defaultViewOrderTableFields,
            currentSavedViewUuid: null,
            currentSavedViewName: null,
          };
        });
        if (shouldRememberFilters) {
          setRememberedTabs(
            JSON.stringify(currentDefaultTab),
            pageType,
            'default',
          );
        }
        if (shouldApplyFilter) {
          applyFilterModel(newFilterModel);
        }
        if (shouldApplySort) {
          applySortModel(currentDefaultTab?.sortModel ?? []);
        }
        if (shouldApplyColumns) {
          setTableColumns(defaultViewOrderTableFields);
        }
      }
    },
    [
      shouldAllowSavedViews,
      shouldRememberFilters,
      defaultFilterTabsConfigs.tabs,
      defaultViewOrderTableFields,
      pageType,
      applyFilterModel,
      applySortModel,
      setTableColumns,
      setRememberedTabs,
      setState,
    ],
  );

  const { data: savedViewsData } = useGetSavedFilterViewsQuery({
    variables: { pageType },
    onCompleted: (data) => {
      const savedViewTab: SavedFilterViewFragment | undefined =
        data?.getSavedFilterViews.find(
          (view) => view.uuid === stateRef.current.ordersTab,
        );

      onChangeTab({
        newTab: stateRef.current.ordersTab,
        newView: savedViewTab,
        // Don't apply the view's default filter model if there are local
        // changes, since we'll apply that changed filter model below
        shouldApplyFilter: isNil(rememberedFilters),
        shouldApplySort: isEmpty(changedSortModel),
        shouldApplyColumns: isEmpty(changedOrderTableFields),
      });

      // Read unsaved filter model changes from local storage.
      // We wait to load the saved filter view data first so that we can
      // avoid overwriting the local storage changes when the view data loads
      if (!isNil(rememberedFilters)) {
        applyFilterModel(rememberedFilters);
      }

      if (!isNil(changedSortModel) && !isEmpty(changedSortModel)) {
        applySortModel(changedSortModel);
      }

      if (
        !isNil(changedOrderTableFields) &&
        !isEmpty(changedOrderTableFields)
      ) {
        setTableColumns(changedOrderTableFields);
      }
    },
  });

  const handleResetViewEdits = useCallback(() => {
    applyFilterModel(stateRef.current.orderTableFilterModel);
    applySortModel(stateRef.current.customSortModelJson);
    setTableColumns(stateRef.current.orderTableFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateRef.current.orderTableFilterModel,
    stateRef.current.customSortModelJson,
    stateRef.current.orderTableFields,
  ]);

  const showCreateSavedViewModal = useCallback(
    async (viewIsFromScratch: boolean) => {
      setNewViewIsFromScratch(viewIsFromScratch);
      setShowCreateNewSavedViewModal(true);
    },
    [],
  );

  const handleCreateNewSavedView = async (
    displayName: string,
    viewIsFromScratch: boolean,
  ) => {
    let filterModel: OrderTableFilterModel | null;
    if (ffEnableNewTableFunctions) {
      filterModel = viewIsFromScratch ? null : (filterModelV2 ?? {});
    } else {
      filterModel = getOrderTableFilterModel(
        viewIsFromScratch ? null : gridRef.current,
      );
    }

    const orderSortV2 = ffEnableNewTableFunctions
      ? getOrderSortV2(gridRef.current)
      : null;
    try {
      const newViewInput = {
        displayName,
        filterModelJson: JSON.stringify(filterModel ?? {}),
        sortModelJson: orderSortV2 ?? [],
        savedViewPageType: pageType,
        orderTableFields: ffEnableNewTableFunctions ? orderTableFields : [],
      };
      const createView = await createSavedViewMutation({
        variables: {
          createSavedFilterViewInput: newViewInput,
        },
        refetchQueries: [GetSavedFilterViewsDocument],
      });
      const newUuid = createView.data?.createSavedFilterView.uuid;
      if (isNil(newUuid)) {
        throw new Error('[create new filter view] no UUID returned');
      }
      setNumFiltersChanged(0);
      setNumSortsChanged(0);
      setNumColumnsChanged(0);
      setChangedSortModel(null);
      setChangedOrderTableFields(null);
      onChangeTab({
        newTab: newUuid,
        newView: {
          uuid: newUuid,
          ...newViewInput,
          isShared: false,
          dispatchTableFields: [],
        },
      });
      setSavedViewSaveSuccessAlertText(`Created ${displayName} successfully`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating filter view', error);
      setSavedViewSaveFailedAlert(true);
    }
  };

  const handleEditSavedViewName = useCallback(
    async (uuid: string, displayName: string) => {
      try {
        await updateSavedViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid,
              displayName,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setState((prevState) => {
          return {
            ...prevState,
            currentFilterViewName:
              uuid === stateRef.current.currentSavedViewUuid
                ? displayName
                : stateRef.current.currentSavedViewName,
            currentCursor: null,
          };
        });
        setSavedViewSaveSuccessAlertText(`Saved ${displayName} successfully`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving filter view', error);
        setSavedViewSaveFailedAlert(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateRef?.current?.currentSavedViewUuid],
  );

  const saveFiltersToExistingView = useCallback(async () => {
    await handleUpdateExistingSavedView({
      isAutoSave: false,
    });
  }, [handleUpdateExistingSavedView]);

  const handleSetIsViewShared = useCallback(
    async (uuid: string, isShared: boolean) => {
      try {
        await updateSavedViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid,
              isShared,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: null,
          };
        });
        const displayName = savedViewsData?.getSavedFilterViews.find(
          (view) => view.uuid === uuid,
        )?.displayName;
        if (!isNilOrEmptyString(displayName)) {
          if (isShared) {
            setSavedViewSaveSuccessAlertText(`Shared ${displayName}`);
          } else {
            setSavedViewSaveSuccessAlertText(`Unshared ${displayName}`);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating filter view', error);
        setSavedViewSaveFailedAlert(true);
      }
    },
    [
      setState,
      savedViewsData?.getSavedFilterViews,
      updateSavedViewMutation,
      setSavedViewSaveFailedAlert,
      setSavedViewSaveSuccessAlertText,
    ],
  );

  const handleDeleteSavedView = useCallback(
    async (uuid: string) => {
      await deleteSavedViewMutation({
        variables: {
          uuid,
        },
        refetchQueries: [GetSavedFilterViewsDocument],
      });
      setNumFiltersChanged(0);
      setNumSortsChanged(0);
      setNumColumnsChanged(0);
      setChangedSortModel(null);
      setChangedOrderTableFields(null);
      onChangeTab({
        newTab: defaultFilterTabsConfigs.defaultTab,
        newView: undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDeleteFilter = useCallback(
    async (field: OrderFilterField) => {
      // reset the filter
      gridRef.current?.api.destroyFilter(field);

      // collapse that filter in the panel.
      const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
      toolPanel?.collapseFilters([field]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClickSelectedFilter = useCallback(
    async (field: OrderFilterField) => {
      // open the toolpanel and show that filter
      const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
      toolPanel?.expandFilters([field]);
      toolPanel?.setFilterLayout([
        columnDefs.find((colDef) => colDef.field === field) ?? {},
      ]);
      gridRef.current?.api.openToolPanel('filters');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnDefs],
  );

  const [createOrderTableReport, { loading: createOrderTableReportLoading }] =
    useCreateOrderTableReportMutation();

  const [setShowReportBeingEmailedMessage, setShowTableReportErrorMessage] =
    useOrdersPageStore(
      (state) => [
        state.setShowReportBeingEmailedMessage,
        state.setShowTableReportErrorMessage,
      ],
      shallow,
    );
  const fetchOrderTableReport = async () => {
    const variables = buildOrderTableReportInput();
    const orderTableReportArgs: CreateOrderTableReportMutationVariables = {
      createOrderTableReportInput: {
        ...variables.getOrderTableFieldValuesInput,
        orderTableFields,
        searchText:
          useMultiSearchText === true
            ? undefined
            : stateRef?.current?.searchText?.trim(),
        noMaximum: true,
        ...(selectedUuids.length > 0 && { uuids: selectedUuids }),
      },
    };
    try {
      const orderTableReportResponse = await createOrderTableReport({
        variables: orderTableReportArgs,
      });

      const orderTableReportResponseData =
        orderTableReportResponse.data?.createOrderTableReport;

      switch (orderTableReportResponseData?.__typename) {
        case 'OrderTableReportDownloadOutput': {
          const url = orderTableReportResponseData?.reportUrl;
          if (!isNilOrEmptyString(url)) {
            await downloadFile({ url });
          }

          break;
        }
        case 'OrderTableReportEmailToUserOutput': {
          setShowReportBeingEmailedMessage(true);

          break;
        }
        case 'MutationErrorOutput': {
          setShowTableReportErrorMessage(true);

          break;
        }
        // No default
      }
    } catch {
      setShowTableReportErrorMessage(true);
    }
  };

  const ORDERS_TABLE_TABS_COMPONENT = (
    <OrderTableTabsNew
      pageType={pageType}
      canWriteOrders={canWriteOrders}
      savedViews={savedViewsData?.getSavedFilterViews}
      currentTab={stateRef?.current?.ordersTab}
      defaultFilterTabsConfigs={defaultFilterTabsConfigs}
      tabsNumberOfOrdersData={
        enableNumberOfOrdersQuery === true ? tabsNumberOfOrderData : undefined
      }
      shouldAllowSavedViews={shouldAllowSavedViews}
      handleEditSavedViewName={handleEditSavedViewName}
      handleSetIsViewShared={handleSetIsViewShared}
      handleDeleteSavedView={handleDeleteSavedView}
      showCreateSavedViewModal={showCreateSavedViewModal}
      onChangeTab={onChangeTab}
    />
  );

  const buildOrdersTableSearchComponent = ({
    width,
  }: {
    width?: string | number;
  }) => {
    return (
      <>
        <OrderTableSearchInput
          hideSearchButton
          pageType={pageType}
          width={width}
          initialSearchText={stateRef?.current?.searchText}
          currentViewName={stateRef?.current?.currentSavedViewName}
          currentTab={stateRef?.current?.ordersTab?.toString()}
          onSearch={handleSearch}
        />
        {useMultiSearchText === true && (
          <Stack direction="row" spacing={1} sx={{ mb: 1, ml: 1 }}>
            {multipleSearches.map((search, existingIndex) => (
              <Chip
                // eslint-disable-next-line react/no-array-index-key
                key={`${search}-${existingIndex}`}
                label={search}
                onDelete={() => {
                  setState((prevState) => ({
                    ...prevState,
                    multipleSearches: multipleSearches.filter(
                      (itrSearch, itrIdx) =>
                        itrSearch !== search && existingIndex !== itrIdx,
                    ),
                    currentCursor: null,
                    searchText: '',
                  }));
                  handleSearch();
                }}
              />
            ))}
            {multipleSearches.length > 0 && (
              <Button
                variant="outlined"
                sx={{ whiteSpace: 'no-wrap', textAlign: 'center' }}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    multipleSearches: [],
                    currentCursor: null,
                    searchText: '',
                  }));
                  handleSearch();
                }}
              >
                Clear ({multipleSearches.length})
              </Button>
            )}
          </Stack>
        )}
      </>
    );
  };

  const DESELECT_ALL_BUTTON_COMPONENT =
    bulkActionsEnabled === true && selectedUuids.length > 0 ? (
      <Tooltip title="Clear Selection">
        <IconButton
          color="error"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            deselectAll();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    ) : null;

  const BULK_ACTIONS_COMPONENT =
    bulkActionsEnabled === true ? (
      <OrderTableFunctionButton
        size="small"
        variant="outlined"
        disabled={
          bulkActionsOverLimit || bulkActionsTotalCount === 0 || !canWriteOrders
        }
        onClick={() => {
          setShowBulkActionsModal(true);
        }}
      >
        Bulk Actions ({bulkActionsTotalCount ?? '-'})
      </OrderTableFunctionButton>
    ) : null;

  const SORT_COMPONENT = (
    <SortButton
      gridRef={gridRef}
      orderTableFields={orderTableFields}
      hasSorts={
        !isEmpty(
          isNil(changedSortModel)
            ? stateRef.current.customSortModelJson
            : changedSortModel,
        )
      }
      hasChanges={!isNil(changedSortModel)}
    />
  );

  const TABLE_FUNCTION_PILLS_COMPONENT = ffEnableNewTableFunctions ? (
    <OrderTableFunctionsPills
      gridRef={gridRef}
      pageType={pageType}
      filterModelV2={filterModelV2}
      setFilterModelV2={setFilterModelV2}
      orderTableFields={orderTableFields}
      rightContent={
        viewChanged && (
          <ViewChangedButtonsNew
            resetView={handleResetViewEdits}
            shouldAllowSavedViews={shouldAllowSavedViews}
            saveView={saveFiltersToExistingView}
            showCreateViewModal={showCreateSavedViewModal}
            currentSavedViewUuid={stateRef.current.currentSavedViewUuid}
          />
        )
      }
    />
  ) : (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '10px',
        p: '7px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          overflowX: 'auto',
        }}
      >
        <Button
          size="small"
          startIcon={<Add />}
          sx={styles.filterButton}
          variant="outlined"
          onClick={(_e) => {
            gridRef.current?.api.openToolPanel('filters');
          }}
        >
          Filter
        </Button>
        <FilterRow
          filterModel={gridRef.current?.api?.getFilterModel()}
          defaultFilterTabsConfigs={defaultFilterTabsConfigs}
          currentTab={stateRef?.current?.ordersTab}
          handleDeleteFilter={handleDeleteFilter}
          handleClickSelectedFilter={handleClickSelectedFilter}
          columnDefs={columnDefs}
        />
      </Box>
    </Box>
  );

  const FILTER_BUTTON_COMPONENT = (
    <FilterButton
      filterModelV2={filterModelV2}
      setFilterModelV2={setFilterModelV2}
      hasChanges={numFiltersChanged > 0}
      pageType={pageType}
    />
  );

  const CONFIGURE_ORDERS_TABLE_COLUMNS_COMPONENT = (
    <ConfigureOrderColumnsNew
      anchorEl={configureOrderColumnsAnchorEl}
      initialFields={orderTableFields}
      onClose={() => {
        setConfigureOrderColumnsAnchorEl(null);
      }}
      onSaveOrderTableColumns={onSaveOrderTableColumns}
    />
  );

  const ORDERS_TABLE_COLUMNS_BUTTON_COMPONENT = (
    <OrderTableFunctionButton
      size="small"
      variant="outlined"
      startIcon={<TableChartOutlinedIcon />}
      showBadge={numColumnsChanged > 0}
      onClick={(e) => {
        setConfigureOrderColumnsAnchorEl(e.currentTarget);
      }}
    >
      Columns
    </OrderTableFunctionButton>
  );

  const ORDERS_TABLE_COLUMNS_ICON_BUTTON_COMPONENT = (
    <StyledIconButton
      size="small"
      shouldHighlight={numColumnsChanged > 0}
      showBadge={numColumnsChanged > 0}
      onClick={(e) => {
        setConfigureOrderColumnsAnchorEl(e.currentTarget);
      }}
    >
      <TableChartOutlinedIcon />
    </StyledIconButton>
  );

  const ORDERS_TABLE_FUNCTIONS_MENU_COMPONENT = (
    <OrderTableFunctionsMenu
      disabled={createOrderTableReportLoading}
      shouldShowGenerateReportButtons={shouldShowGenerateReportButtons}
      onExportAllOrdersInView={async () => fetchOrderTableReport()}
    />
  );

  const ADD_ORDER_BUTTON_COMPONENT =
    shouldShowAddOrderButton === true ? (
      <AddNewOrderOptionsButton
        disabled={!canWriteOrders}
        variant="contained"
      />
    ) : null;

  const SERVICE_DATE_DROPDOWN_FILTER_COMPONENT = shouldShowDatePicker && (
    <DateDropdownPicker
      small
      filterTitle="Service Date"
      dateOption={stateRef?.current?.dateOption ?? initialDateOption}
      setDateOption={handleDatePickerChange}
      defaultFilterType={defaultDatePickerFilterType}
    />
  );

  const DEFAULT_ORDERS_TABLE_FUNCTIONS_CONTAINER_COMPONENT = (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        {ORDERS_TABLE_TABS_COMPONENT}
        <Stack direction="row" spacing={1} alignItems="center">
          {DESELECT_ALL_BUTTON_COMPONENT}
          {SORT_COMPONENT}
          {FILTER_BUTTON_COMPONENT}
          {ORDERS_TABLE_COLUMNS_BUTTON_COMPONENT}
          {BULK_ACTIONS_COMPONENT}
          {ORDERS_TABLE_FUNCTIONS_MENU_COMPONENT}
          {ADD_ORDER_BUTTON_COMPONENT}
        </Stack>
      </Stack>
      <Stack
        // On small screens, we want the pills to wrap below the search input
        // However, we want the content with the pills to wrap "first" (before
        // repositioning itself under the search input), only way to do that is
        // with a media query
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="space-between"
        gap={2}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          height={TABLE_CONFIG_PILL_HEIGHT}
        >
          {buildOrdersTableSearchComponent({})}
          {SERVICE_DATE_DROPDOWN_FILTER_COMPONENT}
        </Stack>
        {TABLE_FUNCTION_PILLS_COMPONENT}
      </Stack>
    </>
  );

  const LINE_HAUL_ORDERS_TABLE_FUNCTIONS_CONTAINER_COMPONENT = (
    <Stack
      direction="column"
      justifyContent="space-between"
      gap={1}
      width="100%"
    >
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap={1}
      >
        {buildOrdersTableSearchComponent({ width: '300px' })}
        <div>
          {FILTER_BUTTON_COMPONENT}
          {ORDERS_TABLE_COLUMNS_ICON_BUTTON_COMPONENT}
          <AddToLineHaulManifestButton
            sx={{ ml: 2 }}
            selectedOrderUuids={selectedUuids}
            refreshGrid={refreshGrid}
          />
        </div>
      </Stack>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="center"
        sx={{ height: '100%' }}
        gap={1}
      >
        {TABLE_FUNCTION_PILLS_COMPONENT}
      </Stack>
    </Stack>
  );

  const CONTAINER_COMPONENT =
    pageType === FilterViewPage.LineHaulOrders
      ? LINE_HAUL_ORDERS_TABLE_FUNCTIONS_CONTAINER_COMPONENT
      : DEFAULT_ORDERS_TABLE_FUNCTIONS_CONTAINER_COMPONENT;

  return (
    <Stack
      sx={{
        p: 1,
        pl: controlBarSpacing ? 1 : 0,
        pt: controlBarSpacing ? 1 : 0,
        gap: 2,
      }}
    >
      {showCreateNewSavedViewModal && (
        <CreateNewSavedViewModal
          open={showCreateNewSavedViewModal}
          setOpen={setShowCreateNewSavedViewModal}
          createNewSavedView={handleCreateNewSavedView}
          viewIsFromScratch={newViewIsFromScratch}
        />
      )}
      {showBulkActionsModal && (
        <BulkActionsModal
          isOpen={showBulkActionsModal}
          setIsOpen={setShowBulkActionsModal}
          getOrderTableFieldValuesInput={getOrderTableFieldValuesInput}
          selectedOrderUuids={selectedUuids}
          searchText={stateRef?.current?.searchText ?? ''}
          onConfirm={(edited, downloaded) => {
            if (edited) {
              if (downloaded) {
                setBulkActionEditConfirmAlertMessage(
                  'Successfully edited orders. Downloading files...',
                );
              } else {
                setBulkActionEditConfirmAlertMessage(
                  'Successfully edited orders.',
                );
              }
            }
            refreshGrid();
          }}
        />
      )}
      {!isNil(bulkActionEditConfirmAlertMessage) && (
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(bulkActionEditConfirmAlertMessage)}
          onClose={() => {
            setBulkActionEditConfirmAlertMessage(undefined);
          }}
        >
          <Alert>{bulkActionEditConfirmAlertMessage}</Alert>
        </Snackbar>
      )}
      {savedViewSaveFailedAlert && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={savedViewSaveFailedAlert}
        >
          <Alert
            severity="error"
            onClose={() => {
              setSavedViewSaveFailedAlert(false);
            }}
          >
            Error saving view
          </Alert>
        </Snackbar>
      )}
      {!isNil(savedViewSaveSuccessAlertText) && (
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!isNil(savedViewSaveSuccessAlertText)}
          onClose={() => {
            setSavedViewSaveSuccessAlertText(undefined);
          }}
        >
          <Alert>{savedViewSaveSuccessAlertText}</Alert>
        </Snackbar>
      )}
      {CONFIGURE_ORDERS_TABLE_COLUMNS_COMPONENT}
      {CONTAINER_COMPONENT}
      <Box>{topRightComponent}</Box>
    </Stack>
  );
};

export default OrdersTableFunctionsContainerNew;
