import { DragDropContext } from '@hello-pangea/dnd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RouteIcon from '@mui/icons-material/Route';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { debounce, isEmpty, isNil } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { shallow } from 'zustand/shallow';
import { DISPATCH_PAGE_CREATE_ROUTE_BUTTON_TEST_ID } from '../../../../constants';
import TagsDropdownField from '../../../common/components/tags-dropdown-field';
import { FeatureFlag } from '../../../common/feature-flags';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { Size } from '../../../common/types';
import useDispatchStore from '../dispatch-store';
import { useDispatchViewSettings } from '../hooks/use-dispatch-view-settings';
import useFetchRoutes from '../hooks/use-fetch-routes';
import useRouteActions from '../hooks/use-route-actions';
import { useRouteCardsColumnWidths } from '../hooks/use-route-cards-column-widths';
import { ROUTE_CARD_GAP_WIDTH } from '../types/dimensions';
import { sortRoutes } from '../utils';
import { AllRouteSelector } from './components/all-route-selector';
import CreateMultipleRoutesPopover from './components/modals/create-multiple-routes-popover';
import RouteFilterMenuButton from './components/route-filter-menu-button';
import RouteSortMenuButton from './components/route-sort-menu-button';
import { RouteStopTypeFilter } from './components/route-stop-type-filter';
import RoutesBulkEditStopsMenu from './components/routes-bulk-edit-stops-menu';
import RoutesContextMenu from './components/routes-context-menu';
import RouteCard from './route-card';
import RouteStopsList from './route-stops-list';

type RoutesProps = {
  readonly terminalUuid: string | null | undefined;
  readonly isMapView?: boolean;
};

const Routes = ({ terminalUuid, isMapView = false }: RoutesProps) => {
  const ffDispatchTrackParity = useFeatureFlag(
    FeatureFlag.FF_DISPATCH_TRACK_PARITY,
  );
  const { numberOfStops } = useDispatchViewSettings();
  const { columnWidths, setColumnWidths } = useRouteCardsColumnWidths();
  const routesToolbarRef = useRef<HTMLDivElement | null>(null);
  const createRoutesButtonRef = useRef<HTMLButtonElement | null>(null);
  const { width: routesToolbarWidth } = useResizeDetector({
    targetRef: routesToolbarRef,
  });
  const [showCreateRoutesModal, setShowCreateRoutesModal] =
    useState<boolean>(false);
  const [
    allRoutes,
    filteredRoutes,
    showMap,
    planningDate,
    routesLoading,
    selectingRoutes,
    getRouteByUuid,
    searchText,
    setSearchText,
    filteredTagUuids,
    setFilteredTagUuids,
    openedRouteUuid,
    setOpenedRouteUuid,
    setAllRouteUuidsLoadingStops,
    sortAsc,
    sortType,
  ] = useDispatchStore(
    (state) => [
      state.routes,
      state.filteredRoutes,
      state.showMap,
      state.planningDate,
      state.routesLoading,
      state.selectingRoutes,
      state.getRouteByUuid,
      state.routeSearchText,
      state.setRouteSearchText,
      state.filteredTagUuids,
      state.setFilteredTagUuids,
      state.openedRouteUuid,
      state.setOpenedRouteUuid,
      state.setAllRouteUuidsLoadingStops,
      state.sortAsc,
      state.sortType,
    ],
    shallow,
  );

  const { sendDispatchUserLeaveLocationEvent } = useMultiplayer();
  const { onDragStart, onDragEnd } = useRouteActions();
  const { fetchRoutes } = useFetchRoutes();
  const openedRoute = isNil(openedRouteUuid)
    ? undefined
    : getRouteByUuid(openedRouteUuid);

  useEffect(() => {
    sendDispatchUserLeaveLocationEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showCreateRoutesModal,
    showMap,
    selectingRoutes,
    planningDate,
    terminalUuid,
  ]);

  useEffect(() => {
    if (isNil(planningDate)) return;
    setOpenedRouteUuid(undefined);

    fetchRoutes({
      planningDate: planningDate.toDate(),
      sortAsc,
      sortType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningDate, terminalUuid]);

  const updateSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 300);

  const showFilterMenu = !isNil(routesToolbarWidth) && routesToolbarWidth < 700;
  const mapViewDispatchTrackParity = isMapView && ffDispatchTrackParity;

  return (
    <Stack height="100%">
      {showCreateRoutesModal && (
        <CreateMultipleRoutesPopover
          open={showCreateRoutesModal}
          setOpen={setShowCreateRoutesModal}
          anchorEl={createRoutesButtonRef.current}
        />
      )}
      <Stack
        ref={routesToolbarRef}
        sx={{ p: 1 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {isNil(openedRoute) ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              {!isNil(routesToolbarWidth) && routesToolbarWidth > 500 && (
                <TextField
                  className="dispatch-search-input"
                  variant="standard"
                  size="small"
                  placeholder="Search routes"
                  autoComplete="off"
                  sx={{ minWidth: 60, maxWidth: 160 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      height: 34,
                    },
                  }}
                  defaultValue={searchText}
                  onChange={(e) => {
                    updateSearchText(e.target.value);
                  }}
                />
              )}
              <RouteSortMenuButton />
              {showFilterMenu && (
                <RouteFilterMenuButton
                  showSearchTextField={
                    !isNil(routesToolbarWidth) && routesToolbarWidth <= 500
                  }
                />
              )}
              {!showFilterMenu && (
                <Stack direction="row" spacing={1}>
                  <RouteStopTypeFilter />
                  <TagsDropdownField
                    size={Size.sm}
                    tagUuids={filteredTagUuids}
                    onChange={(tagUuids) => {
                      setFilteredTagUuids(tagUuids);
                    }}
                  />
                </Stack>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* {mapViewDispatchTrackParity && <RouteMultiSelect />} */}
              <RoutesBulkEditStopsMenu />
              {!mapViewDispatchTrackParity && <RoutesContextMenu />}
              {(!selectingRoutes || isMapView) && (
                <Button
                  ref={createRoutesButtonRef}
                  variant="contained"
                  data-testid={DISPATCH_PAGE_CREATE_ROUTE_BUTTON_TEST_ID}
                  onClick={() => {
                    setShowCreateRoutesModal(true);
                  }}
                >
                  Create
                </Button>
              )}
            </Stack>
          </>
        ) : (
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setAllRouteUuidsLoadingStops();
              setOpenedRouteUuid(undefined);
            }}
          >
            All Routes
          </Button>
        )}
      </Stack>
      {(selectingRoutes || isMapView) && (
        <Stack>
          <Divider />
          <AllRouteSelector isMapView={isMapView} />
        </Stack>
      )}
      <Divider />
      {isNil(openedRoute) ? (
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={`${ROUTE_CARD_GAP_WIDTH}px`}
          sx={{
            p: 1,
            overflowY: 'auto',
          }}
        >
          {isEmpty(filteredRoutes) && (
            <Stack
              sx={{ width: '100%', height: '100%' }}
              alignItems="center"
              justifyContent="center"
            >
              {routesLoading ? (
                <Box sx={{ width: '50%' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <RouteIcon sx={{ fontSize: '5vw', color: '#bababa' }} />
              )}
              <Typography variant="subtitle1">
                {routesLoading ? 'Loading...' : 'No routes'}
              </Typography>
            </Stack>
          )}

          {sortRoutes(allRoutes, sortType, sortAsc)?.map((route, idx) => (
            <RouteCard
              key={route.uuid}
              numberOfStops={numberOfStops}
              route={route}
              selectMode={selectingRoutes || isMapView}
              isMapView={isMapView}
              columnWidths={columnWidths}
              setColumnWidths={setColumnWidths}
              idx={idx}
            />
          ))}
        </Stack>
      ) : (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Stack sx={{ p: 1, overflowY: 'auto' }} gap={1}>
            <RouteCard
              route={openedRoute}
              isMapView={false}
              columnWidths={columnWidths}
              setColumnWidths={setColumnWidths}
            />
            <Box
              sx={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <RouteStopsList route={openedRoute} />
            </Box>
          </Stack>
        </DragDropContext>
      )}
    </Stack>
  );
};

export default memo(Routes);
