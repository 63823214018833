import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Fade,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getSettlementEligibleTotal } from 'shared/billing';
import { safeDivide, safeMultiply } from 'shared/math';
import { BillingMethod } from '../../../../common/types';
import {
  DriverSettlementBillByUuidDocument,
  DriverSettlementBillsDocument,
  type SettlementDeductionFragment,
  useRemoveSettlementDeductionMutation,
  useSettlementDeductionTypesQuery,
  useUpdateSettlementDeductionMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const SettlementDeduction = ({
  isFinalized,
  driverSettlementTotal,
  settlementDeduction,
}: {
  readonly isFinalized: boolean;
  readonly driverSettlementTotal: number | undefined | null;
  readonly settlementDeduction: SettlementDeductionFragment;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const { data: settlementDeductionTypesData } =
    useSettlementDeductionTypesQuery();
  const [removeSettlementDeduction] = useRemoveSettlementDeductionMutation();
  const [updateSettlementDeduction] = useUpdateSettlementDeductionMutation();
  const [deductionBillingMethod, setDeductionBillingMethod] =
    useState<BillingMethod>(BillingMethod.Percentage);

  const [name, setName] = useState<string>(settlementDeduction.name);
  const [flatRate, setFlatRate] = useState<number | undefined>(
    settlementDeduction?.flatRate ?? undefined,
  );
  const [percentageRate, setPercentageRate] = useState<number | undefined>(
    isNil(settlementDeduction?.percentageDecimalRate)
      ? undefined
      : safeMultiply(settlementDeduction?.percentageDecimalRate, 100, 10),
  );

  useEffect(() => {
    if (!isNil(flatRate)) {
      setDeductionBillingMethod(BillingMethod.FlatRate);
    } else if (!isNil(percentageRate)) {
      setDeductionBillingMethod(BillingMethod.Percentage);
    }
  }, [flatRate, percentageRate]);

  const onDeductionRateChange = (rate: number | undefined) => {
    if (deductionBillingMethod === BillingMethod.Percentage) {
      setPercentageRate(rate);
    } else {
      setFlatRate(rate);
    }
  };
  const onDeductionBillingMethodChange = (newBillingMethod: BillingMethod) => {
    setDeductionBillingMethod(newBillingMethod);
    if (newBillingMethod === BillingMethod.Percentage) {
      setPercentageRate(flatRate);
      setFlatRate(undefined);
    } else if (newBillingMethod === BillingMethod.FlatRate) {
      setFlatRate(percentageRate);
      setPercentageRate(undefined);
    }
  };

  const update = async () => {
    await updateSettlementDeduction({
      variables: {
        settlementDeductionUpdateInput: {
          name,
          uuid: settlementDeduction.uuid,
          percentageDecimalRate: isNil(percentageRate)
            ? null
            : safeDivide(percentageRate, 100, 10),
          flatRate: isNil(flatRate) ? null : flatRate,
        },
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
      ],
    });
  };

  const deleteDeduction = async () => {
    await removeSettlementDeduction({
      variables: {
        uuid: settlementDeduction.uuid,
      },
      refetchQueries: [
        DriverSettlementBillsDocument,
        DriverSettlementBillByUuidDocument,
      ],
    });
    setAnchorEl(null);
  };

  return (
    <>
      <Popper
        transition
        sx={{ zIndex: 10 }}
        open={!isNil(anchorEl)}
        anchorEl={anchorEl}
        placement="left"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper sx={{ p: '13px', width: 300 }} elevation={2}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <IconButton
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '17px' }} />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography sx={{ fontSize: '13px' }}>
                      Edit Deduction
                    </Typography>
                    <Box>
                      <IconButton size="small" onClick={deleteDeduction}>
                        <DeleteIcon sx={{ fontSize: '17px' }} />
                      </IconButton>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    onClick={async () => {
                      await update();
                      setAnchorEl(null);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <AutocompleteFuzzy
                      freeSolo
                      disabled={isFinalized}
                      options={
                        settlementDeductionTypesData?.settlementDeductionTypes.map(
                          (type) => ({
                            label: type.name,
                            percentageRate: type.percentageRate,
                            flatRate: type.flatRate,
                          }),
                        ) ?? []
                      }
                      matchSortOptions={{ keys: ['label'] }}
                      value={{
                        label: name ?? '',
                        percentageRate,
                        flatRate,
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props}>
                            <Stack>
                              <Typography sx={{ fontSize: '14px' }}>
                                {option.label}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {isNil(option.flatRate)
                                  ? `${option.percentageRate}%`
                                  : currency(option.flatRate).format()}
                              </Typography>
                            </Stack>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Name"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value);
                          }}
                        />
                      )}
                      onChange={(_, option) => {
                        if (typeof option !== 'string') {
                          setName(option?.label ?? '');
                          setFlatRate(option?.flatRate ?? undefined);
                          setPercentageRate(
                            option?.percentageRate ?? undefined,
                          );
                        }
                      }}
                      onBlur={update}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <FormControl>
                      <TextField
                        disabled={isFinalized}
                        size="small"
                        type="number"
                        value={
                          deductionBillingMethod === BillingMethod.Percentage
                            ? percentageRate
                            : flatRate
                        }
                        InputProps={
                          deductionBillingMethod === BillingMethod.Percentage
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }
                            : {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }
                        }
                        onWheel={(e) => {
                          (e.target as HTMLTextAreaElement).blur();
                        }}
                        onChange={(event) => {
                          const parsed = Number.parseFloat(event.target.value);
                          onDeductionRateChange(
                            Number.isNaN(parsed) ? undefined : parsed,
                          );
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ minWidth: '140px' }}>
                      <Select
                        required
                        disabled={isFinalized}
                        size="small"
                        value={deductionBillingMethod}
                        onChange={(event) => {
                          onDeductionBillingMethodChange(
                            event.target.value as BillingMethod,
                          );
                        }}
                      >
                        <MenuItem value={BillingMethod.Percentage}>
                          {sentenceCase(BillingMethod.Percentage)}
                        </MenuItem>
                        <MenuItem value={BillingMethod.FlatRate}>
                          {sentenceCase(BillingMethod.FlatRate)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography sx={{ fontSize: '14px' }}>
            {isEmpty(name) ? 'Deductions' : name}
          </Typography>
          <IconButton
            disabled={isFinalized}
            sx={{ p: '5px' }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (isNil(anchorEl)) {
                setAnchorEl(event.currentTarget);
              } else {
                setAnchorEl(null);
              }
            }}
          >
            <EditIcon sx={{ width: '13px', height: '13px' }} />
          </IconButton>
        </Stack>
        <Typography sx={{ textAlign: 'right', fontSize: '14px' }}>
          -
          {currency(
            getSettlementEligibleTotal(
              settlementDeduction.flatRate,
              isNil(settlementDeduction.percentageDecimalRate)
                ? undefined
                : safeMultiply(settlementDeduction.percentageDecimalRate, 100),
              driverSettlementTotal ?? 0,
            ) ?? 0,
          ).format()}
        </Typography>
      </Stack>
    </>
  );
};

export default SettlementDeduction;
