import { styled } from 'styled-components';
import theme from '../../../../theme';

import { getBadgeStyle, getCutoutStyle } from '../utils/get-badge-style';
import { IconButton } from '@mui/material';

const BUTTON_HIGHLIGHT_COLOR = theme.palette.primary.light;
const BUTTON_HOVER_COLOR = '#EDEFF2';
const BADGE_POSITION = '10%';

const TableFunctionIconButton = styled(IconButton)<{
  shouldHighlight: boolean;
  showBadge: boolean;
}>(({ shouldHighlight, showBadge }) => {
  const backgroundColor = shouldHighlight
    ? BUTTON_HIGHLIGHT_COLOR
    : 'transparent';
  return {
    borderRadius: '6px',
    ...(shouldHighlight && { color: theme.palette.primary.main }),
    ...(showBadge
      ? {
          ...getBadgeStyle(backgroundColor, BADGE_POSITION),
        }
      : { backgroundColor }),
    '&:hover': {
      color: theme.palette.text.secondary,
      ...(showBadge
        ? getCutoutStyle(BUTTON_HOVER_COLOR, BADGE_POSITION)
        : { background: `${BUTTON_HOVER_COLOR}` }),
    },
  };
});

export default TableFunctionIconButton;
