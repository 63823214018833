import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import { fuelChargeSchema } from './fuel-charge-schema';
import { freightChargeOrCostBaseSchema } from './freight-charge-or-cost-base-schema';

export const freightChargeSchema = freightChargeOrCostBaseSchema.extend({
  fuelCharge: fuelChargeSchema,
  settlementPercentageRate: z.number().nullable(),
  settlementFlatRate: z.number().nullable(),
  settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
  deductionTotal: z.number(),
});
