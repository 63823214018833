import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { type SxProps, useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  type ContactStationsQuery,
  PersonStatus,
  useArchiveContactStationMutation,
  usePersonsQuery,
  useRestoreContactStationMutation,
  useUpdateContactStationMutation,
} from '../../../generated/graphql';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import PalletLink from '../../../pallet-ui/links/link/pallet-link';
import ContactsPageTabs from '../enums/contacts-page-tabs';
import ContactPageMode from './contact-page-mode';

type Station = ContactStationsQuery['contactStations'][number];

type StationFormData = {
  stationName: string;
  defaultPersonId: string | null | undefined;
};

type FormData = StationFormData;

type FormErrors = {
  [key in keyof FormData]?: string;
};

const useStyles = (): Record<string, SxProps> => {
  const theme = useTheme();
  return {
    archivedStation: {
      width: 'max-content',
      padding: '3px 12px',
      borderRadius: '8px',
      backgroundColor: theme.palette.redColor.main,
      color: '#fff',
      fontSize: '15px',
      fontWeight: 500,
    },
  };
};

export default function EditStationPanel({
  station,
  contactUuid,
  canWrite,
  handleChangeMode,
  mode,
  onTabChange,
}: {
  readonly station: Station;
  readonly contactUuid: string;
  readonly canWrite: boolean;
  readonly handleChangeMode: (mode: ContactPageMode) => void;
  readonly mode: ContactPageMode;
  readonly onTabChange: (tab: ContactsPageTabs) => void;
}) {
  const [archiveContactStationMutation, { loading: archivingContactStation }] =
    useArchiveContactStationMutation({
      fetchPolicy: 'network-only',
    });
  const [restoreContactStationMutation, { loading: restoringContactStation }] =
    useRestoreContactStationMutation({
      fetchPolicy: 'network-only',
    });

  const [updateContactStationMutation, { loading: updatingContactStation }] =
    useUpdateContactStationMutation({
      fetchPolicy: 'network-only',
    });

  const { data: personsData, loading: loadingPersons } = usePersonsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      contactUuid,
    },
  });

  const styles = useStyles();
  const [errors, setErrors] = useState<Partial<FormErrors>>({});
  const [formData, setFormData] = useState<FormData>({
    stationName: station.name ?? '',
    defaultPersonId: station.defaultPerson?.id ?? null,
  });

  useEffect(() => {
    setFormData({
      stationName: station.name ?? '',
      defaultPersonId: station.defaultPerson?.id ?? null,
    });
  }, [station]);

  const onSave = async () => {
    const newErrors: Partial<FormErrors> = {};

    if (formData.stationName.trim().length === 0) {
      newErrors.stationName = 'Station name is required';
    }

    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      return;
    }

    await updateContactStationMutation({
      variables: {
        input: {
          id: station.id,
          name: formData.stationName,
          isArchived: false,
          contactUuid,
          defaultPersonId: formData.defaultPersonId,
        },
      },
    });

    handleChangeMode(ContactPageMode.VIEW);
  };

  const onToggleArchive = async () => {
    await (station.isArchived
      ? restoreContactStationMutation({ variables: { id: station.id } })
      : archiveContactStationMutation({ variables: { id: station.id } }));
  };

  const clearError = (fieldName: keyof FormErrors) => {
    setErrors(({ [fieldName]: _ignored, ...restErrors }) => restErrors);
  };

  const defaultPersonOptions = useMemo(
    () =>
      personsData?.persons.persons.filter(
        (person) => person.status === PersonStatus.Active,
      ) ?? [],
    [personsData],
  );

  const loading =
    archivingContactStation ||
    restoringContactStation ||
    updatingContactStation ||
    loadingPersons;

  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Stack direction="column" spacing={2}>
            {station.isArchived && (
              <Typography sx={styles.archivedStation}>Archived</Typography>
            )}
            <TextField
              id="contact-station-name"
              label="Station name"
              disabled={mode === ContactPageMode.VIEW || !canWrite}
              error={'stationName' in errors}
              value={formData.stationName}
              size="small"
              sx={{ width: '280px' }}
              inputProps={{
                sx: { py: '6.75px' },
              }}
              onChange={({ target }) => {
                setFormData((prevData) => ({
                  ...prevData,
                  stationName: target.value,
                }));
                if (target.value.length > 0 && 'stationName' in errors) {
                  clearError('stationName');
                }
              }}
            />
          </Stack>
          <Stack direction="row" gap={1}>
            {mode === ContactPageMode.CREATE ||
            mode === ContactPageMode.EDIT ? (
              <>
                <Button
                  variant="text"
                  disabled={loading}
                  onClick={() => {
                    handleChangeMode(ContactPageMode.VIEW);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" disabled={loading} onClick={onSave}>
                  Save
                </Button>
              </>
            ) : (
              canWrite && (
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    handleChangeMode(ContactPageMode.EDIT);
                  }}
                >
                  Edit
                </Button>
              )
            )}
          </Stack>
        </Stack>
        <Divider sx={{ borderBottomWidth: '2px' }} />
        <Box p={2}>
          <Typography display="block" fontWeight={700} fontSize="17px">
            Default person
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            display="block"
            mb={2}
          >
            Set the default person for this station. This person&apos;s name,
            number and email will automatically be used for new orders.
          </Typography>
          {defaultPersonOptions.length > 0 ? (
            <AutocompleteFuzzy
              sx={{ width: '190px' }}
              matchSortOptions={{ keys: ['firstName', 'lastName'] }}
              disabled={mode === ContactPageMode.VIEW || !canWrite}
              value={defaultPersonOptions.find(
                (person) => person.id === formData.defaultPersonId,
              )}
              options={defaultPersonOptions}
              getOptionLabel={(person) =>
                `${person.firstName} ${person.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Default person"
                  autoComplete="off"
                />
              )}
              onChange={(_, person) => {
                setFormData((prevData) => ({
                  ...prevData,
                  defaultPersonId: person?.id ?? null,
                }));
              }}
            />
          ) : (
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
            >
              There are no people to add. Create people on the{' '}
              <PalletLink
                component="button"
                variant="caption"
                onClick={() => {
                  onTabChange(ContactsPageTabs.PEOPLE);
                }}
              >
                People page
              </PalletLink>{' '}
              for this contact.
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Typography display="block" fontWeight={700} fontSize="17px">
          {station.isArchived ? 'Un-archive ' : 'Archive '}
          this station
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          display="block"
          mb={2}
        >
          {station.isArchived
            ? 'This station is archived and is not available for new orders.'
            : 'Archiving this station will make them unavailable for new orders.'}
        </Typography>
        <Button
          variant="outlined"
          color={station.isArchived ? 'error' : undefined}
          disabled={loading}
          onClick={onToggleArchive}
        >
          {station.isArchived ? 'Un-archive' : 'Archive'}
        </Button>
      </Box>
    </>
  );
}
