import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  type ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import type React from 'react';

// Type guard to check if an element is ButtonProps
function isButtonProps(
  element: ButtonProps | React.ReactNode,
): element is ButtonProps {
  return (
    typeof element === 'object' && element !== null && 'onClick' in element
  );
}

type Action = ButtonProps | React.ReactNode;

type Actions = {
  bottomRight?: Action[];
  bottomLeft?: Action[];
  topRight?: Action[];
};

type PalletModalProps = {
  /** Whether the modal is open */
  readonly open: boolean;
  /** Function to call when the modal should be closed */
  readonly onClose: () => void;
  /** Title of the modal */
  readonly title: React.ReactNode;
  /** Buttons to display in the modal (other than the cancel button).
   * These will be displayed in the order they are provided from left to right */
  readonly actions?: Actions;
  /** Text to display in the cancel button */
  readonly cancelActionButtonText?: string;
  readonly disableCancel?: boolean;
  readonly hideCancel?: boolean;
  /** Content of the modal between the title and action buttons */
  readonly children?: React.ReactNode;
  readonly width?: 'sm' | 'md' | 'lg' | 'xl';
  readonly minContentHeight?: number;
};

/**
 * A reusable dialog component with a title, close button, action button, and customizable content.
 * The content should be provided as children. e.g. <PalletModal>...</PalletModal>
 *
 * @deprecated Use PalletModalNew instead
 * @param props - The props for the PalletModal component
 * @returns A modal with the specified props and behavior
 */
const PalletModalOld = ({
  open,
  onClose,
  title,
  actions,
  cancelActionButtonText,
  disableCancel,
  hideCancel,
  children,
  width = 'sm',
  minContentHeight,
}: PalletModalProps) => {
  const theme = useTheme();
  const {
    bottomRight: bottomRightActions,
    bottomLeft: bottomLeftActions,
    topRight: topRightActions,
  } = actions ?? {};
  const renderAction = (elem: Action) => {
    if (isButtonProps(elem)) {
      return (
        <Button variant="contained" {...elem} key={null}>
          {elem.children}
        </Button>
      );
    }
    return elem;
  };
  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={width}
      PaperProps={{
        style: {
          // Allow overflow to absolutely position buttons outside of the container,
          // e.g. navigation buttons for DocumentViewerModal
          overflow: 'visible',
        },
      }}
      onClose={onClose}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {typeof title === 'string' ? (
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        ) : (
          title
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          {topRightActions?.map(renderAction)}
          <IconButton
            size="small"
            aria-label="close"
            sx={{
              color: theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minHeight: minContentHeight }}>
        {children}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Stack direction="row">
          {hideCancel !== true && (
            <Button disabled={disableCancel} onClick={onClose}>
              {cancelActionButtonText ?? 'Cancel'}
            </Button>
          )}
          {bottomLeftActions?.map(renderAction)}
        </Stack>
        <Stack direction="row">{bottomRightActions?.map(renderAction)}</Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PalletModalOld;
