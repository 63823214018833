import { Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil, filterNotNilOrEmpty } from 'shared/array';
import { getStopMarkAsTestIds } from '../../../../../../../../utils';
import { MarkOrderAsReadyToInvoiceDialog } from '../../../../../../../common/components/modals/mark-order-as-ready-to-invoice-dialog';
import MarkStopAsArrivedDialog from '../../../../../../../common/components/modals/mark-stop-as-arrived-dialog';
import { MarkStopAsAttemptedDialog } from '../../../../../../../common/components/modals/mark-stop-as-attempted.dialog';
import { MarkStopAsCompletedDialog } from '../../../../../../../common/components/modals/mark-stop-as-completed-dialog';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import {
  AppointmentCallStatus,
  LegDocument,
  type LegQuery,
  StopStatus,
  StopType,
  useBulkChangeCallStatusMutation,
  useDeleteSlotsFromRouteMutation,
  useDeleteStopAttemptMutation,
  useMarkAttemptedStopAsCompleteMutation,
  useMarkStopAsFailedV2Mutation,
  useMarkStopAsIncompleteMutation,
} from '../../../../../../../generated/graphql';
import ConfirmMarkIncompleteModal from '../../../../standard/components/standard-order/modals/confirm-mark-incomplete-modal';
import { type OrderFormValues } from '../../../forms/types';
import {
  getCannotCompleteOrder,
  getPickupOrDelivery,
} from '../../../forms/utils';
import { useUpdateAndRefetchOrder } from '../../../hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../../types';
import {
  stopCanBeCompleted,
  stopCanBeMarkedAsArrived,
  stopCanBeMarkedIncomplete,
} from '../../../utils';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../../constants';
import { formatStopAttemptTime } from './utils';

type StopMarkAsMenuProps = {
  readonly buttonRef: HTMLButtonElement | null;
  readonly setShowContextMenu: Dispatch<SetStateAction<boolean>>;
  readonly showContextMenu: boolean;
  readonly idx: number;
  readonly legData?: LegQuery;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
};

const StopMarkAsMenu = ({
  buttonRef,
  setShowContextMenu,
  showContextMenu,
  idx,
  legData,
  onSubmit,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: StopMarkAsMenuProps) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const ffRequireTransferAddress = useFeatureFlag(
    FeatureFlag.FF_REQUIRE_TRANSFER_ADDRESS_ON_COMPLETION,
  );

  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();
  const { control } = useFormContext<OrderFormValues>();
  const [showMarkStopAsIncompleteModal, setShowMarkStopAsIncompleteModal] =
    useState(false);
  const orderUuid = useWatch({
    control,
    name: 'uuid',
  });
  const [showMarkStopAsCompletedDialog, setShowMarkStopAsCompletedDialog] =
    useState(false);
  const [showMarkStopAsArrivedDialog, setShowMarkStopAsArrivedDialog] =
    useState(false);
  const [showMarkAsReadyToInvoiceDialog, setShowMarkAsReadyToInvoiceDialog] =
    useState(false);
  const [showMarkStopAsFailedDialog, setShowMarkStopAsFailedDialog] =
    useState(false);

  const ref = useRef<HTMLUListElement>(null);
  const shipperBillOfLadingNumber = useWatch({
    control,
    name: 'shipperBillOfLadingNumber',
  });
  const stopValues = useWatch({ control, name: `stops.${idx}` });
  const otherStopValues = useWatch({
    control,
    name: `stops.${idx === INBOUND_STOP_IDX ? OUTBOUND_STOP_IDX : INBOUND_STOP_IDX}`,
  });
  const stops = useWatch({
    control,
    name: 'stops',
  });
  const [updateOrderCallStatus] = useBulkChangeCallStatusMutation();
  const [markAsFailed] = useMarkStopAsFailedV2Mutation({
    refetchQueries: [LegDocument],
  });
  const [markStopAsIncomplete] = useMarkStopAsIncompleteMutation();
  const [removeSlotsFromRoute] = useDeleteSlotsFromRouteMutation();
  const [deleteStopAttempt, { loading: deleteStopAttemptLoading }] =
    useDeleteStopAttemptMutation({ refetchQueries: [LegDocument] });
  const [markAttemptedStopAsComplete] = useMarkAttemptedStopAsCompleteMutation({
    refetchQueries: [LegDocument],
  });

  const { companyConfiguration, companyTimezone } = useMe();
  const requirePODPhotoAndName = companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfiguration?.requirePODPhotoAndNameForPickups ??
    requirePODPhotoAndName;

  const requirePODPhotoAndNameOverall =
    requirePODPhotoAndName === true &&
    ((requirePODPhotoAndNameForPickups === true ||
      stops?.every((stop) => stop.stopType === StopType.Delivery)) ??
      false);

  const { stopMarkAsMenuMarkCompletedOptionTestId } = getStopMarkAsTestIds({
    stopIdx: idx,
  });

  const lastAttemptedStop = useMemo(
    () =>
      legData?.leg?.previousStopAttempts
        ?.slice()
        ?.filter((a) => !isNil(a.completedAt))
        ?.sort((a, b) => a.completedAt.localeCompare(b.completedAt))[0],
    [legData],
  );

  const handleMarkStopAsFailed = async (notes: string) => {
    if (!isNil(stopValues)) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markAsFailed,
            vars: {
              markStopAsFailedV2Input: {
                notes,
                uuid: stopValues.uuid,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'marking stop as failed',
      });
    }
    setShowMarkStopAsFailedDialog(false);
  };

  const handleDeleteStopAttempt = async (uuid: string) => {
    await updateAndRefetchOrder({
      additionalUpdateFns: [
        {
          fn: deleteStopAttempt,
          vars: {
            deleteStopAttemptInput: {
              uuid,
            },
          },
        },
      ],
      onSubmit,
      actionString: 'removing stop attempt',
      showSuccess: true,
    });
  };

  const updateCallStatus = async (callStatus: AppointmentCallStatus) => {
    if (!isNil(stopValues)) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: updateOrderCallStatus,
            vars: {
              changeCallStatusArgs: {
                uuids: [stopValues?.uuid],
                callStatus,
              },
            },
          },
        ],
        onSubmit,
        actionString: 'updating call status',
      });
    }
  };

  const unassignStop = async () => {
    const routeSlotUuid = stopValues?.routeSlotUuid;
    const routeUuid = stopValues?.routeUuid;
    if (!isNil(stopValues) && !isNil(routeSlotUuid) && !isNil(routeUuid)) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: removeSlotsFromRoute,
            vars: {
              deleteSlotsFromRouteInput: {
                routeSlotUuids: [routeSlotUuid],
                routeUuid,
              },
            },
          },
          {
            fn: markStopAsIncomplete,
            vars: {
              uuid: stopValues.uuid,
            },
          },
        ],
        onSubmit,
        actionString: 'unassigning stop',
      });
    }
  };

  const handleMarkAttemptedStopAsComplete = async () => {
    if (!isNil(lastAttemptedStop)) {
      await updateAndRefetchOrder({
        additionalUpdateFns: [
          {
            fn: markAttemptedStopAsComplete,
            vars: {
              stopUuid: lastAttemptedStop.uuid,
            },
          },
        ],
        onSubmit,
        actionString: 'marking attempted stop as complete',
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isNil(ref.current) &&
        buttonRef?.contains(event.target as Node) === false
      ) {
        setShowContextMenu(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canBeUnassigned = !isNil(stopValues?.routeUuid);
  const canBeMarkedAsAttempted = !isNil(stopValues?.routeUuid);
  const canBeMarkedAsArrived = stopCanBeMarkedAsArrived({
    stopType: stopValues?.stopType,
    routeUuid: stopValues?.routeUuid,
    stopStatus: stopValues?.status,
  });
  const canBeCompleted = stopCanBeCompleted({
    stopType: stopValues?.stopType,
    shipperBillOfLadingNumber,
    stopStatus: stopValues?.status,
  });
  const canBeIncompleted = stopCanBeMarkedIncomplete({
    stopStatus: stopValues?.status,
  });

  const stopAttemptMenuItemObjects = filterNotNilOrEmpty(
    legData?.leg?.previousStopAttempts ?? [],
  ).map((attempt) => {
    const copy = `Remove attempt at ${formatStopAttemptTime({
      completedAt: attempt.completedAt,
      companyTimezone,
    })}`;
    return (
      <MenuItem
        key={attempt.uuid}
        disabled={deleteStopAttemptLoading}
        onClick={async () => handleDeleteStopAttempt(attempt.uuid)}
      >
        {copy}
      </MenuItem>
    );
  });

  let markCompleteTooltip = '';
  if (!canBeCompleted) {
    if (stopValues?.status === StopStatus.Completed) {
      markCompleteTooltip = 'Stop is already completed';
    } else if (isNil(shipperBillOfLadingNumber)) {
      // TODO: Use HAWB instead of shipper bill of lading number
      markCompleteTooltip =
        'Shipper bill of lading number must be filled in and saved';
    } else {
      markCompleteTooltip =
        'Pickup / delivery must be assigned to a route to be completed';
    }
  }

  let markIncompleteTooltip = '';
  if (!canBeIncompleted && stopValues?.status !== StopStatus.Completed) {
    markIncompleteTooltip = 'Stop must be completed to be marked incomplete';
  }

  const getMarkAsCompleteTooltipText = () => {
    if (stopValues?.status === StopStatus.Completed)
      return 'Order has been completed';
    if (lastAttemptedStop?.canMarkAttemptComplete !== true)
      return 'This attempt cannot be marked as complete because the order has been assigned to a route';
    return '';
  };

  return (
    <>
      <MarkStopAsAttemptedDialog
        handleClose={() => {
          setShowMarkStopAsFailedDialog(false);
          setShowContextMenu(false);
        }}
        open={showMarkStopAsFailedDialog}
        handleMarkStopAsFailed={handleMarkStopAsFailed}
      />
      {!isNil(stopValues) && (
        <MarkStopAsCompletedDialog
          handleClose={() => {
            setShowMarkStopAsCompletedDialog(false);
            setShowContextMenu(false);
          }}
          open={showMarkStopAsCompletedDialog}
          stopUuids={[stopValues?.uuid]}
          routeDate={stopValues?.routeDate ?? undefined}
          allStopsAreDeliveries={stopValues.stopType === StopType.Delivery}
          allStopsArePartnerStops={
            stopValues.stopType === StopType.PartnerCarrierPickup ||
            stopValues.stopType === StopType.PartnerCarrierDropoff
          }
          idx={idx}
          onSave={onSubmit}
        />
      )}
      {!isNil(stopValues) && (
        <MarkStopAsArrivedDialog
          handleClose={() => {
            setShowMarkStopAsArrivedDialog(false);
            setShowContextMenu(false);
          }}
          open={showMarkStopAsArrivedDialog}
          stopUuids={[stopValues?.uuid]}
          routeDate={stopValues?.routeDate ?? undefined}
          onSave={onSubmit}
        />
      )}
      {!isNil(stopValues) && !isNil(orderUuid) && (
        <MarkOrderAsReadyToInvoiceDialog
          handleClose={() => {
            setShowMarkAsReadyToInvoiceDialog(false);
          }}
          open={showMarkAsReadyToInvoiceDialog}
          stops={filterNotNil(
            (stops ?? []).map((stop) => {
              if (
                stop.stopType !== StopType.Pickup &&
                stop.stopType !== StopType.Delivery
              ) {
                return null;
              }
              return {
                uuid: stop.uuid,
                pickupOrDelivery: getPickupOrDelivery(stop.stopType),
                addressName: stop.address?.name ?? '',
                routeDate: stop.routeDate ?? undefined,
                status: stop.status,
                completedAt: stop.completedAt ?? undefined,
                stopType: stop.stopType,
              };
            }),
          )}
          orderUuid={orderUuid}
          onSave={onSubmit}
        />
      )}
      {!isNil(stopValues) && !isNil(orderUuid) && (
        <ConfirmMarkIncompleteModal
          open={showMarkStopAsIncompleteModal}
          stopUuid={stopValues?.uuid}
          onSave={onSubmit}
          onClose={() => {
            setShowMarkStopAsIncompleteModal(false);
            setShowContextMenu(false);
          }}
        />
      )}
      <Menu
        anchorEl={buttonRef}
        open={showContextMenu}
        onClose={() => {
          setShowContextMenu(false);
        }}
      >
        <Tooltip
          title={
            canBeUnassigned
              ? ''
              : 'Stop must be assigned to a route to be unassigned from route'
          }
        >
          <span>
            <MenuItem disabled={!canBeUnassigned} onClick={unassignStop}>
              Unassign from route
            </MenuItem>
          </span>
        </Tooltip>
        {stopAttemptMenuItemObjects}
        <Divider />
        <Tooltip
          title={
            canBeMarkedAsAttempted
              ? ''
              : 'Stop must be assigned to a route to be marked as attempted'
          }
        >
          <span>
            <MenuItem
              disabled={!canBeMarkedAsAttempted}
              onClick={() => {
                setShowMarkStopAsFailedDialog(true);
              }}
            >
              Mark as attempted
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={
            canBeMarkedAsArrived
              ? ''
              : isNil(stopValues?.routeUuid)
                ? 'Stop must be assigned to a route to be marked as arrived'
                : 'Cannot mark a completed stop as arrived'
          }
        >
          <span>
            <MenuItem
              disabled={!canBeMarkedAsArrived}
              onClick={() => {
                setShowMarkStopAsArrivedDialog(true);
              }}
            >
              Mark as arrived
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={markCompleteTooltip}>
          <span>
            <MenuItem
              disabled={!canBeCompleted}
              data-testid={stopMarkAsMenuMarkCompletedOptionTestId}
              onClick={() => {
                const otherStopCompleted =
                  otherStopValues?.stopType === StopType.None ||
                  otherStopValues?.status === StopStatus.Completed;

                // If the other stop is completed, the order would be also completed, so we need to check all stops.
                // Otherwise, we only need to check the current stop.
                const cannotCompleteOrderMessage = getCannotCompleteOrder({
                  stops: otherStopCompleted ? stops : [stopValues],
                  terminalsEnabled,
                  ffRequireTransferAddress,
                });
                if (!isNil(cannotCompleteOrderMessage)) {
                  setCannotCompleteOrderModalMessage(
                    cannotCompleteOrderMessage,
                  );
                  setCannotCompleteOrderModalOpen(true);
                  return;
                }

                if (
                  requirePODPhotoAndNameOverall &&
                  (stops ?? []).some(
                    (s, i) =>
                      idx !== i &&
                      !isNil(s.completedAt) &&
                      isNil(s.proofOfDeliverySignee) &&
                      (s.stopType === StopType.Pickup ||
                        s.stopType === StopType.Delivery),
                  )
                ) {
                  setShowMarkAsReadyToInvoiceDialog(true);
                } else {
                  setShowMarkStopAsCompletedDialog(true);
                }
              }}
            >
              Mark completed
            </MenuItem>
          </span>
        </Tooltip>
        {lastAttemptedStop && (
          <Tooltip title={getMarkAsCompleteTooltipText()}>
            <span>
              <MenuItem
                disabled={lastAttemptedStop?.canMarkAttemptComplete !== true}
                onClick={handleMarkAttemptedStopAsComplete}
              >
                Mark last attempt as complete
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <Tooltip title={canBeIncompleted ? '' : markIncompleteTooltip}>
          <span>
            <MenuItem
              disabled={!canBeIncompleted}
              onClick={() => {
                setShowMarkStopAsIncompleteModal(true);
              }}
            >
              Mark incomplete
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <MenuItem
          onClick={() => {
            updateCallStatus(AppointmentCallStatus.Confirmed);
            setShowContextMenu(false);
          }}
        >
          Confirm appointment
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateCallStatus(AppointmentCallStatus.Rejected);
            setShowContextMenu(false);
          }}
        >
          Request appointment reschedule
        </MenuItem>
      </Menu>
    </>
  );
};

export default StopMarkAsMenu;
