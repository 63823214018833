import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type Option } from '../filters/types';
import useServices from '../react-hooks/use-services';
import useStyles from './general-styles';
import MultiselectFilterButton, {
  getMultiselectFilterLabel,
} from './multiselect-filter-button';

const CACHE_PREFIX = 'CUSTOMER_FILTER';

type ServiceFilterButtonProps = {
  readonly cacheId?: string;
  // Single select
  readonly selectedOption?: Option | null | undefined;
  readonly handleChange?: (option: Option | null | undefined) => void;
  // Multi-select
  readonly selectedOptionsMultiselect?: Option[] | null | undefined;
  readonly handleChangeMultiselect?: (
    options: Option[] | null | undefined,
  ) => void;
  readonly prefixText?: string;
};

const ServiceFilterButton = ({
  cacheId,
  selectedOption,
  handleChange,
  selectedOptionsMultiselect,
  handleChangeMultiselect,
  prefixText = 'Service',
}: ServiceFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;
  const { services } = useServices();

  const serviceLevelOptions = useMemo(() => {
    return services?.map((service) => ({
      label: service.name,
      value: service.uuid,
    }));
  }, [services]);

  useEffect(() => {
    if (!isNil(cacheId) && !isNil(handleChange)) {
      const cachedLabel = localStorage.getItem(
        `${CACHE_PREFIX}_LABEL_${cacheId}`,
      );
      const cachedValue = localStorage.getItem(
        `${CACHE_PREFIX}_VALUE_${cacheId}`,
      );
      if (!isNil(cachedLabel) && !isNil(cachedValue)) {
        handleChange({
          label: cachedLabel,
          value: cachedValue,
        });
      }
    }
  }, [cacheId, handleChange]);

  const filterLabel = getMultiselectFilterLabel(
    isNil(selectedOptionsMultiselect)
      ? isNil(selectedOption)
        ? null
        : [selectedOption]
      : selectedOptionsMultiselect,
    false,
  );

  // isMinimized doesn't work with multiselect
  if (!isNil(handleChangeMultiselect)) {
    return (
      <MultiselectFilterButton
        selectedOptions={selectedOptionsMultiselect}
        options={serviceLevelOptions}
        handleChange={handleChangeMultiselect}
        prefixText={prefixText}
        isSmall={false}
        filterLabel={filterLabel}
        displayNoneOption={false}
        cachePrefix={CACHE_PREFIX}
        cacheId={cacheId}
      />
    );
  }

  const onChange = (option: Option | null | undefined) => {
    if (!isNil(handleChange)) {
      if (!isNil(cacheId)) {
        if (!isNil(option) && !isNil(option.label) && !isNil(option.value)) {
          localStorage.setItem(
            `${CACHE_PREFIX}_LABEL_${cacheId}`,
            option.label,
          );
          localStorage.setItem(
            `${CACHE_PREFIX}_VALUE_${cacheId}`,
            option.value,
          );
        } else {
          localStorage.removeItem(`${CACHE_PREFIX}_LABEL_${cacheId}`);
          localStorage.removeItem(`${CACHE_PREFIX}_VALUE_${cacheId}`);
        }
      }
      handleChange(option ?? undefined);
    }
  };

  return (
    <Box>
      <Button
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>{prefixText}:</Typography>
          <Typography sx={styles.filterValue}>
            {selectedOption?.label ?? 'All'}
          </Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="service-menu"
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isAllSelected ? 'hidden' : undefined,
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOption}
                options={serviceLevelOptions}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                onChange={(event, option) => {
                  onChange(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ServiceFilterButton;
