import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { isNil, isEmpty } from 'lodash';
import pluralize from 'pluralize';
import {
  type AddOrdersToExistingInvoiceErrorOutput,
  type AddOrdersToInvoiceSuccessOutput,
  type AddOrdersToInvoicesForContactSuccessOutput,
  type AddOrdersToNewInvoiceErrorOutput,
} from '../../../../../generated/graphql';
import OrdersListForResultCard from './orders-list-for-result-card';
import { useMemo } from 'react';

const AddOrdersToInvoicesForContactSuccessDetails = ({
  successOutput,
}: {
  readonly successOutput: AddOrdersToInvoicesForContactSuccessOutput;
}) => {
  const { existingInvoiceAdds, newInvoiceAdds } = successOutput;
  const successfulAdditionsToExistingInvoices = useMemo(
    () =>
      existingInvoiceAdds.filter(
        (invoice): invoice is AddOrdersToInvoiceSuccessOutput =>
          invoice.__typename === 'AddOrdersToInvoiceSuccessOutput',
      ),
    [existingInvoiceAdds],
  );
  const successfulCreationsOfNewInvoices = useMemo(
    () =>
      newInvoiceAdds.filter(
        (invoice): invoice is AddOrdersToInvoiceSuccessOutput =>
          invoice.__typename === 'AddOrdersToInvoiceSuccessOutput',
      ),
    [newInvoiceAdds],
  );

  const failedUpdatesToExistingInvoices = useMemo(
    () =>
      existingInvoiceAdds.filter(
        (invoice): invoice is AddOrdersToExistingInvoiceErrorOutput =>
          invoice.__typename === 'AddOrdersToExistingInvoiceErrorOutput',
      ),
    [existingInvoiceAdds],
  );
  const failedCreationsOfNewInvoices = useMemo(
    () =>
      newInvoiceAdds.filter(
        (invoice): invoice is AddOrdersToNewInvoiceErrorOutput =>
          invoice.__typename === 'AddOrdersToNewInvoiceErrorOutput',
      ),
    [newInvoiceAdds],
  );
  return (
    <Stack gap={2}>
      {/* Success Alert */}
      {(!isEmpty(successfulAdditionsToExistingInvoices) ||
        !isEmpty(successfulCreationsOfNewInvoices)) && (
        <Alert severity="success">
          <AlertTitle>
            {successfulAdditionsToExistingInvoices.length +
              successfulCreationsOfNewInvoices.length}{' '}
            succeeded
          </AlertTitle>
          <Stack gap={1}>
            {/* Successful Existing Invoice Adds */}
            {successfulAdditionsToExistingInvoices.length > 0 && (
              <Stack gap={1}>
                <Typography fontWeight="bold" className="mt-2">
                  Added to existing invoices:
                </Typography>
                <Stack pl={2}>
                  {successfulAdditionsToExistingInvoices.map((invoice) => (
                    <Stack key={invoice.invoiceUuid}>
                      <Stack direction="row" gap={1}>
                        <Typography fontWeight="bold">
                          {invoice.invoiceName}
                        </Typography>
                        <Typography>
                          {`(${invoice.ordersAdded.length} ${pluralize(
                            'order',
                            invoice.ordersAdded.length,
                          )})`}
                        </Typography>
                      </Stack>
                      <OrdersListForResultCard orders={invoice.ordersAdded} />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
            {/* Successful New Invoice Adds */}
            {successfulCreationsOfNewInvoices.length > 0 && (
              <Stack gap={1}>
                <Typography fontWeight="bold" className="mt-2">
                  Created new invoices:
                </Typography>
                <Stack pl={2}>
                  {successfulCreationsOfNewInvoices.map((invoice) => (
                    <Stack key={invoice.invoiceUuid}>
                      <Stack direction="row" gap={1}>
                        <Typography fontWeight="bold">
                          {invoice.invoiceName}
                        </Typography>
                        <Typography>
                          {`(${invoice.ordersAdded.length} ${pluralize(
                            'order',
                            invoice.ordersAdded.length,
                          )})`}
                        </Typography>
                      </Stack>
                      <OrdersListForResultCard orders={invoice.ordersAdded} />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Alert>
      )}

      {/* Error Alert */}
      {(!isEmpty(failedUpdatesToExistingInvoices) ||
        !isEmpty(failedCreationsOfNewInvoices)) && (
        <Alert severity="error">
          <AlertTitle>
            {failedUpdatesToExistingInvoices.length +
              failedCreationsOfNewInvoices.length}{' '}
            failed
          </AlertTitle>
          <Stack gap={1}>
            {/* Failure case when existing invoice could not be updated/orders could not be added to it. */}
            {!isEmpty(failedUpdatesToExistingInvoices) && (
              <Stack gap={1}>
                <Typography fontWeight="bold" className="mt-2">
                  Could not add to the following invoices:
                </Typography>
                <Stack pl={2}>
                  {failedUpdatesToExistingInvoices.map((invoice) => (
                    <Stack key={invoice.invoiceUuid}>
                      <Stack direction="row" gap={1}>
                        <Typography color="error" fontWeight="bold">
                          {invoice.invoiceName}
                        </Typography>
                        <Typography color="error">
                          {`(${invoice.attemptedOrdersToAdd.length} ${pluralize(
                            'order',
                            invoice.attemptedOrdersToAdd.length,
                          )})`}
                        </Typography>
                      </Stack>
                      <OrdersListForResultCard
                        orders={invoice.attemptedOrdersToAdd}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}

            {/* Failure case when new invoice could not be created. We know that because the createdInvoiceName is null.
             */}
            {failedCreationsOfNewInvoices.some((invoice) =>
              isNil(invoice.createdInvoiceName),
            ) && (
              <Stack gap={1}>
                <Typography fontWeight="bold" className="mt-2">
                  Could not create new invoices:
                </Typography>
                <Stack pl={2}>
                  {failedCreationsOfNewInvoices.map(
                    (invoice) =>
                      isNil(invoice.createdInvoiceName) && (
                        <Stack key={null}>
                          <Stack direction="row" gap={1}>
                            <Typography color="error" fontWeight="bold">
                              New invoice
                            </Typography>
                            <Typography color="error">
                              {`(${invoice.attemptedOrdersToAdd.length} ${pluralize(
                                'order',
                                invoice.attemptedOrdersToAdd.length,
                              )})`}
                            </Typography>
                          </Stack>
                          <OrdersListForResultCard
                            orders={invoice.attemptedOrdersToAdd}
                          />
                        </Stack>
                      ),
                  )}
                </Stack>
              </Stack>
            )}
            {/* 
            Failure case when new invoice is created but the orders cannot be added to it. With transactions this should
            theoretically never happen but we have a safeguard here. When a new invoice is created but the orders cannot be added to it, 
            the createdInvoiceName will be non null 
            */}
            {failedCreationsOfNewInvoices.some(
              (invoice) => !isNil(invoice.createdInvoiceName),
            ) && (
              <Stack gap={1}>
                <Typography fontWeight="bold" className="mt-2">
                  Created new invoices but could not add orders to them:
                </Typography>
                <Stack pl={2}>
                  {failedCreationsOfNewInvoices.map(
                    (invoice) =>
                      !isNil(invoice.createdInvoiceName) && (
                        <Stack key={null}>
                          <Stack direction="row" gap={1}>
                            <Typography color="error" fontWeight="bold">
                              {invoice.createdInvoiceName}
                            </Typography>
                            <Typography color="error">
                              {`(${invoice.attemptedOrdersToAdd.length} ${pluralize(
                                'order',
                                invoice.attemptedOrdersToAdd.length,
                              )})`}
                            </Typography>
                          </Stack>
                          <OrdersListForResultCard
                            orders={invoice.attemptedOrdersToAdd}
                          />
                        </Stack>
                      ),
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Alert>
      )}
    </Stack>
  );
};

export default AddOrdersToInvoicesForContactSuccessDetails;
