export const FILTER_STORE_KEY = 'filter-store';
export const DISPATCH_STORE_KEY = 'dispatch-store';
export const GENERATE_ROUTES_STORE_KEY = 'generate-routes-store';
export const DISPATCH_SERVICE_DATE_OPTION_KEY = 'dispatch_service_date_option';
export const ORDERS_CHANGED_SORT_MODEL_KEY = 'orders_changed_sort_model';
export const ORDERS_CHANGED_TABLE_COLUMNS_KEY = 'orders_changed_table_columns';
export const ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY =
  'orders_table_column_widths';
export const ORDERS_TABLE_TABS_ORDER_LOCAL_STORAGE_KEY =
  'orders_table_tabs_order';
export const PLANNING_DATE_KEY = 'planningDate';
export const PLANNING_DATE_EXPIRATION_KEY = 'planningDateExpiration';
export const RESERVATION_SYSTEM_COLUMN_WIDTHS_KEY =
  'reservation_system_column_widths';
export const ROUTES_SORT_ASC_KEY = 'routes_sort_asc';
export const ROUTES_SORT_TYPE_KEY = 'routes_sort_type';
export const ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY =
  'route_stops_column_widths';
export const UNASSIGNED_STOPS_COLUMN_WIDTHS_KEY =
  'unassigned_stops_column_widths';
export const UNASSIGNED_STOPS_TABLE_COLUMNS_KEY =
  'unassigned_stops_table_columns';
export const UNASSIGNED_STOPS_CHANGED_SORT_MODEL_KEY =
  'unassigned_stops_changed_sort_model';
export const LH_PAGE_SIZE_LOCAL_STORAGE_KEY = 'line_haul_dispatch_page_number';
export const SIDEBAR_OPEN_KEY = 'sidebar-open';
export const CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY = 'customer-portal-sidebar-open';

/** Keys to persist upon logout. */
export const LOCAL_STORAGE_KEYS_TO_PERSIST = [
  DISPATCH_STORE_KEY,
  DISPATCH_SERVICE_DATE_OPTION_KEY,
  PLANNING_DATE_KEY,
  PLANNING_DATE_EXPIRATION_KEY,
  RESERVATION_SYSTEM_COLUMN_WIDTHS_KEY,
  ROUTES_SORT_ASC_KEY,
  ROUTES_SORT_TYPE_KEY,
  ROUTE_STOPS_COLUMN_WIDTHS_LOCAL_STORAGE_KEY,
  UNASSIGNED_STOPS_COLUMN_WIDTHS_KEY,
  ORDERS_TABLE_TABS_ORDER_LOCAL_STORAGE_KEY,
  ORDERS_TABLE_COLUMN_WIDTHS_LOCAL_STORAGE_KEY,
  CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY,
] as const;
