import { useFormContext, useWatch } from 'react-hook-form';
import { v4 } from 'uuid';
import {
  FulfillmentType,
  SettlementMode,
  ShipmentType,
} from '../../../../../generated/graphql';
import {
  type IndependentSettlementBillLineItemValues,
  type OrderFormFieldValues,
} from '../forms/types';
import { getInitialFreightCostValues } from '../forms/utils';
import { isNil } from 'lodash';

const useOrderFormSettlementActions = ({ stopIdx }: { stopIdx: number }) => {
  const { control, getValues, setValue } =
    useFormContext<OrderFormFieldValues>();

  const defaultFuelSurcharge = getValues('defaultFuelSurcharge');
  const orderFulfillmentType = getValues('fulfillmentType');

  const onAddIndependentSettlementBillLineItem = ({
    driverUuid,
  }: {
    driverUuid: string;
  }) => {
    const currentSettlementBillLineItems =
      getValues(`stops.${stopIdx}.settlementBillLineItems`) ?? [];
    const stopType = getValues(`stops.${stopIdx}.stopType`);
    if (
      !isNil(
        currentSettlementBillLineItems.find(
          (li) => li.driverUuid === driverUuid,
        ),
      )
    ) {
      return currentSettlementBillLineItems;
    }
    const newFreightCost = getInitialFreightCostValues({
      defaultFuelSurcharge,
      context: {
        shipmentType: ShipmentType.Regular,
        stopType,
      },
      fulfillmentType: orderFulfillmentType ?? FulfillmentType.MultiTrip,
    });

    const newLineItem: IndependentSettlementBillLineItemValues = {
      id: v4(),
      settlementMode: SettlementMode.Independent,
      driverUuid,
      driverSettlementBillUuid: null,
      settlementBillFinalizedDate: null,
      freightCost: newFreightCost,
      customCosts: [],
      isLocal: true,
      totalCharge: 0,
    };

    const newSettlementBillLineItems = [
      ...currentSettlementBillLineItems,
      newLineItem,
    ];
    setValue(
      `stops.${stopIdx}.settlementBillLineItems`,
      newSettlementBillLineItems,
    );

    return newSettlementBillLineItems;
  };

  const onRemoveIndependentSettlementBillLineItem = ({
    driverUuid,
  }: {
    driverUuid: string;
  }) => {
    const currentSettlementBillLineItems = getValues(
      `stops.${stopIdx}.settlementBillLineItems`,
    );
    const newSettlementBillLineItems = currentSettlementBillLineItems?.filter(
      (li) => li.driverUuid !== driverUuid,
    );
    setValue(
      `stops.${stopIdx}.settlementBillLineItems`,
      newSettlementBillLineItems,
    );

    return newSettlementBillLineItems;
  };

  return {
    onAddIndependentSettlementBillLineItem,
    onRemoveIndependentSettlementBillLineItem,
  };
};

export { useOrderFormSettlementActions };
