export enum Tabs {
  UNSETTLED = 'Unsettled',
  SETTLED = 'Settled',
}

export enum SettlementBillsTab {
  NOT_FINALIZED = 'Not finalized',
  FINALIZED = 'Finalized',
  ALL = 'All',
}

export enum SettlementBillLineItemsTableMode {
  LINE_ITEMS_TABLE, // When you're viewing a table of all line items
  SETTLEMENT_BILL, // When you're viewing the line items for a specific settlement bill
}
