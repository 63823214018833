import { CheckCircle, Error, Warning } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import { type AddOrdersToInvoicesForContactOutput } from '../../../../../generated/graphql';
import AccordionSummary from '../../accordion-summary';
import AddOrdersToInvoicesForContactError from './add-orders-to-invoices-for-contact-error';
import AddOrdersToInvoicesForContactSuccessDetails from './add-orders-to-invoices-for-contact-success-details';

type AdditionsToInvoicesForContactAccordionProps = {
  readonly addOrdersToInvoicesForContactOutput: AddOrdersToInvoicesForContactOutput;
};

const AccordionEndIcon = ({
  addOrdersToInvoicesForContactOutput,
}: {
  readonly addOrdersToInvoicesForContactOutput: AddOrdersToInvoicesForContactOutput;
}) => {
  const theme = useTheme();
  if (isNil(addOrdersToInvoicesForContactOutput.__typename)) return null;
  if (
    addOrdersToInvoicesForContactOutput.__typename ===
    'AddOrdersToInvoicesForContactErrorOutput'
  ) {
    return (
      <Stack direction="row" gap={1}>
        <Error color="error" />
        <Typography color="error">
          0/
          {addOrdersToInvoicesForContactOutput.proposedNewInvoicesToCreate
            .length +
            addOrdersToInvoicesForContactOutput
              .proposedUpdatesToExistingInvoices.length}{' '}
          succeeded
        </Typography>
      </Stack>
    );
  }
  if (
    addOrdersToInvoicesForContactOutput.__typename ===
    'AddOrdersToInvoicesForContactSuccessOutput'
  ) {
    const numExistingInvoiceAdds =
      addOrdersToInvoicesForContactOutput.existingInvoiceAdds.length;
    const numNewInvoiceAdds =
      addOrdersToInvoicesForContactOutput.newInvoiceAdds.length;

    const numSuccessfulExistingInvoiceAdds =
      addOrdersToInvoicesForContactOutput.existingInvoiceAdds.filter(
        (invoice) => invoice.__typename === 'AddOrdersToInvoiceSuccessOutput',
      ).length;
    const numSuccessfulNewInvoiceAdds =
      addOrdersToInvoicesForContactOutput.newInvoiceAdds.filter(
        (invoice) => invoice.__typename === 'AddOrdersToInvoiceSuccessOutput',
      ).length;

    const failedExistingInvoiceAdds =
      numExistingInvoiceAdds - numSuccessfulExistingInvoiceAdds;
    const failedNewInvoiceAdds =
      numNewInvoiceAdds - numSuccessfulNewInvoiceAdds;

    // all of them failed so show an error
    if (
      failedExistingInvoiceAdds + failedNewInvoiceAdds ===
      numExistingInvoiceAdds + numNewInvoiceAdds
    ) {
      return (
        <Stack direction="row" gap={1}>
          <Error color="error" />
          <Typography color="error">
            0/
            {numExistingInvoiceAdds + numNewInvoiceAdds} succeeded
          </Typography>
        </Stack>
      );
    }

    if (failedExistingInvoiceAdds > 0 || failedNewInvoiceAdds > 0) {
      return (
        <Stack direction="row" gap={1}>
          <Warning sx={{ color: theme.palette.warningYellow[40] }} />
          <Typography sx={{ color: theme.palette.warningYellow[60] }}>
            {numSuccessfulExistingInvoiceAdds + numSuccessfulNewInvoiceAdds}/
            {numExistingInvoiceAdds + numNewInvoiceAdds} succeeded
          </Typography>
        </Stack>
      );
    }
    return (
      <Stack direction="row" gap={1}>
        <CheckCircle sx={{ color: theme.palette.successGreen[60] }} />
        <Typography color={theme.palette.successGreen[60]}>
          {numSuccessfulExistingInvoiceAdds + numSuccessfulNewInvoiceAdds}/
          {numExistingInvoiceAdds + numNewInvoiceAdds} succeeded
        </Typography>
      </Stack>
    );
  }
};

const AdditionsToInvoicesForContactAccordion = ({
  addOrdersToInvoicesForContactOutput,
}: AdditionsToInvoicesForContactAccordionProps) => {
  if (isNil(addOrdersToInvoicesForContactOutput.__typename)) return null;

  return (
    <Accordion
      key={addOrdersToInvoicesForContactOutput.contactUuid}
      elevation={0}
    >
      <AccordionSummary>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{ ml: 1 }}
        >
          <Typography>
            {addOrdersToInvoicesForContactOutput.contactName}
          </Typography>
          <AccordionEndIcon
            addOrdersToInvoicesForContactOutput={
              addOrdersToInvoicesForContactOutput
            }
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {addOrdersToInvoicesForContactOutput.__typename ===
          'AddOrdersToInvoicesForContactErrorOutput' && (
          <AddOrdersToInvoicesForContactError
            errorOutput={addOrdersToInvoicesForContactOutput}
          />
        )}
        {addOrdersToInvoicesForContactOutput.__typename ===
          'AddOrdersToInvoicesForContactSuccessOutput' && (
          <AddOrdersToInvoicesForContactSuccessDetails
            successOutput={addOrdersToInvoicesForContactOutput}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(AdditionsToInvoicesForContactAccordion);
