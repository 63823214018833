import { Box, type SxProps, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';

type AppointmentDatePickerProps = {
  readonly selectedDate: Dayjs;
  readonly setSelectedDate: (date: Dayjs) => void;
};

const AppointmentDatePicker = ({
  selectedDate,
  setSelectedDate,
}: AppointmentDatePickerProps) => {
  const styles: Record<string, SxProps> = {
    datePickerRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    datePickerTitle: {
      marginRight: 2,
      fontWeight: 'bold',
    },
  };

  return (
    <Box sx={styles.datePickerRow}>
      <Typography
        sx={styles.datePickerTitle}
        color="textPrimary"
        whiteSpace="nowrap"
      >
        Appointments for
      </Typography>
      <DatePicker
        renderInput={(params) => (
          <TextField
            aria-label="date-picker"
            size="small"
            sx={{ width: 200, backgroundColor: 'white' }}
            {...params}
          />
        )}
        value={selectedDate}
        onChange={(newDate) => {
          if (!isNil(newDate)) {
            setSelectedDate(newDate);
          }
        }}
      />
    </Box>
  );
};

export default AppointmentDatePicker;
