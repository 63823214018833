import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  type SxProps,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { isNil, isString } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useMinimalServiceRegionsQuery,
} from '../../../generated/graphql';
import { CreateServiceRegionModal } from './create-service-region-modal';
import { EditServiceRegion } from './edit-service-region';
import { useErrors } from '../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../common/components/errors-alert';

type EmptyStateProps = Readonly<{
  sx?: SxProps;
  canCreate: boolean;
  onCreate: () => void;
}>;

const EmptyState = ({ sx, canCreate, onCreate }: EmptyStateProps) => (
  <Box sx={sx}>
    <Typography mb={2}>
      You have not created any service regions yet.
    </Typography>
    {canCreate && (
      <Button variant="outlined" onClick={onCreate}>
        Add
      </Button>
    )}
  </Box>
);

export const ServiceRegionsPage = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTerminals,
  );
  const [selectedRegionId, setSelectedRegionId] = useState<string | null>(null);
  const { errors, onError, clearErrors } = useErrors();
  const { data, loading, error, refetch } = useMinimalServiceRegionsQuery({
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {},
    },
    onCompleted: (data) => {
      const [firstRegion] = data.serviceRegions.serviceRegions;
      if (isNil(selectedRegionId) && !isNil(firstRegion)) {
        setSelectedRegionId(firstRegion.id);
      }
    },
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const serviceRegions = data?.serviceRegions.serviceRegions;

  const createRegionModal = (
    <CreateServiceRegionModal
      open={createModalOpen}
      onCreated={(regions) => {
        setCreateModalOpen(false);
        void refetch();
        setSelectedRegionId(regions[0]?.id ?? serviceRegions?.[0]?.id ?? null);
      }}
      onClose={() => {
        setCreateModalOpen(false);
      }}
    />
  );

  // We show the empty state only when there's no error fetching service regions so that we don't mislead people to think
  // there are actually no service regions in case the query fails.
  if (!loading && isNil(error) && serviceRegions?.length === 0) {
    return (
      <>
        <EmptyState
          sx={{ m: 3 }}
          canCreate={canWrite}
          onCreate={() => {
            setCreateModalOpen(true);
          }}
        />
        {createRegionModal}
      </>
    );
  }

  return (
    <>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <Stack direction="row" height="100%">
        <Box
          width="280px"
          minWidth="280px"
          borderRight={1}
          borderColor="divider"
          overflow="auto"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={3}
          >
            <Typography variant="h2" fontSize="18px" fontWeight="500">
              Service Regions
            </Typography>
            {canWrite && (
              <Button
                variant="outlined"
                disabled={loading}
                onClick={() => {
                  setCreateModalOpen(true);
                }}
              >
                Add
              </Button>
            )}
          </Stack>
          <Divider sx={{ borderBottomWidth: '2px' }} />
          {loading ? (
            <CircularProgress sx={{ m: 3 }} />
          ) : (
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={selectedRegionId ?? false}
              scrollButtons="auto"
              sx={{ pt: 1 }}
              onChange={(_ev, newValue) => {
                if (isString(newValue)) {
                  setSelectedRegionId(newValue);
                }
              }}
            >
              {serviceRegions?.map((region) => (
                <Tab
                  key={region.id}
                  value={region.id}
                  label={
                    <Stack
                      direction="row"
                      component="span"
                      gap={1}
                      alignItems="center"
                    >
                      {region.name}
                      {region.isArchived && (
                        <Typography
                          component="span"
                          color="text.secondary"
                          fontSize="small"
                          fontWeight={400}
                        >
                          (Archived)
                        </Typography>
                      )}
                    </Stack>
                  }
                  sx={{ alignItems: 'start' }}
                />
              ))}
            </Tabs>
          )}
        </Box>
        <Box flexGrow={1} overflow="auto">
          {!isNil(selectedRegionId) && (
            <EditServiceRegion id={selectedRegionId} />
          )}
        </Box>
      </Stack>
      {createRegionModal}
    </>
  );
};
