import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { type StandardShipmentValues } from 'shared/types';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import apolloClient from '../../../apollo-client';
import {
  type ErrorResponse,
  validateNonNegativeNumber,
  validateNotEmpty,
  validateString,
  type ValidationResponse,
} from '../../../common/form/formValidators';
import {
  type BillingShipmentStatus,
  CustomChargeBillingMethod,
  DeadlineType,
  type LegCreateInput,
  type LegUpsertInput,
  MeDocument,
  type MeQuery,
  type MeQueryVariables,
  OrderSegmentType,
  PickupOrDelivery,
  Segment,
  ShipmentStatus,
  ShipmentType,
  type StandardShipmentArrayUpdateInput,
  type StandardShipmentCreateInput,
  type StandardShipmentFieldsCreateInput,
  type StandardShipmentFieldsUpdateInput,
  type StandardShipmentFragment,
  StandardShipmentType,
  type StandardShipmentUpdateInput,
  type StandardStopType,
  type StopType,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  createAddressUpsertInput,
  upsertAddressForShipment,
} from '../../addresses/redux/addresses-values-thunks';
import {
  createFreightChargeCreateInput,
  createFreightChargeUpsertInput,
  createInitialFreightCharge,
  type FreightChargeErrorsResponse,
  getFreightChargeErrors,
  upsertFreightChargesForShipment,
} from '../../freight-charges/redux/freight-charges-values-thunks';
import { upsertInvoice } from '../../invoice-old/redux/invoices-values-slice';
import {
  createLegUpsertInput,
  createNewLeg,
  createStandardLegCreateInput,
  getLegErrors,
  type LegErrorsResponse,
  upsertLegsForShipment,
} from '../../legs/redux/leg-values-thunks';
import {
  createInitialCustomCharge,
  selectCustomChargesByIds,
} from '../../orders/redux/custom-charges-values-slice';
import {
  createCustomChargesCreateInput,
  createCustomChargesUpdateInput,
  type CustomChargeErrorsResponse,
  getCustomChargesErrors,
  upsertCustomChargesForShipment,
} from '../../orders/redux/custom-charges-values-thunks';
import { upsertDocumentsForShipment } from '../../orders/redux/document-values-thunks';
import {
  createShipmentSubscriberUpsertInput,
  upsertShipmentSubscribersForShipment,
} from '../../orders/redux/standard/shipment-subscribers-values-thunks';
import {
  addOnePackageValues,
  selectPackagesByIds,
} from '../../packages/redux/package-values-slice';
import {
  createNewPackage,
  getPackageErrors,
  type PackageErrorsResponse,
} from '../../packages/redux/package-values-thunks';
import { upsertOneStandardShipmentErrors } from './standard-shipments-errors-slice';
import {
  addOneStandardShipmentValues,
  selectStandardShipmentValuesById,
  upsertOneStandardShipmentValues,
} from './standard-shipments-values-slice';

type CreateStandardShipmentCreateInputArg = {
  shipmentUuid: string;
  shipmentSubscriberUuids?: string[];
};

export const createStandardShipmentCreateInput = createAsyncThunk<
  StandardShipmentCreateInput,
  CreateStandardShipmentCreateInputArg,
  {
    state: RootState;
  }
>(
  'standardShipments/createStandardShipmentCreateInput',
  async (arg, thunkAPI): Promise<StandardShipmentCreateInput> => {
    const standardShipmentValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      arg.shipmentUuid,
    );
    if (isNil(standardShipmentValues)) {
      throw new Error(`Invalid shipment uuid: ${arg.shipmentUuid}`);
    }

    const legCreateInputs: LegCreateInput[] = filterNotNil(
      await Promise.all(
        filterNotNil([standardShipmentValues.firstLegUuid]).map(
          async (legUuid) =>
            thunkAPI
              .dispatch(
                createStandardLegCreateInput({
                  legUuid,
                  // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
                  pickupOrDelivery: standardShipmentValues.pickupOrDelivery,
                }),
              )
              .unwrap(),
        ),
      ),
    );

    const standardShipmentFieldsCreateInput: StandardShipmentFieldsCreateInput =
      {
        cargoDescription: standardShipmentValues.cargoDescription,
        cargoPieces: standardShipmentValues.cargoPieces,
        deliveryDate: standardShipmentValues.deliveryDate,
        deadlineType: standardShipmentValues.deadlineType,
        deadlineDate: standardShipmentValues.deadlineDate,
        dimFactor: standardShipmentValues.dimFactor,
        serviceUuid: standardShipmentValues.serviceUuid,
        type: standardShipmentValues.type ?? StandardShipmentType.SingleStop,
        pickupOrDelivery:
          // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
          standardShipmentValues.pickupOrDelivery ?? PickupOrDelivery.Delivery,
      };

    const freightChargeCreateInput = isNil(
      standardShipmentValues.freightChargeId,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createFreightChargeCreateInput({
              freightChargeId: standardShipmentValues.freightChargeId,
            }),
          )
          .unwrap();

    const customChargeCreateInputs = isNil(
      standardShipmentValues.customChargeIds,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createCustomChargesCreateInput({
              customChargeIds: standardShipmentValues.customChargeIds,
            }),
          )
          .unwrap();

    const shipperAddressUpsertInput = isNil(
      standardShipmentValues.shipperAddressUuid,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createAddressUpsertInput({
              addressUuid: standardShipmentValues.shipperAddressUuid,
            }),
          )
          .unwrap();
    const subscriberUuids = [
      ...(arg.shipmentSubscriberUuids ?? []),
      ...(standardShipmentValues.shipmentSubscriberUuids ?? []),
    ];
    const shipmentSubscriberUpsertInputs =
      subscriberUuids.length > 0
        ? await Promise.all(
            subscriberUuids.map(async (shipmentSubscriberUuid) =>
              thunkAPI
                .dispatch(
                  createShipmentSubscriberUpsertInput({
                    shipmentSubscriberUuid,
                  }),
                )
                .unwrap(),
            ),
          )
        : undefined;

    return {
      customChargeCreateInputs,
      freightChargeCreateInput,
      legCreateInputs,
      packageCreateInputs: null,
      standardShipmentFieldsCreateInput:
        standardShipmentValues.shipmentType === ShipmentType.Regular
          ? standardShipmentFieldsCreateInput
          : null,
      shipperAddressUpsertInput,
      shipmentSubscriberUpsertInputs,
      hideFromBilling: standardShipmentValues.hideFromBilling,
      overridePackageWeight: standardShipmentValues.overridePackageWeight,
      shouldUseDimWeight: standardShipmentValues.shouldUseDimensionalWeight,
      documentConnectInputs: standardShipmentValues.documentUuids?.map(
        (docUuid) => ({ uuid: docUuid }),
      ),
      shipmentType: standardShipmentValues.shipmentType,
      airportInfoUuid: standardShipmentValues.airportInfoUuid,
      uuid: arg.shipmentUuid,
    };
  },
);

type CreateStandardShipmentUpdateInputArg = {
  shipmentUuid: string;
};

export const createStandardShipmentUpdateInput = createAsyncThunk<
  StandardShipmentUpdateInput,
  CreateStandardShipmentUpdateInputArg,
  {
    state: RootState;
  }
>(
  'standardShipments/createStandardShipmentUpdateInput',
  async (arg, thunkAPI): Promise<StandardShipmentUpdateInput> => {
    const standardShipmentValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      arg.shipmentUuid,
    );
    if (isNil(standardShipmentValues)) {
      throw new Error(`Invalid shipment uuid: ${arg.shipmentUuid}`);
    }

    const legUuids = filterNotNil([standardShipmentValues.firstLegUuid]);
    const legUpsertInputs: LegUpsertInput[] = await Promise.all(
      legUuids.map(async (legUuid) =>
        thunkAPI.dispatch(createLegUpsertInput({ legUuid })).unwrap(),
      ),
    );

    const standardShipmentFieldsUpdateInput:
      | StandardShipmentFieldsUpdateInput
      | undefined = isNil(standardShipmentValues.fieldsUuid)
      ? undefined
      : {
          cargoDescription: standardShipmentValues.cargoDescription,
          cargoPieces: standardShipmentValues.cargoPieces,
          deliveryDate: standardShipmentValues.deliveryDate,
          deadlineType: standardShipmentValues.deadlineType,
          deadlineDate: standardShipmentValues.deadlineDate,
          dimFactor: standardShipmentValues.dimFactor,
          type: standardShipmentValues.type,
          uuid: standardShipmentValues.fieldsUuid,
          serviceUuid: standardShipmentValues.serviceUuid,
          // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
          pickupOrDelivery: standardShipmentValues.pickupOrDelivery,
        };

    const freightChargeUpsertInput = isNil(
      standardShipmentValues.freightChargeId,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createFreightChargeUpsertInput({
              freightChargeId: standardShipmentValues.freightChargeId,
            }),
          )
          .unwrap();

    const customChargeArrayUpdateInputs = isNil(
      standardShipmentValues.customChargeIds,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createCustomChargesUpdateInput({
              customChargeIds: standardShipmentValues.customChargeIds,
            }),
          )
          .unwrap();

    const shipperAddressUpsertInput = isNil(
      standardShipmentValues.shipperAddressUuid,
    )
      ? undefined
      : await thunkAPI
          .dispatch(
            createAddressUpsertInput({
              addressUuid: standardShipmentValues.shipperAddressUuid,
            }),
          )
          .unwrap();

    const isRecoveryOrTransfer =
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      standardShipmentValues?.pickupOrDelivery === PickupOrDelivery.Transfer ||
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      standardShipmentValues?.pickupOrDelivery === PickupOrDelivery.Recovery;

    return {
      customChargeArrayUpdateInputs,
      freightChargeUpsertInput,
      legUpsertInputs,
      packageArrayUpdateInputs: null,
      overridePackageWeight: standardShipmentValues.overridePackageWeight,
      standardShipmentFieldsUpdateInput,
      hideFromBilling: standardShipmentValues.hideFromBilling,
      uuid: arg.shipmentUuid,
      airportInfoUuid: isRecoveryOrTransfer
        ? standardShipmentValues.airportInfoUuid
        : null,
      shipperAddressUpsertInput,
      shouldUseDimWeight: standardShipmentValues.shouldUseDimensionalWeight,
    };
  },
);

type CreateStandardShipmentArrayUpdateInputArg = {
  shipmentUuid: string;
};

export const createStandardShipmentArrayUpdateInput = createAsyncThunk<
  StandardShipmentArrayUpdateInput,
  CreateStandardShipmentArrayUpdateInputArg,
  {
    state: RootState;
  }
>(
  'standardShipments/createStandardShipmentArrayUpdateInput',
  async (arg, thunkAPI): Promise<StandardShipmentArrayUpdateInput> => {
    const standardShipmentValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      arg.shipmentUuid,
    );
    if (isNil(standardShipmentValues)) {
      throw new Error(`Invalid shipment uuid: ${arg.shipmentUuid}`);
    }

    if (standardShipmentValues.isLocal) {
      return {
        shipmentCreateInput: await thunkAPI
          .dispatch(
            createStandardShipmentCreateInput({
              shipmentUuid: arg.shipmentUuid,
            }),
          )
          .unwrap(),
      };
    }
    return {
      shipmentUpdateInput: await thunkAPI
        .dispatch(
          createStandardShipmentUpdateInput({
            shipmentUuid: arg.shipmentUuid,
          }),
        )
        .unwrap(),
    };
  },
);

type BuildNewStandardShipmentArg = {
  orderUuid?: string;
  quoteUuid?: string;
  pickupOrDelivery: PickupOrDelivery;
  stopType: StopType;
  defaultConsigneeStopUuid?: string;
  defaultShipperStopUuid?: string;
  defaultFuelSurcharge?: number;
  defaultDimFactor?: number;
  defaultStandardStopType?: StandardStopType;
};

export const buildNewStandardShipment = createAsyncThunk<
  string,
  BuildNewStandardShipmentArg,
  {
    state: RootState;
  }
>('standardShipments/buildNewStandardShipment ', async (arg, thunkAPI) => {
  const shipmentUuid = v4();
  const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
  });
  const legUuid: string = await thunkAPI
    .dispatch(
      createNewLeg({
        endStopUuid: arg.defaultConsigneeStopUuid,
        shipmentUuid,
        segment: Segment.Cartage,
        standardStopType: arg.defaultStandardStopType,
        defaultManuallyConfirmed:
          companyData.data.me?.company.configuration
            ?.showManuallyConfirmedByDefault ?? undefined,
        stopType: arg.stopType,
      }),
    )
    .unwrap();
  const packageUuid: string = await thunkAPI
    .dispatch(createNewPackage())
    .unwrap();
  const freightChargeId = await thunkAPI
    .dispatch(
      createInitialFreightCharge({
        shipmentUuid,
        defaultFuelSurcharge:
          arg.defaultFuelSurcharge ??
          companyData.data.me?.company.configuration?.defaultFuelSurcharge ??
          undefined,
        defaultFuelSurchargeBillingMethod:
          companyData.data.me?.company.configuration
            ?.defaultFuelSurchargeBillingMethod,
      }),
    )
    .unwrap();
  const customChargeId = await thunkAPI
    .dispatch(createInitialCustomCharge())
    .unwrap();
  // Add 12 hours so that no matter what time and time zone the user
  // entering this is in, it shows the same for other users
  const tomorrowDate = dayjs()
    .startOf('day')
    .add(1, 'day')
    .add(12, 'hours')
    .toDate();
  thunkAPI.dispatch(
    addOneStandardShipmentValues({
      shouldUseDimensionalWeight: false,
      customChargeIds: [customChargeId],
      freightChargeId,
      orderUuid: arg.orderUuid,
      quoteUuid: arg.quoteUuid,
      airportInfoUuid: undefined,
      uuid: shipmentUuid,
      isLocal: true,
      firstLegUuid: legUuid,
      packageUuids: [packageUuid],
      billingStatus: undefined,
      preBillingStatus: undefined,
      name: undefined,
      weight: undefined,
      cargoDescription: undefined,
      cargoPieces: undefined,
      serviceUuid: undefined,
      deliveryDate: undefined,
      deadlineDate: tomorrowDate.toISOString(),
      deadlineType: DeadlineType.DueOn,
      dimFactor: arg.defaultDimFactor ?? 250,
      type: StandardShipmentType.SingleStop,
      fieldsUuid: v4(),
      overridePackageWeight: false,
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      pickupOrDelivery: arg.pickupOrDelivery,
      numberOfFailures: undefined,
      clientReferenceNumber: undefined,
      shipmentType: ShipmentType.Regular,
      shipperAddressUuid: undefined,
      shouldBillIndividually: false,
      shipmentSubscriberUuids: undefined,
      totalChargesAmount: undefined,
      hasSignedPOD: false,
    }),
  );
  return shipmentUuid;
});

type StandardShipmentSchema = {
  [k in keyof Required<StandardShipmentValues>]: boolean;
};

const standardShipmentFieldIsRequired: StandardShipmentSchema = {
  overridePackageWeight: false,
  consolidatableShipmentUuids: false,
  hideFromBilling: false,
  shipmentSubscriberUuids: false,
  shouldBillIndividually: false,
  orderUuid: true,
  shipperAddressUuid: false,
  customChargeIds: false,
  freightChargeId: false,
  invoiceUuid: false,
  isLocal: false,
  firstLegUuid: false,
  packageUuids: false,
  preBillingStatus: false,
  billingStatus: false,
  uuid: true,
  name: false,
  shouldUseDimensionalWeight: false,
  cargoDescription: false,
  cargoPieces: false,
  weight: false,
  serviceUuid: false,
  service: false,
  shipmentType: false,
  deliveryDate: false,
  deadlineDate: false,
  deadlineType: false,
  dimFactor: true,
  type: true,
  fieldsUuid: false,
  pickupOrDelivery: false,
  documentUuids: false,
  totalChargesAmount: false,
  numberOfFailures: false,
  clientReferenceNumber: false,
  hasSignedPOD: false,
  airportInfoUuid: false,
  quoteUuid: false,
};

type GetStandardShipmentErrorsArg = {
  shipmentId: string;
  segment?: Segment | undefined;
  orderSegmentType?: OrderSegmentType | undefined;

  isQuote?: boolean;

  forceValidateAddress?: boolean;
  ffRecoveryTransferAddressOnly: boolean;
};

export type ShipmentErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
  legsErrors: LegErrorsResponse[];
  packagesErrors: PackageErrorsResponse[];
  freightChargeErrors: FreightChargeErrorsResponse;
  customChargesErrors: CustomChargeErrorsResponse[];
};

/**
 *
 * Validate a standard shipment - from either redux state or react state (bulk
 * shipment creation) and return an errors object
 *
 * In bulk shipment creation it only contains the basic fields so we skip things like legs
 */
export const getStandardShipmentErrors = createAsyncThunk<
  ShipmentErrorsResponse,
  GetStandardShipmentErrorsArg,
  {
    state: RootState;
  }
>(
  'standardShipments/getStandardShipmentErrors',
  async (args, thunkAPI): Promise<ShipmentErrorsResponse> => {
    const {
      shipmentId,
      segment,
      orderSegmentType,
      isQuote,
      forceValidateAddress,
      ffRecoveryTransferAddressOnly,
    } = args;
    const shipmentValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      shipmentId,
    );
    if (isNil(shipmentValues)) {
      throw new Error(`Invalid shipment id: ${shipmentId}`);
    }

    const shipmentErrors: ShipmentErrorsResponse = {
      customChargesErrors: [],
      errors: [],
      freightChargeErrors: {
        isValid: true,
        errors: [],
      },
      legsErrors: [],
      packagesErrors: [],
      isValid: true,
    };

    const isRecovery =
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      shipmentValues.pickupOrDelivery === PickupOrDelivery.Recovery;
    const isTransfer =
      // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
      shipmentValues.pickupOrDelivery === PickupOrDelivery.Transfer;
    const isRecoveryOrTransfer = isRecovery || isTransfer;

    await Promise.all(
      objectKeys(standardShipmentFieldIsRequired).map(async (field) => {
        let userFacingFieldName = '';
        let validationResponse: ValidationResponse | null | undefined;

        switch (field) {
          case 'airportInfoUuid': {
            if (isRecovery && !ffRecoveryTransferAddressOnly) {
              userFacingFieldName = 'Recovery Terminal';
              validationResponse = validateNotEmpty(
                shipmentValues.airportInfoUuid,
                true,
              );
            }
            break;
          }
          case 'pickupOrDelivery': {
            userFacingFieldName = 'Pickup or delivery';
            validationResponse = validateString(
              // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
              shipmentValues.pickupOrDelivery,
              // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
              standardShipmentFieldIsRequired.pickupOrDelivery,
            );
            break;
          }
          case 'service': {
            userFacingFieldName = 'Service level';

            let isServiceRequired = standardShipmentFieldIsRequired.service;
            if (
              segment === Segment.Cartage &&
              orderSegmentType === OrderSegmentType.Cartage &&
              // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
              (shipmentValues.pickupOrDelivery === PickupOrDelivery.Pickup ||
                // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
                shipmentValues.pickupOrDelivery === PickupOrDelivery.Delivery)
            ) {
              isServiceRequired = true;
            }
            validationResponse = validateString(
              shipmentValues.serviceUuid,
              isServiceRequired,
            );
            break;
          }
          case 'cargoDescription': {
            userFacingFieldName = 'Cargo description';
            validationResponse = validateString(
              shipmentValues.cargoDescription,
              standardShipmentFieldIsRequired.cargoDescription,
            );
            break;
          }
          case 'cargoPieces': {
            userFacingFieldName = 'Cargo pieces';
            validationResponse = validateNonNegativeNumber(
              shipmentValues.cargoPieces,
              standardShipmentFieldIsRequired.cargoPieces,
            );
            break;
          }
          case 'customChargeIds': {
            const customChargeIds = shipmentValues?.customChargeIds ?? [];
            const customChargeValues = selectCustomChargesByIds(
              thunkAPI.getState(),
              customChargeIds,
            );
            const customChargesAtLeastPartiallyFilledOut =
              customChargeValues.filter(
                (customCharge) =>
                  (customCharge?.billingMethod ===
                    CustomChargeBillingMethod.AdHoc &&
                    (customCharge?.name ?? '').length > 0) ||
                  (customCharge.billingMethod ===
                    CustomChargeBillingMethod.Accessorial &&
                    customCharge?.accessorialId !== undefined &&
                    customCharge?.accessorialId.length > 0),
              );
            if (!isNil(customChargeIds)) {
              const customChargesErrors = await thunkAPI
                .dispatch(
                  getCustomChargesErrors({
                    customChargeIds: customChargesAtLeastPartiallyFilledOut.map(
                      (customCharge) => customCharge.uuid,
                    ),
                  }),
                )
                .unwrap();
              const customChargesAreValid = customChargesErrors.every(
                (customChargeError) => customChargeError.isValid,
              );
              shipmentErrors.isValid =
                shipmentErrors.isValid && customChargesAreValid;
              shipmentErrors.customChargesErrors = customChargesErrors;
            }
            break;
          }
          case 'dimFactor': {
            userFacingFieldName = 'Dim. factor';
            validationResponse = validateNonNegativeNumber(
              shipmentValues.dimFactor,
              standardShipmentFieldIsRequired.dimFactor,
            );
            break;
          }
          case 'freightChargeId': {
            const freightChargeId = shipmentValues?.freightChargeId;
            if (!isNil(freightChargeId)) {
              const freightChargeErrors = await thunkAPI
                .dispatch(getFreightChargeErrors({ freightChargeId }))
                .unwrap();
              shipmentErrors.isValid =
                shipmentErrors.isValid && freightChargeErrors.isValid;
              shipmentErrors.freightChargeErrors = freightChargeErrors;
            }
            break;
          }
          case 'firstLegUuid': {
            shipmentErrors.legsErrors = await Promise.all(
              filterNotNil([shipmentValues.firstLegUuid]).map(async (legId) => {
                const legErrors = await thunkAPI
                  .dispatch(
                    getLegErrors({
                      legId,
                      segment,
                      orderSegmentType,
                      shouldValidateStopAddress: !isRecoveryOrTransfer,
                      isQuote,
                      forceValidateAddress:
                        forceValidateAddress === true && !isTransfer,
                    }),
                  )
                  .unwrap();
                if (!legErrors.isValid) {
                  shipmentErrors.isValid = false;
                }
                return legErrors;
              }),
            );
            break;
          }
          case 'packageUuids': {
            const packages = selectPackagesByIds(
              thunkAPI.getState(),
              shipmentValues?.packageUuids ?? [],
            );
            const filteredPackages = packages.filter(
              (package_) =>
                !(package_.quantity === 0 || isNil(package_.quantity)),
            );
            shipmentErrors.packagesErrors = await Promise.all(
              filteredPackages.map(async (package_) => {
                const packageErrors = await thunkAPI
                  .dispatch(getPackageErrors({ packageUuid: package_.uuid }))
                  .unwrap();
                if (!packageErrors.isValid) {
                  shipmentErrors.isValid = false;
                }
                return packageErrors;
              }),
            );
            break;
          }
          case 'type': {
            userFacingFieldName = 'Shipment type';
            validationResponse = validateNotEmpty(
              shipmentValues.type,
              standardShipmentFieldIsRequired.type,
            );
            break;
          }
          case 'weight': {
            userFacingFieldName = 'Weight';
            validationResponse = validateNonNegativeNumber(
              shipmentValues.weight,
              standardShipmentFieldIsRequired.weight,
            );
            break;
          }
        }
        if (!isNil(validationResponse)) {
          if (validationResponse.valid) {
            // updateOne didn't seem to work here, not sure why
            thunkAPI.dispatch(
              upsertOneStandardShipmentErrors({
                uuid: shipmentId,
                [field]: undefined,
              }),
            );
          } else {
            shipmentErrors.isValid = false;
            shipmentErrors.errors.push({
              field: userFacingFieldName,
              validationResponse,
            });
            thunkAPI.dispatch(
              upsertOneStandardShipmentErrors({
                uuid: shipmentId,
                [field]: validationResponse.explanation,
              }),
            );
          }
        }
      }),
    );

    return shipmentErrors;
  },
);

type UpsertShipmentArg = {
  companyData: MeQuery;
  isDuplicate?: boolean;
  consolidatableShipmentUuids?: string[] | undefined;
  orderUuid: string | undefined;
  quoteUuid?: string;
  shipment: StandardShipmentFragment;
  billingPartyUuid: string;
};

const preBillingStatuses = new Set([
  ShipmentStatus.Created,
  ShipmentStatus.InProgress,
  ShipmentStatus.OutForDelivery,
  ShipmentStatus.Delivered,
]);
const billingStatus = new Set([
  ShipmentStatus.Paid,
  ShipmentStatus.HasIssue,
  ShipmentStatus.Invoiced,
  ShipmentStatus.Finalized,
]);

export const upsertShipment = createAsyncThunk<
  string,
  UpsertShipmentArg,
  {
    state: RootState;
  }
>(
  'standardShipments/upsertShipment',
  async (arg, thunkAPI): Promise<string> => {
    const {
      companyData,
      consolidatableShipmentUuids,
      orderUuid,
      shipment,
      quoteUuid,
    } = arg;

    const legUuids = await thunkAPI
      .dispatch(
        upsertLegsForShipment({
          shipment,
          isDuplicate: arg.isDuplicate ?? false,
        }),
      )
      .unwrap();
    const freightChargeId = await thunkAPI
      .dispatch(
        upsertFreightChargesForShipment({
          shipment,
          companyData,
          isDuplicate: arg.isDuplicate ?? false,
          billingPartyUuid: arg.billingPartyUuid,
        }),
      )
      .unwrap();
    const customChargeIds = await thunkAPI
      .dispatch(
        upsertCustomChargesForShipment({
          shipment,
          isDuplicate: arg.isDuplicate ?? false,
        }),
      )
      .unwrap();
    await thunkAPI.dispatch(upsertAddressForShipment({ shipment }));
    await thunkAPI.dispatch(upsertShipmentSubscribersForShipment({ shipment }));
    const documentUuids = await thunkAPI
      .dispatch(
        upsertDocumentsForShipment({
          shipment,
          isDuplicate: arg.isDuplicate ?? false,
        }),
      )
      .unwrap();
    if (!isNil(shipment.invoice)) {
      await thunkAPI.dispatch(
        upsertInvoice({
          uuid: shipment.invoice?.uuid,
          documentUuids: [],
          dueDate: shipment.invoice.dueDate,
          name: shipment.invoice?.name,
          billToContactId: shipment.invoice.billToContact.uuid,
          journalNumber: shipment.invoice.journalNumber,
        }),
      );
    }

    const packageUuids = shipment.packages.map((package_) => {
      thunkAPI.dispatch(addOnePackageValues(package_));
      return package_.uuid;
    });

    const existingValues = selectStandardShipmentValuesById(
      thunkAPI.getState(),
      shipment.uuid,
    );

    const firstLegUuid = legUuids[0];

    let shipmentBillingStatus: BillingShipmentStatus | undefined;

    if (billingStatus.has(shipment.status as any)) {
      shipmentBillingStatus = shipment.status as any;
    }
    let preBillingStatus;

    if (preBillingStatuses.has(shipment.status as any)) {
      preBillingStatus = shipment.status as any;
    }

    await thunkAPI.dispatch(
      upsertOneStandardShipmentValues({
        shouldUseDimensionalWeight: shipment.shouldUseDimWeight,
        airportInfoUuid: shipment.airportInfo?.uuid,
        // If two shipments are consolidatable this ensures they don't
        // overwrite each other
        consolidatableShipmentUuids:
          consolidatableShipmentUuids ??
          existingValues?.consolidatableShipmentUuids,
        orderUuid,
        customChargeIds,
        freightChargeId,
        hideFromBilling: shipment.hideFromBilling,
        quoteUuid,
        preBillingStatus,
        isLocal: false,
        firstLegUuid,
        packageUuids,
        // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
        pickupOrDelivery: shipment.fields?.pickupOrDelivery,
        shouldBillIndividually: shipment.shouldBillIndividually,
        billingStatus: shipmentBillingStatus,
        uuid: shipment.uuid,
        name: shipment.name,
        weight: undefined,
        cargoDescription: undefined,
        shipmentSubscriberUuids: shipment.shipmentSubscribers.map(
          (shipmentSubscriber) => shipmentSubscriber.uuid,
        ),
        cargoPieces: undefined,
        invoiceUuid: shipment.invoice?.uuid,
        serviceUuid: shipment?.fields?.service?.uuid ?? undefined,
        shipmentType: shipment.shipmentType,
        deliveryDate: shipment.fields?.deliveryDate,
        deadlineType: shipment.fields?.deadlineType ?? undefined,
        deadlineDate: shipment.fields?.deadlineDate,
        dimFactor: shipment.fields?.dimFactor ?? 0,
        type: shipment.fields?.type ?? undefined,
        fieldsUuid: shipment.fields?.uuid,
        shipperAddressUuid: shipment.shipperAddress?.uuid,
        documentUuids,
        numberOfFailures: undefined,
        clientReferenceNumber: undefined,
        totalChargesAmount: shipment.totalChargesAmount,
        hasSignedPOD: shipment.hasSignedPOD,
        overridePackageWeight: shipment.overridePackageWeight,
      }),
    );
    return shipment.uuid;
  },
);

type GetShipmentArgs = {
  shipmentUuid: string;
};
export const getShipment = createAsyncThunk<
  StandardShipmentValues | undefined,
  GetShipmentArgs,
  {
    state: RootState;
  }
>('standardOrders/getShipment', async (arg, thunkAPI) =>
  selectStandardShipmentValuesById(thunkAPI.getState(), arg.shipmentUuid),
);
