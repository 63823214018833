// eslint-disable-next-line no-restricted-imports
import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { isEmpty, isNil, sortBy } from 'lodash';
import { type ReactNode } from 'react';
import type React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useMe from '../../common/react-hooks/use-me';
import useTerminals from '../../common/react-hooks/use-terminals';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import { PermissionResource, Segment } from '../../generated/graphql';
import { SchedulingPage } from '../scheduling/components/scheduling-page';
import AccessorialsTable from './components/accessorials/accessorials-table';
import BillingTab from './components/billing-tab';
import BusinessDivisionsTable from './components/business-divisions/business-divisions-table';
import CustomFormsTable from './components/custom-driver-forms-table';
import DriversTable from './components/drivers/drivers-table';
import EquipmentTab from './components/equipment-tab';
import FuelProfilesTable from './components/fuel-profiles/fuel-profiles-tables';
import HoldReasons from './components/hold-reasons/hold-reasons';
import LineHaulLanesTable from './components/line-haul/line-haul-lanes-table';
import PackageSpecsTable from './components/package-specs/package-specs-table';
import RecoveryTerminalsTable from './components/recovery-terminals-table';
import RecurringOrderTemplateTable from './components/recurring-order-template-table';
import RecurringRunsTable from './components/recurring-runs-table';
import RouteNames from './components/route-names';
import ServicesTable from './components/service-levels';
import TariffChainsPage from './components/tariff-chains/tariff-chains-page';
import TariffsPage from './components/tariffs-page';
import TerminalsTable from './components/terminals/terminals-table';
import ThirdPartyBrokers from './components/third-party-brokers/third-party-brokers';
import UsersTable from './components/users/users-table';
import WarehouseEmployeesTable from './components/warehouse-employees/warehouse-employees-table';
import ZoneGroupsPage from './components/zones/zone-groups-page';
import { ServiceRegionsPage } from '../service-regions/components/service-regions-page';
import { ManagementTab } from './constants';

type TabPanelProps = {
  readonly children?: React.ReactNode;
  readonly selectedTab: ManagementTab;
  readonly tab: ManagementTab;
  readonly pad: boolean;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, selectedTab, tab, pad } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== tab}
      id={`vertical-tabpanel-${tab}`}
      aria-labelledby={`vertical-tab-${tab}`}
      style={{ height: '100%' }}
    >
      {selectedTab === tab && (
        <Box height="100%" overflow="scroll" sx={pad ? { p: 3 } : undefined}>
          {children}
        </Box>
      )}
    </div>
  );
};

const gridItemSx = {
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'white',
};

type TabContent = {
  label: string;
  component: ReactNode;
  tabVisibleCondition?: boolean | null;
  // Defaults to true.
  panelPadding?: boolean;
  tab: ManagementTab;
};

const parseTabQueryParam = (
  tab: string | null,
  tabComponents: TabContent[],
): ManagementTab => {
  if (isNil(tab)) {
    return ManagementTab.ACCESSORIALS;
  }
  const tabComponent = tabComponents.find(
    (component) => (component.tab as string) === tab,
  );

  return tabComponent?.tab ?? ManagementTab.ACCESSORIALS;
};

const ManagementView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { companyUuid, companyData, loading, companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();

  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const ffShowLinehaulConfigurationUi = companyConfiguration?.lineHaulEnabled;
  const ffPackageSpecs = useFeatureFlag(FeatureFlag.FF_PACKAGE_SPECS);
  const ffScheduling = useFeatureFlag(FeatureFlag.FF_SCHEDULING);
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );
  const ffZoneGroupsEnabled = useFeatureFlag(
    FeatureFlag.FF_ZONE_GROUPS_ENABLED,
  );
  const ffTariffChainsEnabled = useFeatureFlag(
    FeatureFlag.FF_TARIFF_CHAINS_ENABLED,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const isCartage = companyData?.segment === Segment.Cartage;

  const { canRead: canReadCompanyDrivers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyDrivers,
  );

  const { canRead: canReadWarehouseEmployees } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyWarehouseEmployees,
  );

  const { canRead: canReadEquipment } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyEquipment,
  );

  const { canRead: canReadAccessorials } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyAccessorials,
  );

  const { canRead: canReadTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );

  const { canRead: canReadBilling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBilling,
  );

  const { canRead: canReadServices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyServices,
  );

  const { canRead: canReadRouteNames } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRouteNames,
  );

  const { canRead: canReadUsers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyUsers,
  );

  const { canRead: canReadRecoveryTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecoveryTerminals,
  );

  const { canRead: canReadCustomForms } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyCustomForms,
  );

  const { canRead: canReadFuelProfiles } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyFuelProfiles,
  );

  const { canRead: canReadBusinessDivisions } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyBusinessDivisions,
  );

  const { canRead: canReadRecurringOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyRecurringOrders,
  );

  const { canRead: canReadCompanyScheduling } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyScheduling,
  );

  // The same PermissionResource is used for both terminals and service regions.
  const { canRead: canReadTerminals } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTerminals,
  );

  const { canRead: canReadLineHaul } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyLineHaul,
  );

  const { canRead: canReadThirdPartyBrokers } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyThirdPartyBrokers,
  );

  const { canRead: canReadHoldReasons } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyHoldReasons,
  );

  let TAB_COMPONENTS: TabContent[] = [
    {
      label: 'Accessorials',
      component: <AccessorialsTable />,
      tabVisibleCondition: canReadAccessorials,
      tab: ManagementTab.ACCESSORIALS,
    },
    {
      label: 'Billing',
      component: <BillingTab />,
      tabVisibleCondition: canReadBilling,
      tab: ManagementTab.BILLING,
    },
    {
      label: 'Services',
      component: <ServicesTable />,
      tabVisibleCondition: canReadServices,
      tab: ManagementTab.SERVICES,
    },
    {
      label: 'Route Names',
      component: <RouteNames />,
      tabVisibleCondition: canReadRouteNames,
      tab: ManagementTab.ROUTE_NAMES,
    },
    {
      label: 'Recovery Terminals',
      component: <RecoveryTerminalsTable />,
      tabVisibleCondition:
        isCartage && !ffRecoveryTransferAddressOnly && canReadRecoveryTerminals,
      tab: ManagementTab.RECOVERY_TERMINALS,
    },
    {
      label: 'Business Divisions',
      component: <BusinessDivisionsTable />,
      tabVisibleCondition: canReadBusinessDivisions,
      tab: ManagementTab.BUSINESS_DIVISIONS,
    },
    {
      label: 'Custom Forms',
      component: <CustomFormsTable />,
      tabVisibleCondition: canReadCustomForms,
      tab: ManagementTab.CUSTOM_FORMS,
    },
    {
      label: ffDemoLoadManagement ? 'Drivers & Agents' : 'Drivers',
      component: <DriversTable />,
      tabVisibleCondition: canReadCompanyDrivers,
      tab: ManagementTab.DRIVERS,
    },
    {
      label: 'Equipment & Vehicles',
      component: <EquipmentTab />,
      tabVisibleCondition: canReadEquipment,
      tab: ManagementTab.EQUIPMENT,
    },
    {
      label: 'Fuel Profiles',
      component: <FuelProfilesTable />,
      tabVisibleCondition: canReadFuelProfiles,
      tab: ManagementTab.FUEL_PROFILES,
    },
    {
      label: 'Hold Reasons',
      component: <HoldReasons />,
      tabVisibleCondition: canReadHoldReasons,
      tab: ManagementTab.HOLD_REASONS,
    },
    {
      label: 'Line haul',
      component: <LineHaulLanesTable />,
      tabVisibleCondition:
        ffShowLinehaulConfigurationUi === true && canReadLineHaul,
      tab: ManagementTab.LINE_HAUL,
    },
    {
      label: 'Recurring Orders',
      component: <RecurringOrderTemplateTable />,
      tabVisibleCondition: canReadRecurringOrders,
      tab: ManagementTab.RECURRING_ORDERS,
    },
    {
      label: 'Route Templates',
      component: <RecurringRunsTable />,
      tabVisibleCondition: canReadRecurringOrders,
      tab: ManagementTab.ROUTE_TEMPLATES,
    },
    {
      label: 'Service Regions',
      component: <ServiceRegionsPage />,
      tabVisibleCondition:
        ffLineHaulNetworks && terminalsEnabled && canReadTerminals,
      panelPadding: false,
      tab: ManagementTab.SERVICE_REGIONS,
    },
    {
      label: 'Tariffs',
      component: <TariffsPage />,
      tabVisibleCondition: canReadTariffs,
      tab: ManagementTab.TARIFFS,
    },
    {
      label: 'Tariff Chains',
      component: <TariffChainsPage />,
      tabVisibleCondition: ffTariffChainsEnabled && canReadTariffs,
      tab: ManagementTab.TARIFF_CHAINS,
    },
    {
      label: 'Terminals',
      component: <TerminalsTable />,
      tabVisibleCondition: terminalsEnabled && canReadTerminals,
      tab: ManagementTab.TERMINALS,
    },
    {
      label: 'Third Party / Brokers',
      component: <ThirdPartyBrokers />,
      tabVisibleCondition: canReadThirdPartyBrokers,
      tab: ManagementTab.THIRD_PARTY_BROKERS,
    },
    {
      label: 'Users',
      component: <UsersTable companyUuid={companyUuid} />,
      tabVisibleCondition: canReadUsers,
      tab: ManagementTab.USERS,
    },
    {
      label: 'Warehouse Employees',
      component: <WarehouseEmployeesTable />,
      tabVisibleCondition: canReadWarehouseEmployees,
      tab: ManagementTab.WAREHOUSE_EMPLOYEES,
    },
    {
      label: 'Package Types',
      component: <PackageSpecsTable />,
      tabVisibleCondition: ffPackageSpecs && canReadEquipment,
      tab: ManagementTab.PACKAGE_TYPES,
    },
    {
      label: 'Scheduling',
      component: <SchedulingPage />,
      tabVisibleCondition: ffScheduling && canReadCompanyScheduling,
      panelPadding: false,
      tab: ManagementTab.SCHEDULING,
    },
    {
      label: 'Zones',
      component: <ZoneGroupsPage />,
      tabVisibleCondition: ffZoneGroupsEnabled && canReadTariffs,
      tab: ManagementTab.ZONES,
    },
  ];

  TAB_COMPONENTS = sortBy(
    TAB_COMPONENTS.filter(
      (tabInfo) =>
        tabInfo.tabVisibleCondition === true ||
        isNil(tabInfo.tabVisibleCondition),
    ),
    'label',
  );

  TAB_COMPONENTS = TAB_COMPONENTS.filter(
    (component) => component.tabVisibleCondition !== false,
  );

  const [currentTab, setCurrentTab] = useState<ManagementTab>(
    parseTabQueryParam(searchParams.get('tab'), TAB_COMPONENTS),
  );

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newTab: ManagementTab,
  ) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', newTab);
      return newParams;
    });

    setCurrentTab(newTab);
  };

  // This nil check necessary. The optional chaining below throws an NPE in a
  // production build for some reason.

  if (isNil(companyData) || isNil(companyUuid) || isEmpty(companyUuid)) {
    return null;
  }

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container height="100%">
      <Grid item xs={2} sx={gridItemSx} borderRight={1} borderColor="divider">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={currentTab}
          scrollButtons="auto"
          sx={{ pt: 3 }}
          onChange={handleTabChange}
        >
          {TAB_COMPONENTS.map((tabComponent: TabContent) => (
            <Tab
              key={tabComponent.label}
              sx={{ alignItems: 'start' }}
              label={tabComponent.label}
              value={tabComponent.tab}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={gridItemSx}>
        {TAB_COMPONENTS.map((tabComponent) => (
          <TabPanel
            key={tabComponent.label}
            selectedTab={currentTab}
            tab={tabComponent.tab}
            pad={tabComponent.panelPadding ?? true}
          >
            {tabComponent.component}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default ManagementView;
