import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { groupBy, isNil } from 'lodash';
import { mapToZipFile } from 'shared/zip/map-to-zip';
import { type Option } from '../../common/filters/types';
import {
  type DriverSettlementBillForDownloadFragment,
  type MeQuery,
  type StopDriverMapSummaryFragment,
} from '../../generated/graphql';
import GeneratedDriverSettlement from './components/download/generated-driver-settlement-pdf-v2';
import { SettlementsDownloadType } from './types';
import { isImageMimeType } from 'shared/file';

export const groupStopDriverMapsByDriverUuid = (
  stopDriverMaps: StopDriverMapSummaryFragment[],
) => {
  return groupBy(stopDriverMaps, (stopDriverMap) => stopDriverMap.driver?.uuid);
};

export const getOldestAndNewestDates = (
  stopDriverMaps: StopDriverMapSummaryFragment[],
) => {
  let oldestDate: Date | null = null;
  let newestDate: Date | null = null;

  for (const entry of stopDriverMaps) {
    const completedAt = entry.stop?.completedAt;
    if (!isNil(completedAt)) {
      const date = new Date(completedAt);
      if (!oldestDate || date < oldestDate) {
        oldestDate = date;
      }
      if (!newestDate || date > newestDate) {
        newestDate = date;
      }
    }
  }

  return { oldestDate, newestDate };
};

export const getSettlementRateString = (
  flatRate: number | undefined | null,
  percentageRate: number | undefined | null,
) => {
  if (isNil(flatRate) && isNil(percentageRate)) {
    return '$0';
  }
  if (!isNil(percentageRate)) {
    return `${percentageRate}%`;
  }
  return `$${flatRate}`;
};

export const getImageBlobFromUrl = async (
  url: string | null | undefined,
): Promise<Blob | null> => {
  if (isNil(url)) {
    return null;
  }

  try {
    const response = await fetch(url);
    const fileType = response.headers.get('content-type');
    if (!response.ok || isNil(fileType) || !isImageMimeType(fileType)) {
      return null;
    }

    return await response.blob();
  } catch {
    return null;
  }
};

export const downloadDriverSettlementBills = async ({
  settlementBills,
  companyData,
  startDate,
  endDate,
  driverOption,
  downloadType,
  printFormat,
  displayStopTotal,
  displayOrderTotal,
  displayDriverPayout,
  showOtherDriversPaidForStop,
}: {
  settlementBills: DriverSettlementBillForDownloadFragment[];
  companyData: MeQuery | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  driverOption: Option | null | undefined;
  downloadType: SettlementsDownloadType;
  printFormat: string;
  displayStopTotal: boolean;
  displayOrderTotal: boolean;
  displayDriverPayout: boolean;
  showOtherDriversPaidForStop: boolean;
}) => {
  let dateString = 'All Time';
  if (!isNil(startDate) && !isNil(endDate)) {
    dateString = `${dayjs(startDate).format('MM/DD')} - ${dayjs(endDate).format(
      'MM/DD/YY',
    )}`;
  }

  const companyLogoBuffer = await getImageBlobFromUrl(
    companyData?.me?.company?.configuration?.invoicePdfLogoUrl,
  );

  if (downloadType !== SettlementsDownloadType.SEPARATE_FILES) {
    const blob = await pdf(
      <GeneratedDriverSettlement
        companyLogoBuffer={companyLogoBuffer}
        companyData={companyData}
        settlementBills={settlementBills}
        downloadType={downloadType}
        printFormat={printFormat}
        displayStopTotal={displayStopTotal}
        displayOrderTotal={displayOrderTotal}
        displayDriverPayout={displayDriverPayout}
        showOtherDriversPaidForStop={showOtherDriversPaidForStop}
      />,
    ).toBlob();
    saveAs(blob, `driver-settlement-${dateString}.pdf`);
    return;
  }

  const content = await mapToZipFile({
    data: settlementBills,
    mapper: async (settlementBill) => {
      const blob = await pdf(
        <GeneratedDriverSettlement
          companyLogoBuffer={companyLogoBuffer}
          companyData={companyData}
          settlementBills={[settlementBill]}
          downloadType={downloadType}
          printFormat={printFormat}
          displayStopTotal={displayStopTotal}
          displayOrderTotal={displayOrderTotal}
          displayDriverPayout={displayDriverPayout}
          showOtherDriversPaidForStop={showOtherDriversPaidForStop}
        />,
      ).toBlob();
      return {
        path: [`driver-settlement-${settlementBill.billName}.pdf`],
        blob,
      };
    },
  });

  saveAs(
    content,
    `driver-settlements-${dateString}-${driverOption?.label ?? ''}.zip`,
  );
};
