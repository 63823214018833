import { Alert, Snackbar } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import useOrderSnapshot from '../../../../../common/react-hooks/use-order-snapshot';
import {
  EdiAndApiOrdersForReviewDocument,
  useAcceptEdiOrApiOrderMutation,
  useDeleteOrderMutation,
  useMergeEdiOrderMutation,
  useRejectEdiOrderMutation,
  useUpdateStandardOrderMutation,
} from '../../../../../generated/graphql';
import { useAppDispatch } from '../../../../../redux/hooks';
import useContactIsOverCreditLimit from '../../../../contacts/hooks/use-contact-is-over-credit-limit';
import { type StandardOrderValues } from '../../../redux/standard/standard-orders-values-slice';
import {
  createStandardOrderUpdateInput,
  orderHasNilServiceDate,
  orderHasNilServiceLevel,
} from '../../../redux/standard/standard-orders-values-thunks';
import EdiOrdersHaveErrorsModal from './edi-orders-have-errors-modal';
import EdiSelectedOrdersDetails from './edi-selected-orders-details-form';
import RejectEdiOrderModal from './reject-edi-order-modal';

type ReviewEdiOrderDetailsProps = {
  readonly selectedOrderUuids: string[];
  readonly setSelectedOrderUuids: (uuids: string[]) => void;
  readonly selectedOrderUuidsWithPendingChanges: string[];
  readonly setSelectedOrderUuidsWithPendingChanges: (uuids: string[]) => void;
  readonly loadingOrders: boolean;
};

const ReviewEdiOrderDetails = ({
  selectedOrderUuids,
  setSelectedOrderUuids,
  selectedOrderUuidsWithPendingChanges,
  setSelectedOrderUuidsWithPendingChanges,
  loadingOrders,
}: ReviewEdiOrderDetailsProps) => {
  const [selectedOrderUuidsBeingEdited, setSelectedOrderUuidsBeingEdited] =
    useState<string[]>([]);

  useEffect(() => {
    setSelectedOrderUuidsBeingEdited(
      selectedOrderUuidsBeingEdited.filter((uuid) =>
        selectedOrderUuids.includes(uuid),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderUuids]);

  const [showNumApprovedEdiOrders, setShowNumApprovedEdiOrders] = useState<
    number | undefined
  >(undefined);
  const [showMergedOrderName, setShowMergedOrderName] = useState<
    string | undefined
  >(undefined);
  const [showRejectedOrderName, setShowRejectedOrderName] = useState<
    string | undefined
  >(undefined);
  const [rejectOrderModalUuid, setRejectOrderModalUuid] = useState<
    string | undefined
  >(undefined);
  const [ordersWithErrors, setOrdersWithErrors] = useState<
    Array<{ name: string; errors: string[] }>
  >([]);
  const [failedToApproveOrdersMessage, setFailedToApproveOrdersMessage] =
    useState<string | undefined>(undefined);

  // const [showUnsavedChangesWarningModal, setShowUnsavedChangesWarningModal] =
  //   useState(false);
  // const [showApproveChangesModal, setShowApproveChangesModal] = useState(false);
  const dispatch = useAppDispatch();
  const [updateStandardOrder] = useUpdateStandardOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [acceptEdiOrApiOrder] = useAcceptEdiOrApiOrderMutation();
  const [rejectEdiOrder] = useRejectEdiOrderMutation();
  const [mergeEdiOrder] = useMergeEdiOrderMutation();
  const { saveSnapshot } = useOrderSnapshot();
  const { isContactOverCreditLimitByOrderUuid } = useContactIsOverCreditLimit({
    contactUuid: undefined,
  });

  const handleRejectOrder = async (orderUuid: string) => {
    await rejectEdiOrder({
      variables: {
        rejectEdiOrderInput: {
          uuid: orderUuid,
        },
      },
      refetchQueries: [EdiAndApiOrdersForReviewDocument],
    });
    setSelectedOrderUuids(
      selectedOrderUuids.filter((uuid) => uuid !== orderUuid),
    );
  };

  /**
   * TODO: Do the real form validation rather than one-off checks on fields.
   * This was done this way due to time constraints and concern that the redux
   * form validation has diverged from the react-hook-form schema.
   */
  const handleSaveAllAndAccept = async () => {
    setOrdersWithErrors([]);
    const ordersWithErrorsMap = new Map<string, string[]>();
    const ordersOverCreditLimit = (
      await Promise.all(
        selectedOrderUuids.map(async (orderUuid) =>
          isContactOverCreditLimitByOrderUuid(orderUuid),
        ),
      )
    ).filter((order) => order.isOverLimit);
    const ordersWithNilServiceDate = (
      await Promise.all(
        selectedOrderUuids.map(async (orderUuid) =>
          dispatch(orderHasNilServiceDate({ orderUuid })).unwrap(),
        ),
      )
    ).filter((order) => order.hasNilServiceDate);
    const ordersWithNilServiceLevel = (
      await Promise.all(
        selectedOrderUuids.map(async (orderUuid) =>
          dispatch(orderHasNilServiceLevel({ orderUuid })).unwrap(),
        ),
      )
    ).filter((order) => order.hasNilServiceLevel);
    for (const order of ordersOverCreditLimit) {
      if (!isNil(order.shipperBillOfLadingNumber)) {
        if (ordersWithErrorsMap.has(order.shipperBillOfLadingNumber)) {
          ordersWithErrorsMap
            .get(order.shipperBillOfLadingNumber)
            ?.push('Customer over credit limit');
        } else {
          ordersWithErrorsMap.set(order.shipperBillOfLadingNumber, [
            'Customer over credit limit',
          ]);
        }
      }
    }
    for (const order of ordersWithNilServiceDate) {
      if (!isNil(order.shipperBillOfLadingNumber)) {
        if (ordersWithErrorsMap.has(order.shipperBillOfLadingNumber)) {
          ordersWithErrorsMap
            .get(order.shipperBillOfLadingNumber)
            ?.push('Service date is required on pickups and deliveries');
        } else {
          ordersWithErrorsMap.set(order.shipperBillOfLadingNumber, [
            'Service date is required on pickups and deliveries',
          ]);
        }
      }
    }
    for (const order of ordersWithNilServiceLevel) {
      if (!isNil(order.shipperBillOfLadingNumber)) {
        if (ordersWithErrorsMap.has(order.shipperBillOfLadingNumber)) {
          ordersWithErrorsMap
            .get(order.shipperBillOfLadingNumber)
            ?.push('Service level is required');
        } else {
          ordersWithErrorsMap.set(order.shipperBillOfLadingNumber, [
            'Service level is required',
          ]);
        }
      }
    }

    if (ordersWithErrorsMap.size > 0) {
      const newOrdersWithErrors = [...ordersWithErrorsMap.entries()].map(
        ([name, errors]) => ({ name, errors }),
      );
      setOrdersWithErrors(newOrdersWithErrors);
    }

    const updateInputsForOrdersWithUnsavedChanges = await Promise.all(
      selectedOrderUuidsWithPendingChanges.map(async (orderUuid) => {
        return dispatch(createStandardOrderUpdateInput({ orderUuid })).unwrap();
      }),
    );
    // update the fields and also convert draft status to false
    const updatedWithUnsavedChanges = filterNotNil(
      await Promise.all(
        updateInputsForOrdersWithUnsavedChanges.map(async (updateInput) => {
          if (
            ordersOverCreditLimit.find(
              (order) => order.uuid === updateInput.uuid,
            )?.isOverLimit === true
          ) {
            return null;
          }
          if (
            ordersWithNilServiceDate.find(
              (order) => order.uuid === updateInput.uuid,
            )?.hasNilServiceDate === true
          ) {
            return null;
          }
          if (
            ordersWithNilServiceLevel.find(
              (order) => order.uuid === updateInput.uuid,
            )?.hasNilServiceLevel === true
          ) {
            return null;
          }

          await updateStandardOrder({
            variables: {
              updateStandardOrderInput: {
                orderUpdateInput: {
                  ...updateInput,
                },
              },
            },
          });
          // This clears the isDraftOrder flag and (for EDI orders) sends a 990 accepted
          await acceptEdiOrApiOrder({
            variables: {
              acceptEdiOrApiOrderInput: {
                uuid: updateInput.uuid,
              },
            },
            refetchQueries: [EdiAndApiOrdersForReviewDocument],
          });
          return updateInput.uuid;
        }),
      ),
    );

    // save snapshots for these recently saved changes
    await Promise.all(
      selectedOrderUuidsWithPendingChanges.map(async (uuid) => {
        if (
          ordersOverCreditLimit.find((order) => order.uuid === uuid)
            ?.isOverLimit === true
        ) {
          return false;
        }
        if (
          ordersWithNilServiceDate.find((order) => order.uuid === uuid)
            ?.hasNilServiceDate === true
        ) {
          return false;
        }
        if (
          ordersWithNilServiceLevel.find((order) => order.uuid === uuid)
            ?.hasNilServiceLevel === true
        ) {
          return null;
        }

        return saveSnapshot(uuid);
      }),
    );

    // for orders that don't have changes just update the draft status
    const ordersWithoutPendingChanges = filterNotNil(
      await Promise.all(
        selectedOrderUuids
          .filter(
            (uuid) => !selectedOrderUuidsWithPendingChanges.includes(uuid),
          )
          .map(async (uuid) => {
            if (
              ordersOverCreditLimit.find((order) => order.uuid === uuid)
                ?.isOverLimit === true
            ) {
              return null;
            }
            if (
              ordersWithNilServiceDate.find((order) => order.uuid === uuid)
                ?.hasNilServiceDate === true
            ) {
              return null;
            }
            if (
              ordersWithNilServiceLevel.find((order) => order.uuid === uuid)
                ?.hasNilServiceLevel === true
            ) {
              return null;
            }

            // This clears the isDraftOrder flag and (for EDI orders) sends a 990 accepted
            await acceptEdiOrApiOrder({
              variables: {
                acceptEdiOrApiOrderInput: {
                  uuid,
                },
              },
              refetchQueries: [EdiAndApiOrdersForReviewDocument],
            });
            return uuid;
          }),
      ),
    );

    setSelectedOrderUuids(
      selectedOrderUuids.filter((uuid) => {
        return (
          !ordersWithoutPendingChanges.includes(uuid) &&
          !updatedWithUnsavedChanges.includes(uuid)
        );
      }),
    );
    setSelectedOrderUuidsWithPendingChanges(
      selectedOrderUuidsWithPendingChanges.filter((uuid) => {
        return !updatedWithUnsavedChanges.includes(uuid);
      }),
    );
    const totalApproved =
      ordersWithoutPendingChanges.length + updatedWithUnsavedChanges.length;
    if (totalApproved > 0) {
      setShowNumApprovedEdiOrders(totalApproved);
    }
  };

  const handleSaveSingleOrder = async (orderUuid: string) => {
    const messageArray: string[] = [];
    const orderOverCreditLimit =
      await isContactOverCreditLimitByOrderUuid(orderUuid);

    if (orderOverCreditLimit.isOverLimit) {
      messageArray.push(
        `Failed to approve orders with customers over credit limit: ${orderOverCreditLimit.shipperBillOfLadingNumber}`,
      );
    }

    const orderNilServiceDate = await dispatch(
      orderHasNilServiceDate({ orderUuid }),
    ).unwrap();

    if (orderNilServiceDate.hasNilServiceDate) {
      messageArray.push(
        `Service date is required for pickups and deliveries: ${orderNilServiceDate.shipperBillOfLadingNumber}`,
      );
    }

    const orderNilServiceLevel = await dispatch(
      orderHasNilServiceLevel({ orderUuid }),
    ).unwrap();

    if (orderNilServiceLevel.hasNilServiceLevel) {
      messageArray.push(
        `Service level is required: ${orderNilServiceLevel.shipperBillOfLadingNumber}`,
      );
    }

    if (messageArray.length > 0) {
      setFailedToApproveOrdersMessage(messageArray.join('\n'));
      return;
    }

    if (selectedOrderUuidsWithPendingChanges.includes(orderUuid)) {
      const updateInput = await dispatch(
        createStandardOrderUpdateInput({ orderUuid }),
      ).unwrap();

      await updateStandardOrder({
        variables: {
          updateStandardOrderInput: {
            orderUpdateInput: {
              ...updateInput,
            },
          },
        },
      });
      // This clears the isDraftOrder flag and (for EDI orders) sends a 990 accepted
      await acceptEdiOrApiOrder({
        variables: {
          acceptEdiOrApiOrderInput: {
            uuid: orderUuid,
          },
        },
        refetchQueries: [EdiAndApiOrdersForReviewDocument],
      });
      // save snapshot for these recently saved changes
      await saveSnapshot(orderUuid);
    } else {
      // This clears the isDraftOrder flag and (for EDI orders) sends a 990 accepted
      await acceptEdiOrApiOrder({
        variables: {
          acceptEdiOrApiOrderInput: {
            uuid: orderUuid,
          },
        },
        refetchQueries: [EdiAndApiOrdersForReviewDocument],
      });
    }

    setSelectedOrderUuids([]);
    setSelectedOrderUuidsWithPendingChanges([]);
    setShowNumApprovedEdiOrders(selectedOrderUuids.length ?? undefined);
  };

  const handleMergeOrder = async (orderToMerge: StandardOrderValues) => {
    const ordersToMergeWith =
      orderToMerge.ordersWithSameBillOfLadingNumber?.map(
        (order) => order.uuid,
      ) ?? [];

    await Promise.all(
      ordersToMergeWith.map(async (orderUuid) => {
        return mergeEdiOrder({
          variables: {
            mergeEdiOrderInput: {
              existingOrderUuid: orderUuid,
              incomingEdiOrderUuid: orderToMerge.uuid,
            },
          },
        });
      }),
    );

    // // save snapshots
    await Promise.all(
      (orderToMerge.ordersWithSameBillOfLadingNumber ?? []).map(
        async (order) => {
          return saveSnapshot(order.uuid);
        },
      ),
    );

    await deleteOrder({
      variables: {
        uuid: orderToMerge.uuid,
      },
      refetchQueries: [EdiAndApiOrdersForReviewDocument],
    });

    setSelectedOrderUuids(
      selectedOrderUuids.filter((uuid) => uuid !== orderToMerge.uuid),
    );
    setSelectedOrderUuidsWithPendingChanges(
      selectedOrderUuidsWithPendingChanges.filter(
        (uuid) => uuid !== orderToMerge.uuid,
      ),
    );
    setShowMergedOrderName(orderToMerge.name);
  };

  const updateOrderWithChanges = (orderUuid: string, hasChanges: boolean) => {
    if (
      hasChanges &&
      !selectedOrderUuidsWithPendingChanges.includes(orderUuid)
    ) {
      setSelectedOrderUuidsWithPendingChanges([
        ...selectedOrderUuidsWithPendingChanges,
        orderUuid,
      ]);
    }
    if (!hasChanges) {
      setSelectedOrderUuidsWithPendingChanges(
        selectedOrderUuidsWithPendingChanges.filter(
          (uuid) => uuid !== orderUuid,
        ),
      );
    }
  };

  const updateOrderBeingEdited = (orderUuid: string, isEditing: boolean) => {
    if (isEditing && !selectedOrderUuidsBeingEdited.includes(orderUuid)) {
      setSelectedOrderUuidsBeingEdited([
        ...selectedOrderUuidsBeingEdited,
        orderUuid,
      ]);
    }
    if (!isEditing) {
      setSelectedOrderUuidsBeingEdited(
        selectedOrderUuidsBeingEdited.filter((uuid) => uuid !== orderUuid),
      );
    }
  };

  return (
    <>
      <EdiSelectedOrdersDetails
        selectedOrderUuids={selectedOrderUuids}
        loadingOrders={loadingOrders}
        handleAcceptSingleOrder={handleSaveSingleOrder}
        handleAcceptOrders={handleSaveAllAndAccept}
        handleMergeOrder={handleMergeOrder}
        orderUuidsWithPendingChanges={selectedOrderUuidsWithPendingChanges}
        orderUuidsBeingEdited={selectedOrderUuidsBeingEdited}
        setOrderHasChanges={updateOrderWithChanges}
        updateOrderBeingEdited={updateOrderBeingEdited}
        openRejectOrderModalForUuid={(orderUuid: string) => {
          setRejectOrderModalUuid(orderUuid);
        }}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(showNumApprovedEdiOrders)}
        onClose={() => {
          setShowNumApprovedEdiOrders(undefined);
        }}
      >
        <Alert severity="success">
          {' '}
          Created {showNumApprovedEdiOrders} orders
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(showMergedOrderName)}
        onClose={() => {
          setShowMergedOrderName(undefined);
        }}
      >
        <Alert severity="success">
          {' '}
          Successfully merged {showMergedOrderName}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(showRejectedOrderName)}
        onClose={() => {
          setShowRejectedOrderName(undefined);
        }}
      >
        <Alert severity="success">
          {' '}
          Rejected order {showRejectedOrderName}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={8000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(failedToApproveOrdersMessage)}
        onClose={() => {
          setFailedToApproveOrdersMessage(undefined);
        }}
      >
        <Alert severity="error" sx={{ whiteSpace: 'pre-line' }}>
          {failedToApproveOrdersMessage}
        </Alert>
      </Snackbar>
      <RejectEdiOrderModal
        open={!isNil(rejectOrderModalUuid)}
        orderUuid={rejectOrderModalUuid}
        handleRejectOrder={handleRejectOrder}
        onClose={() => {
          setRejectOrderModalUuid(undefined);
        }}
      />
      <EdiOrdersHaveErrorsModal
        open={ordersWithErrors.length > 0}
        orders={ordersWithErrors}
        onClose={() => {
          setOrdersWithErrors([]);
        }}
      />
    </>
  );
};

export default ReviewEdiOrderDetails;
