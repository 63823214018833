import { Check } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { memo, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import useStyles from '../../../../../common/components/general-styles';
import { useVehicleTypesMinimalQuery } from '../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useTariffGroupStore from '../store/tariff-group-state-store';

const VehicleTypesField = () => {
  const [vehicleTypeUuids, setVehicleTypeUuids] = useTariffGroupStore(
    (state) => [state.vehicleTypeUuids, state.setVehicleTypeUuids],
    shallow,
  );

  const { data: vehicleTypesData, loading: vehicleTypesLoading } =
    useVehicleTypesMinimalQuery({
      fetchPolicy: 'cache-and-network',
    });

  const styles = useStyles();

  const getVehicleTypesToDisplay = (allPlaceholder?: string) => {
    const vehicleTypesToDisplay = (vehicleTypesData?.vehicleTypes ?? [])
      .filter((vehicleType) => vehicleTypeUuids.includes(vehicleType.uuid))
      .map((vehicleType) => vehicleType.name);
    if (vehicleTypesToDisplay.length >= 3) {
      return `${vehicleTypesToDisplay.length} vehicle types`;
    }
    return vehicleTypesToDisplay.length > 0
      ? vehicleTypesToDisplay.join(', ')
      : (allPlaceholder ?? '');
  };

  const vehicleTypeUuidToNameMap = useMemo(() => {
    return vehicleTypesData?.vehicleTypes?.reduce(
      (acc, vehicleType) => {
        acc[vehicleType.uuid] = vehicleType.name;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [vehicleTypesData]);

  const getVehicleTypeName = (uuid: string) => {
    return vehicleTypeUuidToNameMap?.[uuid] ?? '';
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel shrink id="select-vehicle-types-label">
        Vehicle Types
      </InputLabel>
      <Select
        fullWidth
        displayEmpty
        notched
        label="Vehicle Types"
        labelId="select-vehicle-types-label"
        size="small"
        renderValue={() => getVehicleTypesToDisplay('All')}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            selected={vehicleTypeUuids.length === 0}
            onClick={() => {
              setVehicleTypeUuids([]);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    vehicleTypeUuids.length === 0 ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            selected={vehicleTypeUuids.length > 0}
            disabled={vehicleTypesLoading}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    vehicleTypeUuids.length > 0 ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                multiple
                disableCloseOnSelect
                size="small"
                sx={{ backgroundColor: 'white', width: '250px' }}
                value={vehicleTypeUuids.map((uuid) => ({
                  label: getVehicleTypeName(uuid),
                  value: uuid,
                }))}
                options={
                  vehicleTypesData?.vehicleTypes?.map((vehicleType) => ({
                    label: vehicleType.name,
                    value: vehicleType.uuid,
                  })) ?? []
                }
                matchSortOptions={{ keys: ['label'] }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => <TextField {...params} size="small" />}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.label}
                  </li>
                )}
                onChange={(_, options) => {
                  setVehicleTypeUuids(options.map((option) => option.value));
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Select>
    </FormControl>
  );
};

export default memo(VehicleTypesField);
