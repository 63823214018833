import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { type FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import { type OrderFormValues } from '../../forms/types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../constants';
import { OrderFormCard } from '../order-form-card';
import { OrderFormCardTitle } from '../order-form-card-title';
import DriverSettlementForOrderChargesTable from './components/driver-settlement-for-order-charges-table';
import DriverSettlementTable from './components/driver-settlement-table';
import StopSettlementTable from './components/settlement-v2/stop-settlement-table';

type DriverSettlementProps = {
  readonly showTitle?: boolean;
};

const DriverSettlement: FunctionComponent<DriverSettlementProps> = ({
  showTitle = true,
}) => {
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const ffRobustSettlementEnabled = useFeatureFlag(
    FeatureFlag.FF_ROBUST_SETTLEMENT,
  );
  const { control } = useFormContext<OrderFormValues>();
  const orderChargesShipmentUuid = useWatch({
    control,
    name: 'orderChargesShipment.uuid',
  });

  return (
    <OrderFormCard>
      {showTitle && (
        <OrderFormCardTitle
          title={ffDemoLoadManagement ? 'Settlement' : 'Driver Settlement'}
        />
      )}
      {ffRobustSettlementEnabled ? (
        <TableContainer>
          <Table size="small">
            <TableBody>
              <StopSettlementTable idx={INBOUND_STOP_IDX} />
              <StopSettlementTable idx={OUTBOUND_STOP_IDX} />
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Eligible Percentage</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DriverSettlementTable idx={INBOUND_STOP_IDX} />
              <DriverSettlementTable idx={OUTBOUND_STOP_IDX} />
              {!isNil(orderChargesShipmentUuid) && (
                <DriverSettlementForOrderChargesTable />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </OrderFormCard>
  );
};

export default React.memo(DriverSettlement);
