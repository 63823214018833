import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import {
  isPartnerCarrierStop,
  StopMethod,
} from '../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import ServiceLevelDeterminesStopTypeTooltip from './service-level-determines-stop-type-tooltip';

type Props = {
  readonly stopMethod: StopMethod;
};

export const CustomerPortalOrderPartnerCarrierField = ({
  stopMethod,
}: Props) => {
  const { disabledIfNoAccess } = useOrderFormEditAccess();
  const { getStopType } = useCustomerPortalOrderFormContext();
  const stopType = getStopType(stopMethod);

  const partnerCarrierEnabled =
    !isNil(stopType) && isPartnerCarrierStop(stopType);

  const otherServiceLabel =
    stopMethod === StopMethod.Inbound
      ? 'Order freight starts at your warehouse'
      : 'Order freight will be picked up from your warehouse';

  return (
    <Stack>
      <ServiceLevelDeterminesStopTypeTooltip disabled={disabledIfNoAccess}>
        <FormControlLabel
          disabled
          control={<Checkbox disabled checked={partnerCarrierEnabled} />}
          label={otherServiceLabel}
        />
      </ServiceLevelDeterminesStopTypeTooltip>
    </Stack>
  );
};
