import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ShipmentType } from '../../../../../../../../generated/graphql';
import { type OrderFormFieldValues } from '../../../../forms/types';
import FreightChargeRow from '../freight-charge-row';

const StopFreightChargeRow = ({
  stopIdx,
  inBillingReview,
}: {
  readonly stopIdx: number;
  readonly inBillingReview: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const hideFromBilling = useWatch({
    control,
    name: `stops.${stopIdx}.hideFromBilling`,
  });

  return (
    <FreightChargeRow
      context={{
        shipmentType: ShipmentType.Regular,
        stopIdx,
        inSettlement: false,
      }}
      inBillingReview={inBillingReview}
      hideFromBilling={hideFromBilling}
      setHideFromBilling={(hideFromBillingNew) => {
        setValue(`stops.${stopIdx}.hideFromBilling`, hideFromBillingNew);
      }}
    />
  );
};

export default memo(StopFreightChargeRow);
