import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Select,
  type SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import ButtonLink from '../../common/components/buttons/button-link';
import { type Option } from '../../common/filters/types';
import useContacts from '../../common/react-hooks/use-contacts';
import useWarehouses from '../../common/react-hooks/use-warehouses';
import { useShallowItemsLazyQuery } from '../../generated/graphql';
import WarehouseSelector from '../storage-orders/components/common/warehouse-selector';
import ItemFormModal from './item-form-modal';

const ITEM_PAGE_SIZE = 15;

const ItemsTable = () => {
  const { contacts } = useContacts();
  const [contactUuid, setContactUuid] = useState<string>(
    contacts[0]?.uuid ?? '',
  );
  const handleChangeContact = (e: SelectChangeEvent) => {
    const val = e.target.value;
    if (val === contactUuid) return;
    setContactUuid(val);
  };
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState<boolean>(false);
  const [showItemFormModal, setShowItemFormModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse, selectedWarehouseRef] =
    useStateRef<Option | null>(null);

  const [getItems, { data: itemsData, loading: itemsDataLoading }] =
    useShallowItemsLazyQuery();
  const { warehouses } = useWarehouses();

  /**
   * Graphql pagination args
   * https://relay.dev/graphql/connections.htm
   */
  type FetchItemsParams = {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  };

  const fetchItems = async ({
    first,
    after,
    last,
    before,
  }: FetchItemsParams) => {
    await getItems({
      variables: {
        contactUuid,
        first,
        after,
        last,
        before,
        searchText,
        warehouseUuid: selectedWarehouseRef.current?.value,
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(contactUuid)) {
      fetchItems({ first: ITEM_PAGE_SIZE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactUuid]);

  const performSearchQuery = async () => {
    setSearching(true);
    await fetchItems({ first: ITEM_PAGE_SIZE });
    setSearching(false);
  };

  const paginationButtons = (
    <Box sx={{ mb: 2 }}>
      <Button
        disabled={
          Boolean(itemsDataLoading) ||
          (itemsData?.items.pageInfo.hasPreviousPage !== true && !canGoPrevious)
        }
        variant="contained"
        sx={{ mr: 2 }}
        startIcon={<ArrowLeft />}
        onClick={async () => {
          await fetchItems({
            last: ITEM_PAGE_SIZE,
            before: itemsData?.items.pageInfo.startCursor,
          });
          setCanGoPrevious(false);
          setCanGoNext(true);
        }}
      >
        Previous page
      </Button>
      <Button
        disabled={
          Boolean(itemsDataLoading) ||
          (itemsData?.items.pageInfo.hasNextPage !== true && !canGoNext)
        }
        variant="contained"
        endIcon={<ArrowRight />}
        onClick={async () => {
          await fetchItems({
            first: ITEM_PAGE_SIZE,
            after: itemsData?.items.pageInfo.endCursor,
          });
          setCanGoPrevious(true);
          setCanGoNext(false);
        }}
      >
        Next page
      </Button>
    </Box>
  );

  return (
    <>
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={6}>
          <FormControl>
            <Typography>Contact</Typography>
            <Select
              native
              size="small"
              disabled={isEmpty(contacts)}
              value={contactUuid}
              onChange={handleChangeContact}
            >
              {contacts.map((contact) => {
                return (
                  <option key={contact.uuid} value={contact.uuid}>
                    {contact.displayName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={8}
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <WarehouseSelector
                whiteBackground
                value={selectedWarehouse}
                cacheId="items-table"
                onChange={(wh) => {
                  setSelectedWarehouse(wh);
                  performSearchQuery();
                }}
              />
              <TextField
                size="small"
                label="Search items"
                InputProps={{ style: { backgroundColor: 'white' } }}
                value={searchText}
                sx={{ width: '400px' }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    performSearchQuery();
                  }
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <Button
                variant="contained"
                disabled={searching}
                onClick={async () => {
                  performSearchQuery();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  setShowItemFormModal(true);
                }}
              >
                Create item
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="items-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU Name</TableCell>
                      <TableCell>Description</TableCell>
                      {(warehouses?.length ?? 0) > 1 && (
                        <TableCell>Warehouse</TableCell>
                      )}
                      <TableCell>Primary UoM</TableCell>
                      <TableCell>Secondary UoM</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itemsData?.items.edges.map(({ node: item }) => {
                      return (
                        <TableRow key={item.uuid}>
                          <TableCell>{item.sku}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          {(warehouses?.length ?? 0) > 1 && (
                            <TableCell>
                              {item?.warehouse?.name ?? '-'}
                            </TableCell>
                          )}
                          <TableCell>
                            {sentenceCase(item.primaryUOM.unitOfMeasure)}
                          </TableCell>
                          <TableCell>
                            {sentenceCase(
                              item?.secondaryUOM?.unitOfMeasure ?? '-',
                            )}
                          </TableCell>
                          <TableCell>
                            <ButtonLink
                              href={`/warehouse/settings/items/${item.uuid}`}
                              variant="outlined"
                              size="small"
                            >
                              Edit
                            </ButtonLink>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {paginationButtons}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ItemFormModal
        contactUuid={contactUuid}
        open={showItemFormModal}
        warehouseOverride={
          warehouses?.length === 1 && warehouses[0] != null
            ? { label: warehouses[0].name, value: warehouses[0].uuid }
            : undefined
        }
        onClose={() => {
          setShowItemFormModal(false);
        }}
      />
    </>
  );
};

export default ItemsTable;
