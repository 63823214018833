import { z } from 'zod';

export const addressSchema = z.object({
  uuid: z.string().uuid(),
  city: z.string().min(1, 'Missing city'),
  country: z.string().optional(),
  line1: z.string().min(1, 'Missing address line 1'),
  line2: z.string().nullish(),
  name: z.string().min(1, 'Missing name'),
  state: z.string().min(1, 'Missing state'),
  zip: z.string().min(1, 'Missing zip'),
  longitude: z.number().nullish(),
  latitude: z.number().nullish(),
  preventCoordRecompute: z.boolean().nullish(),
  specialInstructions: z.string().nullish(),
  internalNotes: z.string().nullish(),
  iataCode: z.string().nullish(),
});

/**
 *
 * @see {addressSchema} with all fields optional except for uuid
 *
 * Note that not all fields are nullable here. This is done to remain compliant
 * with the AddressFormField type, which doesn't allow null for most values
 *
 * We use this for fields like shipperAddress and consigneeAddress
 * which aren't visible to the user and may be null/undefined or have
 * missing/empty values from EDI or doc scanning
 *
 * Eventually we should remove these values from the form entirely, but in the
 * meantime we want to validate them as loosely as possible.
 */
export const addressSchemaWithAllOptionalFields = z.object({
  uuid: z.string().uuid(),
  // Remove min length validations from city, line1, name, state, zip
  city: z.string().optional(),
  country: z.string().optional(),
  line1: z.string().optional(),
  line2: z.string().nullish(),
  name: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  longitude: z.number().nullish(),
  latitude: z.number().nullish(),
  preventCoordRecompute: z.boolean().nullish(),
  specialInstructions: z.string().nullish(),
  internalNotes: z.string().nullish(),
  iataCode: z.string().nullish(),
});
