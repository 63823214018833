import { type QueryHookOptions } from '@apollo/client';
import {
  type MeAsThirdPartyUserQuery,
  type MeAsThirdPartyUserQueryVariables,
  useMeAsThirdPartyUserQuery,
} from '../../generated/graphql';

const useMeAsThirdPartyUser = (
  baseOptions?: QueryHookOptions<
    MeAsThirdPartyUserQuery,
    MeAsThirdPartyUserQueryVariables
  >,
) => {
  const { data: meData, loading: meDataLoading } = useMeAsThirdPartyUserQuery({
    fetchPolicy: 'cache-first',
    ...baseOptions,
  });

  const thirdPartyUser = meData?.meAsThirdPartyUser;
  const uuid = thirdPartyUser?.uuid;
  const email = thirdPartyUser?.email;

  return {
    loading: meDataLoading,
    thirdPartyUser,
    uuid,
    email,
  };
};

export default useMeAsThirdPartyUser;
