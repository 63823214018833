import { Box, Dialog, TableContainer } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import {
  Table,
  TableBody,
  TableCell,
  TableButtonCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TableButton,
  TableHeadButtonCell,
} from '../../../../common/components/table/styled-table-components';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  type TariffZoneGroupFragment,
  TariffZoneGroupsDocument,
  useArchiveTariffZoneGroupMutation,
  useRestoreTariffZoneGroupMutation,
  useTariffZoneGroupsQuery,
} from '../../../../generated/graphql';
import CreateOrEdit from '../../enums/create-or-edit';
import { ActiveArchivedButtonGroup } from '../common/active-or-archived-button-group';
import { ActiveOrArchivedTab } from '../common/enums';
import CreateOrEditZoneGroupModal from './create-zone-group-modal';
import ZonesTable from './zones-table';

const ZoneGroupsPage = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyTariffs } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyTariffs,
  );

  const [activeOrArchivedTab, setActiveOrArchivedTab] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);
  const [showCreateOrEditZoneGroupModal, setShowCreateOrEditZoneGroupModal] =
    useState<boolean>(false);
  const [showZonesModal, setShowZonesModal] = useState<boolean>(false);

  const [currentZoneGroup, setCurrentZoneGroup] = useState<
    TariffZoneGroupFragment | undefined
  >(undefined);

  const { data: zoneGroups, loading: zoneGroupsLoading } =
    useTariffZoneGroupsQuery({
      fetchPolicy: 'network-only',
      variables: {
        findTariffZoneGroupsInput: {
          isArchived: activeOrArchivedTab === ActiveOrArchivedTab.ARCHIVED,
        },
      },
    });
  const [archiveZoneGroup, { loading: archiveZoneGroupLoading }] =
    useArchiveTariffZoneGroupMutation({
      refetchQueries: [TariffZoneGroupsDocument],
    });
  const [restoreZoneGroup, { loading: restoreZoneGroupLoading }] =
    useRestoreTariffZoneGroupMutation({
      refetchQueries: [TariffZoneGroupsDocument],
    });

  const handleCreateZoneGroup = () => {
    setCurrentZoneGroup(undefined);
    setShowCreateOrEditZoneGroupModal(true);
  };

  const handleEditZoneGroup = (zoneGroup: TariffZoneGroupFragment) => {
    setCurrentZoneGroup(zoneGroup);
    setShowCreateOrEditZoneGroupModal(true);
  };

  return (
    <Box>
      <ActiveArchivedButtonGroup
        activeTab={activeOrArchivedTab}
        setTab={setActiveOrArchivedTab}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Description</TableHeadCell>
              <TableHeadButtonCell sx={{ textAlign: 'right' }}>
                <TableButton onClick={handleCreateZoneGroup}>
                  Create Zone Group
                </TableButton>
              </TableHeadButtonCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zoneGroupsLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <CenteredCircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              zoneGroups?.tariffZoneGroups.map((zoneGroup) => (
                <TableRow key={zoneGroup.id}>
                  <TableCell>{zoneGroup.name}</TableCell>
                  <TableCell>{zoneGroup.description}</TableCell>
                  <TableButtonCell sx={{ textAlign: 'right' }}>
                    {activeOrArchivedTab === ActiveOrArchivedTab.ACTIVE ? (
                      <>
                        <TableButton
                          disabled={!canWriteCompanyTariffs}
                          onClick={() => {
                            handleEditZoneGroup(zoneGroup);
                          }}
                        >
                          Edit
                        </TableButton>
                        <TableButton
                          disabled={!canWriteCompanyTariffs}
                          onClick={() => {
                            setCurrentZoneGroup(zoneGroup);
                            setShowZonesModal(true);
                          }}
                        >
                          Zones
                        </TableButton>
                        <TableButton
                          color="error"
                          disabled={
                            !canWriteCompanyTariffs || archiveZoneGroupLoading
                          }
                          onClick={() => {
                            archiveZoneGroup({
                              variables: { id: zoneGroup.id },
                            });
                          }}
                        >
                          Archive
                        </TableButton>
                      </>
                    ) : (
                      <TableButton
                        color="success"
                        disabled={
                          !canWriteCompanyTariffs || restoreZoneGroupLoading
                        }
                        onClick={() => {
                          restoreZoneGroup({
                            variables: { id: zoneGroup.id },
                          });
                        }}
                      >
                        Restore
                      </TableButton>
                    )}
                  </TableButtonCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateOrEditZoneGroupModal
        key={currentZoneGroup?.id ?? 'CREATE_MODE'}
        open={showCreateOrEditZoneGroupModal}
        createOrEdit={
          isNil(currentZoneGroup) ? CreateOrEdit.Create : CreateOrEdit.Edit
        }
        tariffZoneGroup={currentZoneGroup}
        onClose={() => {
          setShowCreateOrEditZoneGroupModal(false);
        }}
      />
      {currentZoneGroup && showZonesModal && (
        <Dialog
          open={showZonesModal}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: '2000px',
              height: 'calc(100% - 64px)',
              padding: '16px',
            },
          }}
          onClose={() => {
            setShowZonesModal(false);
          }}
        >
          <ZonesTable tariffZoneGroupId={currentZoneGroup.id} />
        </Dialog>
      )}
    </Box>
  );
};

export default ZoneGroupsPage;
