import { QrCode } from '@mui/icons-material';
import {
  Chip,
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { groupBy, isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { useStandardOrderQuery } from '../../../../../../generated/graphql';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useMe from '../../../../../../common/react-hooks/use-me';

const StyledChip = styled(Chip)`
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.concreteGrey['70']};
  background-color: ${({ theme }) => theme.palette.concreteGrey['20']};
`;

type StorageLocationsProps = {
  readonly orderUuid: string;
};
const StorageLocations = ({ orderUuid }: StorageLocationsProps) => {
  const theme = useTheme();
  const { companyConfiguration } = useMe();
  const ffStorageLocationsOnOrderForm = useFeatureFlag(
    FeatureFlag.FF_STORAGE_LOCATIONS_ON_ORDER_FORM,
  );
  const { data: order } = useStandardOrderQuery({
    fetchPolicy: 'cache-first',
    variables: { uuid: orderUuid },
    skip: !ffStorageLocationsOnOrderForm,
  });
  const piecesGroupedByWarehouseLocation = useMemo(() => {
    if (isNil(order)) {
      return [];
    }
    const grouped = groupBy(
      order.standardOrder.pieces,
      (piece) => piece.warehouseLocation?.name,
    );
    return Object.entries(grouped).map(([warehouseLocationName, pieces]) => ({
      warehouseLocationName,
      pieces,
    }));
  }, [order]);

  if (
    !ffStorageLocationsOnOrderForm ||
    companyConfiguration?.wmsEnabled !== true ||
    isEmpty(piecesGroupedByWarehouseLocation)
  ) {
    return null;
  }

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell colSpan={7} sx={{ py: 1 }}>
          Storage locations
        </TableCell>
      </TableRow>
      {piecesGroupedByWarehouseLocation.map(
        ({ pieces, warehouseLocationName }) => (
          <TableRow key={warehouseLocationName}>
            <TableCell colSpan={2}>
              <Typography
                variant="subtitle2"
                color={theme.palette.concreteGrey['70']}
              >
                {warehouseLocationName}
              </Typography>
            </TableCell>
            <TableCell colSpan={5}>
              <Stack direction="row" gap={2}>
                {pieces.map((piece) => (
                  <StyledChip
                    key={piece.uuid}
                    size="small"
                    variant="outlined"
                    icon={<QrCode fontSize="small" />}
                    label={piece.referenceNumber}
                  />
                ))}
              </Stack>
            </TableCell>
          </TableRow>
        ),
      )}
    </>
  );
};

export default StorageLocations;
