export enum OrganizationPageTabs {
  GENERAL = 'GENERAL',
  PEOPLE = 'PEOPLE',
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  BILLING = 'BILLING',
  NOTIFICATIONS = 'NOTIFICATIONS',
  THIRD_PARTY_USERS = 'THIRD_PARTY_USERS',
}

export enum OrganizationPageMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

type OrganizationPageCreateMode = {
  mode: OrganizationPageMode.CREATE;
  uuid: null;
};
type OrganizationPageEditMode = {
  mode: OrganizationPageMode.EDIT;
  uuid: string;
};
type OrganizationPageViewMode = {
  mode: OrganizationPageMode.VIEW;
  uuid: string;
};

export type OrganizationPageProps =
  | OrganizationPageCreateMode
  | OrganizationPageEditMode
  | OrganizationPageViewMode;
