import {
  Checkbox,
  TableCell,
  TableRow,
  type SxProps,
  type Theme,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { isProduction } from '../../../../../environment-variables';
import { type OrderFragmentForEdiReviewFragment } from '../../../../../generated/graphql';
import theme from '../../../../../theme';

type ReviewEdiOrdersTableRowProps = {
  readonly ediOrder: OrderFragmentForEdiReviewFragment;
  readonly rowIsSelected: boolean;
  readonly setRowAsSelected: () => void;
  readonly unselectRow: () => void;
  readonly hasPendingChanges: boolean;
};

const styles = {
  truncatedTableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  paddedTableCell: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  row: {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.grey[300],
      cursor: 'pointer',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

const ReviewEdiOrdersTableRow = ({
  ediOrder,
  rowIsSelected,
  setRowAsSelected,
  unselectRow,
  hasPendingChanges,
}: ReviewEdiOrdersTableRowProps) => {
  const toggleSelected = () => {
    if (rowIsSelected && !hasPendingChanges) {
      unselectRow();
    } else {
      setRowAsSelected();
    }
  };

  // QA would like to keep source column for testing purposes
  const shouldShowSource = !isProduction();

  return (
    <TableRow
      sx={[
        styles.row,
        rowIsSelected && { backgroundColor: theme.palette.grey[300] },
      ]}
      onClick={toggleSelected}
    >
      <TableCell sx={styles.paddedTableCell}>
        <Checkbox checked={rowIsSelected} onChange={toggleSelected} />
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>{`${ediOrder.name}`}</TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {ediOrder.standardOrderFields.shipperBillOfLadingNumber}
      </TableCell>
      {shouldShowSource && (
        <TableCell sx={styles.truncatedTableCell}>{ediOrder.source}</TableCell>
      )}
      <TableCell sx={styles.truncatedTableCell}>
        {ediOrder.contact.displayName}
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {dayjs(ediOrder.createdAt).format('MM/DD/YY hh:mm a')}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ReviewEdiOrdersTableRow);
