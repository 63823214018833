/**
 * View details of an individual contact
 * @constructor
 */
import { Box, CircularProgress, Tab, Tabs } from '@mui/material';
import React, { type SyntheticEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { PaddingTabPanel } from '../../../common/components/tab-panel';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../generated/graphql';
import { useAppSelector } from '../../../redux/hooks';
import ContactsPageTabs from '../enums/contacts-page-tabs';
import { selectContactValuesById } from '../redux/contact-values-slice';
import AddressBookPanel from './address-book-panel';
import BillingPanel from './billing-panel';
import ContactPageMode from './contact-page-mode';
import DetailsPanel from './details-panel';
import NotificationsPanel from './notifications/notifications-panel';
import PeoplePanel from './people-panel';
import StationsPanel from './stations-panel';
import ThirdPartyUsersPanel from './third-party-users-panel';
import WarehousePanel from './warehouse-panel';

const parseTabQueryParam = (tab: string | null): ContactsPageTabs => {
  if (Object.values(ContactsPageTabs).includes(tab as ContactsPageTabs)) {
    return tab as ContactsPageTabs;
  }
  return ContactsPageTabs.DETAILS;
};

type ContactPageProps = {
  readonly initialMode: ContactPageMode;
  readonly uuid: string;
};

const ContactPage = ({ initialMode, uuid }: ContactPageProps) => {
  const [searchParams] = useSearchParams();
  const { companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();

  const { canRead: canReadWarehouses } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Warehouses,
  );
  const [currentTab, setCurrentTab] = useState<ContactsPageTabs>(
    parseTabQueryParam(searchParams.get('tab')),
  );
  const [currentMode, setCurrentMode] = useState<ContactPageMode>(initialMode);
  const contactValues = useAppSelector((state) =>
    selectContactValuesById(state, uuid),
  );
  const ffStationTab = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const ffReplaceCustomersTabWithDirectoryTabInNav = useFeatureFlag(
    FeatureFlag.FF_REPLACE_CUSTOMERS_TAB_WITH_DIRECTORY_TAB_IN_NAV,
  );

  if (contactValues === undefined) {
    return <CircularProgress />;
  }

  const handleChangeTab = (
    event: SyntheticEvent,
    newValue: ContactsPageTabs,
  ) => {
    setCurrentTab(newValue);
  };

  const handleChangeMode = (newMode: ContactPageMode) => {
    setCurrentMode(newMode);
  };

  const detailsPanel = (
    <DetailsPanel
      contactUuid={uuid}
      mode={currentMode}
      handleChangeMode={handleChangeMode}
      ffReplaceCustomersTabWithDirectoryTabInNav={
        ffReplaceCustomersTabWithDirectoryTabInNav
      }
    />
  );
  const stationsPanel = (
    <StationsPanel
      contactUuid={uuid}
      mode={currentMode}
      handleChangeMode={handleChangeMode}
      onTabChange={setCurrentTab}
    />
  );
  const peoplePanel = (
    <PeoplePanel
      contactUuid={uuid}
      mode={currentMode}
      handleChangeMode={handleChangeMode}
    />
  );
  const addressBookPanel = (
    <AddressBookPanel
      contactUuid={uuid}
      mode={currentMode}
      handleChangeMode={handleChangeMode}
    />
  );
  const billingPanel = <BillingPanel contactUuid={uuid} />;
  const thirdPartyUsersPanel = <ThirdPartyUsersPanel contactUuid={uuid} />;
  const notificationsPanel = <NotificationsPanel contactUuid={uuid} />;
  const warehousePanel = <WarehousePanel contactUuid={uuid} />;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Tabs
        value={currentTab}
        aria-label="add-contact-tabs"
        onChange={handleChangeTab}
      >
        <Tab
          // Leads to weird states if navigating away in the middle of creating a new contact or address
          disabled={currentMode === ContactPageMode.CREATE}
          label="Details"
          value={ContactsPageTabs.DETAILS}
        />
        {ffStationTab && (
          <Tab
            // Only allow modifying contact stations after the initial contact is created
            disabled={currentMode === ContactPageMode.CREATE}
            label="Stations"
            value={ContactsPageTabs.STATIONS}
          />
        )}
        <Tab
          // Only allow modifying contact persons after the initial contact is created
          disabled={currentMode === ContactPageMode.CREATE}
          label="People"
          value={ContactsPageTabs.PEOPLE}
        />
        <Tab
          // Only allow modifying addresses after the initial contact is created
          disabled={currentMode === ContactPageMode.CREATE}
          label="Address Book"
          value={ContactsPageTabs.ADDRESS_BOOK}
        />
        <Tab
          // Only allow modifying tariffs after the initial contact is created
          disabled={currentMode === ContactPageMode.CREATE}
          label="Billing"
          value={ContactsPageTabs.BILLING}
        />
        <Tab
          disabled={currentMode === ContactPageMode.CREATE}
          label="Third Party Users"
          value={ContactsPageTabs.THIRD_PARTY}
        />
        <Tab
          disabled={currentMode === ContactPageMode.CREATE}
          label="Notifications"
          value={ContactsPageTabs.NOTIFICATIONS}
        />
        {companyConfiguration?.wmsEnabled === true &&
          companyConfiguration.wmsStorageOrdersEnabled &&
          canReadWarehouses && (
            <Tab
              disabled={currentMode === ContactPageMode.CREATE}
              label="Warehouse"
              value={ContactsPageTabs.WAREHOUSE}
            />
          )}
      </Tabs>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.STATIONS}
        selectedValue={currentTab}
        sx={{ width: '100%' }}
      >
        {stationsPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.DETAILS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {detailsPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.PEOPLE}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {peoplePanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.ADDRESS_BOOK}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {addressBookPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.BILLING}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {billingPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.THIRD_PARTY}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {thirdPartyUsersPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.NOTIFICATIONS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {notificationsPanel}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={ContactsPageTabs.WAREHOUSE}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {warehousePanel}
      </PaddingTabPanel>
    </Box>
  );
};

export default ContactPage;
