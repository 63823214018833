import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  type SxProps,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { type ShallowTerminalFragment } from '../../../../generated/graphql';

const styles = {
  container: {
    bgcolor: 'background.paper',
    // boxShadow: 24,
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '40px',
    p: 4,
  } as SxProps,
  terminalCard: {
    border: 'solid 1px black',
    padding: '50px',
    position: 'relative',
  },
  editorContainer: {
    width: '80%',
    justifyContent: 'space-between',
  },
};

const AddTerminalButton = ({
  idx,
  targetId,
  availableTerminals,
  addTerminalToIntermediateList,
  isCreateMode,
}: {
  readonly idx: number;
  readonly targetId: string;
  readonly availableTerminals: ShallowTerminalFragment[];
  readonly addTerminalToIntermediateList: (index: number, uuid: string) => void;
  readonly isCreateMode: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleOpenMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleMenuSelect = (uuid: string) => {
    addTerminalToIntermediateList(idx, uuid);
    handleCloseMenu();
  };

  return (
    <ArcherElement
      id={`button_${idx}`}
      relations={[
        {
          targetId,
          targetAnchor: 'left',
          sourceAnchor: 'right',
        },
      ]}
    >
      <Stack sx={{ justifyContent: 'center' }}>
        <Button
          disabled={availableTerminals.length === 0 || !isCreateMode}
          onClick={handleOpenMenu}
        >
          {' '}
          + Add terminal{' '}
        </Button>
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {availableTerminals.map((t) => (
            <MenuItem
              key={t.uuid}
              onClick={() => {
                handleMenuSelect(t.uuid);
              }}
            >
              {t.name}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
    </ArcherElement>
  );
};

const LineHaulEditorIntermediateSteps = ({
  allTerminals,
  intermediateTerminalUuids,
  setIntermediateTerminalUuids,
  availableTerminals,
  isCreateMode,
}: {
  readonly allTerminals: ShallowTerminalFragment[];
  readonly intermediateTerminalUuids: string[] | undefined;
  readonly setIntermediateTerminalUuids: Dispatch<
    SetStateAction<string[] | undefined>
  >;
  readonly availableTerminals: ShallowTerminalFragment[];
  readonly isCreateMode: boolean;
}) => {
  const addTerminalToIntermediateList = (idx: number, uuid: string) => {
    setIntermediateTerminalUuids([
      ...(intermediateTerminalUuids ?? []).slice(0, idx),
      uuid,
      ...(intermediateTerminalUuids ?? []).slice(idx),
    ]);
  };

  const removeTerminalFromIntermediateList = (idx: number) => {
    const updatedUuids = [...(intermediateTerminalUuids ?? [])];
    updatedUuids.splice(idx, 1);
    setIntermediateTerminalUuids(updatedUuids);
  };

  return (
    <>
      <AddTerminalButton
        targetId={
          !isNil(intermediateTerminalUuids) &&
          intermediateTerminalUuids.length > 0
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              intermediateTerminalUuids[0]!
            : 'destination'
        }
        idx={0}
        availableTerminals={availableTerminals}
        addTerminalToIntermediateList={(index: number, uuid: string) => {
          addTerminalToIntermediateList(index, uuid);
        }}
        isCreateMode={isCreateMode}
      />
      {!isNil(intermediateTerminalUuids) &&
        intermediateTerminalUuids.length > 0 &&
        intermediateTerminalUuids.map((intermediateUuid, idx) => {
          return (
            <>
              <ArcherElement
                id={intermediateUuid}
                relations={[
                  {
                    targetId: `button_${idx + 1}`,
                    targetAnchor: 'left',
                    sourceAnchor: 'right',
                  },
                ]}
              >
                <Stack sx={styles.terminalCard}>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                      p: 0,
                      zIndex: '10',
                    }}
                    disabled={!isCreateMode}
                    onClick={() => {
                      removeTerminalFromIntermediateList(idx);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  {allTerminals?.find((t) => t.uuid === intermediateUuid)?.code}
                </Stack>
              </ArcherElement>
              <AddTerminalButton
                targetId={
                  idx === intermediateTerminalUuids.length - 1
                    ? 'destination'
                    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      intermediateTerminalUuids[idx + 1]!
                }
                idx={idx + 1}
                availableTerminals={availableTerminals}
                addTerminalToIntermediateList={(
                  index: number,
                  uuid: string,
                ) => {
                  addTerminalToIntermediateList(index, uuid);
                }}
                isCreateMode={isCreateMode}
              />
            </>
          );
        })}
    </>
  );
};

const LineHaulEditor = ({
  originTerminalUuid,
  destinationTerminalUuid,
  terminals,
  intermediateTerminalUuids,
  setIntermediateTerminalUuids,
  isCreateMode,
}: {
  readonly originTerminalUuid: string | undefined;
  readonly destinationTerminalUuid: string | undefined;
  readonly terminals: ShallowTerminalFragment[];
  readonly intermediateTerminalUuids: string[] | undefined;
  readonly setIntermediateTerminalUuids: Dispatch<
    SetStateAction<string[] | undefined>
  >;
  readonly isCreateMode: boolean;
}) => {
  if (isNil(originTerminalUuid) || isNil(destinationTerminalUuid)) {
    return (
      <Stack width="100%" alignItems="center" p="100px">
        {' '}
        Select an origin and destination to continue{' '}
      </Stack>
    );
  }
  return (
    <Stack width="100%" mt="100px">
      <ArcherContainer strokeColor="black">
        <Stack justifyContent="space-between" direction="row">
          <ArcherElement
            id={originTerminalUuid}
            relations={[
              {
                targetId: 'button_0',
                targetAnchor: 'left',
                sourceAnchor: 'right',
              },
            ]}
          >
            <Stack sx={styles.terminalCard}>
              {terminals?.find((t) => t.uuid === originTerminalUuid)?.code}
            </Stack>
          </ArcherElement>

          <LineHaulEditorIntermediateSteps
            intermediateTerminalUuids={intermediateTerminalUuids}
            setIntermediateTerminalUuids={setIntermediateTerminalUuids}
            allTerminals={terminals}
            availableTerminals={terminals.filter(
              (t) =>
                !(intermediateTerminalUuids ?? []).includes(t.uuid) &&
                t.uuid !== originTerminalUuid &&
                t.uuid !== destinationTerminalUuid,
            )}
            isCreateMode={isCreateMode}
          />

          <ArcherElement id="destination">
            <Stack sx={styles.terminalCard}>
              {terminals?.find((t) => t.uuid === destinationTerminalUuid)?.code}
            </Stack>
          </ArcherElement>
        </Stack>
      </ArcherContainer>
    </Stack>
  );
};

export default LineHaulEditor;
