import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Stack, TextField, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import { isNilOrEmptyString } from '../../../../common/utils/utils';
import {
  type RouteFragment,
  RoutesDocument,
  useUpdateRouteMutation,
} from '../../../../generated/graphql';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import useRouteActions from '../../hooks/use-route-actions';

const RouteLoadTimeField = ({
  route,
  isStart,
  forceEdit = false,
}: {
  readonly route: RouteFragment;
  readonly isStart: boolean;
  readonly forceEdit?: boolean;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [time, setTime] = useState<string>();
  const { fetchRoute } = useFetchRoutes();
  const { calculateRouteEta } = useRouteActions();
  const [updateRoute] = useUpdateRouteMutation({
    refetchQueries: [RoutesDocument],
  });

  useEffect(() => {
    setShowInput(forceEdit);
  }, [forceEdit]);

  const loadTime = isStart
    ? route.startLoadTimeInMinutes
    : route.endLoadTimeInMinutes;

  useEffect(() => {
    setTime(loadTime?.toString());
  }, [loadTime]);

  return (
    <Stack
      direction="row"
      sx={{ maxWidth: 130 }}
      alignItems="center"
      spacing={showInput ? 0.5 : 0}
    >
      {isStart ? (
        <UploadOutlinedIcon
          style={{ fontSize: showInput ? undefined : 17 }}
          color="primary"
        />
      ) : (
        <DownloadOutlinedIcon
          style={{ fontSize: showInput ? undefined : 17 }}
          color="primary"
        />
      )}
      {!showInput || route.locked ? (
        <Typography variant="caption">
          {isNil(loadTime) ? '-' : `${loadTime} min`}
        </Typography>
      ) : (
        <TextField
          inputProps={{ style: { fontSize: '14px' } }}
          size="small"
          variant="standard"
          type="number"
          placeholder={isStart ? 'Load Time' : 'Unload Time'}
          value={time}
          sx={{ width: '100%' }}
          onChange={(e) => {
            setTime(e.target.value);
          }}
          onFocus={() => {
            sendDispatchUserLocationEvent({
              action: DispatchMultiplayerAction.EDITING,
              routeUuid: route.uuid,
            });
          }}
          onBlur={async () => {
            await updateRoute({
              variables: {
                updateRouteInput: {
                  routeUpdateInput: {
                    uuid: route.uuid,

                    startLoadTimeInMinutes: isStart
                      ? isNilOrEmptyString(time)
                        ? null
                        : Number.parseFloat(time)
                      : undefined,

                    endLoadTimeInMinutes: isStart
                      ? undefined
                      : isNilOrEmptyString(time)
                        ? null
                        : Number.parseFloat(time),
                  },
                },
              },
            });
            await calculateRouteEta(route.uuid);
            fetchRoute(route.uuid);
          }}
        />
      )}
    </Stack>
  );
};

export default memo(RouteLoadTimeField);
