export enum AppointmentTableField {
  ORDER_NUMBER = 'Order #',
  APPOINTMENT_CONTACT = 'Contact name',
  SERVICE_LEVEL = 'Service level',
  TEXT_STATUS = 'Text status',
  CALL_STATUS = 'Call status',
  CONSIGNEE = 'Consignee',
  APPOINTMENT = 'Appointment',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zip',
  APPOINTMENT_REQUIRED = 'Appt required',
  APPOINTMENT_MANUALLY_CONFIRMED = 'Manually confirmed',
  STOP_TYPE = 'Stop type',
  TEXT_RESPONSES = 'Text responses',
  DRIVER = 'Driver',
  ADDRESS = 'Address',
  PHONE = 'Phone',
  ADDRESS_TYPE = 'Address type',
  CUSTOMER = 'Customer',
}
