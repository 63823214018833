import HandymanIcon from '@mui/icons-material/Handyman';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {
  Avatar,
  AvatarGroup,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React, { useState, useEffect, memo, useMemo } from 'react';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import useDrivers from '../../../../common/react-hooks/use-drivers';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import { type DriverOption } from '../../../../common/types';
import { stringToColor } from '../../../../common/utils/colors';
import { getDriverName } from '../../../../common/utils/utils';
import {
  DriversWithRoutesDocument,
  NumberOfStopsDocument,
  type RouteFragment,
  RoutesDocument,
  useUpdateRouteDriversMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { getDriverIdentifier } from '../../utils';
import RouteHelpersModal from './modals/route-helpers-modal';

type RouteDriverFieldProps = {
  readonly route: RouteFragment;
  readonly isEditing?: boolean;
};
const RouteDriverField = ({
  route,
  isEditing = false,
}: RouteDriverFieldProps) => {
  const initialDriver = route.drivers[0];
  const selectedTerminalUuid = useGlobalStore(
    (state) => state.selectedTerminalUuid,
  );
  const { companyConfiguration } = useMe();
  const useDriverNumberForDispatch =
    companyConfiguration?.useDriverNumberForDispatch;
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { drivers } = useDrivers();
  const [selectedDriverOption, setSelectedDriverOption] = useState<
    DriverOption | undefined
  >(
    isNil(initialDriver)
      ? undefined
      : {
          value: initialDriver.uuid,
          label: getDriverIdentifier(
            initialDriver,
            useDriverNumberForDispatch,
            false,
          ),
          terminal:
            initialDriver.terminal?.name ??
            (terminalsEnabled ? 'All terminals' : undefined),
        },
  );
  const [searchText, setSearchText] = useState<string>('');
  const [showRouteHelpersModal, setShowRouteHelpersModal] =
    useState<boolean>(false);
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const [updateRouteDrivers] = useUpdateRouteDriversMutation({
    refetchQueries: [
      RoutesDocument,
      NumberOfStopsDocument,
      DriversWithRoutesDocument,
    ],
  });

  const updateDriver = async () => {
    const driverUuid = selectedDriverOption?.value;
    await updateRouteDrivers({
      variables: {
        updateRouteDriversInput: {
          uuid: route.uuid,
          driverUuids: isNil(driverUuid) ? [] : [driverUuid],
        },
      },
    });
  };

  useEffect(() => {
    if (!isEditing && initialDriver?.uuid !== selectedDriverOption?.value) {
      updateDriver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const driversAndHelperNames = route.drivers
    .map((driver) => getDriverIdentifier(driver, useDriverNumberForDispatch))
    .concat(
      route.helpers.map((helper) =>
        getDriverIdentifier(helper, useDriverNumberForDispatch),
      ),
    )
    .join(', ');

  const driverOptions = useMemo(() => {
    return (
      drivers
        ?.filter(
          (driver) =>
            !isEmpty(searchText) ||
            (isEmpty(searchText) &&
              driver.terminal?.uuid === selectedTerminalUuid) ||
            isNil(driver.terminal?.uuid),
        )
        ?.sort((a, b) =>
          getDriverIdentifier(a, useDriverNumberForDispatch)?.localeCompare(
            getDriverIdentifier(b, useDriverNumberForDispatch),
          ),
        )
        .map((driver) => ({
          value: driver.uuid,
          label: `${
            isEmpty(driver.driverReferenceNumber)
              ? ''
              : `${driver.driverReferenceNumber} - `
          }${getDriverName(driver)}`,
          terminal:
            driver.terminal?.name ??
            (terminalsEnabled ? 'All terminals' : undefined),
        })) ?? []
    );
  }, [
    drivers,
    searchText,
    selectedTerminalUuid,
    terminalsEnabled,
    useDriverNumberForDispatch,
  ]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {(!isEditing || route.locked) && isEmpty(route.drivers) && (
        <Typography variant="caption">No driver</Typography>
      )}
      {(!isEditing || route.locked) && (
        <Typography noWrap variant="caption">
          {driversAndHelperNames}
        </Typography>
      )}
      {isEditing && !route.locked && (
        <>
          <PeopleOutlineIcon sx={{ fontSize: 17 }} color="primary" />
          <AutocompleteFuzzy
            size="small"
            sx={{
              width: '150px',
            }}
            defaultValue={selectedDriverOption}
            value={selectedDriverOption}
            options={driverOptions}
            matchSortOptions={{ keys: ['label'] }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  <Stack>
                    <Typography sx={{ fontSize: '14px' }}>
                      {option.label}
                    </Typography>
                    {!isNil(option.terminal) && (
                      <Typography variant="caption" color="text.secondary">
                        {option.terminal}
                      </Typography>
                    )}
                  </Stack>
                </li>
              );
            }}
            onChange={(event, option) => {
              setSelectedDriverOption(option ?? undefined);
              event.stopPropagation();
            }}
            onFocus={() => {
              sendDispatchUserLocationEvent({
                action: DispatchMultiplayerAction.EDITING,
                routeUuid: route.uuid,
              });
            }}
          />
        </>
      )}
      {isEditing && (
        <AvatarGroup
          max={3}
          sx={{
            '& .MuiAvatar-root': { width: 18, height: 18, fontSize: 10 },
          }}
        >
          {route.helpers.map((helper) => (
            <Tooltip
              key={helper.uuid}
              title={`${helper.firstName ?? ''} ${helper.lastName ?? ''}`}
            >
              <Avatar
                sx={{
                  bgcolor: stringToColor(helper.uuid),
                  fontSize: '10px',
                  height: '18px',
                  width: '18px',
                }}
              >
                {useDriverNumberForDispatch === true
                  ? helper.driverReferenceNumber
                  : `${helper.firstName[0]}${helper.lastName[0]}`}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      )}

      {isEditing && !route.locked && (
        <Tooltip title="Edit Helpers">
          <IconButton
            size="small"
            onClick={() => {
              setShowRouteHelpersModal(true);
            }}
          >
            <HandymanIcon sx={{ width: '14px', height: '14px' }} />
          </IconButton>
        </Tooltip>
      )}
      <RouteHelpersModal
        open={showRouteHelpersModal}
        setOpen={setShowRouteHelpersModal}
        route={route}
      />
    </Stack>
  );
};

export default memo(RouteDriverField);
