import { isAHoliday } from '@18f/us-federal-holidays';
import { kilograms } from '@buge/ts-units/mass';
import { comparePlainTimes } from 'shared/plain-date-time';
import {
  cubicCentimeters,
  cubicFeet,
  cubicInches,
  pounds,
} from 'shared/units/rates';
import {
  SpecialDayOfWeek,
  type PackageSpecsQuery,
  type PlainTime,
  type ServiceAvailabilityDateEntity,
  type VehicleTypeCapacityQuery,
} from '../../../../../../generated/graphql';
import { isNil } from 'lodash';
import { type PackageValues } from '../../forms/types';

export const plainTimeIsWithin = ({
  checkTime,
  from,
  to,
}: {
  checkTime: PlainTime;
  from: PlainTime;
  to: PlainTime;
}) =>
  comparePlainTimes(checkTime, from) >= 0 &&
  comparePlainTimes(checkTime, to) <= 0;

export const dateAndTimeIsWithinAvailability = ({
  date,
  startTime,
  endTime,
  availabilities,
}: {
  date: Date;
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
  availabilities: Array<
    Pick<
      ServiceAvailabilityDateEntity,
      'dayCategory' | 'start' | 'end' | 'enabled'
    >
  >;
}): boolean => {
  let dayOfWeek: SpecialDayOfWeek;
  if (date.getDay() === 6) {
    dayOfWeek = SpecialDayOfWeek.Saturday;
  } else if (date.getDay() === 0) {
    dayOfWeek = SpecialDayOfWeek.Sunday;
  } else if (isAHoliday(date)) {
    dayOfWeek = SpecialDayOfWeek.Holiday;
  } else {
    dayOfWeek = SpecialDayOfWeek.Weekday;
  }

  const availability = availabilities.find(
    (a) => a.dayCategory === dayOfWeek && a.enabled,
  );
  if (isNil(availability)) {
    return false;
  }

  if (
    !isNil(startTime) &&
    !plainTimeIsWithin({
      checkTime: {
        // Unfortunately we currently interpret the appointment start and end times in the local time zone of the user viewing this order.
        hour: startTime.getHours(),
        minute: startTime.getMinutes(),
      },
      from: availability.start,
      to: availability.end,
    })
  ) {
    return false;
  }

  return (
    isNil(endTime) ||
    plainTimeIsWithin({
      checkTime: {
        hour: endTime.getHours(),
        minute: endTime.getMinutes(),
      },
      from: availability.start,
      to: availability.end,
    })
  );
};

export const vehicleTypeCapacityWarningMessage = ({
  vehicleTypeCapacity,
  packageSpecs,
  packages,
  useKilograms,
  useCentimeters,
}: {
  vehicleTypeCapacity: VehicleTypeCapacityQuery['vehicleType'];
  packageSpecs: PackageSpecsQuery['packageSpecs']['packageSpecs'];
  packages: PackageValues[];
  useKilograms: boolean;
  useCentimeters: boolean;
}): string | null => {
  const { maxWeight, maxVolume, vehicleTypePackageSpecs } = vehicleTypeCapacity;

  let totalWeight = pounds(0);
  let totalVolume = cubicInches(0);
  const packageSpecIdToTotalQuantity: Record<string, number> = {};
  for (const {
    packageSpecId,
    quantity,
    weight,
    length,
    width,
    height,
  } of packages) {
    if (!isNil(weight)) {
      totalWeight = totalWeight
        .plus(useKilograms ? kilograms(weight) : pounds(weight))
        .times(quantity);
    }
    if (!isNil(length) && !isNil(width) && !isNil(height)) {
      totalVolume = totalVolume.plus(
        (useCentimeters
          ? cubicCentimeters(length * width * height)
          : cubicInches(length * width * height)
        ).times(quantity),
      );
    }
    if (!isNil(packageSpecId)) {
      packageSpecIdToTotalQuantity[packageSpecId] =
        (packageSpecIdToTotalQuantity[packageSpecId] ?? 0) + quantity;
    }
  }

  const incompatiblePackageSpecMessages: string[] = [];

  if (!isNil(maxWeight) && totalWeight.value() > maxWeight.value()) {
    incompatiblePackageSpecMessages.push(
      `The selected vehicle type only supports up to ${maxWeight.in(pounds).toString()}.`,
    );
  }
  if (!isNil(maxVolume) && totalVolume.value() > maxVolume.value()) {
    incompatiblePackageSpecMessages.push(
      `The selected vehicle type only supports up to ${maxVolume.in(cubicFeet).toString()}.`,
    );
  }

  for (const [packageSpecId, quantity] of Object.entries(
    packageSpecIdToTotalQuantity,
  )) {
    const vehicleTypePackageSpec = vehicleTypePackageSpecs.find(
      ({ packageSpec }) => packageSpec.id === packageSpecId,
    );
    const packageSpecName =
      vehicleTypePackageSpec?.packageSpec?.name ??
      packageSpecs.find((ps) => ps.id === packageSpecId)?.name ??
      'Unknown package type';

    if (isNil(vehicleTypePackageSpec)) {
      incompatiblePackageSpecMessages.push(
        `The selected vehicle type does not support the ${packageSpecName} package type.`,
      );
    } else if (
      !isNil(vehicleTypePackageSpec.maxQuantity) &&
      quantity > vehicleTypePackageSpec.maxQuantity
    ) {
      incompatiblePackageSpecMessages.push(
        `The selected vehicle type only supports up to ${vehicleTypePackageSpec.maxQuantity} of the ${packageSpecName} package type.`,
      );
    }
  }

  if (incompatiblePackageSpecMessages.length === 0) {
    return null;
  }

  return incompatiblePackageSpecMessages.join(' ');
};
