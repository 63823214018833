import { useMemo } from 'react';
import { groupBy } from 'lodash';
import type { LineHaulManifest, NonEmptyArray } from '../utils';

// Returns manifest grouped by start and end terminal, groups sorted ascending by terminal codes and manifests
// sorted ascending by createdAt. Each tuple in the returned array has a terminal code pair in a format that can
// be displayed to users and a non-empty array of manifests for that terminal pair.
export const useGroupedManifests = (
  manifests: LineHaulManifest[],
): Array<
  [terminalPairKey: string, manifests: NonEmptyArray<LineHaulManifest>]
> =>
  useMemo((): Array<[string, NonEmptyArray<LineHaulManifest>]> => {
    const grouped = groupBy(
      manifests,
      (manifest) =>
        `${manifest.startTerminal.code} → ${manifest.endTerminal.code}`,
    );

    const sortedGrouped = Object.entries(grouped).toSorted(([a], [b]) =>
      a.localeCompare(b),
    ) as Array<[string, NonEmptyArray<LineHaulManifest>]>;

    for (const [, manifests] of sortedGrouped) {
      manifests.sort((a, b) =>
        (a.createdAt as string).localeCompare(b.createdAt as string),
      );
    }

    return sortedGrouped;
  }, [manifests]);
