import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { type Dispatch, type SetStateAction } from 'react';
import type React from 'react';
import { useEffect, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { validateEmail } from '../../../../common/utils/utils';
import {
  type AccountingReportTypeV2,
  type ReportFiltersInput,
  useSendAccountingReportsMutation,
} from '../../../../generated/graphql';
import PalletLink from '../../../../pallet-ui/links/link/pallet-link';
import useInvoicesStore from '../../../invoices/invoices-store';
import { EmailReportOption, getEmailReportOptionCopy } from '../../types/email';

export type EmailReportOptionsProps = {
  readonly reportType: AccountingReportTypeV2;
  readonly reportFilters: ReportFiltersInput;
  readonly contactUuid?: string | undefined;
  readonly onEmailPdf?: () => Promise<void>; // TODO: Replace old API to send report to specific emails with new one.
  readonly isEmailSending?: boolean; // State that is set purely for emailing reports to specific people. Does not apply to batch send
  readonly showContainerModal: Dispatch<SetStateAction<boolean>>;
  readonly showEmailReportSuccessMessage: Dispatch<SetStateAction<boolean>>;
  readonly showEmailReportErrorMessage: Dispatch<SetStateAction<boolean>>;
};

/**
 * A component that provides different ways to email reports that can be reused across different report types.
 * Note that this component still expects some handlers like onEmailPdf to be passed in. The intention is to keep the component
 * agnostic to the type of report being emailed by providing a general mutation to send reports to customers. Once the old report-specific
 * mutation has been replaced, the per-report handler props will be removed.
 * @param reportType
 * @param reportFilters
 * @param contactUuid
 * @param onEmailPdf
 * @param isEmailSending
 * @constructor
 */
const EmailReportOptions = ({
  reportType,
  reportFilters,
  contactUuid,
  onEmailPdf,
  isEmailSending,
  showContainerModal,
  showEmailReportSuccessMessage,
  showEmailReportErrorMessage,
}: EmailReportOptionsProps) => {
  const theme = useTheme();
  const confirm = useConfirm();

  const [setShowInvoiceSendMenu, setInvoiceMenuTabIndex] = useInvoicesStore(
    (state) => [state.setShowInvoiceSendMenu, state.setInvoiceMenuTabIndex],
  );
  const [selectedEmailReportOption, setSelectedEmailReportOption] =
    useState<EmailReportOption>(EmailReportOption.SEND_TO_ALL_CONTACTS);
  const [recipientEmails, setRecipientEmails] = useState<
    string | null | undefined
  >(undefined);
  const [recipientEmailError, setRecipientEmailError] = useState<string>('');

  const [sendAccountingReports, { loading: sendAccountingReportsLoading }] =
    useSendAccountingReportsMutation();

  useEffect(() => {
    if (isNil(recipientEmails)) {
      setRecipientEmailError('');
    } else {
      const recipientEmailsList = recipientEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email.length);
      if (recipientEmailsList.some((email) => !validateEmail(email))) {
        setRecipientEmailError('Invalid email');
      } else {
        setRecipientEmailError('');
      }
    }
  }, [recipientEmails]);

  const onBatchSend = async () => {
    try {
      const sendAccountingReportsOutput = await sendAccountingReports({
        variables: {
          sendAccountingReportsInput: {
            reportType,
            contactUuids: isNilOrEmptyString(contactUuid) ? [] : [contactUuid],
            reportFilters,
          },
        },
      });
      if (
        sendAccountingReportsOutput.data?.sendAccountingReports.__typename ===
        'SendAccountingReportsSuccessOutput'
      ) {
        showEmailReportSuccessMessage(true);
        showContainerModal(false);
        await confirm({
          title: '',
          description: (
            <Typography>
              Please wait while we generate and send your reports. You can check
              the progress and download reports{' '}
              <PalletLink
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setInvoiceMenuTabIndex(1);
                  setShowInvoiceSendMenu(true);
                }}
              >
                here
              </PalletLink>
            </Typography>
          ),
          hideCancelButton: true,
          confirmationText: `Done`,
        });
      } else {
        showEmailReportErrorMessage(true);
        showContainerModal(false);
      }
    } catch {
      showEmailReportErrorMessage(true);
      showContainerModal(false);
    }
  };

  const getEmailReportOptionComponent = (
    option: EmailReportOption,
  ): React.ReactNode => {
    switch (option) {
      case EmailReportOption.SEND_TO_ALL_CONTACTS: {
        return (
          <Stack gap={1}>
            <Button
              variant="contained"
              color="info"
              disabled={sendAccountingReportsLoading}
              sx={{ width: '35%' }}
              onClick={onBatchSend}
            >
              Send report
            </Button>
            <Typography
              variant="body2"
              color={theme.palette.unassignedColor.main}
            >
              {'Emails are sent to each Contact > General > Email'}
            </Typography>
          </Stack>
        );
      }
      case EmailReportOption.SEND_TO_SPECIFIC_EMAILS: {
        return (
          <Grid container sx={{ width: '100%' }} gap={1}>
            <Grid item xs={8}>
              <TextField
                required
                label="Recipients (comma-separated)"
                value={recipientEmails}
                size="small"
                error={!isEmpty(recipientEmailError)}
                helperText={recipientEmailError}
                sx={{ flex: 1, width: '100%' }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setRecipientEmails(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="info"
                sx={{ width: '100%' }}
                disabled={
                  isNil(recipientEmails) ||
                  !isEmpty(recipientEmailError) ||
                  isEmpty(recipientEmails.trim()) ||
                  isEmailSending === true
                }
                onClick={onEmailPdf}
              >
                Email PDF
              </Button>
            </Grid>
          </Grid>
        );
      }
      default: {
        return exhaustive(option);
      }
    }
  };

  return (
    <RadioGroup
      value={selectedEmailReportOption}
      sx={{ width: '100%' }}
      onChange={(e) => {
        setSelectedEmailReportOption(e.target.value as EmailReportOption);
      }}
    >
      <Stack direction="column">
        {Object.values(EmailReportOption).map((option) => {
          if (
            option === EmailReportOption.SEND_TO_SPECIFIC_EMAILS &&
            isNil(onEmailPdf)
          ) {
            return null;
          }

          const checked = selectedEmailReportOption === option;
          return (
            <Stack
              key={option}
              direction="column"
              gap={1}
              sx={{ width: '100%' }}
            >
              <FormControlLabel
                key={option}
                checked={checked}
                value={option}
                control={<Radio />}
                label={getEmailReportOptionCopy({ option, reportType })}
              />
              {checked && getEmailReportOptionComponent(option)}
            </Stack>
          );
        })}
      </Stack>
    </RadioGroup>
  );
};

export default EmailReportOptions;
