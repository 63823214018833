import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack } from '@mui/material';
import { type AgGridReact } from 'ag-grid-react';
import { debounce, isNil } from 'lodash';
import type React from 'react';
import { useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import SecondaryButton from '../../../../common/components/SecondaryButton';
import {
  type FilterViewPage,
  type OrderTableField,
} from '../../../../generated/graphql';
import { SortPills } from '../../sort-pills';
import { TABLE_CONFIG_PILL_HEIGHT } from '../../table-configuration-pills';
import { type OrderTableFilterModel } from '../types';
import FilterPills from './filters/filter-pills';

const PILLS_CONTAINER_CLASSNAME = 'pills-container';

type OrderTableFunctionsPillsProps = {
  readonly gridRef: React.RefObject<AgGridReact>;
  readonly pageType: FilterViewPage;
  readonly orderTableFields: OrderTableField[];
  readonly filterModelV2: OrderTableFilterModel;
  readonly rightContent: React.ReactNode;
  readonly setFilterModelV2: (filterModel: OrderTableFilterModel) => void;
};

const OrderTableFunctionsPills: React.FC<OrderTableFunctionsPillsProps> = ({
  gridRef,
  pageType,
  orderTableFields,
  filterModelV2,
  setFilterModelV2,
  rightContent,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  const computeisOverflowing = debounce(() => {
    const newIsOverflowing = (() => {
      if (!containerRef.current) {
        return false;
      }

      const pillButtons: NodeListOf<HTMLElement> =
        containerRef.current.querySelectorAll(
          `.${PILLS_CONTAINER_CLASSNAME} button`,
        );

      // Check if any button is on a different line than the first button
      const firstButtonTop = pillButtons[0]?.getBoundingClientRect().top;
      if (!isNil(firstButtonTop)) {
        for (let i = pillButtons.length - 1; i > 0; i -= 1) {
          const buttonTop = pillButtons[i]?.getBoundingClientRect().top;
          if (!isNil(buttonTop) && buttonTop > firstButtonTop) {
            return true;
          }
        }
      }

      // Fallback to checking right edges
      // If we "hide" the pills but they extend past the container, we are still overflowing,
      // even though the tops are all the same
      const containerRight = containerRef.current.getBoundingClientRect().right;
      for (let i = pillButtons.length - 1; i >= 0; i -= 1) {
        const buttonRight = pillButtons[i]?.getBoundingClientRect().right;
        if (!isNil(buttonRight) && buttonRight >= containerRight) {
          return true;
        }
      }

      return false;
    })();
    setIsOverflowing(newIsOverflowing);
  }, 500);

  useResizeDetector({
    onResize: computeisOverflowing,
    targetRef: containerRef,
  });

  return (
    <Stack
      direction="row"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      flexGrow={1}
      minWidth={0}
    >
      <Stack
        ref={containerRef}
        direction="row"
        gap={1}
        alignItems="center"
        flexGrow={1}
        minWidth={0}
      >
        <Stack
          className={PILLS_CONTAINER_CLASSNAME}
          direction="row"
          gap={1}
          sx={
            isHidden
              ? {
                  // Only show a single row of pills
                  height: TABLE_CONFIG_PILL_HEIGHT,
                  flexGrow: 1,
                  overflow: 'hidden',
                }
              : { flexWrap: 'wrap' }
          }
        >
          <SortPills gridRef={gridRef} orderTableFields={orderTableFields} />
          <FilterPills
            wrap={!isHidden}
            filterModelV2={filterModelV2}
            setFilterModelV2={setFilterModelV2}
            pageType={pageType}
          />
        </Stack>
        {isOverflowing && (
          <SecondaryButton
            sx={{
              ml: 'auto',
              mb: 'auto',
              boxShadow: 'none',
              gap: 1,
              px: 2,
              zIndex: 1,
              height: TABLE_CONFIG_PILL_HEIGHT,
            }}
            onClick={() => {
              setIsHidden((h) => !h);
            }}
          >
            {isHidden ? 'Show' : 'Hide'}
            <KeyboardArrowUpIcon
              sx={{
                fontSize: '1rem',
                transform: isHidden ? 'rotate(180deg)' : undefined,
                transition: 'transform 0.2s',
              }}
            />
          </SecondaryButton>
        )}
      </Stack>
      <Box ml="auto">{rightContent}</Box>
    </Stack>
  );
};

export { OrderTableFunctionsPills };
