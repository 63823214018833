import { ChevronLeft } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { transformAddressToFullAddressString } from 'shared/copy';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { MarkStopAsAttemptedDialog } from '../../../common/components/modals/mark-stop-as-attempted.dialog';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type CompletedStopNotOnRouteFragment,
  DocumentType,
  OrderWithInboundOutboundPaperworkDocument,
  OrderWithPaperworkDocument,
  PermissionResource,
  StopStatus,
  useDocumentsByShipmentQuery,
  useMarkStopAsFailedV2Mutation,
  useOrderWithInboundOutboundPaperworkQuery,
  useUpdatePaperworkCompletedForStopsMutation,
} from '../../../generated/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAddresses } from '../../addresses/redux/addresses-values-slice';
import Paperwork from '../../orders/components/paperwork';
import { transformAppointmentStatus } from '../../orders/components/utils';
import { updateOneStopValues } from '../../stops/redux/stop-values-slice';
import EndOfDayContext from '../end-of-day-context';
import { getAppointmentTimeStringEOD } from '../end-of-day-utils';
import StopRowContextMenu from './context-menu';

const StopStatusTagVariant: Record<
  StopStatus,
  'success' | 'error' | 'warning' | 'default' | 'info' | undefined
> = {
  [StopStatus.Completed]: 'success',
  [StopStatus.Failed]: 'warning',
  [StopStatus.Cancelled]: 'error',
  [StopStatus.NotArrived]: 'default',
  [StopStatus.Arrived]: 'info',
};

type RoutelessStopRowProps = {
  readonly stop: CompletedStopNotOnRouteFragment;
  readonly tableIsMinimized: boolean;
  readonly setTableIsMinimized: Dispatch<SetStateAction<boolean>>;
  readonly selectedStopUuid: string | undefined;
  readonly setSelectedStopUuid: Dispatch<SetStateAction<string | undefined>>;
  readonly order: number;
  readonly setShowMarkStopAsAttemptedSuccessMessage: Dispatch<
    SetStateAction<boolean>
  >;
  readonly setShowMarkStopAsAttemptedErrorMessage: Dispatch<
    SetStateAction<boolean>
  >;
  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
};

const RoutelessStopRow = ({
  stop,
  tableIsMinimized,
  setTableIsMinimized,
  selectedStopUuid,
  setSelectedStopUuid,
  order,
  setShowMarkStopAsAttemptedSuccessMessage,
  setShowMarkStopAsAttemptedErrorMessage,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: RoutelessStopRowProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteEndOfDay } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EndOfDay,
  );

  const { setShouldLoadRoutes, completedStopsNotOnRoutesSelected } =
    useContext(EndOfDayContext);
  const [markStopAsFailed] = useMarkStopAsFailedV2Mutation();
  const [showMarkStopAsFailedDialog, setShowMarkStopAsFailedDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  // const [ref, setRef] = useState<null | HTMLAnchorElement>(null);
  // TODO: Have handling for grouped stops
  // Assume one stop per slot for now
  const stopUuid = stop?.uuid;
  const { shipment } = stop.leg;

  const [updateOrderPaperworkCompleted] =
    useUpdatePaperworkCompletedForStopsMutation({
      refetchQueries: [
        OrderWithPaperworkDocument,
        OrderWithInboundOutboundPaperworkDocument,
      ],
    });
  const dispatch = useAppDispatch();

  const { data: documentsByShipmentData } = useDocumentsByShipmentQuery(
    isNilOrEmptyString(shipment.uuid)
      ? { skip: true }
      : { variables: { uuid: shipment.uuid } },
  );
  const shipmentOrderUuid = shipment.order?.uuid;
  const { data: paperworkCompleteData } =
    useOrderWithInboundOutboundPaperworkQuery(
      isNilOrEmptyString(shipmentOrderUuid)
        ? { skip: true }
        : { variables: { uuid: shipmentOrderUuid } },
    );
  const paperworkComplete =
    paperworkCompleteData?.order?.paperwork.paperworkComplete ?? false;
  const paperworkMarkedCompleteOverride =
    paperworkCompleteData?.order?.paperwork.paperworkMarkedCompleteOverride ??
    null;
  const ffHidePaperworkCompleteColumn = useFeatureFlag(
    FeatureFlag.FF_HIDE_PAPERWORK_COMPLETE_COLUMN,
  );
  const ffInboundOutboundPaperworkColumns = useFeatureFlag(
    FeatureFlag.FF_INBOUND_OUTBOUND_PAPERWORK_COLUMNS,
  );

  const hasEndOfDayDocuments = useMemo(() => {
    return documentsByShipmentData?.shipmentV2?.documents.some(
      (doc) => doc.type === DocumentType.EndOfDayDocument,
    );
  }, [documentsByShipmentData]);

  const addresses = useAppSelector((state) => selectAddresses(state));
  const currentAddress = addresses.find(
    (address) => address.uuid === stop?.address.uuid,
  );

  if (isNil(stop)) {
    return <CircularProgress />;
  }

  const timeString = getAppointmentTimeStringEOD(
    stop?.appointmentTime,
    stop?.endAppointmentTime,
  );

  const tableCellTimeString = `${timeString} ${transformAppointmentStatus(
    stop?.appointmentTextStatus ?? '',
  )}`;

  const getStopStatusTag = (status: StopStatus | null | undefined) => {
    if (isNil(status)) {
      return <Chip size="small" label="Unknown" color="secondary" />;
    }
    return (
      <Chip
        size="small"
        label={sentenceCase(
          status === StopStatus.Failed ? 'Attempted' : status,
        )}
        color={StopStatusTagVariant[status]}
      />
    );
  };

  return (
    <TableRow
      hover
      sx={{
        '& > *': { borderBottom: 'unset' },
        transition: '0.15s',
        cursor: 'pointer',
      }}
      selected={selectedStopUuid === stopUuid}
      onClick={() => {
        if (tableIsMinimized) {
          setSelectedStopUuid(stopUuid);
        }
      }}
    >
      {!tableIsMinimized && !completedStopsNotOnRoutesSelected && (
        <TableCell align="center" width="10px">
          {order}
        </TableCell>
      )}
      <TableCell align="center">
        {shipment.order?.standardOrderFields?.shipperBillOfLadingNumber ?? '-'}
      </TableCell>
      <TableCell align="center">
        {sentenceCase(
          shipment?.standardShipmentFields?.pickupOrDelivery ?? '-',
        )}
      </TableCell>
      {!tableIsMinimized && (
        <TableCell align="center">{currentAddress?.name ?? '-'}</TableCell>
      )}
      {!tableIsMinimized && (
        <TableCell align="center">
          {isNil(currentAddress)
            ? '-'
            : transformAddressToFullAddressString({
                ...currentAddress,
                line1: currentAddress?.line1 ?? null,
                city: currentAddress?.city ?? null,
                zip: currentAddress?.zip ?? null,
              })}
        </TableCell>
      )}
      {!tableIsMinimized && (
        <TableCell align="center">{tableCellTimeString}</TableCell>
      )}
      {!completedStopsNotOnRoutesSelected && (
        <TableCell align="center">{getStopStatusTag(stop.status)}</TableCell>
      )}
      {!ffHidePaperworkCompleteColumn && (
        <>
          <TableCell align="center">
            <Stack>
              <Tooltip
                arrow
                title={
                  hasEndOfDayDocuments === true
                    ? 'Documents uploaded'
                    : 'No documents uploaded'
                }
              >
                {hasEndOfDayDocuments === true ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </Tooltip>
            </Stack>
          </TableCell>
          <TableCell align="center">
            <Stack>
              <Tooltip
                arrow
                title={
                  paperworkMarkedCompleteOverride === true
                    ? 'Paperwork marked complete'
                    : paperworkMarkedCompleteOverride === false
                      ? 'Paperwork marked incomplete'
                      : paperworkComplete
                        ? 'Paperwork completed'
                        : 'Missing documents'
                }
              >
                {paperworkComplete ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </Tooltip>
            </Stack>
          </TableCell>
        </>
      )}
      {ffInboundOutboundPaperworkColumns && (
        <>
          <TableCell align="center">
            <Paperwork
              paperwork={paperworkCompleteData?.order?.inboundPaperwork}
            />
          </TableCell>
          <TableCell align="center">
            <Paperwork
              paperwork={paperworkCompleteData?.order?.outboundPaperwork}
            />
          </TableCell>
        </>
      )}
      <TableCell>
        <Stack direction="row">
          {!tableIsMinimized && (
            <Tooltip arrow title="Open Document Upload">
              <IconButton
                sx={{ padding: '5px' }}
                onClick={() => {
                  if (!tableIsMinimized) {
                    setTableIsMinimized(true);
                    setSelectedStopUuid(stopUuid);
                  }
                }}
              >
                <ChevronLeft />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            sx={{ padding: '5px' }}
            disabled={!canWriteEndOfDay}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <StopRowContextMenu
            setShowMarkStopAsFailedDialog={setShowMarkStopAsFailedDialog}
            updatePaperworkCompleted={async () => {
              try {
                if (isNil(shipmentOrderUuid)) {
                  return;
                }
                const res = await updateOrderPaperworkCompleted({
                  variables: {
                    updatePaperworkCompleteInput: {
                      uuid: shipmentOrderUuid,
                      paperworkCompleted: !paperworkComplete,
                    },
                  },
                });
                if (!isNil(res.errors)) {
                  return;
                }
                dispatch(
                  updateOneStopValues({
                    id: stop.uuid,
                    changes: {
                      paperworkMarkedComplete: !paperworkComplete,
                    },
                  }),
                );
              } catch {
                // console.log(e)
              }
            }}
            stopUuid={stop?.uuid ?? ''}
            orderUuid={shipment?.order?.uuid ?? ''}
            setAnchorEl={setAnchorEl}
            setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
            setCannotCompleteOrderModalMessage={
              setCannotCompleteOrderModalMessage
            }
            status={stop?.status ?? undefined}
            isLocalSlot={false}
            anchorEl={anchorEl}
          />
        </Stack>
      </TableCell>
      <MarkStopAsAttemptedDialog
        handleClose={() => {
          setShowMarkStopAsFailedDialog(false);
        }}
        handleMarkStopAsFailed={async (notes) => {
          try {
            if (!isNil(stopUuid)) {
              await markStopAsFailed({
                variables: {
                  markStopAsFailedV2Input: {
                    notes,
                    uuid: stopUuid,
                  },
                },
              });
              setShowMarkStopAsAttemptedSuccessMessage(true);
            }
            setShowMarkStopAsFailedDialog(false);
            setShouldLoadRoutes(true);
          } catch {
            setShowMarkStopAsAttemptedErrorMessage(true);
          }
        }}
        open={showMarkStopAsFailedDialog}
      />
    </TableRow>
  );
};

export default RoutelessStopRow;
