import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { Sidebar } from '../../../common/components/sidebar';
import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY } from '../../../common/local-storage/keys';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import CustomerPortalCompanySelectModal, {
  SelectEntityType,
} from './customer-portal-order-form/customer-portal-company-select-modal';
import { CustomerPortalUserMenu } from './customer-portal-user-menu';
import useCustomerPortalDrawerItems from './use-customer-portal-drawer-items';

const CustomerPortalSidebar = React.memo(() => {
  const { drawerItems } = useCustomerPortalDrawerItems();
  // null means no modal is open
  const [selectEntityType, setSelectEntityType] =
    useState<SelectEntityType | null>(null);
  const navigate = useNavigate();
  const { thirdPartyUser } = useMeAsThirdPartyUser();

  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );
  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );

  const distinctCompanies = useMemo(() => {
    if (isNil(thirdPartyUser)) {
      return [];
    }
    const companies = thirdPartyUser.contacts.map((c) => c.company);
    return uniqBy(companies, ({ uuid }) => uuid);
  }, [thirdPartyUser]);

  const handleAddOrder = () => {
    if (distinctCompanies.length === 1) {
      const firstContactUuid = thirdPartyUser?.contacts[0]?.uuid;
      if (isNilOrEmptyString(firstContactUuid)) {
        return;
      }
      navigate('/customer-portal/orders/add', {
        state: { contactUuid: firstContactUuid },
      });
    } else {
      setSelectEntityType(SelectEntityType.Order);
    }
  };

  const handleGetQuote = () => {
    if (distinctCompanies.length === 1) {
      const firstContactUuid = thirdPartyUser?.contacts[0]?.uuid;
      if (isNilOrEmptyString(firstContactUuid)) {
        return;
      }
      navigate('/customer-portal/quotes/add', {
        state: { contactUuid: firstContactUuid },
      });
    } else {
      setSelectEntityType(SelectEntityType.Quote);
    }
  };

  const drawerActions = ffCustomerPortalOrderEntry && (
    <>
      {!isNil(selectEntityType) && (
        <CustomerPortalCompanySelectModal
          entityType={selectEntityType}
          onClose={() => {
            setSelectEntityType(null);
          }}
        />
      )}
      <Stack gap={1}>
        <Button
          startIcon={<Add />}
          sx={{
            color: '#fff',
            whiteSpace: 'nowrap',
            backgroundColor: (theme) => theme.palette.airfreightBlue[70],
            '&:hover': {
              backgroundColor: (theme) => theme.palette.airfreightBlue[60],
            },
          }}
          onClick={handleAddOrder}
        >
          Add Order
        </Button>
        {ffCustomerPortalQuoteEntry && (
          <Button
            variant="outlined"
            sx={{
              color: '#fff',
              whiteSpace: 'nowrap',
              borderColor: (theme) => theme.palette.airfreightBlue[70],
              '&:hover': {
                backgroundColor: (theme) => theme.palette.airfreightBlue[70],
                borderColor: (theme) => theme.palette.airfreightBlue[60],
              },
            }}
            onClick={handleGetQuote}
          >
            Get Quote
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <Sidebar
      drawerItems={drawerItems}
      actionItems={drawerActions}
      sidebarOpenLocalStorageKey={CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY}
      userMenu={<CustomerPortalUserMenu />}
    />
  );
});

export default CustomerPortalSidebar;
