import { inches } from '@buge/ts-units/length';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil, round } from 'lodash';
import type React from 'react';
import { useRef, useState } from 'react';
import {
  type OrderTableFieldPackageFragment,
  useRateOrderByUuidLazyQuery,
  useUpdatePackageMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';

enum PackageInputId {
  QUANTITY = 'quantity',
  LENGTH = 'length',
  WIDTH = 'width',
  HEIGHT = 'height',
}

const PackageRowCellComponent = ({
  orderUuid,
  pkg,
}: {
  readonly orderUuid: string;
  readonly pkg: OrderTableFieldPackageFragment;
}) => {
  const initialLength = isNil(pkg.length)
    ? undefined
    : round(pkg.length?.in(inches).amount, 3);
  const initialWidth = isNil(pkg.width)
    ? undefined
    : round(pkg.width?.in(inches).amount, 3);
  const initialHeight = isNil(pkg.height)
    ? undefined
    : round(pkg.height?.in(inches).amount, 3);
  const setOrderUuidToRefetch = useGlobalStore(
    (state) => state.setOrderUuidToRefetch,
  );
  const [isEditing, setIsEditing] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const lengthRef = useRef<HTMLInputElement>(null);
  const widthRef = useRef<HTMLInputElement>(null);
  const heightRef = useRef<HTMLInputElement>(null);
  const [quantity, setQuantity] = useState(pkg.quantity.toString());
  const [length, setLength] = useState(initialLength?.toString() ?? '');
  const [width, setWidth] = useState(initialWidth?.toString() ?? '');
  const [height, setHeight] = useState(initialHeight?.toString() ?? '');
  const [rateOrderByUuid] = useRateOrderByUuidLazyQuery();
  const [updatePackage] = useUpdatePackageMutation();

  const onSave = async () => {
    const quantityNumber = Number.parseInt(quantity, 10);
    const lengthNumber = Number.parseFloat(length);
    const widthNumber = Number.parseFloat(width);
    const heightNumber = Number.parseFloat(height);

    if (!Number.isNaN(quantityNumber)) {
      await updatePackage({
        variables: {
          updatePackageInput: {
            packageUpdateInput: {
              uuid: pkg.uuid,
              quantity: quantityNumber,
              length: Number.isNaN(lengthNumber) ? null : lengthNumber,
              width: Number.isNaN(widthNumber) ? null : widthNumber,
              height: Number.isNaN(heightNumber) ? null : heightNumber,
            },
          },
        },
      });
      await rateOrderByUuid({
        variables: {
          uuid: orderUuid,
          persist: true,
        },
      });
      setOrderUuidToRefetch(orderUuid);
    }
    setIsEditing(false);
  };

  const onInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      switch (e.target.id) {
        case PackageInputId.QUANTITY: {
          lengthRef.current?.focus();
          break;
        }
        case PackageInputId.LENGTH: {
          widthRef.current?.focus();
          break;
        }
        case PackageInputId.WIDTH: {
          heightRef.current?.focus();
          break;
        }
        case PackageInputId.HEIGHT: {
          quantityRef.current?.focus();
          break;
        }
        default: {
          break;
        }
      }
    } else if (e.key === 'Enter') {
      await onSave();
    }
  };

  if (isEditing) {
    return (
      <Stack
        direction="row"
        spacing={0.25}
        alignItems="center"
        sx={{ mt: '-1px' }}
      >
        <TextField
          inputRef={quantityRef}
          id={PackageInputId.QUANTITY}
          defaultValue={pkg.quantity.toString()}
          type="number"
          size="small"
          onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
          InputProps={{
            style: { height: 20 },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">pc</Typography>
              </InputAdornment>
            ),
          }}
          onKeyDown={onInputKeyDown}
          inputProps={{
            style: { fontSize: '11px' },
          }}
          sx={{ width: '40px', padding: 0 }}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
          error={Number.isNaN(quantity)}
        />
        <TextField
          id={PackageInputId.LENGTH}
          inputRef={lengthRef}
          defaultValue={initialLength}
          type="number"
          size="small"
          onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
          InputProps={{
            style: { height: 20 },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">L</Typography>
              </InputAdornment>
            ),
          }}
          onKeyDown={onInputKeyDown}
          inputProps={{
            style: { fontSize: '11px' },
          }}
          sx={{ width: '37px', padding: 0 }}
          onChange={(e) => {
            setLength(e.target.value);
          }}
        />
        <span>⨉</span>
        <TextField
          id={PackageInputId.WIDTH}
          inputRef={widthRef}
          defaultValue={initialWidth}
          type="number"
          size="small"
          onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
          InputProps={{
            style: { height: 20 },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">W</Typography>
              </InputAdornment>
            ),
          }}
          onKeyDown={onInputKeyDown}
          inputProps={{
            style: { fontSize: '11px' },
          }}
          sx={{ width: '38px' }}
          onChange={(e) => {
            setWidth(e.target.value);
          }}
        />
        <span>⨉</span>
        <TextField
          id={PackageInputId.HEIGHT}
          inputRef={heightRef}
          defaultValue={initialHeight}
          type="number"
          size="small"
          onWheel={(e) => (e.target as HTMLTextAreaElement).blur()}
          InputProps={{
            style: { height: 20 },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="caption">H</Typography>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: { fontSize: '11px' },
          }}
          onKeyDown={onInputKeyDown}
          sx={{ width: '37px', fontSize: '10px' }}
          onChange={(e) => {
            setHeight(e.target.value);
          }}
        />
        <IconButton size="small" sx={{ p: '3px' }} onClick={onSave}>
          <CheckIcon sx={{ fontSize: 12 }} />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography variant="caption">{pkg.quantity}</Typography>
      {(!isNil(initialLength) ||
        !isNil(initialWidth) ||
        !isNil(initialHeight)) && (
        <Typography variant="caption">
          {initialLength}x{initialWidth}x{initialHeight}
        </Typography>
      )}
      <IconButton
        size="small"
        sx={{ p: '3px' }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        <EditIcon sx={{ fontSize: 12 }} />
      </IconButton>
    </Stack>
  );
};

const PackagesCellComponent = ({
  orderUuid,
  packages,
}: {
  readonly orderUuid: string;
  readonly packages: OrderTableFieldPackageFragment[];
}) => {
  return (
    <Stack direction="row" sx={{ width: 'fill' }}>
      {packages.map((pkg) => (
        <PackageRowCellComponent
          key={pkg.uuid}
          orderUuid={orderUuid}
          pkg={pkg}
        />
      ))}
    </Stack>
  );
};

export { PackagesCellComponent };
