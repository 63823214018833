import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from '@mui/material';
import type React from 'react';

export type HelpTooltipProps = {
  readonly title: React.ReactNode;
};
const HelpTooltip = ({ title }: HelpTooltipProps) => {
  return (
    <Tooltip title={title} placement="bottom-end">
      <HelpIcon sx={{ fontSize: 16 }} />
    </Tooltip>
  );
};

export default HelpTooltip;
