import ModeIcon from '@mui/icons-material/Mode';
import {
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { getStopCustomChargeTestIds } from '../../../../../../../../../utils';
import { specialChargeGroupFormat } from '../../../../../../../../common/utils/utils';
import {
  type AllAccessorialFieldsFragment,
  CustomChargeBillingMethod,
} from '../../../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import {
  type CustomChargeValues,
  type CustomCostValues,
  type OrderFormValues,
} from '../../../../forms/types';
import { type CustomChargesPrefix } from '../../../../utils';

export type CustomChargeRateInputProps = {
  readonly customChargeKey: CustomChargesPrefix;
  readonly customCharge:
    | CustomChargeValues
    | CustomCostValues
    | null
    | undefined;
  readonly customChargeIdx: number;
  readonly stopIdx: 0 | 1;
  readonly currentAccessorial: AllAccessorialFieldsFragment | undefined;
  readonly disabled: boolean;
};

const CustomChargeRateInput = ({
  customChargeKey,
  customCharge,
  customChargeIdx,
  stopIdx,
  currentAccessorial,
  disabled,
}: CustomChargeRateInputProps) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [rateInput, setRateInput] = useState<string>('');
  const { control, setValue } = useFormContext<OrderFormValues>();
  let chargeGroupOptions =
    currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity'
      ? currentAccessorial.chargeGroups?.map((chargeGroup) => ({
          value: chargeGroup.uuid,
          label: chargeGroup.name,
        }))
      : [];
  chargeGroupOptions =
    currentAccessorial?.__typename === 'SpecialAccessorialEntity'
      ? currentAccessorial.chargeGroups?.map((chargeGroup) => ({
          value: chargeGroup.uuid,
          label: specialChargeGroupFormat(chargeGroup),
        }))
      : chargeGroupOptions;
  const chargeGroupUuid = customCharge?.chargeGroupUuid;

  const {
    customChargeRateInputTestId,
    customChargeAccessorialChargeGroupSelectTestId,
    customChargeAccessorialChargeGroupSelectInputTestId,
  } = getStopCustomChargeTestIds({
    stopIdx,
    customChargeIdx,
  });

  useEffect(() => {
    if (
      customCharge?.isLocal === true &&
      customCharge.billingMethod === CustomChargeBillingMethod.AdHoc
    ) {
      setIsEditing(true);
    }
  }, [customCharge?.isLocal, customCharge?.billingMethod]);

  useEffect(() => {
    setRateInput(customCharge?.rate?.toString() ?? '');
  }, [customCharge?.rate]);

  if (
    currentAccessorial?.__typename === 'ZoneBasedAccessorialEntity' ||
    currentAccessorial?.__typename === 'SpecialAccessorialEntity'
  ) {
    return (
      <AutocompleteFuzzy
        size="small"
        disabled={disabled}
        value={
          isNil(chargeGroupUuid)
            ? null
            : {
                value: chargeGroupUuid,
                label:
                  chargeGroupOptions?.find(
                    (option) => option.value === chargeGroupUuid,
                  )?.label ?? '',
              }
        }
        data-testid={customChargeAccessorialChargeGroupSelectTestId}
        options={chargeGroupOptions}
        matchSortOptions={{ keys: ['label'] }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Charge group"
            inputProps={{
              ...params.inputProps,
              'data-testid':
                customChargeAccessorialChargeGroupSelectInputTestId,
            }}
          />
        )}
        onChange={(_, option) => {
          setValue(`${customChargeKey}.chargeGroupUuid`, option?.value ?? null);
        }}
      />
    );
  }

  const onSaveRate = () => {
    if (rateInput === '') {
      setValue(`${customChargeKey}.rate`, null);
    } else {
      const parsedFloat = Number.parseFloat(rateInput);
      if (!Number.isNaN(parsedFloat)) {
        setValue(`${customChargeKey}.rate`, parsedFloat);

        /**
         * The useAccessorialRate field is used to differentiate user-entered accessorial rates from the default accessorial rate.
         * It is set to false here here to ensure that any user-entered accessorial rates are not overriden by the backend.
         */
        if (
          customCharge?.billingMethod === CustomChargeBillingMethod.Accessorial
        ) {
          setValue(`${customChargeKey}.useAccessorialRate`, false);
        }
      }
    }
    setIsEditing(false);
  };

  const editingDisabled =
    customCharge?.billingMethod === CustomChargeBillingMethod.Accessorial &&
    isNilOrEmptyString(customCharge.accessorialUuid);

  return (
    <Controller
      control={control}
      name={`${customChargeKey}.rate`}
      render={({ field: { value } }) => {
        return isEditing ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormControl fullWidth sx={{ maxWidth: '200px' }}>
              <TextField
                autoFocus
                type="number"
                disabled={disabled}
                size="small"
                value={rateInput ?? ''}
                InputProps={{
                  endAdornment: currentAccessorial?.__typename ===
                    'WeightBasedAccessorialEntity' && (
                    <InputAdornment position="end">/100 lbs</InputAdornment>
                  ),
                  inputProps: {
                    'data-testid': customChargeRateInputTestId,
                  },
                }}
                focused={isEditing}
                onChange={(e) => {
                  setRateInput(e.target.value);
                }}
                onBlur={() => {
                  onSaveRate();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSaveRate();
                  }
                }}
              />
            </FormControl>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{value ?? '-'}</Typography>
            {!disabled && (
              <IconButton
                disabled={editingDisabled}
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <ModeIcon
                  sx={{ color: theme.palette.grey[500], fontSize: '16px' }}
                />
              </IconButton>
            )}
          </Stack>
        );
      }}
    />
  );
};

export default React.memo(CustomChargeRateInput);
