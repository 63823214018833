import { Build, CheckCircle, LocalShipping } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useSamsaraOAuthTokenQuery,
  useSamaraOAuthAuthorizeUrlLazyQuery,
} from '../../generated/graphql';
import PalletButton from '../../pallet-ui/button/pallet-button';

const useStyles = () => {
  return {
    box: {
      height: '70vh', // 100% doesn't seem to work here
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};

const SamsaraSettingsTab = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteSettingsSamsara } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsSamsara,
  );

  const styles = useStyles();
  const { data, loading } = useSamsaraOAuthTokenQuery();
  const [
    // eslint-disable-next-line @typescript-eslint/naming-convention
    getSamaraOAuthAuthorizeUrl,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { loading: samaraOAuthAuthorizeUrlLoading },
  ] = useSamaraOAuthAuthorizeUrlLazyQuery();

  const handleAuthorize = async () => {
    const url = (await getSamaraOAuthAuthorizeUrl()).data
      ?.samaraOAuthAuthorizeUrl;
    if (!isNil(url)) {
      window.open(url, '_blank');
    }
  };

  if (loading) {
    return (
      <Box sx={styles.box}>
        <CircularProgress />
      </Box>
    );
  }
  if (!isNil(data?.samsaraOAuthToken?.uuid)) {
    return (
      <Box sx={styles.box}>
        <CheckCircle
          sx={{
            color: 'green',
            fontSize: '40px',
            mb: '20px',
          }}
        />
        <Typography sx={{ fontSize: '30px', mb: 5 }}>
          Samsara integration complete
        </Typography>
        <PalletButton
          startIcon={<Build />}
          variant="contained"
          disabled={!canWriteSettingsSamsara}
          loading={samaraOAuthAuthorizeUrlLoading}
          onClick={handleAuthorize}
        >
          Repair
        </PalletButton>
      </Box>
    );
  }
  return (
    <Box sx={styles.box}>
      <LocalShipping
        sx={{
          fontSize: '40px',
          mb: '20px',
        }}
      />
      <PalletButton
        variant="contained"
        disabled={!canWriteSettingsSamsara}
        loading={samaraOAuthAuthorizeUrlLoading}
        onClick={handleAuthorize}
      >
        Click here to set up your Samsara integration
      </PalletButton>
    </Box>
  );
};

export default SamsaraSettingsTab;
