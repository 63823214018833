import { Stack } from '@mui/material';
import { isEmpty, isNil, uniq } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWmsContactsQuery } from '../../../../generated/graphql';
import InventoryPage from '../../../inventory/components/inventory-page';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../../constants';
import useCustomerPortalStore from '../../use-customer-portal-store';

const CustomerPortalInventory = () => {
  const navigate = useNavigate();
  const { data: wmsContacts, loading } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const companies = useMemo(() => {
    return uniq(
      wmsContacts?.wmsContacts?.map((contact) => contact.company) ?? [],
    );
  }, [wmsContacts]);
  const { company, setCompany } = useCustomerPortalStore((state) => ({
    company: state.company,
    setCompany: state.setCompany,
  }));

  useEffect(() => {
    if (!loading && isEmpty(companies)) {
      // if there are no companies with WMS inventory for this user, redirect to orders page
      setCompany(null);
      navigate(CUSTOMER_PORTAL_ORDERS_PATH);
      return;
    }
    if (isNil(company) && !isEmpty(companies)) {
      setCompany(companies[0] ?? null);
    }
  }, [loading, company, companies, setCompany, navigate]);

  return (
    <Stack
      direction="column"
      sx={{
        height: '100vh',
        overflowY: 'auto',
      }}
    >
      <InventoryPage isCustomerPortal />
    </Stack>
  );
};

export default CustomerPortalInventory;
