import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import { useRef, useState } from 'react';
import {
  getAppointmentTimeString,
  transformAddressToFullAddressString,
} from 'shared/copy';
import { shallow } from 'zustand/shallow';
import { ColoredChip } from '../../../common/components/colored-chip';
import useMultiplayerStore from '../../../common/multiplayer/multiplayer-store';
import {
  DispatchMultiplayerAction,
  type DispatchUserLocationPayload,
} from '../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import { Size } from '../../../common/types';
import {
  FulfillmentType,
  type StopFragment,
  type StopOnRouteFragment,
  StopStatus,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import { useDispatchViewSettings } from '../hooks/use-dispatch-view-settings';
import { getEtaOrCompletedTimeString, getStopDataFromFragment } from '../utils';
import PackageInfoText from './components/package-info-text';
import RouteAppointmentTimeField from './components/route-appointment-time-field';
import RouteServiceTimeField from './components/route-service-time-field';
import RouteStopCardHoverMenu from './components/route-stop-card-hover-menu';
import RouteStopRefNumberRow from './components/route-stop-ref-number-row';
import StopDetailsStatusIcon from './components/stop-details-status-icon';
import StopStatusChip from './components/stop-status-chip';
import UserEditingPopup from './components/user-editing-popup';

type RouteStopCardProps = {
  readonly stop: StopOnRouteFragment | StopFragment;
  readonly locked: boolean;
  readonly index: number | undefined;
  readonly width: number | undefined;
  readonly allowHover?: boolean;
  readonly noBorder?: boolean;
};
const CardContentSpecialPadding = styled(CardContent)(`
  padding: 3px;
  flex: 1;
  &:last-child {
    padding-bottom: 5px;
  }
`);

const RouteStopCard = ({
  stop,
  locked,
  index,
  width,
  allowHover = true,
  noBorder = false,
}: RouteStopCardProps) => {
  const theme = useTheme();
  const { dispatchTableColors } = useDispatchTableColors();
  const { useMinimizedAppointmentTime } = useDispatchViewSettings();
  const {
    stopUuid,
    stopStatus,
    stopType,
    contactPerson,
    specialInstructions,
    address,
    order,
    serviceUuid,
    serviceName,
    shipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber,
  } = getStopDataFromFragment(stop);
  const packages = order?.packages;

  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const cardRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const userEditLocationsForStop: DispatchUserLocationPayload[] =
    useMultiplayerStore((state) =>
      state.dispatchUserPresenceData
        .filter(
          (userPresenceData) =>
            userPresenceData.data.stopUuid === stopUuid &&
            userPresenceData.data.connectionId !== state.dispatchConnectionId,
        )
        .map((userPresenceData) => userPresenceData.data),
    );
  const [isHovering, isHoveringLinkedOrder] = useDispatchStore(
    (state) => [
      state.hoveredStopUuid === stopUuid && allowHover,
      state.hoveredOrderUuid === order?.uuid &&
        order?.fulfillmentType === FulfillmentType.Dedicated,
    ],
    shallow,
  );
  const [
    planningDate,
    isSelectedInMap,
    setHoveredOrderUuid,
    setHoveredStopUuid,
    setSelectedMapStop,
  ] = useDispatchStore(
    (state) => [
      state.planningDate,
      state.selectedMapStop?.uuid === stopUuid && state.showMap,
      state.setHoveredOrderUuid,
      state.setHoveredStopUuid,
      state.setSelectedMapStop,
    ],
    shallow,
  );

  const appointmentTimeString = getAppointmentTimeString({
    stop: stop.__typename === 'StandardStopEntity' ? stop : stop.stop,
    deliveryDate:
      stop.__typename === 'StandardStopEntity'
        ? stop.shipment?.standardShipmentFields?.deliveryDate
        : stop.stop.deliveryDate,
    useMinimizedAppointmentTime,
  });

  const etaOrCompletedTimeString =
    stop.__typename === 'CachedStopEntity'
      ? null
      : getEtaOrCompletedTimeString(stop);

  const serviceColor = dispatchTableColors?.find(
    (dispatchTableColor) =>
      !isNil(dispatchTableColor.service) &&
      dispatchTableColor.service.uuid === serviceUuid &&
      dispatchTableColor.active,
  )?.color;

  const PackageDetailsComponent = (
    <Box>
      {isEmpty(packages) || isNil(packages) ? (
        <Typography variant="caption">No packages</Typography>
      ) : (
        <Stack>
          {packages.map((pkg, i) => (
            <PackageInfoText
              // Unfortunately there's currently no uuid field on PackageForCachedStop.
              key={'uuid' in pkg ? pkg.uuid : i}
              pkg={pkg}
            />
          ))}
        </Stack>
      )}
    </Box>
  );

  const notes = 'notes' in stop && !isEmpty(stop.notes) ? stop.notes : null;

  return (
    <Badge sx={{ width: '100%' }}>
      <Card
        ref={cardRef}
        id={`route_stop_card_${stopUuid}`}
        variant="outlined"
        sx={{
          p: '5px',
          pb: 0,
          width: '100%',
          borderColor:
            isSelectedInMap || isHovering || isHoveringLinkedOrder
              ? theme.palette.primary.main
              : undefined,
          borderTopLeftRadius: isEmpty(userEditLocationsForStop)
            ? undefined
            : 0,
          borderWidth: noBorder ? 0 : 1,
        }}
        onMouseEnter={() => {
          setHoveredStopUuid(stopUuid);
          setHoveredOrderUuid(order?.uuid);
        }}
        onMouseLeave={() => {
          setHoveredStopUuid(undefined);
          setHoveredOrderUuid(undefined);
        }}
        onClick={() => {
          setSelectedMapStop(
            stop.__typename === 'StandardStopEntity' ? stop : stop.stop,
          );
        }}
      >
        <UserEditingPopup
          anchorRef={cardRef}
          userEditLocations={userEditLocationsForStop}
        />
        <Stack
          direction="row"
          spacing={0.5}
          color={
            stopStatus === StopStatus.NotArrived ? undefined : 'text.secondary'
          }
        >
          {!isNil(index) && (
            <Box
              sx={{
                display: 'flex',
                minWidth: '24px',
                height: '24px',
                borderRadius: '100%',
                border: 1,
                borderColor: 'thistle',
              }}
            >
              <Typography variant="caption" sx={{ m: 'auto', mt: '2px' }}>
                {index + 1}
              </Typography>
            </Box>
          )}
          <CardContentSpecialPadding>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  maxWidth: isNil(width) ? '100%' : width * 0.85 - 90,
                }}
              >
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  {sentenceCase(stopType ?? '')}
                </Typography>
                {!isNil(stop.stopDetails) && (
                  <StopDetailsStatusIcon
                    status={stop.stopDetails.stopDetailsStatus}
                    message={stop.stopDetails.message}
                  />
                )}
                {!isNil(serviceName) && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ maxWidth: '100px' }}
                    >
                      <Typography
                        noWrap
                        variant="caption"
                        color="text.secondary"
                      >
                        {serviceName}
                      </Typography>
                    </Stack>
                    {!isNil(serviceColor) && (
                      <ColoredChip
                        dot
                        label=""
                        size={Size.sm}
                        color={serviceColor}
                      />
                    )}
                  </>
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ float: 'right', ml: 1 }}
              >
                <Stack direction="row">
                  <Typography variant="caption">
                    {appointmentTimeString} | {etaOrCompletedTimeString}
                  </Typography>
                </Stack>
                <StopStatusChip
                  status={stopStatus}
                  refusedBy={
                    !isNil(order) && 'refusedBy' in order
                      ? order.refusedBy
                      : null
                  }
                />
              </Stack>
              {isHovering &&
                !isEditing &&
                !locked &&
                stop.__typename === 'StandardStopEntity' &&
                stopStatus !== StopStatus.Completed && (
                  <RouteStopCardHoverMenu
                    stop={stop}
                    setIsEditing={setIsEditing}
                    top={-5}
                  />
                )}
            </Stack>
            {!isNil(address) && (
              <Stack
                direction="column"
                alignItems="left"
                justifyContent="space-between"
              >
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  {address.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {transformAddressToFullAddressString(address, false)}
                </Typography>
                {(isNil(address.latitude) || isNil(address.longitude)) && (
                  <Tooltip title="This stop's address has no coordinates tied to it, so it will not be included on the map or route optimizations">
                    <Typography
                      variant="caption"
                      color="error"
                      fontWeight="bold"
                    >
                      Address not found
                    </Typography>
                  </Tooltip>
                )}
              </Stack>
            )}
            <Stack
              direction="row"
              alignItems="left"
              justifyContent="space-between"
            >
              <Typography variant="caption">{contactPerson?.phone}</Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              {isEditing && stop.__typename === 'StandardStopEntity' && (
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <RouteAppointmentTimeField
                      isStart
                      stop={stop}
                      baseDate={planningDate}
                    />
                    <Typography variant="caption"> - </Typography>
                    <RouteAppointmentTimeField
                      stop={stop}
                      isStart={false}
                      baseDate={planningDate}
                    />
                    <Typography variant="caption">appt.</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <RouteServiceTimeField stop={stop} />
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 1 }}
                      onClick={() => {
                        setIsEditing(false);
                        sendDispatchUserLocationEvent({
                          action: DispatchMultiplayerAction.LEAVE,
                        });
                      }}
                    >
                      Done
                    </Button>
                  </Stack>
                </Stack>
              )}
              {!isEditing && PackageDetailsComponent}
            </Stack>
            <RouteStopRefNumberRow
              orderUuid={order?.uuid}
              shipperBillOfLadingNumber={shipperBillOfLadingNumber}
              masterAirwayBillOfLadingNumber={masterAirwayBillOfLadingNumber}
              refNumbers={order?.refNumbers}
            />
            {(!isEmpty(specialInstructions) || !isEmpty(notes)) && (
              <Stack sx={{ width: isNil(width) ? '100%' : width }}>
                <Divider />
                {!isEmpty(specialInstructions) && (
                  <Typography variant="caption">
                    Special Instructions: {specialInstructions}
                  </Typography>
                )}
                {!isEmpty(notes) && (
                  <Typography variant="caption">Notes: {notes}</Typography>
                )}
              </Stack>
            )}
          </CardContentSpecialPadding>
        </Stack>
      </Card>
    </Badge>
  );
};

export default RouteStopCard;
