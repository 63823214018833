import { isNil, merge } from 'lodash';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { GENERATE_ROUTES_STORE_KEY } from '../../../common/local-storage/keys';
import {
  AppointmentWindowType,
  type DriverEquipmentConstraintInput,
} from '../../../generated/graphql';

type DriverEquipmentConstraint = DriverEquipmentConstraintInput & {
  active: boolean;
};

type GenerateRoutesStore = {
  selectedDriverUuid: string | undefined;
  driverEquipmentConstraints: DriverEquipmentConstraint[];
  appointmentWindowType: AppointmentWindowType;
};

type GenerateRoutesActions = {
  setSelectedDriverUuid: (driverUuid: string | undefined) => void;
  setAppointmentWindowType: (
    appointmentWindowType: AppointmentWindowType,
  ) => void;
  getDriverEquipmentConstraintByDriverUuid: (
    driverUuid: string,
  ) => DriverEquipmentConstraint | undefined;
  upsertDriverEquipmentConstraint: (input: DriverEquipmentConstraint) => void;
  removeDriverEquipmentConstraint: ({
    driverUuid,
  }: {
    driverUuid: string;
  }) => void;
  reset: () => void;
};

const initialState: GenerateRoutesStore = {
  selectedDriverUuid: undefined,
  driverEquipmentConstraints: [],
  appointmentWindowType: AppointmentWindowType.Soft,
};

const useGenerateRoutesStore = create(
  persist(
    immer<GenerateRoutesStore & GenerateRoutesActions>((set, get) => ({
      ...initialState,
      setAppointmentWindowType: (
        appointmentWindowType: AppointmentWindowType,
      ) => {
        set((state) => {
          state.appointmentWindowType = appointmentWindowType;
        });
      },
      setSelectedDriverUuid: (driverUuid: string | undefined) => {
        set((state) => {
          state.selectedDriverUuid = driverUuid;
        });
      },
      getDriverEquipmentConstraintByDriverUuid: (driverUuid: string) => {
        return get().driverEquipmentConstraints.find(
          (constraint) => constraint.driverUuid === driverUuid,
        );
      },
      upsertDriverEquipmentConstraint: (input: DriverEquipmentConstraint) => {
        const driverEquipmentConstraint = get().driverEquipmentConstraints.find(
          (constraint) => constraint.driverUuid === input.driverUuid,
        );
        if (isNil(driverEquipmentConstraint)) {
          set((state) => {
            state.driverEquipmentConstraints.push(input);
          });
          return;
        }
        set((state) => {
          const newConstraintIdx = state.driverEquipmentConstraints.findIndex(
            (constraint) => constraint.driverUuid === input.driverUuid,
          );
          if (newConstraintIdx !== -1) {
            state.driverEquipmentConstraints[newConstraintIdx] = merge(
              state.driverEquipmentConstraints[newConstraintIdx],
              input,
            );
          }
        });
      },
      removeDriverEquipmentConstraint: ({
        driverUuid,
      }: {
        driverUuid: string;
      }) => {
        set((state) => {
          state.driverEquipmentConstraints =
            state.driverEquipmentConstraints.filter(
              (constraint) => constraint.driverUuid !== driverUuid,
            );
        });
      },
      reset: () => {
        const { appointmentWindowType, ...newState } = get();
        set(newState);
      },
    })),
    {
      name: GENERATE_ROUTES_STORE_KEY,
      partialize: (state) => ({
        appointmentWindowType: state.appointmentWindowType,
      }),
    },
  ),
);

export default useGenerateRoutesStore;
