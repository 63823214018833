import {
  DateFilterOptionV2,
  OrderTableField,
} from '../../../generated/graphql';

// These are the options that are displayed to the user in the UI. The Last X days options maps to LAST_X_DAYS in the backend.
export enum RelativeDateOption {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Tomorrow = 'Tomorrow',
  BeforeToday = 'Before Today',
  LastSevenDays = 'Last 7 Days',
  LastFifteenDays = 'Last 15 Days',
  LastThirtyDays = 'Last 30 Days',
  LastSixtyDays = 'Last 60 Days',
}

export const relativeFilterDateObjects = {
  [RelativeDateOption.Today]: {
    dateFilterOption: DateFilterOptionV2.Today,
    offsetDays: 0,
  },
  [RelativeDateOption.Yesterday]: {
    dateFilterOption: DateFilterOptionV2.Yesterday,
    offsetDays: 0,
  },
  [RelativeDateOption.Tomorrow]: {
    dateFilterOption: DateFilterOptionV2.Tomorrow,
    offsetDays: 0,
  },
  [RelativeDateOption.BeforeToday]: {
    dateFilterOption: DateFilterOptionV2.BeforeToday,
    offsetDays: 0,
  },
  [RelativeDateOption.LastSevenDays]: {
    dateFilterOption: DateFilterOptionV2.LastXDays,
    offsetDays: 7,
  },
  [RelativeDateOption.LastFifteenDays]: {
    dateFilterOption: DateFilterOptionV2.LastXDays,
    offsetDays: 15,
  },
  [RelativeDateOption.LastThirtyDays]: {
    dateFilterOption: DateFilterOptionV2.LastXDays,
    offsetDays: 30,
  },
  [RelativeDateOption.LastSixtyDays]: {
    dateFilterOption: DateFilterOptionV2.LastXDays,
    offsetDays: 60,
  },
} as const;

export const EXPENSIVE_TABLE_FIELDS = new Set<OrderTableField>([
  OrderTableField.TotalChargesWithItemized,
  OrderTableField.DriverSettlement,
]);

/** Order table fields that are only available for companies with terminals enabled */
export const TERMINAL_ONLY_ORDER_FIELDS: OrderTableField[] = [
  OrderTableField.OriginTerminal,
  OrderTableField.DestinationTerminal,
];

export const DEPRECATED_ORDER_FIELDS: OrderTableField[] = [
  // Replaced by OrderServiceDate
  OrderTableField.PrimaryServiceDate,
];

/** Order table fields that don't make sense to show to clients */
export const THIRD_PARTY_USER_ORDER_FIELDS: OrderTableField[] = [
  // Only used for third party users in the customer portal
  OrderTableField.Company,
];
