import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { type OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';

const ReferenceNumberLabelsConfigCard = ({
  mode,
}: {
  readonly mode: OrganizationPageMode;
}) => {
  const styles = useStyles();
  const { control, register } = useFormContext<OrganizationFormValues>();

  const { fields, append, remove } = useFieldArray<OrganizationFormValues>({
    name: 'referenceNumberLabels',
    control,
  });

  return (
    <Card sx={{ ...styles.card }}>
      <CardContent sx={styles.cardContent}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Reference number labels</Typography>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => {
                append({ name: '' });
              }}
            >
              Add Label
            </Button>
          </Stack>

          {fields.map((field, index) => (
            <Stack
              key={field.id}
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <TextField
                fullWidth
                {...register(`referenceNumberLabels.${index}.name`)}
                placeholder="Enter label"
                size="small"
              />
              <IconButton
                color="error"
                aria-label="remove"
                onClick={() => {
                  remove(index);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReferenceNumberLabelsConfigCard;
