import {
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { isNil, values } from 'lodash';
import { useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import DriverFilterButton from '../../../common/components/driver-filter-button';
import PalletButtonGroup from '../../../pallet-ui/button-group/pallet-button-group';
import PalletDateRangePicker from '../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import { OrderDialog } from '../../orders/components/order-dialog';
import { SettlementBillLineItemsTableMode, Tabs } from '../enums';
import { useGetSettlementBillLineItems } from '../hooks/use-get-settlement-bill-line-items';
import { CHECKBOX_CELL_WIDTH } from './common';
import SendToSettlementsModal from './send-to-settlements-modal';
import { SettlementBillLineItemsRow } from './settlement-bill-line-items-row';

const ROW_PER_PAGE_OPTIONS = [25, 50, 100];

export type SettlementBillLineItemsTableProps =
  | {
      readonly mode: SettlementBillLineItemsTableMode.SETTLEMENT_BILL;
      readonly settlementBillUuid: string;
    }
  | {
      readonly mode: SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE;
      readonly settlementBillUuid: null;
    };

export const SettlementBillLineItemsTable = ({
  mode,
  settlementBillUuid,
}: SettlementBillLineItemsTableProps) => {
  const {
    refresh,
    selectedSettlementBillLineItemIds,
    setSelectedSettlementBillLineItemIds,
    driverOptions,
    rangeStart,
    rangeEnd,
    tab,
    handleTabChange,
    totalCount,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    handleDriverOptionsChange,
    handleRangeChange,
    loadingSettlementBillLineItems,
    settlementBillLineItems,
    searchText,
    handleSearchTextChange,
    handleDriverSettlementBillUuidsChange,
  } = useGetSettlementBillLineItems();

  useEffect(() => {
    handleDriverSettlementBillUuidsChange(filterNotNil([settlementBillUuid]));
  }, [settlementBillUuid, handleDriverSettlementBillUuidsChange]);

  // This is the number of columns in the table and should be updated if the number of columns changes
  const COLUMNS_COUNT =
    mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? 10 : 8;

  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);
  const [sendingToSettlements, setSendingToSettlements] = useState(false);

  const onSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setSelectedSettlementBillLineItemIds(
        new Set(settlementBillLineItems.map((item) => item.id)),
      );
    } else {
      setSelectedSettlementBillLineItemIds(new Set());
    }
  };

  const onCheckboxChange = (settlementBillLineItemId: string) => {
    setSelectedSettlementBillLineItemIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(settlementBillLineItemId)) {
        newSet.delete(settlementBillLineItemId);
      } else {
        newSet.add(settlementBillLineItemId);
      }
      return newSet;
    });
  };

  const dateLabel = tab === Tabs.SETTLED ? 'Settlement date' : 'Completed at';

  const [currentSearchText, setCurrentSearchText] =
    useState<string>(searchText);

  const handleSearch = () => {
    handleSearchTextChange(currentSearchText);
  };

  return (
    <Stack direction="column" height="100%" spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
        sx={{ height: '40px' }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
            <PalletButtonGroup
              size="small"
              options={values(Tabs).map((tab) => ({
                label: tab,
                value: tab,
              }))}
              value={tab}
              onChange={handleTabChange}
            />
          )}
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
            <PalletDateRangePicker
              isClearable
              value={[rangeStart, rangeEnd]}
              label="Completed date"
              isRequired={false}
              sx={{ width: '250px' }}
              onAccept={handleRangeChange}
            />
          )}
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
            <DriverFilterButton
              selectedOptionsMultiselect={driverOptions}
              handleChangeMultiselect={handleDriverOptionsChange}
              prefixText="Driver"
            />
          )}
          <TextField
            size="small"
            placeholder="Search"
            value={currentSearchText}
            sx={{ width: '200px' }}
            onChange={(e) => {
              setCurrentSearchText(e.target.value);
            }}
            onBlur={handleSearch}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
            <Button
              variant="contained"
              disabled={selectedSettlementBillLineItemIds.size === 0}
              onClick={() => {
                setSendingToSettlements(true);
              }}
            >
              Send to settlements{' '}
              {selectedSettlementBillLineItemIds.size > 0 &&
                `(${selectedSettlementBillLineItemIds.size})`}
            </Button>
          )}
          <TablePagination
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            labelRowsPerPage="Show"
            component="div"
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={async (_, newPage) => {
              await handlePageChange({ newPage, forward: newPage > page });
            }}
            onRowsPerPageChange={async (event) => {
              const pageSize = Number(event.target.value);
              await handleRowsPerPageChange(pageSize);
            }}
          />
        </Stack>
      </Stack>
      <TableContainer sx={{ overflowY: 'auto', flex: 1 }}>
        <Table
          stickyHeader
          aria-label="stop-driver-maps-table"
          size="small"
          sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: '5px 5px',
            },
          }}
        >
          <colgroup>
            <col style={{ width: '1%' }} />
            {Array.from({ length: COLUMNS_COUNT - 1 }).map((_, index) => (
              <col
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ width: `${99 / COLUMNS_COUNT}%` }}
              />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE ? (
                <TableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
                  <Checkbox
                    checked={
                      selectedSettlementBillLineItemIds.size ===
                        settlementBillLineItems.length &&
                      settlementBillLineItems.length > 0
                    }
                    indeterminate={
                      selectedSettlementBillLineItemIds.size > 0 &&
                      selectedSettlementBillLineItemIds.size <
                        settlementBillLineItems.length
                    }
                    onChange={onSelectAllCheckboxChange}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              <TableCell>
                {/* TODO: add sort functionality when flat view is implemented BKO-1486 */}
                <TableSortLabel>{dateLabel}</TableSortLabel>
              </TableCell>
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
                <TableCell>
                  <TableSortLabel>Driver/Agent</TableSortLabel>
                </TableCell>
              )}
              {mode === SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE && (
                <TableCell>Type</TableCell>
              )}
              <TableCell>Customer</TableCell>
              <TableCell sx={{ minWidth: '25px' }}>Stop type</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Ref #s</TableCell>
              <TableCell>Total payout</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {loadingSettlementBillLineItems ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {settlementBillLineItems.map((settlementBillLineItem) => (
                <SettlementBillLineItemsRow
                  key={settlementBillLineItem.id}
                  settlementBillLineItem={settlementBillLineItem}
                  isChecked={selectedSettlementBillLineItemIds.has(
                    settlementBillLineItem.id,
                  )}
                  setOpenedOrderUuid={setOpenedOrderUuid}
                  tab={tab}
                  mode={mode}
                  onCheckboxChange={onCheckboxChange}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <SendToSettlementsModal
        open={sendingToSettlements}
        selectedSettlementBillLineItemIds={selectedSettlementBillLineItemIds}
        settlementBillLineItems={settlementBillLineItems}
        refresh={refresh}
        onClose={() => {
          setSendingToSettlements(false);
        }}
      />
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid ?? null}
        onClose={async () => {
          setOpenedOrderUuid(null);
          await refresh();
        }}
      />
    </Stack>
  );
};

export default SettlementBillLineItemsTable;
