import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import {
  Alert,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  styled,
  Tab,
  Tabs,
  TabScrollButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { type IRowNode, type SortModelItem } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import {
  type AssignStopEventPayload,
  type NewOrderEventPayload,
  type UnassignStopEventPayload,
} from 'shared/types';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import ClearTextFieldButton from '../../../common/components/clear-text-field-button';
import DateDropdownPicker, {
  type DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../common/feature-flags';
import {
  DISPATCH_SERVICE_DATE_OPTION_KEY,
  UNASSIGNED_STOPS_CHANGED_SORT_MODEL_KEY,
  UNASSIGNED_STOPS_TABLE_COLUMNS_KEY,
} from '../../../common/local-storage/keys';
import DispatchMultiplayerTableComponent from '../../../common/multiplayer/components/dispatch-multiplayer-table-component';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMe from '../../../common/react-hooks/use-me';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  type DispatchTableColorFragment,
  DispatchTableField,
  FilterOperator,
  FilterViewPage,
  type FindStopsFiltersInput,
  GetSavedFilterViewsDocument,
  type SavedFilterViewFragment,
  type StopSortDirection,
  type StopsQueryVariables,
  useCreateSavedFilterViewMutation,
  useDeleteSavedFilterViewMutation,
  useGetSavedFilterViewsQuery,
  useUpdateSavedFilterViewMutation,
} from '../../../generated/graphql';
import useDispatchStore from '../../dispatch/dispatch-store';
import useFetchRoutes from '../../dispatch/hooks/use-fetch-routes';
import useFetchStops from '../../dispatch/hooks/use-fetch-stops';
import useStopColumns from '../../dispatch/hooks/use-stop-columns';
import StopsOnRoutesFoundDialog from '../../dispatch/routes/components/modals/stops-on-routes-found-dialog';
import RouteAssignMultipleStopsButton from '../../dispatch/routes/components/route-assign-multiple-stops-button';
import type { StopsTableElement } from '../../dispatch/types/stops';
import {
  isRecurringRunHeaderFragmentNode,
  isStopFragment,
  isStopFragmentNode,
} from '../../dispatch/utils';
import { type FilterModel } from '../../orders/components/enums/order-filters';
import CreateNewSavedViewModal from '../../saved-filter-views/components/create-new-saved-view-modal';
import EditSavedViewModal from '../../saved-filter-views/components/edit-saved-view-modal';
import { EditSavedViewTabButtonAndMenu } from '../../saved-filter-views/components/edit-saved-view-tab-button-menu';
import useFilterStore from '../filter-store';
import { useNewTableFunctionsFeatureFlag } from '../use-new-table-functions-feature-flag';
import {
  changesBetweenTableFields,
  countChangesBetweenFilterModels,
  countChangesBetweenSortModels,
  getSortModelFromColumnState,
} from '../utils';
import ViewChangedButton from '../view-changed-button';
import { DispatchTableFilters } from './components/dispatch-table-filters';
import {
  DEFAULT_UNASSIGNED_STOP_QUERY_VARIABLES,
  DEFAULT_UNASSIGNED_STOP_TABLE_FIELDS,
  type StopFilterField,
  type StopsTab,
} from './constants';
import 'ag-grid-enterprise';
import DispatchStopsTable from './dispatch-stops-table';
import { useStopsPanelTabFunctions } from './hooks/use-stops-panel-tab-functions';
import {
  type DefaultFilterTabsConfigs,
  type DispatchSortV2,
  type DispatchTableFilterModel,
  type State,
} from './types';
import { useDispatchUnassignedStopsRowDrag } from './use-dispatch-unassigned-stops-row-drag';
import {
  addFilterFromFilterModelToResult,
  getAGGridFilterModel,
  getDispatchColumnSortStates,
  getDispatchSortV2,
  getDispatchTableFilterModel,
  isLegacyFilterModel,
  migrateLegacyFilterModel,
} from './utils';

const MyTabScrollButton = styled(TabScrollButton)(({ theme }) => ({
  width: 28,
  overflow: 'hidden',
  transition: theme.transitions.create('width', {
    duration: '0.5s',
  }),
  '&.Mui-disabled': {
    width: 0,
  },
}));

type UnassignedStopsTableProps = {
  readonly pageType: FilterViewPage;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<StopsTab>;
  readonly onDataChanged?: (variables: StopsQueryVariables) => void;
  readonly selectedTerminalUuid: string | undefined;
  readonly shouldAllowSavedFilterViews?: boolean;
  readonly dispatchTableColors: DispatchTableColorFragment[] | undefined;
  readonly showFilterMenu?: boolean;
  readonly isMapView?: boolean;
  readonly sequentiallyLoadRouteCardsAfterLoad?: boolean;
};

/**
 *
 * @param pageType
 * @param defaultFilterTabsConfigs
 * @param selectedTerminalUuid
 * @param shouldAllowSavedFilterViews
 * @param onDataChanged
 * @param dispatchTableColors
 * @param showFilterMenu whether to show the filters in dropdown menu or expanded
 * @param sequentiallyLoadRouteCardsAfterLoad
 * @constructor
 */
const DispatchStopsControlPanel = ({
  pageType,
  defaultFilterTabsConfigs,
  selectedTerminalUuid,
  onDataChanged,
  shouldAllowSavedFilterViews = false,
  dispatchTableColors,
  showFilterMenu = false,
  isMapView = false,
  sequentiallyLoadRouteCardsAfterLoad = false,
}: UnassignedStopsTableProps) => {
  const { dispatchTableFields: userDispatchTableFields } = useTableFields();
  const { userUuid, companyUuid } = useMe();
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);
  const ffStopsTableCountsForCurrentTab = useFeatureFlag(
    FeatureFlag.FF_STOPS_TABLE_COUNTS_FOR_CURRENT_TAB,
  );
  const gridRef = useRef<AgGridReact<StopsTableElement>>(null);
  const [
    planningDate,
    showMap,
    openedOrderUuid,
    setOpenedOrderUuid,
    selectedStopUuids,
    setSelectedStopUuids,
    shouldRefreshGrid,
    setShouldRefreshGrid,
  ] = useDispatchStore(
    (state) => [
      state.planningDate,
      state.showMap,
      state.openedOrderUuid,
      state.setOpenedOrderUuid,
      state.selectedStopUuids,
      state.setSelectedStopUuids,
      state.shouldRefreshGrid,
      state.setShouldRefreshGrid,
    ],
    shallow,
  );
  const [rememberedFilters, setRememberedTabs] = useFilterStore(
    (state) => [state.filters, state.setTabs],
    shallow,
  );

  const [
    /**
     * This is non-nil if the current view has unsaved changes to the sort model
     * When loading the page, we first check `changedSortModel` for local changes
     * that should override the sort model saved in the DB, otherwise use the DB model
     */
    changedSortModel,
    setChangedSortModel,
  ] = useLocalStorageState<DispatchSortV2[] | null>(
    `${UNASSIGNED_STOPS_CHANGED_SORT_MODEL_KEY}-${pageType}`,
    { defaultValue: [] },
  );

  const [changedDispatchTableFields, setChangedDispatchTableFields] =
    useLocalStorageState<DispatchTableField[] | null>(
      UNASSIGNED_STOPS_TABLE_COLUMNS_KEY,
      { defaultValue: null },
    );

  const columnDefinitions = useStopColumns({
    dispatchTableColors,
    setOpenedOrderUuid,
  });
  const [, setState, stateRef] = useStateRef<State>({
    searchText: '',
    serviceDateOption: initialDateOption,
    terminalUuid: undefined,
    stopsTab: defaultFilterTabsConfigs.defaultTab,
    customFilterModelJson: {},
    dispatchTableFilterModel: {},
    customSortModelJson: [],
    dispatchTableFields:
      (ffEnableNewTableFunctions
        ? changedDispatchTableFields
        : userDispatchTableFields) ?? DEFAULT_UNASSIGNED_STOP_TABLE_FIELDS,
    currentCursor: null,
    currentFilterViewName: null,
    currentFilterViewUuid: null,
    columnDefs: columnDefinitions,
    numberNewStops: 0,
    topScrollPosition: 0,
    totalCount: undefined,
    stopsOnRoutes: [],
    datasourceVersionId: 0,
    firstRender: true,
  });

  const { fetchStops } = useFetchStops();
  const { fetchRoute } = useFetchRoutes();
  const { getNumberOfStopsForCurrentTab, getNumberOfStopsForAllTabs } =
    useStopsPanelTabFunctions({
      stateRef,
      setState,
      defaultFilterTabsConfigs,
      selectedTerminalUuid,
    });

  const [orderUuidToRefetch, setOrderUuidToRefetch] = useState<string>();
  const [columnToolbarOpened, setColumnToolbarOpened] =
    useState<boolean>(false);

  const { addRouteDropZones } = useDispatchUnassignedStopsRowDrag();

  const { dispatchTableFields: stateRefCurrentDispatchTableFields } =
    stateRef.current;
  const dispatchTableFields = useMemo(() => {
    if (ffEnableNewTableFunctions) {
      return changedDispatchTableFields ?? stateRefCurrentDispatchTableFields;
    }
    return stateRefCurrentDispatchTableFields;
  }, [
    ffEnableNewTableFunctions,
    changedDispatchTableFields,
    stateRefCurrentDispatchTableFields,
  ]);

  const [serviceDateOption, setServiceDateOption] =
    useLocalStorageState<DateOption>(
      `${DISPATCH_SERVICE_DATE_OPTION_KEY}${isMapView ? '_map' : ''}`,
      {
        defaultValue: initialDateOption,
      },
    );

  const [filterViewSaveSuccessAlert, setFilterViewSaveSuccessAlert] =
    useState(false);
  const [filterViewSaveFailedAlert, setFilterViewSaveFailedAlert] =
    useState(false);
  const [showCreateNewSavedViewModal, setShowCreateNewSavedViewModal] =
    useState(false);
  const [showEditSavedViewModal, setShowEditSavedViewModal] = useState(false);
  const [newViewIsFromScratch, setNewViewIsFromScratch] = useState(true);

  /// /////////////////////////////////////////////////////////////////////////////
  // SAVED VIEW STATE HANDLERS
  /// /////////////////////////////////////////////////////////////////////////////

  const [numFiltersChanged, setNumFiltersChanged] = useState(0);
  const [numSortsChanged, setNumSortsChanged] = useState(0);
  const [numColumnsChanged, setNumColumnsChanged] = useState(0);

  const computeNumFiltersChanged = useCallback(
    (filterModel: DispatchTableFilterModel | FilterModel | undefined) => {
      const currentFilterModel = ffEnableNewTableFunctions
        ? stateRef.current.dispatchTableFilterModel
        : stateRef.current.customFilterModelJson;

      const changesFromCurrentFilterModel = countChangesBetweenFilterModels(
        currentFilterModel,
        filterModel,
      );

      setNumFiltersChanged(changesFromCurrentFilterModel);
    },
    [ffEnableNewTableFunctions, stateRef],
  );

  const computeNumSortsChanged = useCallback(
    (sortModel: DispatchSortV2[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentSortModel = countChangesBetweenSortModels(
        stateRef.current.customSortModelJson,
        sortModel ?? [],
      );
      setNumSortsChanged(changesFromCurrentSortModel);
      return changesFromCurrentSortModel;
    },
    [stateRef, ffEnableNewTableFunctions],
  );

  const computeNumColumnsChanged = useCallback(
    (tableFields: DispatchTableField[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentTableFields = changesBetweenTableFields(
        stateRef.current.dispatchTableFields,
        tableFields,
      );

      setNumColumnsChanged(changesFromCurrentTableFields);

      if (changesFromCurrentTableFields > 0) {
        setChangedDispatchTableFields(tableFields);
      } else {
        setChangedDispatchTableFields(null);
      }

      return changesFromCurrentTableFields;
    },
    [stateRef, ffEnableNewTableFunctions, setChangedDispatchTableFields],
  );

  const applyFilterModel = useCallback(
    (dispatchTableFilterModel: DispatchTableFilterModel | null) => {
      const agGridFilterModel = isNil(dispatchTableFilterModel)
        ? {}
        : getAGGridFilterModel(dispatchTableFilterModel);
      gridRef.current?.api?.setFilterModel(agGridFilterModel);
      computeNumFiltersChanged(dispatchTableFilterModel ?? undefined);
    },
    [computeNumFiltersChanged],
  );

  const applySortModel = useCallback(
    (sortModel: DispatchSortV2[]) => {
      gridRef.current?.columnApi?.applyColumnState({
        state: getDispatchColumnSortStates(sortModel),
        defaultState: { sort: null },
      });
      computeNumSortsChanged(sortModel);
    },
    [computeNumSortsChanged],
  );

  const applyTableColumns = (tableFields: DispatchTableField[]) => {
    if (!ffEnableNewTableFunctions) {
      return;
    }

    populateTableFromConfig(tableFields);
    computeNumColumnsChanged(tableFields);
  };

  const onChangeTab = ({
    newTab,
    newView,
    shouldApplyFilter = true,
    shouldApplySort = true,
    shouldApplyColumns = true,
  }: {
    newTab: string | StopsTab;
    newView: Omit<SavedFilterViewFragment, 'user'> | undefined;
    shouldApplyFilter?: boolean;
    shouldApplySort?: boolean;
    shouldApplyColumns?: boolean;
  }) => {
    setChangedDispatchTableFields(null);
    if (shouldAllowSavedFilterViews && !isNil(newView)) {
      let newFilterModel = JSON.parse(newView.filterModelJson);
      if (!isNil(newFilterModel) && isLegacyFilterModel(newFilterModel)) {
        // Even if the feature flag is on, existing views in the DB
        // or local storage might not have been migrated
        newFilterModel = migrateLegacyFilterModel(newFilterModel);
      }
      setState((prevState) => {
        return {
          ...prevState,
          stopsTab: newTab,
          currentCursor: null,
          ...(ffEnableNewTableFunctions
            ? {
                dispatchTableFilterModel:
                  newFilterModel as DispatchTableFilterModel,
              }
            : { customFilterModelJson: newFilterModel }),
          customSortModelJson:
            (newView.sortModelJson as DispatchSortV2[] | null | undefined) ??
            [],
          dispatchTableFields: newView.dispatchTableFields,
          currentFilterViewUuid: newView.uuid,
          currentFilterViewName: newView.displayName,
        };
      });
      setRememberedTabs(JSON.stringify(newView), pageType, 'custom');

      if (shouldApplyFilter) {
        applyFilterModel(newFilterModel);
      }
      if (shouldApplySort) {
        applySortModel(
          (newView.sortModelJson as DispatchSortV2[] | null | undefined) ?? [],
        );
      }
      if (shouldApplyColumns) {
        applyTableColumns(newView.dispatchTableFields);
      }
    } else {
      const newDefaultTab = defaultFilterTabsConfigs.tabs.find(
        (tab) => tab.value === newTab,
      );
      let newFilterModel = newDefaultTab?.filterModel ?? {};
      if (!isNil(newFilterModel) && isLegacyFilterModel(newFilterModel)) {
        // Even if the feature flag is on, existing views in the DB
        // or local storage might not have been migrated
        newFilterModel = migrateLegacyFilterModel(newFilterModel);
      }

      setState((prevState) => {
        return {
          ...prevState,
          stopsTab: newTab as StopsTab,
          currentCursor: null,
          ...(ffEnableNewTableFunctions
            ? {
                dispatchTableFilterModel:
                  (newDefaultTab?.filterModel as DispatchTableFilterModel) ??
                  {},
              }
            : { customFilterModelJson: newFilterModel }),
          customSortModelJson: newDefaultTab?.sortModel ?? [],
          dispatchTableFields: userDispatchTableFields ?? [],
          currentFilterViewUuid: null,
          currentFilterViewName: null,
        };
      });
      setRememberedTabs(JSON.stringify(newDefaultTab), pageType, 'default');

      if (shouldApplyFilter) {
        applyFilterModel(newFilterModel);
      }
      if (shouldApplySort) {
        applySortModel(newDefaultTab?.sortModel ?? []);
      }
      if (shouldApplyColumns && !isNil(userDispatchTableFields)) {
        applyTableColumns(userDispatchTableFields);
      }
    }

    if (ffStopsTableCountsForCurrentTab) {
      getNumberOfStopsForCurrentTab();
    }

    refreshGrid({});
  };

  /**
   * We only want to update the user's personal list of dispatch table fields
   * if the current view is a default view.
   */
  const shouldUseUserTableFields =
    !ffEnableNewTableFunctions || isNil(stateRef.current.currentFilterViewUuid);

  // This is a hack to account for userDispatchTableFields being undefined
  // while it's loading, since that loading may not finish before the
  // saved views finish loading and we initially call onChangeTab.
  // TODO(Elliot): delete once saved view state is cleaned up
  useEffect(() => {
    if (!shouldUseUserTableFields) {
      // If we're using a custom set of columns for a saved view,
      // don't use the user's personal list of order table fields.
      return;
    }
    if (isEmpty(dispatchTableFields) && !isNil(userDispatchTableFields)) {
      setState((prevState) => {
        return {
          ...prevState,
          dispatchTableFields: userDispatchTableFields,
        };
      });
    }
  }, [
    shouldUseUserTableFields,
    ffEnableNewTableFunctions,
    dispatchTableFields,
    userDispatchTableFields,
    setState,
  ]);

  /// /////////////////////////////////////////////////////////////////////////////
  // QUERIES
  /// /////////////////////////////////////////////////////////////////////////////

  const { data: savedFilterViewsData } = useGetSavedFilterViewsQuery({
    variables: { pageType },
    onCompleted: (data) => {
      const savedViewTab: SavedFilterViewFragment | undefined =
        data.getSavedFilterViews.find(
          (view) => view.uuid === stateRef.current.stopsTab,
        );

      // Read unsaved filter model changes from local storage.
      // We wait to load the saved filter view data first so that we can
      // avoid overwriting the local storage changes when the view data loads
      const rememberedFilter = rememberedFilters[pageType];
      let changedFilterModel = isNil(rememberedFilter)
        ? undefined
        : (JSON.parse(rememberedFilter) as FilterModel);

      onChangeTab({
        newTab: stateRef.current.stopsTab,
        newView: savedViewTab,
        // Don't apply the view's default filter model if there are local
        // changes, since we'll apply that changed filter model below
        shouldApplyFilter: isEmpty(changedFilterModel),
        shouldApplySort: isEmpty(changedSortModel),
        shouldApplyColumns: isEmpty(changedDispatchTableFields),
      });

      if (
        !isNil(changedFilterModel) &&
        isLegacyFilterModel(changedFilterModel)
      ) {
        // Even if the feature flag is on, existing views in the DB
        // or local storage might not have been migrated
        changedFilterModel = migrateLegacyFilterModel(changedFilterModel);
      }

      if (!isEmpty(changedFilterModel)) {
        applyFilterModel(changedFilterModel);
      }

      if (!isNil(changedSortModel) && !isEmpty(changedSortModel)) {
        applySortModel(changedSortModel);
      }

      if (
        !isNil(changedDispatchTableFields) &&
        !isEmpty(changedDispatchTableFields)
      ) {
        applyTableColumns(changedDispatchTableFields);
      }
    },
  });

  const [updateSavedFilterViewMutation] = useUpdateSavedFilterViewMutation({
    refetchQueries: [GetSavedFilterViewsDocument],
  });
  const [createSavedFilterViewMutation] = useCreateSavedFilterViewMutation({
    refetchQueries: [GetSavedFilterViewsDocument],
  });
  const [deleteSavedFilterViewMutation] = useDeleteSavedFilterViewMutation({
    refetchQueries: [GetSavedFilterViewsDocument],
  });

  const removeEmptyRecurringRunHeaders = () => {
    const renderedNodes = gridRef.current?.api.getRenderedNodes() ?? [];

    for (let i = 0; i < renderedNodes.length; ++i) {
      const node = renderedNodes[i];
      if (isRecurringRunHeaderFragmentNode(node)) {
        let hasChildStop = false;
        for (
          let childI = i + 1;
          childI < renderedNodes.length && !hasChildStop;
          ++childI
        ) {
          const childNode = renderedNodes[childI];
          hasChildStop =
            isStopFragmentNode(childNode) &&
            childNode.data.recurringRunUuid === node.data.uuid;
        }
        if (!hasChildStop) {
          gridRef.current?.api.applyServerSideTransaction({
            remove: [node.data],
          });
        }
      }
    }
  };

  const { stopsTab: currentStopsTab } = stateRef.current;
  const currentDefaultTab = useMemo(
    () =>
      defaultFilterTabsConfigs.tabs.find(
        (tab) => tab.value === currentStopsTab,
      ),
    [defaultFilterTabsConfigs, currentStopsTab],
  );

  const planningDateRef = useRef(planningDate);

  const refreshGrid = useCallback(
    ({ shouldDeselect = false }: { shouldDeselect?: boolean }) => {
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
          totalCount: undefined,
          datasourceVersionId: prevState.datasourceVersionId + 1,
        };
      });
      if (!isNil(gridRef.current?.api)) {
        gridRef.current?.api.paginationGoToFirstPage();
        gridRef.current?.api.refreshServerSide({ purge: true });
        if (shouldDeselect) {
          gridRef.current?.api.deselectAll();
        }
      }
    },
    [setState],
  );

  useEffect(() => {
    planningDateRef.current = planningDate;
    refreshGrid({});
  }, [planningDate, refreshGrid]);

  const getFetchStopsVariables = ({
    filterModel,
    sortModel,
    includeCount = false,
  }: {
    filterModel: FilterModel;
    sortModel?: SortModelItem[];
    includeCount?: boolean;
  }): StopsQueryVariables => {
    const filters: FindStopsFiltersInput[] = [];

    const filterModelFromPreset = currentDefaultTab?.filterModel;
    if (!isNil(filterModelFromPreset)) {
      let filterSet: FindStopsFiltersInput = {};
      for (const [key, value] of Object.entries(filterModelFromPreset)) {
        filterSet = addFilterFromFilterModelToResult(
          filterSet,
          key as StopFilterField,
          value,
          planningDateRef,
        );
      }
      filters.push(filterSet);
    }

    if (!isEmpty(filterModel)) {
      let conjuctionFilterSet: FindStopsFiltersInput = {};
      let disjunctionFilterSet: FindStopsFiltersInput = {};
      for (const [key, value] of Object.entries(filterModel)) {
        if (
          'filterOperator' in value &&
          value.filterOperator === FilterOperator.Or
        ) {
          disjunctionFilterSet = addFilterFromFilterModelToResult(
            disjunctionFilterSet,
            key as StopFilterField,
            value,
            planningDateRef,
          );
        } else {
          conjuctionFilterSet = addFilterFromFilterModelToResult(
            conjuctionFilterSet,
            key as StopFilterField,
            value,
            planningDateRef,
          );
        }
      }
      if (!isEmpty(conjuctionFilterSet)) {
        conjuctionFilterSet.filterOperator = FilterOperator.And;
        filters.push(conjuctionFilterSet);
      }
      if (!isEmpty(disjunctionFilterSet)) {
        disjunctionFilterSet.filterOperator = FilterOperator.Or;
        filters.push(disjunctionFilterSet);
      }
    }

    let sorts: StopsQueryVariables['sorts'];
    if (!isNil(sortModel)) {
      sorts = filterNotNil(
        sortModel.map((sort) =>
          Object.values(DispatchTableField).includes(
            sort.colId as DispatchTableField,
          )
            ? {
                sortBy: sort.colId as DispatchTableField,
                sortDirection: sort.sort as StopSortDirection,
              }
            : null,
        ),
      );
    }

    const serviceDateFilters = {
      filterOperator: FilterOperator.And,
      startFilterValue: stateRef.current.serviceDateOption.startDate,
      endFilterValue: stateRef.current.serviceDateOption.endDate,
    };

    return {
      ...DEFAULT_UNASSIGNED_STOP_QUERY_VARIABLES,
      serviceDateFilters,
      filters,
      sorts,
      includeCount,
      searchText: stateRef.current.searchText?.trim(),
      terminalUuid: stateRef.current.terminalUuid,
    };
  };

  const populateTableFromConfig = (newFields: DispatchTableField[]) => {
    const updatedColumnDefs = stateRef.current.columnDefs.map((columnDef) => ({
      ...columnDef,
      hide:
        columnDef.headerCheckboxSelection !== true &&
        columnDef.field !== 'Route',
    }));
    const checkboxColumn = updatedColumnDefs.find(
      (column) => column.headerCheckboxSelection === true,
    );
    const routeEntryColumn = updatedColumnDefs.find(
      (column) => column.field === 'Route',
    );
    if (isNil(checkboxColumn) || isNil(routeEntryColumn) || isNil(newFields)) {
      return;
    }
    const tableColumns = [
      checkboxColumn,
      routeEntryColumn,
      ...filterNotNil(
        newFields.map((field) => {
          const prevIndex = stateRef.current.columnDefs.findIndex(
            (def) =>
              sentenceCase(def.field ?? '').toLowerCase() ===
              sentenceCase(field).toLowerCase(),
          );
          const updatedColumnDefForField = updatedColumnDefs[prevIndex];
          if (!isNil(updatedColumnDefForField)) {
            updatedColumnDefForField.hide = false;
          }
          return updatedColumnDefForField;
        }),
      ),
    ];
    const nonTableColumns = updatedColumnDefs.filter(
      (columnDef) =>
        !tableColumns.some((tableCol) => tableCol?.field === columnDef.field),
    );
    setState((prevState) => {
      return {
        ...prevState,
        columnDefs: [...tableColumns, ...nonTableColumns],
      };
    });
  };

  useEffect(() => {
    if (!isNil(dispatchTableFields)) {
      populateTableFromConfig(dispatchTableFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchTableFields]);

  /**
   * Reset stateRef.current.columnDefs if columnDefinitions changes for some reason
   * We need to immediately call populateTableFromConfig after to e.g. update `hide`
   * for each column def based on `dispatchTableFields`
   */
  useEffect(() => {
    setState((prevState) => ({ ...prevState, columnDefs: columnDefinitions }));
    populateTableFromConfig(dispatchTableFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefinitions, setState]);

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        terminalUuid: selectedTerminalUuid,
      };
    });
    if (ffStopsTableCountsForCurrentTab) {
      getNumberOfStopsForCurrentTab();
    }
    refreshGrid({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTerminalUuid,
    stateRef.current.serviceDateOption,
    ffStopsTableCountsForCurrentTab,
  ]);

  useEffect(() => {
    if (shouldRefreshGrid) {
      if (ffStopsTableCountsForCurrentTab) {
        getNumberOfStopsForCurrentTab();
      } else {
        getNumberOfStopsForAllTabs();
      }
      refreshGrid({
        shouldDeselect: true,
      });
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid, ffStopsTableCountsForCurrentTab]);

  useEffect(() => {
    if (isNil(openedOrderUuid) && !isNil(orderUuidToRefetch)) {
      const variables = getFetchStopsVariables({
        filterModel: gridRef.current?.api.getFilterModel() ?? {},
        sortModel: [],
      });
      fetchStops({
        variables: { ...variables, orderUuid: orderUuidToRefetch },
        first: 5,
        useCache: false,
      }).then(({ stops }) => {
        const nodes = gridRef.current?.api.getRenderedNodes();
        const updatedStops = stops.edges.map((edge) => edge.node);
        gridRef.current?.api.applyServerSideTransaction({
          update: updatedStops,
          remove: nodes
            ?.filter(
              ({ data }) =>
                isStopFragment(data) &&
                data.order?.uuid === orderUuidToRefetch &&
                !updatedStops.some((updatedElem) =>
                  isStopFragment(updatedElem)
                    ? updatedElem.stop.uuid === data.stop.uuid
                    : false,
                ),
            )
            .map((node) => node.data),
        });
        removeEmptyRecurringRunHeaders();
        gridRef.current?.api.redrawRows();
      });
    }
    setOrderUuidToRefetch(openedOrderUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrderUuid]);

  useEffect(() => {
    if (!showMap) {
      setTimeout(() => {
        if (stateRef.current.topScrollPosition <= 100) {
          setState((prevState) => ({ ...prevState, numberNewStops: 0 }));
        }
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.numberNewStops, showMap, setState]);

  /// /////////////////////////////////////////////////////////////////////////////
  // MULTIPLAYER EVENTS
  /// /////////////////////////////////////////////////////////////////////////////

  const handleAssignStop = (payload: AssignStopEventPayload) => {
    const variables = getFetchStopsVariables({
      filterModel: gridRef.current?.api.getFilterModel() ?? {},
      sortModel: getSortModelFromColumnState(
        gridRef.current?.columnApi.getColumnState(),
      ),
    });
    onDataChanged?.(variables);
    const shouldAssign = gridRef.current?.api
      .getRenderedNodes()
      .some(
        ({ data }) =>
          isStopFragment(data) && data.stop.uuid === payload.stopUuid,
      );
    if (shouldAssign ?? false) {
      gridRef.current?.api.applyServerSideTransaction({
        remove: [
          {
            // This field is needed so that Ag Grid can identify the right row using getRowId.
            __typename: 'CachedStopEntity',
            stop: {
              uuid: payload.stopUuid,
            },
          },
        ],
      });
      removeEmptyRecurringRunHeaders();
    }
    setSelectedStopUuids(
      selectedStopUuids.filter((stopUuid) => stopUuid !== payload.stopUuid),
    );
    fetchRoute(payload.routeUuid);
  };

  const handleUnassignStop = async (payload: UnassignStopEventPayload) => {
    const variables = getFetchStopsVariables({
      filterModel: gridRef.current?.api.getFilterModel() ?? {},
      sortModel: getSortModelFromColumnState(
        gridRef.current?.columnApi.getColumnState(),
      ),
    });
    onDataChanged?.(variables);
    const data = await fetchStops({
      variables: {
        ...variables,
        uuid: payload.stopUuid,
        terminalUuid: selectedTerminalUuid,
      },
      first: 1,
      useCache: false,
    });
    const stop = data.stops.edges.map((edge) => edge.node)[0];
    if (!isNil(stop)) {
      setState((prevState) => ({
        ...prevState,
        numberNewStops: prevState.numberNewStops + 1,
      }));
      let addIndex = 0;
      if (isStopFragment(stop) && !isNil(stop.recurringRunUuid)) {
        const recurringRunHeader = gridRef.current?.api
          .getRenderedNodes()
          .find(
            (node) =>
              isRecurringRunHeaderFragmentNode(node) &&
              node.data.uuid === stop.recurringRunUuid,
          );
        if (!isNil(recurringRunHeader) && !isNil(recurringRunHeader.rowIndex)) {
          addIndex = recurringRunHeader.rowIndex + 1;
        }
      }
      gridRef.current?.api.applyServerSideTransaction({
        addIndex,
        add: [stop],
      });
    }
    fetchRoute(payload.routeUuid);
  };

  const handleNewOrder = async (payload: NewOrderEventPayload) => {
    const existingStopUuids = filterNotNil(
      gridRef.current?.api
        .getRenderedNodes()
        .map(({ data }: IRowNode<StopsTableElement>) =>
          isStopFragment(data) ? data.stop.uuid : null,
        ) ?? [],
    );
    const variables = getFetchStopsVariables({
      filterModel: gridRef.current?.api.getFilterModel() ?? {},
      sortModel: getSortModelFromColumnState(
        gridRef.current?.columnApi.getColumnState(),
      ),
    });
    onDataChanged?.(variables);
    const data = await fetchStops({
      variables: {
        ...variables,
        orderUuid: payload.orderUuid,
        terminalUuid: selectedTerminalUuid,
      },
      first: 10,
      useCache: false,
    });
    const stops = data.stops.edges
      .map((edge) => edge.node)
      .filter(isStopFragment);
    if (!isEmpty(stops)) {
      for (const stop of stops) {
        if (existingStopUuids.includes(stop.stop.uuid)) {
          gridRef.current?.api.applyServerSideTransaction({
            update: [stop],
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            numberNewStops: prevState.numberNewStops + stops.length,
          }));
          gridRef.current?.api.applyServerSideTransaction({
            addIndex: 0,
            add: [stop],
          });
        }
      }
      gridRef.current?.api.redrawRows();
    }
  };

  /// /////////////////////////////////////////////////////////////////////////////
  // INDIVIDUAL FILTER HANDLERS
  /// /////////////////////////////////////////////////////////////////////////////
  const destroyFilter = (field: StopFilterField) => {
    gridRef.current?.api.destroyFilter(field);
  };

  const handleDeleteFilter = async (field: StopFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };
  const handleClickSelectedFilter = async (field: StopFilterField) => {
    // open the toolpanel and show that filter
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      stateRef.current.columnDefs.find((colDef) => colDef.field === field) ??
        {},
    ]);
    gridRef.current?.api.openToolPanel('filters');
  };

  const handleToggleColumnsOpen = () => {
    const agColumnPanelElements = document.querySelectorAll('.ag-column-panel');
    if (agColumnPanelElements.length !== 1) {
      return;
    }

    const agColumnPanelElement = agColumnPanelElements[0];
    if (agColumnPanelElement === undefined) return;

    if (columnToolbarOpened) {
      agColumnPanelElement.parentElement?.classList.add('ag-hidden');
      agColumnPanelElement.setAttribute('aria-hidden', 'true');
    } else {
      agColumnPanelElement.parentElement?.classList.remove('ag-hidden');
      agColumnPanelElement.setAttribute('aria-hidden', 'false');
    }
    setColumnToolbarOpened(!columnToolbarOpened);
  };

  useEffect(() => {
    const gridApi = gridRef.current?.api;
    if (!isNil(gridApi) && isMapView) {
      setServiceDateOption({
        filterType: DatePickerFilterType.Range,
        value: undefined,
        startDate: planningDate?.startOf('day').toDate(),
        endDate: planningDate?.endOf('day').toDate(),
      });
    }
  }, [planningDate, setServiceDateOption, isMapView]);

  /// /////////////////////////////////////////////////////////////////////////////
  // FILTER VIEW HANDLERS
  /// ////////////////////////////////////////////////////////////////////////////

  const showCreateFilterViewModal = async (viewIsFromScratch: boolean) => {
    setNewViewIsFromScratch(viewIsFromScratch);
    setShowCreateNewSavedViewModal(true);
  };

  const handleCreateNewFilterView = async (
    displayName: string,
    viewIsFromScratch: boolean,
  ) => {
    let filterModel: DispatchTableFilterModel | FilterModel;
    if (ffEnableNewTableFunctions) {
      filterModel = getDispatchTableFilterModel(
        viewIsFromScratch ? null : gridRef.current,
      );
    } else {
      filterModel = viewIsFromScratch
        ? {}
        : (gridRef.current?.api.getFilterModel() ?? {});
    }
    const dispatchSortV2 = getDispatchSortV2(gridRef.current);
    try {
      const newViewInput = {
        displayName,
        savedViewPageType: pageType,
        filterModelJson: JSON.stringify(filterModel ?? {}),
        sortModelJson: ffEnableNewTableFunctions ? (dispatchSortV2 ?? []) : [],
        dispatchTableFields: ffEnableNewTableFunctions
          ? dispatchTableFields
          : undefined,
        orderTableFields: [],
      };
      const createView = await createSavedFilterViewMutation({
        variables: {
          createSavedFilterViewInput: newViewInput,
        },
      });
      const newUuid = createView.data?.createSavedFilterView.uuid;
      if (isNil(newUuid)) {
        throw new Error('[create new filter view] no UUID returned');
      }
      setNumFiltersChanged(0);
      setNumSortsChanged(0);
      setNumColumnsChanged(0);
      setChangedSortModel(null);
      setChangedDispatchTableFields(null);
      onChangeTab({
        newTab: newUuid,
        newView: {
          uuid: newUuid,
          ...newViewInput,
          dispatchTableFields: newViewInput.dispatchTableFields ?? [],
          isShared: false,
        },
      });
      setFilterViewSaveSuccessAlert(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating filter view', error);
      setFilterViewSaveFailedAlert(true);
    }
  };

  const handleEditFilterView = async (displayName: string) => {
    try {
      if (isNil(stateRef.current.currentFilterViewUuid)) {
        throw new Error(
          '[saving filter view] trying to save to null filter view UUID',
        );
      }
      await updateSavedFilterViewMutation({
        variables: {
          updateSavedFilterViewInput: {
            uuid: stateRef.current.currentFilterViewUuid,
            displayName,
          },
        },
      });
      setState((prevState) => {
        return {
          ...prevState,
          currentFilterViewName: displayName,
          currentCursor: null,
        };
      });
      setFilterViewSaveSuccessAlert(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving filter view', error);
      setFilterViewSaveFailedAlert(true);
    }
  };

  const handleSetIsViewShared = useCallback(
    async (isShared: boolean) => {
      try {
        if (
          isNil(stateRef?.current) ||
          isNil(stateRef.current.currentFilterViewUuid)
        ) {
          throw new Error(
            '[saving filter view] trying to save to null filter view UUID',
          );
        }
        await updateSavedFilterViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid: stateRef.current.currentFilterViewUuid,
              isShared,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setState((prevState) => {
          return {
            ...prevState,
            currentCursor: null,
          };
        });
        const currentViewDisplayName =
          savedFilterViewsData?.getSavedFilterViews.find(
            (view) => view.uuid === stateRef.current.currentFilterViewUuid,
          )?.displayName;
        if (!isNilOrEmptyString(currentViewDisplayName)) {
          setFilterViewSaveSuccessAlert(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving filter view', error);
        setFilterViewSaveFailedAlert(true);
      }
    },
    [
      setState,
      stateRef,
      savedFilterViewsData?.getSavedFilterViews,
      updateSavedFilterViewMutation,
      setFilterViewSaveSuccessAlert,
      setFilterViewSaveFailedAlert,
    ],
  );

  const handleDeleteFilterView = async () => {
    try {
      if (isNil(stateRef.current.currentFilterViewUuid)) {
        throw new Error(
          '[saving filter view] trying to save to null filter view UUID',
        );
      }
      await deleteSavedFilterViewMutation({
        variables: {
          uuid: stateRef.current.currentFilterViewUuid,
        },
      });
      setNumFiltersChanged(0);
      setNumSortsChanged(0);
      setNumColumnsChanged(0);
      setChangedSortModel(null);
      setChangedDispatchTableFields(null);
      onChangeTab({
        newTab: defaultFilterTabsConfigs.defaultTab,
        newView: undefined,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting filter view', error);
    }
  };

  const handleResetFilterEdits = () => {
    applyFilterModel(
      ffEnableNewTableFunctions
        ? stateRef.current.dispatchTableFilterModel
        : stateRef.current.customFilterModelJson,
    );
    applySortModel(stateRef.current.customSortModelJson);
    applyTableColumns(stateRef.current.dispatchTableFields);
  };

  const handleSaveFiltersToExistingFilterView = async () => {
    const filterModel = ffEnableNewTableFunctions
      ? getDispatchTableFilterModel(gridRef.current)
      : gridRef.current?.api.getFilterModel();
    const dispatchSortV2 = getDispatchSortV2(gridRef.current);
    try {
      if (isNil(stateRef.current.currentFilterViewUuid)) {
        throw new Error(
          '[saving filter view] trying to save to null filter view UUID',
        );
      }
      await updateSavedFilterViewMutation({
        variables: {
          updateSavedFilterViewInput: {
            uuid: stateRef.current.currentFilterViewUuid,
            filterModelJson: JSON.stringify(filterModel ?? {}),
            sortModelJson: ffEnableNewTableFunctions
              ? (dispatchSortV2 ?? [])
              : [],
            dispatchTableFields: ffEnableNewTableFunctions
              ? dispatchTableFields
              : undefined,
          },
        },
      });
      setNumFiltersChanged(0);
      setNumSortsChanged(0);
      setNumColumnsChanged(0);
      setChangedSortModel(null);
      setChangedDispatchTableFields(null);
      setState((prevState) => {
        return {
          ...prevState,
          ...(ffEnableNewTableFunctions
            ? {
                dispatchTableFilterModel:
                  (filterModel as DispatchTableFilterModel) ?? {},
              }
            : { customFilterModelJson: filterModel ?? {} }),
          dispatchTableFields: ffEnableNewTableFunctions
            ? dispatchTableFields
            : [],
          customSortModelJson: dispatchSortV2 ?? [],
        };
      });
      setFilterViewSaveSuccessAlert(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving filter view', error);
      setFilterViewSaveFailedAlert(true);
    }
  };

  const handleSearch = () => {
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid({});
  };

  const handleSearchChange = (newSearch: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        searchText: newSearch,
        currentCursor: null,
      };
    });
  };

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        serviceDateOption,
      };
    });
  }, [serviceDateOption, setState]);

  /// /////////////////////////////////////////////////////////////////////////////

  return (
    <Stack height="100%">
      {!isNil(companyUuid) && (
        <DispatchMultiplayerTableComponent
          // useChannel needs to be re-mounted
          key={`${planningDate}${selectedTerminalUuid}`}
          companyUuid={companyUuid}
          handleAssignStop={handleAssignStop}
          handleUnassignStop={handleUnassignStop}
          handleNewOrder={handleNewOrder}
        />
      )}
      <StopsOnRoutesFoundDialog
        stopsOnRoutes={stateRef.current.stopsOnRoutes}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            stopsOnRoutes: [],
          }));
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={filterViewSaveFailedAlert}
      >
        <Alert
          severity="error"
          onClose={() => {
            setFilterViewSaveFailedAlert(false);
          }}
        >
          Error saving filters
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={filterViewSaveSuccessAlert}
        onClose={() => {
          setFilterViewSaveSuccessAlert(false);
        }}
      >
        <Alert> Filters saved!</Alert>
      </Snackbar>
      <Stack
        direction="row"
        justifyContent="space-between"
        pr="5px"
        borderBottom="1px solid divider"
        borderColor="divider"
      >
        <Tabs
          ScrollButtonComponent={MyTabScrollButton}
          variant="scrollable"
          scrollButtons="auto"
          value={stateRef.current.stopsTab}
          onChange={(_, val) => {
            const getSavedViewTabFromVal =
              savedFilterViewsData?.getSavedFilterViews.find(
                (view) => view.uuid === val,
              );
            onChangeTab({
              newTab: val,
              newView: getSavedViewTabFromVal,
            });
          }}
        >
          {defaultFilterTabsConfigs.tabs.map((tabConfig) => {
            const tabCount =
              !isNil(stateRef.current.totalCount) &&
              tabConfig.value === stateRef.current.stopsTab
                ? `(${stateRef.current.totalCount})`
                : '';
            return (
              <Tab
                key={tabConfig.label}
                value={tabConfig.value}
                label={`${tabConfig.label} ${tabCount}`}
              />
            );
          })}
          {shouldAllowSavedFilterViews &&
            savedFilterViewsData?.getSavedFilterViews.map((view) => (
              <Tab
                key={view.uuid}
                sx={{
                  maxHeight: '10px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  minWidth: '50px',
                }}
                value={view.uuid}
                label={
                  <Stack direction="row" alignItems="center">
                    {view.displayName}
                    {view.uuid === stateRef.current.stopsTab
                      ? ` (${
                          isNil(stateRef.current.totalCount)
                            ? '-'
                            : stateRef.current.totalCount
                        })`
                      : ''}
                    {stateRef.current.stopsTab === view.uuid && (
                      <EditSavedViewTabButtonAndMenu
                        pageType={FilterViewPage.Dispatch}
                        isViewShared={view.isShared}
                        isUserViewCreator={view.user.uuid === userUuid}
                        handleClickRename={() => {
                          setShowEditSavedViewModal(true);
                        }}
                        handleSetIsViewShared={handleSetIsViewShared}
                        handleClickDelete={handleDeleteFilterView}
                      />
                    )}
                  </Stack>
                }
              />
            ))}
        </Tabs>
        {shouldAllowSavedFilterViews && (
          <Stack direction="row" alignItems="center">
            <Divider
              orientation="vertical"
              style={{ height: 30, alignSelf: 'center', borderColor: 'gray' }}
            />
            <ViewChangedButton
              numFiltersChanged={numFiltersChanged}
              numSortsChanged={numSortsChanged}
              numColumnsChanged={numColumnsChanged}
              resetView={handleResetFilterEdits}
              shouldAllowSavedViews={shouldAllowSavedFilterViews}
              saveView={handleSaveFiltersToExistingFilterView}
              showCreateViewModal={showCreateFilterViewModal}
              currentSavedViewUuid={stateRef.current.currentFilterViewUuid}
            />
            <Button
              sx={{ minWidth: 100 }}
              onClick={async () => showCreateFilterViewModal(true)}
            >
              + New View
            </Button>
            <Tooltip title="Columns">
              <IconButton color="primary" onClick={handleToggleColumnsOpen}>
                {columnToolbarOpened ? (
                  <CloseIcon sx={{ fontSize: '15px' }} />
                ) : (
                  <ViewWeekIcon sx={{ fontSize: '15px' }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" padding={1}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <TextField
            className="dispatch-search-input"
            variant="standard"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <ClearTextFieldButton
                  searchText={stateRef.current.searchText}
                  handleClearSearchText={() => {
                    handleSearchChange('');
                    handleSearch();
                  }}
                />
              ),
            }}
            value={stateRef.current.searchText}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            onChange={(e) => {
              handleSearchChange(e.target.value);
            }}
          />
          <DateDropdownPicker
            small
            allowNoLimit
            filterTitle="Service Date"
            dateOption={serviceDateOption}
            setDateOption={(option: DateOption) => {
              setServiceDateOption(option);
            }}
            showFilters={[
              DatePickerFilterType.AllSelect,
              DatePickerFilterType.DayPaginate,
              DatePickerFilterType.NextHourInput,
              DatePickerFilterType.Range,
            ]}
          />
          <DispatchTableFilters
            currentDefaultTab={currentDefaultTab}
            gridRef={gridRef}
            handleDeleteFilter={handleDeleteFilter}
            handleClickSelectedFilter={handleClickSelectedFilter}
            showMenu={showFilterMenu}
          />
        </Stack>
        <RouteAssignMultipleStopsButton />
      </Stack>
      <DispatchStopsTable
        ref={gridRef}
        pageType={pageType}
        sequentiallyLoadRouteCardsAfterLoad={
          sequentiallyLoadRouteCardsAfterLoad
        }
        setChangedSortModel={setChangedSortModel}
        stateRef={stateRef}
        setState={setState}
        defaultFilterTabsConfigs={defaultFilterTabsConfigs}
        selectedTerminalUuid={selectedTerminalUuid}
        applyTableColumns={applyTableColumns}
        getFetchStopsVariables={getFetchStopsVariables}
        refreshGrid={refreshGrid}
        computeNumSortsChanged={computeNumSortsChanged}
        computeNumFiltersChanged={computeNumFiltersChanged}
        onDataChanged={onDataChanged}
        onRowDragEnter={() => {
          addRouteDropZones({
            gridRef,
            onFinish: () => {
              removeEmptyRecurringRunHeaders();
              getNumberOfStopsForAllTabs();
            },
          });
        }}
      />
      <CreateNewSavedViewModal
        open={showCreateNewSavedViewModal}
        setOpen={setShowCreateNewSavedViewModal}
        createNewSavedView={handleCreateNewFilterView}
        viewIsFromScratch={newViewIsFromScratch}
      />
      <EditSavedViewModal
        open={showEditSavedViewModal}
        setOpen={setShowEditSavedViewModal}
        currentName={stateRef.current.currentFilterViewName ?? undefined}
        editSavedView={handleEditFilterView}
      />
    </Stack>
  );
};

export default React.memo(DispatchStopsControlPanel);
