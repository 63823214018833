import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { v4 } from 'uuid';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  type DefaultPackageSpec,
  useGetContactDefaultPackageSpec,
} from '../../../../../common/react-hooks/use-get-contact-default-package-spec';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  type CompanyConfigurationFragment,
  FulfillmentType,
  InboundMethod,
  type ShallowContactFragment,
  type StandardOrderFragmentFragment,
  StopType,
  useStandardOrderLazyQuery,
} from '../../../../../generated/graphql';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { type OrderFormFieldValues } from '../forms/types';
import {
  convertStandardOrderPayloadToFormData,
  initializeOrderToFormData,
} from '../forms/utils';
import { useOrderFormContact } from './use-order-form-contact';

const forceRecoveryToPickupAndTransferToDelivery = (
  formData: OrderFormFieldValues,
) => {
  if (formData.stops?.[INBOUND_STOP_IDX]?.stopType === StopType.Recovery) {
    formData.stops[INBOUND_STOP_IDX].stopType = StopType.Pickup;
    if (!isNil(formData.stops[INBOUND_STOP_IDX].address)) {
      // Provide a new uuid in case the order is saved.

      formData.stops[INBOUND_STOP_IDX].address = {
        ...formData.stops[INBOUND_STOP_IDX].address,
        uuid: v4(),
      };
    }

    formData.stops[INBOUND_STOP_IDX].outboundMethod = undefined;
  }
  if (formData.stops?.[OUTBOUND_STOP_IDX]?.stopType === StopType.Transfer) {
    formData.stops[OUTBOUND_STOP_IDX].stopType = StopType.Delivery;
    if (!isNil(formData.stops[OUTBOUND_STOP_IDX].address)) {
      // Provide a new uuid in case the order is saved.

      formData.stops[OUTBOUND_STOP_IDX].address = {
        ...formData.stops[OUTBOUND_STOP_IDX].address,
        uuid: v4(),
      };
    }

    formData.stops[OUTBOUND_STOP_IDX].inboundMethod = InboundMethod.Other;
  }
};

const useLoadOrderForm = (
  /** If a company config is provided, it is used instead of the Me query. */
  providedCompanyConfiguration?: CompanyConfigurationFragment | null,
) => {
  const { reset, control, getValues } = useFormContext<OrderFormFieldValues>();
  const { contactUuid, refetch: refetchContact } = useOrderFormContact();
  const getContactDefaultPackageSpec = useGetContactDefaultPackageSpec();
  const formOrderUuid = useWatch({ control, name: 'uuid' });
  const ffNoRecoveryTransfer = useFeatureFlag(
    FeatureFlag.FF_NO_RECOVERY_TRANSFER,
  );

  const { companyConfiguration: meCompanyConfiguration } = useMe({
    skip: !isNil(providedCompanyConfiguration),
  });
  const companyConfiguration =
    providedCompanyConfiguration ?? meCompanyConfiguration;
  const terminalsEnabled = companyConfiguration?.terminalsEnabled === true;

  // For recording the snapshot diff. The snapshot in the db can be stale if
  // other actions were taken since
  const [initialOrder, setInitialOrder] =
    useState<StandardOrderFragmentFragment>();

  const [getStandardOrder] = useStandardOrderLazyQuery();

  // This hook is called from different useEffects (tech debt) and we can't
  // guarantee exactly when it's called, so this was added to guard against
  // weird race conditions / stale responses. We can remove this once we've
  // refactored to initialize the form in a single, predictable place.
  // We tried adding abort controllers too but they didn't play nice with Apollo
  // client for some reason (see PR discussion).
  const requestId = useRef<number>(0);

  const refetchContactData = async (): Promise<
    | {
        contactData: ShallowContactFragment | undefined;
        contactDefaultPackageSpec: DefaultPackageSpec | undefined;
      }
    | undefined
  > => {
    if (isNilOrEmptyString(contactUuid)) {
      return;
    }
    const [resContact, packageSpec] = await Promise.all([
      refetchContact({ uuid: contactUuid }),
      getContactDefaultPackageSpec(contactUuid),
    ]);
    return {
      contactData: resContact.data?.contact,
      contactDefaultPackageSpec: packageSpec,
    };
  };

  /**
   * @param uuid
   * @param refetchOrderAfterSave
   * @param duplicate
   * @param standardOrderFragment Pass in the already queried data to avoid
   * needing to query it twice
   */
  const fetchData = async ({
    uuid,
    refetchOrderAfterSave,
    duplicate,
    standardOrderFragment,
  }: {
    uuid: string;
    refetchOrderAfterSave?: boolean;
    duplicate?: string | null;
    standardOrderFragment?: StandardOrderFragmentFragment;
  }) => {
    const currentRequestId = requestId.current;
    requestId.current += 1;

    let order = standardOrderFragment;
    if (isNil(order)) {
      const res = await getStandardOrder({ variables: { uuid } });
      order = res.data?.standardOrder ?? undefined;
    }
    if (!isNil(order)) {
      setInitialOrder(order);
      const { contactData, contactDefaultPackageSpec } =
        (await refetchContactData()) ?? {};

      const formData = await convertStandardOrderPayloadToFormData({
        standardOrder: order,
        terminalsEnabled,
        defaultPackageSpec: contactDefaultPackageSpec,
        // we only care to duplicate if it's the first time the form is being loaded
        duplicate: isNilOrEmptyString(formOrderUuid) ? duplicate : undefined,
        contactUuid: order.billingPartyContact.uuid,
        companyConfiguration,
        contact: contactData,
        refetchOrderAfterSave,
        useCentimeters: getValues('useCentimeters'),
        useKilograms: getValues('useKilograms'),
      });
      if (ffNoRecoveryTransfer) {
        // Force Recovery to be Pickup and Transfer to be Delivery.
        // This is a little hacky, not meant to be production code (it's for a demo).
        forceRecoveryToPickupAndTransferToDelivery(formData);
      }

      // If this isn't the latest request, ignore the response
      if (currentRequestId !== requestId.current - 1) {
        return;
      }

      reset(formData);
    }
  };

  const initializeForm = async ({
    orderUuid,
    recurringTemplate,
    duplicate,
  }: {
    orderUuid: string | undefined | null;
    recurringTemplate: boolean;
    duplicate: string | null | undefined;
  }) => {
    if (!isNil(orderUuid)) {
      void fetchData({ uuid: orderUuid, duplicate });
    } else if (!isNil(companyConfiguration)) {
      const currentRequestId = requestId.current;
      requestId.current += 1;

      const { contactData, contactDefaultPackageSpec } =
        (await refetchContactData()) ?? {};

      const formData = await initializeOrderToFormData({
        contactUuid,
        companyConfiguration,
        contact: contactData,
        terminalsEnabled,
        defaultPackageSpec: contactDefaultPackageSpec,
        recurringTemplate,
        fulfillmentType: FulfillmentType.MultiTrip,
        ffNoRecoveryTransfer,
      });
      if (ffNoRecoveryTransfer) {
        // Force Recovery to be Pickup and Transfer to be Delivery.
        // This is a little hacky, not meant to be production code (it's for a demo).
        forceRecoveryToPickupAndTransferToDelivery(formData);
      }

      // If this isn't the latest request, ignore the response
      if (currentRequestId !== requestId.current - 1) {
        return;
      }

      reset(formData);
    }
  };

  return { initialOrder, initializeForm, fetchData };
};

export { useLoadOrderForm };
