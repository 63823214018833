import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import {
  type ErrorResponse,
  validateNonNegativeNumber,
  validateNotEmpty,
  type ValidationResponse,
} from '../../../common/form/formValidators';
import {
  type PackageCreateInput,
  PackageType,
  type PackageUpdateInput,
  type PackageUpsertInput,
  type StandardOrderFragmentFragment,
} from '../../../generated/graphql';
import { type RootState } from '../../../redux/store';
import {
  selectStandardShipmentValuesById,
  updateStandardShipmentValues,
} from '../../shipments/redux/standard-shipments-values-slice';
import { upsertOnePackageErrors } from './package-errors-slice';
import {
  addOnePackageValues,
  removeOnePackageValues,
  selectPackageById,
  upsertOnePackageValues,
} from './package-values-slice';

export const upsertPackagesForOrder = createAsyncThunk<
  string[],
  {
    order: StandardOrderFragmentFragment;
    isDuplicate: boolean;
  },
  { state: RootState }
>(
  'packages/upsertPackagesForShipment',
  async (arg, thunkAPI): Promise<string[]> => {
    const packages = await Promise.all(
      arg.order.packages.map(async (package_) => {
        const { warehouseLocation, ...packageValues } = package_;
        return thunkAPI.dispatch(
          upsertOnePackageValues({
            ...packageValues,
            uuid: arg.isDuplicate ? v4() : package_.uuid,
            warehouseLocation: warehouseLocation ?? undefined,
          }),
        ).payload;
      }),
    );

    return packages.map((pkg) => pkg.uuid);
  },
);

export const createNewPackage = createAsyncThunk<
  string,
  void,
  { state: RootState }
>('packages/createNewPackage', async (_, thunkAPI): Promise<string> => {
  const uuid = v4();
  await thunkAPI.dispatch(
    addOnePackageValues({
      uuid,
      type: PackageType.Skid,
      description: undefined,
      quantity: undefined,
    }),
  );
  return uuid;
});

export const addPackageToShipment = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('packages/addPackageToShipment', async (shipmentUuid, thunkAPI) => {
  const packageUuid = await thunkAPI.dispatch(createNewPackage()).unwrap();
  const shipment = await selectStandardShipmentValuesById(
    thunkAPI.getState(),
    shipmentUuid,
  );
  if (shipment === undefined) {
    throw new Error(`Invalid shipment uuid: ${shipmentUuid}`);
  }
  thunkAPI.dispatch(
    updateStandardShipmentValues({
      id: shipmentUuid,
      changes: {
        packageUuids: [...(shipment.packageUuids ?? []), packageUuid],
      },
    }),
  );
});

type RemovePackageFromShipmentArg = {
  shipmentUuid: string;
  packageUuid: string;
};

export const removePackageFromShipment = createAsyncThunk<
  void,
  RemovePackageFromShipmentArg,
  { state: RootState }
>(
  'packages/removePackageFromShipment',
  async ({ shipmentUuid, packageUuid }, thunkAPI) => {
    const shipment = await selectStandardShipmentValuesById(
      thunkAPI.getState(),
      shipmentUuid,
    );
    if (shipment === undefined) {
      throw new Error(`Invalid shipment uuid: ${shipmentUuid}`);
    }
    const packageUuids = (shipment.packageUuids ?? []).filter(
      (uuid) => uuid !== packageUuid,
    );
    thunkAPI.dispatch(
      updateStandardShipmentValues({
        id: shipmentUuid,
        changes: {
          packageUuids,
        },
      }),
    );
    thunkAPI.dispatch(removeOnePackageValues(packageUuid));
  },
);

type GetPackageErrorsArg = { packageUuid: string };
export type PackageErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
};

export const getPackageErrors = createAsyncThunk<
  PackageErrorsResponse,
  GetPackageErrorsArg,
  { state: RootState }
>(
  'packages/getPackageErrors',
  async (arg, thunkAPI): Promise<PackageErrorsResponse> => {
    const packageValues = selectPackageById(
      thunkAPI.getState(),
      arg.packageUuid,
    );
    if (isNil(packageValues)) {
      throw new Error(`Invalid package uuid: ${arg.packageUuid}`);
    }

    let isValid = true;
    const errors: ErrorResponse[] = [];
    await Promise.all(
      objectKeys(packageValues).map(async (field) => {
        let validationResponse: ValidationResponse | null = null;
        let userFacingFieldName = '';
        const value = packageValues[field];

        switch (field) {
          case 'class': {
            break;
          }
          case 'hazmat': {
            break;
          }
          case 'height': {
            userFacingFieldName = 'Height';
            validationResponse = validateNonNegativeNumber(
              value as number,
              false,
            );
            break;
          }
          case 'length': {
            userFacingFieldName = 'Length';
            validationResponse = validateNonNegativeNumber(
              value as number,
              false,
            );
            break;
          }
          case 'nmfc': {
            break;
          }
          case 'quantity': {
            userFacingFieldName = 'Quantity';
            validationResponse = validateNonNegativeNumber(
              value as number,
              true,
            );
            break;
          }
          case 'uuid': {
            break;
          }
          case 'weight': {
            userFacingFieldName = 'Weight';
            validationResponse = validateNonNegativeNumber(
              value as number,
              false,
            );
            break;
          }
          case 'width': {
            userFacingFieldName = 'Width';
            validationResponse = validateNonNegativeNumber(
              value as number,
              false,
            );
            break;
          }
          case 'description': {
            userFacingFieldName = 'Description';
            validationResponse = validateNotEmpty(value, false);
            break;
          }
          case 'type': {
            break;
          }
          case 'warehouseLocation': {
            break;
          }
        }
        if (validationResponse?.valid === true) {
          thunkAPI.dispatch(
            upsertOnePackageErrors({
              uuid: arg.packageUuid,
              [field]: undefined,
            }),
          );
        } else if (validationResponse?.valid === false) {
          isValid = false;
          errors.push({ field: userFacingFieldName, validationResponse });
          thunkAPI.dispatch(
            upsertOnePackageErrors({
              uuid: arg.packageUuid,
              [field]: validationResponse?.explanation,
            }),
          );
        }
      }),
    );

    return { errors, isValid };
  },
);

type CreatePackageCreateInputArg = {
  packageUuid: string;
};

export const createPackageCreateInput = createAsyncThunk<
  PackageCreateInput | null,
  CreatePackageCreateInputArg,
  { state: RootState }
>(
  'packages/createPackageCreateInput',
  async (arg, thunkAPI): Promise<PackageCreateInput | null> => {
    const packageValues = selectPackageById(
      thunkAPI.getState(),
      arg.packageUuid,
    );
    if (isNil(packageValues)) {
      throw new Error(`Invalid package uuid: ${arg.packageUuid}`);
    }

    if (isNil(packageValues.quantity)) {
      // We filter packages without quantity from page validation as well. When
      // we create a new order we create an empty package and we don't want to
      // flag on the missing field on order creation we just don't create it
      return null;
    }

    return {
      class: packageValues.class,
      description: packageValues.description ?? '',
      hazmat: packageValues.hazmat,
      height: packageValues.height,
      length: packageValues.length,
      nmfc: packageValues.nmfc,
      quantity: packageValues.quantity,
      type: packageValues.type,
      packageSpecId: packageValues.packageSpec?.id,
      weight: packageValues.weight,
      width: packageValues.width,
      skuNumber: packageValues.skuNumber,
    };
  },
);

type CreatePackageUpdateInputArg = {
  packageUuid: string;
};

export const createPackageUpdateInput = createAsyncThunk<
  PackageUpdateInput,
  CreatePackageUpdateInputArg,
  { state: RootState }
>(
  'packages/createPackageUpdateInput',
  async (arg, thunkAPI): Promise<PackageUpdateInput> => {
    const packageValues = selectPackageById(
      thunkAPI.getState(),
      arg.packageUuid,
    );
    if (isNil(packageValues)) {
      throw new Error(`Invalid package uuid: ${arg.packageUuid}`);
    }

    return {
      class: packageValues.class,
      description: packageValues.description,
      hazmat: packageValues.hazmat,
      height: packageValues.height,
      length: packageValues.length,
      nmfc: packageValues.nmfc,
      quantity: packageValues.quantity,
      type: packageValues.type,
      weight: packageValues.weight,
      width: packageValues.width,
      uuid: packageValues.uuid,
      skuNumber: packageValues.skuNumber,
    };
  },
);

type CreatePackageUpsertInputArg = {
  packageUuid: string;
};

export const createPackageUpsertInput = createAsyncThunk<
  PackageUpsertInput | null,
  CreatePackageUpsertInputArg,
  { state: RootState }
>(
  'legs/createPackageUpsertInput',
  async (arg, thunkAPI): Promise<PackageUpsertInput | null> => {
    const packageValues = selectPackageById(
      thunkAPI.getState(),
      arg.packageUuid,
    );
    if (isNil(packageValues)) {
      throw new Error(`Invalid package uuid: ${arg.packageUuid}`);
    }

    if (isNil(packageValues.quantity)) {
      // We filter packages without quantity from page validation as well. When
      // we create a new order we create an empty package and we don't want to
      // flag on the missing field on order creation we just don't create it
      return null;
    }

    return {
      class: packageValues.class,
      description: packageValues.description ?? '',
      hazmat: packageValues.hazmat,
      height: packageValues.height,
      length: packageValues.length,
      nmfc: packageValues.nmfc,
      quantity: packageValues.quantity,
      type: packageValues.type,
      packageSpecId: packageValues.packageSpec?.id,
      weight: packageValues.weight,
      width: packageValues.width,
      uuid: packageValues.uuid,
      skuNumber: packageValues.skuNumber,
      warehouseLocationUuid: packageValues.warehouseLocation?.uuid,
    };
  },
);
