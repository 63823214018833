import { CircularProgress, Stack } from '@mui/material';
import { isNil } from 'lodash';
import { useOrganizationForAddressBookQuery } from '../../../../generated/graphql';
import OrderAddressesConfig from './order-addresses-config';
import AgentTerminalsConfig from './agent-terminals-config';
type OrganizationAddressBookPanelProps = {
  readonly organizationUuid: string;
};

// Add this type definition near the top of the file
type AddressFormData = {
  name: string;
  zip: string;
  city: string;
  state: string;
  line1: string;
  line2: string;
};

const AddressBookTab = (props: OrganizationAddressBookPanelProps) => {
  const { data, loading, error } = useOrganizationForAddressBookQuery({
    variables: {
      input: { uuid: props.organizationUuid },
    },
  });

  if (isNil(data)) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <OrderAddressesConfig
        organizationUuid={props.organizationUuid}
        addresses={data.organization.addresses}
      />
      {!isNil(data.organization.agentFields) && (
        <AgentTerminalsConfig
          organizationUuid={props.organizationUuid}
          terminals={data.organization.agentFields.activeTerminals}
        />
      )}
    </Stack>
  );
};

export default AddressBookTab;
