import { memo } from 'react';
import { ShipmentType } from '../../../../../../../../generated/graphql';
import FreightChargeRow from '../freight-charge-row';

const OrderChargesFreightChargeRow = ({
  inBillingReview,
}: {
  readonly inBillingReview: boolean;
}) => {
  return (
    <FreightChargeRow
      context={{
        shipmentType: ShipmentType.OrderCharges,
        inSettlement: false,
      }}
      inBillingReview={inBillingReview}
      hideFromBilling={false}
    />
  );
};

export default memo(OrderChargesFreightChargeRow);
