import { Button, IconButton, Stack, Typography } from '@mui/material';
import { type NonEmptyArray } from './utils';
import { type LineHaulManifest } from './utils';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import AddIcon from '@mui/icons-material/Add';
import pluralize from 'pluralize';
import {
  LineHaulManifestsDocument,
  useCreateLineHaulManifestsMutation,
} from '../../../../generated/graphql';
import { type Dayjs } from 'dayjs';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../../common/components/errors-alert';

type ManifestLaneGroupHeaderProps = {
  readonly manifests: NonEmptyArray<LineHaulManifest>;
  readonly planningDate: Dayjs;
};

const ManifestLaneGroupHeader = ({
  manifests,
  planningDate,
}: ManifestLaneGroupHeaderProps) => {
  const [createManifest, { loading }] = useCreateLineHaulManifestsMutation({
    refetchQueries: [LineHaulManifestsDocument],
  });

  const { errors, onError, clearErrors } = useErrors();

  const handleAddManifest = async () => {
    await createManifest({
      onError,
      variables: {
        input: {
          lanes: [
            {
              startTerminalUuid: manifests[0].startTerminal.uuid,
              endTerminalUuid: manifests[0].endTerminal.uuid,
              count: 1,
            },
          ],
          departDate: planningDate.toISOString(),
        },
      },
      onCompleted: (data) => {
        if (data.createLineHaulManifests.__typename === 'MutationErrorOutput') {
          onError(data.createLineHaulManifests);
        }
      },
    });
  };

  return (
    <>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <Stack py={1} px={2} direction="row" gap={1} alignItems="center">
        <Stack direction="row" gap={0.5} alignItems="center" fontWeight="700">
          <Typography fontWeight="700">
            {manifests[0].startTerminal.code}
          </Typography>
          <ArrowRightAltRoundedIcon style={{ fontSize: '16px' }} />
          <Typography fontWeight="700">
            {manifests[0].endTerminal.code}
          </Typography>
        </Stack>
        <Typography color="#838384" fontSize="12px">
          {manifests.length} {pluralize('vehicle', manifests.length)} loaded
        </Typography>
        <Button
          sx={{ marginLeft: 'auto' }}
          startIcon={<AddIcon />}
          disabled={loading}
          onClick={() => {
            void handleAddManifest();
          }}
        >
          Add manifest
        </Button>
      </Stack>
    </>
  );
};

export default ManifestLaneGroupHeader;
