import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useDriversLazyQuery, DriverType } from '../../generated/graphql';
import useGlobalStore from '../../layouts/dashboard/global-store';
import { filterNotNil } from 'shared/array';

const useDrivers = () => {
  const [getDrivers, { loading }] = useDriversLazyQuery({
    variables: {
      active: true,
    },
  });
  const [drivers, setDrivers] = useGlobalStore(
    (state) => [state.drivers, state.setDrivers],
    shallow,
  );

  const driversEligibleForSettlement = useMemo(() => {
    return drivers.filter(
      (driver) => driver.driverType !== DriverType.CompanyDriver,
    );
  }, [drivers]);

  const getDriverName = useCallback(
    (uuid: string | null | undefined) => {
      if (isNil(uuid)) {
        return '';
      }
      const driver = drivers?.find((d) => d.uuid === uuid);
      return `${driver?.firstName ?? ''} ${driver?.lastName ?? ''}`;
    },
    [drivers],
  );

  const loadDrivers = async () => {
    const res = await getDrivers({
      variables: {
        active: true,
      },
    });

    setDrivers(res.data?.drivers ?? []);
  };

  return {
    getDriverName,
    drivers,
    driversEligibleForSettlement,
    loadDrivers,
    loading,
  };
};

export default useDrivers;
