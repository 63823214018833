import { Dialog, Box, Paper } from '@mui/material';
import React from 'react';
import Draggable from 'react-draggable';
import UnifiedSearch from './unified-search';

const PaperComponent = (props: any) => {
  return (
    <Draggable handle="#drag-icon" cancel='[class*="MuiDialogContent-root"]'>
      <Paper {...props} />
    </Draggable>
  );
};

const UnifiedSearchDialog = ({
  open,
  setOpen,
}: {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
}) => {
  return (
    <Dialog
      fullWidth
      PaperComponent={PaperComponent}
      maxWidth="md"
      open={open}
      aria-labelledby="drag-icon"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box sx={{ minHeight: '500px' }}>
        <UnifiedSearch setOpen={setOpen} />
      </Box>
    </Dialog>
  );
};

export default UnifiedSearchDialog;
