export const EXPOSE_TO_CUSTOMERS_HELP_TOOLTIP_TITLE =
  "If enabled, the service will be exposed to your customers for selection in the customer portal or while using Pallet's API";

export enum ManagementTab {
  ACCESSORIALS = 'accessorials',
  BILLING = 'billing',
  SERVICES = 'services',
  ROUTE_NAMES = 'route-names',
  RECOVERY_TERMINALS = 'recovery-terminals',
  BUSINESS_DIVISIONS = 'business-divisions',
  CUSTOM_FORMS = 'custom-forms',
  DRIVERS = 'drivers',
  EQUIPMENT = 'equipment',
  FUEL_PROFILES = 'fuel-profiles',
  HOLD_REASONS = 'hold-reasons',
  LINE_HAUL = 'line-haul',
  RECURRING_ORDERS = 'recurring-orders',
  ROUTE_TEMPLATES = 'route-templates',
  SERVICE_REGIONS = 'service-regions',
  TARIFFS = 'tariffs',
  TARIFF_CHAINS = 'tariff-chains',
  TERMINALS = 'terminals',
  THIRD_PARTY_BROKERS = 'third-party-brokers',
  USERS = 'users',
  WAREHOUSE_EMPLOYEES = 'warehouse-employees',
  PACKAGE_TYPES = 'package-types',
  SCHEDULING = 'scheduling',
  ZONES = 'zones',
}
