import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useState } from 'react';
import { useHasUnsavedChanges } from '../../../common/react-hooks/use-has-unsaved-changes';
import {
  useServiceRegionQuery,
  useUpdateServiceRegionMutation,
} from '../../../generated/graphql';
import {
  leftColumnWidth,
  ServiceRegionTerminalOverview,
} from './service-region-terminal-overview';
import { useErrors } from '../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../common/components/errors-alert';

const styles = {
  container: {
    background: 'white',
    width: '100%',
  },
  sectionHeading: {
    fontSize: '14px',
    fontWeight: 500,
  },
};

type EditServiceRegionProps = Readonly<{
  id: string;
}>;

export const EditServiceRegion: FunctionComponent<EditServiceRegionProps> = ({
  id,
}) => {
  const theme = useTheme();
  const {
    hasUnsavedChanges,
    triggerHasUnsavedChanges,
    resetHasUnsavedChanges,
  } = useHasUnsavedChanges();
  const { errors, onError, clearErrors } = useErrors();
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [name, setName] = useState('');

  const { data, loading } = useServiceRegionQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id },
    onCompleted: (data) => {
      setName(data.serviceRegion.name);
    },
  });
  const [updateServiceRegion, { loading: updateServiceRegionLoading }] =
    useUpdateServiceRegionMutation({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (
          data.updateServiceRegion.__typename ===
          'UpdateServiceRegionSuccessOutput'
        ) {
          resetHasUnsavedChanges();
          setSnackbarMessage('Service region updated');
        } else {
          onError(data.updateServiceRegion);
        }
      },
    });

  const handleSave = () => {
    clearErrors();
    const trimmedName = name.trim();
    if (trimmedName === '') {
      onError('Name is required');
      return;
    }
    void updateServiceRegion({
      variables: {
        input: {
          id,
          name: trimmedName,
        },
      },
    });
  };

  const serviceRegion = data?.serviceRegion;

  const handleToggleArchived = () => {
    if (!isNil(serviceRegion)) {
      void updateServiceRegion({
        variables: {
          input: {
            id,
            isArchived: !serviceRegion.isArchived,
          },
        },
      });
    }
  };

  return (
    <>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      {loading && isNil(serviceRegion) && <CircularProgress sx={{ m: 3 }} />}
      {!isNil(serviceRegion) && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <FormControl required sx={{ width: 200 }}>
              <TextField
                label="Name"
                value={name}
                size="small"
                sx={{ width: '280px' }}
                onChange={({ target }) => {
                  setName(target.value);
                  triggerHasUnsavedChanges();
                }}
              />
            </FormControl>
            <Button
              variant="contained"
              disabled={
                !hasUnsavedChanges || loading || updateServiceRegionLoading
              }
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
          <Divider sx={{ borderBottomWidth: '2px' }} />
          <Stack alignItems="stretch" sx={styles.container} gap={2} p={2}>
            <Box>
              <Typography sx={styles.sectionHeading}>Terminals</Typography>
              {serviceRegion.terminals.length === 0 && (
                <Typography variant="caption" color="text.secondary">
                  No terminals are associated with this service region.
                </Typography>
              )}
            </Box>
            {serviceRegion.terminals.length > 0 && (
              <Stack direction="row" gap={2}>
                <Typography
                  variant="h4"
                  fontSize="15px"
                  fontWeight={500}
                  color="text.secondary"
                  sx={{ width: leftColumnWidth }}
                >
                  Terminal
                </Typography>
                <Typography
                  variant="h4"
                  fontSize="15px"
                  fontWeight={500}
                  color="text.secondary"
                >
                  Service areas
                </Typography>
              </Stack>
            )}
            {serviceRegion.terminals.map((terminal) => (
              <Box
                key={terminal.uuid}
                py={1.5}
                sx={{
                  borderTopWidth: '1px',
                  borderTopStyle: 'solid',
                  borderColor: 'divider',
                }}
              >
                <ServiceRegionTerminalOverview terminal={terminal} />
              </Box>
            ))}
          </Stack>
          <Divider />
          <Stack alignItems="stretch" sx={styles.container} gap={2} p={2}>
            <Box>
              <Typography sx={styles.sectionHeading}>
                {serviceRegion.isArchived ? 'Un-archive ' : 'Archive '}
                this service region
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {serviceRegion.isArchived
                  ? 'This service region is archived.'
                  : 'Archiving this service region will make it unavailable for new orders.'}
              </Typography>
            </Box>
            {/* This wrapping box is here to keep the button from being stretched to full width. */}
            <Box>
              <Button
                variant="outlined"
                color={serviceRegion.isArchived ? undefined : 'error'}
                disabled={
                  updateServiceRegionLoading ||
                  serviceRegion.terminals.some((terminal) => terminal.isActive)
                }
                onClick={handleToggleArchived}
              >
                {serviceRegion.isArchived ? 'Un-archive' : 'Archive'}
              </Button>
            </Box>
          </Stack>
        </>
      )}
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!isNil(snackbarMessage)}
        onClose={() => {
          setSnackbarMessage(null);
        }}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};
