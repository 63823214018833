import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray } from 'lodash';
import { type Control, Controller } from 'react-hook-form';
import useServices from '../../../common/react-hooks/use-services';
import { type OrganizationFormValues } from '../form/types';

const ContactServiceLevelsMultiSelect = ({
  control,
  disabled,
}: {
  readonly control: Control<OrganizationFormValues>;
  readonly disabled: boolean;
}) => {
  const { getServiceName, services } = useServices();

  return (
    <FormControl sx={{ flex: 1 }}>
      <FormLabel>Service levels</FormLabel>
      <Controller
        name="serviceUuids"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Select
            {...field}
            multiple
            value={field.value ?? []} // Ensure value is always an array
            size="medium"
            disabled={disabled}
            renderValue={(selected) => {
              if (isArray(selected)) {
                return (
                  <Box>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={sentenceCase(getServiceName(value))}
                      />
                    ))}
                  </Box>
                );
              }
              return null;
            }}
          >
            {services?.map((service) => (
              <MenuItem key={service.uuid} value={service.uuid}>
                {sentenceCase(service.name)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ContactServiceLevelsMultiSelect;
