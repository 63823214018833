import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { exhaustive } from 'shared/switch';
import { StopType } from '../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { CustomerPortalStopType } from '../../../orders/components/order-form/forms/stop-type';
import { type StopTypeWithoutNone } from './forms/types';
import ServiceLevelDeterminesStopTypeTooltip from './service-level-determines-stop-type-tooltip';

const CUSTOMER_PORTAL_STOP_TYPES = Object.values(CustomerPortalStopType);

/** Convert StopType to CustomerPortalStopType */
export const getCustomerPortalStopType = (
  stopType: StopTypeWithoutNone,
): CustomerPortalStopType => {
  switch (stopType) {
    case StopType.Recovery:
    case StopType.Transfer:
    case StopType.PartnerCarrierDropoff:
    case StopType.PartnerCarrierPickup: {
      return CustomerPortalStopType.WarehouseOrAirport;
    }
    case StopType.Pickup:
    case StopType.Delivery: {
      return CustomerPortalStopType.BusinessOrResidence;
    }
    default: {
      return exhaustive(stopType);
    }
  }
};

type CustomerPortalStopTypeFieldProps = {
  readonly stopType: StopTypeWithoutNone;
};

const CustomerPortalStopTypeField = ({
  stopType,
}: CustomerPortalStopTypeFieldProps) => {
  const customerPortalStopType = getCustomerPortalStopType(stopType);

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  return (
    <ServiceLevelDeterminesStopTypeTooltip disabled={disabledIfNoAccess}>
      <FormControl sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <FormLabel>This is a</FormLabel>
        <RadioGroup
          value={customerPortalStopType}
          sx={{ flexDirection: 'row' }}
        >
          {CUSTOMER_PORTAL_STOP_TYPES.map((stopType) => (
            <FormControlLabel
              key={stopType}
              disabled
              value={stopType}
              control={<Radio />}
              label={stopType}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </ServiceLevelDeterminesStopTypeTooltip>
  );
};

export default CustomerPortalStopTypeField;
