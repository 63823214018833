import { Box, Stack, Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import type { ServiceRegionQuery } from '../../../generated/graphql';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ServiceAreaChip } from '../../../common/components/service-area-chip';
import PalletLink from '../../../pallet-ui/links/link/pallet-link';

export const leftColumnWidth = '260px';

type Terminal = ServiceRegionQuery['serviceRegion']['terminals'][number];

type ServiceRegionTerminalOverviewProps = Readonly<{
  terminal: Terminal;
}>;

export const ServiceRegionTerminalOverview = ({
  terminal,
}: ServiceRegionTerminalOverviewProps) => {
  return (
    <Stack direction="row" gap={2}>
      <Box width={leftColumnWidth} minWidth={leftColumnWidth}>
        <Tooltip
          title={terminal.name}
          open={terminal.code === terminal.name ? false : undefined}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: '15px', fontWeight: 500, mb: 1 }}
          >
            {terminal.code}
          </Typography>
        </Tooltip>
        <PalletLink
          href={`/management/terminals/${terminal.uuid}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          Edit <OpenInNewIcon sx={{ fontSize: '16px' }} />
        </PalletLink>
      </Box>
      <Stack direction="row" flexGrow={1} gap={1} flexWrap="wrap">
        {terminal.serviceAreas.length === 0 && (
          <Typography variant="caption" display="block">
            No service areas
          </Typography>
        )}
        {terminal.serviceAreas.map(({ uuid, zipcode, city }) => (
          <ServiceAreaChip key={uuid} zipcode={zipcode} city={city} />
        ))}
      </Stack>
    </Stack>
  );
};
