import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type AuditReportBucketFragment } from '../../generated/graphql';

type AuditReportState = {
  currentBucket: AuditReportBucketFragment | undefined;
};

type AuditReportActions = {
  setCurrentBucket: (bucket: AuditReportBucketFragment | undefined) => void;
};

const useAuditReportStore = create(
  immer<AuditReportState & AuditReportActions>((set) => ({
    currentBucket: undefined,
    setCurrentBucket: (bucket: AuditReportBucketFragment | undefined) => {
      set((state) => {
        state.currentBucket = bucket;
      });
    },
  })),
);

export default useAuditReportStore;
