import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type OrdersPageState = {
  showReportBeingEmailedMessage: boolean;
  showTableReportErrorMessage: boolean;
};

type OrdersPageAction = {
  setShowReportBeingEmailedMessage: (show: boolean) => void;
  setShowTableReportErrorMessage: (show: boolean) => void;
};

const useOrdersPageStore = create(
  immer<OrdersPageState & OrdersPageAction>((set) => ({
    showReportBeingEmailedMessage: false,
    showTableReportErrorMessage: false,
    setShowReportBeingEmailedMessage: (show: boolean) => {
      set((state) => {
        state.showReportBeingEmailedMessage = show;
      });
    },
    setShowTableReportErrorMessage: (show: boolean) => {
      set((state) => {
        state.showTableReportErrorMessage = show;
      });
    },
  })),
);

export default useOrdersPageStore;
