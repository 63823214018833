import { Drawer, IconButton, Stack, Tooltip } from '@mui/material';
import type React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import LeftPanelClose from '../../icons/left-panel-close.svg?react';
import { SidebarDrawerItemGroup } from '../../layouts/dashboard/components/sidebar-drawer-item';
import PalletLogo from '../../layouts/dashboard/icons/pallet-logo-white.svg?react';
import {
  type DashboardPage,
  type DashboardDrawerItem,
  type CustomerPortalPage,
} from '../../layouts/dashboard/types';
import theme from '../../theme';
import { isNilOrEmptyString } from '../utils/utils';
import { isNil } from 'lodash';

const TRANSITION_DURATION = 225;
const DRAWER_WIDTH = 240;

type SidebarProps = {
  readonly actionItems?: React.ReactNode | null;
  readonly drawerItems?: Array<
    DashboardDrawerItem<DashboardPage | CustomerPortalPage>
  >;
  readonly userMenu?: React.ReactNode | null;
  readonly sidebarOpenLocalStorageKey: string;
};

const Sidebar = ({
  actionItems = null,
  drawerItems = [],
  userMenu = null,
  sidebarOpenLocalStorageKey,
}: SidebarProps) => {
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(
    sidebarOpenLocalStorageKey,
    {
      defaultValue: true,
    },
  );

  return (
    <Drawer
      hideBackdrop
      open
      PaperProps={{
        style: {
          backgroundColor: theme.palette.airfreightBlue[90],
          transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
          width: sidebarOpen ? DRAWER_WIDTH : 0,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overflowX: 'hidden',
          border: 'none',
        },
      }}
      sx={{
        width: sidebarOpen ? DRAWER_WIDTH : 0,
        minHeight: '100%',
        transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
        '& .MuiDrawer-paper': {
          transition: `width ${TRANSITION_DURATION}ms ease-in-out`,
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      }}
      variant="persistent"
      anchor="left"
    >
      <Stack sx={{ flexGrow: 1, p: 2, width: DRAWER_WIDTH }} gap={3}>
        <Stack gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <PalletLogo />
            <Stack direction="row">
              <Tooltip title="Collapse sidebar">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <LeftPanelClose fill="white" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          {actionItems}
        </Stack>
        {!isNil(drawerItems) && (
          <Stack gap={2}>
            {drawerItems.map((drawerItem) => (
              <SidebarDrawerItemGroup
                key={drawerItem.name}
                drawerItem={drawerItem}
              />
            ))}
          </Stack>
        )}
      </Stack>
      {!isNil(userMenu) && (
        <Stack
          sx={{
            borderTop: `1px solid ${theme.palette.grey[700]}`,
            position: 'sticky',
            bottom: 0,
            backgroundColor: theme.palette.airfreightBlue[90],
          }}
        >
          {userMenu}
        </Stack>
      )}
    </Drawer>
  );
};

export { Sidebar };
