import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    cardContent: {
      '&:last-child': {
        pb: 2,
      },
      p: 2,
    },
    card: {
      boxShadow: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.concreteGrey[30],
      borderRadius: 2,
    },
  };
};
