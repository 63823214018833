import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Badge, Button, Chip, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { memo, useRef, useState } from 'react';
import { getAppointmentTimeString } from 'shared/copy';
import { shallow } from 'zustand/shallow';
import { DispatchMultiplayerAction } from '../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useDispatchTableColors from '../../../common/react-hooks/use-dispatch-table-colors';
import {
  type StopOnRouteFragment,
  StopStatus,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import { useDispatchViewSettings } from '../hooks/use-dispatch-view-settings';
import { getEtaOrCompletedTimeString } from '../utils';
import PackageInfoText from './components/package-info-text';
import RouteAppointmentTimeField from './components/route-appointment-time-field';
import RouteServiceTimeField from './components/route-service-time-field';
import RouteStopCardHoverMenu from './components/route-stop-card-hover-menu';
import RouteStopCardPopup from './components/route-stop-card-popup';
import StopDetailsStatusIcon from './components/stop-details-status-icon';

const rightEndIconStyle = { fontSize: '13px', ml: '2px' };

const RouteCardStopsListRow = ({
  idx,
  stop,
  locked,
}: {
  readonly idx: number;
  readonly stop: StopOnRouteFragment;
  readonly locked: boolean;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const { useMinimizedAppointmentTime, routeCardWidth } =
    useDispatchViewSettings();
  const [selectingRoutes, planningDate, setOpenedOrderUuid] = useDispatchStore(
    (state) => [
      state.selectingRoutes,
      state.planningDate,
      state.setOpenedOrderUuid,
    ],
    shallow,
  );
  const listRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { dispatchTableColors } = useDispatchTableColors();

  const onClickRow = () => {
    if (!selectingRoutes) {
      setOpenedOrderUuid(stop.shipment?.order?.uuid);
    }
  };

  const appointmentTimeString = getAppointmentTimeString({
    stop,
    deliveryDate: stop.shipment?.standardShipmentFields?.deliveryDate,
    useSingleMinimizedAppointmentTime: useMinimizedAppointmentTime,
  });
  const etaOrCompletedTimeString = getEtaOrCompletedTimeString(stop);
  const isNotPending = stop.status !== StopStatus.NotArrived;

  const serviceColor = dispatchTableColors?.find(
    (dispatchTableColor) =>
      !isNil(dispatchTableColor.service) &&
      dispatchTableColor.service.uuid === stop.shipment?.order?.service?.uuid &&
      dispatchTableColor.active,
  )?.color;

  return (
    <Badge sx={{ width: '100%' }}>
      <RouteStopCardPopup
        open={isHovering}
        anchorRef={listRef}
        stop={stop}
        idx={idx}
      />
      <Stack
        ref={listRef}
        direction="row"
        justifyContent="space-between"
        sx={{
          width: '100%',
          backgroundColor: isHovering ? '#eeeeee' : undefined,
          cursor: 'default',
        }}
        onMouseEnter={() => {
          if (!selectingRoutes) {
            setIsHovering(true);
          }
        }}
        onMouseLeave={() => {
          if (!selectingRoutes) {
            setIsHovering(false);
          }
        }}
      >
        {isHovering && !locked && (
          <RouteStopCardHoverMenu
            stop={stop}
            setIsEditing={setIsEditing}
            showEdit={false}
          />
        )}
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{
            cursor: 'pointer',
            width: routeCardWidth - 150,
          }}
          onClick={onClickRow}
        >
          <Typography
            variant="caption"
            sx={{ minWidth: '13px' }}
            color={isNotPending ? 'text.secondary' : undefined}
          >
            {idx + 1}
          </Typography>
          <Typography
            variant="caption"
            sx={{ fontWeight: 'bold' }}
            color={isNotPending ? 'text.secondary' : undefined}
          >
            {stop.stopType?.at(0)}
          </Typography>
          <Stack
            spacing={0.5}
            alignItems="center"
            direction="row"
            sx={{ width: routeCardWidth <= 290 ? routeCardWidth - 150 : 90 }}
          >
            {!isNil(stop.stopDetails) && (
              <StopDetailsStatusIcon
                status={stop.stopDetails.stopDetailsStatus}
                message={stop.stopDetails.message}
              />
            )}
            {!isNil(serviceColor) && isNil(stop.stopDetails) && (
              <Chip
                size="small"
                sx={{
                  height: 7,
                  width: 7,
                  backgroundColor: serviceColor,
                }}
                label=""
              />
            )}
            <Typography
              noWrap
              variant="caption"
              color={isNotPending ? 'text.secondary' : undefined}
            >
              {stop.address.city}
            </Typography>
          </Stack>
          {routeCardWidth > 290 && (
            <Stack
              direction="row"
              sx={{ width: Math.min(routeCardWidth - 250, 130) }}
            >
              <Typography
                noWrap
                variant="caption"
                color={isNotPending ? 'text.secondary' : undefined}
                sx={{ flex: 1 }}
              >
                {stop.address.name}
              </Typography>
            </Stack>
          )}
          {routeCardWidth > 446 && (
            <Stack
              direction="row"
              sx={{ width: Math.min(180, routeCardWidth - 400) }}
            >
              <PackageInfoText pkg={stop.shipment?.order?.packages[0]} />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
          {isEditing ? (
            <Stack spacing={1} alignItems="flex-end" sx={{ pb: 1 }}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <RouteAppointmentTimeField
                  isStart
                  stop={stop}
                  baseDate={planningDate}
                />
                <Typography variant="caption"> - </Typography>
                <RouteAppointmentTimeField
                  stop={stop}
                  isStart={false}
                  baseDate={planningDate}
                />
                <Typography variant="caption" color="text.secondary">
                  appt.
                </Typography>
              </Stack>
              <RouteServiceTimeField stop={stop} />
              <Button
                variant="outlined"
                sx={{ borderRadius: 1, py: 0 }}
                onClick={() => {
                  setIsEditing(false);
                  sendDispatchUserLocationEvent({
                    action: DispatchMultiplayerAction.LEAVE,
                  });
                }}
              >
                Done
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ width: 120, textAlign: 'right', float: 'right' }}
            >
              <Typography
                variant="caption"
                color={isNotPending ? 'text.secondary' : undefined}
              >
                {stop.status === StopStatus.Completed
                  ? etaOrCompletedTimeString
                  : appointmentTimeString}
              </Typography>
              {stop.status === StopStatus.Completed &&
                isNil(stop.shipment?.order?.refusedBy) && (
                  <CheckCircleIcon color="success" sx={rightEndIconStyle} />
                )}
              {!isNil(stop.shipment?.order?.refusedBy) && (
                <CancelIcon color="error" sx={rightEndIconStyle} />
              )}
              {stop.status === StopStatus.Arrived && (
                <CheckIcon color="info" sx={rightEndIconStyle} />
              )}
              {(stop.status === StopStatus.Failed ||
                stop.status === StopStatus.Cancelled) && (
                <WarningIcon color="warning" sx={rightEndIconStyle} />
              )}
              {stop.status === StopStatus.NotArrived && (
                <Typography
                  variant="caption"
                  color={isNotPending ? 'text.secondary' : undefined}
                >
                  <span style={{ marginLeft: '2px', marginRight: '2px' }}>
                    |
                  </span>
                  {isNil(stop.cachedEta?.arrivalTime)
                    ? 'No ETA'
                    : `${dayjs(stop.cachedEta?.arrivalTime).format('HH:mm')}`}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Badge>
  );
};

export default memo(RouteCardStopsListRow);
