import FreightChargeRow from '../../../charges/components/freight-charge-row';
import React, { useState } from 'react';
import PalletModal from '../../../../../../../../pallet-ui/modal/pallet-modal';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  type CustomCostValues,
  type OrderFormFieldValues,
} from '../../../../forms/types';
import { isNil } from 'lodash';
import useDrivers from '../../../../../../../../common/react-hooks/use-drivers';
import FuelChargeRow from '../../../charges/components/fuel-charges/fuel-charge-row';
import CustomChargeRow from '../../../charges/components/custom-charge/custom-charge-row';
import { STOP_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import currency from 'currency.js';
import {
  type AccessorialType,
  CustomChargeBillingMethod,
  ShipmentType,
  useAccessorialsForCustomChargeRowQuery,
  StopType,
} from '../../../../../../../../generated/graphql';
import { CUSTOM_CHARGE_KEY } from '../../../charges/components/custom-charge/labels';
import { getAccessorialTypeFromTypename } from '../../../../../../../management/components/accessorials/common';
import { v4 } from 'uuid';
import useMe from '../../../../../../../../common/react-hooks/use-me';
import { type OrderRegularShipmentContext } from '../../../../types';
import { useOrderFormSettlementActions } from '../../../../hooks/use-order-form-settlement-actions';

export const DriverSettlementEditor = ({
  stopIdx,
  settlementBillLineItemIdx,
  onClose,
}: {
  readonly stopIdx: number;
  readonly settlementBillLineItemIdx: number;
  readonly onClose: () => void;
}) => {
  const theme = useTheme();
  const { getDriverName } = useDrivers();
  const { companyConfiguration } = useMe();
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const { onRemoveIndependentSettlementBillLineItem } =
    useOrderFormSettlementActions({ stopIdx });

  const contactUuid: string = useWatch({ control, name: 'contactUuid' });
  const stopType = useWatch({ control, name: `stops.${stopIdx}.stopType` });
  const terminalUuid = useWatch({
    control,
    name: `stops.${stopIdx}.terminalUuid`,
  });
  const lineItems = useWatch({
    control,
    name: `stops.${stopIdx}.settlementBillLineItems`,
  });
  const lineItem = useWatch({
    control,
    name: `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}`,
  });
  const customCosts =
    useWatch({
      control,
      name: `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}.customCosts`,
    }) ?? [];

  const { data: accessorialsForCustomChargeData } =
    useAccessorialsForCustomChargeRowQuery({
      fetchPolicy: 'cache-first',
      variables: {
        billingPartyContactUuid: contactUuid,
        terminalUuid,
      },
      skip: isNil(contactUuid),
    });
  const accessorialsForCustomCharge =
    accessorialsForCustomChargeData?.accessorialsByBillingContact ?? [];
  const defaultChargeToAccessorial =
    companyConfiguration?.defaultToAccessorial === true;

  const [newlyAddedCharge, setNewlyAddedCharge] = useState(false);

  const onAddCost = (accessorialOption?: {
    // The value is either the UUID of the accessorial or CUSTOM_CHARGE_KEY.
    value: string;
    label: string;
  }) => {
    let accessorialUuid: string | null = null;
    let accessorialType: AccessorialType | null = null;
    const isAccessorial =
      !isNil(accessorialOption) &&
      accessorialOption.value !== CUSTOM_CHARGE_KEY;
    if (isAccessorial) {
      accessorialUuid = accessorialOption.value;
      const accessorial = accessorialsForCustomCharge.find(
        (itrAccessorial) => itrAccessorial.uuid === accessorialUuid,
      );
      if (!isNil(accessorial?.__typename)) {
        accessorialType = getAccessorialTypeFromTypename(
          accessorial.__typename,
        );
      }
    }
    const newCustomCost: CustomCostValues = {
      quantity: 1,
      rate: 0,
      totalCharge: 0,
      isAutoApplied: false,
      accessorialUuid,
      accessorialType,
      specialAccessorialMatrixItemUuid: null,
      zoneBasedAccessorialMatrixItemUuid: null,
      fuelSurchargePercentageRate: 0,
      billingMethod:
        isAccessorial || defaultChargeToAccessorial
          ? CustomChargeBillingMethod.Accessorial
          : CustomChargeBillingMethod.AdHoc,
      uuid: v4(),
      isLocal: true,
      name: accessorialOption?.label ?? '',
      accessorialName: accessorialOption?.label ?? null,
      zoneUuid: null,
      chargeGroupUuid: null,
      accessorialRangeUuid: null,
      postedFuelSurchargeRate: null,
      description: null,
      authoCode: null,
      useAccessorialRate: true,
    };

    if (defaultChargeToAccessorial) {
      setNewlyAddedCharge(true);
    }

    setValue(
      `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}.customCosts`,
      [...customCosts, newCustomCost],
    );
  };

  const onDeleteSettlementBillLineItem = () => {
    onRemoveIndependentSettlementBillLineItem({
      driverUuid: lineItem.driverUuid,
    });
    onClose();
  };

  if (isNil(lineItem) || isNil(lineItem?.driverUuid)) {
    return null;
  }

  const context: OrderRegularShipmentContext = {
    shipmentType: ShipmentType.Regular,
    stopIdx,
    inSettlement: true,
    settlementBillLineItemIdx,
    settlementBillFinalizedDate: lineItem.settlementBillFinalizedDate,
  };

  const settlementFinalized = !isNil(lineItem.settlementBillFinalizedDate);

  return (
    <PalletModal
      open
      title={`${getDriverName(lineItem.driverUuid)}'s ${stopType?.toString().toLowerCase()} settlement`}
      pinnedElements={{
        bottomLeft: (
          <Button
            variant="contained"
            color="error"
            disabled={settlementFinalized}
            onClick={onDeleteSettlementBillLineItem}
          >
            Delete settlement
          </Button>
        ),
      }}
      onClose={onClose}
    >
      <Stack spacing={1} width="1000px" sx={{ minHeight: '200px' }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell width="150">Quantity</TableCell>
                <TableCell colSpan={2}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ backgroundColor: theme.palette.grey[100] }}
                data-testid="order-form-charges-stop-row"
              >
                <TableCell data-testid="order-form-charges-stop-row-header">
                  {stopType?.toString().toUpperCase()}
                </TableCell>
                <TableCell colSpan={4} />
              </TableRow>
              {stopType !== StopType.Recovery && (
                <FreightChargeRow
                  context={context}
                  inBillingReview={false}
                  hideFromBilling={false}
                />
              )}
              {
                <>
                  {stopType !== StopType.Recovery && (
                    <FuelChargeRow context={context} idx={stopIdx} />
                  )}
                  {lineItem.customCosts?.map(({ uuid }, customChargeIdx) => (
                    <CustomChargeRow
                      // because of the way we access custom charges inside custom-charge-row (using the index syntax),
                      // we have to key on that index to avoid staleness when the array changes size
                      // eslint-disable-next-line react/no-array-index-key
                      key={customChargeIdx}
                      context={context}
                      customChargeIdx={customChargeIdx}
                      accessorials={accessorialsForCustomCharge}
                      inBillingReview={false}
                      newlyAddedCharge={newlyAddedCharge}
                      setNewlyAddedCharge={setNewlyAddedCharge}
                    />
                  ))}
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Button
                        disabled={settlementFinalized}
                        onClick={() => {
                          onAddCost();
                        }}
                      >
                        + Add charge
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>Total</TableCell>
                    <TableCell>
                      <Typography data-testid={STOP_CHARGE_TOTAL_TEST_ID}>
                        {isNil(lineItem?.totalCharge)
                          ? '-'
                          : currency(lineItem.totalCharge).format()}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </PalletModal>
  );
};
