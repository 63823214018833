import type ReactPDF from '@react-pdf/renderer';
import { Text, View } from '@react-pdf/renderer';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import { safeAdd } from 'shared/math';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { StopType } from '../../../generated/graphql';
import { type OrderChargesLineHaulShipment } from '../utils';

export const OrderCharges = ({
  chargeableShipments,
  styles,
}: {
  readonly chargeableShipments: Array<
    OrderChargesLineHaulShipment & {
      stopType: StopType | string | null | undefined;
    }
  >;
  readonly styles: ReactPDF.Styles;
}) => {
  const chargeRow = (
    name: string,
    authoCode: string | null | undefined,
    total: number | null | undefined,
    isTotal?: boolean,
  ) => (
    <View style={styles.row}>
      <View
        style={filterNotNil([
          styles.row,
          {
            width: '55%',
            borderTopWidth: isTotal === true ? 1 : undefined,
            fontWeight: isTotal === true ? 'bold' : undefined,
          },
        ])}
      >
        <View style={{ width: '70%' }}>
          <Text style={styles.subHeaderTextSize}>
            {name}
            {isNil(authoCode) ? '' : ` (${authoCode})`}
          </Text>
        </View>
        <View style={{ width: '30%' }}>
          <Text style={styles.subHeaderTextSize}>
            {currency(total ?? 0).format()}
          </Text>
        </View>
      </View>
      <View style={{ width: '5%' }} />
      <View
        style={filterNotNil([
          isTotal === true ? {} : styles.formLineBottom,
          { width: '30%' },
        ])}
      />
    </View>
  );

  return (
    <View style={styles.cell}>
      <View style={styles.row}>
        <Text style={styles.headerText}>Charges</Text>
      </View>
      {chargeableShipments.map((sh, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={index} style={styles.colWithPadding}>
          <View style={styles.row}>
            <Text
              style={[
                {
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  width: '60%',
                },
              ]}
            >
              {sh?.stopType}
            </Text>
            <Text
              style={filterNotNil([
                styles.subHeaderTextSize,
                {
                  fontWeight: 'bold',
                  width: '40%',
                },
              ])}
            >
              Authorization
            </Text>
          </View>
          {sh.stopType !== StopType.Recovery &&
            !isNil(sh?.freightCharge) &&
            chargeRow(
              'Freight',
              sh?.freightCharge?.authoCode,
              sh?.freightCharge?.total,
            )}
          {sh.stopType !== StopType.Recovery &&
            !isNil(sh?.freightCharge?.fuelCharge) &&
            chargeRow(
              'Fuel',
              sh?.freightCharge?.fuelCharge.authoCode,
              sh?.freightCharge?.fuelCharge.total,
            )}
          {sh?.customCharges.map((charge) =>
            chargeRow(
              isNilOrEmptyString(charge.name) ? 'Custom charge' : charge.name,
              charge.authoCode,
              charge.total,
            ),
          )}
          {chargeRow(
            'Total',
            null,
            safeAdd(
              safeAdd(
                sh?.freightCharge?.total ?? 0,
                sh?.freightCharge?.fuelCharge?.total ?? 0,
              ),
              sh?.customCharges.reduce(
                (prev, curr) => safeAdd(prev, curr.total ?? 0),
                0,
              ) ?? 0,
            ),
            true,
          )}
        </View>
      ))}
      {chargeRow(
        'Order total',
        null,
        chargeableShipments.reduce(
          (prevSh, sh) =>
            safeAdd(
              prevSh,
              safeAdd(
                safeAdd(
                  sh?.freightCharge?.total ?? 0,
                  sh?.freightCharge?.fuelCharge?.total ?? 0,
                ),
                sh?.customCharges.reduce(
                  (prev, curr) => safeAdd(prev, curr.total ?? 0),
                  0,
                ) ?? 0,
              ),
            ),
          0,
        ),
        true,
      )}
    </View>
  );
};
