import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type TariffPageState = {
  errorMessage: string | null;
};

type TariffPageActions = {
  setErrorMessage: (errorMessage: string | null) => void;
};

const useTariffPageStore = create(
  immer<TariffPageState & TariffPageActions>((set) => ({
    errorMessage: null,
    setErrorMessage: (errorMessage) => {
      set((state) => {
        state.errorMessage = errorMessage;
      });
    },
  })),
);

export default useTariffPageStore;
