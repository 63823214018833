import { CheckCircle, Error } from '@mui/icons-material';
import {
  Box,
  Button,
  Fade,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import ButtonLink from '../../../common/components/buttons/button-link';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  PermissionResource,
  useEquipmentsQuery,
  useSamsaraOAuthTokenQuery,
} from '../../../generated/graphql';
import { ActiveArchivedButtonGroup } from './common/active-or-archived-button-group';
import { ActiveOrArchivedTab } from './common/enums';
import { COULD_NOT_FIND_IN_SAMSARA_TOOLTIP } from './strings';

const styles = {
  equipmentButton: {
    mx: 1.5,
  } as SxProps,
  tableCell: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
};

const EquipmentTable = () => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCompanyEquipment } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyEquipment,
  );
  const [isActiveOrArchived, setIsActiveOrArchived] =
    useState<ActiveOrArchivedTab>(ActiveOrArchivedTab.ACTIVE);
  const navigate = useNavigate();
  const [hoveredEquipmentUuid, setHoveredEquipmentUuid] = useState<string>();
  const { data: equipmentsData } = useEquipmentsQuery({
    variables: {
      includeNotActive: true,
    },
  });
  const { data: samsaraOAuthTokenData } = useSamsaraOAuthTokenQuery();

  const hasSamsaraIntegration = !isNil(
    samsaraOAuthTokenData?.samsaraOAuthToken?.uuid,
  );

  const sortedEquipments = equipmentsData?.equipments
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((e) =>
      isActiveOrArchived === ActiveOrArchivedTab.ACTIVE
        ? e.isActive
        : !e.isActive,
    );

  return (
    <Stack direction="column" sx={{ display: 'flex' }}>
      <Stack sx={{ p: 2 }}>
        <ActiveArchivedButtonGroup
          activeTab={isActiveOrArchived}
          setTab={setIsActiveOrArchived}
        />
      </Stack>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Subtype</TableCell>
              <TableCell>Vehicle Type</TableCell>
              {hasSamsaraIntegration && <TableCell>Samsara status</TableCell>}
              <TableCell align="right">
                <ButtonLink
                  href="/management/equipments/add"
                  variant="contained"
                  disabled={!canWriteCompanyEquipment}
                >
                  Add Equipment
                </ButtonLink>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEquipments?.map((equipment) => {
              const hasSamsaraVehicleId = !isNil(equipment.samsaraVehicleId);
              return (
                <TableRow
                  key={equipment.uuid}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  onMouseEnter={() => {
                    setHoveredEquipmentUuid(equipment.uuid);
                  }}
                  onMouseLeave={() => {
                    setHoveredEquipmentUuid(undefined);
                  }}
                >
                  <TableCell>
                    <Box sx={styles.tableCell}>{equipment.name}</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={styles.tableCell}>{equipment.type}</Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={styles.tableCell}>{equipment.subtype}</Box>
                  </TableCell>
                  <TableCell>{equipment.vehicleType?.name}</TableCell>
                  {hasSamsaraIntegration && (
                    <TableCell>
                      <Box sx={styles.tableCell}>
                        {hasSamsaraVehicleId && (
                          <CheckCircle sx={{ color: 'green' }} />
                        )}
                        {!hasSamsaraVehicleId && (
                          <Tooltip
                            title={COULD_NOT_FIND_IN_SAMSARA_TOOLTIP}
                            sx={styles.tableCell}
                          >
                            <Error color="warning" />
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Fade in={hoveredEquipmentUuid === equipment.uuid}>
                      <Button
                        sx={styles.equipmentButton}
                        variant="contained"
                        disabled={!canWriteCompanyEquipment}
                        onClick={() => {
                          navigate(
                            `/management/equipments/${equipment.uuid}?edit=true`,
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </Fade>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default EquipmentTable;
