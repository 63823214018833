import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { CUSTOMER_PORTAL_ORDER_SCHEMA } from '../forms/customer-portal-order-schema';
import { type CustomerPortalOrderFormValues } from '../forms/types';
import { CUSTOMER_PORTAL_ORDER_DEFAULT_VALUES } from '../forms/utils';

type UseCreateCustomerPortalOrderFormReturn =
  UseFormReturn<CustomerPortalOrderFormValues>;

/**
 * Initializes a form to create a new customer portal order from scratch
 * No loading state required since we don't fetch any data from the backend
 */
export const useCreateCustomerPortalOrderForm =
  (): UseCreateCustomerPortalOrderFormReturn => {
    const form = useForm<CustomerPortalOrderFormValues>({
      resolver: zodResolver(CUSTOMER_PORTAL_ORDER_SCHEMA),
      defaultValues: CUSTOMER_PORTAL_ORDER_DEFAULT_VALUES,
      mode: 'all',
      criteriaMode: 'all',
    });
    return form;
  };
