import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import {
  type OrganizationAgentTerminalFragment,
  OrganizationForAddressBookDocument,
  type TerminalCreateInput,
  type TerminalUpdateInput,
  useCreateAddressMutation,
  useCreateTerminalMutation,
  useUpdateAddressMutation,
  useUpdateTerminalMutation,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import PalletButton from '../../../../pallet-ui/button/pallet-button';
import {
  type ServiceRegionOption,
  ServiceRegionSelect,
} from '../../../management/components/service-region-select/service-region-select';
import { useNavigate } from 'react-router-dom';
import { ManagementTab } from '../../../management/constants';

type TerminalFormCommonFields = {
  name: string;
  code: string;
  addressName: string;
  zip: string;
  city: string;
  state: string;
  line1: string;
  line2: string;
  country: string;
  serviceRegion: ServiceRegionOption | null;
};

// Add this type definition near the top of the file
type TerminalFormData =
  | ({
      mode: 'EDIT';
      addressUuid: string;
    } & TerminalFormCommonFields)
  | ({ mode: 'CREATE' } & TerminalFormCommonFields);

type AgentTerminalsConfigProps = {
  readonly organizationUuid: string;
  readonly terminals: OrganizationAgentTerminalFragment[];
};

/**
 *
 * I accidentally implemented the form here lol. It's meant to be readonly
 * on this page so if we eventually want this to be read/write the code is ready.
 */
const AgentTerminalsConfig = ({
  organizationUuid,
  terminals,
}: AgentTerminalsConfigProps) => {
  const firstTerminal = terminals[0];
  const [selectedTerminalUuid, setSelectedTerminalUuid] = useState<
    string | null
  >(firstTerminal?.uuid ?? null);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting },
    control,
    setValue,
  } = useForm<TerminalFormData>({
    mode: 'onChange',
    defaultValues: isNil(firstTerminal)
      ? undefined
      : ({
          name: firstTerminal.name,
          code: firstTerminal.code,
          addressName: firstTerminal.address.name,
          line1: firstTerminal.address.line1,
          line2: firstTerminal.address.line2 ?? '',
          city: firstTerminal.address.city,
          state: firstTerminal.address.state,
          zip: firstTerminal.address.zip,
          country: firstTerminal?.address.country,
          serviceRegion: isNil(firstTerminal.serviceRegion)
            ? null
            : {
                id: firstTerminal.serviceRegion.id,
                name: firstTerminal.serviceRegion.name,
              },
          mode: 'EDIT',
          addressUuid: firstTerminal.address.uuid,
        } satisfies TerminalFormData),
  });

  register('serviceRegion', { required: true });

  const serviceRegion = useWatch({
    control,
    name: 'serviceRegion',
  });

  const [isAddingNewTerminal, setIsAddingNewTerminal] = useState(false);

  const theme = useTheme();
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [createAddressMutation, { loading: createAddressLoading }] =
    useCreateAddressMutation();
  const [updateAddressMutation, { loading: updateAddressLoading }] =
    useUpdateAddressMutation();

  const [createTerminalMutation, { loading: createTerminalLoading }] =
    useCreateTerminalMutation({
      onCompleted: (data) => {
        if (
          data.createTerminalV2.__typename === 'CreateTerminalSuccessOutput'
        ) {
          setSuccessMessage('Terminal created successfully');
          setShowSuccessMessage(true);
          setIsAddingNewTerminal(false);
          // should we select the new terminal?
          setSelectedTerminalUuid(data.createTerminalV2.terminal.uuid);
        } else {
          setErrorMessage('Failed to create terminal');
          setShowErrorMessage(true);
        }
      },
      onError: () => {
        setErrorMessage('Failed to create terminal');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationForAddressBookDocument],
    });

  const [updateTerminalMutation, { loading: updateTerminalLoading }] =
    useUpdateTerminalMutation({
      onCompleted: (data) => {
        if (
          data.updateTerminalV2.__typename === 'UpdateTerminalSuccessOutput'
        ) {
          setSuccessMessage('Terminal updated successfully');
          setShowSuccessMessage(true);
        } else {
          setErrorMessage('Failed to update terminal');
          setShowErrorMessage(true);
        }
      },
      onError: () => {
        setErrorMessage('Failed to update terminal');
        setShowErrorMessage(true);
      },
      refetchQueries: [OrganizationForAddressBookDocument],
    });

  const resetForm = (data: TerminalFormData | null) => {
    if (isNil(data))
      reset({
        mode: 'CREATE',
        name: '',
        code: '',
        addressName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        serviceRegion: null,
      } satisfies TerminalFormData);
    else {
      reset(data);
    }
  };

  const onSubmit = async (formData: TerminalFormData) => {
    if (isAddingNewTerminal && formData.mode === 'CREATE') {
      const res = await createAddressMutation({
        variables: {
          input: {
            contactUuid: organizationUuid,
            addressCreateInput: {
              name: formData.name,
              line1: formData.line1,
              line2: formData.line2,
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
              country: formData.country,
            },
          },
        },
      });
      if (!isNil(res.errors) || isNil(res.data)) {
        setErrorMessage('Failed to create terminal');
        setShowErrorMessage(true);
        return;
      }
      // create the terminal
      await createTerminalMutation({
        variables: {
          input: {
            name: formData.name,
            code: formData.code,
            // pass in the newly created address uuid
            addressUuid: res.data.createAddress.uuid,
            serviceRegionId: formData.serviceRegion?.id ?? null,
            agentId: organizationUuid,
            isActive: true,
          } satisfies Required<TerminalCreateInput>,
        },
      });
    } else if (
      !isAddingNewTerminal &&
      formData.mode === 'EDIT' &&
      !isNil(selectedTerminalUuid)
    ) {
      const res = await updateAddressMutation({
        variables: {
          input: {
            addressUpdateInput: {
              uuid: formData.addressUuid,
              name: formData.addressName,
              line1: formData.line1,
              line2: formData.line2,
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
              country: formData.country,
            },
          },
        },
      });
      if (!isNil(res.errors) || isNil(res.data)) {
        setErrorMessage('Failed to update terminal');
        setShowErrorMessage(true);
      }

      // create the terminal
      await updateTerminalMutation({
        variables: {
          input: {
            uuid: selectedTerminalUuid,
            name: formData.name,
            code: formData.code,
            // pass in the newly created address uuid
            addressUuid: formData.addressUuid,
            serviceRegionId: formData.serviceRegion?.id ?? null,
            agentId: organizationUuid,
            isActive: true,
            laneEndTerminalUuids: null,
          } satisfies Required<TerminalUpdateInput>,
        },
      });
    } else {
      setErrorMessage('Something went wrong. Please contact support.');
      setShowErrorMessage(true);
    }
  };

  const onError = () => {
    setErrorMessage('The form has errors. Please fix them and try again.');
    setShowErrorMessage(true);
  };

  useEffect(() => {
    if (isNil(selectedTerminalUuid)) {
      setSelectedTerminalUuid(null);
      resetForm(null);
      return;
    }
    const terminal = terminals.find((t) => t.uuid === selectedTerminalUuid);

    if (!isNil(terminal)) {
      setSelectedTerminalUuid(terminal.uuid);
      resetForm({
        mode: 'EDIT',
        name: terminal.name,
        zip: terminal.address.zip,
        city: terminal.address.city,
        state: terminal.address.state,
        line1: terminal.address.line1,
        line2: terminal.address.line2 ?? '',
        country: terminal.address.country,
        addressUuid: terminal.address.uuid,
        serviceRegion: terminal.serviceRegion ?? null,
        code: terminal.code,
        addressName: terminal.address.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminals, selectedTerminalUuid]);

  const handleNewTerminal = () => {
    navigate(`/management?tab=${ManagementTab.TERMINALS}`);

    // Uncomment if we want to add new terminals here.
    // setIsAddingNewTerminal(true);
    // setSelectedTerminalUuid(null);
  };

  const handleCancel = () => {
    setIsAddingNewTerminal(false);
    // Reset to first address or null if no addresses
    setSelectedTerminalUuid(firstTerminal?.uuid ?? null);
  };

  return (
    <Stack gap={1}>
      <Typography variant="h6">Terminals</Typography>
      <Stack direction="row" spacing={2} width="100%">
        <List
          sx={{
            flex: 1,
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '8px',
            p: 0,
            minHeight: '200px',
          }}
        >
          <Box sx={{ p: 1 }}>
            <Button
              variant="outlined"
              disabled={isAddingNewTerminal}
              sx={{ width: '100%' }}
              startIcon={<AddIcon />}
              onClick={handleNewTerminal}
            >
              New terminal
            </Button>
          </Box>
          {isAddingNewTerminal && (
            <ListItem disablePadding>
              <ListItemButton selected>
                <ListItemText
                  primary="New terminal"
                  sx={{ color: theme.palette.signalOrange[50] }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {terminals.map((terminal) => (
            <ListItem key={terminal.uuid} disablePadding>
              <ListItemButton
                selected={selectedTerminalUuid === terminal.uuid}
                onClick={() => {
                  if (!isAddingNewTerminal) {
                    setSelectedTerminalUuid(terminal.uuid);
                  }
                }}
              >
                <ListItemText
                  primary={terminal.name}
                  secondary={terminal.code}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Card
          sx={{
            flex: 3,
            p: 2,
            boxShadow: 'none',
            border: '1px solid #E0E0E0',
          }}
        >
          {isNil(firstTerminal) && !isAddingNewTerminal ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography color={theme.palette.concreteGrey[40]}>
                No terminals configured
              </Typography>
            </Stack>
          ) : (
            <Stack spacing={3}>
              <Stack spacing={2} width="80%">
                <Stack direction="row" gap={2}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: 'Name is required',
                      minLength: {
                        value: 1,
                        message: 'Name must be at least 1 character',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl error={!isNil(error)}>
                        <TextField
                          {...field}
                          required
                          disabled
                          label="Terminal name"
                          InputLabelProps={{ shrink: true }}
                        />
                        {!isNil(error) && (
                          <FormHelperText error>{error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />

                  <FormControl>
                    <TextField
                      disabled
                      label="Code"
                      InputLabelProps={{ shrink: true }}
                      {...register('code', {
                        required: 'Code is required',
                        minLength: {
                          value: 1,
                          message: 'Code must be at least 1 character',
                        },
                      })}
                      required
                    />
                    {!isNil(errors.line1) && (
                      <FormHelperText error>
                        {errors.line1.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <FormControl error={!isNil(errors.serviceRegion)}>
                  <ServiceRegionSelect
                    disabled
                    required
                    size="medium"
                    value={serviceRegion}
                    onChange={(value: ServiceRegionOption) => {
                      setValue('serviceRegion', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    onError={(error: Error) => {
                      setErrorMessage(error.message);
                      setShowErrorMessage(true);
                    }}
                  />
                  {!isNil(errors.serviceRegion) && (
                    <FormHelperText error>
                      Service region is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Stack gap={3}>
                <Stack>
                  <Typography
                    fontWeight="medium"
                    color={theme.palette.concreteGrey[70]}
                    sx={{ pl: 1 }}
                  >
                    Address
                  </Typography>
                  {isAddingNewTerminal && (
                    <Typography
                      variant="caption"
                      color={theme.palette.concreteGrey[40]}
                      sx={{ pl: 1 }}
                    >
                      This address will be automatically added to the addresses
                      for this organization
                    </Typography>
                  )}
                </Stack>
                <Stack gap={2}>
                  <FormControl fullWidth error={!isNil(errors.name)}>
                    {/* <InputLabel>Name</InputLabel> */}
                    <TextField
                      disabled
                      label="Name"
                      InputLabelProps={{ shrink: true }}
                      {...register('addressName', {
                        required: 'Name is required',
                        minLength: {
                          value: 1,
                          message: 'Name must be at least 1 character',
                        },
                      })}
                      required
                    />
                    {!isNil(errors.addressName) && (
                      <FormHelperText error>
                        {errors.addressName.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Line 1"
                      InputLabelProps={{ shrink: true }}
                      {...register('line1', {
                        required: 'Line 1 is required',
                        minLength: {
                          value: 1,
                          message: 'Line 1 must be at least 1 character',
                        },
                      })}
                      required
                    />
                    {!isNil(errors.line1) && (
                      <FormHelperText error>
                        {errors.line1.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      disabled
                      label="Line 2"
                      InputLabelProps={{ shrink: true }}
                      {...register('line2')}
                    />
                  </FormControl>

                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="City"
                        InputLabelProps={{ shrink: true }}
                        {...register('city', {
                          required: 'City is required',
                          minLength: {
                            value: 1,
                            message: 'City must be at least 1 character',
                          },
                        })}
                        required
                      />
                      {!isNil(errors.city) && (
                        <FormHelperText error>
                          {errors.city.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="State"
                        InputLabelProps={{ shrink: true }}
                        {...register('state', {
                          required: 'State is required',
                          minLength: {
                            value: 1,
                            message: 'State must be at least 1 character',
                          },
                        })}
                        required
                      />
                      {!isNil(errors.state) && (
                        <FormHelperText error>
                          {errors.state.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Zip"
                        InputLabelProps={{ shrink: true }}
                        {...register('zip', {
                          required: 'Zip is required',
                          minLength: {
                            value: 1,
                            message: 'Zip must be at least 1 character',
                          },
                        })}
                        required
                      />
                      {!isNil(errors.zip) && (
                        <FormHelperText error>
                          {errors.zip.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Country"
                        InputLabelProps={{ shrink: true }}
                        {...register('country', {
                          required: 'Country is required',
                          minLength: {
                            value: 1,
                            message: 'Country must be at least 1 character',
                          },
                        })}
                        required
                      />
                      {!isNil(errors.country) && (
                        <FormHelperText error>
                          {errors.country.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  {isAddingNewTerminal && (
                    <PalletButton
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </PalletButton>
                  )}
                  <PalletButton
                    variant="contained"
                    color="primary"
                    disabled={!isDirty}
                    loading={
                      isSubmitting ||
                      createAddressLoading ||
                      updateAddressLoading ||
                      createTerminalLoading ||
                      updateTerminalLoading
                    }
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    {isAddingNewTerminal ? 'Add terminal' : 'Save changes'}
                  </PalletButton>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Card>
      </Stack>
    </Stack>
  );
};

export default AgentTerminalsConfig;
