import { isNil } from 'lodash';

import { StopType } from '../../../generated/graphql';

export const dummyStopTypes = [
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export const inboundStopTypes = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
];

export const outboundStopTypes = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
];

/**
 * Returns true if the order is a cross-dock order. A cross-dock order is an
 * order that has a partner carrier stop on both the inbound and outbound stops.
 * @see https://notion.so/RFC-39
 */
export const isCrossDock = (
  inboundStopType: StopType | null | undefined,
  outboundStopType: StopType | null | undefined,
) => {
  return (
    !isNil(inboundStopType) &&
    !isNil(outboundStopType) &&
    dummyStopTypes.includes(inboundStopType) &&
    dummyStopTypes.includes(outboundStopType) &&
    !(inboundStopType === StopType.None && outboundStopType === StopType.None)
  );
};
