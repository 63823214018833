import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { isNil } from 'lodash';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import React, { useState } from 'react';
import useLogout from '../../../common/react-hooks/use-logout';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { EnvironmentVariables } from '../../../environment-variables';
import theme from '../../../theme';

const FOOTER_HEIGHT = '64px';

const CustomerPortalUserMenu = () => {
  const logout = useLogout();
  const me = useMeAsThirdPartyUser();

  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const contactName = me.thirdPartyUser?.contacts[0]?.displayName;

  return (
    <>
      <Stack justifyContent="flex-start" height={FOOTER_HEIGHT} padding="0">
        <Stack
          direction="row"
          alignItems="center"
          height="100%"
          padding="12px 8px"
          gap="8px"
        >
          <Stack flex={1} minWidth="0px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                noWrap
                variant="caption"
                color="white"
                fontWeight={600}
              >
                {isNilOrEmptyString(contactName)
                  ? 'No company'
                  : capitalCase(contactName)}
              </Typography>
              <IconButton
                onClick={(e) => {
                  setUserAnchorEl(e.currentTarget);
                }}
              >
                <ChevronDownIcon color="white" width="16px" height="16px" />
              </IconButton>
            </Stack>
            <Typography noWrap variant="caption" color="white">
              {me?.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Menu
        id="user-menu"
        anchorEl={userAnchorEl}
        open={!isNil(userAnchorEl)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setUserAnchorEl(null);
        }}
      >
        <MenuList dense sx={{ py: 0 }}>
          <MenuItem
            sx={{
              color: theme.palette.redColor.main,
              fontSize: 16,
            }}
            onClick={async () => {
              await logout();
            }}
          >
            Logout
          </MenuItem>
          {!isNil(EnvironmentVariables.VITE_RENDER_GIT_COMMIT) && (
            <>
              <Divider />
              <MenuItem
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    cursor: 'default',
                  },
                  fontSize: 12,
                  color: theme.palette.text.primary,
                }}
              >
                App Version:{' '}
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                {EnvironmentVariables.VITE_RENDER_GIT_COMMIT?.slice(0, 5)}
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export { CustomerPortalUserMenu };
