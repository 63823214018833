import { isNil } from 'lodash';

import { sentenceCase } from 'change-case';

import {
  type OrderSource,
  ScannedOrderFailureReason,
  ScannedOrderResultStatus,
} from '../../generated/graphql';
import { formatOrderSource } from '../../common/utils/prettyPrintUtils';
import { exhaustive } from 'shared/switch';

export const canViewCompletedOrder = (
  status: ScannedOrderResultStatus,
): boolean => {
  switch (status) {
    case ScannedOrderResultStatus.Attached:
    case ScannedOrderResultStatus.Duplicate:
    case ScannedOrderResultStatus.Failed:
    case ScannedOrderResultStatus.InProgress:
    case ScannedOrderResultStatus.InReview:
    case ScannedOrderResultStatus.Rejected: {
      return false;
    }
    case ScannedOrderResultStatus.Approved:
    case ScannedOrderResultStatus.ManuallyEntered: {
      return true;
    }
  }
};

export const canDeleteScannedOrder = (
  status: ScannedOrderResultStatus,
): boolean => {
  switch (status) {
    case ScannedOrderResultStatus.Attached:
    case ScannedOrderResultStatus.Approved:
    case ScannedOrderResultStatus.ManuallyEntered: {
      return false;
    }
    case ScannedOrderResultStatus.Duplicate:
    case ScannedOrderResultStatus.InReview:
    case ScannedOrderResultStatus.InProgress:
    case ScannedOrderResultStatus.Rejected:
    case ScannedOrderResultStatus.Failed: {
      return true;
    }
    default: {
      return exhaustive(status);
    }
  }
};

export const getFailureReasonString = (
  failureReason: ScannedOrderFailureReason,
): string => {
  switch (failureReason) {
    case ScannedOrderFailureReason.InvalidPageSize: {
      return 'Invalid page size. Use Letter';
    }
    case ScannedOrderFailureReason.InvalidPageSizeA0:
    case ScannedOrderFailureReason.InvalidPageSizeA1:
    case ScannedOrderFailureReason.InvalidPageSizeA2:
    case ScannedOrderFailureReason.InvalidPageSizeA3:
    case ScannedOrderFailureReason.InvalidPageSizeA4:
    case ScannedOrderFailureReason.InvalidPageSizeA5:
    case ScannedOrderFailureReason.InvalidPageSizeA6:
    case ScannedOrderFailureReason.InvalidPageSizeA7:
    case ScannedOrderFailureReason.InvalidPageSizeA8:
    case ScannedOrderFailureReason.InvalidPageSizeA9:
    case ScannedOrderFailureReason.InvalidPageSizeA10:
    case ScannedOrderFailureReason.InvalidPageSizeB0:
    case ScannedOrderFailureReason.InvalidPageSizeB1:
    case ScannedOrderFailureReason.InvalidPageSizeB2:
    case ScannedOrderFailureReason.InvalidPageSizeB3:
    case ScannedOrderFailureReason.InvalidPageSizeB4:
    case ScannedOrderFailureReason.InvalidPageSizeB5:
    case ScannedOrderFailureReason.InvalidPageSizeB6:
    case ScannedOrderFailureReason.InvalidPageSizeB7:
    case ScannedOrderFailureReason.InvalidPageSizeB8:
    case ScannedOrderFailureReason.InvalidPageSizeB9:
    case ScannedOrderFailureReason.InvalidPageSizeB10:
    case ScannedOrderFailureReason.InvalidPageSizeC0:
    case ScannedOrderFailureReason.InvalidPageSizeC1:
    case ScannedOrderFailureReason.InvalidPageSizeC2:
    case ScannedOrderFailureReason.InvalidPageSizeC3:
    case ScannedOrderFailureReason.InvalidPageSizeC4:
    case ScannedOrderFailureReason.InvalidPageSizeC5:
    case ScannedOrderFailureReason.InvalidPageSizeC6:
    case ScannedOrderFailureReason.InvalidPageSizeC7:
    case ScannedOrderFailureReason.InvalidPageSizeC8:
    case ScannedOrderFailureReason.InvalidPageSizeC9:
    case ScannedOrderFailureReason.InvalidPageSizeC10:
    case ScannedOrderFailureReason.InvalidPageSizeJuniorLegal:
    case ScannedOrderFailureReason.InvalidPageSizeLegal:
    case ScannedOrderFailureReason.InvalidPageSizeLetter:
    case ScannedOrderFailureReason.InvalidPageSizeTabloid: {
      const failureReasonString = failureReason.replace(
        'INVALID_PAGE_SIZE_',
        '',
      );
      return `Invalid page size: ${sentenceCase(
        failureReasonString,
      )}. Use Letter`;
    }
    case ScannedOrderFailureReason.InvalidFiletype: {
      return 'Invalid file type';
    }
    case ScannedOrderFailureReason.LowDocumentQuality: {
      return 'Low document quality';
    }
    case ScannedOrderFailureReason.Unknown: {
      return 'Unknown';
    }
    case ScannedOrderFailureReason.UnrecognizedDocument: {
      return 'Unsupported document';
    }
    case ScannedOrderFailureReason.NoHawbExtracted: {
      return 'No HAWB found';
    }
    case ScannedOrderFailureReason.TooManyPages: {
      return 'Too many pages (max 15)';
    }
  }
};

export const getStatusString = (
  status: ScannedOrderResultStatus,
  failureReason: ScannedOrderFailureReason | null | undefined,
  duplicateOrderSource: OrderSource | null | undefined,
): string => {
  switch (status) {
    case ScannedOrderResultStatus.Duplicate: {
      return `Duplicate ${formatOrderSource(
        duplicateOrderSource ?? undefined,
      )}`;
    }
    case ScannedOrderResultStatus.InProgress: {
      return 'Loading';
    }
    case ScannedOrderResultStatus.Approved: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.Failed: {
      return `${sentenceCase(status)} ${
        isNil(failureReason) ? '' : `(${getFailureReasonString(failureReason)})`
      }`;
    }
    case ScannedOrderResultStatus.Attached: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.InReview: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.ManuallyEntered: {
      return sentenceCase(status);
    }
    case ScannedOrderResultStatus.Rejected: {
      return sentenceCase(status);
    }
  }
};
