import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import DateDropdownPicker, {
  type DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../common/components/date-dropdown-picker';
import TerminalFilterButton from '../../../common/components/terminal-filter-button';
import { type Option } from '../../../common/filters/types';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  type QuoteSource,
  QuoteStatus,
  useQuotesLazyQuery,
} from '../../../generated/graphql';
import BillingPartyContactModal from '../../orders/components/standard/billing-party-contact-modal';
import QuoteRow from './components/quote-row';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { FeatureFlag } from '../../../common/feature-flags';

const PAGE_SIZE = 15;

const ViewQuotes = () => {
  const [queryQuotes, { data: quotesData, loading: quotesLoading }] =
    useQuotesLazyQuery();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [searchText, setSearchText] = useState('');
  const [originTerminalOption, setOriginTerminalOption] = useState<
    Option | undefined
  >();
  const [destinationTerminalOption, setDestinationTerminalOption] = useState<
    Option | undefined
  >();

  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );

  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showPendingQuotes, setShowPendingQuotes] = useState(true);
  const [showApprovedQuotes, setShowApprovedQuotes] = useState(true);
  const [dateOption, setDateOption] = useState<DateOption>();

  const fetchQuotes = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    let quoteStatus: QuoteStatus | undefined;
    if (showPendingQuotes && showApprovedQuotes) {
      quoteStatus = undefined;
    } else if (showPendingQuotes) {
      quoteStatus = QuoteStatus.Pending;
    } else if (showApprovedQuotes) {
      quoteStatus = QuoteStatus.Approved;
    }
    await queryQuotes({
      variables: {
        first,
        after,
        last,
        before,
        searchText,
        quoteStatus,
        originTerminalUuid: originTerminalOption?.value,
        destinationTerminalUuid: destinationTerminalOption?.value,
        createdAtStartDate: dateOption?.startDate,
        createdAtEndDate: dateOption?.endDate,
      },
    });
  };

  useEffect(() => {
    fetchQuotes({ first: PAGE_SIZE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showPendingQuotes,
    showApprovedQuotes,
    originTerminalOption?.value,
    destinationTerminalOption?.value,
    dateOption?.startDate,
    dateOption?.endDate,
  ]);

  const handleOriginChange = (option: Option | null | undefined) => {
    setOriginTerminalOption(option ?? undefined);
  };
  const handleDestinationChange = (option: Option | null | undefined) => {
    setDestinationTerminalOption(option ?? undefined);
  };

  const paginationButtons = (
    <Box sx={{ mb: 2 }}>
      <Button
        disabled={
          quotesLoading ||
          (quotesData?.quotes.pageInfo.hasPreviousPage !== true &&
            !canGoPrevious)
        }
        variant="contained"
        sx={{ mr: 2 }}
        startIcon={<ArrowLeft />}
        onClick={async () => {
          await fetchQuotes({
            last: PAGE_SIZE,
            before: quotesData?.quotes.pageInfo.startCursor,
          });
          setCanGoPrevious(false);
          setCanGoNext(true);
        }}
      >
        Previous page
      </Button>
      <Button
        disabled={
          quotesLoading ||
          (quotesData?.quotes.pageInfo.hasNextPage !== true && !canGoNext)
        }
        variant="contained"
        endIcon={<ArrowRight />}
        onClick={async () => {
          await fetchQuotes({
            first: PAGE_SIZE,
            after: quotesData?.quotes.pageInfo.endCursor,
          });
          setCanGoPrevious(true);
          setCanGoNext(false);
        }}
      >
        Next page
      </Button>
    </Box>
  );

  return (
    <Stack spacing={1} height="100%">
      <BillingPartyContactModal
        isFromQuotesPage
        open={showContactModal}
        setIsOpen={setShowContactModal}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, pt: 1 }}
      >
        <Stack direction="row" spacing={1}>
          <DateDropdownPicker
            filterTitle="Created at"
            dateOption={dateOption ?? initialDateOption}
            setDateOption={setDateOption}
            defaultFilterType={DatePickerFilterType.WeekPaginate}
          />
          {terminalsEnabled && (
            <TerminalFilterButton
              displayCode
              cacheId="QUOTES_ORIGIN_TERMINAL"
              selectedOption={originTerminalOption}
              handleChange={handleOriginChange}
              prefixText="Orig"
              includeInactiveTerminals={false}
            />
          )}
          {terminalsEnabled && (
            <TerminalFilterButton
              displayCode
              cacheId="QUOTES_DESTINATION_TERMINAL"
              selectedOption={destinationTerminalOption}
              handleChange={handleDestinationChange}
              prefixText="Dest"
              includeInactiveTerminals={false}
            />
          )}

          <TextField
            value={searchText}
            label="Search"
            size="small"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onBlur={() => {
              if (searchText.length === 0) {
                fetchQuotes({ first: PAGE_SIZE });
              }
            }}
          />
          <Button
            variant="contained"
            onClick={async () => fetchQuotes({ first: PAGE_SIZE })}
          >
            Search
          </Button>
        </Stack>
        <Button
          variant="contained"
          onClick={() => {
            setShowContactModal(true);
          }}
        >
          Create quote
        </Button>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ pl: 1 }}>
        <Button
          variant={showPendingQuotes ? 'contained' : 'outlined'}
          onClick={() => {
            setShowPendingQuotes(!showPendingQuotes);
          }}
        >
          Pending
        </Button>
        <Button
          variant={showApprovedQuotes ? 'contained' : 'outlined'}
          onClick={() => {
            setShowApprovedQuotes(!showApprovedQuotes);
          }}
        >
          Approved
        </Button>
      </Stack>
      {quotesLoading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Card>
            <TableContainer sx={{ height: '100%' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Consignee</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Total charge</TableCell>
                    {ffCustomerPortalQuoteEntry && (
                      <TableCell>Source</TableCell>
                    )}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotesData?.quotes.edges.map(({ node: quote }) => (
                    // eslint-disable-next-line react/jsx-key
                    <QuoteRow quote={quote} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {paginationButtons}
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ViewQuotes;
