import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import {
  type OrderAddedToInvoice,
  type OrderToAddToInvoice,
} from '../../../../../generated/graphql';

const OrdersListForResultCard = ({
  orders,
}: {
  readonly orders: OrderAddedToInvoice[] | OrderToAddToInvoice[];
}) => {
  return (
    <Stack>
      {orders.map((order) => (
        <Typography
          key={order.orderUuid}
          pl={1}
        >{`${order.orderName}`}</Typography>
      ))}
    </Stack>
  );
};

export default OrdersListForResultCard;
