import {
  Box,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { safeMultiply, safeDivide } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../../../../common/types';
import { PermissionResource } from '../../../../../../../generated/graphql';
import { type OrderFormValues } from '../../../forms/types';
import { DriverSettlementDeductionRateField } from './driver-settlement-deduction-rate-field';
import { isNil } from 'lodash';
const DriverSettlementDeductionRow = ({
  idx,
  totalCharge,
}: {
  readonly idx: number;
  readonly totalCharge: number;
}) => {
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );

  const { setValue, control } = useFormContext<OrderFormValues>();

  const stops = useWatch({ control, name: 'stops' });

  const settlementDeductionPercentageRate = useWatch({
    control,
    name: `stops.${idx}.settlementDeductionPercentageRate`,
  });
  const settlementDeductionFlatRate = useWatch({
    control,
    name: `stops.${idx}.settlementDeductionFlatRate`,
  });
  const deductionTotal = useWatch({
    control,
    name: `stops.${idx}.settlementTotal`,
  });
  const billingMethod =
    useWatch({ control, name: `stops.${idx}.settlementBillingMethod` }) ??
    BillingMethod.Percentage;

  useEffect(() => {
    if (isNil(stops)) {
      return;
    }
    if (billingMethod === BillingMethod.FlatRate) {
      setValue(`stops.${idx}.settlementTotal`, settlementDeductionFlatRate);
    }
    if (billingMethod === BillingMethod.Percentage) {
      setValue(
        `stops.${idx}.settlementTotal`,
        safeMultiply(
          safeDivide(settlementDeductionPercentageRate ?? 0, 100, 5),
          totalCharge ?? 0,
          5,
        ),
      );
    }
  }, [
    settlementDeductionPercentageRate,
    settlementDeductionFlatRate,
    totalCharge,
    stops,
  ]);

  if (isNil(stops)) {
    return null;
  }

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <FormControl>
            <InputLabel>Deduction</InputLabel>
            <Select
              label="Deduction"
              value={billingMethod}
              size="small"
              disabled={!canWriteOrders}
              onChange={(e) => {
                if (e.target.value === BillingMethod.FlatRate) {
                  setValue(
                    `stops.${idx}.settlementDeductionPercentageRate`,
                    undefined,
                  );
                  setValue(`stops.${idx}.settlementDeductionFlatRate`, 0);
                  setValue(
                    `stops.${idx}.settlementBillingMethod`,
                    BillingMethod.FlatRate,
                  );
                }
                if (e.target.value === BillingMethod.Percentage) {
                  setValue(`stops.${idx}.settlementDeductionPercentageRate`, 0);
                  setValue(
                    `stops.${idx}.settlementDeductionFlatRate`,
                    undefined,
                  );
                  setValue(
                    `stops.${idx}.settlementBillingMethod`,
                    BillingMethod.Percentage,
                  );
                }
              }}
            >
              <MenuItem value={BillingMethod.FlatRate}>Flat rate</MenuItem>
              <MenuItem value={BillingMethod.Percentage}>Percentage</MenuItem>
            </Select>
          </FormControl>
          <Controller
            name={`stops.${idx}.settlementName`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Name"
                size="small"
                value={value}
                disabled={!canWriteOrders}
                onChange={onChange}
              />
            )}
          />
        </Box>
      </TableCell>
      <TableCell>
        <DriverSettlementDeductionRateField
          idx={idx}
          disabled={!canWriteOrders}
        />
      </TableCell>
      <TableCell>-${deductionTotal?.toFixed(2) ?? '0.00'}</TableCell>
    </TableRow>
  );
};

export default React.memo(DriverSettlementDeductionRow);
