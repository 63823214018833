import { isNil } from 'lodash';
import {
  PageSizes,
  PDFDocument,
  type PDFEmbeddedPage,
  type PDFImage,
} from 'pdf-lib';

type GetDimsForDocumentProps = {
  documentWidth: number;
  documentHeight: number;
  fileType: string;
  pageWidth: number;
  pageHeight: number;
  documentImage?: PDFImage;
  documentPdf?: PDFEmbeddedPage;
};

const getDimsForDocument = ({
  documentWidth,
  documentHeight,
  fileType,
  pageWidth,
  pageHeight,
  documentImage,
  documentPdf,
}: GetDimsForDocumentProps) => {
  let dims;
  if (documentWidth > pageWidth || documentHeight > pageHeight) {
    let widthFactor;
    let heightFactor;
    let scaleFactor;
    let newWidth = documentWidth;
    let newHeight = documentHeight;
    if (documentWidth > pageWidth) {
      widthFactor = pageWidth / documentWidth;
    }
    if (documentHeight > pageHeight) {
      heightFactor = pageHeight / documentHeight;
    }
    if (
      !isNil(heightFactor) &&
      (isNil(widthFactor) || (widthFactor ?? 0) >= (heightFactor ?? 0))
    ) {
      newWidth *= heightFactor ?? 1;
      newHeight *= heightFactor ?? 1;
      scaleFactor = heightFactor;
    } else if (
      !isNil(widthFactor) &&
      (isNil(heightFactor) || (heightFactor ?? 0) >= (widthFactor ?? 0))
    ) {
      newWidth *= widthFactor ?? 1;
      newHeight *= widthFactor ?? 1;
      scaleFactor = widthFactor;
    }

    switch (fileType) {
      case 'image/png': {
        dims = documentImage?.scaleToFit(newWidth, newHeight);
        break;
      }
      case 'application/pdf': {
        dims = documentPdf?.scale(scaleFactor ?? 1);
        break;
      }
      case 'image/jpeg':
      case 'image/jpg': {
        dims = documentImage?.scaleToFit(newWidth, newHeight);
        break;
      }
    }
  }
  return dims;
};

// TODO: break this out into a shared util
const createPagesForPdf = async (
  documentBytes: ArrayBuffer,
  fileType: string,
  pdfDoc: PDFDocument,
  directlyAttachPdfPagesUsingCopy = false,
) => {
  if (
    fileType === 'image/png' ||
    fileType === 'image/jpg' ||
    fileType === 'image/jpeg'
  ) {
    let img: PDFImage | null | undefined;
    try {
      img = await pdfDoc.embedPng(documentBytes);
    } catch (error) {
      if (fileType === 'image/png') {
        // eslint-disable-next-line no-console
        console.error(
          'Could not generate png image for fileType image/png. Attempting to generate jpg image.',
          error,
        );
      }
      try {
        img = await pdfDoc.embedJpg(documentBytes);
      } catch (error) {
        if (fileType === 'image/jpg' || fileType === 'image/jpeg') {
          // eslint-disable-next-line no-console
          console.error(
            'Could not generate jpg image for fileType image/jpg.',
            error,
          );
        }
      }
    }
    if (!isNil(img)) {
      const { width: imgWidth, height: imgHeight } = img.size();
      const page = pdfDoc.addPage();
      const dims = getDimsForDocument({
        documentWidth: imgWidth,
        documentHeight: imgHeight,
        pageWidth: page.getWidth(),
        pageHeight: page.getHeight(),
        fileType,
        documentImage: img,
      });

      page.drawImage(img, dims);
    }
  } else if (fileType === 'application/pdf') {
    const document = await PDFDocument.load(documentBytes, {
      ignoreEncryption: true,
    });

    if (!isNil(document) && directlyAttachPdfPagesUsingCopy) {
      const pages = await document.getPages();

      const copiedPages = await pdfDoc.copyPages(
        document,
        pages.map((_, idx) => idx),
      );

      for (const page of copiedPages) {
        pdfDoc.addPage(page); // This adds each copied page to the end of `pdfDoc`
      }
    } else if (!isNil(document)) {
      const pages = await document.getPages();
      await Promise.all(
        pages.map(async (pageData) => {
          const embeddedPage = await pdfDoc.embedPage(pageData);
          const { width: pdfWidth, height: pdfHeight } = pageData.getSize();
          const addedPage = pdfDoc.addPage(PageSizes.A4);
          const dims = getDimsForDocument({
            documentWidth: pdfWidth,
            documentHeight: pdfHeight,
            pageWidth: addedPage.getWidth(),
            pageHeight: addedPage.getHeight(),
            fileType,
            documentPdf: embeddedPage,
          });
          addedPage.drawPage(embeddedPage, dims);
        }),
      );
    }
  }
};

export default createPagesForPdf;

export const CHECKED_CHECKBOX_IMAGE_URL =
  'https://img.icons8.com/ios/50/checked-checkbox--v1.png';
export const UNCHECKED_CHECKBOX_IMAGE_URL =
  'https://img.icons8.com/ios/50/unchecked-checkbox.png';
