import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  Email,
} from '@mui/icons-material';
import { IconButton, Stack, styled, type Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { downloadImage } from '../../domains/end-of-day/end-of-day-utils';
import { type DocViewerDocument } from '../../domains/end-of-day/types/doc-viewer-document';
import { getDocumentTypeIcon } from '../../domains/orders/components/order-form/components/overview/documents/utils';
import PalletModalOld from '../../pallet-ui/modal/pallet-modal-old';
import useDocuments from '../react-hooks/use-documents';
import { isNilOrEmptyString } from '../utils/utils';

const EML_FILE_TYPE = 'message/rfc822';

const getNavigationButtonPosition = (theme: Theme) => ({
  xs: theme.spacing(2),
  sm: theme.spacing(3),
  xl: '-5%',
});

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  zIndex: 1,
  '&:hover': {
    backgroundColor: theme.palette.hoverColor.main,
  },
}));

type DocumentViewerModalProps = {
  readonly showDocViewerModal: boolean;
  readonly doc: DocViewerDocument;
  readonly onClose: () => void;
  readonly hasPrevDoc?: boolean;
  readonly hasNextDoc?: boolean;
  readonly onClickBack?: () => void;
  readonly onClickForward?: () => void;
};

/**
 * Displays a single document in a modal, with optional navigation buttons.
 *
 * You probably want to show a list of documents: @see {@link DocumentsViewerModal}
 */
const DocumentViewerModal = ({
  showDocViewerModal,
  doc,
  onClose,
  hasPrevDoc,
  hasNextDoc,
  onClickBack,
  onClickForward,
}: DocumentViewerModalProps) => {
  const theme = useTheme();

  const { getDocumentTypeCopy, loading } = useDocuments();

  const showNavigationButtons =
    !isNilOrEmptyString(onClickBack) && !isNilOrEmptyString(onClickForward);

  useEffect(() => {
    if (!showNavigationButtons) {
      return noop;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        onClickBack();
      } else if (event.key === 'ArrowRight') {
        onClickForward();
      }
    };

    globalThis.addEventListener('keydown', handleKeyDown);
    return () => {
      globalThis.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClickBack, onClickForward, showNavigationButtons]);

  if (loading) {
    return null;
  }
  return (
    <PalletModalOld
      hideCancel
      open={showDocViewerModal}
      title={
        <Stack direction="column">
          <Stack direction="row" alignItems="center" spacing={1}>
            {getDocumentTypeIcon(doc.docType, doc.fileType)}
            <Typography variant="h6">
              {getDocumentTypeCopy({ documentType: doc.docType })}
            </Typography>
          </Stack>
          <Typography variant="subtitle1" color="text.secondary">
            {doc.fileName}
          </Typography>
        </Stack>
      }
      actions={{
        topRight: [
          {
            variant: 'outlined',
            onClick: async () =>
              downloadImage(doc.preSignedGetUrl, doc?.fileName),
            children: 'Download',
          },
        ],
      }}
      width="lg"
      onClose={onClose}
    >
      <Stack spacing={2} direction="column" height="75vh">
        <Stack spacing={2} direction="row" height="100%">
          {showNavigationButtons && hasPrevDoc === true && (
            <NavigationButton
              sx={{ left: getNavigationButtonPosition(theme) }}
              onClick={onClickBack}
            >
              <ArrowBackOutlined sx={{ fontSize: '2rem' }} />
            </NavigationButton>
          )}
          {doc.fileType === EML_FILE_TYPE ? (
            // Displaying .eml files in an <object> automatically downloads them, not a great UX
            <Stack
              alignItems="center"
              justifyContent="center"
              gap={2}
              height="100%"
              flexGrow={1}
              sx={{ color: theme.palette.text.secondary }}
            >
              <Email />
              <Typography>
                Please download .eml files to preview them.
              </Typography>
            </Stack>
          ) : (
            <object
              key={doc.uuid}
              style={{
                flexGrow: 1,
                objectFit: 'contain',
              }}
              // Without this key, objects don't re-render / remove themselves when the doc changes
              data={doc.preSignedGetUrl}
            >
              {' '}
            </object>
          )}
          {showNavigationButtons && hasNextDoc === true && (
            <NavigationButton
              sx={{ right: getNavigationButtonPosition(theme) }}
              onClick={onClickForward}
            >
              <ArrowForwardOutlined sx={{ fontSize: '2rem' }} />
            </NavigationButton>
          )}
        </Stack>
        {!isNilOrEmptyString(doc.notes) && (
          <Typography variant="subtitle1" color="gray">
            Notes: {doc.notes}
          </Typography>
        )}
      </Stack>
    </PalletModalOld>
  );
};

export default DocumentViewerModal;
