import {
  type MeAsThirdPartyUserQuery,
  OrderSource,
} from '../../../../../generated/graphql';
import { useMemo } from 'react';
import { type GetOrderTableFieldValuesInput } from '../../../../../generated/graphql';
import { type GetOrderTableFieldValuesQueryVariables } from '../../../../../generated/graphql';
import { useCallback } from 'react';
import { useGetOrderTableFieldValuesQuery } from '../../../../../generated/graphql';
import { type QueryHookOptions } from '@apollo/client';
import { type GetOrderTableFieldValuesQuery } from '../../../../../generated/graphql';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../../../common/react-hooks/use-me-as-third-party-user';
import { CUSTOMER_PORTAL_PENDING_ORDERS_TABLE_FIELDS } from '../constants';

export const getCustomerPortalPendingOrdersFilters = (
  contacts: NonNullable<
    MeAsThirdPartyUserQuery['meAsThirdPartyUser']
  >['contacts'],
) => {
  const contactsUuids = contacts.map((c) => c.uuid);
  return {
    isDraftOrderFilter: {
      eq: true,
    },
    orderSourceFilter: {
      eq: OrderSource.CustomerPortal,
    },
    customerUuidFilter: {
      in: contactsUuids,
    },
  } satisfies GetOrderTableFieldValuesInput['filters'];
};

type PaginationVariables = Pick<
  GetOrderTableFieldValuesInput,
  'first' | 'last' | 'after' | 'before'
>;

/**
 * Wraps the getOrderTableFieldValues query with a fixed set of filters and
 * fields to fetch pending customer portal orders.
 */
export const useCustomerPortalPendingOrders = ({
  first,
  last,
  after,
  before,
  ...queryHookOptions
}: PaginationVariables &
  QueryHookOptions<
    GetOrderTableFieldValuesQuery,
    GetOrderTableFieldValuesQueryVariables
  >) => {
  const { thirdPartyUser, loading: meAsThirdPartyUserLoading } =
    useMeAsThirdPartyUser();

  const CUSTOMER_PORTAL_PENDING_ORDERS_FILTERS = useMemo(
    () => getCustomerPortalPendingOrdersFilters(thirdPartyUser?.contacts ?? []),
    [thirdPartyUser?.contacts],
  );

  const enableNewTableFunctions = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_TABLE_FUNCTIONS,
  );

  const initialVariables = useMemo(
    () => ({
      ...(enableNewTableFunctions
        ? { filters: CUSTOMER_PORTAL_PENDING_ORDERS_FILTERS }
        : CUSTOMER_PORTAL_PENDING_ORDERS_FILTERS),
      orderTableFields: CUSTOMER_PORTAL_PENDING_ORDERS_TABLE_FIELDS,
      totalCount: true,
    }),
    [enableNewTableFunctions, CUSTOMER_PORTAL_PENDING_ORDERS_FILTERS],
  );

  const { data, loading, error, refetch } = useGetOrderTableFieldValuesQuery({
    variables: {
      getOrderTableFieldValuesInput: {
        ...initialVariables,
        first,
        last,
        after,
        before,
      },
    },
    ...queryHookOptions,
    // Wait for contacts to load before fetching orders
    skip: meAsThirdPartyUserLoading || queryHookOptions.skip,
  });

  const refetchWithInitialVariables = useCallback(
    async (
      variables: Omit<
        GetOrderTableFieldValuesInput,
        'orderTableFields' | 'filters'
      >,
    ) =>
      refetch({
        getOrderTableFieldValuesInput: {
          ...variables,
          ...initialVariables,
        },
      }),
    [refetch, initialVariables],
  );

  return {
    ...data?.getOrderTableFieldValues.formattedOrderConnection,
    loading,
    error,
    refetch: refetchWithInitialVariables,
  };
};
