/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment */
import { isEmpty, isNil } from 'lodash';

import {
  type ICellRendererParams,
  type ColDef,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { TextFilter } from 'ag-grid-community';
import { capitalCase, sentenceCase } from 'change-case';
import {
  AppointmentTextStatus,
  AppointmentCallStatus,
  type AppointmentFragment,
  StandardStopType,
  useContactsSimpleQuery,
} from '../../../generated/graphql';
import {
  BooleanFilter,
  MultiSelectFilter,
  SingleSelectFilter,
} from '../../ag-grid/filter-components';
import AppointmentFilterField from '../enums/appointment-filters';
import { useMemo } from 'react';
import PhoneNumberTextField from '../components/phone-number-field';
import dayjs from 'dayjs';
import useServices from '../../../common/react-hooks/use-services';
import { AppointmentTableField } from '../enums/appointment-table-fields';
import { getAppointmentTableFieldHeaderCopy } from '../components/utils/utils';

type AppointmentFragmentFilterColDef = {
  field: AppointmentFilterField;
  headerName?: string;
  hide: boolean;
  suppressColumnsToolPanel: boolean;
  filter?:
    | typeof SingleSelectFilter
    | typeof MultiSelectFilter
    | typeof TextFilter;
  filterParams?: {
    values?: unknown;
    defaultToNothingSelected: boolean;
    suppressSelectAll: boolean;
    keyCreator?: (params: ValueFormatterParams) => string;
    valueFormatter?: (params: ValueFormatterParams) => string;
    inDispatchTable?: true;
  };
} & ColDef<AppointmentFragment>;

export const useAppointmentsTableColumns = () => {
  const { services } = useServices();
  const { data: contactsData } = useContactsSimpleQuery({
    fetchPolicy: 'cache-first',
    variables: {
      excludeNonActive: true,
    },
  });

  const filterColumns: AppointmentFragmentFilterColDef[] = useMemo(() => {
    return [
      {
        field: AppointmentFilterField.CONSIGNEE,
        headerName: 'Consignee',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: TextFilter,
      },
      {
        field: AppointmentFilterField.TEXT_STATUS,
        headerName: 'Text status',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: SingleSelectFilter,
        filterParams: {
          values: Object.values(AppointmentTextStatus).map((status) => ({
            actualValue: status,
            displayValue: sentenceCase(status),
          })),
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayValue),
        },
      },
      {
        field: AppointmentFilterField.CALL_STATUS,
        headerName: 'Call status',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: SingleSelectFilter,
        filterParams: {
          values: [
            AppointmentCallStatus.Called,
            AppointmentCallStatus.Confirmed,
            AppointmentCallStatus.Failed,
            AppointmentCallStatus.InProgress,
            AppointmentCallStatus.Rejected,
          ].map((status) => ({
            actualValue: status,
            displayValue: sentenceCase(status),
          })),
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayValue),
        },
      },
      {
        field: AppointmentFilterField.SERVICE_LEVEL,
        headerName: 'Service level',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: MultiSelectFilter,
        filterParams: {
          values: services.map((service) => ({
            actualValue: service.uuid,
            displayValue: service.name,
          })),
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayValue),
        },
      },
      {
        field: AppointmentFilterField.PHONE,
        headerName: 'Phone',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: TextFilter,
      },
      {
        field: AppointmentFilterField.APPOINTMENT_CONTACT,
        headerName: 'Contact',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: TextFilter,
      },
      {
        field: AppointmentFilterField.ADDRESS_TYPE,
        headerName: 'Address type',
        filter: SingleSelectFilter,
        hide: true,
        suppressColumnsToolPanel: true,
        filterParams: {
          values: [
            StandardStopType.Commercial,
            StandardStopType.Residential,
          ].map((type) => ({
            actualValue: type,
            displayValue: sentenceCase(type),
          })),
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayValue),
        },
      },
      {
        field: AppointmentFilterField.APPOINTMENT_REQUIRED,
        headerName: 'Appt required',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: BooleanFilter,
        filterParams: {
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayValue),
        },
      },
      {
        field: AppointmentFilterField.CUSTOMER,
        headerName: 'Customer',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: SingleSelectFilter,
        filterParams: {
          values:
            contactsData?.contacts.map((contact) => ({
              actualValue: contact.uuid,
              displayValue: contact.displayName,
            })) ?? [],
          defaultToNothingSelected: true,
          suppressSelectAll: true,
          keyCreator: (params: ValueFormatterParams) => params.value.uuid,
          valueFormatter: (params: ValueFormatterParams) =>
            sentenceCase(params.value.displayName),
        },
      },
    ].sort((a, b) => a.field.toString().localeCompare(b.field.toString()));
  }, [services, contactsData]);

  const displayColumns: Array<ColDef<AppointmentFragment>> = [
    {
      field: 'checkbox',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      lockPosition: 'left',
    },
    {
      field: AppointmentTableField.ORDER_NUMBER,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.ORDER_NUMBER,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return (
          params.data?.leg?.shipment?.order?.standardOrderFields
            .shipperBillOfLadingNumber ??
          params.data?.leg?.shipment?.order?.name
        );
      },
    },
    {
      field: AppointmentTableField.CUSTOMER,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.CUSTOMER,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return (
          params?.data?.leg?.shipment?.order?.billingPartyContact
            ?.displayName ?? '-'
        );
      },
    },
    {
      field: AppointmentTableField.CONSIGNEE,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.CONSIGNEE,
      ),
      minWidth: 120,
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        if (isEmpty(params.data?.shipmentConsigneeAddress?.name)) {
          return 'NA';
        }
        return params.data?.shipmentConsigneeAddress?.name;
      },
      filter: TextFilter,
    },
    {
      field: AppointmentTableField.APPOINTMENT,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.APPOINTMENT,
      ),
      minWidth: 150,
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        if (isNil(params.data)) {
          return 'No appointment';
        }
        let appointmentString = '';
        const appointmentTime = params.data?.appointmentTime;
        const endAppointmentTime = params.data?.endAppointmentTime;
        if (!isNil(appointmentTime)) {
          appointmentString += `${dayjs(appointmentTime).format('hh:mm a')}`;
        }
        if (!isNil(appointmentTime) && !isNil(endAppointmentTime)) {
          appointmentString += ' - ';
        }
        if (!isNil(endAppointmentTime)) {
          appointmentString += `${dayjs(endAppointmentTime).format('hh:mm a')}`;
        }
        if (isNil(appointmentTime) && isNil(endAppointmentTime)) {
          appointmentString = 'No appointment';
        }
        return appointmentString;
      },
    },
    {
      field: AppointmentTableField.CITY,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.CITY,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(params.data?.address?.city ?? '-');
      },
    },
    {
      field: AppointmentTableField.APPOINTMENT_MANUALLY_CONFIRMED,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.APPOINTMENT_MANUALLY_CONFIRMED,
      ),
      // minWidth: '100',
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(
          params.data?.appointmentManuallyConfirmed === true ? 'Yes' : 'No',
        );
      },
    },
    {
      field: AppointmentTableField.STOP_TYPE,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.STOP_TYPE,
      ),
      // minWidth: '10',
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(
          // eslint-disable-next-line custom-rules/no-pickup-or-delivery-use
          params.data?.leg?.shipment?.standardShipmentFields
            ?.pickupOrDelivery ?? 'NA',
        );
      },
    },
    {
      field: AppointmentTableField.TEXT_RESPONSES,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.TEXT_RESPONSES,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return params.data?.textResponses ?? 'None';
      },
    },
    {
      field: AppointmentTableField.TEXT_STATUS,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.TEXT_STATUS,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(params.data?.appointmentTextStatus ?? 'Not sent');
      },
    },
    {
      field: AppointmentTableField.CALL_STATUS,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.CALL_STATUS,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(params.data?.appointmentCallStatus ?? 'Not called');
      },
    },
    {
      field: AppointmentTableField.SERVICE_LEVEL,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.SERVICE_LEVEL,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return capitalCase(
          params.data?.leg?.shipment?.standardShipmentFields?.service?.name ??
            'None',
        );
      },
    },
    {
      field: AppointmentTableField.DRIVER,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.DRIVER,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        const driver = params.data?.routeSlot?.route?.drivers[0];
        const route = params.data?.routeSlot?.route;
        if (!isNil(driver)) {
          return `${driver.firstName ?? 'Driver'} ${driver.lastName ?? ''}`;
        }
        if (!isNil(route)) {
          return 'No driver';
        }
        return 'No route';
      },
    },
    {
      field: AppointmentTableField.ADDRESS,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.ADDRESS,
      ),
      minWidth: 160,
      cellRenderer: (params: ICellRendererParams<AppointmentFragment>) => {
        const address = params.data?.address;
        return (
          <>
            <div>{capitalCase(address?.line1 ?? '-')}, </div>
            <div>{!isEmpty(address?.zip) && address?.zip}</div>
          </>
        );
      },
    },
    {
      field: AppointmentTableField.PHONE,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.PHONE,
      ),
      minWidth: 120,
      cellRenderer: PhoneNumberTextField,
    },
    {
      field: AppointmentFilterField.APPOINTMENT_CONTACT,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.APPOINTMENT_CONTACT,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        if (
          isNil(params.data?.contactPerson) ||
          (isEmpty(params.data?.contactPerson?.firstName) &&
            isEmpty(params.data?.contactPerson?.lastName))
        ) {
          return 'None';
        }
        return capitalCase(
          `${params.data?.contactPerson?.firstName ?? ''} ${
            params.data?.contactPerson?.lastName ?? ''
          }`,
        );
      },
    },
    {
      field: AppointmentTableField.ADDRESS_TYPE,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.ADDRESS_TYPE,
      ),
      filter: SingleSelectFilter,
      hide: true,
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return params.data?.standardStopType;
      },
    },
    {
      field: AppointmentTableField.APPOINTMENT_REQUIRED,
      headerName: getAppointmentTableFieldHeaderCopy(
        AppointmentTableField.APPOINTMENT_REQUIRED,
      ),
      valueGetter: (params: ValueGetterParams<AppointmentFragment>) => {
        return params.data?.appointmentRequired === true ? 'Yes' : 'No';
      },
    },
  ];

  const columnDefs: Array<ColDef<AppointmentFragment>> = [
    ...filterColumns,
    ...displayColumns,
  ];

  return {
    columnDefs,
    filterColumns,
  };
};
