import { Box, Stack } from '@mui/material';
import React from 'react';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import theme from '../../../../../theme';
import { useIsEditingDisabled } from '../../../hooks/use-page-mode';
import { type AutocompletePerson } from '../../standard/components/autocomplete-person';
import { useOrderFormEditAccess } from '../contexts/order-form-edit-access-context';
import HawbComponent from './hawb-component';
import MawbComponent from './mawb-component';
import { OrderFormCard } from './order-form-card';
import OrderPerson from './order-person';
import ReferenceNumbers from './reference-numbers';
import Service from './service';
import {
  DemoShipmentType,
  ShipmentTypeDemoField,
  useShipmentTypeDemoFieldValue,
} from './shipment-type-demo-field';
import { TruckloadShipmentCarrierDemoField } from './stops/truckload-shipment-carrier-demo-field';

type DetailsProps = {
  readonly isEditMode: boolean;
  readonly hawbRequired?: boolean;
  readonly setAutocompletePerson: (person: AutocompletePerson | null) => void;
  readonly showDuplicateHawbWarnings?: boolean;
};

const Details = ({
  isEditMode,
  hawbRequired = true,
  showDuplicateHawbWarnings = true,
  setAutocompletePerson,
}: DetailsProps) => {
  const editingDisabled = useIsEditingDisabled();

  const { disabledIfNoAccess, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const ffShowCustomerContact = useFeatureFlag(
    FeatureFlag.FF_SHOW_CUSTOMER_CONTACT,
  );
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );
  const [demoShipmentType] = useShipmentTypeDemoFieldValue();

  const showOrderPerson = ffShowCustomerContact || ffUseStations;

  return (
    <OrderFormCard
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',
      }}
    >
      <Stack direction="row" alignItems="start" gap="14px" flexWrap="wrap">
        <Box
          sx={{
            flexBasis: '220px',
          }}
        >
          <HawbComponent
            editingDisabled={editingDisabled || disabledIfNoAccess}
            required={hawbRequired}
            showDuplicateWarnings={showDuplicateHawbWarnings}
          />
        </Box>
        {!ffDemoLoadManagement && (
          <Box
            sx={{
              flexBasis: '220px',
            }}
          >
            <MawbComponent
              editingDisabled={editingDisabled || disabledIfInvoicePosted}
            />
          </Box>
        )}
        <ReferenceNumbers />
      </Stack>
      <Stack
        sx={{
          gap: '14px',
          flexDirection: 'column',
          alignItems: 'stretch',
          [theme.breakpoints.up(2360)]: {
            flexDirection: 'row',
            alignItems: 'start',
          },
        }}
      >
        {showOrderPerson && (
          <OrderPerson
            editingDisabled={editingDisabled}
            isEditMode={isEditMode}
            setAutocompletePerson={setAutocompletePerson}
          />
        )}
        {ffDemoLoadManagement ? (
          <Stack direction="row" gap="14px" alignItems="start">
            <ShipmentTypeDemoField />
            {demoShipmentType === DemoShipmentType.Truckload && (
              <TruckloadShipmentCarrierDemoField />
            )}
            <Service isEditMode={isEditMode} />
          </Stack>
        ) : (
          <Service isEditMode={isEditMode} />
        )}
      </Stack>
    </OrderFormCard>
  );
};

export default React.memo(Details);
