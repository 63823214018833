import currency from 'currency.js';
import { isNil, unzip } from 'lodash';
import { MAX_INTEGER } from 'shared/math';
import { type Scalar } from 'shared/units/scalar';
import { v4 } from 'uuid';
import {
  type RateType,
  type TariffCreateInput,
  type TariffForTariffGroupFragment,
  type TariffGroupFragment,
  type TariffGroupScope,
  type TariffGroupType,
  type TariffOverageRateCreateInput,
  TariffOverageRateType,
  type TariffType,
  type TariffUpsertInput,
  type TariffZoneCreateInput,
  TariffZoneType,
  type TariffZoneUpdateInput,
} from '../../../../../generated/graphql';
import {
  MAX_MATRIX_SIZE,
  type MinMaxAmountValue,
  type OverageRates,
  initialMinMaxAmountValue,
} from '../types';

export type LocationZoneData = {
  uuid: string;
  name: string;
  isLhLaneActive?: boolean;
};

export enum TariffModalMode {
  EDIT,
  CREATE,
}

export type TariffGroupState = {
  tariffGroupUuid: string | undefined;
  selectedContactUuids: string[];
  rateMatrix: Array<Array<number | undefined>>;
  zoneType: TariffZoneType;
  tariffType: TariffType;
  rateType: RateType;
  xRangeValues: Array<number | undefined>;
  yRangeValues: Array<number | undefined>;
  locationZonesData: LocationZoneData[];
  minMaxAmountValues: MinMaxAmountValue[];
  settlementPercentageRateValues: Array<Scalar | null>;
  zoneBasedOverageRates: OverageRates[];
  useGlobalOverageRates: boolean;
  name: string | null;
  tariffGroupType: TariffGroupType;
  fuelProfileUuid: string | null | undefined;
  tariffGroupScope: TariffGroupScope | null;

  contactUuidToServiceUuidsMapping: Record<string, string[]>;

  existingTariffGroupBeforeChanges: TariffGroupFragment | null;
  modalMode: TariffModalMode | null;
  globalOverageRates: OverageRates;

  // mileOverageRate: number | null;
  // mileOverageApplicableAbove: number | null;
  // weightOverageRate: number | null;
  // weightOverageApplicableAbove: number | null;

  useActualWeight: boolean;
  parentTariffGroupUuid: string | null;
  rateMultiplier: number | null;
  startDate: Date | null;
  endDate: Date | null;
  useDeficitBilling: boolean;

  useZoneGroups: boolean;
  tariffZoneGroupId: string | null;
  allZonesInGroup: LocationZoneData[];

  terminalUuids: string[];
  serviceUuids: string[];
  vehicleTypeUuids: string[];

  isLoadingTariffGroup: boolean;
};

export const sortTariffs = (tariffs: TariffForTariffGroupFragment[]) => {
  // if it's mile range, sort by the mile range not alphabetically.
  if (tariffs.some((t) => t.tariffZone.type === TariffZoneType.Miles)) {
    tariffs.sort((a, b) => {
      const aZone = a.tariffZone;
      const bZone = b.tariffZone;
      if (!isNil(aZone) && !isNil(bZone)) {
        const aEnd = aZone.mileRangeEnd;
        const bEnd = bZone.mileRangeEnd;
        if (!isNil(aEnd) && !isNil(bEnd)) {
          return aEnd - bEnd;
        }
      }
      return 0;
    });
  } else {
    tariffs.sort((a, b) => a.tariffZone.name.localeCompare(b.tariffZone.name));
  }

  return tariffs;
};

export const getValueWithinBounds = (amount: number) => {
  if (amount < 0) {
    return 0;
  }
  if (amount > MAX_INTEGER) {
    return MAX_INTEGER;
  }
  return amount;
};
export const convertDollarsToCents = (amount: number) => {
  return getValueWithinBounds(
    currency(amount, { precision: 3 }).multiply(100).value,
  );
};
export const convertCentsToDollars = (cents: number) => {
  return currency(cents, { precision: 3 }).divide(100).value;
};

const getLowerUpperBound = (
  index: number,
  range: Array<number | undefined>,
) => {
  const lowerBound = index > 0 ? range[index - 1] : 0;
  const upperBound = index < range.length - 1 ? range[index] : MAX_INTEGER;
  return [lowerBound, upperBound];
};

const getTariffZoneChangesForMileZoneType = (
  currentState: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  const tariffZoneCreates: TariffZoneCreateInput[] = [];
  const tariffZoneUpdates: TariffZoneUpdateInput[] = [];
  const tariffZoneDeletes: string[] = [];
  const tariffUuids: string[] = [];
  let currentRangeIndex = 0;

  const oldZoneUpdates = currentState.existingTariffGroupBeforeChanges?.tariffs
    .filter((tariff) => tariff.tariffZone.type !== TariffZoneType.Location) // exclude location because locations are only modified in global zone editor
    .map((tariff) => tariff.tariffZone);
  while (
    !isNil(oldZoneUpdates) &&
    currentRangeIndex < (oldZoneUpdates?.length ?? 0)
  ) {
    const tariffZone = oldZoneUpdates[currentRangeIndex];
    if (!isNil(tariffZone)) {
      if (currentRangeIndex < currentState.xRangeValues.length) {
        const { uuid } = tariffZone;
        const [lowerBound, upperBound] = getLowerUpperBound(
          currentRangeIndex,
          currentState.xRangeValues,
        );
        tariffZoneUpdates.push({
          mileRangeEnd: upperBound,
          mileRangeStart: lowerBound,
          name: `${lowerBound} to ${
            upperBound === MAX_INTEGER ? 'Infinity' : upperBound
          } miles`,
          type: currentState.zoneType,
          uuid,
        });
        tariffUuids.push(uuid);
      } else {
        tariffZoneDeletes.push(tariffZone.uuid);
      }
    }
    currentRangeIndex += 1;
  }
  while (currentRangeIndex < currentState.xRangeValues.length) {
    const [lowerBound, upperBound] = getLowerUpperBound(
      currentRangeIndex,
      currentState.xRangeValues,
    );
    const newUuid = v4();
    tariffZoneCreates.push({
      mileRangeEnd: upperBound,
      mileRangeStart: lowerBound,
      name: `${lowerBound} to ${
        upperBound === MAX_INTEGER ? 'Infinity' : upperBound
      } miles`,
      type: currentState.zoneType,
      uuid: newUuid,
    });
    tariffUuids.push(newUuid);
    currentRangeIndex += 1;
  }

  return [tariffZoneCreates, tariffZoneUpdates, tariffZoneDeletes, tariffUuids];
};

const getTariffZoneChangesFromUniversalZoneType = (
  currentState: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  const newZoneType = currentState.zoneType;
  const zoneUpdatesFromExistingTariffs =
    currentState.existingTariffGroupBeforeChanges?.tariffs
      .filter((tariff) => tariff.tariffZone.type !== TariffZoneType.Location) // exclude location because locations are only modified in global zone editor
      .map((tariff) => tariff.tariffZone) ?? [];
  switch (newZoneType) {
    case TariffZoneType.Universal: {
      const tariffZone = zoneUpdatesFromExistingTariffs[0];
      if (!isNil(tariffZone)) {
        return [
          [],
          [
            {
              name: 'Universal Zone',
              type: newZoneType,
              uuid: tariffZone.uuid,
            },
          ],
          [],
          [tariffZone.uuid],
        ];
      }

      break;
    }
    case TariffZoneType.Location: {
      return [
        [],
        [],
        zoneUpdatesFromExistingTariffs?.map((tariffZone) => tariffZone.uuid),
        [],
      ];
    }
    case TariffZoneType.Miles: {
      return getTariffZoneChangesForMileZoneType(currentState);
    }
    // No default
  }
  return [[], [], [], []];
};

const getTariffZoneChangesFromMileZoneType = (
  state: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  const newZoneType = state.zoneType;
  if (newZoneType === TariffZoneType.Miles) {
    return getTariffZoneChangesForMileZoneType(state);
  }
  const zoneUpdatesFromExistingTariffs =
    state.existingTariffGroupBeforeChanges?.tariffs
      .filter((tariff) => tariff.tariffZone.type !== TariffZoneType.Location) // exclude location because locations are only modified in global zone editor
      .map((tariff) => tariff.tariffZone);
  if (newZoneType === TariffZoneType.Location) {
    return [
      [],
      [],
      zoneUpdatesFromExistingTariffs?.map((tariffZone) => tariffZone.uuid) ??
        [],
      [],
    ];
  }
  if (newZoneType === TariffZoneType.Universal) {
    const newUuid = v4();
    return [
      [{ name: 'Universal Zone', type: newZoneType, uuid: newUuid }],
      [],
      zoneUpdatesFromExistingTariffs?.map((tariffZone) => tariffZone.uuid) ??
        [],
      [newUuid],
    ];
  }
  return [[], [], [], []];
};

const getTariffZoneChangesFromLocationZoneType = (
  state: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  const newZoneType = state.zoneType;
  if (newZoneType === TariffZoneType.Miles) {
    return getTariffZoneChangesForMileZoneType(state);
  }
  if (newZoneType === TariffZoneType.Universal) {
    const newUuid = v4();
    return [
      [{ name: 'Universal Zone', type: newZoneType, uuid: newUuid }],
      [],
      [],
      [newUuid],
    ];
  }
  return [[], [], [], []];
};

export const getNewTariffZoneChanges = (
  state: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  const newZoneType = state.zoneType;
  if (newZoneType === TariffZoneType.Universal) {
    const newUuid = v4();
    return [
      [{ name: 'Universal Zone', type: newZoneType, uuid: newUuid }],
      [],
      [],
      [newUuid],
    ];
  }
  if (newZoneType === TariffZoneType.Miles) {
    return getTariffZoneChangesForMileZoneType(state);
  }
  return [[], [], [], []];
};

export const getGlobalOverageRates = (
  tariffData: TariffForTariffGroupFragment[],
): Array<number | null> => {
  const first = tariffData[0];

  let mileOverageRate: number | null = null;
  let mileOverageFlatRate: number | null = null;
  let mileOverageApplicableAbove: number | null = null;
  let pieceOverageRate: number | null = null;
  let pieceOverageFlatRate: number | null = null;
  let pieceOverageApplicableAbove: number | null = null;
  let weightOverageRate: number | null = null;
  let weightOverageFlatRate: number | null = null;
  let weightOverageApplicableAbove: number | null = null;

  if (
    !isNil(first) &&
    !isNil(first?.tariffOverageRates) &&
    (first.tariffOverageRates.length ?? 0) > 0
  ) {
    for (const overageRate of first.tariffOverageRates) {
      switch (overageRate.type) {
        case TariffOverageRateType.Miles: {
          mileOverageRate = convertCentsToDollars(overageRate.rateUsdCents);
          mileOverageFlatRate = convertCentsToDollars(
            overageRate.flatRateUsdCents,
          );
          mileOverageApplicableAbove = overageRate.applicableAboveAmount;

          break;
        }
        case TariffOverageRateType.Pieces: {
          pieceOverageRate = convertCentsToDollars(overageRate.rateUsdCents);
          pieceOverageFlatRate = convertCentsToDollars(
            overageRate.flatRateUsdCents,
          );
          pieceOverageApplicableAbove = overageRate.applicableAboveAmount;

          break;
        }
        case TariffOverageRateType.Weight: {
          weightOverageRate = convertCentsToDollars(overageRate.rateUsdCents);
          weightOverageFlatRate = convertCentsToDollars(
            overageRate.flatRateUsdCents,
          );
          weightOverageApplicableAbove = overageRate.applicableAboveAmount;

          break;
        }
        // No default
      }
    }
    return [
      mileOverageRate,
      mileOverageFlatRate,
      mileOverageApplicableAbove,
      pieceOverageRate,
      pieceOverageFlatRate,
      pieceOverageApplicableAbove,
      weightOverageRate,
      weightOverageFlatRate,
      weightOverageApplicableAbove,
    ];
  }

  return [
    mileOverageRate,
    mileOverageFlatRate,
    mileOverageApplicableAbove,
    pieceOverageRate,
    pieceOverageFlatRate,
    pieceOverageApplicableAbove,
    weightOverageRate,
    weightOverageFlatRate,
    weightOverageApplicableAbove,
  ];
};

export const getRateMatrix = (tariffData: TariffForTariffGroupFragment[]) => {
  const rateMatrix: number[][] = new Array(MAX_MATRIX_SIZE)
    .fill(undefined)
    .map(() => new Array(MAX_MATRIX_SIZE).fill(undefined));

  const copyTariffData = [...tariffData];
  copyTariffData.sort((a, b) => {
    if (a.tariffZone.type === TariffZoneType.Miles) {
      if (isNil(a.tariffZone.mileRangeEnd) || isNil(b.tariffZone.mileRangeEnd))
        return 0;
      return a.tariffZone.mileRangeEnd - b.tariffZone.mileRangeEnd;
    }
    return a.tariffZone.name.localeCompare(b.tariffZone.name);
  });
  for (
    let i = 0;
    i < Math.min(copyTariffData.length, MAX_MATRIX_SIZE);
    i += 1
  ) {
    const tariff = copyTariffData[i];
    if (!isNil(tariff)) {
      const ranges = tariff.tariffRanges;
      for (let j = 0; j < Math.min(ranges.length, MAX_MATRIX_SIZE); j += 1) {
        const range = ranges[j];
        const row = rateMatrix[j];
        if (!isNil(range) && !isNil(row)) {
          row[i] = convertCentsToDollars(range.rateUsdCents);
        }
      }
    }
  }
  return rateMatrix;
};

export const getMileRange = (tariffData: TariffForTariffGroupFragment[]) => {
  const mileRange: Array<number | undefined> = [];
  const copyTariffData = [...tariffData];
  copyTariffData.sort((a, b) => {
    const aZone = a.tariffZone;
    const bZone = b.tariffZone;
    if (!isNil(aZone) && !isNil(bZone)) {
      const aEnd = aZone.mileRangeEnd;
      const bEnd = bZone.mileRangeEnd;
      if (!isNil(aEnd) && !isNil(bEnd)) {
        return aEnd - bEnd;
      }
    }
    return 0;
  });

  for (const data of copyTariffData) {
    if (!isNil(data)) {
      const endValue = data.tariffZone.mileRangeEnd;
      if (endValue === MAX_INTEGER) {
        mileRange.push(undefined);
      } else if (typeof endValue === 'number' || endValue === undefined) {
        mileRange.push(endValue);
      }
    }
  }
  return mileRange;
};

export const getYRange = (tariffData: TariffForTariffGroupFragment[]) => {
  const yRange: Array<number | undefined> = [];
  // mile ranges are repeated for each tariff, so we can just use first one to populate
  const first = tariffData[0];
  if (!isNil(first)) {
    for (let i = 0; i < first.tariffRanges.length; i += 1) {
      yRange.push(first.tariffRanges[i]?.lessThanOrEqualToValue);
    }
  }
  return yRange;
};

/*
 * If any tariffs have different overage rates or applicable above amounts,
 * we derive that overage rates are set per zone and return false for useGlobalOverageRates
 * otherwise we return true
 */
export const getUseGlobalOverageRates = (
  zoneBasedOverageRates: OverageRates[],
): boolean => {
  if (zoneBasedOverageRates.length <= 1) {
    return true;
  }

  const firstRate = zoneBasedOverageRates[0];
  if (isNil(firstRate)) {
    return true;
  }

  const keys = Object.keys(firstRate) as Array<keyof OverageRates>;

  return zoneBasedOverageRates.every((rate) =>
    keys.every((key) => rate[key] === firstRate[key]),
  );
};

export const getZoneBasedOverageRates = (
  tariffData: TariffForTariffGroupFragment[],
) => {
  const zoneBasedOverageRates: OverageRates[] = [];

  const copyTariffData = sortTariffs([...tariffData]);

  for (
    let i = 0;
    i < Math.min(copyTariffData.length, MAX_MATRIX_SIZE);
    i += 1
  ) {
    const tariff = copyTariffData[i];
    if (!isNil(tariff)) {
      let newMileOverageRate = null;
      let newMileOverageFlatRate = null;
      let newMileOverageApplicableAbove = null;
      let newPieceOverageRate = null;
      let newPieceOverageFlatRate = null;
      let newPieceOverageApplicableAbove = null;
      let newWeightOverageRate = null;
      let newWeightOverageFlatRate = null;
      let newWeightOverageApplicableAbove = null;

      const overageRates = tariff.tariffOverageRates;

      if (!isNil(overageRates)) {
        for (const overageRate of overageRates) {
          switch (overageRate.type) {
            case TariffOverageRateType.Miles: {
              newMileOverageRate = convertCentsToDollars(
                overageRate.rateUsdCents,
              );
              newMileOverageFlatRate = convertCentsToDollars(
                overageRate.flatRateUsdCents,
              );
              newMileOverageApplicableAbove = overageRate.applicableAboveAmount;

              break;
            }
            case TariffOverageRateType.Pieces: {
              newPieceOverageRate = convertCentsToDollars(
                overageRate.rateUsdCents,
              );
              newPieceOverageFlatRate = convertCentsToDollars(
                overageRate.flatRateUsdCents,
              );
              newPieceOverageApplicableAbove =
                overageRate.applicableAboveAmount;

              break;
            }
            case TariffOverageRateType.Weight: {
              newWeightOverageRate = convertCentsToDollars(
                overageRate.rateUsdCents,
              );
              newWeightOverageFlatRate = convertCentsToDollars(
                overageRate.flatRateUsdCents,
              );
              newWeightOverageApplicableAbove =
                overageRate.applicableAboveAmount;

              break;
            }
            // No default
          }
        }
      }

      zoneBasedOverageRates.push({
        mileOverageRate: newMileOverageRate,
        mileOverageFlatRate: newMileOverageFlatRate,
        mileOverageApplicableAbove: newMileOverageApplicableAbove,
        pieceOverageRate: newPieceOverageRate,
        pieceOverageFlatRate: newPieceOverageFlatRate,
        pieceOverageApplicableAbove: newPieceOverageApplicableAbove,
        weightOverageRate: newWeightOverageRate,
        weightOverageFlatRate: newWeightOverageFlatRate,
        weightOverageApplicableAbove: newWeightOverageApplicableAbove,
      });
    }
  }

  return zoneBasedOverageRates;
};

export const getMinMaxAmountValues = (
  tariffData: TariffForTariffGroupFragment[],
) => {
  const minMaxAmountValues: MinMaxAmountValue[] = new Array(
    MAX_MATRIX_SIZE,
  ).fill({ ...initialMinMaxAmountValue });

  const copyTariffData = sortTariffs([...tariffData]);

  for (
    let i = 0;
    i < Math.min(copyTariffData.length, MAX_MATRIX_SIZE);
    i += 1
  ) {
    const tariff = copyTariffData[i];
    const newMinMax = { ...minMaxAmountValues[i] };
    if (!isNil(tariff) && !isNil(newMinMax)) {
      newMinMax.min = isNil(tariff.minimumUsdCents)
        ? undefined
        : convertCentsToDollars(tariff.minimumUsdCents);
      newMinMax.max = isNil(tariff.maximumUsdCents)
        ? undefined
        : convertCentsToDollars(tariff.maximumUsdCents);
    }
    minMaxAmountValues[i] = newMinMax;
  }
  return minMaxAmountValues;
};

export const getSettlementPercentageRateValues = (
  tariffData: TariffForTariffGroupFragment[],
) => {
  const settlementPercentageRateValues: Array<Scalar | null> = new Array(
    MAX_MATRIX_SIZE,
  ).fill(null);

  const copyTariffData = sortTariffs([...tariffData]);

  for (
    let i = 0;
    i < Math.min(copyTariffData.length, MAX_MATRIX_SIZE);
    i += 1
  ) {
    const tariff = copyTariffData[i];
    if (!isNil(tariff)) {
      settlementPercentageRateValues[i] =
        tariff.settlementPercentageRate ?? null;
    }
  }
  return settlementPercentageRateValues;
};

const buildTariffOverageCreateInput = ({
  overageRates,
  name,
}: {
  overageRates: OverageRates | undefined;
  name?: string | null;
}) => {
  if (isNil(overageRates)) {
    return;
  }

  const {
    mileOverageRate,
    mileOverageFlatRate,
    mileOverageApplicableAbove,
    pieceOverageRate,
    pieceOverageFlatRate,
    pieceOverageApplicableAbove,
    weightOverageRate,
    weightOverageFlatRate,
    weightOverageApplicableAbove,
  } = overageRates;
  const tariffOverageRateCreates: TariffOverageRateCreateInput[] = [];

  if (!isNil(mileOverageRate) || !isNil(mileOverageFlatRate)) {
    tariffOverageRateCreates.push({
      rateUsdCents: convertDollarsToCents(mileOverageRate ?? 0),
      flatRateUsdCents: convertDollarsToCents(mileOverageFlatRate ?? 0),
      type: TariffOverageRateType.Miles,
      applicableAboveAmount: isNil(mileOverageApplicableAbove)
        ? 0
        : mileOverageApplicableAbove,
      name: name ?? 'STANDARD',
    });
  }
  if (!isNil(pieceOverageRate) || !isNil(pieceOverageFlatRate)) {
    tariffOverageRateCreates.push({
      rateUsdCents: convertDollarsToCents(pieceOverageRate ?? 0),
      flatRateUsdCents: convertDollarsToCents(pieceOverageFlatRate ?? 0),
      type: TariffOverageRateType.Pieces,
      applicableAboveAmount: isNil(pieceOverageApplicableAbove)
        ? 0
        : pieceOverageApplicableAbove,
      name: name ?? 'STANDARD',
    });
  }
  if (!isNil(weightOverageRate) || !isNil(weightOverageFlatRate)) {
    tariffOverageRateCreates.push({
      rateUsdCents: convertDollarsToCents(weightOverageRate ?? 0),
      flatRateUsdCents: convertDollarsToCents(weightOverageFlatRate ?? 0),
      type: TariffOverageRateType.Weight,
      applicableAboveAmount: isNil(weightOverageApplicableAbove)
        ? 0
        : weightOverageApplicableAbove,
      name: name ?? 'STANDARD',
    });
  }

  return tariffOverageRateCreates;
};

export const buildTariffCreateInputs = (
  tariffGroupState: TariffGroupState,
  zoneUuids: string[],
): TariffCreateInput[] | null => {
  const tariffCreates: TariffCreateInput[] = [];
  const {
    locationZonesData,
    xRangeValues,
    yRangeValues,
    zoneType,
    rateMatrix,
    minMaxAmountValues,
    settlementPercentageRateValues,
    rateType,
    tariffType,
    useActualWeight,
    parentTariffGroupUuid,
    useGlobalOverageRates,
    zoneBasedOverageRates,
  } = tariffGroupState;

  const newZoneType = zoneType;
  let xLength = xRangeValues.length;
  const firstUndefinedYRangeValueIdx = yRangeValues.findIndex((v) => isNil(v));

  const newYRangeValues = [...yRangeValues];
  if (firstUndefinedYRangeValueIdx !== -1) {
    // delete all after the first undefined
    newYRangeValues.splice(firstUndefinedYRangeValueIdx + 1);
  }
  let yLength = newYRangeValues.length;

  // If present, use the zoneUuids created by getNewTariffZoneChanges
  // This should only happen when creating a tariff group with a universal zone
  // or mile based zones
  let zones: string[] = zoneUuids;

  // Use the zoneUuids from the state if the zone type is location
  // These are uuids of TariffZone objects in the db
  if (newZoneType === TariffZoneType.Location) {
    xLength = locationZonesData.length;
    zones = locationZonesData.map((zoneData) => zoneData.uuid);
  }
  if (xLength === 0) {
    xLength = 1;
  }
  if (yLength === 0) {
    yLength = 1;
  }

  // if there's a parent tariff it's just linked, no need to create new tariffs
  if (!isNil(parentTariffGroupUuid)) {
    return [];
  }

  const transposedRateMatrix = unzip(rateMatrix);
  for (let row = 0; row < xLength; row += 1) {
    const zoneUuid = zones[row];
    if (!isNil(zoneUuid)) {
      const overageRates = zoneBasedOverageRates[row];

      const tariffOverageRateCreateInputs = useGlobalOverageRates
        ? undefined
        : buildTariffOverageCreateInput({ overageRates });

      const min = minMaxAmountValues[row]?.min;
      const max = minMaxAmountValues[row]?.max;
      const tariffCreate: TariffCreateInput = {
        rateType,
        tariffType,
        tariffZoneUuid: zoneUuid,
        minimumUsdCents: isNil(min) ? null : convertDollarsToCents(min),
        maximumUsdCents: isNil(max) ? null : convertDollarsToCents(max),
        settlementPercentageRate: settlementPercentageRateValues[row],
        tariffRangeCreateInputs: [],
        useActualWeightInCalculation: useActualWeight,
        tariffOverageRateCreateInputs,
      };

      for (let col = 0; col < yLength; col += 1) {
        const rateColumn = transposedRateMatrix[row];

        if (
          !isNil(rateColumn) &&
          !isNil(tariffCreate.tariffRangeCreateInputs)
        ) {
          const rate = rateColumn[col];
          tariffCreate.tariffRangeCreateInputs.push({
            lessThanOrEqualToValue: newYRangeValues[col] ?? MAX_INTEGER,
            rateUsdCents: convertDollarsToCents(isNil(rate) ? 0 : rate),
          });
        } else {
          return null;
        }
      }
      tariffCreates.push(tariffCreate);
    }
  }
  return tariffCreates;
};

export const buildGlobalTariffOverageRateCreates = (
  tariffGroupState: TariffGroupState,
): TariffOverageRateCreateInput[] | undefined => {
  if (!isNil(tariffGroupState.parentTariffGroupUuid)) {
    return [];
  }

  return buildTariffOverageCreateInput({
    overageRates: tariffGroupState.globalOverageRates,
    name: tariffGroupState.name,
  });
};

export const buildTariffUpsertInputs = (
  tariffGroupState: TariffGroupState,
  zoneUuids: string[],
): TariffUpsertInput[] | null => {
  const tariffUpsertInputs: TariffUpsertInput[] = [];
  const {
    locationZonesData,
    xRangeValues,
    yRangeValues,
    zoneType,
    rateMatrix,
    minMaxAmountValues,
    settlementPercentageRateValues,
    tariffType,
    existingTariffGroupBeforeChanges,
    useActualWeight,
    parentTariffGroupUuid,
    useGlobalOverageRates,
    zoneBasedOverageRates,
  } = tariffGroupState;
  const newZoneType = zoneType;
  let xLength = xRangeValues.length;

  const firstUndefinedYRangeValueIdx = yRangeValues.findIndex((v) => isNil(v));

  const newYRangeValues = [...yRangeValues];
  if (firstUndefinedYRangeValueIdx !== -1) {
    // delete all after the first undefined
    newYRangeValues.splice(firstUndefinedYRangeValueIdx + 1);
  }
  let yLength = newYRangeValues.length;

  let zones: string[] = zoneUuids;

  // don't upsert if this is a linked tariff
  if (!isNil(parentTariffGroupUuid)) {
    return [];
  }

  if (newZoneType === TariffZoneType.Location) {
    xLength = locationZonesData.length;
    zones = locationZonesData.map((zoneData) => zoneData.uuid);
  }
  if (xLength === 0) {
    xLength = 1;
  }
  if (yLength === 0) {
    yLength = 1;
  }

  const transposedRateMatrix = unzip(rateMatrix);
  for (let row = 0; row < xLength; row += 1) {
    const zoneUuid = zones[row];
    if (!isNil(zoneUuid)) {
      const min = minMaxAmountValues[row]?.min;
      const max = minMaxAmountValues[row]?.max;

      const overageRates = zoneBasedOverageRates[row];

      const tariffOverageRateCreateInputs = useGlobalOverageRates
        ? undefined
        : buildTariffOverageCreateInput({ overageRates });

      const tariffUpsert: TariffUpsertInput = {
        tariffZoneUuid: zoneUuid,
        tariffType,
        minimumUsdCents: isNil(min) ? null : convertDollarsToCents(min),
        maximumUsdCents: isNil(max) ? null : convertDollarsToCents(max),
        settlementPercentageRate: settlementPercentageRateValues[row],
        tariffRangeUpdateInputs: [],
        uuid: existingTariffGroupBeforeChanges?.tariffs.find(
          (tariff) => tariff.tariffZone.uuid === zoneUuid,
        )?.uuid,
        useActualWeightInCalculation: useActualWeight,
        tariffOverageRateCreateInputs,
      };

      const existingTariffRangeUuids =
        existingTariffGroupBeforeChanges?.tariffs
          ?.find((t) => t.tariffZone.uuid === zoneUuid)
          ?.tariffRanges?.map((range) => range.uuid) ?? [];

      for (let col = 0; col < yLength; col += 1) {
        const rateColumn = transposedRateMatrix[row];

        if (
          !isNil(rateColumn) &&
          !isNil(tariffUpsert.tariffRangeUpdateInputs)
        ) {
          const rate = rateColumn[col];
          tariffUpsert.tariffRangeUpdateInputs.push({
            lessThanOrEqualToValue: newYRangeValues[col] ?? MAX_INTEGER,
            rateUsdCents: convertDollarsToCents(isNil(rate) ? 0 : rate),
            uuid: existingTariffRangeUuids.pop() ?? v4(),
          });
        } else {
          throw new Error(
            `Reached the end of the rate matrix. row: ${row}, col: ${col}. Contact eng`,
          );
        }
      }
      tariffUpsertInputs.push(tariffUpsert);
    }
  }
  return tariffUpsertInputs;
};

export const buildTariffZoneGroupChanges = (
  state: TariffGroupState,
): [TariffZoneCreateInput[], TariffZoneUpdateInput[], string[], string[]] => {
  if (state.existingTariffGroupBeforeChanges === undefined) {
    return getNewTariffZoneChanges(state);
  }
  const prevZoneType =
    state.existingTariffGroupBeforeChanges?.tariffs[0]?.tariffZone.type;

  if (prevZoneType === TariffZoneType.Location) {
    return getTariffZoneChangesFromLocationZoneType(state);
  }
  if (prevZoneType === TariffZoneType.Miles) {
    return getTariffZoneChangesFromMileZoneType(state);
  }
  if (prevZoneType === TariffZoneType.Universal) {
    return getTariffZoneChangesFromUniversalZoneType(state);
  }
  return [[], [], [], []];
};
