import { isNil } from 'lodash';
import {
  useCreateOrderSnapshotMutation,
  useStandardOrderLazyQuery,
} from '../../generated/graphql';

const useOrderSnapshot = () => {
  const [createOrderSnapshot] = useCreateOrderSnapshotMutation();
  const [getStandardOrder] = useStandardOrderLazyQuery();

  const saveSnapshot = async (uuid: string | undefined) => {
    if (isNil(uuid)) {
      // eslint-disable-next-line no-console
      console.error('Unable to save snapshot - nil uuid');
      return uuid;
    }
    const updatedOrderResponse = await getStandardOrder({
      variables: {
        uuid,
      },
    });

    const updatedOrder = updatedOrderResponse.data?.standardOrder;
    if (!isNil(updatedOrder)) {
      createOrderSnapshot({
        variables: {
          orderEventSnapshotCreateInput: {
            orderUuid: updatedOrder.uuid,
            snapshot: JSON.stringify(updatedOrder),
          },
        },
      });
    }
    return updatedOrder?.uuid;
  };

  return { saveSnapshot };
};

export default useOrderSnapshot;
