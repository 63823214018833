import PercentIcon from '@mui/icons-material/Percent';
import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  ButtonGroup,
  Checkbox,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty, isNil } from 'lodash';
import React, {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import DateDropdownPicker, {
  type DateOption,
  DatePickerFilterType,
  initialDateOption,
} from '../../../../common/components/date-dropdown-picker';
import DriverFilterButton from '../../../../common/components/driver-filter-button';
import DriverSettlementStatusFilterButton, {
  DriverSettlementStatus,
} from '../../../../common/components/driver-settlement-status-filter-button';
import OrderBillingStatusFilterButton, {
  OrderBillingStatus,
} from '../../../../common/components/order-billing-status-filter-button-simple';
import { SortType } from '../../../../common/components/sort-component';
import { FeatureFlag } from '../../../../common/feature-flags';
import { type Option } from '../../../../common/filters/types';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import {
  InvoiceStatus,
  Segment,
  type StopBySearchTextFragment,
  type StopDriverMapsSummaryQuery,
  type StopDriverMapsSummaryQueryResult,
  StopType,
  useRemoveDriverSettlementBillMutation,
  useStopDriverMapsSummaryLazyQuery,
  useStopsBySearchTextLazyQuery,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import { OrderDialog } from '../../../orders/components/order-dialog';
import useDriverSettlementStore from '../../driver-settlement-store';
import StopDriverMapsTableRow from './stop-driver-maps-table-row';
import StopsBySearchTextDialog from './stops-by-search-text-dialog';

const DEFAULT_STOPS_PAGE_SIZE = 15;
const ROW_PER_PAGE_OPTIONS = [15, 25, 50];

type StopDriverMapsTableProps = {
  readonly settlementUuid?: string | undefined;
  readonly settlementIsFinalized?: boolean | undefined;
  readonly shouldRefresh?: boolean;
  readonly setShouldRefresh?: Dispatch<SetStateAction<boolean>>;
  readonly rightComponent?: ReactNode;
};

type FetchStopDriverMapsArgs = {
  first?: number | null | undefined;
  after?: string | null | undefined;
  last?: number | null | undefined;
  before?: string | null | undefined;
  noMaximum?: boolean | null | undefined;
  searchInput?: string | undefined;
  includeStopDriverMapUuids?: string[] | null | undefined;
  excludeStopDriverMapUuids?: string[] | null | undefined;
  includeTotalAmounts?: boolean;
};

enum DriverSettlementStopsTab {
  UNSETTLED,
  SETTLED,
}

const StopDriverMapsTable = ({
  settlementUuid,
  settlementIsFinalized,
  shouldRefresh,
  setShouldRefresh,
  rightComponent,
}: StopDriverMapsTableProps) => {
  const { segment } = useMe();
  const [openedOrderUuid, setOpenedOrderUuid, orderUuidToRefetch] =
    useGlobalStore(
      (state) => [
        state.currentOrderUuid,
        state.setCurrentOrderUuid,
        state.orderUuidToRefetch,
      ],
      shallow,
    );
  const [
    allSelected,
    setAllSelected,
    selectedStopUuids,
    deselectedStopUuids,
    setSelectedStopsForSettlements,
    setOpenedSettlementUuid,
    setShouldRefreshSettlementsList,
  ] = useDriverSettlementStore(
    (state) => [
      state.allSelected,
      state.setAllSelected,
      state.selectedStopUuids,
      state.deselectedStopUuids,
      state.setSelectedStopsForSettlements,
      state.setOpenedSettlementUuid,
      state.setShouldRefreshSettlementsList,
    ],
    shallow,
  );
  const [currentTab, setCurrentTab] = useState<DriverSettlementStopsTab>(
    DriverSettlementStopsTab.UNSETTLED,
  );
  const [driverOption, setDriverOption] = useState<Option | null | undefined>();
  const [completedDateOption, setCompletedDateOption] =
    useState<DateOption>(initialDateOption);
  const [settlementDateOption, setSettlementDateOption] =
    useState<DateOption>(initialDateOption);
  const [settlementStatus, setSettlementStatus] = useState<
    DriverSettlementStatus | undefined
  >();
  const [orderBillingStatus, setOrderBillingStatus] = useState<
    OrderBillingStatus | undefined
  >(OrderBillingStatus.CHARGES_FINALIZED);
  const [searchText, setSearchText] = useState<string>('');

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_STOPS_PAGE_SIZE,
  );
  const [dateSortState, setDateSortState] = useState<SortType>(
    SortType.Ascending,
  );
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [stopDriverMapsData, setStopDriverMapsData] =
    useState<StopDriverMapsSummaryQuery>();
  const [prevFetchArgs, setPrevFetchArgs] = useState<
    FetchStopDriverMapsArgs | undefined
  >(undefined);
  const [getStopDriverMaps, { loading }] = useStopDriverMapsSummaryLazyQuery();
  const [
    getStopDriverMapsForSettlement,
    { loading: loadingSendToSettlements },
  ] = useStopDriverMapsSummaryLazyQuery();
  const [matchingStopsBySearchText, setMatchingStopsBySearchText] = useState<
    StopBySearchTextFragment[]
  >([]);
  const [showMatchingStopsDialog, setShowMatchingStopsDialog] =
    useState<boolean>(false);
  const [getStopsBySearchText, { loading: loadingStopsBySearchText }] =
    useStopsBySearchTextLazyQuery();
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  const handleCompletedDateSort = () => {
    if (dateSortState === SortType.Ascending) {
      setDateSortState(SortType.Descending);
    } else if (dateSortState === SortType.Descending) {
      setDateSortState(SortType.Ascending);
    }
  };

  const fetchStopDriverMaps = async ({
    first,
    after,
    last,
    before,
    noMaximum,
    searchInput,
    includeStopDriverMapUuids,
    excludeStopDriverMapUuids,
    includeTotalAmounts = true,
  }: FetchStopDriverMapsArgs) => {
    setPrevFetchArgs({ first, after, last, before, searchInput });
    let res: StopDriverMapsSummaryQueryResult;
    const settlementFinalized =
      settlementStatus === DriverSettlementStatus.FINALIZED
        ? true
        : settlementStatus === DriverSettlementStatus.UNFINALIZED
          ? false
          : undefined;

    let onInvoice;
    let chargesFinalized;
    let invoiceStatuses;
    if (isNil(settlementUuid)) {
      switch (orderBillingStatus) {
        case OrderBillingStatus.CHARGES_FINALIZED: {
          chargesFinalized = true;

          break;
        }
        case OrderBillingStatus.ON_POSTED_INVOICE: {
          onInvoice = true;
          invoiceStatuses = [
            InvoiceStatus.ReadyToInvoice,
            InvoiceStatus.Invoiced,
            InvoiceStatus.Paid,
          ];

          break;
        }
        case OrderBillingStatus.ON_INVOICE: {
          onInvoice = true;

          break;
        }
        // No default
      }
    }

    const stopTypes = [
      StopType.Pickup,
      StopType.Delivery,
      StopType.Recovery,
      StopType.Transfer,
    ];

    if (noMaximum === true) {
      res = await getStopDriverMapsForSettlement({
        variables: {
          noMaximum: true,
          onDriverSettlementBill:
            currentTab === DriverSettlementStopsTab.SETTLED ||
            !isNil(settlementUuid),
          stopTypes,
          completedStartDate: allSelected
            ? completedDateOption.startDate
            : undefined,
          completedEndDate: allSelected
            ? completedDateOption.endDate
            : undefined,
          settlementStartDate: allSelected
            ? settlementDateOption.startDate
            : undefined,
          settlementEndDate: allSelected
            ? settlementDateOption.endDate
            : undefined,
          driverUuid: allSelected ? driverOption?.value : undefined,
          driverSettlementFinalized: allSelected
            ? settlementFinalized
            : undefined,
          onInvoice: allSelected ? onInvoice : undefined,
          chargesFinalized: allSelected ? chargesFinalized : undefined,
          invoiceStatuses: allSelected ? invoiceStatuses : undefined,
          includeStopDriverMapUuids,
          excludeStopDriverMapUuids,
          includeTotalAmounts,
        },
      });
    } else {
      res = await getStopDriverMaps({
        variables: {
          first,
          after,
          last,
          before,
          searchText: searchInput?.trim(),
          completedStartDate: completedDateOption.startDate,
          completedEndDate: completedDateOption.endDate,
          settlementStartDate: settlementDateOption.startDate,
          settlementEndDate: settlementDateOption.endDate,
          sortByCompletedDateAsc:
            currentTab === DriverSettlementStopsTab.SETTLED
              ? undefined
              : dateSortState === SortType.Ascending,
          sortBySettlementDateAsc:
            currentTab === DriverSettlementStopsTab.SETTLED
              ? dateSortState === SortType.Ascending
              : undefined,
          driverUuid: driverOption?.value,
          driverSettlementBillUuid: settlementUuid,
          onDriverSettlementBill:
            currentTab === DriverSettlementStopsTab.SETTLED ||
            !isNil(settlementUuid),
          driverSettlementFinalized: settlementFinalized,
          onInvoice,
          chargesFinalized,
          invoiceStatuses,
          stopTypes,
          includeTotalAmounts,
        },
      });
      setTotalCount(res.data?.stopDriverMaps.totalCount ?? 0);
      setStopDriverMapsData(res.data);
      if (
        isNil(settlementUuid) &&
        (res.data?.stopDriverMaps.totalCount ?? 0) === 0 &&
        !isEmpty(searchInput?.trim())
      ) {
        const matchingStopsRes = await getStopsBySearchText({
          variables: {
            searchText: searchInput?.trim() ?? '',
          },
        });
        setMatchingStopsBySearchText(
          matchingStopsRes.data?.stopsBySearchText ?? [],
        );
        if ((matchingStopsRes.data?.stopsBySearchText ?? []).length > 0) {
          setShowMatchingStopsDialog(true);
        }
      } else {
        setMatchingStopsBySearchText([]);
      }
    }
    return res.data?.stopDriverMaps.edges.map(({ node }) => node);
  };

  const getAllStopsForSettlements = async () => {
    const stopDriverMaps = await fetchStopDriverMaps({
      noMaximum: true,
      includeStopDriverMapUuids: allSelected ? undefined : selectedStopUuids,
      excludeStopDriverMapUuids: deselectedStopUuids,
      includeTotalAmounts: false,
    });

    setSelectedStopsForSettlements(stopDriverMaps);
  };

  const prev = async () => {
    await fetchStopDriverMaps({
      last: rowsPerPage,
      before:
        stopDriverMapsData?.stopDriverMaps.pageInfo.startCursor ?? undefined,
    });
  };
  const next = async () => {
    await fetchStopDriverMaps({
      first: rowsPerPage,
      after: stopDriverMapsData?.stopDriverMaps.pageInfo.endCursor ?? undefined,
    });
  };

  const refresh = (searchInput?: string) => {
    setMatchingStopsBySearchText([]);
    fetchStopDriverMaps({ first: rowsPerPage, searchInput });
    setPage(0);
  };

  useEffect(() => {
    refresh(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateSortState,
    rowsPerPage,
    driverOption,
    completedDateOption.startDate,
    completedDateOption.endDate,
    settlementDateOption.startDate,
    settlementDateOption.endDate,
    settlementStatus,
    orderBillingStatus,
    currentTab,
    settlementUuid,
  ]);

  useEffect(() => {
    if (isNil(openedOrderUuid) && !isNil(orderUuidToRefetch)) {
      if (isNil(prevFetchArgs)) {
        refresh(searchText);
      } else {
        fetchStopDriverMaps(prevFetchArgs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrderUuid, orderUuidToRefetch]);

  useEffect(() => {
    if (shouldRefresh === true && !isNil(setShouldRefresh)) {
      refresh();
      setShouldRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  const [removeSettlement] = useRemoveDriverSettlementBillMutation();
  const deleteSettlement = async () => {
    if (!isNil(settlementUuid)) {
      await removeSettlement({
        variables: {
          uuid: settlementUuid,
        },
      });
    }
    setOpenedSettlementUuid(undefined);
    setShouldRefreshSettlementsList(true);
  };

  let bolFieldString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';
  if (ffDemoLoadManagement) {
    bolFieldString = 'Auth';
  }
  const refNumberString =
    segment === Segment.Cartage ? 'Ref #s' : 'Secondary Ref #';

  const selectedCount = useMemo(() => {
    return allSelected
      ? totalCount - deselectedStopUuids.length
      : selectedStopUuids.length;
  }, [allSelected, totalCount, deselectedStopUuids, selectedStopUuids]);

  return (
    <Stack spacing={1} height="100%">
      <Box>
        <Grid container>
          <Grid item xs={isNil(settlementUuid) ? 9 : 6}>
            <Stack direction="column" sx={{ ml: '10px' }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{ pt: 1 }}
              >
                {isNil(settlementUuid) && (
                  <>
                    <ButtonGroup
                      disableElevation
                      variant="outlined"
                      size="small"
                    >
                      <Button
                        variant={
                          currentTab === DriverSettlementStopsTab.UNSETTLED
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          setCurrentTab(DriverSettlementStopsTab.UNSETTLED);
                          setSettlementStatus(undefined);
                          setOrderBillingStatus(
                            OrderBillingStatus.CHARGES_FINALIZED,
                          );
                          setCompletedDateOption(initialDateOption);
                          setSettlementDateOption(initialDateOption);
                        }}
                      >
                        Unsettled
                      </Button>
                      <Button
                        variant={
                          currentTab === DriverSettlementStopsTab.SETTLED
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => {
                          setCurrentTab(DriverSettlementStopsTab.SETTLED);
                          setSettlementStatus(
                            DriverSettlementStatus.UNFINALIZED,
                          );
                          setOrderBillingStatus(undefined);
                          setSettlementDateOption(initialDateOption);
                          setCompletedDateOption(initialDateOption);
                        }}
                      >
                        Settled
                      </Button>
                    </ButtonGroup>
                    {currentTab === DriverSettlementStopsTab.UNSETTLED && (
                      <DateDropdownPicker
                        filterTitle="Completed Date"
                        dateOption={completedDateOption}
                        setDateOption={setCompletedDateOption}
                        defaultFilterType={DatePickerFilterType.AllSelect}
                      />
                    )}
                    {currentTab === DriverSettlementStopsTab.SETTLED && (
                      <DateDropdownPicker
                        filterTitle="Settlement Date"
                        dateOption={settlementDateOption}
                        setDateOption={setSettlementDateOption}
                        defaultFilterType={DatePickerFilterType.AllSelect}
                      />
                    )}
                    <DriverFilterButton
                      prefixText={
                        ffDemoLoadManagement ? 'Driver/agent' : 'Driver'
                      }
                      cacheId="UNSETTLED_STOPS"
                      selectedOption={driverOption}
                      handleChange={(option: Option | null | undefined) => {
                        setDriverOption(option);
                      }}
                    />
                    {currentTab === DriverSettlementStopsTab.SETTLED && (
                      <DriverSettlementStatusFilterButton
                        selectedOption={settlementStatus}
                        handleChange={(
                          status: DriverSettlementStatus | undefined,
                        ) => {
                          setSettlementStatus(status);
                        }}
                      />
                    )}
                    {currentTab === DriverSettlementStopsTab.UNSETTLED && (
                      <OrderBillingStatusFilterButton
                        selectedOption={orderBillingStatus}
                        handleChange={(
                          status: OrderBillingStatus | undefined,
                        ) => {
                          setOrderBillingStatus(status);
                        }}
                      />
                    )}
                  </>
                )}
                <TextField
                  size="small"
                  label="Search"
                  InputProps={{ style: { backgroundColor: 'white' } }}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.length === 0) {
                      refresh();
                    }
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      refresh(searchText);
                    }
                  }}
                />
                {isNil(settlementUuid) && loadingStopsBySearchText && (
                  <Typography color="gray">Searching all stops...</Typography>
                )}
                {isNil(settlementUuid) &&
                  matchingStopsBySearchText.length > 0 && (
                    <Button
                      onClick={() => {
                        setShowMatchingStopsDialog(true);
                      }}
                    >
                      View other matching stops
                    </Button>
                  )}
              </Stack>
            </Stack>
          </Grid>
          {currentTab === DriverSettlementStopsTab.UNSETTLED && (
            <Grid item xs={isNil(settlementUuid) ? 3 : 6}>
              {isNil(settlementUuid) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ float: 'right', mr: 1 }}
                >
                  <Button
                    variant="contained"
                    disabled={selectedCount === 0 || loadingSendToSettlements}
                    startIcon={
                      loadingSendToSettlements ? (
                        <CircularProgress size={20} />
                      ) : null
                    }
                    onClick={() => {
                      getAllStopsForSettlements();
                    }}
                  >
                    Send to Settlements{' '}
                    {selectedCount > 0 ? `(${selectedCount})` : ''}
                  </Button>
                </Stack>
              ) : (
                rightComponent
              )}
            </Grid>
          )}
          <Grid item xs={12} sx={{ ml: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2} alignItems="center">
                {loading && <CircularProgress size={20} />}
              </Stack>
              <TablePagination
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                labelRowsPerPage="Show"
                component="div"
                count={
                  loading
                    ? 0
                    : isNil(stopDriverMapsData?.stopDriverMaps.totalCount)
                      ? totalCount
                      : (stopDriverMapsData?.stopDriverMaps.totalCount ?? 0)
                }
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  disabled: loading || page === 0,
                }}
                nextIconButtonProps={{
                  disabled:
                    loading ||
                    stopDriverMapsData?.stopDriverMaps.totalCount === 0 ||
                    page + 1 ===
                      Math.ceil(
                        (stopDriverMapsData?.stopDriverMaps.totalCount ?? 0) /
                          rowsPerPage,
                      ),
                }}
                onPageChange={(_, newPage: number) => {
                  if (newPage > page) {
                    next();
                  } else {
                    prev();
                  }
                  setPage(newPage);
                }}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box flex={1} minHeight={0}>
        {!isNil(settlementUuid) &&
        stopDriverMapsData?.stopDriverMaps.edges.length === 0 &&
        searchText.length === 0 ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Button color="error" onClick={deleteSettlement}>
              Delete empty settlement
            </Button>
          </Box>
        ) : (
          <TableContainer
            sx={{
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            <Table
              stickyHeader
              aria-label="stop-driver-maps-table"
              size="small"
              sx={{
                '& .MuiTableCell-sizeSmall': {
                  padding: '5px 5px',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '10px' }}>
                    {isNil(settlementUuid) && (
                      <Checkbox
                        checked={allSelected}
                        onChange={(e) => {
                          setAllSelected(e.target.checked);
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      minWidth: '80px',
                    }}
                  >
                    <TableSortLabel
                      active
                      direction={
                        dateSortState === SortType.Descending ? 'desc' : 'asc'
                      }
                      hideSortIcon={false}
                      onClick={handleCompletedDateSort}
                    >
                      {currentTab === DriverSettlementStopsTab.SETTLED
                        ? 'Settlement date'
                        : 'Completed at'}
                    </TableSortLabel>
                  </TableCell>
                  {isNil(settlementUuid) && (
                    <TableCell
                      sx={{
                        minWidth: '130px',
                      }}
                    >
                      {ffDemoLoadManagement ? 'Driver/agent' : 'Driver'}
                    </TableCell>
                  )}
                  {isNil(settlementUuid) && (
                    <TableCell
                      sx={{
                        minWidth: '120px',
                      }}
                    >
                      Type
                    </TableCell>
                  )}
                  <TableCell>Customer</TableCell>
                  <TableCell sx={{ minWidth: '100px' }}>Stop type</TableCell>
                  <TableCell>{bolFieldString}</TableCell>
                  <TableCell>Order</TableCell>
                  {isNil(settlementUuid) && (
                    <TableCell>{refNumberString}</TableCell>
                  )}
                  {isNil(settlementUuid) && <TableCell>Consignee</TableCell>}
                  {currentTab === DriverSettlementStopsTab.SETTLED && (
                    <TableCell sx={{ width: '130px' }}>Settlement</TableCell>
                  )}
                  <TableCell
                    align="right"
                    sx={{
                      minWidth: '80px',
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      minWidth: '80px',
                    }}
                  >
                    Eligible
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      minWidth: '90px',
                    }}
                  >
                    Driver pay
                  </TableCell>
                  {currentTab === DriverSettlementStopsTab.SETTLED && (
                    <TableCell align="right" sx={{ width: '70px' }}>
                      <Tooltip
                        arrow
                        title="Driver Pay / Eligible Amount"
                        placement="top"
                      >
                        <PercentIcon sx={{ width: '16px' }} />
                      </Tooltip>
                    </TableCell>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {stopDriverMapsData?.stopDriverMaps.edges.map(
                    ({ node: stopDriverMap }) => (
                      <StopDriverMapsTableRow
                        key={stopDriverMap.uuid}
                        enableCheckbox={isNil(settlementUuid)}
                        settlementUuid={settlementUuid}
                        settlementIsFinalized={settlementIsFinalized}
                        refresh={refresh}
                        isChecked={
                          (allSelected &&
                            !deselectedStopUuids.includes(
                              stopDriverMap.uuid,
                            )) ||
                          selectedStopUuids.includes(stopDriverMap.uuid)
                        }
                        stopDriverMap={stopDriverMap}
                        showSettledStops={
                          currentTab === DriverSettlementStopsTab.SETTLED
                        }
                      />
                    ),
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Box>
      {showMatchingStopsDialog && (
        <StopsBySearchTextDialog
          matchingStops={matchingStopsBySearchText}
          onClose={() => {
            setShowMatchingStopsDialog(false);
          }}
        />
      )}
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid ?? null}
        onClose={() => {
          setOpenedOrderUuid(undefined);
        }}
      />
    </Stack>
  );
};

export default StopDriverMapsTable;
