import { type SettlementContextForEditAccess } from '../forms/use-order-form-edit-access';
import { useOrderFormEditAccess } from './order-form-edit-access-context';
import { type OrderShipmentContext } from '../types';

/**
 * Wrapper hook for `useOrderFormEditAccess` that indicates whether the user has edit access for charges
 * in a given context (customer charges or settlement charges).
 *
 * If in settlement charges, disable if the line item has been added to a finalized bill or the user has no write access
 * Otherwise, disable under the conditions outlined in `getDisabledForEditAccess`
 * */
export const useOrderFormChargesEditAccess = ({
  chargesContext,
}: {
  chargesContext: OrderShipmentContext;
}): { editingChargesDisabled: boolean } => {
  const { inSettlement } = chargesContext;
  const settlementContext: SettlementContextForEditAccess | null = inSettlement
    ? {
        settlementBillFinalizedDate: chargesContext.settlementBillFinalizedDate,
      }
    : null;
  const { disabledIfFinalizedOrLater, disabledIfSettlementFinalized } =
    useOrderFormEditAccess({
      settlementContext,
    });

  const editingChargesDisabled = inSettlement
    ? disabledIfSettlementFinalized
    : disabledIfFinalizedOrLater;

  return { editingChargesDisabled };
};
