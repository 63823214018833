import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { getPermissionsFlags } from 'shared/roles';
import useMe from '../../common/react-hooks/use-me';
import useUserRoles from '../../common/react-hooks/use-user-roles';
import {
  MeDocument,
  PermissionResource,
  useUpdateCompanyMutation,
} from '../../generated/graphql';
import { COMMON_TIMEZONES } from './constants';

export function isValidTimeZone(tz: string): boolean {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch {
    return false;
  }
}

type FormValues = {
  uuid: string | null | undefined;
  name: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  timeZone: string;
};

const GeneralSettings = () => {
  const [isSaveNotificationOpen, setIsSaveNotificationOpen] = useState(false);
  const [isSaveErrorNotificationOpen, setIsSaveErrorNotificationOpen] =
    useState(false);
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteSettingsGeneral } = getPermissionsFlags(
    userPermissions,
    PermissionResource.SettingsGeneral,
  );

  const { companyData, refetchMeData } = useMe();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      uuid: companyData?.defaultAddress?.uuid,
      name: companyData?.defaultAddress?.name ?? '',
      line1: companyData?.defaultAddress?.line1 ?? '',
      line2: companyData?.defaultAddress?.line2 ?? '',
      city: companyData?.defaultAddress?.city ?? '',
      state: companyData?.defaultAddress?.state ?? '',
      zip: companyData?.defaultAddress?.zip ?? '',
      country: companyData?.defaultAddress?.country ?? '',
      timeZone: companyData?.timeZone ?? '',
    },
    mode: 'onChange',
  });

  const [updateCompany, { loading: updateCompanyLoading }] =
    useUpdateCompanyMutation({
      refetchQueries: [MeDocument],
    });

  const onSubmit = handleSubmit(async (data) => {
    if (!isValid || isNil(data.uuid)) {
      setIsSaveErrorNotificationOpen(true);
      return;
    }

    try {
      await updateCompany({
        variables: {
          updateCompanyInput: {
            defaultAddressUpdateInput: {
              uuid: data.uuid,
              name: data.name,
              line1: data.line1,
              line2: data.line2,
              city: data.city,
              state: data.state,
              zip: data.zip,
              country: data.country,
            },
            timeZone: data.timeZone,
          },
        },
      });
      refetchMeData();
      setIsSaveNotificationOpen(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating company', error);
      setIsSaveErrorNotificationOpen(true);
    }
  });

  const disabledFields = updateCompanyLoading || !canWriteSettingsGeneral;

  return (
    <Stack spacing={2}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSaveNotificationOpen}
        onClose={() => {
          setIsSaveNotificationOpen(false);
        }}
      >
        <Alert severity="success">Settings saved.</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSaveErrorNotificationOpen}
        onClose={() => {
          setIsSaveErrorNotificationOpen(false);
        }}
      >
        <Alert severity="error">Error saving settings.</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={
            updateCompanyLoading || !canWriteSettingsGeneral || !isValid
          }
          onClick={onSubmit}
        >
          Save
        </Button>
      </Stack>
      <Typography variant="h6">Company Settings</Typography>
      <Divider />
      <Typography variant="h6">Address</Typography>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 2,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Address name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Address name"
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                disabled={disabledFields}
              />
            )}
          />
          <Controller
            control={control}
            name="line1"
            rules={{ required: 'Address line 1 is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Address line 1"
                error={Boolean(errors.line1)}
                helperText={errors.line1?.message}
                disabled={disabledFields}
              />
            )}
          />
          <Controller
            control={control}
            name="line2"
            render={({ field }) => (
              <TextField
                {...field}
                label="Address line 2"
                error={Boolean(errors.line2)}
                helperText={errors.line2?.message}
                disabled={disabledFields}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <Controller
            control={control}
            name="city"
            rules={{ required: 'City is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="City"
                error={Boolean(errors.city)}
                helperText={errors.city?.message}
                disabled={disabledFields}
              />
            )}
          />
          <Controller
            control={control}
            name="state"
            rules={{ required: 'State is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="State"
                error={Boolean(errors.state)}
                helperText={errors.state?.message}
                disabled={disabledFields}
              />
            )}
          />
          <Controller
            control={control}
            name="zip"
            rules={{ required: 'Zip is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Zip"
                error={Boolean(errors.zip)}
                helperText={errors.zip?.message}
                disabled={disabledFields}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <Controller
            control={control}
            name="country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Country"
                variant="outlined"
                error={Boolean(errors.country)}
                helperText={errors.country?.message}
                disabled={disabledFields}
              />
            )}
          />
        </Box>
      </Card>
      <Divider />
      <Typography variant="h6">Timezone</Typography>
      <Controller
        control={control}
        name="timeZone"
        rules={{ required: 'Timezone is required' }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            required
            label="Timezone"
            error={Boolean(errors.timeZone)}
            helperText={errors.timeZone?.message}
            disabled={disabledFields}
          >
            {COMMON_TIMEZONES.map((timezone) => (
              <MenuItem key={timezone} value={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Stack>
  );
};

export default GeneralSettings;
