import { ChevronRight, QrCode } from '@mui/icons-material';
import {
  Chip,
  Stack,
  type StepIconProps,
  StepLabel,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import {
  LineHaulManifestStatus,
  type StandardOrderFragmentFragment,
} from '../../../../../../../generated/graphql';
import theme from '../../../../../../../theme';
import LineHaulPartialPiecesWarning from '../../../../../../line-haul/components/common/line-haul-partial-pieces-warning';
import PalletLink from '../../../../../../../pallet-ui/links/link/pallet-link';
import { exhaustive } from 'shared/switch';

type QontoStepIconRootProps = {
  ownerState: {
    active?: boolean;
    completed?: boolean;
  };
};

const QontoStepIconRoot = styled('div')<QontoStepIconRootProps>(
  ({ ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(!isNil(ownerState.completed) &&
      ownerState.completed && {
        color: 'black',
      }),
    ...(!isNil(ownerState.active) &&
      ownerState.active && {
        color: 'black',
      }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const QontoStepIcon = ({ active, completed, className }: StepIconProps) => (
  <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
    <div className="QontoStepIcon-circle" />
  </QontoStepIconRoot>
);

type StatusChipProps = {
  terminalStatus: LineHaulManifestStatus;
};

const StatusChip = styled(Chip)<StatusChipProps>`
  width: fit-content;
  font-size: 12px;
  margin: auto;
  ${({ terminalStatus }) => {
    switch (terminalStatus) {
      case LineHaulManifestStatus.Departed: {
        return `
          background-color: #b8dbf7;
          color: #0d47a1;
          border-color: #3c82f6;
        `;
      }
      case LineHaulManifestStatus.Arrived: {
        return `
          background-color: #ffecb3;
          color: #e65200;
          border-color: #ffc403;
        `;
      }
      case LineHaulManifestStatus.Planning: {
        return `
          background-color: #f0f0f0;
          color: black;
        `;
      }
      default: {
        return exhaustive(terminalStatus);
      }
    }
  }}
`;

type ScanIndicatorProps = Readonly<{
  scans: Array<{ scannedAt: string }>;
}>;

const ScanIndicator = ({ scans }: ScanIndicatorProps) => {
  const lastScan = scans.at(-1);
  if (isNil(lastScan)) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <QrCode sx={{ color: 'gray' }} fontSize="small" />
      <Typography variant="caption" color="gray">
        {dayjs(lastScan.scannedAt).format('MM/DD hh:mm:ss A')}
      </Typography>
    </Stack>
  );
};

const LinkInChip = styled(PalletLink)`
  cursor: pointer;
  direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0070e0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

type ManifestLinkProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
}>;

const ManifestLink = ({ event }: ManifestLinkProps) => {
  const linkDateText = isNil(event.departingManifest?.startedAt) ? null : (
    <Typography
      overflow="hidden"
      whiteSpace="nowrap"
      fontSize={12}
      maxWidth="120px"
      textOverflow="ellipsis"
    >
      {dayjs(event.departingManifest.startedAt as string).format('MM/DD h:mma')}
    </Typography>
  );
  if (isNil(event.departingManifest)) {
    if (!isNil(event.markedArrivedAt)) {
      return (
        <Typography sx={{ fontSize: 12 }}>
          {dayjs(event.markedArrivedAt as string).format('MM/DD h:mma')}
        </Typography>
      );
    }
    return null;
  }
  return (
    <Stack>
      <LinkInChip
        href={`/dispatch/line-haul/?manifestUuid=${event.departingManifest.uuid}`}
        target="_blank"
      >
        <Stack>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '120px',
              fontSize: 12,
            }}
          >
            {event.departingManifest.referenceNumber}
          </Typography>
          {event.departureScans.length === 0 &&
            event.arrivalScans.length === 0 &&
            linkDateText}
        </Stack>
        <ChevronRight sx={{ cursor: 'pointer' }} fontSize="small" />
      </LinkInChip>
      {!isNil(event.departingManifest?.driver) && (
        <Typography sx={{ fontSize: 12 }} color={theme.palette.grey[700]}>
          Driver: {event.departingManifest?.driver?.firstName}{' '}
          {event.departingManifest?.driver?.lastName}
        </Typography>
      )}
    </Stack>
  );
};

type StatusContentProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
  expectedPieceCount: number;
}>;

const StatusContent = ({ event, expectedPieceCount }: StatusContentProps) => {
  if (event.departureScans.length > 0) {
    return (
      <>
        <StatusChip
          label="DEPARTED"
          terminalStatus={LineHaulManifestStatus.Departed}
          variant="outlined"
        />
        <Stack alignItems="center" margin="auto" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.departureScans} />
          <LineHaulPartialPiecesWarning
            totalScans={event.departureScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </>
    );
  }
  if (event.arrivalScans.length > 0) {
    return (
      <>
        <StatusChip
          label="ARRIVED"
          terminalStatus={LineHaulManifestStatus.Arrived}
          variant="outlined"
        />
        <Stack alignItems="center" margin="auto" gap={1}>
          <ManifestLink event={event} />
          <LineHaulPartialPiecesWarning
            totalScans={event.arrivalScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </>
    );
  }
  if (
    event.departingManifest?.status === LineHaulManifestStatus.Departed ||
    event.departingManifest?.status === LineHaulManifestStatus.Arrived
  ) {
    return (
      <>
        <StatusChip
          label="DEPARTED"
          terminalStatus={LineHaulManifestStatus.Departed}
          variant="outlined"
        />
        <Stack alignItems="center" margin="auto" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.departureScans} />
          <LineHaulPartialPiecesWarning
            totalScans={event.departureScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </>
    );
  }
  if (event.departingManifest?.status === LineHaulManifestStatus.Planning) {
    return (
      <>
        <StatusChip
          label="PLANNING"
          terminalStatus={LineHaulManifestStatus.Planning}
        />
        <ManifestLink event={event} />
      </>
    );
  }
  if (!isNil(event.markedArrivedAt)) {
    return (
      <>
        <StatusChip
          label="ARRIVED"
          terminalStatus={LineHaulManifestStatus.Arrived}
          variant="outlined"
        />
        <Stack alignItems="center" margin="auto" gap={1}>
          <ManifestLink event={event} />
          <ScanIndicator scans={event.arrivalScans} />
          <LineHaulPartialPiecesWarning
            // Display a warning for missing departure scans. The idea is to prefer the next leg, since the
            // manifest has already arrived.
            totalScans={event.departureScans.length}
            totalPieces={expectedPieceCount}
          />
        </Stack>
      </>
    );
  }
  return (
    <>
      <StatusChip
        label="AWAITING"
        terminalStatus={LineHaulManifestStatus.Planning}
      />
      <ManifestLink event={event} />
    </>
  );
};

type LineHaulTimelineEventProps = Readonly<{
  event: StandardOrderFragmentFragment['lineHaulTimeline'][number];
  expectedPieceCount: number;
}>;

export const LineHaulTimelineEvent = ({
  event,
  expectedPieceCount,
}: LineHaulTimelineEventProps) => (
  <StepLabel
    sx={{ padding: 0, marginTop: 0 }}
    StepIconComponent={QontoStepIcon}
  >
    <Stack alignItems="center" sx={{ fontSize: 12 }} gap={1}>
      <Typography>{event.terminal.code}</Typography>
      <StatusContent event={event} expectedPieceCount={expectedPieceCount} />
    </Stack>
  </StepLabel>
);
