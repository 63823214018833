import { TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useMemo, type SyntheticEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { type Option } from '../../../../common/types';
import { useServicesForThirdPartyUserQuery } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { StopMethod } from '../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import { type CustomerPortalOrderFormValues } from './forms/types';
import { getStopTypeFromServiceLevel } from './forms/utils';

const isOptionEqualToValue = (option: Option, value: Option) =>
  option.value === value.value;

const getOptionLabel = (option: Option) => option.label;

const CustomerPortalServiceField = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<CustomerPortalOrderFormValues>();
  const serviceUuid = useWatch({ control, name: 'serviceId' });

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const { companyUuid } = useCustomerPortalOrderFormContext();

  const { data } = useServicesForThirdPartyUserQuery({
    variables: {
      findServicesForThirdPartyUserInput: {
        companyUuid,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { serviceLevelOptions, selectedOption } = useMemo(() => {
    const services = data?.servicesForThirdPartyUser ?? [];

    const options = services
      .map((service) => ({
        value: service.uuid,
        label: sentenceCase(service.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const selectedService = services.find(
      (service) => service.uuid === serviceUuid,
    );

    const selected = isNil(selectedService)
      ? undefined
      : {
          value: selectedService.uuid,
          label: sentenceCase(selectedService.name),
        };

    return {
      serviceLevelOptions: options,
      selectedOption: selected,
    };
  }, [data?.servicesForThirdPartyUser, serviceUuid]);

  /**
   * Update the service level in the form and clear the inbound/outbound stop
   * if the new service level doesn't include that stop method.
   *
   * This could be done as transformation in the Zod schema too.
   */
  const onChange = (newServiceUuid: string) => {
    const newSelectedService = data?.servicesForThirdPartyUser.find(
      (service) => service.uuid === newServiceUuid,
    );
    const newInboundStopType = getStopTypeFromServiceLevel(
      StopMethod.Inbound,
      newSelectedService?.defaultInboundStopType ?? null,
      newSelectedService?.defaultOutboundStopType ?? null,
    );
    const newOutboundStopType = getStopTypeFromServiceLevel(
      StopMethod.Outbound,
      newSelectedService?.defaultInboundStopType ?? null,
      newSelectedService?.defaultOutboundStopType ?? null,
    );

    if (newInboundStopType === null) {
      setValue('inboundInformation', undefined);
    }
    if (newOutboundStopType === null) {
      setValue('outboundInformation', undefined);
    }

    setValue('serviceId', newServiceUuid);
  };

  return (
    <AutocompleteFuzzy
      // Without key, the autocomplete doesn't display the selected option
      // when the form is populated (e.g. after fetching an existing order)
      key={selectedOption?.value}
      disableClearable
      fullWidth
      size="small"
      disabled={disabledIfNoAccess}
      value={selectedOption}
      matchSortOptions={{ keys: ['label'] }}
      isOptionEqualToValue={isOptionEqualToValue}
      options={serviceLevelOptions}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="Service Level"
          error={!isNil(errors.serviceId)}
          helperText={errors.serviceId?.message}
        />
      )}
      onChange={(_event: SyntheticEvent, option: Option) => {
        onChange(option.value);
      }}
    />
  );
};

export default CustomerPortalServiceField;
