import { Button, CircularProgress } from '@mui/material';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { useSageBusinessWorksInvoiceExportCsvLazyQuery } from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';

const ExportToSageBusinessWorks = () => {
  const [selectedInvoiceUuids, createFileDownload] = useInvoicesStore(
    (state) => [state.selectedInvoiceUuids, state.createFileDownload],
    shallow,
  );

  const [getSageBusinessWorksInvoiceExportCsv, { loading }] =
    useSageBusinessWorksInvoiceExportCsvLazyQuery();

  const onExport = async (uuids: string[]) => {
    const completeDownload = createFileDownload();

    try {
      const csvRes = await getSageBusinessWorksInvoiceExportCsv({
        variables: {
          invoiceUuids: uuids,
        },
      });

      const presignedGetUrl =
        csvRes.data?.sageBusinessWorksInvoiceExportCsv.url;
      const fileName = csvRes.data?.sageBusinessWorksInvoiceExportCsv.fileName;
      const errors = csvRes.data?.sageBusinessWorksInvoiceExportCsv.errors;

      if (!isNil(errors) && !isEmpty(errors)) {
        completeDownload({ alertSeverity: 'error', message: errors[0] });
      } else if (!isNil(presignedGetUrl) && !isNil(fileName)) {
        const getFileRes = await fetch(presignedGetUrl, {
          cache: 'no-cache',
        });

        const blob = await getFileRes.blob();

        fileDownload(blob, fileName);
        completeDownload();
      } else {
        completeDownload({
          alertSeverity: 'error',
          message:
            'An error occurred while generating the export, please try again and contact support if the issue persists',
        });
      }
    } catch (error) {
      completeDownload({
        alertSeverity: 'error',
        message:
          'An error occurred while generating the report, please try again and contact support if the issue persists',
      });
      // eslint-disable-next-line no-console
      console.error('Error exporting invoices to Sage BusinessWorks', error);
    }
  };

  return (
    <Button
      disabled={selectedInvoiceUuids.length === 0 || loading}
      variant="contained"
      color="success"
      onClick={async () => onExport(selectedInvoiceUuids)}
    >
      {loading && <CircularProgress size={10} />}
      {selectedInvoiceUuids.length > 0
        ? `Export invoices (${selectedInvoiceUuids.length})`
        : 'Export invoices'}
    </Button>
  );
};

export default ExportToSageBusinessWorks;
