import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  type ServiceRegionEntity,
  useCreateServiceRegionsMutation,
} from '../../../generated/graphql';
import { useErrors } from '../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../common/components/errors-alert';

type CreateServiceRegionModalProps = Readonly<{
  open: boolean;
  onCreated: (
    serviceRegions: Array<
      Pick<ServiceRegionEntity, 'id' | 'name' | 'isArchived'>
    >,
  ) => void;
  onClose: () => void;
}>;

export const CreateServiceRegionModal = ({
  open,
  onCreated,
  onClose,
}: CreateServiceRegionModalProps) => {
  const { errors, onError, clearErrors } = useErrors();
  const [name, setName] = useState('');
  const [createServiceRegions, { loading: createServiceRegionsLoading }] =
    useCreateServiceRegionsMutation({
      onCompleted: (data) => {
        if (
          data.createServiceRegions.__typename ===
          'CreateServiceRegionsSuccessOutput'
        ) {
          setName('');
          onCreated(data.createServiceRegions.serviceRegions);
        } else {
          onError(data.createServiceRegions);
        }
      },
    });

  const handleCancel = () => {
    clearErrors();
    setName('');
    onClose();
  };

  const handleCreate = () => {
    clearErrors();
    const trimmedName = name.trim();
    if (trimmedName === '') {
      onError('Name is required');
      return;
    }
    void createServiceRegions({
      variables: {
        input: {
          serviceRegions: [{ name: trimmedName }],
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{ sx: { width: '460px' } }}
      onClose={handleCancel}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <Typography variant="h4" fontSize="20px" lineHeight={1}>
          Create a service region
        </Typography>
        <IconButton
          disabled={createServiceRegionsLoading}
          style={{ width: '30px', height: '30px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={3}>
        <ErrorsAlert errors={errors} onClear={clearErrors} />
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            size="small"
            label="Name"
            value={name}
            style={{ width: '100%' }}
            onChange={({ target }) => {
              setName(target.value);
            }}
          />
        </FormControl>
      </Box>
      <Divider />
      <Stack direction="row" justifyContent="flex-end" p={2} gap={2}>
        <Button
          variant="text"
          color="inherit"
          disabled={createServiceRegionsLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={createServiceRegionsLoading}
          onClick={handleCreate}
        >
          Create
        </Button>
      </Stack>
    </Dialog>
  );
};
