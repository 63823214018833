import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Stack,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Chip,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import {
  EdiTransactionCode,
  useEdiTransactionsLazyQuery,
} from '../../../../generated/graphql';

const DEFAULT_EDI_MESSAGE_PAGE_SIZE = 15;

const mapEDITransactionCodeToEvent = (code: EdiTransactionCode) => {
  switch (code) {
    case EdiTransactionCode.MotorCarrierLoadTender: {
      return 'Load Tender (204)';
    }
    case EdiTransactionCode.MotorCarrierBillOfLading: {
      return 'Bill of Lading (211)';
    }
    case EdiTransactionCode.ResponseToALoadTender: {
      return 'Response to Load Tender (990)';
    }
    case EdiTransactionCode.TransportationCarrierShipmentStatusMessage: {
      return 'Shipment Status Update (214)';
    }
    case EdiTransactionCode.MotorCarrierFreightDetailsAndInvoice: {
      return 'Invoice (210)';
    }
  }
  return 'Unknown Transaction';
};

const INBOUND_EDI_TRANSACTIONS = new Set([
  EdiTransactionCode.MotorCarrierLoadTender,
  EdiTransactionCode.MotorCarrierBillOfLading,
]);

/**
 * Graphql pagination args
 * https://relay.dev/graphql/connections.htm
 */
type FetchEdiTransactionsParams = {
  first?: number | null | undefined;
  after?: string | null | undefined;
  last?: number | null | undefined;
  before?: string | null | undefined;
  searchTerms?: string[];
};

const EdiMessagesTable = () => {
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [
    findEdiTransactions,
    { data: ediTransactionsData, loading: ediTransactionsLoading },
  ] = useEdiTransactionsLazyQuery();

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const handleSubmitSearch = () => {
    if (isEmpty(searchInputValue)) return;
    if (searchTerms.includes(searchInputValue)) return;

    setSearchTerms([...searchTerms, searchInputValue]);
    setSearchInputValue('');
  };

  const fetchEdiTransactions = async ({
    first,
    after,
    last,
    before,
  }: FetchEdiTransactionsParams) => {
    await findEdiTransactions({
      variables: {
        first,
        after,
        last,
        before,
        searchTerms,
      },
    });
  };

  useEffect(() => {
    fetchEdiTransactions({ first: DEFAULT_EDI_MESSAGE_PAGE_SIZE, searchTerms });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  return (
    <Stack height="100%" spacing={1}>
      <Box>
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ marginRight: '10px', ml: 2 }}
        >
          <div>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="Find messages by HAWB"
                InputProps={{
                  style: { backgroundColor: 'white' },
                  endAdornment: (
                    <ClearTextFieldButton
                      searchText={searchInputValue}
                      handleClearSearchText={() => {
                        setSearchInputValue('');
                      }}
                    />
                  ),
                }}
                value={searchInputValue}
                sx={{ minWidth: '300px' }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    handleSubmitSearch();
                  }
                }}
                onChange={(e) => {
                  setSearchInputValue(e.target.value);
                }}
              />
              <Button
                variant="contained"
                disabled={ediTransactionsLoading}
                sx={{ mb: 'auto' }}
                onClick={handleSubmitSearch}
              >
                Search
              </Button>
            </Stack>
            {searchTerms.length > 0 && (
              <Stack direction="row" flexWrap="wrap">
                {searchTerms.map((searchTerm) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    label={searchTerm}
                    sx={{ mt: 1, mr: 1 }}
                    onDelete={() => {
                      setSearchTerms(
                        searchTerms.filter((term) => term !== searchTerm),
                      );
                    }}
                  />
                ))}
                <Button
                  variant="outlined"
                  sx={{ whiteSpace: 'no-wrap', textAlign: 'center', mt: 1 }}
                  onClick={() => {
                    setSearchTerms([]);
                  }}
                >
                  Clear ({searchTerms.length})
                </Button>
              </Stack>
            )}
          </div>
          <Stack direction="row">
            {ediTransactionsLoading && <CircularProgress size={20} />}
            <IconButton
              disabled={
                ediTransactionsLoading ||
                (ediTransactionsData?.ediTransactions.pageInfo
                  .hasPreviousPage !== true &&
                  !canGoPrevious)
              }
              onClick={async () => {
                await fetchEdiTransactions({
                  last: DEFAULT_EDI_MESSAGE_PAGE_SIZE,
                  before:
                    ediTransactionsData?.ediTransactions.pageInfo.startCursor,
                });
                setCanGoPrevious(false);
                setCanGoNext(true);
              }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              disabled={
                ediTransactionsLoading ||
                (ediTransactionsData?.ediTransactions.pageInfo.hasNextPage !==
                  true &&
                  !canGoNext)
              }
              onClick={async () => {
                await fetchEdiTransactions({
                  first: DEFAULT_EDI_MESSAGE_PAGE_SIZE,
                  after:
                    ediTransactionsData?.ediTransactions.pageInfo.endCursor,
                });
                setCanGoPrevious(true);
                setCanGoNext(false);
              }}
            >
              <ChevronRight />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <Box minHeight={0} overflow="auto">
        <Table stickyHeader>
          <TableHead>
            <TableCell>EDI Transaction</TableCell>
            <TableCell>Sent At</TableCell>
            <TableCell>Direction</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Shipper Bill of Lading #</TableCell>
            <TableCell>Reference #</TableCell>
          </TableHead>
          <TableBody>
            {ediTransactionsData?.ediTransactions.edges.map(
              ({ node: ediTransaction }) => {
                return (
                  <TableRow key={ediTransaction.uuid}>
                    <TableCell>
                      {mapEDITransactionCodeToEvent(ediTransaction.code)}
                    </TableCell>
                    <TableCell>
                      {dayjs(ediTransaction.updatedAt).format(
                        'MM/DD/YYYY [at] hh:mmA',
                      )}
                    </TableCell>
                    <TableCell>
                      {INBOUND_EDI_TRANSACTIONS.has(ediTransaction.code)
                        ? 'Received'
                        : 'Sent'}
                    </TableCell>
                    <TableCell>
                      {startCase(toLower(ediTransaction.status))}
                    </TableCell>
                    <TableCell>{ediTransaction.order?.name ?? 'N/A'}</TableCell>
                    <TableCell>
                      {ediTransaction.order?.standardOrderFields
                        .shipperBillOfLadingNumber ??
                        ediTransaction.hawb ??
                        'N/A'}
                    </TableCell>
                    <TableCell>
                      {isNilOrEmptyString(
                        ediTransaction.order?.secondaryRefNumber,
                      )
                        ? 'N/A'
                        : ediTransaction.order?.secondaryRefNumber}
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  );
};
export default EdiMessagesTable;
