import { TextField } from '@mui/material';
import { type ICellRendererParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PhoneInputMask from '../../../common/components/phone/phone-input-mask';
import { type PhoneNumberData } from '../types/types';

const component = forwardRef((params: ICellRendererParams, ref) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      getPhoneNumberInfo(): PhoneNumberData {
        if (phoneNumber.length === 0) {
          return {};
        }
        if (phoneNumber.length > 0 && phoneNumber.length !== 14) {
          setError(true);
          return {};
        }
        return {
          phoneNumber,
          stopUuid: params.data.uuid,
          contactPersonUuid: params.data.contactPerson?.uuid ?? undefined,
        };
        //   console.log(phoneNumber);
      },
    };
  });

  if (isNil(params) || isNil(params.data)) {
    return null;
  }

  if (
    !isNil(params.data.contactPerson) &&
    !isNil(params.data.contactPerson.phone)
  ) {
    return params.data.contactPerson.phone;
  }

  return (
    <TextField
      size="small"
      value={phoneNumber}
      error={error}
      helperText={error ? 'invalid number' : ''}
      InputProps={{
        inputComponent: PhoneInputMask as any,
      }}
      onChange={(e) => {
        if (error) {
          setError(false);
        }
        setPhoneNumber(e.target.value);
      }}
    />
  );
});
export default component;
