import {
  customChargeOrCostBaseSchema,
  refineExtendedBaseCustomSchema,
} from './custom-charge-or-cost-base-schema';
import { isNil } from 'lodash';

// An object that tracks custom costs/accessorials attached to a settlement bill line item
// Used for driver/agent settlement. The analogous object when tracking charges for a customer is customCharge
// See https://www.notion.so/RFC-179 for more details
export const customCostSchema = refineExtendedBaseCustomSchema(
  customChargeOrCostBaseSchema,
).transform((data) => {
  if (data.isAutoApplied) {
    // eslint-disable-next-line no-console
    console.warn(
      `Settlement custom cost ${data.uuid} was incorrectly marked as auto-applied, setting back to false`,
    );
  }
  const fuelSurchargePercentageRatesMatch =
    data.fuelSurchargePercentageRate === data.postedFuelSurchargeRate ||
    (isNil(data.postedFuelSurchargeRate) &&
      isNil(data.fuelSurchargePercentageRate));
  if (!fuelSurchargePercentageRatesMatch) {
    // eslint-disable-next-line no-console
    console.warn(
      `Actual and posted fuel surcharge rates differ for settlement custom cost ${data.uuid}: actual is ${data.fuelSurchargePercentageRate} and posted is ${data.postedFuelSurchargeRate}. Setting both to actual rate.`,
    );
  }
  return {
    ...data,
    isAutoApplied: false,
    postedFuelSurchargeRate: data.fuelSurchargePercentageRate,
  };
});
