import { CircularProgress, Stack } from '@mui/material';
import type React from 'react';
import { type PreviewAddOrdersToInvoicesOutput } from '../../../../../generated/graphql';
import PreviewAdditionsToInvoicesForContactCard from './preview-additions-to-invoices-for-contact-card';

type PreviewSendOrdersProps = {
  readonly previewData: PreviewAddOrdersToInvoicesOutput;
  readonly previewLoading: boolean;
};

const PreviewSendOrders: React.FC<PreviewSendOrdersProps> = ({
  previewData,
  previewLoading,
}) => {
  return (
    <Stack sx={{ height: '100%', justifyContent: 'center' }}>
      {previewLoading ? (
        <CircularProgress size={50} />
      ) : (
        <Stack sx={{ height: '70vh', overflowY: 'scroll' }} spacing={2}>
          {previewData.invoicesToAddToByContact.map((contactPreview) => (
            <PreviewAdditionsToInvoicesForContactCard
              key={contactPreview.contactUuid}
              contactPreview={contactPreview}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default PreviewSendOrders;
