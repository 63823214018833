import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { exhaustive } from 'shared/switch';
import { downloadBlob } from '../../common/utils/file';
import { convertRowsToXlsxBlob } from '../../common/utils/utils';
import {
  type DockScanReportQuery,
  type ItemInventorySummaryQuery,
  ScanType,
} from '../../generated/graphql';

const getScanTypeLabel = (scanType: ScanType) => {
  switch (scanType) {
    case ScanType.Arrival: {
      return 'In';
    }
    case ScanType.Departure: {
      return 'Out';
    }
    case ScanType.Delivery: {
      return 'Delivery';
    }
    default: {
      return exhaustive(scanType);
    }
  }
};

export const convertDockScanReportToCsv = (
  dockScanReport: DockScanReportQuery,
  terminalsEnabled = false,
) => {
  const DOCK_SCAN_REPORT_CSV_HEADERS = [
    'Customer',
    'Scan date',
    ...(terminalsEnabled ? ['Terminal'] : []),
    'Direction',
    'Barcode',
    'Service date',
    'HAWB',
    'MAWB',
    'Piece ID',
    'Consignee',
    'OSD Reason',
    'Pieces on hand',
    'Pieces expected',
  ];
  const csvRows = dockScanReport.dockScanReport.rows.map((row) => {
    return [
      row.contactName,
      dayjs(row.scannedAt).format('MM/DD/YY hh:mm:ss A'),
      ...(terminalsEnabled ? [row.terminalCode ?? ''] : []),
      getScanTypeLabel(row.scanType),
      row.barcode,
      isNil(row.serviceDate) ? '' : dayjs(row.serviceDate).format('MM/DD/YY'),
      row.shipperBillOfLadingNumber,
      row.masterAirwayBillOfLadingNumber,
      row.pieceName,
      row.consigneeName,
      row.osdReason,
      row.pieceMarkedOnHandOrdinal,
      row.piecesExpected,
    ];
  });
  csvRows.unshift(DOCK_SCAN_REPORT_CSV_HEADERS);
  return csvRows;
};

export const downloadItemInventorySummaryXlsx = async (
  items: ItemInventorySummaryQuery['items']['edges'],
) => {
  const ITEM_INVENTORY_SUMMARY_HEADERS = [
    'Customer',
    'Receipt ref #',
    'SKU',
    'Description',
    'Quantity',
    'Containers',
  ];
  const rows = items.map(({ node }) => {
    return [
      node.contact.displayName,
      node.storageOrders.map((receipt) => receipt.referenceNumber).join(', '),
      node.sku,
      node.description ?? '',
      node.quantity.toString(),
      node.storageUnitsCount.toString(),
    ];
  });
  rows.unshift(ITEM_INVENTORY_SUMMARY_HEADERS);
  const xlsxBlob = await convertRowsToXlsxBlob({
    rows,
    sheetName: 'Item inventory summary',
  });
  return downloadBlob(
    xlsxBlob,
    `item-inventory-summary-${dayjs().format('MM-DD')}.xlsx`,
  );
};
