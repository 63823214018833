import { Chip, TextField, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { type DocumentType } from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDocuments from '../react-hooks/use-documents';
import { type Option } from '../types';

type DocumentTypeSelectorProps = {
  readonly value: DocumentType[];
  readonly label?: string;
  readonly disabled?: boolean;
  readonly onChange: (value: DocumentType[]) => void;
  readonly onBlur?: () => void;
};
const DocumentTypeSelector = ({
  value,
  label,
  disabled,
  onChange,
  onBlur,
}: DocumentTypeSelectorProps) => {
  const theme = useTheme();
  const { documentTypesForSelection, getDocumentTypeCopy, loading } =
    useDocuments();

  const options = useMemo(() => {
    return (
      documentTypesForSelection?.map((documentType) => ({
        label: getDocumentTypeCopy({ documentType }),
        value: documentType,
      })) ?? []
    );
  }, [documentTypesForSelection, getDocumentTypeCopy]);

  const selectedOptions = useMemo(() => {
    return value.map((documentType) => ({
      label:
        getDocumentTypeCopy?.({ documentType }) ?? sentenceCase(documentType),
      value: documentType,
    }));
  }, [getDocumentTypeCopy, value]);

  const handleChange = (newValue: Array<Option<DocumentType>>) => {
    onChange(newValue.map((option) => option.value));
  };
  if (loading) return null;
  return (
    <AutocompleteFuzzy
      multiple
      disableCloseOnSelect
      options={options}
      value={selectedOptions}
      limitTags={1} // limit so that selector does not grow beyond one line
      size="small"
      matchSortOptions={{ keys: ['label'] }}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      renderTags={(tagsValue, getTagProps) => {
        if (tagsValue.length === 1) {
          const firstTag = tagsValue[0];
          if (!isNil(firstTag)) {
            return (
              <Chip
                {...getTagProps({ index: 0 })}
                key={firstTag.value}
                label={firstTag.label}
                size="small"
              />
            );
          }
        }
        return (
          <Chip label={`${tagsValue.length} documents selected`} size="small" />
        );
      }}
      onChange={(_, newValue) => {
        handleChange(newValue);
      }}
      onBlur={onBlur}
    />
  );
};
export default DocumentTypeSelector;
