import { FormControl, TextField, type TextFieldProps } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useWatch,
  type FieldPathByValue,
  type FieldValues,
} from 'react-hook-form';
import {
  isValidPartialOrCompleteNonNegativeIntegerInput,
  isValidPartialOrCompleteNumberInput,
} from '../../../../../../../utils';

/**
 * Generic form number input component for react-hook-form forms
 *
 * Accepts an optional TFieldValues generic to guarantee that the
 * fieldName is a path to a number field
 *
 * Note: the generic doesn't work with union types, e.g. OrderFormFieldValues
 */
const FormNumberInput = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPathByValue<
    TFieldValues,
    number | null | undefined
  > = FieldPathByValue<TFieldValues, number | null | undefined>,
>(
  props: TextFieldProps & {
    readonly fieldName: TFieldName;
    /** If true, input must be a non-negative integer */
    readonly nonNegativeInteger?: boolean;
  },
) => {
  const { fieldName, nonNegativeInteger, onBlur, onChange, ...textFieldProps } =
    props;

  const { control } = useFormContext<TFieldValues>();

  // This type assert is safe because we've verified that `fieldName` is a path to a number field
  const value = useWatch({
    control,
    name: fieldName,
  }) as number | null | undefined;

  const [numberInput, setNumberInput] = useState<string>();

  useEffect(() => {
    if (isNil(value)) {
      // Coalesce to '' rather than undefined to make sure the TextField updates
      setNumberInput('');
    } else {
      setNumberInput(String(value));
    }
  }, [value]);

  const checkInput = (input: string) => {
    if (nonNegativeInteger === true) {
      return isValidPartialOrCompleteNonNegativeIntegerInput(input);
    }
    return isValidPartialOrCompleteNumberInput(input);
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange: controllerOnChange } }) => (
        <FormControl fullWidth>
          <TextField
            size="small"
            value={numberInput ?? value}
            onBlur={(e) => {
              if (isNil(onBlur)) {
                const parsedAmount = Number.parseFloat(numberInput ?? '');
                const newValue = Number.isNaN(parsedAmount)
                  ? null
                  : parsedAmount;
                setNumberInput(newValue?.toString() ?? '');
                controllerOnChange(newValue);
              } else {
                onBlur(e);
              }
            }}
            onChange={(e) => {
              if (!isNil(onChange)) {
                onChange(e);
              } else if (checkInput(e.target.value)) {
                setNumberInput(e.target.value);
                const parsedAmount = Number.parseFloat(e.target.value);
                if (!Number.isNaN(parsedAmount)) {
                  controllerOnChange(parsedAmount);
                }
              }
            }}
            {...textFieldProps}
          />
        </FormControl>
      )}
    />
  );
};

export default FormNumberInput;
