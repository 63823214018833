import { Button, Stack, Typography } from '@mui/material';
import { type ReactNode, useState } from 'react';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { useTariffChains } from '../../../../common/react-hooks/use-tariff-chains';
import {
  type CompanyDefaultTariffChainFragment,
  useCompanyDefaultTariffChainsQuery,
  useUpdateDefaultTariffChainsForCompanyMutation,
} from '../../../../generated/graphql';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import { TariffChainDropdown } from './tariff-chain-dropdown';
import PalletButton from '../../../../pallet-ui/button/pallet-button';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';

const EditDefaultTariffChainsModal = ({
  open,
  onClose,
  canEdit,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly canEdit: boolean;
}) => {
  const [
    defaultCustomerOrdinaryTariffChain,
    setDefaultCustomerOrdinaryTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);
  const [
    defaultCustomerLineHaulTariffChain,
    setDefaultCustomerLineHaulTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);
  const [
    defaultCustomerTransferTariffChain,
    setDefaultCustomerTransferTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);
  const [
    defaultCustomerPointToPointTariffChain,
    setDefaultCustomerPointToPointTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);

  const [
    defaultSettlementOrdinaryTariffChain,
    setDefaultSettlementOrdinaryTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);
  const [
    defaultSettlementTransferTariffChain,
    setDefaultSettlementTransferTariffChain,
  ] = useState<CompanyDefaultTariffChainFragment | null>(null);

  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const { loading: defaultTariffChainsLoading } =
    useCompanyDefaultTariffChainsQuery({
      onCompleted: (data) => {
        setDefaultCustomerOrdinaryTariffChain(
          data.company.defaultOrdinaryTariffChain ?? null,
        );
        setDefaultCustomerLineHaulTariffChain(
          data.company.defaultLineHaulTariffChain ?? null,
        );
        setDefaultCustomerTransferTariffChain(
          data.company.defaultTransferTariffChain ?? null,
        );
        setDefaultCustomerPointToPointTariffChain(
          data.company.defaultPointToPointTariffChain ?? null,
        );
        setDefaultSettlementOrdinaryTariffChain(
          data.company.defaultOrdinarySettlementTariffChain ?? null,
        );
        setDefaultSettlementTransferTariffChain(
          data.company.defaultTransferSettlementTariffChain ?? null,
        );
      },
    });

  const ffRobustSettlementEnabled = useFeatureFlag(
    FeatureFlag.FF_ROBUST_SETTLEMENT,
  );

  const [updateDefaultTariffChainsForCompany, { loading: updateLoading }] =
    useUpdateDefaultTariffChainsForCompanyMutation({
      onCompleted: (data) => {
        if (
          data.updateDefaultTariffChainsForCompany.__typename ===
          'MutationErrorOutput'
        ) {
          setErrorMessage(data.updateDefaultTariffChainsForCompany.message);
          setShowErrorMessage(true);
        } else {
          onClose();
        }
      },
    });

  const {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
    loading: tariffChainsLoading,
  } = useTariffChains();

  const loading = defaultTariffChainsLoading || tariffChainsLoading;

  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const customerTariffChainDropdowns: ReactNode[] = [];
  const settlementTariffChainDropdowns: ReactNode[] = [];

  if (ordinaryTariffGroupAvailable) {
    customerTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default ordinary tariff chain"
        value={defaultCustomerOrdinaryTariffChain?.id}
        options={ordinaryTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultCustomerOrdinaryTariffChain}
      />,
    );

    settlementTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default settlement ordinary tariff chain"
        value={defaultSettlementOrdinaryTariffChain?.id}
        options={ordinaryTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultSettlementOrdinaryTariffChain}
      />,
    );
  }

  if (lineHaulTariffGroupAvailable) {
    customerTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default line-haul tariff chain"
        value={defaultCustomerLineHaulTariffChain?.id}
        options={lineHaulTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultCustomerLineHaulTariffChain}
      />,
    );
  }

  if (transferTariffGroupAvailable) {
    customerTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default transfer tariff chain"
        value={defaultCustomerTransferTariffChain?.id}
        options={transferTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultCustomerTransferTariffChain}
      />,
    );

    settlementTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default settlement transfer tariff chain"
        value={defaultSettlementTransferTariffChain?.id}
        options={transferTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultSettlementTransferTariffChain}
      />,
    );
  }

  if (pointToPointTariffGroupAvailable) {
    customerTariffChainDropdowns.push(
      <TariffChainDropdown
        label="Default point-to-point tariff chain"
        value={defaultCustomerPointToPointTariffChain?.id}
        options={pointToPointTariffChains ?? []}
        canEdit={canEdit}
        loading={loading}
        onChange={setDefaultCustomerPointToPointTariffChain}
      />,
    );
  }

  const onSave = async () => {
    await updateDefaultTariffChainsForCompany({
      variables: {
        updateDefaultTariffChainsForCompanyInput: {
          defaultOrdinaryTariffChainId: defaultCustomerOrdinaryTariffChain?.id,
          defaultLineHaulTariffChainId: defaultCustomerLineHaulTariffChain?.id,
          defaultTransferTariffChainId: defaultCustomerTransferTariffChain?.id,
          defaultPointToPointTariffChainId:
            defaultCustomerPointToPointTariffChain?.id,
          defaultOrdinarySettlementTariffChainId:
            defaultSettlementOrdinaryTariffChain?.id,
          defaultTransferSettlementTariffChainId:
            defaultSettlementTransferTariffChain?.id,
        },
      },
    });
  };

  return (
    <PalletModal
      open={open}
      title="Edit Default Tariff Chains"
      pinnedElements={{
        bottomRight: (
          <Button
            variant="contained"
            disabled={loading || !canEdit}
            onClick={onSave}
          >
            Save
          </Button>
        ),
        bottomLeft: (
          <PalletButton
            variant="text"
            loading={updateLoading}
            onClick={onClose}
          >
            Cancel
          </PalletButton>
        ),
      }}
      onClose={onClose}
    >
      <Stack direction="row" gap={4} width="800px">
        <Stack direction="column" alignItems="center" gap={1} width="100%">
          <Typography variant="subtitle1">
            Default Customer Tariff Chains
          </Typography>
          {customerTariffChainDropdowns}
        </Stack>
        {ffRobustSettlementEnabled && (
          <Stack direction="column" alignItems="center" gap={1} width="100%">
            <Typography variant="subtitle1">
              Default Settlement Tariff Chains
            </Typography>
            {settlementTariffChainDropdowns}
          </Stack>
        )}
      </Stack>
    </PalletModal>
  );
};

export default EditDefaultTariffChainsModal;
