import {
  Checkbox,
  type SxProps,
  TableCell,
  type Theme,
  TableRow,
} from '@mui/material';
import React from 'react';
import { getOrderTableFieldValue } from '../../utils';
import {
  OrderTableField,
  type FormattedOrderFragment,
} from '../../../../../generated/graphql';
import theme from '../../../../../theme';

type ReviewCustomerPortalOrdersTableRowProps = {
  readonly order: FormattedOrderFragment;
  readonly rowIsSelected: boolean;
  readonly selectRow: () => void;
  readonly unselectRow: () => void;
};

const styles = {
  truncatedTableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  paddedTableCell: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  row: {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.grey[300],
      cursor: 'pointer',
    },
  },
} satisfies Record<string, SxProps<Theme>>;

const ReviewCustomerPortalOrdersTableRow = ({
  order,
  rowIsSelected,
  selectRow,
  unselectRow,
}: ReviewCustomerPortalOrdersTableRowProps) => {
  const toggleSelected = () => {
    if (rowIsSelected) {
      unselectRow();
    } else {
      selectRow();
    }
  };

  return (
    <TableRow
      sx={[
        styles.row,
        rowIsSelected && { backgroundColor: theme.palette.grey[300] },
      ]}
      onClick={toggleSelected}
    >
      <TableCell sx={styles.paddedTableCell}>
        <Checkbox checked={rowIsSelected} onChange={toggleSelected} />
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Name,
        })}
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.BillOfLadingNumber,
        })}
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.CustomerName,
        })}
      </TableCell>
      <TableCell sx={styles.truncatedTableCell}>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.CreatedAt,
        })}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ReviewCustomerPortalOrdersTableRow);
