import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import {
  type DefaultValues,
  useForm,
  type UseFormReturn,
} from 'react-hook-form';
import { useStandardOrderLazyQuery } from '../../../../../generated/graphql';
import { CUSTOMER_PORTAL_ORDER_SCHEMA } from '../forms/customer-portal-order-schema';
import { type CustomerPortalOrderFormValues } from '../forms/types';
import { getExistingCustomerPortalOrderDefaultValues } from '../forms/utils';

type UseViewCustomerPortalOrderFormReturn = {
  loading: boolean;
  form: UseFormReturn<CustomerPortalOrderFormValues>;
  contactUuid: string | null;
};

/**
 * Initializes a form to view an existing customer portal order
 * Read-only for now
 *
 * Eventually we may combine this with @see useCreateCustomerPortalOrderForm
 */
export const useViewCustomerPortalOrderForm = (
  orderUuid: string,
): UseViewCustomerPortalOrderFormReturn => {
  // We could use a different query that fetches a subset of the StandardOrder,
  // but this is a good starting point
  const [
    getStandardOrder,
    { data: standardOrder, loading: getStandardOrderLoading },
  ] = useStandardOrderLazyQuery();
  const form = useForm<CustomerPortalOrderFormValues>({
    resolver: zodResolver(CUSTOMER_PORTAL_ORDER_SCHEMA),
    defaultValues: async () => {
      const { data } = await getStandardOrder({
        variables: { uuid: orderUuid },
      });
      if (isNil(data?.standardOrder)) {
        throw new Error('No standard order found');
      }
      const defaultValues: DefaultValues<CustomerPortalOrderFormValues> =
        getExistingCustomerPortalOrderDefaultValues(data.standardOrder);
      // This cast is necessary because AsyncDefaultValues doesn't use DeepPartial, while
      // DefaultValues does. We can either come up with meaningful default values for when
      // standard order fields are missing (e.g. service can be undefined), or leave it
      // undefined in those cases and cast the types.
      // https://github.com/react-hook-form/react-hook-form/issues/9915
      return defaultValues as CustomerPortalOrderFormValues;
    },
    mode: 'all',
    criteriaMode: 'all',
    disabled: true,
  });

  const contactUuid =
    standardOrder?.standardOrder.billingPartyContact.uuid ?? null;

  // There might be a render cycle or two before the form actually kicks off
  // loading the default values. Until then, `getStandardOrderLoading` is false,
  // but form.formState.isLoading is true.
  const loading = form.formState.isLoading || getStandardOrderLoading;
  return { loading, form, contactUuid };
};
