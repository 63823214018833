import { isNil } from 'lodash';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import {
  GetOrderTableFieldValuesDocument,
  PermissionResource,
  useAcceptCustomerPortalOrderMutation,
  useRejectCustomerPortalOrderMutation,
} from '../../../../../../generated/graphql';
import useGlobalStore from '../../../../../../layouts/dashboard/global-store';
import { type OnSubmitParams } from '../../types';
import OrderReviewActions from './order-review-actions';

type CustomerPortalOrderActionsProps = {
  readonly orderUuid: string | null;
  readonly onSubmit: (args: OnSubmitParams) => Promise<boolean>;
  readonly isSubmitting: boolean;
};

const CustomerPortalOrderActions = ({
  orderUuid,
  onSubmit,
  isSubmitting,
}: CustomerPortalOrderActionsProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteCustomerPortalOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CustomerPortalOrders,
  );
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [acceptOrder, { loading: acceptOrderLoading }] =
    useAcceptCustomerPortalOrderMutation({
      onCompleted: (data) => {
        if (
          data.acceptCustomerPortalOrder.__typename ===
          'AcceptCustomerPortalOrderSuccessOutput'
        ) {
          setSuccessMessage('Order accepted');
          setShowSuccessMessage(true);
        } else if (
          data.acceptCustomerPortalOrder.__typename === 'MutationErrorOutput'
        ) {
          setErrorMessage(
            isNilOrEmptyString(data.acceptCustomerPortalOrder.message)
              ? 'Error accepting order'
              : data.acceptCustomerPortalOrder.message,
          );
          setShowErrorMessage(true);
        }
      },
      refetchQueries: [GetOrderTableFieldValuesDocument],
    });

  const [rejectOrder, { loading: rejectOrderLoading }] =
    useRejectCustomerPortalOrderMutation({
      onCompleted: (data) => {
        if (
          data.rejectCustomerPortalOrder.__typename ===
          'RejectCustomerPortalOrderSuccessOutput'
        ) {
          setSuccessMessage('Order rejected');
          setShowSuccessMessage(true);
        } else if (
          data.rejectCustomerPortalOrder.__typename === 'MutationErrorOutput'
        ) {
          setErrorMessage(
            isNilOrEmptyString(data.rejectCustomerPortalOrder.message)
              ? 'Error rejecting order'
              : data.rejectCustomerPortalOrder.message,
          );
          setShowErrorMessage(true);
        }
      },
      refetchQueries: [GetOrderTableFieldValuesDocument],
    });

  const handleAccept = async () => {
    if (isNil(orderUuid)) {
      return;
    }
    const success = await onSubmit({});
    if (!success) {
      return;
    }
    await acceptOrder({
      variables: {
        acceptCustomerPortalOrderInput: {
          orderUuid,
        },
      },
    });
  };

  const handleReject = async () => {
    if (isNil(orderUuid)) {
      return;
    }
    await rejectOrder({
      variables: {
        rejectCustomerPortalOrderInput: {
          orderUuid,
        },
      },
    });
  };

  return (
    <OrderReviewActions
      disabled={!canWriteCustomerPortalOrders || isNil(orderUuid)}
      acceptLoading={acceptOrderLoading || isSubmitting}
      rejectLoading={rejectOrderLoading}
      onAccept={handleAccept}
      onReject={handleReject}
    />
  );
};

export default CustomerPortalOrderActions;
