import {
  Box,
  styled,
  TableCell,
  type TableCellProps,
  TableRow,
} from '@mui/material';

export const CHECKBOX_CELL_WIDTH = '20px';

export const CompactRow = styled(TableRow)({
  '> *': {
    padding: '0px 5px !important',
    maxWidth: '75px',
  },
});

export const SettlementTableCell = ({ children, ...props }: TableCellProps) => {
  return (
    <TableCell {...props}>
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        width="100%"
      >
        {children}
      </Box>
    </TableCell>
  );
};
