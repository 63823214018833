import { type ContactsReportQuery } from '../../generated/graphql';

export const formatContactsReportToCSV = (
  data: ContactsReportQuery | undefined,
) => {
  const header = [
    'Name',
    'Email',
    'Shortcode',
    'Active',
    'Address name',
    'Address line 1',
    'Address line 2',
    'City',
    'State',
    'Zip code',
    'Business division',
    'Type',
    'Reference number',
    'Number of orders per invoice',
    'Invoice time period split',
    'Credit hold enabled',
    'Credit limit',
    'Billing cycle',
    'Default invoice transmission method',
    'Default invoice type',
  ];
  const rows =
    data?.contactsReport?.map((contact) => [
      contact.name,
      contact.email,
      contact.shortcode,
      contact.active,
      contact.addressName,
      contact.addressLine1,
      contact.addressLine2,
      contact.city,
      contact.state,
      contact.zipcode,
      contact.businessDivision,
      contact.type,
      contact.referenceNumber,
      contact.numberOfOrdersPerInvoice,
      contact.invoiceTimePeriodSplit,
      contact.enableCreditHold,
      contact.creditLimit,
      contact.billingCycle,
      contact.defaultInvoiceTransmissionMethod,
      contact.defaultInvoiceType,
    ]) ?? [];
  return [header, ...rows];
};
