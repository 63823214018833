import {
  Check,
  ChevronLeft,
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { startOfWeek, endOfWeek, setHours } from 'date-fns';
import dayjs, { type ManipulateType, type OpUnitType } from 'dayjs';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { getNoonOfDay } from 'shared/date';
import { exhaustive } from 'shared/switch';
import DateRangeFilter from '../../domains/reports/components/date-range-filter';
import useStyles from './general-styles';

export enum DatePickerFilterType {
  AllSelect = 'All',
  Range = 'Range',
  DayPaginate = 'DayPaginate',
  WeekPaginate = 'WeekPaginate',
  MonthPaginate = 'MonthPaginate',
  PastDayInput = 'Past Day',
  PastWeekInput = 'Past Week',
  PastMonthInput = 'Past Month',
  NextHourInput = 'Next Hour',
}

export type DateOption = {
  filterType: DatePickerFilterType;
  value: number | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

type DateDropdownPickerProps = {
  readonly dateOption: DateOption;
  readonly setDateOption: (option: DateOption) => void;
  readonly showFilters?: DatePickerFilterType[] | undefined;
  readonly defaultFilterType?: DatePickerFilterType | undefined;
  readonly filterTitle?: string;
  readonly small?: boolean;
  readonly allowNoLimit?: boolean;
};

export const initialDateOption = {
  filterType: DatePickerFilterType.AllSelect,
  value: undefined,
  startDate: undefined,
  endDate: undefined,
};

// TODO (Ashank): Audit all reports to determine if they're depending on this logic.
// If they are, we should modify the filter on the backend to calculate the startOf and endOf dates
// to avoid rolling over to the next day when converting to UTC.
export const defaultPast1WeekDateRangeOption = {
  filterType: DatePickerFilterType.Range,
  value: 1,
  startDate: dayjs().startOf('week').toDate(),
  endDate: dayjs().endOf('week').toDate(),
};

export const getDefaultPast1WeekDateRangeOption = () => {
  const now = new Date();
  return {
    filterType: DatePickerFilterType.Range,
    value: 1,
    startDate: startOfWeek(now),
    endDate: setHours(endOfWeek(now), 12),
  };
};

const getInputTimeUnitString = (type: DatePickerFilterType) => {
  switch (type) {
    case DatePickerFilterType.DayPaginate:
    case DatePickerFilterType.PastDayInput: {
      return 'Day';
    }
    case DatePickerFilterType.WeekPaginate:
    case DatePickerFilterType.PastWeekInput: {
      return 'Week';
    }
    case DatePickerFilterType.MonthPaginate:
    case DatePickerFilterType.PastMonthInput: {
      return 'Month';
    }
    case DatePickerFilterType.NextHourInput: {
      return 'Hour';
    }
    case DatePickerFilterType.AllSelect:
    case DatePickerFilterType.Range: {
      return 'All';
    }
    default: {
      exhaustive(type);
    }
  }
};

const getDayPaginateTimeString = (date: Date | undefined) => {
  if (isNil(date)) {
    return 'Select Date';
  }
  if (dayjs().isSame(date, 'day')) {
    return 'Today';
  }
  if (dayjs().add(1, 'day').isSame(date, 'day')) {
    return 'Tomorrow';
  }
  if (dayjs().subtract(1, 'day').isSame(date, 'day')) {
    return 'Yesterday';
  }
  return dayjs(date).format('MM/DD/YYYY');
};

const getWeekPaginateTimeString = (
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  if (isNil(startDate) || isNil(endDate)) {
    return 'Select Week';
  }
  if (dayjs().isSame(startDate, 'week')) {
    return 'This Week';
  }
  if (dayjs().add(1, 'week').isSame(startDate, 'week')) {
    return 'Next Week';
  }
  if (dayjs().subtract(1, 'week').isSame(startDate, 'week')) {
    return 'Last Week';
  }
  return `${dayjs(startDate).format(
    new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
      ? 'MM/DD'
      : 'MM/DD/YYYY',
  )} - ${dayjs(endDate).format('MM/DD/YYYY')}`;
};

const getMonthPaginateTimeString = (startDate: Date | undefined) => {
  if (isNil(startDate)) {
    return 'Select Month';
  }
  if (dayjs().isSame(startDate, 'month')) {
    return 'This Month';
  }
  if (dayjs().add(1, 'month').isSame(startDate, 'month')) {
    return 'Next Month';
  }
  if (dayjs().subtract(1, 'month').isSame(startDate, 'month')) {
    return 'Last Month';
  }
  return `${dayjs(startDate).format('MM/YYYY')}`;
};

const getFilterValueString = (option: DateOption) => {
  switch (option.filterType) {
    case DatePickerFilterType.AllSelect: {
      return option.filterType;
    }
    case DatePickerFilterType.DayPaginate: {
      return getDayPaginateTimeString(option.startDate);
    }
    case DatePickerFilterType.WeekPaginate: {
      return getWeekPaginateTimeString(option.startDate, option.endDate);
    }
    case DatePickerFilterType.MonthPaginate: {
      return getMonthPaginateTimeString(option.startDate);
    }
    case DatePickerFilterType.PastDayInput:
    case DatePickerFilterType.PastWeekInput:
    case DatePickerFilterType.PastMonthInput: {
      if (isNil(option.value)) {
        return 'No Date Selected';
      }
      return `Past ${option.value} ${getInputTimeUnitString(
        option.filterType,
      )}${option.value > 1 ? 's' : ''}`;
    }
    case DatePickerFilterType.NextHourInput: {
      if (isNil(option.value)) {
        return 'No Date Selected';
      }
      return `Next ${option.value} ${getInputTimeUnitString(
        option.filterType,
      )}${option.value > 1 ? 's' : ''}`;
    }
    case DatePickerFilterType.Range: {
      if (isNil(option.startDate) && isNil(option.endDate)) {
        return 'All';
      }
      const formattedStartDate = dayjs(option.startDate).format('MM/DD/YYYY');
      const formattedEndDate = dayjs(option.endDate).format('MM/DD/YYYY');
      if (isNil(option.startDate)) {
        return `Before ${formattedEndDate}`;
      }
      if (isNil(option.endDate)) {
        return `After ${formattedStartDate}`;
      }
      if (dayjs(option.startDate).isSame(option.endDate, 'day')) {
        return formattedEndDate;
      }
      return `${dayjs(option.startDate).format(
        new Date(option.startDate).getFullYear() ===
          new Date(option.endDate).getFullYear()
          ? 'MM/DD'
          : 'MM/DD/YYYY',
      )} - ${formattedEndDate}`;
    }
    default: {
      break;
    }
  }
  return '';
};

/**
 * Date Dropdown Reusable Picker Component
 * Note that we intentionally wrap endOf calls in getNoonOfDay to avoid
 * the date rolling over to the next day when converting to UTC.
 * The backend already accounts for setting the end date to midnight of the day
 *
 * TODO: Refactor to avoid these getNoonOfDay hacks
 *
 * @param dateOption - Date Option State (the state is held in the parent component)
 * @param setDateOption - Set the date option (callback)
 * @param showFilters - List of DatePickerFilterTypes to be shown
 * @param defaultFilterType - Default filter type to set on load
 * @param filterTitle - Filter title to use. The use case would be to specify what type of date you are setting (ex: Completed At Date)
 * @constructor
 */
const DateDropdownPicker = ({
  dateOption,
  setDateOption,
  showFilters,
  defaultFilterType,
  filterTitle = 'Date',
  small = false,
  allowNoLimit = false,
}: DateDropdownPickerProps) => {
  const styles = useStyles();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterTypeChange = (type: DatePickerFilterType) => {
    if (dateOption.filterType === type) {
      return;
    }
    const newState = { ...dateOption };
    newState.filterType = type;
    switch (type) {
      case DatePickerFilterType.AllSelect: {
        newState.startDate = undefined;
        newState.endDate = undefined;

        break;
      }
      case DatePickerFilterType.DayPaginate: {
        newState.startDate = dayjs().toDate();
        newState.endDate = dayjs().toDate();

        break;
      }
      case DatePickerFilterType.WeekPaginate: {
        newState.startDate = dayjs().startOf('week').toDate();
        newState.endDate = getNoonOfDay(dayjs().endOf('week'));

        break;
      }
      case DatePickerFilterType.MonthPaginate: {
        newState.startDate = dayjs().startOf('month').toDate();
        newState.endDate = getNoonOfDay(dayjs().endOf('month'));

        break;
      }
      case DatePickerFilterType.Range: {
        newState.startDate = dayjs().toDate();
        newState.endDate = dayjs().toDate();

        break;
      }
      // No default
    }
    newState.value = undefined;
    setDateOption(newState);
  };

  const handleNumberInputChange = (
    value: string,
    type: DatePickerFilterType,
  ) => {
    const number = Number.parseFloat(value);
    if (Number.isNaN(number)) {
      setDateOption({
        ...dateOption,
        value: undefined,
      });
    } else
      switch (type) {
        case DatePickerFilterType.PastDayInput: {
          const startDate = dayjs()
            .subtract(number - 1, 'day')
            .startOf('day')
            .toDate();
          const endDate = dayjs().toDate();
          setDateOption({
            ...dateOption,
            value: number,
            startDate,
            endDate,
          });

          break;
        }
        case DatePickerFilterType.PastWeekInput: {
          const startDate = dayjs()
            .subtract(number - 1, 'week')
            .startOf('week')
            .toDate();
          const endDate = getNoonOfDay(dayjs().endOf('week'));
          setDateOption({
            ...dateOption,
            value: number,
            startDate,
            endDate,
          });

          break;
        }
        case DatePickerFilterType.PastMonthInput: {
          const startDate = dayjs()
            .subtract(number - 1, 'month')
            .startOf('month')
            .toDate();
          const endDate = getNoonOfDay(dayjs().endOf('month'));
          setDateOption({
            ...dateOption,
            value: number,
            startDate,
            endDate,
          });

          break;
        }
        case DatePickerFilterType.NextHourInput: {
          const startDate = dayjs().toDate();
          const endDate = dayjs().add(number, 'hours').endOf('hours').toDate();
          setDateOption({
            ...dateOption,
            value: number,
            startDate,
            endDate,
          });

          break;
        }
        // No default
      }
  };

  const handleGoPrev = (type: DatePickerFilterType) => {
    const timeUnit = getInputTimeUnitString(type).toLowerCase();
    const opUnit = timeUnit as OpUnitType;
    const manipulateUnit = timeUnit as ManipulateType;
    const startDate = dayjs(dateOption.startDate)
      .subtract(1, manipulateUnit)
      .startOf(opUnit)
      .toDate();
    let endDate = dayjs(startDate).endOf(manipulateUnit).toDate();
    endDate = getNoonOfDay(endDate);
    setDateOption({
      ...dateOption,
      startDate,
      endDate,
    });
  };

  const handleGoNext = (type: DatePickerFilterType) => {
    const timeUnit = getInputTimeUnitString(type).toLowerCase();
    const opUnit = timeUnit as OpUnitType;
    const manipulateUnit = timeUnit as ManipulateType;
    const startDate = dayjs(dateOption.startDate)
      .add(1, manipulateUnit)
      .startOf(opUnit)
      .toDate();
    let endDate = dayjs(startDate).endOf(manipulateUnit).toDate();
    endDate = getNoonOfDay(endDate);
    setDateOption({
      ...dateOption,
      startDate,
      endDate,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    setDateOption({
      ...dateOption,
      startDate: date ?? undefined,
    });
  };

  const handleEndDateChange = (date: Date | null) => {
    setDateOption({
      ...dateOption,
      endDate: date ?? undefined,
    });
  };

  useEffect(() => {
    if (!isNil(defaultFilterType)) {
      handleFilterTypeChange(defaultFilterType);
    }
    handleFilterTypeChange(dateOption.filterType);
    if (!isNil(dateOption.value)) {
      handleNumberInputChange(
        dateOption?.value.toString(),
        dateOption.filterType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Tooltip
        placement="top"
        title={
          dateOption.filterType === DatePickerFilterType.AllSelect
            ? ''
            : `${dayjs(dateOption.startDate).format('MM/DD/YYYY')} - ${dayjs(
                dateOption.endDate,
              ).format('MM/DD/YYYY')}`
        }
      >
        <Button
          ref={buttonRef}
          size="small"
          sx={[small ? styles.filterButtonSmall : styles.filterButton]}
          variant="outlined"
          onClick={(e) => {
            setMenuAnchorEl(e.currentTarget);
          }}
        >
          <Box
            sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
          >
            <Typography sx={styles.filterTitle}>{filterTitle}:</Typography>
            <Typography sx={styles.filterValue}>
              {getFilterValueString(dateOption)}
            </Typography>
            <ExpandMore fontSize="small" sx={{ mr: 0 }} />
          </Box>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        open={!isNil(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          {Object.values(DatePickerFilterType)
            .filter(
              (filterType) =>
                isNil(showFilters) || showFilters.includes(filterType),
            )
            .map((filterType) => (
              <MenuItem
                key={filterType}
                sx={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'visible',
                  pl: '10px',
                }}
                onClick={() => {
                  handleFilterTypeChange(filterType);
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Check
                    sx={{
                      visibility:
                        dateOption.filterType === filterType
                          ? undefined
                          : 'hidden',
                      fontSize: '14px',
                      ml: 0,
                      mr: '6px',
                    }}
                  />
                  {[DatePickerFilterType.AllSelect].includes(filterType) && (
                    <Box
                      sx={{
                        ...styles.center,

                        width: '100%',
                      }}
                    >
                      <Typography sx={styles.menuText}>{filterType}</Typography>
                    </Box>
                  )}
                  {filterType === DatePickerFilterType.DayPaginate && (
                    <Grid container alignItems="center">
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoPrev(filterType);
                          }}
                        >
                          <ChevronLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6} sx={styles.center}>
                        <Typography sx={styles.menuText}>
                          {filterType === dateOption.filterType
                            ? getDayPaginateTimeString(dateOption?.startDate)
                            : 'Select Date'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoNext(filterType);
                          }}
                        >
                          <ChevronRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  {filterType === DatePickerFilterType.WeekPaginate && (
                    <Grid container alignItems="center">
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoPrev(filterType);
                          }}
                        >
                          <ChevronLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6} sx={styles.center}>
                        <Typography sx={styles.menuText}>
                          {filterType === dateOption.filterType
                            ? getWeekPaginateTimeString(
                                dateOption?.startDate,
                                dateOption?.endDate,
                              )
                            : 'Select Week'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoNext(filterType);
                          }}
                        >
                          <ChevronRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  {filterType === DatePickerFilterType.MonthPaginate && (
                    <Grid container alignItems="center">
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoPrev(filterType);
                          }}
                        >
                          <ChevronLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={6} sx={styles.center}>
                        <Typography sx={styles.menuText}>
                          {filterType === dateOption.filterType
                            ? getMonthPaginateTimeString(dateOption?.startDate)
                            : 'Select Month'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={styles.center}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={() => {
                            handleGoNext(filterType);
                          }}
                        >
                          <ChevronRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  {[
                    DatePickerFilterType.PastDayInput,
                    DatePickerFilterType.PastWeekInput,
                    DatePickerFilterType.PastMonthInput,
                    DatePickerFilterType.NextHourInput,
                  ].includes(filterType) && (
                    <>
                      <Typography sx={styles.menuText}>
                        {filterType === DatePickerFilterType.NextHourInput
                          ? 'Next'
                          : 'Past'}{' '}
                      </Typography>
                      <TextField
                        type="number"
                        value={
                          dateOption.filterType === filterType
                            ? dateOption.value
                            : ''
                        }
                        name="last_x"
                        InputProps={{
                          inputProps: { pattern: '[0-9]*', min: 0 },
                        }}
                        size="small"
                        sx={{ width: 100 }}
                        onWheel={(e) => {
                          (e.target as HTMLTextAreaElement).blur();
                        }}
                        onChange={(e) => {
                          handleNumberInputChange(e.target.value, filterType);
                        }}
                      />
                      <Typography sx={styles.menuText}>
                        {' '}
                        {getInputTimeUnitString(filterType)}
                      </Typography>
                    </>
                  )}
                  {filterType === DatePickerFilterType.Range && (
                    <DateRangeFilter
                      startDate={
                        dateOption.filterType === DatePickerFilterType.Range
                          ? dateOption.startDate
                          : new Date()
                      }
                      endDate={
                        dateOption.filterType === DatePickerFilterType.Range
                          ? dateOption.endDate
                          : new Date()
                      }
                      handleStartDateChange={handleStartDateChange}
                      handleEndDateChange={handleEndDateChange}
                      allowNoLimit={allowNoLimit}
                    />
                  )}
                </Stack>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </LocalizationProvider>
  );
};

export default DateDropdownPicker;
