import { ArrowLeft } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import FlagIcon from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  FindConsolidatableOrdersError,
  OrderBillingStatus,
  OrderSource,
  OrderStatus,
  type OutstandingOrderFragmentFragment,
  PermissionResource,
  type StandardOrderFragmentFragment,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import Charges from '../../../orders/components/order-form/components/charges';
import DriverSettlement from '../../../orders/components/order-form/components/driver-settlement';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import useSaveOrderBrm from '../../../orders/components/order-form/forms/use-save-order-brm';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import useBillingReviewNavigationActions from '../../hooks/use-billing-review-navigation-actions';
import { isConsolidatableStopType } from '../../utils';
import ConsolidateOrderFlow from './consolidate-order-flow/consolidate-order-flow';
import ConsolidateOutstandingOrdersModal from './consolidate-outstanding-orders-modal';
import OutstandingOrderOverview from './outstanding-order-overview';
import UnconsolidateOutstandingOrdersModal from './unconsolidate-outstanding-orders-modal';

type OutstandingOrderFormProps = {
  readonly setErrorMessage: Dispatch<SetStateAction<string | null>>;
};

const OutstandingOrderForm = ({
  setErrorMessage,
}: OutstandingOrderFormProps) => {
  const theme = useTheme();
  const { companyConfiguration } = useMe();
  const ffShowProrateConsolidationFlow = useFeatureFlag(
    FeatureFlag.FF_SHOW_PRORATE_CONSOLIDATION_FLOW,
  );

  const [
    searchedOrders,
    outstandingOrdersInPage,
    openedOutstandingOrderUuid,
    setConsolidateOrderFlowIsOpen,
    consolidateOrderFlowIsOpen,
    hasPreviousPage,
    isSubmitting,
    setIsSubmitting,
  ] = useBillingReviewStore(
    (state) => [
      state.searchedOrders,
      state.outstandingOrdersInPage,
      state.openedOutstandingOrderUuid,
      state.setConsolidateOrderFlowIsOpen,
      state.consolidateOrderFlowIsOpen,
      state.paginationInfo.hasPrevPage,
      state.isSubmitting,
      state.setIsSubmitting,
    ],
    shallow,
  );

  const [setOrderUuidToRefetch] = useGlobalStore(
    (state) => [state.setOrderUuidToRefetch],
    shallow,
  );

  const [currentOrder, setCurrentOrder] = useState<
    StandardOrderFragmentFragment | OutstandingOrderFragmentFragment | null
  >(null);
  const [openConsolidationModal, setOpenConsolidationModal] = useState(false);
  const [openUnconsolidationModal, setOpenUnconsolidationModal] =
    useState(false);

  const isStatusIncomplete =
    isNil(currentOrder) ||
    currentOrder.status === OrderStatus.Created ||
    currentOrder.status === OrderStatus.InProgress;

  const { userPermissions } = useUserRoles();
  const { canWrite: canFinalizeChargesWithoutPaperworkComplete } =
    getPermissionsFlags(
      userPermissions,
      PermissionResource.FinalizeChargesOrdersPaperworkIncomplete,
    );

  const cannotFinalizeBecausePaperworkIncomplete =
    !(currentOrder?.paperwork.paperworkComplete ?? false) &&
    !canFinalizeChargesWithoutPaperworkComplete;

  const isIncomplete =
    isStatusIncomplete || cannotFinalizeBecausePaperworkIncomplete;

  const hasPreviousOrder = useMemo(
    () =>
      searchedOrders[0]?.uuid !== openedOutstandingOrderUuid &&
      !(
        isEmpty(searchedOrders) &&
        !hasPreviousPage &&
        outstandingOrdersInPage[0]?.uuid === openedOutstandingOrderUuid
      ),
    [
      openedOutstandingOrderUuid,
      outstandingOrdersInPage,
      searchedOrders,
      hasPreviousPage,
    ],
  );

  const { fetchOrderCacheFirst, refetchOrdersAndOverwriteCache } =
    useBillingReviewActions();

  const { saveAndGoToNextOrder, saveAndGoToPreviousOrder } =
    useBillingReviewNavigationActions();

  const fetchCurrentOrder = async () => {
    if (isNil(openedOutstandingOrderUuid)) {
      setCurrentOrder(null);
      setOpenConsolidationModal(false);
    } else {
      const newOrder = await fetchOrderCacheFirst({
        orderUuid: openedOutstandingOrderUuid,
      });
      setCurrentOrder(newOrder);

      const consolidatableShipments = newOrder?.shipments.filter(
        (s) =>
          isConsolidatableStopType(s.legs[0]?.endStop?.stopType) &&
          !s.shouldBillIndividually,
      );
      setOpenConsolidationModal(
        !isEmpty(newOrder?.consolidatableOrders?.orders) &&
          !(newOrder?.shouldBillIndividually ?? false) &&
          !isEmpty(consolidatableShipments),
      );
    }
  };
  useEffect(() => {
    fetchCurrentOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOutstandingOrderUuid]);

  const { saveOrder: saveCurrentOrder, loading } = useSaveOrderBrm();

  const markReadyToInvoice = async () => {
    setIsSubmitting(true);
    await saveAndGoToNextOrder({
      newBillingStatus: OrderBillingStatus.ReadyToInvoice,
    });
    setIsSubmitting(false);
  };

  const clickConsolidateOrder = async () => {
    if (isNil(openedOutstandingOrderUuid)) {
      setErrorMessage('Order not found. Please try again.');
    } else {
      await saveCurrentOrder({});
      setConsolidateOrderFlowIsOpen(true);
    }
    setIsSubmitting(false);
  };

  const consolidatableShipments = currentOrder?.shipments.filter((s) =>
    isConsolidatableStopType(s.legs[0]?.endStop?.stopType),
  );

  useEffect(() => {
    setOpenConsolidationModal(
      !isEmpty(currentOrder?.consolidatableOrders?.orders) &&
        !isEmpty(consolidatableShipments) &&
        (consolidatableShipments?.some((sh) => !sh.shouldBillIndividually) ??
          false),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOutstandingOrderUuid]);

  const onCloseConsolidateFlow = async ({
    orderUuidsToRefetch,
  }: {
    orderUuidsToRefetch: string[];
  }) => {
    await refetchOrdersAndOverwriteCache(orderUuidsToRefetch);
    if (!isNil(currentOrder)) {
      setOrderUuidToRefetch(currentOrder.uuid);
    }
    setConsolidateOrderFlowIsOpen(false);
  };

  return (
    <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
      {!isNil(currentOrder) && (
        <Dialog
          open={consolidateOrderFlowIsOpen}
          maxWidth="xl"
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '100%',
              },
            },
          }}
        >
          <ConsolidateOrderFlow
            initialOrderUuid={currentOrder.uuid}
            onClose={onCloseConsolidateFlow}
          />
        </Dialog>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '5px',
          pb: '20px',
        }}
      >
        {currentOrder?.source === OrderSource.Consolidation ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '82%',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '18px' }}>
              Reviewing charges for order {currentOrder?.name ?? '-'}
              <Typography color="gray">
                {currentOrder?.consolidatedShipmentString ?? ''}
              </Typography>
            </Typography>
            {!openUnconsolidationModal && (
              <Button
                variant="outlined"
                disabled={isSubmitting}
                sx={{ ml: '10px', px: '30px' }}
                onClick={() => {
                  setOpenUnconsolidationModal(true);
                }}
              >
                Unconsolidate
              </Button>
            )}
          </Box>
        ) : (
          <Typography variant="h6" sx={{ fontSize: '18px' }}>
            Reviewing charges for order {currentOrder?.name ?? '-'}
            {!isEmpty(currentOrder?.consolidatableOrders.orders) &&
              !openConsolidationModal && (
                <Button
                  variant="outlined"
                  disabled={isSubmitting}
                  sx={{ ml: '10px' }}
                  onClick={() => {
                    setOpenConsolidationModal(true);
                  }}
                >
                  Consolidate
                </Button>
              )}
          </Typography>
        )}

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ minWidth: 400 }}
        >
          {ffShowProrateConsolidationFlow && (
            <Tooltip
              title="Order has already been prorated"
              disableHoverListener={isEmpty(currentOrder?.ordersProratedWith)}
              placement="top"
            >
              <Box>
                <Button
                  variant="outlined"
                  disabled={
                    isSubmitting || !isEmpty(currentOrder?.ordersProratedWith)
                  }
                  onClick={clickConsolidateOrder}
                >
                  Consolidate
                </Button>
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <Grid
        container
        sx={{
          p: '10px',
          backgroundColor: theme.palette.background.default,
          height: '100%',
          overflow: 'hidden',
        }}
        spacing={1}
      >
        <Grid
          item
          xs={8}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {}
          {openConsolidationModal ? (
            <ConsolidateOutstandingOrdersModal
              handleClose={() => {
                if (!isNil(openedOutstandingOrderUuid)) {
                  saveCurrentOrder({});
                }
                setOpenConsolidationModal(false);
              }}
              setErrorMessage={setErrorMessage}
            />
          ) : openUnconsolidationModal ? (
            <UnconsolidateOutstandingOrdersModal
              handleClose={() => {
                if (!isNil(openedOutstandingOrderUuid)) {
                  saveCurrentOrder({});
                }
                setOpenUnconsolidationModal(false);
              }}
              handleCloseWithoutSave={() => {
                setOpenUnconsolidationModal(false);
              }}
              setErrorMessage={setErrorMessage}
            />
          ) : (
            <>
              <Box sx={{ height: '90%' }}>
                <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>
                  {currentOrder?.consolidatableOrders.error ===
                    FindConsolidatableOrdersError.MultipleAddresses &&
                    !isEmpty(currentOrder?.consolidatableOrders?.shipments) && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        p={1}
                        my={1}
                        bgcolor={theme.palette.grey[200]}
                      >
                        <InfoIcon color="info" />
                        <Typography>
                          Some shipments in this order can be consolidated with{' '}
                          {currentOrder?.consolidatableOrders?.shipments
                            ?.length ?? 0}{' '}
                          other shipments. If you would like to consolidate
                          these shipments, please separate billable stops
                          (pickup and delivery) into different orders.
                        </Typography>
                      </Stack>
                    )}
                  <GappedStackCard
                    style={{
                      border: `1px solid ${theme.palette.borderColor.main}`,
                      overflow: 'hidden',
                      borderRadius: '4px',
                    }}
                  >
                    <Charges inBillingReview />
                    {companyConfiguration?.showDriverSettlementInBilling ===
                      true && <DriverSettlement />}
                  </GappedStackCard>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: '10px',
                  backgroundColor: 'white',
                  height: '8%',
                }}
                boxShadow={5}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    disabled={!hasPreviousOrder}
                    startIcon={<ArrowLeft />}
                    size="large"
                    onClick={async () => {
                      setIsSubmitting(true);
                      await saveAndGoToPreviousOrder();
                      setIsSubmitting(false);
                    }}
                  >
                    Previous
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Typography color="gray">Continue and mark</Typography>
                  <Button
                    variant="outlined"
                    disabled={isSubmitting || isIncomplete}
                    size="large"
                    onClick={async () => {
                      setIsSubmitting(true);
                      await saveAndGoToNextOrder({
                        newBillingStatus: OrderBillingStatus.NotReadyToInvoice,
                      });
                      setIsSubmitting(false);
                    }}
                  >
                    <>
                      {isSubmitting && (
                        <CircularProgress size={10} sx={{ mr: '5px' }} />
                      )}
                      Not ready
                    </>
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isSubmitting || isIncomplete}
                    color="error"
                    startIcon={<FlagIcon />}
                    size="large"
                    onClick={async () => {
                      setIsSubmitting(true);
                      await saveAndGoToNextOrder({
                        newBillingStatus: OrderBillingStatus.HasIssues,
                      });
                      setIsSubmitting(false);
                    }}
                  >
                    <>
                      {isSubmitting && (
                        <CircularProgress size={10} sx={{ mr: '5px' }} />
                      )}
                      Has issue
                    </>
                  </Button>
                  <Tooltip
                    title={
                      cannotFinalizeBecausePaperworkIncomplete &&
                      'Charges cannot be finalized because paperwork is not complete'
                    }
                  >
                    {/* <span> is needed for the tooltip when the button is disabled */}
                    <span>
                      <Button
                        variant="contained"
                        disabled={isSubmitting || isIncomplete}
                        color="primary"
                        startIcon={<CheckIcon />}
                        size="large"
                        onClick={markReadyToInvoice}
                      >
                        <>
                          {isSubmitting && (
                            <CircularProgress size={10} sx={{ mr: '5px' }} />
                          )}
                          Finalize charges
                        </>
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={4} height="100%">
          <OutstandingOrderOverview
            isConsolidated={currentOrder?.source === OrderSource.Consolidation}
            isStatusIncomplete={isStatusIncomplete}
            cannotFinalizeBecausePaperworkIncomplete={
              cannotFinalizeBecausePaperworkIncomplete
            }
            saveOrderLoading={loading}
            currentOrder={currentOrder}
          />
        </Grid>
      </Grid>
    </OrderFormEditAccessProvider>
  );
};

export default OutstandingOrderForm;
