import { Box, Chip, FormControl, MenuItem, Select } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray, isNil } from 'lodash';
import { useMemo } from 'react';
import { Controller, type Control } from 'react-hook-form';
import {
  VehicleTypeStatus,
  type VehicleTypesMinimalQuery,
} from '../../../generated/graphql';
import { type OrganizationFormValues } from '../form/types';

type MinimalVehicleType = VehicleTypesMinimalQuery['vehicleTypes'][number];

type CustomerVehicleTypesMultiSelectProps = {
  readonly data: VehicleTypesMinimalQuery | undefined;
  readonly control: Control<OrganizationFormValues>;
  readonly disabled: boolean;
  readonly selectedVehicleTypeUuids: string[] | undefined | null;
};
const CustomerVehicleTypesMultiSelect = ({
  data,
  control,
  disabled,
  selectedVehicleTypeUuids,
}: CustomerVehicleTypesMultiSelectProps) => {
  const vehicleTypeOptions = useMemo<MinimalVehicleType[]>(() => {
    if (isNil(data)) {
      return [];
    }
    const options = data.vehicleTypes.filter(
      (vt) => vt.status === VehicleTypeStatus.Active,
    );
    // Fill in any necessary archived vehicle types.
    if (!isNil(selectedVehicleTypeUuids)) {
      for (const uuid of selectedVehicleTypeUuids) {
        if (!options.some((vt) => vt.uuid === uuid)) {
          const vehicleType = data.vehicleTypes.find((vt) => vt.uuid === uuid);
          if (!isNil(vehicleType)) {
            vehicleTypeOptions.push(vehicleType);
          }
        }
      }
    }
    return options;
  }, [selectedVehicleTypeUuids, data]);

  return (
    <FormControl sx={{ flex: 1 }}>
      <Controller
        name="vehicleTypeUuids"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Select
            {...field}
            multiple
            value={field.value ?? []} // Ensure value is always an array
            size="medium"
            disabled={disabled}
            renderValue={(selected) => {
              if (isArray(selected)) {
                return (
                  <Box>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          vehicleTypeOptions.find((vt) => vt.uuid === value)
                            ?.name ?? 'Unknown vehicle type'
                        }
                      />
                    ))}
                  </Box>
                );
              }
              return null;
            }}
          >
            {data?.vehicleTypes?.map((vehicleType) => (
              <MenuItem key={vehicleType.uuid} value={vehicleType.uuid}>
                {sentenceCase(vehicleType.name)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default CustomerVehicleTypesMultiSelect;
