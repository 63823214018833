import {
  type Control,
  Controller,
  type FieldName,
  type FieldPath,
} from 'react-hook-form';
import { type OrganizationFormValues } from '../form/types';
import { type TariffChainFragment } from '../../../generated/graphql';
import { MenuItem, TextField } from '@mui/material';
import { OrganizationPageMode } from '../enums';
import { isNil } from 'lodash';

const TariffChainSelect = ({
  label,
  name,
  tariffChains,
  mode,
  control,
}: {
  readonly label: string;
  readonly name: FieldPath<OrganizationFormValues>;
  readonly tariffChains: TariffChainFragment[];
  readonly mode: OrganizationPageMode;
  readonly control: Control<OrganizationFormValues>;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextField
          fullWidth
          select
          size="medium"
          disabled={mode === OrganizationPageMode.VIEW}
          label={label}
          value={value ?? ''}
          helperText={error?.message}
          onChange={(e) => {
            onChange(e.target.value === '' ? null : e.target.value);
          }}
        >
          {tariffChains?.map((tariffChain) => (
            <MenuItem key={tariffChain.id} value={tariffChain.id}>
              {tariffChain.name}
            </MenuItem>
          ))}
          <MenuItem value="">None</MenuItem>
        </TextField>
      )}
    />
  );
};

export default TariffChainSelect;
