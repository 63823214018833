import { type AgGridReact } from 'ag-grid-react';
import dayjs, { type Dayjs } from 'dayjs';
import { invert, isEmpty, isNil, mapValues, sortBy } from 'lodash';
import { type RefObject } from 'react';
import { filterNotNil } from 'shared/array';
import { getNoonOfDay } from 'shared/date';
import { exhaustive } from 'shared/switch';
import {
  type DateFilterStartEnd,
  DispatchTableField,
  type FindStopsFiltersInput,
  NullsOrder,
  PickupOrDelivery,
  type SortOrder,
} from '../../../generated/graphql';
import { type StopsTableElement } from '../../dispatch/types/stops';
import {
  booleanFilterOptionToBooleanFilter,
  booleanFilterOptionToBooleanFilterV2,
  convertDateFilterOptionToDateFilters,
  type DateFilterOption,
  type FilterModel,
  type MultiSelectFilterValue,
} from '../../orders/components/enums/order-filters';
import { type ColumnSortState, getSortOrder } from '../utils';
import { StopFilterField } from './constants';
import {
  type DispatchDateFilterOption,
  DispatchFilterType,
  DispatchRelativeDateFilterOption,
  type DispatchSortV2,
  type DispatchTableFilterModel,
} from './types';

const STOP_FILTER_FIELD_ID_TO_DISPLAY_NAME = mapValues(
  StopFilterField,
) as Record<StopFilterField, string>;

const STOP_FILTER_FIELD_DISPLAY_NAME_TO_ID = invert(StopFilterField) as Record<
  string,
  StopFilterField
>;

/** If a filter model uses display name keys, it's outdated */
export const isLegacyFilterModel = (filterModel: FilterModel): boolean => {
  return Object.keys(filterModel).some(
    (key) => key in STOP_FILTER_FIELD_DISPLAY_NAME_TO_ID,
  );
};

export const migrateLegacyFilterModel = (
  filterModel: FilterModel,
): DispatchTableFilterModel => {
  const dispatchTableFilterModel: Record<keyof typeof StopFilterField, any> =
    Object.fromEntries(
      Object.entries(filterModel ?? {}).map(([displayName, value]) => [
        STOP_FILTER_FIELD_DISPLAY_NAME_TO_ID[displayName],
        value,
      ]),
    );
  return dispatchTableFilterModel;
};

/**
 * Convert an AG Grid filter model to a DispatchTableFilterModel to save
 * in local storage or the DB
 *
 * @see DispatchTableFilterModel
 */
export const getDispatchTableFilterModel = (
  gridRef: AgGridReact<StopsTableElement> | null,
): DispatchTableFilterModel => {
  return migrateLegacyFilterModel(gridRef?.api.getFilterModel() ?? {});
};

/**
 * Convert a DispatchTableFilterModel to an AG Grid filter model
 * that can be directly applied to an AG Grid instance
 *
 * @see DispatchTableFilterModel
 */
export const getAGGridFilterModel = (
  dispatchTableFilterModel: DispatchTableFilterModel,
): FilterModel => {
  return Object.fromEntries(
    Object.entries(dispatchTableFilterModel).map(([columnId, value]) => [
      STOP_FILTER_FIELD_ID_TO_DISPLAY_NAME[columnId as StopFilterField],
      value,
    ]),
  );
};

const buildDispatchSortV2 = ({
  fieldName,
  direction,
  nulls,
}: {
  fieldName: DispatchTableField;
  direction: SortOrder;
  nulls: NullsOrder;
}): DispatchSortV2 | undefined => {
  const baseSortInput = { direction, nulls };
  switch (fieldName) {
    case DispatchTableField.StopType: {
      return {
        fieldName: DispatchTableField.StopType,
        stopTypeSortInput: baseSortInput,
      };
    }
    case DispatchTableField.InboundMethod: {
      return {
        fieldName: DispatchTableField.InboundMethod,
        inboundMethodSortInput: baseSortInput,
      };
    }
    case DispatchTableField.OutboundMethod: {
      return {
        fieldName: DispatchTableField.OutboundMethod,
        outboundMethodSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Mawb: {
      return {
        fieldName: DispatchTableField.Mawb,
        mawbSortInput: baseSortInput,
      };
    }
    case DispatchTableField.City: {
      return {
        fieldName: DispatchTableField.City,
        citySortInput: baseSortInput,
      };
    }
    case DispatchTableField.ZipCode: {
      return {
        fieldName: DispatchTableField.ZipCode,
        zipCodeSortInput: baseSortInput,
      };
    }
    case DispatchTableField.DueDate: {
      return {
        fieldName: DispatchTableField.DueDate,
        dueDateSortInput: baseSortInput,
      };
    }
    case DispatchTableField.ServiceDate: {
      return {
        fieldName: DispatchTableField.ServiceDate,
        serviceDateSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Address: {
      return {
        fieldName: DispatchTableField.Address,
        addressSortInput: baseSortInput,
      };
    }
    case DispatchTableField.AddressType: {
      return {
        fieldName: DispatchTableField.AddressType,
        addressTypeSortInput: baseSortInput,
      };
    }
    case DispatchTableField.State: {
      return {
        fieldName: DispatchTableField.State,
        stateSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Appointment: {
      return {
        fieldName: DispatchTableField.Appointment,
        appointmentSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Consignee: {
      return {
        fieldName: DispatchTableField.Consignee,
        consigneeSortInput: baseSortInput,
      };
    }
    case DispatchTableField.AppointmentScheduled: {
      return {
        fieldName: DispatchTableField.AppointmentScheduled,
        appointmentScheduledSortInput: baseSortInput,
      };
    }
    case DispatchTableField.AppointmentRequired: {
      return {
        fieldName: DispatchTableField.AppointmentRequired,
        appointmentRequiredSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Customer: {
      return {
        fieldName: DispatchTableField.Customer,
        customerSortInput: baseSortInput,
      };
    }
    case DispatchTableField.ServiceLevel: {
      return {
        fieldName: DispatchTableField.ServiceLevel,
        serviceLevelSortInput: baseSortInput,
      };
    }
    case DispatchTableField.VehicleTypeName: {
      return {
        fieldName: DispatchTableField.VehicleTypeName,
        vehicleTypeNameSortInput: baseSortInput,
      };
    }
    case DispatchTableField.BusinessDivision: {
      return {
        fieldName: DispatchTableField.BusinessDivision,
        businessDivisionSortInput: baseSortInput,
      };
    }
    case DispatchTableField.SpecialInstructions: {
      return {
        fieldName: DispatchTableField.SpecialInstructions,
        specialInstructionsSortInput: baseSortInput,
      };
    }
    case DispatchTableField.RouteName: {
      return {
        fieldName: DispatchTableField.RouteName,
        routeNameSortInput: baseSortInput,
      };
    }
    case DispatchTableField.Terminal: {
      return {
        fieldName: DispatchTableField.Terminal,
        terminalSortInput: baseSortInput,
      };
    }
    case DispatchTableField.InboundName: {
      return {
        fieldName: DispatchTableField.InboundName,
        inboundNameSortInput: baseSortInput,
      };
    }
    case DispatchTableField.OutboundName: {
      return {
        fieldName: DispatchTableField.OutboundName,
        outboundNameSortInput: baseSortInput,
      };
    }
    case DispatchTableField.InboundZip: {
      return {
        fieldName: DispatchTableField.InboundZip,
        inboundZipSortInput: baseSortInput,
      };
    }
    case DispatchTableField.OutboundZip: {
      return {
        fieldName: DispatchTableField.OutboundZip,
        outboundZipSortInput: baseSortInput,
      };
    }
    case DispatchTableField.InboundCity: {
      return {
        fieldName: DispatchTableField.InboundCity,
        inboundCitySortInput: baseSortInput,
      };
    }
    case DispatchTableField.OutboundCity: {
      return {
        fieldName: DispatchTableField.OutboundCity,
        outboundCitySortInput: baseSortInput,
      };
    }
    // These fields aren't sortable
    case DispatchTableField.Eta:
    case DispatchTableField.OrderName:
    case DispatchTableField.OrderNumber:
    case DispatchTableField.OrderStatus:
    case DispatchTableField.RefNumber:
    case DispatchTableField.PrimaryConsignee:
    case DispatchTableField.InboundTerminal:
    case DispatchTableField.OutboundTerminal:
    case DispatchTableField.Special:
    case DispatchTableField.ServiceTime:
    case DispatchTableField.OrderNotes:
    case DispatchTableField.PieceCount:
    case DispatchTableField.Dims:
    case DispatchTableField.Weight:
    case DispatchTableField.TotalCharges:
    case DispatchTableField.Tags:
    case DispatchTableField.InboundPaperwork:
    case DispatchTableField.OutboundPaperwork:
    case DispatchTableField.AppointmentDate:
    case DispatchTableField.AppointmentTime: {
      return undefined;
    }
    default: {
      return exhaustive(fieldName);
    }
  }
};

/** Convert AG Grid column sort state into DispatchSortV2[] */
export const getDispatchSortV2 = (
  gridRef: AgGridReact | null,
): DispatchSortV2[] | undefined => {
  if (isNil(gridRef)) {
    return undefined;
  }

  const sortState = gridRef.columnApi
    .getColumnState()
    .filter((s): s is ColumnSortState<DispatchTableField> => !isNil(s.sort))
    .map((s) => ({
      colId: s.colId,
      sort: getSortOrder(s.sort),
      sortIndex: s.sortIndex,
    }));

  const sortModelBySortIndex = sortBy(sortState, 'sortIndex', 'asc');

  const sortModel = sortModelBySortIndex.map(({ colId, sort }) =>
    buildDispatchSortV2({
      fieldName: colId,
      direction: sort,
      // TODO: support null ordering
      nulls: NullsOrder.Last,
    }),
  );

  return filterNotNil(sortModel);
};

/**
 * Convert DispatchSortV2[] into AG Grid column sort state
 *
 * TODO: this should also handle null ordering
 */
export const getDispatchColumnSortStates = (
  dispatchSortV2: DispatchSortV2[],
): Array<ColumnSortState<DispatchTableField>> => {
  const sortModel = dispatchSortV2.map(({ fieldName, ...sortInput }, i) => {
    switch (fieldName) {
      case DispatchTableField.StopType: {
        if (isNil(sortInput.stopTypeSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.StopType,
          sort: sortInput.stopTypeSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.InboundMethod: {
        if (isNil(sortInput.inboundMethodSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.InboundMethod,
          sort: sortInput.inboundMethodSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.OutboundMethod: {
        if (isNil(sortInput.outboundMethodSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.OutboundMethod,
          sort: sortInput.outboundMethodSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Mawb: {
        if (isNil(sortInput.mawbSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Mawb,
          sort: sortInput.mawbSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.City: {
        if (isNil(sortInput.citySortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.City,
          sort: sortInput.citySortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.ZipCode: {
        if (isNil(sortInput.zipCodeSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.ZipCode,
          sort: sortInput.zipCodeSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.DueDate: {
        if (isNil(sortInput.dueDateSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.DueDate,
          sort: sortInput.dueDateSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.ServiceDate: {
        if (isNil(sortInput.serviceDateSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.ServiceDate,
          sort: sortInput.serviceDateSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Address: {
        if (isNil(sortInput.addressSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Address,
          sort: sortInput.addressSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.AddressType: {
        if (isNil(sortInput.addressTypeSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.AddressType,
          sort: sortInput.addressTypeSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.State: {
        if (isNil(sortInput.stateSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.State,
          sort: sortInput.stateSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Appointment: {
        if (isNil(sortInput.appointmentSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Appointment,
          sort: sortInput.appointmentSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Consignee: {
        if (isNil(sortInput.consigneeSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Consignee,
          sort: sortInput.consigneeSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.AppointmentScheduled: {
        if (isNil(sortInput.appointmentScheduledSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.AppointmentScheduled,
          sort: sortInput.appointmentScheduledSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.AppointmentRequired: {
        if (isNil(sortInput.appointmentRequiredSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.AppointmentRequired,
          sort: sortInput.appointmentRequiredSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Customer: {
        if (isNil(sortInput.customerSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Customer,
          sort: sortInput.customerSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.ServiceLevel: {
        if (isNil(sortInput.serviceLevelSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.ServiceLevel,
          sort: sortInput.serviceLevelSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.VehicleTypeName: {
        if (isNil(sortInput.vehicleTypeNameSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.VehicleTypeName,
          sort: sortInput.vehicleTypeNameSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.BusinessDivision: {
        if (isNil(sortInput.businessDivisionSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.BusinessDivision,
          sort: sortInput.businessDivisionSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.SpecialInstructions: {
        if (isNil(sortInput.specialInstructionsSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.SpecialInstructions,
          sort: sortInput.specialInstructionsSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.RouteName: {
        if (isNil(sortInput.routeNameSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.RouteName,
          sort: sortInput.routeNameSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.Terminal: {
        if (isNil(sortInput.terminalSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.Terminal,
          sort: sortInput.terminalSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.InboundName: {
        if (isNil(sortInput.inboundNameSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.InboundName,
          sort: sortInput.inboundNameSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.OutboundName: {
        if (isNil(sortInput.outboundNameSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.OutboundName,
          sort: sortInput.outboundNameSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.InboundZip: {
        if (isNil(sortInput.inboundZipSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.InboundZip,
          sort: sortInput.inboundZipSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.OutboundZip: {
        if (isNil(sortInput.outboundZipSortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.OutboundZip,
          sort: sortInput.outboundZipSortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.InboundCity: {
        if (isNil(sortInput.inboundCitySortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.InboundCity,
          sort: sortInput.inboundCitySortInput.direction,
          sortIndex: i,
        };
      }
      case DispatchTableField.OutboundCity: {
        if (isNil(sortInput.outboundCitySortInput)) {
          return;
        }
        return {
          colId: DispatchTableField.OutboundCity,
          sort: sortInput.outboundCitySortInput.direction,
          sortIndex: i,
        };
      }
      // These fields aren't sortable
      case DispatchTableField.Eta:
      case DispatchTableField.OrderName:
      case DispatchTableField.OrderNumber:
      case DispatchTableField.OrderStatus:
      case DispatchTableField.RefNumber:
      case DispatchTableField.PrimaryConsignee:
      case DispatchTableField.InboundTerminal:
      case DispatchTableField.OutboundTerminal:
      case DispatchTableField.ServiceTime:
      case DispatchTableField.Special:
      case DispatchTableField.OrderNotes:
      case DispatchTableField.PieceCount:
      case DispatchTableField.Dims:
      case DispatchTableField.Weight:
      case DispatchTableField.TotalCharges:
      case DispatchTableField.Tags:
      case DispatchTableField.InboundPaperwork:
      case DispatchTableField.OutboundPaperwork:
      case DispatchTableField.AppointmentDate:
      case DispatchTableField.AppointmentTime: {
        return;
      }
      default: {
        return exhaustive(fieldName);
      }
    }
  });
  return filterNotNil(sortModel);
};

export const convertDispatchDateFilterOptionToDateFilters = ({
  dispatchDateFilterOption,
  startDate,
  endDate,
  planningDate,
}: {
  dispatchDateFilterOption: DispatchDateFilterOption | DateFilterOption;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  planningDate: Dayjs | null | undefined;
}): DateFilterStartEnd | null => {
  if (typeof dispatchDateFilterOption === 'string') {
    return convertDateFilterOptionToDateFilters({
      dateFilterOption: dispatchDateFilterOption,
      startDate,
      endDate,
    });
  }
  switch (dispatchDateFilterOption.filterType) {
    case DispatchFilterType.FIXED: {
      return convertDateFilterOptionToDateFilters({
        dateFilterOption: dispatchDateFilterOption.fixedDateOption,
        startDate,
        endDate,
      });
    }
    case DispatchFilterType.RELATIVE: {
      switch (dispatchDateFilterOption.relativeDateOption) {
        case DispatchRelativeDateFilterOption.PLANNING_DATE: {
          if (isNil(planningDate)) {
            return null;
          }
          return {
            startFilterValue: dayjs(planningDate).startOf('day').toDate(),
            endFilterValue: getNoonOfDay(planningDate),
          };
        }
        default: {
          return exhaustive(dispatchDateFilterOption);
        }
      }
    }
    default: {
      return exhaustive(dispatchDateFilterOption);
    }
  }
};

const isPickupOrDelivery = (value: unknown): value is PickupOrDelivery =>
  Object.values(PickupOrDelivery).includes(value as never);

export const addFilterFromFilterModelToResult = (
  currentResult: FindStopsFiltersInput,
  filterField: StopFilterField | DispatchTableField,

  filterModelValue: any,
  planningDateRef: RefObject<Dayjs | undefined> | undefined,
): FindStopsFiltersInput => {
  switch (filterField) {
    case StopFilterField.ORDER_NAME:
    case StopFilterField.ORDER_STATUS:
    case StopFilterField.CITY:
    case StopFilterField.ADDRESS:
    case DispatchTableField.Eta:
    case DispatchTableField.OrderNumber:
    case DispatchTableField.Mawb:
    case DispatchTableField.RefNumber:
    case DispatchTableField.OrderName:
    case DispatchTableField.OrderStatus:
    case DispatchTableField.OrderNotes:
    case DispatchTableField.PieceCount:
    case DispatchTableField.City:
    case DispatchTableField.Address:
    case DispatchTableField.AddressType:
    case DispatchTableField.Consignee:
    case DispatchTableField.PrimaryConsignee:
    case DispatchTableField.ZipCode:
    case DispatchTableField.ServiceDate:
    case DispatchTableField.Dims:
    case DispatchTableField.Weight:
    case DispatchTableField.SpecialInstructions:
    case DispatchTableField.ServiceTime:
    case DispatchTableField.Terminal:
    case DispatchTableField.TotalCharges:
    case StopFilterField.TAG:
    case StopFilterField.TRANSFER_PENDING:
    case DispatchTableField.State:
    case DispatchTableField.VehicleTypeName:
    case DispatchTableField.Tags:
    case DispatchTableField.InboundName:
    case DispatchTableField.OutboundName:
    case DispatchTableField.InboundMethod:
    case DispatchTableField.OutboundMethod:
    case DispatchTableField.InboundTerminal:
    case DispatchTableField.OutboundTerminal: // todo?
    case DispatchTableField.InboundZip:
    case DispatchTableField.OutboundZip:
    case DispatchTableField.InboundCity:
    case DispatchTableField.OutboundCity:
    case DispatchTableField.InboundPaperwork:
    case DispatchTableField.OutboundPaperwork:
    case DispatchTableField.AppointmentDate:
    case DispatchTableField.AppointmentTime: {
      return currentResult;
    }
    case StopFilterField.ADDRESS_TYPE: {
      return {
        addressType: filterModelValue.value,
        ...currentResult,
      };
    }
    case StopFilterField.HAS_APPOINTMENT: {
      return {
        ...currentResult,
        hasAppointment: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.HAS_APPOINTMENT_DATE: {
      return {
        ...currentResult,
        hasAppointmentDate: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case DispatchTableField.StopType:
    case StopFilterField.STOP_TYPE: {
      if (
        'values' in filterModelValue &&
        Array.isArray(filterModelValue.values) &&
        filterModelValue.values.length > 0 &&
        (filterModelValue.values as MultiSelectFilterValue[]).every(
          ({ actualValue }) => isPickupOrDelivery(actualValue),
        )
      ) {
        return {
          ...currentResult,
          pickupOrDeliveryTypes: (
            filterModelValue.values as MultiSelectFilterValue[]
          ).map(({ actualValue }) => actualValue as PickupOrDelivery),
        };
      }
      return {
        ...currentResult,
        pickupOrDeliveryTypes: isPickupOrDelivery(filterModelValue.value)
          ? [filterModelValue.value]
          : undefined,
      };
    }
    case StopFilterField.PREVIOUSLY_ATTEMPTED: {
      return {
        ...currentResult,
        previouslyAttempted: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.APPOINTMENT_REQUIRED:
    case DispatchTableField.AppointmentRequired: {
      return {
        ...currentResult,
        appointmentRequired: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.APPOINTMENT_SCHEDULED:
    case DispatchTableField.AppointmentScheduled: {
      return {
        ...currentResult,
        appointmentScheduled: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.HAS_APPOINTMENT_OPTIONS_PROPOSED: {
      return {
        ...currentResult,
        hasAppointmentOptionsProposed: booleanFilterOptionToBooleanFilter(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.ON_HAND: {
      return {
        ...currentResult,
        onHand: booleanFilterOptionToBooleanFilter(filterModelValue.value),
      };
    }
    case StopFilterField.IS_PICKED: {
      return {
        ...currentResult,
        isPicked: booleanFilterOptionToBooleanFilter(filterModelValue.value),
      };
    }
    case StopFilterField.IS_REFUSED: {
      return {
        ...currentResult,
        isRefusedFilter: booleanFilterOptionToBooleanFilterV2(
          filterModelValue.value,
        ),
      };
    }
    case StopFilterField.DEADLINE_DATE:
    case DispatchTableField.DueDate: {
      return {
        ...currentResult,
        deadlineDateFilters: convertDispatchDateFilterOptionToDateFilters({
          dispatchDateFilterOption: filterModelValue.value as
            | DispatchDateFilterOption
            | DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
          planningDate: planningDateRef?.current,
        }),
      };
    }
    case StopFilterField.CREATED_DATE: {
      return {
        ...currentResult,
        createdAtFilters: convertDispatchDateFilterOptionToDateFilters({
          dispatchDateFilterOption: filterModelValue.value as
            | DispatchDateFilterOption
            | DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
          planningDate: planningDateRef?.current,
        }),
      };
    }
    case StopFilterField.APPOINTMENT_DATE:
    case DispatchTableField.Appointment: {
      return {
        ...currentResult,
        appointmentTimeFilters: convertDispatchDateFilterOptionToDateFilters({
          dispatchDateFilterOption: filterModelValue.value as
            | DispatchDateFilterOption
            | DateFilterOption,
          startDate: filterModelValue.startDate as Date,
          endDate: filterModelValue.endDate as Date,
          planningDate: planningDateRef?.current,
        }),
      };
    }
    case StopFilterField.IS_SPECIAL:
    case DispatchTableField.Special: {
      return {
        ...currentResult,
        isSpecial: booleanFilterOptionToBooleanFilter(filterModelValue.value),
      };
    }
    case DispatchTableField.Customer:
    case StopFilterField.CUSTOMER: {
      return {
        ...currentResult,
        customerUuid: isEmpty(filterModelValue.value as string)
          ? undefined
          : (filterModelValue.value as string),
      };
    }
    case StopFilterField.ROUTE_NAME:
    case DispatchTableField.RouteName: {
      return {
        ...currentResult,
        routeNameUuid: isEmpty(filterModelValue.value as string)
          ? undefined
          : (filterModelValue.value as string),
      };
    }
    case StopFilterField.SERVICE_LEVEL:
    case DispatchTableField.ServiceLevel: {
      return {
        ...currentResult,
        serviceLevelUuids: isEmpty(filterModelValue.value)
          ? []
          : [filterModelValue.value],
      };
    }
    case StopFilterField.BUSINESS_DIVISION:
    case DispatchTableField.BusinessDivision: {
      return {
        ...currentResult,
        businessDivisionUuid: filterModelValue.value,
      };
    }
    case StopFilterField.CAN_DISPATCH: {
      return {
        ...currentResult,
        canDispatch: booleanFilterOptionToBooleanFilter(filterModelValue.value),
      };
    }
    default: {
      return exhaustive(filterField);
    }
  }
};
