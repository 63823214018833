import { isNil } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  type PropsWithChildren,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  MeasurementUnits,
  useServicesForThirdPartyUserQuery,
} from '../../../../../generated/graphql';
import { type StopMethod } from '../../../../orders/components/order-form/forms/stop-type';
import { type ThirdPartyUserCompany } from '../../../types';
import {
  type CustomerPortalOrderFormValues,
  type StopTypeWithoutNone,
} from '../forms/types';
import { getStopTypeFromServiceLevel } from '../forms/utils';

/**
 * This context provides read-only state for the customer portal order form.
 * For example, fields derived from the form state but that aren't themselves editable
 * (e.g. stop types).
 */
type CustomerPortalOrderFormContextValue = {
  /** UUID of the current company, derived from the form's selected contact */
  companyUuid: string;
  /** Whether terminals are enabled for the current company */
  terminalsEnabled: boolean;
  /** The default measurement units for the current company */
  measurementUnits: MeasurementUnits;
  /** Get the stop type for the given stop method, based on the current service level */
  getStopType: (stopMethod: StopMethod) => StopTypeWithoutNone | null;
};

const CustomerPortalOrderFormContext =
  createContext<CustomerPortalOrderFormContextValue | null>(null);

type CustomerPortalOrderFormContextProviderProps = PropsWithChildren<{
  readonly company: ThirdPartyUserCompany;
}>;

export const CustomerPortalOrderFormContextProvider = ({
  company,
  children,
}: CustomerPortalOrderFormContextProviderProps) => {
  const { control } = useFormContext<CustomerPortalOrderFormValues>();
  const serviceUuid = useWatch({ control, name: 'serviceId' });

  const { data } = useServicesForThirdPartyUserQuery({
    variables: {
      findServicesForThirdPartyUserInput: {
        companyUuid: company.uuid,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const selectedService = data?.servicesForThirdPartyUser?.find(
    (service) => service.uuid === serviceUuid,
  );

  const getStopType = useCallback(
    (stopMethod: StopMethod): StopTypeWithoutNone | null => {
      return getStopTypeFromServiceLevel(
        stopMethod,
        selectedService?.defaultInboundStopType ?? null,
        selectedService?.defaultOutboundStopType ?? null,
      );
    },
    [
      selectedService?.defaultInboundStopType,
      selectedService?.defaultOutboundStopType,
    ],
  );

  const value = useMemo(
    () => ({
      companyUuid: company.uuid,
      getStopType,
      terminalsEnabled: company?.configuration?.terminalsEnabled ?? false,
      measurementUnits:
        company?.defaultMeasurementUnits ?? MeasurementUnits.Inches,
    }),
    [company, getStopType],
  );

  return (
    <CustomerPortalOrderFormContext.Provider value={value}>
      {children}
    </CustomerPortalOrderFormContext.Provider>
  );
};

export const useCustomerPortalOrderFormContext = () => {
  const context = useContext(CustomerPortalOrderFormContext);
  if (isNil(context)) {
    throw new Error(
      'useCustomerPortalOrderFormContext must be used within a CustomerPortalOrderFormContextProvider',
    );
  }
  return context;
};
