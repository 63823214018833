import { isNil } from 'lodash';
import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  type FormattedOrderFragment,
  type OrderTableFieldItemizedChargesFragment,
  useOrderWithItemizedChargesLazyQuery,
} from '../../../generated/graphql';

/* 
    Uses zustand to cache itemized charges for an order instead of the built-in apollo cache because Currency scalars cannot currently be cached. Loom with some more info https://www.loom.com/share/3595ed31ef3a4efa938bcbb9852f1203
*/

type OrderItemizedChargesState = {
  cachedOrders: Record<string, OrderTableFieldItemizedChargesFragment>;
  setCachedOrder: (
    orderUuid: string,
    data: OrderTableFieldItemizedChargesFragment,
  ) => void;
};

const useOrderItemizedChargesStore = create(
  immer<OrderItemizedChargesState>((set) => ({
    cachedOrders: {},
    setCachedOrder: (orderUuid, data) => {
      set((state) => {
        state.cachedOrders[orderUuid] = data;
      });
    },
  })),
);

export const useOrderItemizedCharges = ({
  uuid: orderUuid,
  itemizedChargesField,
}: FormattedOrderFragment) => {
  const [fetchOrderWithItemizedCharges, { loading, error }] =
    useOrderWithItemizedChargesLazyQuery();
  const { cachedOrders, setCachedOrder } = useOrderItemizedChargesStore();

  useEffect(() => {
    const fetchOrder = async () => {
      // If itemizedCharges are passed in, cache them immediately and skip the network request
      if (!isNil(itemizedChargesField)) {
        setCachedOrder(orderUuid, itemizedChargesField);
        return;
      }

      // If itemized charges are already in cache, skip the network request
      if (cachedOrders[orderUuid]) {
        return;
      }

      // Fetch the itemized charges if they are not in cache and were not passed in
      const response = await fetchOrderWithItemizedCharges({
        variables: {
          uuid: orderUuid,
        },
      });

      const fetchedItemizedCharges =
        response.data?.getOrderTableFieldValues.formattedOrderConnection
          .edges[0]?.node.itemizedChargesField;

      if (!isNil(fetchedItemizedCharges)) {
        setCachedOrder(orderUuid, fetchedItemizedCharges);
      }
    };

    fetchOrder();
  }, [
    orderUuid,
    cachedOrders,
    fetchOrderWithItemizedCharges,
    setCachedOrder,
    itemizedChargesField,
  ]);

  return {
    loading,
    error,
    itemizedChargesField: cachedOrders[orderUuid] || itemizedChargesField,
  };
};
