import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from '@mui/material';
import { isNil } from 'lodash';
import { memo, useState } from 'react';
import {
  type DetailedLineHaulManifestFragment,
  LineHaulManifestStatus,
} from '../../../../generated/graphql';
import useLineHaulDispatchActions from '../../hooks/use-line-haul-dispatch-actions';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';
import DeleteTruckloadModal from '../delete-truckload-modal';

const OpenedManifestContextMenu = ({
  openedManifest,
}: {
  readonly openedManifest: DetailedLineHaulManifestFragment;
}) => {
  const [dropdownMenuRef, setDropdownMenuRef] = useState<null | HTMLElement>(
    null,
  );
  const dropdownMenuOpen = Boolean(dropdownMenuRef);

  const [showDeleteTruckloadModal, setShowDeleteTruckloadModal] =
    useState(false);

  const closeDropdownMenu = () => {
    setDropdownMenuRef(null);
  };

  const [planningDate, manifestsWithSameSegment] = useLineHaulDispatchStore(
    (state) => [
      state.planningDate,
      state.manifests?.filter(
        (m) =>
          m.startTerminal.uuid === openedManifest.startTerminal.uuid &&
          m.endTerminal.uuid === openedManifest.endTerminal.uuid,
      ),
    ],
  );

  const { removeAllOrdersFromManifest, departManifest, addTruckload } =
    useLineHaulDispatchActions();

  if (isNil(planningDate)) {
    return null;
  }

  return (
    <>
      <DeleteTruckloadModal
        open={showDeleteTruckloadModal}
        setOpen={setShowDeleteTruckloadModal}
        manifestUuids={[openedManifest.uuid]}
      />
      <Button
        color="info"
        onClick={(e) => {
          setDropdownMenuRef(e.currentTarget);
        }}
        endIcon={<ArrowDropDownIcon />}
        // disabled={!nextSegmentMatchesOpenedManifest}
      >
        Manage
      </Button>
      <Menu
        anchorEl={dropdownMenuRef}
        open={dropdownMenuOpen}
        onClose={closeDropdownMenu}
      >
        <MenuList dense sx={{ py: 0 }}>
          {openedManifest.status === LineHaulManifestStatus.Planning && (
            <MenuItem
              // disabled={locked}
              onClick={async () => {
                closeDropdownMenu();
                departManifest({
                  uuid: openedManifest.uuid,
                  refreshGridAfter: true,
                });
              }}
            >
              Mark as departed
            </MenuItem>
          )}
          <MenuItem
            // disabled={locked}
            onClick={async () => {
              closeDropdownMenu();
              addTruckload({
                manifest: openedManifest,
                departDate: planningDate,
                openNewLoad: true,
              });
            }}
          >
            Add truckload
          </MenuItem>

          {openedManifest.orderSegments.length > 0 && (
            <MenuItem
              // disabled={locked}
              onClick={async () => {
                closeDropdownMenu();
                removeAllOrdersFromManifest({
                  manifest: openedManifest,
                });
              }}
            >
              Remove all orders
            </MenuItem>
          )}

          <Divider />
          <Tooltip
            title="Cannot delete load. This is the only load for this segment"
            disableHoverListener={(manifestsWithSameSegment ?? []).length > 1}
          >
            <div>
              <MenuItem
                // disabled={locked}
                onClick={async () => {
                  setShowDeleteTruckloadModal(true);
                  closeDropdownMenu();
                  //   await calculateRouteEta(route.uuid);
                  //   await fetchRoute(route.uuid);
                }}
                sx={{ color: 'red' }}
                disabled={(manifestsWithSameSegment ?? []).length <= 1}
              >
                Delete truckload
              </MenuItem>
            </div>
          </Tooltip>
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(OpenedManifestContextMenu);
