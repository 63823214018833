import { MenuItem, TextField } from '@mui/material';
import { type TariffChainFragment } from '../../../../generated/graphql';

type TariffChainDropdownProps = {
  readonly label: string;
  readonly value?: string;
  readonly options: TariffChainFragment[];
  readonly onChange: (tariffChain: TariffChainFragment | null) => void;
  readonly canEdit: boolean;
  readonly loading: boolean;
};

export const TariffChainDropdown = ({
  label,
  value,
  options,
  onChange,
  canEdit,
  loading,
}: TariffChainDropdownProps) => (
  <TextField
    fullWidth
    select
    size="small"
    disabled={!canEdit || loading}
    label={label}
    value={value ?? null}
    onChange={(e) => {
      const tariffChain = options.find((tc) => tc.id === e.target.value);
      onChange(tariffChain ?? null);
    }}
  >
    {options.map((tariffChain) => (
      <MenuItem key={tariffChain.id} value={tariffChain.id}>
        {tariffChain.name}
      </MenuItem>
    ))}
    <MenuItem value="">None</MenuItem>
  </TextField>
);
