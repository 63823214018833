import { Box, Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import CustomerPortalServiceField from './customer-portal-service-field';
import { type CustomerPortalOrderFormValues } from './forms/types';

type CustomerPortalOrderDetailsProps = {
  readonly disabled?: boolean;
};

const CustomerPortalOrderDetails = ({
  disabled = false,
}: CustomerPortalOrderDetailsProps) => {
  const { control } = useFormContext<CustomerPortalOrderFormValues>();

  return (
    <Stack direction="row" gap={2} bgcolor="white" p={2}>
      <Controller
        name="hawb"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="HAWB"
            disabled={disabled}
            size="small"
            value={value ?? ''}
            error={!isNil(error)}
            helperText={error?.message}
            sx={{ flexBasis: '220px' }}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="mawb"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="MAWB"
            disabled={disabled}
            size="small"
            value={value ?? ''}
            error={!isNil(error)}
            helperText={error?.message}
            sx={{ flexBasis: '220px' }}
            onChange={onChange}
          />
        )}
      />
      <Box sx={{ flexBasis: '220px' }}>
        <CustomerPortalServiceField />
      </Box>
    </Stack>
  );
};

export default CustomerPortalOrderDetails;
