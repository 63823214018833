import { Box, Button, Stack, useTheme } from '@mui/material';
import { isEmpty, isNil, noop } from 'lodash';
import { useFormContext, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import useMeAsThirdPartyUser from '../../../../common/react-hooks/use-me-as-third-party-user';
import { useWarningOnExit } from '../../../../common/react-hooks/warning-on-exit';
import SaveButton from '../../../orders/components/order-form/components/save-button';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { StopMethod } from '../../../orders/components/order-form/forms/stop-type';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import OrderPageToolbar from '../../../orders/components/order-form/order-page-toolbar';
import { type ThirdPartyUserCompany } from '../../types';
import { CustomerPortalOrderFormContextProvider } from './contexts/customer-portal-order-form-context';
import CustomerPortalCompanyField from './customer-portal-company-field';
import CustomerPortalContactField from './customer-portal-contact-field';
import CustomerPortalOrderDetails from './customer-portal-order-details';
import CustomerPortalPackages from './customer-portal-packages';
import CustomerPortalStopDetails from './customer-portal-stop-details';
import { type CustomerPortalOrderFormValues } from './forms/types';

type CustomerPortalOrderFormProps = {
  readonly company: ThirdPartyUserCompany;
  readonly contactUuid: string;
} & (
  | {
      // Typed this way so that we don't have to come up with meaningful
      // values for setContactUuid and onSubmit when the form is disabled
      readonly disabled: true;
      readonly setContactUuid?: undefined;
      readonly onSubmit?: undefined;
    }
  | {
      readonly disabled?: false;
      readonly setContactUuid: (contactUuid: string) => void;
      readonly onSubmit: () => Promise<void>;
    }
);

const CustomerPortalOrderForm = ({
  company,
  contactUuid,
  setContactUuid,
  onSubmit,
  disabled = false,
}: CustomerPortalOrderFormProps) => {
  const { thirdPartyUser } = useMeAsThirdPartyUser();

  const { control } = useFormContext<CustomerPortalOrderFormValues>();
  const { isSubmitting, isSubmitSuccessful, dirtyFields } =
    useFormState<CustomerPortalOrderFormValues>({ control });

  // RHF sometimes sets isDirty when a field is `undefined` but not present in
  // the default values. This is closer to the behavior we want.
  // https://github.com/react-hook-form/react-hook-form/issues/4740
  const isDirty = !isEmpty(dirtyFields);

  const navigate = useNavigate();

  const setCompany = (companyUuid: string) => {
    const firstContactForNewCompany = thirdPartyUser?.contacts.find(
      (c) => c.company.uuid === companyUuid,
    );
    if (isNil(firstContactForNewCompany)) {
      return;
    }
    setContactUuid?.(firstContactForNewCompany.uuid);
  };

  const theme = useTheme();

  useWarningOnExit(
    !disabled && isDirty && !isSubmitting && !isSubmitSuccessful,
    'Are you sure you want to leave? This order has unsaved changes.',
  );

  return (
    <CustomerPortalOrderFormContextProvider company={company}>
      <OrderFormEditAccessProvider
        value={disabled ? OrderFormEditAccess.None : OrderFormEditAccess.All}
      >
        <Stack height="100%">
          <OrderPageToolbar>
            <Stack
              flexGrow={1}
              direction="row"
              gap={2}
              alignItems="center"
              flexWrap="wrap"
            >
              <Button
                variant="text"
                onClick={() => {
                  navigate('/customer-portal/orders');
                }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1, maxWidth: '380px' }}>
                <CustomerPortalCompanyField
                  required
                  companyUuid={company.uuid}
                  label="Carrier"
                  onChange={setCompany}
                />
              </Box>
              {!isNil(company) && (
                <CustomerPortalContactField
                  contactUuid={contactUuid ?? null}
                  onChange={setContactUuid ?? noop}
                />
              )}
            </Stack>
            {!isNil(onSubmit) && (
              <SaveButton
                isEditMode={false}
                saveAndExitCopy="Submit order"
                onSubmit={onSubmit}
              />
            )}
          </OrderPageToolbar>
          <Stack height="100%" minHeight={0} direction="row">
            <GappedStackCard
              style={{
                flexBasis: '67%',
                flexShrink: 0,
                borderRight: `1px solid ${theme.palette.borderColor.main}`,
                overflowY: 'auto',
              }}
            >
              <CustomerPortalOrderDetails disabled={disabled} />
              <Stack flexDirection="row" flexWrap="wrap" gap="1px">
                <CustomerPortalStopDetails stopMethod={StopMethod.Inbound} />
                <CustomerPortalStopDetails stopMethod={StopMethod.Outbound} />
              </Stack>
              <CustomerPortalPackages disabled={disabled} />
            </GappedStackCard>
          </Stack>
        </Stack>
      </OrderFormEditAccessProvider>
    </CustomerPortalOrderFormContextProvider>
  );
};

export default CustomerPortalOrderForm;
