import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { ServiceLevelAutocomplete } from '../../../../../../../common/components/service-level-autocomplete';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import { type ShallowContactFragment } from '../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { type OrderFormFieldValues } from '../../../forms/types';
import { useShouldRateOrder } from '../../../hooks/use-should-rate-order';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import { FeatureFlag } from '../../../../../../../common/feature-flags';

const ServiceAutocomplete = ({
  contact,
  label,
  deriveLaneFromTerminals,
  isEditMode,
  isMinimized = false,
}: {
  readonly contact: ShallowContactFragment | undefined;
  readonly label?: string;
  readonly deriveLaneFromTerminals: () => void;
  readonly isEditMode: boolean;
  readonly isMinimized?: boolean;
}) => {
  const { control, setValue, clearErrors } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });
  const { companyConfiguration } = useMe();
  const { services } = useServices();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { shouldRateOrder } = useShouldRateOrder({ detailedStatus });

  const serviceUuid = useWatch({ control, name: `serviceUuid` });
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  useEffect(() => {
    if (!isNil(serviceUuid) && shouldRateOrder && !isEditMode) {
      const service = services.find((s) => s.uuid === serviceUuid);
      if (!isNil(service)) {
        if (service.defaultIsLineHaul) {
          if (ffLineHaulNetworks) {
            setValue('enableLineHaul', true);
          } else {
            setValue('isUsingLineHaul', true);
            deriveLaneFromTerminals();
          }
        }
        if (
          !isNil(service.defaultFulfillmentType) &&
          companyConfiguration?.fulfillmentTypeEnabled === true
        ) {
          setValue('fulfillmentType', service.defaultFulfillmentType);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceUuid, ffLineHaulNetworks]);

  useEffect(() => {
    if (!isNil(contact?.defaultService) && isNil(serviceUuid)) {
      setValue('serviceUuid', contact?.defaultService.uuid);
    }
  }, [contact?.defaultService, serviceUuid, setValue]);

  return (
    <ServiceLevelAutocomplete
      required
      label={label}
      contact={contact}
      contactUuid={undefined}
      isMinimized={isMinimized}
      selectedServiceUuid={serviceUuid ?? null}
      disabled={disabledIfFinalizedOrLater}
      error={!isNil(errors.serviceUuid)}
      onChange={(service: ShallowContactFragment['services'][0] | null) => {
        clearErrors();
        setValue('serviceUuid', service?.uuid, { shouldTouch: true });
      }}
    />
  );
};

export default React.memo(ServiceAutocomplete);
