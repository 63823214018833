import { type QueryHookOptions } from '@apollo/client';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import {
  type GetOrderTableFieldValuesInput,
  type GetOrderTableFieldValuesQuery,
  type GetOrderTableFieldValuesQueryVariables,
  OrderSource,
  OrderTableField,
  useGetOrderTableFieldValuesQuery,
} from '../../../../../../generated/graphql';
import { useCallback, useMemo } from 'react';

const CUSTOMER_PORTAL_ORDERS_FOR_REVIEW_TABLE_FIELDS = [
  OrderTableField.Name,
  OrderTableField.BillOfLadingNumber,
  OrderTableField.CustomerName,
  OrderTableField.CreatedAt,
];

const CUSTOMER_PORTAL_ORDERS_FOR_REVIEW_FILTERS = {
  isDraftOrderFilter: {
    eq: true,
  },
  orderSourceFilter: {
    eq: OrderSource.CustomerPortal,
  },
} satisfies GetOrderTableFieldValuesInput['filters'];

type PaginationVariables = Pick<
  GetOrderTableFieldValuesInput,
  'first' | 'last' | 'after' | 'before'
>;

/**
 * Wraps the getOrderTableFieldValues query with a fixed set of filters and
 * fields to fetch customer portal orders for review.
 */
export const useCustomerPortalOrdersForReview = ({
  first,
  last,
  after,
  before,
  ...queryHookOptions
}: PaginationVariables &
  QueryHookOptions<
    GetOrderTableFieldValuesQuery,
    GetOrderTableFieldValuesQueryVariables
  >) => {
  const enableNewTableFunctions = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_TABLE_FUNCTIONS,
  );

  const initialVariables: GetOrderTableFieldValuesInput = useMemo(
    () => ({
      ...(enableNewTableFunctions
        ? { filters: CUSTOMER_PORTAL_ORDERS_FOR_REVIEW_FILTERS }
        : CUSTOMER_PORTAL_ORDERS_FOR_REVIEW_FILTERS),
      orderTableFields: CUSTOMER_PORTAL_ORDERS_FOR_REVIEW_TABLE_FIELDS,
      totalCount: true,
    }),
    [enableNewTableFunctions],
  );

  const { data, loading, error, refetch } = useGetOrderTableFieldValuesQuery({
    variables: {
      getOrderTableFieldValuesInput: {
        ...initialVariables,
        // Note that we don't want to reuse these when refetching,
        // otherwise you might pass `first` initially and want to use `last`
        // when refetching (and that would include both!)
        first,
        last,
        after,
        before,
      },
    },
    ...queryHookOptions,
  });

  const refetchWithInitialVariables = useCallback(
    async (
      variables: Omit<
        GetOrderTableFieldValuesInput,
        'orderTableFields' | 'filters' | 'totalCount'
      >,
    ) =>
      refetch({
        getOrderTableFieldValuesInput: {
          ...initialVariables,
          ...variables,
        },
      }),
    [initialVariables, refetch],
  );

  return {
    ...data?.getOrderTableFieldValues.formattedOrderConnection,
    loading,
    error,
    refetch: refetchWithInitialVariables,
  };
};
