import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { get, isEmpty, isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { getSmartyAddressFromFrontend } from 'shared/smarty-api';
import { v4 } from 'uuid';
import AddressAutocompleteForm from '../../../../../../common/components/address-autocomplete-form';
import { ADDRESS_REQUIRED_FIELDS } from '../../../../../../common/constants';
import useMe from '../../../../../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../../../../../common/utils/utils';
import {
  FreightBillingMethod,
  StandardStopType,
  useCoordinatesForAddressLazyQuery,
} from '../../../../../../generated/graphql';
import { type AddressFormField } from '../../../../../addresses/redux/addresses-values-slice';
import {
  type AddressValues,
  type ContactPersonValues,
  type OrderFormFieldValues,
} from '../../forms/types';
import {
  AddressResolverWarningAndModal,
  ViewAddressInMapLinkAndModal,
} from './address-resolver-modals';
import EditAddressDialog from './edit-address-dialog';

type AddressProps = {
  readonly index: number;
  readonly disabled?: boolean;
  readonly forceEditMode?: boolean;
  readonly isTransferAddress?: boolean;
  readonly isPartnerCarrierAddress?: boolean;
};

const Address = ({
  index,
  disabled,
  forceEditMode,
  isTransferAddress,
  isPartnerCarrierAddress,
}: AddressProps) => {
  const { setValue, clearErrors, getValues, control } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });
  const theme = useTheme();
  const { useAllCaps } = useMe();

  const [shouldRefreshAddresses, setShouldRefreshAddresses] = useState(false);
  const [showEditAddressDialog, setShowEditAddressDialog] = useState(false);
  const contactPerson = useWatch({
    control,
    name: `stops.${index}.contactPerson`,
  });
  const specialInstructions = useWatch({
    control,
    name: `stops.${index}.specialInstructions`,
  });
  const standardStopType = useWatch({
    control,
    name: `stops.${index}.standardStopType`,
  });
  const addressString =
    isTransferAddress === true ? 'transferAddress' : 'address';
  const address = useWatch({
    control,
    name: `stops.${index}.${addressString}`,
  });
  const addressError = errors.stops?.[index]?.[addressString];
  let showAddressError = !isNil(addressError);
  if (isTransferAddress !== true) {
    for (const field of ADDRESS_REQUIRED_FIELDS) {
      if (!isNil(get(addressError, `${field}.message`))) {
        showAddressError = true;
        break;
      }
    }
  }
  // To-do: Implement address is resolved.
  const [isLoadingAddressResolveMessage, setIsLoadingAddressResolveMessage] =
    useState<boolean>(false);
  const [isAddressResolved, setIsAddressResolved] = useState<boolean | null>(
    isNil(address)
      ? null
      : !isNil(address.latitude) && !isNil(address.longitude),
  );
  const [
    shouldShowSuccessfulAddressUpdate,
    setShouldShowSuccessfulAddressUpdate,
  ] = useState(false);
  const [addressFormValueChangeHistory, setAddressFormValueChangeHistory] =
    useState<Array<{ isAutofill: boolean; addressValues: AddressValues }>>([]);

  const resetMiles = () => {
    if (
      getValues(`stops.${index}.freightCharge.billingMethod`) ===
      FreightBillingMethod.Tariff
    ) {
      setValue(`stops.${index}.miles`, null);
    }
  };

  const setNewAddress = (newAddress: AddressFormField) => {
    setValue(`stops.${index}.${addressString}`, {
      ...newAddress,
      name: newAddress.name ?? '',
      city: newAddress.city ?? '',
      line1: newAddress.line1 ?? '',
      state: newAddress.state ?? '',
      zip: newAddress.zip ?? '',
    });

    resetMiles();
  };

  const setNewContact = (newContact: ContactPersonValues) => {
    if (!isNil(newContact)) {
      setValue(`stops.${index}.contactPerson`, newContact);
    }
  };

  const getResidentialOrCommercial = useCallback(
    async (newAddress?: AddressValues) => {
      if (
        !isNil(newAddress) &&
        !isNilOrEmptyString(newAddress.line1) &&
        !isNilOrEmptyString(newAddress.city)
      ) {
        const apiResponse = await getSmartyAddressFromFrontend({
          line1: newAddress?.line1 ?? '',
          city: newAddress?.city ?? '',
          state: newAddress?.state ?? '',
          country: newAddress?.country ?? 'USA',
          zip: newAddress?.zip ?? '',
        });

        const rdi = apiResponse?.[0]?.metadata?.rdi;

        if (rdi === 'Commercial') {
          setValue(
            `stops.${index}.standardStopType`,
            StandardStopType.Commercial,
          );
        } else if (rdi === 'Residential') {
          setValue(
            `stops.${index}.standardStopType`,
            StandardStopType.Residential,
          );
        }
      }
    },
    [index, setValue],
  );

  const handleAddressChange = async (
    isAutofillChange: boolean,
    newAddress?: AddressFormField,
  ) => {
    if (isNil(newAddress)) {
      const contactToSet = null;

      const addressToSet: AddressValues | null =
        isTransferAddress === true
          ? null
          : {
              uuid: v4(),
              city: '',
              name: '',
              country: '',
              line1: '',
              state: '',
              zip: '',
            };
      setValue(`stops.${index}.contactPerson`, contactToSet);
      setValue(`stops.${index}.${addressString}`, addressToSet);
      resetMiles();
      setValue(`stops.${index}.specialInstructions`, '');
      clearErrors();
    } else {
      let addressToSet: AddressValues = {
        ...newAddress,
        uuid: isEmpty(newAddress.uuid) ? v4() : newAddress.uuid,
        city: newAddress.city ?? '',
        name: newAddress.name ?? '',
        country: newAddress.country ?? '',
        line1: newAddress.line1 ?? '',
        state: newAddress.state ?? '',
        zip: newAddress.zip ?? '',
      };
      if (useAllCaps) {
        addressToSet = {
          ...newAddress,
          uuid: isEmpty(newAddress.uuid) ? v4() : newAddress.uuid,
          city: newAddress.city?.toUpperCase() ?? '',
          name: newAddress.name?.toUpperCase() ?? '',
          country: newAddress.country?.toUpperCase() ?? '',
          line1: newAddress.line1?.toUpperCase() ?? '',
          state: newAddress.state?.toUpperCase() ?? '',
          zip: newAddress.zip?.toUpperCase() ?? '',
        };
      }
      // this is a trick to not call API on blur if we already called it when the field was autofilled.
      // populate this state which is a log of all the times handleAddressChange was called
      // when autofill it's called once when the value is populated and again on blur but ideally we should only call
      // the API the first time.
      setAddressFormValueChangeHistory([
        ...addressFormValueChangeHistory,
        { isAutofill: isAutofillChange, addressValues: addressToSet },
      ]);
      setValue(`stops.${index}.${addressString}`, addressToSet);

      if (
        isNilOrEmptyString(contactPerson?.firstName) &&
        isNilOrEmptyString(contactPerson?.lastName) &&
        isNilOrEmptyString(contactPerson?.email) &&
        isNilOrEmptyString(contactPerson?.phone)
      ) {
        const contactToSet: ContactPersonValues | null = isNil(
          newAddress.associatedContact,
        )
          ? null
          : {
              firstName: newAddress.associatedContact.firstName,
              lastName: newAddress.associatedContact.lastName,
              email: newAddress.associatedContact.email,
              phone: newAddress.associatedContact.phone,
              uuid: newAddress.associatedContact.uuid,
            };
        setValue(`stops.${index}.contactPerson`, contactToSet);
      }
      resetMiles();
      if (
        !isNilOrEmptyString(newAddress.specialInstructions) &&
        isNilOrEmptyString(specialInstructions)
      ) {
        setValue(
          `stops.${index}.specialInstructions`,
          newAddress.specialInstructions,
        );
      }
      clearErrors();
    }
  };

  const [getCoordinatesForAddress] = useCoordinatesForAddressLazyQuery();

  const interpretGeocodingApiResponseAndUpdateAddress = useCallback(
    async (newAddress?: AddressValues) => {
      if (
        isNil(newAddress) ||
        isNilOrEmptyString(newAddress.line1) ||
        isNilOrEmptyString(newAddress.city) ||
        isNilOrEmptyString(newAddress.zip) ||
        isNilOrEmptyString(newAddress.state)
      ) {
        setIsAddressResolved(null);
        return;
      }
      setIsLoadingAddressResolveMessage(true);
      const coordinatesRes = await getCoordinatesForAddress({
        variables: {
          line1: newAddress.line1,
          line2: newAddress.line2,
          city: newAddress.city,
          state: newAddress.state,
          zip: newAddress.zip,
          country: newAddress.country,
        },
      });
      const latitude = coordinatesRes.data?.coordinatesForAddress.latitude;
      const longitude = coordinatesRes.data?.coordinatesForAddress.longitude;
      setValue(`stops.${index}.${addressString}.latitude`, latitude);
      setValue(`stops.${index}.${addressString}.longitude`, longitude);
      const addressResolved = !isNil(latitude) && !isNil(longitude);
      setValue(
        `stops.${index}.${addressString}.preventCoordRecompute`,
        addressResolved,
      );
      setIsAddressResolved(addressResolved);
      setIsLoadingAddressResolveMessage(false);
    },
    [getCoordinatesForAddress, index, setValue, addressString],
  );

  useEffect(() => {
    const len = addressFormValueChangeHistory.length;
    if (len === 0) {
      return;
    }
    const first = addressFormValueChangeHistory[0];
    if (len === 1 && !isNil(first)) {
      interpretGeocodingApiResponseAndUpdateAddress(first.addressValues);
      getResidentialOrCommercial(first.addressValues);
    }
    if (len > 1) {
      const secondToLast = addressFormValueChangeHistory[len - 2];
      const last = addressFormValueChangeHistory[len - 1];
      // this handles the edge case where you autofill, and then you change the value in a field
      // before blurring, you would need to call the API again on blur.
      // only reset the history when the last change was from a blur.
      if (
        secondToLast?.addressValues?.city !== last?.addressValues?.city ||
        secondToLast?.addressValues?.state !== last?.addressValues?.state ||
        secondToLast?.addressValues?.line1 !== last?.addressValues?.line1 ||
        secondToLast?.addressValues?.zip !== last?.addressValues?.zip
      ) {
        interpretGeocodingApiResponseAndUpdateAddress(last?.addressValues);
        getResidentialOrCommercial(last?.addressValues);
        if (last?.isAutofill === false) {
          setAddressFormValueChangeHistory([]);
        }
      }
    }
  }, [
    addressFormValueChangeHistory,
    addressFormValueChangeHistory.length,
    getResidentialOrCommercial,
    interpretGeocodingApiResponseAndUpdateAddress,
  ]);
  const stopType = useWatch({ control, name: `stops.${index}.stopType` });

  return (
    <>
      {!isNil(address) && showEditAddressDialog && (
        <EditAddressDialog
          open={showEditAddressDialog}
          setOpen={setShowEditAddressDialog}
          address={{ ...address, isLocal: false }}
          contactPerson={contactPerson ?? undefined}
          specialInstructions={specialInstructions ?? ''}
          setShouldRefreshAddresses={setShouldRefreshAddresses}
          setNewAddress={setNewAddress}
          setNewContact={setNewContact}
          setSpecialInstructions={(newSpecialInstructions: string) => {
            setValue(
              `stops.${index}.specialInstructions`,
              newSpecialInstructions,
            );
          }}
          internalNotes={address.internalNotes ?? ''}
        />
      )}
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <FormLabel>Address</FormLabel>
          <Button
            size="small"
            disabled={disabled}
            onClick={() => {
              setShowEditAddressDialog(true);
            }}
          >
            Edit
          </Button>
        </Stack>
      </Grid>
      <AddressAutocompleteForm
        newStyling
        disabled={disabled}
        shouldRefreshAddresses={shouldRefreshAddresses}
        currentAddress={
          isNil(address) ? undefined : { ...address, isLocal: false }
        }
        handleChange={handleAddressChange}
        width="500px"
        showError={showAddressError}
        useAllCaps={useAllCaps}
        forceEditMode={forceEditMode}
        stopIdx={index}
        optional={
          isTransferAddress === true || isPartnerCarrierAddress === true
        }
        stopType={stopType}
      />
      {isLoadingAddressResolveMessage && <CircularProgress size={20} />}
      {!isLoadingAddressResolveMessage &&
        !isNil(isAddressResolved) &&
        (isAddressResolved ? (
          <ViewAddressInMapLinkAndModal
            idx={index}
            disabled={disabled}
            resolveAddress={() => {
              setIsAddressResolved(true);
            }}
            showSuccessAlert={() => {
              setShouldShowSuccessfulAddressUpdate(true);
            }}
          />
        ) : (
          <Box sx={{ mt: 1 }}>
            <AddressResolverWarningAndModal
              idx={index}
              disabled={disabled}
              resolveAddress={() => {
                setIsAddressResolved(true);
              }}
              showSuccessAlert={() => {
                setShouldShowSuccessfulAddressUpdate(true);
              }}
            />
          </Box>
        ))}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={shouldShowSuccessfulAddressUpdate}
        onClose={() => {
          setShouldShowSuccessfulAddressUpdate(false);
        }}
      >
        <Alert> Address updated </Alert>
      </Snackbar>
      <FormHelperText sx={{ color: '#D32F2F' }}>
        {addressError?.message?.toString()}
      </FormHelperText>
      {showAddressError && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          Missing address fields
        </FormHelperText>
      )}
      <Grid
        item
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {!isNilOrEmptyString(address?.internalNotes) && (
          <Tooltip title={address?.internalNotes ?? 'None'}>
            <Button
              sx={{
                whiteSpace: 'nowrap',
                textAlign: 'center',
                color: theme.palette.grey[600],
              }}
            >
              Location notes
            </Button>
          </Tooltip>
        )}
        <Controller
          name={`stops.${index}.standardStopType`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth sx={{ mt: '2px' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '13px',
                  alignItems: 'center',
                }}
              >
                <FormLabel component="legend">This is a</FormLabel>
                <RadioGroup
                  value={field}
                  aria-disabled={disabled}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {[
                      StandardStopType.Commercial,
                      StandardStopType.Residential,
                    ].map((option) => (
                      <FormControlLabel
                        key={option}
                        checked={option === standardStopType}
                        disabled={disabled}
                        value={option}
                        control={<Radio />}
                        label={
                          option === StandardStopType.Commercial
                            ? 'Business'
                            : 'Residence'
                        }
                      />
                    ))}
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

export default React.memo(Address);
