import { Chip, Stack, type SxProps, Typography } from '@mui/material';
import { isNotNilOrEmptyString } from 'shared/string';

const sx: SxProps = {
  borderRadius: '4px',
};

type ServiceAreaChipProps = {
  readonly zipcode: string;
  readonly city: string | null | undefined;
};

export const ServiceAreaChip = ({ zipcode, city }: ServiceAreaChipProps) => (
  <Chip
    label={
      <Stack direction="row" gap={0.5}>
        <Typography component="span" fontWeight={700} fontSize="14px">
          {zipcode}
        </Typography>
        {isNotNilOrEmptyString(city) && (
          <Typography component="span" fontSize="14px">
            {city}
          </Typography>
        )}
      </Stack>
    }
    sx={sx}
  />
);
