import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  EquipmentType,
  type EquipmentsQuery,
  useUpdateVehicleForRouteMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

const VehicleSelectField = ({
  currentVehicleUuid,
  routeUuid,
  equipmentsData,
}: {
  readonly currentVehicleUuid: string | undefined;
  readonly routeUuid: string;
  readonly equipmentsData: EquipmentsQuery | undefined;
}) => {
  const [selectedVehicleUuid, setSelectedVehicleUuid] =
    useState(currentVehicleUuid);
  const vehicle = equipmentsData?.equipments.find(
    (equipment) => equipment.uuid === selectedVehicleUuid,
  );
  const [updateVehicleForRoute] = useUpdateVehicleForRouteMutation();

  useEffect(() => {
    setSelectedVehicleUuid(currentVehicleUuid);
  }, [currentVehicleUuid]);

  return (
    <AutocompleteFuzzy
      disablePortal
      value={
        isNil(vehicle) ? undefined : { label: vehicle.name, id: vehicle.uuid }
      }
      options={
        equipmentsData?.equipments
          .filter((equipment) => equipment.type === EquipmentType.Vehicle)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((equipment) => ({
            label: equipment.name,
            id: equipment.uuid,
          })) ?? []
      }
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => <TextField {...params} size="small" />}
      onChange={(e, val) => {
        if (!isNil(val)) {
          updateVehicleForRoute({
            variables: { routeUuid, vehicleUuid: val.id },
          });
          setSelectedVehicleUuid(val.id);
        }
      }}
    />
  );
};

export default VehicleSelectField;
