import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { type Dayjs, isDayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import PalletDateRangePicker from '../../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { StopMethod } from '../../../orders/components/order-form/forms/stop-type';

enum DatePickerMode {
  SINGLE = 'SINGLE',
  RANGE = 'RANGE',
}

type CustomerPortalDateRangeFieldProps = {
  readonly stopMethod: StopMethod;
  /** Range start date, should be the stop's service date */
  readonly rangeStart: Dayjs | undefined;
  /** Range end date, should be the stop's deadline date */
  readonly rangeEnd: Dayjs | undefined;
  readonly error: string | undefined;
  readonly onChange: (range: {
    rangeStart: Dayjs | undefined;
    rangeEnd: Dayjs | undefined;
  }) => void;
};

const CustomerPortalDateRangeField = ({
  stopMethod,
  rangeStart: propsRangeStart,
  rangeEnd: propsRangeEnd,
  error,
  onChange,
}: CustomerPortalDateRangeFieldProps) => {
  const theme = useTheme();

  const { disabledIfNoAccess: disabled } = useOrderFormEditAccess();

  // Ensure that the range start is before the range end
  const [rangeStart, rangeEnd] = useMemo(() => {
    if (propsRangeStart?.isBefore(propsRangeEnd) === true) {
      return [propsRangeStart, propsRangeEnd];
    }
    return [propsRangeEnd, propsRangeStart];
  }, [propsRangeStart, propsRangeEnd]);

  const [mode, setMode] = useState(
    !isNil(rangeStart) &&
      !isNil(rangeEnd) &&
      !dayjs(rangeStart).isSame(rangeEnd, 'day')
      ? DatePickerMode.RANGE
      : DatePickerMode.SINGLE,
  );
  const methodVerb = stopMethod === StopMethod.Inbound ? 'Pick up' : 'Deliver';
  const label =
    stopMethod === StopMethod.Inbound ? 'Pickup date' : 'Delivery date';

  const onModeChange = (mode: DatePickerMode) => {
    setMode(mode);
    if (
      mode === DatePickerMode.RANGE &&
      dayjs(rangeStart).isSame(rangeEnd, 'day')
    ) {
      onChange({ rangeStart, rangeEnd: undefined });
    } else {
      onChange({ rangeStart, rangeEnd: rangeStart });
    }
  };

  const onSingleDateChange = (date: Dayjs | undefined) => {
    if (isDayjs(date) && !date.isValid()) {
      // Filter out invalid dates but allow undefined values to pass through
      return;
    }
    onChange({
      rangeStart: date,
      rangeEnd: date,
    });
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5} width="100%">
      <RadioGroup
        value={mode}
        onChange={(e) => {
          onModeChange(e.target.value as DatePickerMode);
        }}
      >
        <FormControlLabel
          value={DatePickerMode.SINGLE}
          control={<Radio size="small" />}
          label={`${methodVerb} by`}
          disabled={disabled}
        />
        <FormControlLabel
          value={DatePickerMode.RANGE}
          control={<Radio size="small" />}
          label={`${methodVerb} between`}
          disabled={disabled}
        />
      </RadioGroup>
      <Stack>
        {mode === DatePickerMode.SINGLE ? (
          <DatePicker
            disablePast
            disabled={disabled}
            value={rangeStart ?? null}
            renderInput={(props) => (
              <TextField
                size="small"
                {...props}
                error={!isNil(error)}
                label={label}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    onSingleDateChange(undefined);
                  } else {
                    onSingleDateChange(dayjs(e.target.value));
                  }
                }}
              />
            )}
            onChange={(date) => {
              onSingleDateChange(date ?? undefined);
            }}
          />
        ) : (
          <PalletDateRangePicker
            disablePast
            disabled={disabled}
            inputBaseStyles={{ width: '225px' }}
            label={label}
            value={[
              isNil(rangeStart) ? null : dayjs(rangeStart),
              isNil(rangeEnd) ? null : dayjs(rangeEnd),
            ]}
            hasError={!isNil(error)}
            onAccept={([rangeStart, rangeEnd]) => {
              onChange({
                rangeStart: isNil(rangeStart) ? undefined : rangeStart,
                rangeEnd: isNil(rangeEnd) ? undefined : rangeEnd,
              });
            }}
          />
        )}
        {!isNil(error) && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {error}
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomerPortalDateRangeField;
