import {
  type DragStart,
  type DropResult,
  type ResponderProvided,
} from '@hello-pangea/dnd';
import { type Dayjs } from 'dayjs';
import { clamp, isEmpty, isNil } from 'lodash';
import { getNoonOfDay } from 'shared/date';
import { shallow } from 'zustand/shallow';
import { DispatchMultiplayerAction } from '../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useMe from '../../../common/react-hooks/use-me';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  DocumentType,
  type RouteCreateInput,
  type RouteFragment,
  type RouteSlotArrayUpdateInput,
  useCreateAppointmentsForRouteMutation,
  useCreateRoutePathsMutation,
  useCreateRoutesMutation,
  useOrdersForCoverSheetsLazyQuery,
  useOrdersForDocumentAttachmentsDownloadLazyQuery,
  useRefreshEtasForRouteMutation,
  useRunOptimizationMutation,
  useUpdateRouteMutation,
  useUpdateRouteSlotsMutation,
} from '../../../generated/graphql';
import {
  downloadMultipleCoverSheets,
  downloadMultipleDocumentAttachments,
} from '../../orders/components/utils';
import useDispatchStore from '../dispatch-store';
import {
  MAX_ROUTES_TO_CREATE,
  type RouteStopInsertInput,
} from '../types/routes';
import useAssignStopsRouteActions from './use-assign-stops-route-actions';
import useFetchRoutes from './use-fetch-routes';

const useRouteActions = () => {
  // Documents with e-signatures are buggy when generating pdfs
  // update function these are passed into so we ensure
  // e-signatures don't disappear on pdfs
  const printablePODDocuments = [
    DocumentType.ProofOfDeliveryScanned,
    DocumentType.DeliveryAlert,
    DocumentType.DeliveryReceipt,
    DocumentType.PickupAlert,
    DocumentType.PickupReceiptForEsign,
  ];
  const { segment, companyConfiguration } = useMe();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { fetchRoute } = useFetchRoutes();
  const { reassignStop } = useAssignStopsRouteActions();
  const { sendDispatchUserLocationEvent, sendDispatchUserLeaveLocationEvent } =
    useMultiplayer();
  const [
    getRouteByUuid,
    setShowMap,
    setErrorMessage,
    reorderRouteSlots,
    reverseRouteOrder,
    delRoute,
    addRoute,
    setRoutePaths,
    setRoute,
    setRoutes,
    deselectAllRouteUuids,
    setSelectedMapRouteUuids,
    selectMapRouteUuid,
    clearRouteUuidsLoadingStops,
  ] = useDispatchStore(
    (state) => [
      state.getRouteByUuid,
      state.setShowMap,
      state.setErrorMessage,
      state.reorderRouteSlots,
      state.reverseRoute,
      state.deleteRoute,
      state.addRoute,
      state.setRoutePaths,
      state.setRoute,
      state.setRoutes,
      state.deselectAllRoutes,
      state.setSelectedMapRouteUuids,
      state.selectMapRouteUuid,
      state.clearRouteUuidsLoadingStops,
    ],
    shallow,
  );
  const [createRoutePaths] = useCreateRoutePathsMutation();
  const [calculateEtaForRoute] = useRefreshEtasForRouteMutation();
  const [createAppointmentsForRoute] = useCreateAppointmentsForRouteMutation();
  const [getOrdersForCoverSheets] = useOrdersForCoverSheetsLazyQuery();
  const [getOrdersForDocumentAttachmentsDownload] =
    useOrdersForDocumentAttachmentsDownloadLazyQuery();
  const [optimizeRoute] = useRunOptimizationMutation();
  const [updateRouteSlots] = useUpdateRouteSlotsMutation();
  const [createRoutes] = useCreateRoutesMutation();
  const [updateRoute] = useUpdateRouteMutation();

  const clearRoutes = () => {
    setRoutes([]);
    deselectAllRouteUuids();
    clearRouteUuidsLoadingStops();
  };

  const calculateRouteEta = async (routeUuid: string) => {
    const res = await calculateEtaForRoute({
      variables: {
        uuid: routeUuid,
      },
    });

    if (!isNil(res.errors)) {
      setErrorMessage(res.errors[0]?.message);
    }

    return !isNil(res.data?.refreshEtasForRoute);
  };

  const generateRoutePaths = async ({ uuids }: { uuids: string[] }) => {
    if (isEmpty(uuids)) return false;

    const res = await createRoutePaths({
      variables: {
        routePathsCreateInput: {
          routeUuids: uuids,
        },
      },
    });

    const routePaths = res.data?.createRoutePaths ?? [];

    setRoutePaths(routePaths);
    return !isNil(res.errors);
  };

  const createAppointments = async (routeUuid: string) => {
    const res = await createAppointmentsForRoute({
      variables: {
        uuid: routeUuid,
      },
    });
    const newRoute = res.data?.createAppointmentsForRoute;
    if (!isNil(res.errors)) {
      setErrorMessage(
        'Failed to create appointments for route - please try again.',
      );
    } else if (!isNil(newRoute)) {
      setRoute(newRoute);
    }
  };

  const downloadCoverSheetsForRoutes = async (
    routesToDownload: RouteFragment[],
  ) => {
    const orderUuids: string[] = [];
    for (const route of routesToDownload) {
      for (const slot of route.slots) {
        const order = slot.stops[0]?.shipment?.order;
        if (!isNil(order)) {
          orderUuids.push(order.uuid);
        }
      }
    }
    const responses = await getOrdersForCoverSheets({
      variables: {
        uuids: orderUuids,
      },
    });

    const ordersForCoverSheets =
      responses.data?.ordersByUuids.sort(
        (a, b) => orderUuids.indexOf(a.uuid) - orderUuids.indexOf(b.uuid),
      ) ?? [];
    await downloadMultipleCoverSheets(
      segment,
      companyConfiguration,
      ordersForCoverSheets,
      terminalsEnabled,
      companyConfiguration?.logisticsSectionEnabled ?? false,
      true,
      `cover-sheets`,
    );
  };

  const downloadUnsignedPodDocumentsForRoutes = async (
    routesToDownload: RouteFragment[],
    forceDownload: boolean,
  ) => {
    const orderUuids: string[] = [];
    for (const route of routesToDownload) {
      for (const slot of route.slots) {
        const order = slot.stops[0]?.shipment?.order;
        if (!isNil(order)) {
          orderUuids.push(order.uuid);
        }
      }
    }
    const responses = await getOrdersForDocumentAttachmentsDownload({
      variables: {
        uuids: orderUuids,
      },
    });

    const ordersForUnsignedPodDocuments =
      responses.data?.ordersByUuids.sort(
        (a, b) => orderUuids.indexOf(a.uuid) - orderUuids.indexOf(b.uuid),
      ) ?? [];

    if (
      forceDownload ||
      ordersForUnsignedPodDocuments?.every((order) =>
        order.documents.some((doc) => printablePODDocuments.includes(doc.type)),
      )
    ) {
      await downloadMultipleDocumentAttachments(
        ordersForUnsignedPodDocuments,
        printablePODDocuments,
      );
      return [];
    }
    return ordersForUnsignedPodDocuments.filter((order) =>
      order.documents.every((doc) => !printablePODDocuments.includes(doc.type)),
    );
  };

  const getOptimizeRouteData = async (routeUuid: string) => {
    const routeOptimizationRes = await optimizeRoute({
      variables: {
        createOptimizationRunInput: {
          routeUuid,
        },
      },
    });
    return {
      optimizationUuid: routeOptimizationRes.data?.runOptimization.uuid,
    };
  };

  const updateRouteStops = async (
    newRoute: RouteFragment,
    newStops?: RouteStopInsertInput[],
  ) => {
    const routeSlotArrayUpdateInputs: RouteSlotArrayUpdateInput[] = [];

    for (const [idx, slot] of newRoute.slots.entries()) {
      if (isNil(slot)) continue;
      const stopUuid = slot.stops[0]?.uuid;
      const stopCreateInputAtIndex = newStops?.find(
        (stop) => stop.index === idx,
      );
      if (!isNil(stopCreateInputAtIndex)) {
        routeSlotArrayUpdateInputs.push({
          routeSlotCreateInput: {
            drayageStopUuids: [stopCreateInputAtIndex.stopUuid],
          },
        });
      }
      if (!isNil(stopUuid)) {
        routeSlotArrayUpdateInputs.push({
          routeSlotUpdateInput: {
            uuid: slot.uuid,
            drayageStopUuids: [stopUuid],
          },
        });
      }
    }
    const stopCreateInputAtLastIndex = newStops?.find(
      (stop) => stop.index === newRoute.slots.length,
    );
    if (!isNil(stopCreateInputAtLastIndex)) {
      routeSlotArrayUpdateInputs.push({
        routeSlotCreateInput: {
          drayageStopUuids: [stopCreateInputAtLastIndex.stopUuid],
        },
      });
    }

    const res = await updateRouteSlots({
      variables: {
        updateRouteSlotsInput: {
          uuid: newRoute.uuid,
          routeSlotsUpdatedAt: new Date(),
          routeSlotArrayUpdateInputs,
        },
      },
    });

    if (!isNil(res.errors)) {
      setErrorMessage(res.errors[0]?.message);
    }

    return isNil(res.errors) && !isNil(res.data?.updateRouteSlots);
  };

  const updateStopOrder = async ({
    routeUuid,
    startIndex,
    endIndex,
    stopUuid,
    swap = false,
  }: {
    routeUuid: string;
    startIndex?: number;
    endIndex: number;
    stopUuid?: string;
    swap?: boolean;
  }) => {
    if (!isNil(startIndex)) {
      const newRoute = reorderRouteSlots(routeUuid, startIndex, endIndex, swap);
      if (!isNil(newRoute)) {
        setRoute(newRoute);
        return updateRouteStops(newRoute);
      }
      return false;
    }
    if (!isNil(stopUuid)) {
      const newRoute = getRouteByUuid(routeUuid);
      if (!isNil(newRoute)) {
        setRoute(newRoute);
        return updateRouteStops(newRoute, [{ stopUuid, index: endIndex }]);
      }
    }
    return true;
  };

  const createNewRoutes = async ({
    planningDate,
    count = 1,
    terminalUuid,
    startTime,
    loadTime,
    unloadTime,
    stopUuids,
    driverUuid,
    equipmentUuid,
  }: {
    planningDate: Dayjs;
    count?: number | undefined;
    terminalUuid: string | undefined;
    startTime?: Date | null;
    loadTime?: number | null;
    unloadTime?: number | null;
    stopUuids?: string[] | null;
    driverUuid?: string | null;
    equipmentUuid?: string | null;
  }) => {
    const res = await createRoutes({
      variables: {
        createRoutesInput: {
          routeCreateInputs: new Array<RouteCreateInput>(
            clamp(count, 1, isNil(stopUuids) ? MAX_ROUTES_TO_CREATE : 1),
          ).fill({
            date: getNoonOfDay(planningDate.toDate()),
            terminalUuid,
            defaultStartTime: startTime,
            loadTimeInMinutes: loadTime,
            unloadTimeInMinutes: unloadTime,
            stopUuids,
            driverUuid,
            equipmentUuid,
          }),
        },
      },
    });
    const createdRoutes = res.data?.createRoutes;
    if (!isNil(createdRoutes)) {
      for (const route of createdRoutes) {
        addRoute(route);
        selectMapRouteUuid(route.uuid);
      }
    }
  };

  const reverseRoute = async (routeUuid: string) => {
    const newRoute = reverseRouteOrder(routeUuid);
    if (!isNil(newRoute)) {
      return updateRouteStops(newRoute);
    }
    return true;
  };

  const lockRoute = async (routeUuid: string) => {
    await updateRoute({
      variables: {
        updateRouteInput: {
          routeUpdateInput: {
            uuid: routeUuid,
            locked: true,
          },
        },
      },
    });
    await fetchRoute(routeUuid);
  };

  const unlockRoute = async (routeUuid: string) => {
    await updateRoute({
      variables: {
        updateRouteInput: {
          routeUpdateInput: {
            uuid: routeUuid,
            locked: false,
          },
        },
      },
    });
    await fetchRoute(routeUuid);
  };

  const deleteRoute = async (routeUuid: string) => {
    delRoute(routeUuid);
  };

  const showRouteOnMap = (routeUuid: string) => {
    setShowMap(true);
    setSelectedMapRouteUuids([]);
    selectMapRouteUuid(routeUuid);
  };

  const onDragStart = async (start: DragStart) => {
    const { source } = start;
    sendDispatchUserLocationEvent({
      action: DispatchMultiplayerAction.REORDERING,
      routeUuid: source.droppableId,
      stopUuid: start.draggableId,
    });
  };

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    sendDispatchUserLeaveLocationEvent();
    if (isNil(destination)) return;
    if (source.droppableId === destination.droppableId) {
      const res = await updateStopOrder({
        routeUuid: destination.droppableId,
        startIndex: source.index,
        endIndex: destination.index,
      });
      if (!res) {
        fetchRoute(destination.droppableId);
        setErrorMessage(
          'Failed to reorder stops because the route has changed - please try again.',
        );
      }
    } else if (!isNil(getRouteByUuid(destination.droppableId))) {
      const res = await reassignStop({
        routeUuid: destination.droppableId,
        slotUuid: result.draggableId,
        endIndex: destination.index,
      });
      if (!res) {
        setErrorMessage(
          'Failed to reorder stops because the route has changed - please try again.',
        );
      }
    }
  };

  return {
    updateStopOrder,
    updateRouteStops,
    clearRoutes,
    calculateRouteEta,
    createAppointments,
    downloadCoverSheetsForRoutes,
    downloadUnsignedPodDocumentsForRoutes,
    generateRoutePaths,
    getOptimizeRouteData,
    showRouteOnMap,
    createNewRoutes,
    reverseRoute,
    lockRoute,
    unlockRoute,
    deleteRoute,
    onDragStart,
    onDragEnd,
  };
};

export default useRouteActions;
