import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  type SxProps,
  Typography,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { type FieldValues, useFormContext } from 'react-hook-form';
import useContacts from '../../../../../../../common/react-hooks/use-contacts';
import useMe from '../../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../../common/react-hooks/use-services';
import useTerminals from '../../../../../../../common/react-hooks/use-terminals';
import useThirdPartyBrokers from '../../../../../../../common/react-hooks/use-third-party-brokers';
import { OrderDocumentType } from '../../../../../../../generated/graphql';
import GeneratedArrivalNoticePdf from '../../../../../../generated-documents/components/generated-arrival-notice-pdf';
import { convertOrderFormValuesToArrivalNoticeData } from '../../../../../../generated-documents/utils';
import EmailOrderDocuments from './email-order-documents-component';

const styles = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as SxProps,
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  } as SxProps,
};

const PrintArrivalNoticeModal = ({
  isOpen,
  setIsOpen,
  isQuote,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly isQuote: boolean;
}) => {
  const { segment, companyData, companyConfiguration } = useMe();
  const { terminalsEnabled, getTerminalCode } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { getContactName } = useContacts();
  const { getServiceName } = useServices();
  const { getThirdPartyBrokerName } = useThirdPartyBrokers();
  const { getValues } = useFormContext();
  const order: FieldValues = getValues();
  const [showArrivalNoticeError, setShowArrivalNoticeError] =
    useState<boolean>(false);
  const [showCharges, setShowCharges] = useState(true);

  const showExternalNotes =
    companyConfiguration?.logisticsSectionEnabled === true;

  const arrivalNoticeData = convertOrderFormValuesToArrivalNoticeData({
    order,
    terminalsEnabled,
    companyData,
    thirdPartyBrokerName: getThirdPartyBrokerName(order.thirdPartyBrokerUuid),
    getTerminalCode,
    contactName: getContactName(order.contactUuid),
    serviceName: getServiceName(order.serviceUuid),
  });

  const generatedPdf = isNil(arrivalNoticeData) ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  ) : (
    <GeneratedArrivalNoticePdf
      segment={segment}
      data={arrivalNoticeData}
      showCharges={showCharges}
      showExternalNotes={showExternalNotes}
    />
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={styles.center}>
        <Box sx={styles.modal}>
          <IconButton
            sx={{ padding: 0, float: 'right', mb: '10px', ml: 'auto' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack alignItems="center" spacing={2}>
            {!isNil(arrivalNoticeData) && (
              <PDFViewer showToolbar style={{ width: '100%', height: '65vh' }}>
                {generatedPdf}
              </PDFViewer>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={showCharges}
                onChange={(e) => {
                  setShowCharges(e.target.checked);
                }}
              />
              <Typography>Show charges</Typography>
            </Box>
            <Button variant="contained">
              <PDFDownloadLink
                document={generatedPdf}
                fileName={`arrival-notice-${arrivalNoticeData?.orderName}.pdf`}
              >
                {({ loading }) => (loading ? 'Loading...' : 'Download')}
              </PDFDownloadLink>
            </Button>
            <EmailOrderDocuments
              documentType={OrderDocumentType.ArrivalNotice}
              isQuote={isQuote}
              generatedPdf={generatedPdf}
              isOpen={isOpen}
            />
          </Stack>
        </Box>
        <Snackbar
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showArrivalNoticeError}
          onClose={() => {
            setShowArrivalNoticeError(false);
          }}
        >
          <Alert severity="error">
            Unable to generate order arrival notice - please contact support.
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default React.memo(PrintArrivalNoticeModal);
