import { Alert, Snackbar } from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil } from 'lodash';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../common/feature-flags';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import { useTableFields } from '../../common/react-hooks/use-table-fields';
import LineHaulDispatchTableAgGrid from '../ag-grid/line-haul/line-haul-dispatch-table-ag-grid';
import { ExcludeFromHiding } from '../orders/components/enums/order-filters';
import LineHaulDispatchManifestsSection from './components/line-haul-dispatch-manifests-section';
import { DemoLineHaulView } from './demo/demo-line-haul-view';
import COLUMNS from './grid-columns';
import useLineHaulDispatchStore from './store/line-haul-dispatch-store';
import { type LineHaulOrdersTab, type SegmentedLineHaulOrder } from './types';
import { canAddOrderToManifest } from './utils';
import { VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS } from '../orders/constants';
import { useOrdersTableColumns } from '../orders/components/order-form/hooks/use-orders-table-columns';
import { FilterViewPage } from '../../generated/graphql';
import { OrdersTableWithFiltersAgGrid } from '../ag-grid/orders/orders-table-with-filters-ag-grid';
import { type DefaultFilterTabsConfigs } from '../ag-grid/orders/types';
import useMe from '../../common/react-hooks/use-me';
import { OrderDialog } from '../orders/components/order-dialog';
import useGlobalStore from '../../layouts/dashboard/global-store';
import LineHaulManifestsSection from './components/manifests-section/line-haul-manifests-section';

dayjs.extend(utc);
dayjs.extend(timezone);

const LINE_HAUL_ORDERS_PAGE_SIZE = 25;

const LINE_HAUL_DEFAULT_FILTER_TAB_CONFIGS: DefaultFilterTabsConfigs<LineHaulOrdersTab> =
  Object.freeze({
    defaultTab: '',
    baseTab: '',
    tabs: [],
  });

const LineHaulView = () => {
  const { lineHaulTableFields, orderTableFields } = useTableFields();
  const gridRef = useRef<AgGridReact<SegmentedLineHaulOrder>>(null);

  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);

  const { userUuid } = useMe();

  const [
    currentManifestTab,
    openedManifest,
    snackbarSuccessMessage,
    setSnackbarSuccessMessage,
    snackbarErrorMessage,
    setSnackbarErrorMessage,
  ] = useLineHaulDispatchStore(
    (state) => [
      state.currentManifestTab,
      state.openedManifest,
      state.snackbarSuccessMessage,
      state.setSnackbarSuccessMessage,
      state.snackbarErrorMessage,
      state.setSnackbarErrorMessage,
    ],
    shallow,
  );

  const [openedOrderUuid, setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.currentOrderUuid, state.setCurrentOrderUuid],
    shallow,
  );

  useEffect(() => {
    gridRef.current?.columnApi?.setColumnVisible(
      ExcludeFromHiding.EMPTY_SPACE,
      canAddOrderToManifest(openedManifest, currentManifestTab),
    );
  }, [currentManifestTab, openedManifest]);

  const orderTableHeaders = useMemo(() => {
    return !isNil(orderTableFields) && !isEmpty(orderTableFields)
      ? orderTableFields
      : VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS;
  }, [orderTableFields]);

  const columnDefinitions = useOrdersTableColumns({
    pageType: FilterViewPage.Orders,
  });

  const columnsOrderedByConfig: Array<ColDef<SegmentedLineHaulOrder>> =
    useMemo(() => {
      let orderedColumns: Array<ColDef<SegmentedLineHaulOrder>>;

      orderedColumns = isNil(lineHaulTableFields)
        ? COLUMNS
        : (lineHaulTableFields
            .map((header) => COLUMNS.find((column) => column.field === header))
            .filter((column) => !isNil(column)) as Array<
            ColDef<SegmentedLineHaulOrder>
          >);

      // If we add filters, remember to add them here
      return [
        ...orderedColumns,
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          maxWidth: 50,
          field: ExcludeFromHiding.EMPTY_SPACE,
          headerName: '',
          pinned: 'left' as const,
        },
      ];
    }, [lineHaulTableFields]);

  if (ffDemoSterlingLineHaul) {
    return <DemoLineHaulView />;
  }

  return (
    <>
      <Splitter
        stateStorage="local"
        stateKey="line-haul-splitter-state"
        style={{ height: '100%' }}
      >
        <SplitterPanel style={{ height: '100%' }}>
          {ffLineHaulNetworks ? (
            // TODO: Add selectedUuids and setSelectedUuids, isHeaderCheckboxSelected and setIsHeaderCheckboxSelected
            <OrdersTableWithFiltersAgGrid<LineHaulOrdersTab>
              rowSelectionEnabled
              // We pass false also in ViewAllOrdersPage. Currently header checkbox selection is broken.
              enableHeaderCheckboxSelection={false}
              userUuid={userUuid}
              userOrderTableFields={orderTableHeaders}
              columnDefinitions={columnDefinitions}
              pageSize={LINE_HAUL_ORDERS_PAGE_SIZE}
              pageType={FilterViewPage.LineHaulOrders}
              defaultFilterTabsConfigs={LINE_HAUL_DEFAULT_FILTER_TAB_CONFIGS}
              showTotalCount={false}
              enableNumberOfOrdersQuery={false}
              shouldAllowSavedViews={false}
            />
          ) : (
            <LineHaulDispatchTableAgGrid
              tableHeaders={lineHaulTableFields}
              columnDefinitions={columnsOrderedByConfig}
              selectedUuids={selectedUuids}
              setSelectedUuids={setSelectedUuids}
              isHeaderCheckboxSelected={isHeaderCheckboxSelected}
              setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
              gridRef={gridRef}
            />
          )}
        </SplitterPanel>
        <SplitterPanel style={{ overflow: 'auto' }}>
          {ffLineHaulNetworks ? (
            <LineHaulManifestsSection />
          ) : (
            <LineHaulDispatchManifestsSection />
          )}
        </SplitterPanel>
      </Splitter>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(snackbarSuccessMessage)}
        onClose={() => {
          setSnackbarSuccessMessage(undefined);
        }}
      >
        <Alert>{snackbarSuccessMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={!isNil(snackbarErrorMessage)}
        onClose={() => {
          setSnackbarErrorMessage(undefined);
        }}
      >
        <Alert severity="error">{snackbarErrorMessage}</Alert>
      </Snackbar>
      {!isNil(openedOrderUuid) && (
        <OrderDialog
          open
          orderUuid={openedOrderUuid}
          onClose={() => {
            setOpenedOrderUuid(undefined);
          }}
        />
      )}
    </>
  );
};

export default LineHaulView;
