import {
  AppointmentCallStatus,
  AppointmentTextStatus,
} from '../../generated/graphql';

export const UNCONFIRMED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [
  AppointmentTextStatus.Sent,
  AppointmentCallStatus.InProgress,
  AppointmentCallStatus.Called,
  AppointmentCallStatus.Busy,
  AppointmentCallStatus.Failed,
  AppointmentCallStatus.Rejected,
  AppointmentCallStatus.NoAnswer,
  AppointmentCallStatus.Cancelled,
];

export const UNSCHEDULED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [
  AppointmentTextStatus.NotSent,
  AppointmentCallStatus.NotCalled,
  null,
  undefined,
];

export const CONFIRMED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [AppointmentTextStatus.Confirmed, AppointmentCallStatus.Confirmed];

export const REJECTED_STATUSES: Array<
  AppointmentTextStatus | AppointmentCallStatus | undefined | null
> = [AppointmentTextStatus.Rejected, AppointmentCallStatus.Rejected];
