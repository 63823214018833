import { Check, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import useStyles from '../../../../common/components/general-styles';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

export type InvoiceFilterOption = {
  value: string | undefined;
  label: string | undefined;
};

type InvoiceFilterButtonProps = {
  readonly options: InvoiceFilterOption[];
  readonly selectedOption: InvoiceFilterOption | undefined;
  readonly setSelectedOption: (option: InvoiceFilterOption | undefined) => void;
};

const InvoiceFilterButton = ({
  options,
  selectedOption,
  setSelectedOption,
}: InvoiceFilterButtonProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const isAllSelected = selectedOption === undefined;

  const displayLabel = useMemo(() => {
    const label = selectedOption?.label;
    if (isNil(label)) return 'All';
    if (label.length > 20) {
      return `${label.slice(0, 20)}...`;
    }
    return label;
  }, [selectedOption]);

  return (
    <Box>
      <Button
        size="large"
        variant="outlined"
        sx={[styles.filterButton]}
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
          <Typography sx={styles.filterTitle}>Invoice: </Typography>
          <Typography sx={styles.filterValue}>{displayLabel}</Typography>
          <ExpandMore fontSize="small" sx={{ mr: 0 }} />
        </Box>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        id="invoice-menu"
        open={Boolean(menuAnchorEl)}
        sx={{
          '& .MuiMenu-paper': { overflow: 'visible' },
          top: '3px',
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
      >
        <MenuList
          dense
          sx={{
            p: 0,
          }}
        >
          <MenuItem
            key="all"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
            onClick={() => {
              setSelectedOption(undefined);
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility:
                    selectedOption === undefined ? undefined : 'hidden',
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <Typography sx={styles.menuText}>All</Typography>
            </Stack>
          </MenuItem>
          <MenuItem
            key="custom"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'visible',
              pl: '10px',
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Check
                sx={{
                  visibility: isAllSelected ? 'hidden' : undefined,
                  fontSize: '14px',
                  ml: 0,
                  mr: '6px',
                }}
              />
              <AutocompleteFuzzy
                size="small"
                sx={{ backgroundColor: 'white', width: '200px' }}
                value={selectedOption}
                options={options}
                matchSortOptions={{ keys: ['label'] }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
                onChange={(_, option) => {
                  if (isNil(option)) return;
                  setSelectedOption(option);
                }}
              />
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default InvoiceFilterButton;
