import {
  Button,
  MenuList,
  Menu,
  Stack,
  Typography,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { type LineHaulManifest } from '../utils';
import ManifestStatusChip from '../../manifest-status-chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import {
  LineHaulManifestsDocument,
  LineHaulManifestStatus,
  useMarkManifestAsArrivedMutation,
  useMarkManifestAsDepartedMutation,
  useMarkManifestAsPlanningMutation,
} from '../../../../../generated/graphql';
import { exhaustive } from 'shared/switch';

type StautsPickerProps = {
  readonly manifest: LineHaulManifest;
};

const StatusPicker = ({ manifest }: StautsPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [markManifestAsDeparted, { loading: markManifestAsDepartedLoading }] =
    useMarkManifestAsDepartedMutation({
      refetchQueries: [LineHaulManifestsDocument],
    });
  const [markManifestAsArrived, { loading: markManifestAsArrivedLoading }] =
    useMarkManifestAsArrivedMutation({
      refetchQueries: [LineHaulManifestsDocument],
    });
  const [markManifestAsPlanning, { loading: markManifestAsPlanningLoading }] =
    useMarkManifestAsPlanningMutation({
      refetchQueries: [LineHaulManifestsDocument],
    });

  const handleStatusTransition = async (status: LineHaulManifestStatus) => {
    switch (status) {
      case LineHaulManifestStatus.Departed: {
        await markManifestAsDeparted({
          variables: {
            markManifestAsDepartedInput: {
              uuid: manifest.uuid,
            },
          },
        });
        break;
      }
      case LineHaulManifestStatus.Arrived: {
        await markManifestAsArrived({
          variables: {
            markManifestAsArrivedInput: {
              uuid: manifest.uuid,
            },
          },
        });
        break;
      }
      case LineHaulManifestStatus.Planning: {
        await markManifestAsPlanning({
          variables: {
            markManifestAsPlanningInput: {
              uuid: manifest.uuid,
            },
          },
        });
        break;
      }
      default: {
        exhaustive(status);
      }
    }
  };

  const isLoading =
    markManifestAsDepartedLoading ||
    markManifestAsArrivedLoading ||
    markManifestAsPlanningLoading;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="subtitle2">Status</Typography>
      <Stack direction="row" alignItems="center" gap={0.5}>
        {isLoading && (
          <CircularProgress
            size={15}
            sx={{
              color: 'primary.main',
            }}
          />
        )}
        <Button
          endIcon={<ArrowDropDownIcon />}
          disabled={isLoading}
          onClick={handleClick}
        >
          <ManifestStatusChip status={manifest.status} />
        </Button>
      </Stack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuList>
          {Object.values(LineHaulManifestStatus).map((status) => {
            return (
              <MenuItem
                key={status}
                onClick={() => {
                  handleClose();
                  void handleStatusTransition(status);
                }}
              >
                <ManifestStatusChip status={status} />
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default StatusPicker;
