import { Warning } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Alert,
  AppBar,
  Box,
  Button,
  FormControl,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil, noop } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { filterNotNil } from 'shared/array';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import { GappedStackCard } from '../../../common/components/gapped-stack-card';
import { UserPermissionsProvider } from '../../../common/contexts/user-permissions';
import { FeatureFlag } from '../../../common/feature-flags';
import { type Option } from '../../../common/filters/types';
import useDrivers from '../../../common/react-hooks/use-drivers';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useLogout from '../../../common/react-hooks/use-logout';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import useServices from '../../../common/react-hooks/use-services';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import { useWarningOnExit } from '../../../common/react-hooks/warning-on-exit';
import {
  FreightBillingMethod,
  FulfillmentType,
  PermissionResource,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useBillingReviewStore from '../../invoices/billing-review-store';
import { ContactStationsField } from '../../orders/components/order-form/components/contact-station';
import Details from '../../orders/components/order-form/components/details';
import OrderActions from '../../orders/components/order-form/components/header/order-actions';
import Logistics from '../../orders/components/order-form/components/logistics/logistics';
import { OrderFormCard } from '../../orders/components/order-form/components/order-form-card';
import OrderFormErrorDialog, {
  OrderFormType,
} from '../../orders/components/order-form/components/order-form-error-dialog';
import { OrderNameField } from '../../orders/components/order-form/components/order-name-field';
import OrderOverLimitDialog from '../../orders/components/order-form/components/order-over-limit-dialog';
import Documents from '../../orders/components/order-form/components/overview/documents';
import Packages from '../../orders/components/order-form/components/packages';
import { DemoPackages } from '../../orders/components/order-form/components/packages/demo/demo-packages';
import Stops from '../../orders/components/order-form/components/stops/stops';
import { OrderFormEditAccessProvider } from '../../orders/components/order-form/contexts/order-form-edit-access-context';
import { type OrderFormValues } from '../../orders/components/order-form/forms/types';
import useOrderForm from '../../orders/components/order-form/forms/use-order-form';
import {
  getDisabledForEditAccess,
  useGetOrderFormEditAccess,
} from '../../orders/components/order-form/forms/use-order-form-edit-access';
import { useLoadOrderForm } from '../../orders/components/order-form/hooks/use-load-order-form';
import { useOrderFormContact } from '../../orders/components/order-form/hooks/use-order-form-contact';
import { useSaveOrderForm } from '../../orders/components/order-form/hooks/use-save-order-form';
import OrderPageToolbar from '../../orders/components/order-form/order-page-toolbar';
import { type OnSubmitParams } from '../../orders/components/order-form/types';
import {
  getInboundStop,
  getOutboundStop,
  shouldShowTariffWarning,
} from '../../orders/components/order-form/utils';
import { type AutocompletePerson } from '../../orders/components/standard/components/autocomplete-person';
import useOrderFormStore from '../../orders/order-form-store';
import { demoPermissions } from './demo-permissions';

const useStyles = () => {
  return {
    appBar: {
      position: 'sticky',
      flexDirection: 'row',
      height: 64,
      alignItems: 'center',
      justifyContent: 'center',
    },
    appBarTypography: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: 1,
      color: '#FFFFFF',
    },
  };
};

export type DemoCustomerPortalPlaceOrdersPageProps = {
  readonly orderUuid?: string;
};

const DemoCustomerPortalPlaceOrdersPageForm = ({
  orderUuid,
}: DemoCustomerPortalPlaceOrdersPageProps) => {
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const { userPermissions } = useUserRoles();
  // We use this instead of form.isSubmitSuccessful to not block navigation
  // when the order is deleted
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);
  const { setValue, control, getValues, handleSubmit, clearErrors } =
    useFormContext<OrderFormValues>();
  const { isDirty, errors } = useFormState<OrderFormValues>({ control });
  const navigate = useNavigate();
  const { loadTerminals } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { loadServices } = useServices();
  const { loadDrivers } = useDrivers();
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const ffCustomerPortalLogisticsSection = useFeatureFlag(
    FeatureFlag.FF_DEMO_CUSTOMER_PORTAL_LOGISTICS_SECTION,
  );
  const ffCustomerPortalFulfillmentType = useFeatureFlag(
    FeatureFlag.FF_DEMO_CUSTOMER_PORTAL_FULFILLMENT_TYPE,
  );
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );
  const [setOpenedOrderUuid] = useGlobalStore(
    (state) => [state.setCurrentOrderUuid],
    shallow,
  );
  const [setJustFinalizedCharges] = useOrderFormStore(
    (state) => [state.setJustFinalizedCharges],
    shallow,
  );
  const [setOpenedOrderDataLoading] = useBillingReviewStore(
    (state) => [state.setOpenedOrderDataLoading],
    shallow,
  );

  const [searchParams] = useSearchParams();
  const logout = useLogout();
  const styles = useStyles();

  const duplicate = searchParams.get('duplicate');
  const pageOrderUuid = searchParams.get('orderUuid');
  const isDocumentScan = !isNil(searchParams.get('isDocumentScan'));

  const isEditMode =
    (!isNil(pageOrderUuid) || !isNil(orderUuid)) && isNil(duplicate);
  const orderUuidToUse = orderUuid ?? pageOrderUuid;

  const { canWrite: hasDeleteOrdersPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.DeleteOrders,
  );

  const { contactUuid } = useOrderFormContact();
  const currentCompany = isNil(contactUuid)
    ? null
    : thirdPartyUser?.contacts.find((c) => c.uuid === contactUuid)?.company;

  const { initializeForm } = useLoadOrderForm(currentCompany?.configuration);
  const { saveOrderForm } = useSaveOrderForm({
    isEditMode,
    modalOnClose: undefined,
    completeRebillAfterSave: undefined,
  });
  const existingContactUuid = useWatch({ control, name: 'contactUuid' });

  const [showOrderHasBeenUpdated, setShowOrderHasBeenUpdated] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const theme = useTheme();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState<
    string | undefined
  >();
  const confirm = useConfirm();

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();
  const editAccess = getOrderFormEditAccess({ detailedStatus });
  const { disabledIfInvoicePosted } = getDisabledForEditAccess({ editAccess });

  const successCallback = useCallback(
    async (args: OnSubmitParams): Promise<boolean> => {
      const orderValues = getValues();
      const stopValues = orderValues.stops ?? [];

      const shouldShowFulfillmentWarning = (() => {
        if (!ffCustomerPortalFulfillmentType) {
          return false;
        }
        if (orderValues.fulfillmentType !== FulfillmentType.Dedicated) {
          return false;
        }

        if (
          isNil(stopValues[0]?.deliveryDate) &&
          isNil(stopValues[1]?.deliveryDate)
        ) {
          return false;
        }

        return !dayjs(stopValues[0]?.serviceDate).isSame(
          stopValues[1]?.serviceDate,
          'date',
        );
      })();

      const inboundStop = getInboundStop(stopValues);
      const outboundStop = getOutboundStop(stopValues);

      if (shouldShowFulfillmentWarning) {
        await confirm({
          title: 'Warning: Inbound date is not the same as outbound date',
          confirmationText: `Save`,
        })
          .then(async () => {
            await saveOrderForm(args);
          })
          // Catch block handles rejection when user cancels or closes dialog.
          // This ensures the promise chain completes and subsequent code executes.
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
          .catch(noop);
        return true;
      }
      if (shouldShowTariffWarning({ inboundStop, outboundStop })) {
        await confirm({
          title: 'Unable to calculate freight charges',
          description: (
            <>
              <Typography mb="10px">
                Could not find a valid tariff for:{' '}
              </Typography>
              {inboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(inboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Inbound stop ({inboundStop.stopType})
                  </Typography>
                )}
              {outboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(outboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Outbound stop ({outboundStop.stopType})
                  </Typography>
                )}
              <Typography mt="10px"> Save this order anyway?</Typography>
            </>
          ),
          confirmationText: `Save`,
        })
          .then(async () => {
            await (isDocumentScan
              ? saveOrderForm({ isDocScan: true })
              : saveOrderForm(args));
          })
          // Catch block handles rejection when user cancels or closes dialog.
          // This ensures the promise chain completes and subsequent code executes.
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
          .catch(noop);
        return true;
      }

      // TODO: show error if the save fails
      await saveOrderForm(args);
      setShowSuccessMessage(true);
      return true;
    },
    [
      confirm,
      getValues,
      isDocumentScan,
      saveOrderForm,
      ffCustomerPortalFulfillmentType,
    ],
  );

  const onSubmit = useCallback(
    async (args: OnSubmitParams) => {
      clearErrors();
      let success = false;
      await handleSubmit(
        async () => {
          try {
            // Ideally we'd also wait for the save mutation (called by
            // successCallback) to complete before setting wasSubmitSuccessful
            // to true, but successCallback also navigates away :(
            setWasSubmitSuccessful(true);
            success = await successCallback(args);
          } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(`Error in successCallback: ${JSON.stringify(error)}`);

            // Type-safe error handling
            if (error instanceof Error) {
              setCustomErrorMessage(error.message);
            } else {
              setCustomErrorMessage('An unexpected error occurred');
            }
            setShowErrorModal(true);
            setWasSubmitSuccessful(false);
            success = false;
          }
        },
        async (err) => {
          setWasSubmitSuccessful(false);
          // eslint-disable-next-line no-console
          console.warn(`Error creating/saving order: ${JSON.stringify(err)}`);
          setShowErrorModal(true);
          success = false;
        },
      )();
      return success;
    },
    [clearErrors, handleSubmit, successCallback],
  );

  useEffect(() => {
    void loadTerminals();
    void loadServices();
    void loadDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany?.uuid]);

  useEffect(() => {
    if (!isNil(currentCompany)) {
      void initializeForm({
        orderUuid: orderUuidToUse,
        recurringTemplate: false,
        duplicate,
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentCompany?.uuid, orderUuidToUse, duplicate]);

  useWarningOnExit(
    isDirty && !wasSubmitSuccessful,
    'Are you sure you want to leave? This order has unsaved changes.',
  );

  const contactOptions = useMemo<Option[]>(() => {
    if (isNil(thirdPartyUser)) {
      return [];
    }
    return thirdPartyUser.contacts
      .map((contact) => ({
        value: contact.uuid,
        label: contact.displayName,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));
  }, [thirdPartyUser]);

  const [autocompletePerson, setAutocompletePerson] =
    useState<AutocompletePerson | null>(null);

  const deleteEnabled =
    isEditMode && !isDocumentScan && hasDeleteOrdersPermission;

  return (
    <>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
      >
        <Alert>Order saved</Alert>
      </Snackbar>
      <OrderFormErrorDialog
        handleClose={() => {
          setShowErrorModal(false);
          setCustomErrorMessage(undefined);
        }}
        open={showErrorModal}
        additionalErrors={filterNotNil([customErrorMessage])}
        type={OrderFormType.Order}
      />
      {!isEditMode && (
        <OrderOverLimitDialog
          contactUuid={contactUuid ?? existingContactUuid}
        />
      )}
      <Stack
        direction="column"
        sx={{
          backgroundColor: '#F7F7F7',
          height: '100vh',
          overflowY: 'auto',
        }}
      >
        <AppBar color="primary" sx={styles.appBar}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Typography sx={styles.appBarTypography}>
              Place an order
              {isNil(currentCompany) ? '' : ` with ${currentCompany.name}`}
            </Typography>
          </Box>
          <Button
            startIcon={<LogoutIcon />}
            sx={{ justifySelf: 'flex-end', color: '#fff' }}
            onClick={logout}
          >
            Logout
          </Button>
        </AppBar>
        <Box
          sx={{
            height: 'calc(100dvh - 64px)',
            paddingTop: '2px',
          }}
        >
          <OrderFormEditAccessProvider value={editAccess}>
            <Stack sx={{ maxHeight: '100%' }}>
              <Box
                flexDirection="column"
                p={2}
                gap={1}
                style={{
                  display: showOrderHasBeenUpdated ? 'flex' : 'none',
                  borderBottom: `1px solid ${theme.palette.borderColor.main}`,
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.warning.main }}
                  >
                    WARNING: This order has been edited by another user.
                  </Typography>
                  <Warning
                    fontSize="large"
                    sx={{ color: theme.palette.warning.main }}
                  />
                </Box>
                <Typography variant="body1">
                  Refresh the page to get the latest details. Otherwise, saving
                  the order now may override the latest changes.
                </Typography>
                <Box display="flex" gap={1}>
                  <Button
                    variant="contained"
                    sx={{ width: 'fit-content' }}
                    onClick={() => {
                      globalThis.location.reload();
                    }}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ width: 'fit-content' }}
                    onClick={() => {
                      setShowOrderHasBeenUpdated(false);
                    }}
                  >
                    Dismiss
                  </Button>
                </Box>
              </Box>
              <OrderPageToolbar>
                <Stack
                  flexGrow={1}
                  direction="row"
                  gap={2}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate(-1);
                      setOpenedOrderDataLoading(true);
                      setOpenedOrderUuid(undefined);
                      setJustFinalizedCharges(false);
                    }}
                  >
                    Back
                  </Button>
                  {contactOptions.length > 1 && (
                    <Controller
                      name="contactUuid"
                      control={control}
                      render={({ field }) => (
                        // <ContactAutocompleteComponent<OrderFormValues, 'contactUuid'>
                        //   contactUuid={contactUuid ?? null}
                        //   disabled={disabledIfInvoicePosted}
                        //   field={field}
                        //   errors={errors}
                        // />
                        <FormControl
                          sx={{
                            flexGrow: 1,
                            maxWidth: '380px',
                          }}
                        >
                          <AutocompleteFuzzy
                            {...field}
                            autoHighlight
                            size="small"
                            value={
                              isNil(contactUuid)
                                ? null
                                : {
                                    value: contactUuid,
                                    label:
                                      contactOptions.find(
                                        (option) =>
                                          option.value === contactUuid,
                                      )?.label ?? '',
                                  }
                            }
                            matchSortOptions={{ keys: ['label'] }}
                            options={contactOptions}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Customer"
                                sx={{ width: '100%' }}
                                error={!isNil(errors.contactUuid)}
                              />
                            )}
                            disabled={disabledIfInvoicePosted}
                            onChange={(_event, option) => {
                              field.onChange(option?.value ?? '');
                              setValue('contactStationId', null);
                            }}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  {ffUseStations && (
                    <ContactStationsField disabled={disabledIfInvoicePosted} />
                  )}
                  <OrderNameField disabled />
                </Stack>
                <OrderActions
                  isEditMode={isEditMode}
                  isDuplicate={!isEmpty(duplicate)}
                  deleteEnabled={deleteEnabled}
                  setWasSubmitSuccessful={setWasSubmitSuccessful}
                  onSubmit={onSubmit}
                />
              </OrderPageToolbar>
              <Stack
                sx={{
                  overflow: 'hidden',
                  backgroundColor: theme.palette.grey[100],
                  flexDirection: 'row',
                }}
              >
                <GappedStackCard
                  style={{
                    overflow: 'auto',
                    flexBasis: '100%',
                    flexShrink: 0,
                  }}
                >
                  <Details
                    isEditMode={isEditMode}
                    setAutocompletePerson={setAutocompletePerson}
                  />
                  <Stops
                    isQuotePage={false}
                    showDocumentComponent={isDocumentScan}
                    isEditMode={isEditMode}
                    autocompletePerson={autocompletePerson}
                    setAutocompletePerson={setAutocompletePerson}
                  />
                  {ffCustomerPortalLogisticsSection && <Logistics />}
                  {ffDemoSterlingLineHaul ? <DemoPackages /> : <Packages />}
                  {/* <Charges */}
                  {/*  onSubmit={onSubmit} */}
                  {/*  successCallback={successCallback} */}
                  {/* /> */}
                  <OrderFormCard>
                    <Documents isEditMode={isEditMode} />
                  </OrderFormCard>
                </GappedStackCard>
              </Stack>
            </Stack>
          </OrderFormEditAccessProvider>
        </Box>
      </Stack>
    </>
  );
};

export const DemoCustomerPortalPlaceOrdersPage = (
  props: DemoCustomerPortalPlaceOrdersPageProps,
) => {
  const { form } = useOrderForm({
    isRecurringTemplate: false,
  });

  // TODO: Support permissions for third-party users.
  return (
    <FormProvider {...form}>
      <UserPermissionsProvider value={demoPermissions}>
        <DemoCustomerPortalPlaceOrdersPageForm {...props} />
      </UserPermissionsProvider>
    </FormProvider>
  );
};
