import { zodResolver } from '@hookform/resolvers/zod';
import Add from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Divider,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';
import { ErrorsAlert } from '../../../../../common/components/errors-alert';
import { useErrors } from '../../../../../common/react-hooks/use-errors';
import {
  LineHaulManifestsDocument,
  useCreateLineHaulManifestsMutation,
} from '../../../../../generated/graphql';
import PalletDatePicker from '../../../../../pallet-ui/date-picker/pallet-date-picker';
import { dayjsDate } from '../../../../orders/components/order-form/forms/zod-utils';
import CreateManifestsLanes from './create-manifests-lanes';

// Schema for the form. All fields are required.
const ManifestCreationSchema = z.object({
  date: dayjsDate('Date is required'),
  rows: z.array(
    z.object({
      startTerminalUuid: z.string().uuid(),
      endTerminalUuid: z.string().uuid(),
      count: z
        .number({
          required_error: 'Load count is required',
        })
        .int({
          message: 'Load count must be an integer',
        })
        .min(1, {
          message: 'Load count must be greater than 0',
        }),
    }),
  ),
});

export type ManifestCreationFormValues = z.infer<typeof ManifestCreationSchema>;

type CreateManifestsButtonProps = {
  readonly initialDate: Dayjs;
};

const CreateManifestsButton = ({ initialDate }: CreateManifestsButtonProps) => {
  const { onError, clearErrors, errors } = useErrors();

  // State for the popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // React Hook Form
  const formMethods = useForm<ManifestCreationFormValues>({
    resolver: zodResolver(ManifestCreationSchema),
    defaultValues: {
      date: initialDate,
      rows: [
        {
          startTerminalUuid: undefined,
          endTerminalUuid: undefined,
          count: 1,
        },
      ],
    },
  });

  // Sync form value with updates to initialDate
  useEffect(() => {
    formMethods.setValue('date', initialDate);
  }, [initialDate, formMethods]);

  // Close the popover and reset the form
  const handleClose = () => {
    setAnchorEl(null);

    // Add timeout so fields don't reset before form close animation is complete
    setTimeout(() => {
      formMethods.reset({
        date: initialDate,
        rows: [
          {
            startTerminalUuid: undefined,
            endTerminalUuid: undefined,
            count: 1,
          },
        ],
      });
    }, 500);
  };

  // Form submission + manifest creation
  const [createManifests] = useCreateLineHaulManifestsMutation({
    refetchQueries: [LineHaulManifestsDocument],
  });
  const onSubmit: SubmitHandler<ManifestCreationFormValues> = (data) => {
    void createManifests({
      variables: {
        input: {
          departDate: data.date.toISOString(),
          lanes: data.rows.map((row) => ({
            startTerminalUuid: row.startTerminalUuid,
            endTerminalUuid: row.endTerminalUuid,
            count: row.count,
          })),
        },
      },
      onCompleted: handleClose,
      onError,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{ minWidth: 'max-content' }}
        onClick={handleClick}
      >
        New manifest
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack>
              <Box px={2} paddingBottom={1} paddingTop={2}>
                <Box maxWidth={300} whiteSpace="wrap">
                  <ErrorsAlert errors={errors} onClear={clearErrors} />
                </Box>
                <Typography fontWeight="500" fontSize="20px">
                  Create manifest(s)
                </Typography>
              </Box>
              <Divider />
              <Stack p={2} gap={2}>
                <Controller
                  name="date"
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography fontWeight="700" fontSize="14px">
                          Date
                        </Typography>
                        <PalletDatePicker
                          showArrows
                          value={dayjs(field.value)}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                        />
                      </Stack>
                      {!isNil(fieldState?.error?.message) &&
                        fieldState.error.message.length > 0 && (
                          <Typography color="error" fontSize="12px">
                            {fieldState.error.message}
                          </Typography>
                        )}
                    </>
                  )}
                />
                <CreateManifestsLanes />
                <Stack
                  direction="row"
                  gap={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" type="submit">
                    Create
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Popover>
    </>
  );
};

export default CreateManifestsButton;
