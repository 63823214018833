import {
  InvoiceTotalDocument,
  InvoiceTotalsDocument,
  OrderStatus,
  OrderStatusFilterType,
  OutstandingShipmentsUuidsDocument,
  ShipmentStatus,
  ShipmentStatusFilterType,
} from '../../generated/graphql';

export const ALL_ORDER_STATUS_FILTERS = [
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Delivered,
  },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Invoiced,
  },
  { filterType: OrderStatusFilterType.Equals, status: OrderStatus.Cancelled },
  {
    filterType: OrderStatusFilterType.Equals,
    status: OrderStatus.Finalized,
  },
];

export const ALL_SHIPMENT_STATUS_FILTERS = [
  {
    filterType: ShipmentStatusFilterType.Equals,
    status: ShipmentStatus.HasIssue,
  },
  {
    filterType: ShipmentStatusFilterType.Equals,
    status: ShipmentStatus.Finalized,
  },
  {
    filterType: ShipmentStatusFilterType.Equals,
    status: ShipmentStatus.Delivered,
  },
  {
    filterType: ShipmentStatusFilterType.Equals,
    status: ShipmentStatus.Invoiced,
  },
  {
    filterType: ShipmentStatusFilterType.Equals,
    status: ShipmentStatus.Paid,
  },
];

/// a list of queries that should be refetched after any invoice mutation
/// this is necessary to ensure that the UI is updated with the latest data
export const invoiceRefetchQueries = [
  OutstandingShipmentsUuidsDocument,
  InvoiceTotalDocument,
  InvoiceTotalsDocument,
];
