import { AlertTitle, Stack, Typography } from '@mui/material';

import { Alert } from '@mui/material';
import { type AddOrdersToInvoicesForContactErrorOutput } from '../../../../../generated/graphql';
import pluralize from 'pluralize';
import OrdersListForResultCard from './orders-list-for-result-card';
import { isEmpty } from 'lodash';

const AddOrdersToInvoicesForContactError = ({
  errorOutput,
}: {
  readonly errorOutput: AddOrdersToInvoicesForContactErrorOutput;
}) => {
  const { proposedNewInvoicesToCreate, proposedUpdatesToExistingInvoices } =
    errorOutput;
  return (
    <Alert severity="error">
      <AlertTitle>
        {proposedNewInvoicesToCreate.length +
          proposedUpdatesToExistingInvoices.length}{' '}
        failed
      </AlertTitle>
      {!isEmpty(proposedUpdatesToExistingInvoices) && (
        <Stack gap={1}>
          <Typography fontWeight="bold" className="mt-2">
            Could not add to following invoices:
          </Typography>
          <Stack pl={2}>
            {proposedUpdatesToExistingInvoices.map((invoice) => (
              <Stack key={invoice.invoiceUuid}>
                <Stack direction="row" gap={1}>
                  <Typography color="error" fontWeight="bold">
                    {invoice.name}
                  </Typography>
                  <Typography color="error">
                    {`(${invoice.ordersToAdd.length} ${pluralize('order', invoice.ordersToAdd.length)})`}
                  </Typography>
                </Stack>
                <OrdersListForResultCard orders={invoice.ordersToAdd} />
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
      {!isEmpty(proposedNewInvoicesToCreate) && (
        <Stack gap={1}>
          <Typography fontWeight="bold" className="mt-2">
            Could not create new invoices:
          </Typography>
          <Stack pl={2}>
            {proposedNewInvoicesToCreate.map((invoice) => (
              <Stack key={null}>
                <Stack direction="row" gap={1}>
                  <Typography color="error" fontWeight="bold">
                    {invoice.name ?? 'New invoice'}
                  </Typography>
                  <Typography color="error">
                    {`(${invoice.ordersToAdd.length} ${pluralize('order', invoice.ordersToAdd.length)})`}
                  </Typography>
                </Stack>
                <OrdersListForResultCard orders={invoice.ordersToAdd} />
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Alert>
  );
};

export default AddOrdersToInvoicesForContactError;
