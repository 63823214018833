import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import {
  isInboundShipment,
  isOutboundShipment,
} from '../../../common/utils/stops';
import {
  isAddressEmpty,
  isNilOrEmptyString,
} from '../../../common/utils/utils';
import {
  type LineHaulManifestForPrintFragment,
  type LineHaulOrderForPrintedManifestFragment,
} from '../../../generated/graphql';
import { getStopTypeLabel } from '../../orders/components/inbound-messages/review-edi-orders/edi-selected-order-accordion';
import DisplayAddress from './display-address';

dayjs.extend(utc);
dayjs.extend(timezone);

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
    fontFamily: 'Roboto',
  },
  stopSection: {
    flexDirection: 'row',
    fontSize: '10px',
    marginTop: '12px',
  },
  stopSectionMediumSlice: {
    width: '27%',
    paddingLeft: '10px',
  },
  stopSectionLargeSlice: {
    width: '45%',
    paddingLeft: '10px',
  },
  stopSectionFirstSlice: {
    width: '8%',
  },
  stopSectionSmallSlice: {
    width: '20%',
    paddingLeft: '10px',
  },
  companyTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  manifestNumberAndLaneSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  laneBoxes: {
    flexDirection: 'row',
    gap: '5px',
  },
  laneBox: {
    border: '1px solid black',
    fontWeight: 700,
    paddingLeft: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '10px',
    fontSize: '20px',
  },
  manifestDetailsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '12px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  manifestDetailColumn: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
  },
  // orders list
  ordersListHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
  },
  orderDetailsSection: {
    flexDirection: 'column',
    gap: '5px',
    borderBottom: '0.5px solid black',
    paddingBottom: '10px',
  },
  orderDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '9px',
    width: '100%',
    textAlign: 'center',
  },
  orderDetailsRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  orderDetailsRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  refAndTagsSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
  },
  orderInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%',
  },
  orderOrdinalAndShipperConsigneeSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
  },
  orderShipperConsigneeColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '10px',
  },
  orderFinalDestinationColumn: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    gap: '5px',
  },
  orderOrdinalBox: {
    border: '1px solid black',
    fontWeight: 700,
    paddingLeft: '20px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingRight: '20px',
    fontSize: '20px',
  },
  titleA: {
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  additionalSection: {
    flexDirection: 'row',
    fontSize: '10px',
    paddingTop: '8px',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    textAlign: 'right',
    fontSize: '10px',
  },
  totalsSection: {
    flexDirection: 'row',
    width: '25%',
    fontSize: '12px',
    padding: '8px',
    justifyContent: 'space-between',
    border: '1px solid black',
    marginTop: '10px',
    alignSelf: 'flex-end',
  },
});

type GeneratedLineHaulManifestPdfProps = {
  readonly manifests: LineHaulManifestForPrintFragment[] | null;
  readonly companyName: string;
  readonly documentTitle?: string;
};

const getInboundAddress = (
  order: LineHaulOrderForPrintedManifestFragment,
  carrier?: string | null,
) => {
  const inboundShipment = order.shipments.find((s) => isInboundShipment(s));

  const address = inboundShipment?.legs[0]?.endStop.address;

  if (!isAddressEmpty(address)) {
    return <DisplayAddress address={address} />;
  }

  if (!isNilOrEmptyString(carrier)) {
    return (
      <View>
        <Text>{carrier}</Text>
      </View>
    );
  }

  return <Text>No inbound info provided</Text>;
};

const getOutboundAddress = (
  order: LineHaulOrderForPrintedManifestFragment,
  carrier?: string | null,
) => {
  const outboundShipment = order.shipments.find((s) => isOutboundShipment(s));

  if (isNil(outboundShipment)) {
    return null;
  }

  const lastLeg = outboundShipment.legs.at(-1);
  const address = lastLeg?.endStop.address;

  if (!isAddressEmpty(address)) {
    return <DisplayAddress address={address} />;
  }

  if (!isNilOrEmptyString(carrier)) {
    return (
      <View>
        <Text>{carrier}</Text>
      </View>
    );
  }

  return <Text>No outbound info provided</Text>;
};

const GeneratedLineHaulManifestPdf = ({
  manifests,
  companyName,
  documentTitle,
}: GeneratedLineHaulManifestPdfProps) => {
  if (isNil(manifests)) {
    return null;
  }
  return (
    <Document title={documentTitle ?? 'Manifest new'}>
      {manifests?.map((manifest) => {
        return (
          <Page key={manifest.uuid} size="LETTER" style={styles.page}>
            <Text
              fixed
              style={styles.pageNumbers}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
            <View style={{ marginBottom: '10px' }}>
              <Text style={styles.companyTitle}>{companyName}</Text>
            </View>
            <View style={{ borderTop: '1px solid black' }} />
            <View style={styles.manifestNumberAndLaneSection}>
              <Text>
                <Text style={{ fontWeight: 700 }}>Manifest#: </Text>
                {manifest.referenceNumber}
              </Text>
              <View style={styles.laneBoxes}>
                <View style={styles.laneBox}>
                  <Text>{manifest.startTerminal.code}</Text>
                </View>
                <View style={styles.laneBox}>
                  <Text>{manifest.endTerminal.code}</Text>
                </View>
              </View>
            </View>
            <View style={{ borderTop: '1px solid black' }} />
            <View style={styles.manifestDetailsSection}>
              <View style={styles.manifestDetailColumn}>
                <Text>
                  <Text style={{ fontWeight: 700 }}>Line haul: </Text>
                  {manifest.startTerminal.code} - {manifest.endTerminal.code}
                </Text>
                <Text>
                  <Text style={{ fontWeight: 700 }}>Departed: </Text>
                  {(isNil(manifest.startedAt)
                    ? undefined
                    : dayjs(manifest.startedAt).format('MM/DD/YYYY hh:mm')) ??
                    'N/A'}
                </Text>
                <Text>
                  <Text style={{ fontWeight: 700 }}>Driver: </Text>
                  {!isNil(manifest.driver?.firstName) &&
                  !isNil(manifest.driver?.lastName)
                    ? `${manifest.driver?.firstName ?? ''} ${
                        manifest.driver?.lastName ?? ''
                      }`
                    : 'N/A'}
                </Text>
              </View>
              <View style={styles.manifestDetailColumn}>
                <Text>
                  <Text style={{ fontWeight: 700 }}>Manifest date: </Text>
                  {(isNil(manifest.departDate)
                    ? undefined
                    : dayjs(manifest.departDate).format('MM/DD/YYYY')) ?? 'N/A'}
                </Text>
                <Text>
                  <Text style={{ fontWeight: 700 }}>Truck #: </Text>
                  {isNil(manifest.equipment) ? 'N/A' : manifest.equipment.name}
                </Text>
                <Text style={{ fontWeight: 700 }}>Trailer #: </Text>
                <Text style={{ fontWeight: 700 }}>Seal #: </Text>
              </View>
            </View>
            <View
              style={{ borderTop: '0.5px solid black', marginBottom: '10px' }}
            />
            <View
              fixed
              style={[styles.ordersListHeader, styles.orderDetailsRow]}
            >
              <View style={styles.orderDetailsRowLeftSection}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>HAWB </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>Order # </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>MAWB </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>Deadline </Text>
                </View>
              </View>
              <View style={styles.orderDetailsRowRightSection}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>Pcs </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>Dim Wt </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontWeight: 700 }}>Weight </Text>
                </View>
              </View>
            </View>
            {manifest?.orderSegments.map((orderSegment, idx) => {
              const { order } = orderSegment;
              const inboundShipment = order.shipments.find((s) =>
                isInboundShipment(s),
              );
              const outboundShipment = order.shipments.find((s) =>
                isOutboundShipment(s),
              );
              const isReceivedAtTerminal =
                (orderSegment.segment.ordinal === 0 &&
                  !isNil(orderSegment.order.dateMarkedReceivedAtOrigin)) ||
                !isNil(orderSegment?.priorOrderLineHaulSegment?.arrivedAt);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <View key={idx} wrap={false} style={styles.orderDetailsSection}>
                  <View style={styles.orderDetailsRow}>
                    <View style={styles.orderDetailsRowLeftSection}>
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontWeight: 700 }}>
                          {order.standardOrderFields.shipperBillOfLadingNumber}{' '}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text>{order.name} </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text>
                          {
                            order.standardOrderFields
                              .masterAirwayBillOfLadingNumber
                          }
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text>
                          {isNil(
                            outboundShipment?.standardShipmentFields
                              ?.deadlineDate,
                          )
                            ? 'N/A'
                            : dayjs(
                                outboundShipment?.standardShipmentFields
                                  ?.deadlineDate,
                              ).format('MM/DD/YYYY')}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.orderDetailsRowRightSection}>
                      <View style={{ flex: 1 }}>
                        <Text>{order.pieceCountFromPackages}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text>{order.dimWeight}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text>{order.weight}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.refAndTagsSection}>
                    <Text style={{ fontSize: '10px' }}>
                      <Text style={{ fontWeight: 700 }}>Reference #: </Text>
                      <Text>
                        {order.refNumbers.length > 0
                          ? order.refNumbers.join(', ')
                          : 'None'}
                      </Text>
                    </Text>
                    <Text style={{ fontSize: '10px' }}>
                      <Text style={{ fontWeight: 700 }}>Tags: </Text>
                      <Text>
                        {order.tags.length > 0
                          ? order.tags.map((tag) => tag.value).join(', ')
                          : 'None'}
                      </Text>
                    </Text>
                  </View>
                  <View style={styles.orderInfoSection}>
                    <Text style={{ fontSize: '10px' }}>
                      <Text style={{ fontSize: '10px', fontWeight: 700 }}>
                        Customer:{' '}
                      </Text>
                      {order.billingPartyContact.displayName}
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {isReceivedAtTerminal && (
                        <Text style={{ fontSize: '10px' }}>
                          Received at Terminal
                        </Text>
                      )}
                      {order?.hazmat === true && (
                        <Text
                          style={{
                            fontSize: '9px',
                            fontWeight: 700,
                            backgroundColor: 'black',
                            color: 'white',
                            paddingHorizontal: '2px',
                            marginLeft: '10px',
                            paddingVertical: '1px',
                          }}
                        >
                          HAZMAT
                        </Text>
                      )}
                    </View>
                  </View>
                  <View style={styles.orderOrdinalAndShipperConsigneeSection}>
                    <View>
                      <View style={styles.orderOrdinalBox}>
                        <Text>{idx + 1}</Text>
                      </View>
                    </View>
                    <View style={styles.orderShipperConsigneeColumn}>
                      <Text style={{ fontWeight: 700 }}>
                        Inbound (
                        {getStopTypeLabel({
                          stopType: inboundShipment?.legs[0]?.endStop?.stopType,
                          inboundMethod:
                            inboundShipment?.legs[0]?.endStop?.inboundMethod,
                          outboundMethod:
                            outboundShipment?.legs[0]?.endStop?.outboundMethod,
                        })}
                        )
                        <Text style={{ fontWeight: 100 }}>
                          {!isNil(
                            inboundShipment?.legs[0]?.endStop?.serviceDate,
                          ) &&
                            ` — ${dayjs(inboundShipment?.legs[0]?.endStop?.serviceDate).format('MM/DD/YYYY')}`}
                        </Text>
                      </Text>
                      {getInboundAddress(
                        order,
                        inboundShipment?.legs[0]?.endStop?.incomingCarrier,
                      )}
                    </View>
                    <View style={styles.orderShipperConsigneeColumn}>
                      <Text style={{ fontWeight: 700 }}>
                        Outbound (
                        {getStopTypeLabel({
                          stopType:
                            outboundShipment?.legs[0]?.endStop?.stopType,
                          inboundMethod:
                            outboundShipment?.legs[0]?.endStop?.inboundMethod,
                          outboundMethod:
                            outboundShipment?.legs[0]?.endStop?.outboundMethod,
                        })}
                        )
                        <Text style={{ fontWeight: 100 }}>
                          {!isNil(
                            outboundShipment?.legs[0]?.endStop?.serviceDate,
                          ) &&
                            ` — ${dayjs(outboundShipment?.legs[0]?.endStop?.serviceDate).format('MM/DD/YYYY')}`}
                        </Text>
                      </Text>
                      {getOutboundAddress(
                        order,
                        outboundShipment?.legs[0]?.endStop?.outboundCarrier,
                      )}
                    </View>
                    <View style={styles.orderFinalDestinationColumn}>
                      <Text style={{ fontWeight: 700 }}>Final destination</Text>
                      <View style={styles.orderOrdinalBox}>
                        <Text>
                          {order.lineHaulLane?.destinationTerminal.code}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
            <View style={styles.totalsSection} wrap={false}>
              <View>
                <Text style={{ fontWeight: 700 }}>Total Pcs: </Text>
                <Text>
                  {manifest?.orderSegments.reduce(
                    (sum, orderSegment) =>
                      sum + orderSegment.order.pieceCountFromPackages,
                    0,
                  )}
                </Text>
              </View>
              <View>
                <Text style={{ fontWeight: 700 }}>Total Wt: </Text>
                <Text>
                  {manifest?.orderSegments.reduce(
                    (sum, orderSegment) => sum + orderSegment.order.weight,
                    0,
                  )}
                </Text>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default GeneratedLineHaulManifestPdf;
