import { Stack } from '@mui/material';
import { type LineHaulManifest } from '../utils';
import StatusPicker from './status-picker';
import ManifestDriver from './manifest-driver';
import ManifestVehicle from './manifest-vehicle';
import LoadSummary from './load-summary';

type ManifestDetailsBodyProps = {
  readonly manifest: LineHaulManifest;
};

const ManifestDetailsBody = ({ manifest }: ManifestDetailsBodyProps) => {
  return (
    <Stack p={1} gap={2}>
      <StatusPicker manifest={manifest} />
      <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
        <ManifestDriver manifest={manifest} />
        <ManifestVehicle manifest={manifest} />
      </Stack>
      <LoadSummary manifest={manifest} />
    </Stack>
  );
};

export default ManifestDetailsBody;
