import { z } from 'zod';
import { BillingMethod } from '../../../../../common/types';
import { fuelChargeOrCostBaseSchema } from './fuel-charge-or-cost-base-schema';

export const fuelChargeSchema = fuelChargeOrCostBaseSchema.extend({
  settlementPercentageRate: z.number().nullable(),
  settlementFlatRate: z.number().nullable(),
  settlementBillingMethod: z.nativeEnum(BillingMethod).nullable(),
  deductionTotal: z.number(),
});
